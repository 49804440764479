export const groupIcons = {
  "SUMARIOS": "pi-file",
  "EXPEDIENTES": "pi-folder",
  "UFI DELITOS": "pi-desktop",
  "PRELIMINARES": "pi-file-excel",
  "PARAMETRIZACIÓN": "pi-sliders-h",
  "DEPÓSITO": "pi-bars",
  // "OTROS LINKS": "pi-question-circle"
};

export const sidebarLinks = [
  {
    path:'/',
    pathName:'escritorio',
    titulo:'ESCRITORIO',
    icon: 'pi-chart-bar',
    roles:[],
    type:'button',
    grupo:'TOP'
  },
  {
    path:'/actuaciones/list',
    pathName:'actuaciones',
    titulo:'ACTUACIONES',
    icon: 'pi-list',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario','UsuarioDependencia','Control de Gestion','Dirección de Información para el Delito D-2','Dpto. Asuntos Judiciales D-5','Dpto. Planeamiento' , 'Juez' , 'Ayudante de Juez', 'Dpto.Criminalistica' , 'Lab. Informatico Forense', 'Administrativo' , 'Ayudante Fiscal' , 'Fiscal' , 'Fiscal General' , 'Policia Investigador' , 'Supervisor','UsuarioDepartamental'],
    type:'button',
    grupo:'TOP'
  },
  {
    path:'/actuaciones/new/sumario-denuncia/initial',
    pathName:'sumario-denuncia',
    titulo:'Sumario Denuncia',
    icon: 'pi-briefcase',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'SUMARIOS',
    props: { formType: "denuncia", actuacionType: "sumario-denuncia", pageTitle: "PREVENCIONAL DENUNCIA" }
  },
  {
    path:'/actuaciones/new/sumario-oficio/initial',
    pathName:'sumario-oficio',
    titulo:'Sumario Oficio',
    icon: 'pi-bookmark',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'SUMARIOS',
    props: { formType: "oficio" , actuacionType: "sumario-oficio" , pageTitle: "PREVENCIONAL OFICIO" },

  },
  {
    path:'/actuaciones/new/expediente-denuncia/initial',
    pathName:'expediente-denuncia',
    titulo:'Expediente Denuncia',
    icon: 'pi-book',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'EXPEDIENTES',
    props: { formType: "contravencional-denuncia", actuacionType: "expediente-denuncia" , pageTitle: "CONTRAVENCIONAL DENUNCIA"},
  },
  {
    path:'/actuaciones/new/expediente-oficio/initial',
    pathName:'expediente-oficio',
    titulo:'Expediente Oficio',
    icon: 'pi-desktop',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'EXPEDIENTES',
    props: { formType: "contravencional-oficio" , actuacionType: "expediente-oficio" , pageTitle: "CONTRAVENCIONAL OFICIO" },
  },
  {
    path:'/actuaciones/new/ufi-flagrancia/initial',
    pathName:'ufi-flagrancia',
    titulo:'Flagrancia',
    icon: 'pi-bookmark-fill',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'UFI DELITOS',
    props: { formType: "ufi-flagrancia", actuacionType: "ufi-flagrancia" , pageTitle: "UFI - FLAGRANCIA" },
  },
  {
    path:'/actuaciones/ufi-genericos/menu',
    pathName:'ufi-genericos',
    titulo:'Genéricos',
    icon: 'pi-box',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'submenu', // Cambiamos el type a 'submenu'
    grupo:'UFI DELITOS',
    props: { formType: "ufi-genericos" , pageTitle: "UFI - GENÉRICOS"},
    items: [  // Agregamos la propiedad 'items' que contendrá los submenús
        {
            path:'/actuaciones/new/ufi-generica-denuncia/initial',
            pathName:'ufi-generica-denuncia',
            titulo:'Genéricos por Denuncia',
            icon: 'pi-box',
            roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
            type:'link',
            props: { formType: "ufi-anivi", actuacionType: "ufi-anivi" , pageTitle: "UFI - ANIVI" },
        },
        {
            path:'/actuaciones/new/ufi-generica-oficio/initial',
            pathName:'ufi-generica-oficio',
            titulo:'Genéricos por Oficio',
            icon: 'pi-box',
            roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
            type:'link',
            props: { formType: "ufi-establecer-paradero", actuacionType: "ufi-establecer-paradero" , pageTitle: "UFI - ESTABLECER PARADERO" },
        },
        {
          path: "/actuaciones/new/ufi-establecer-paradero/initial",
          pathName: "ufi-establecer-paradero",
          titulo: "Establecer Paradero",
          icon: 'pi-box',
          roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
          type:'link',
          props: { formType: "ufi-anivi", actuacionType: "ufi-anivi" , pageTitle: "UFI - ANIVI" },
        }
    ]
},
  {
    path:'/actuaciones/new/ufi-propiedad-denuncia/initial',
    pathName:'ufi-propiedad-denuncia',
    titulo:'Contra la Propiedad por Denuncia',
    icon: 'pi-box',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'UFI DELITOS',
    props: { formType: "ufi-anivi", actuacionType: "ufi-anivi" , pageTitle: "UFI - ANIVI" },
  }
  ,
  {
    path:'/actuaciones/new/ufi-propiedad-oficio/initial',
    pathName:'ufi-propiedad-oficio',
    titulo:'Contra la Propiedad por Oficio',
    icon: 'pi-box',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'UFI DELITOS',
    props: { formType: "ufi-anivi", actuacionType: "ufi-anivi" , pageTitle: "UFI - ANIVI" },
  }
  ,
  {
    path:'/actuaciones/new/ufi-informatica-denuncia/initial',
    pathName:'ufi-informatica-denuncia',
    titulo:'Informáticos y Estafas por Denuncia',
    icon: 'pi-box',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'UFI DELITOS',
    props: { formType: "ufi-anivi", actuacionType: "ufi-anivi" , pageTitle: "UFI - ANIVI" },
  }
  ,
  {
    path:'/actuaciones/new/ufi-informatica-oficio/initial',
    pathName:'ufi-informatica-oficio',
    titulo:'Informáticos y Estafas por Oficio',
    icon: 'pi-box',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'UFI DELITOS',
    props: { formType: "ufi-anivi", actuacionType: "ufi-anivi" , pageTitle: "UFI - ANIVI" },
  }
  ,
  {
    path:'/actuaciones/new/ufi-cavig/initial',
    pathName:'ufi-cavig',
    titulo:'Cavig',
    icon: 'pi-building',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'UFI DELITOS',
    props: { formType: "ufi-cavig", actuacionType: "ufi-cavig" , pageTitle: "UFI - CAVIG" },
  }
  ,
  {
    path:'/actuaciones/new/ufi-anivi/initial',
    pathName:'ufi-anivi',
    titulo:'Anivi',
    icon: 'pi-box',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'UFI DELITOS',
    props: { formType: "ufi-anivi", actuacionType: "ufi-anivi" , pageTitle: "UFI - ANIVI" },
  }
  ,
  {
    path:'/actuaciones/ufi-delitos-especiales/menu',
    pathName:'ufi-delitos-especiales',
    titulo:'Especiales',
    icon: 'pi-flag-fill',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'submenu',
    grupo:'UFI DELITOS',
    props: { formType: "ufi-delitos-especiales" , pageTitle: "UFI - DELITOS ESPECIALES"},
    items: [
      {
        path: '/actuaciones/new/ufi-emergencias-medicas/initial',
        pathName: "especiales-médicas",
        titulo: "Emergencias Medicas",
        icon: "pi-microsoft",
        roles: ["User Tester", "Super User", "Jefe de Dependencia", "Oficial Sumariante", "Jefe de Sumario"],
        type: "link",
        grupo: "Especiales",
        props: { formType: "especiales-médicas", pageTitle: "EMERGENCIAS MÉDICAS" }
      },
      {
        path: '/actuaciones/new/ufi-siniestro-vial/initial',
        pathName: "especiales-vial",
        titulo: "Siniestro Vial",
        icon: "pi-microsoft",
        roles: ["User Tester", "Super User", "Jefe de Dependencia", "Oficial Sumariante", "Jefe de Sumario"],
        type: "link",
        grupo: "Especiales",
        props: { formType: "especiales-vial", pageTitle: "SINIESTRO VIAL" }
      },
      {
        path: '/actuaciones/new/ufi-suicidio/initial',
        pathName: "especiales-ahorcamiento-suicidio",
        titulo: "Ahorcamiento o Suicidio",
        icon: "pi-microsoft",
        roles: ["User Tester", "Super User", "Jefe de Dependencia", "Oficial Sumariante", "Jefe de Sumario"],
        type: "link",
        grupo: "Especiales",
        props: { formType: "especiales-ahorcamiento-suicidio", pageTitle: "AHORCAMIENTO O SUICIDIO" }
      },
      {
        path: '/actuaciones/new/ufi-incendio-vivienda/initial',
        pathName: "especiales-incendio-vivienda",
        titulo: "Incendio en Vivienda",
        icon: "pi-microsoft",
        roles: ["User Tester", "Super User", "Jefe de Dependencia", "Oficial Sumariante", "Jefe de Sumario"],
        type: "link",
        grupo: "Especiales",
        props: { formType: "especiales-incendio-vivienda", pageTitle: "INCENDIO EN VIVIENDA" }
      },
      {
        path: '/actuaciones/new/ufi-intoxicacion/initial',
        pathName: "especiales-intoxicación",
        titulo: "Intoxicación",
        icon: "pi-microsoft",
        roles: ["User Tester", "Super User", "Jefe de Dependencia", "Oficial Sumariante", "Jefe de Sumario"],
        type: "link",
        grupo: "DELITOS-ESPECIALES",
        props: { formType: "especiales-intoxicación", pageTitle: "INTOXICACIÓN" }
      }, 
      {
        path: '/actuaciones/new/ufi-otros/initial',
        pathName: "ufi-otros",
        titulo: "Otros",
        roles: ["User Tester", "Super User", "Jefe de Dependencia", "Oficial Sumariante", "Jefe de Sumario"],
        type: "link",
        grupo: "DELITOS-ESPECIALES",
        props: { formType: "ufi-otros", pageTitle: "UFI - OTROS" }
      }
    ]
  },
  {
    path:'/actuaciones/preliminares/menu',
    pathName:'preliminares',
    titulo:'Preliminares',
    icon: 'pi-microsoft',
    roles:['User Tester','Super User','Jefe de Dependencia','Oficial Sumariante','Jefe de Sumario'],
    type:'link',
    grupo:'PRELIMINARES',
    props: { formType: "actuaciones-preliminares" ,  pageTitle: "ACTUACIONES PRELIMINARES"},
  },
  {
    path:'/parametros/list',
    pathName:'parametros',
    titulo:'Parametrización',
    icon: 'pi-sliders-v',
    roles:['User Tester','Super User'],
    type:'link',
    grupo:'PARAMETRIZACIÓN'
  },
  {
    path:'/deposito/efectos',
    pathName:'deposito-efectos',
    titulo:'Efectos',
    icon: 'pi-folder-open',
    roles:['User Tester','Super User','Deposito Judicial','Jefe de Dependencia','UsuarioDepartamental'],
    type:'link',
    grupo:'DEPÓSITO'
  },
  {
    path:'/deposito/solicitudes',
    pathName:'solicitudesView',
    titulo:'Solicitudes',
    icon: 'pi-inbox',
    roles:['User Tester','Super User','Deposito Judicial','Jefe de Dependencia','UsuarioDepartamental'],
    type:'link',
    grupo:'DEPÓSITO'
  }
  // ,
  // {
  //   path:'/indice',
  //   pathName:'direccion-d1',
  //   titulo:'Direccion-D1',
  //   icon: 'pi-hashtag',
  //   roles:['User Tester','Super User'],
  //   type:'link',
  //   grupo:'OTROS LINKS'
  // }
  ,
  {
    path:'/location',
    pathName:'localizacion',
    titulo:'Localización',
    icon: 'pi-map-marker',
    roles:['Jefe de Dependencia', 'Jefe de Sumario','Oficial Sumariante', 'Super User'],
    type:'link',
    grupo:'BOTTOM'
  }
  ,
  {
    path:'/editar-contrasena',
    pathName:'cambiar-contraseña',
    titulo:'Cambiar Contraseña',
    icon: 'pi-key',
    roles:[],
    type:'link',
    grupo:'BOTTOM'
  }
  ,
  {
    path:'/logout',
    pathName:'cerrar-Sesión',
    titulo:'Cerrar Sesión',
    icon: 'pi-sign-out',
    roles:[],
    type:'link',
    grupo:'BOTTOM',
    action: 'logout'
  },
  // {
  //   path: '/',
  //   pathName: 'spacer',
  //   titulo: '',
  //   icon: '', // Deja el icono en blanco
  //   roles: [],
  //   type: 'spacer',
  //   grupo: 'OTROS LINKS'
  // }
]
