import { render, staticRenderFns } from "./DinamicViewer.vue?vue&type=template&id=154a7ad3&scoped=true&"
import script from "./DinamicViewer.vue?vue&type=script&lang=js&"
export * from "./DinamicViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154a7ad3",
  null
  
)

export default component.exports