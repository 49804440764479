<template>
  <div class="grid card px-3 py-1 shadow-2" >
    <!-- Header -->
    <div class="col-12 flex justify-content-between align-items-center border-bottom-1 border-black-alpha-30 p-0 mb-2">
      <h2 class="text-2xl font-light">Nuevo Afectado</h2>
      <div class="button_Section_process guardar" v-if="arrId">
        <Button
          label="Guardar"
          icon="pi pi-back"
          @click="handleSaveDenunciante()"
          test-id="buttonSaveDenuciante"
          :disabled="!isTouched || hasErrors"
        />
      </div>
    </div>
    <!-- Lista de personas -->
    <div v-if="!minimal" class="col-12 md:col-4 lg:col-3 lateral" >
      <div class="flex justify-content-between align-items-center w-full border-round-md shadow-2 border-none px-3 mb-2" style="border: 1px solid red;">
        <h3 class="font-light text-black-alpha-50">Afectados</h3>
        <Button
          icon="pi pi-plus"
          class="p-button-success p-button-lg p-button-rounded p-button-outlined px-2 py-2 m-1"
          style="z-index: 3"
          @click="handleNewAfectado"
        />  
      </div>
      <Listbox
        v-model="dataArrId"
        :options="denuncianteTodosSelect"
        dataKey="arrId"
        optionValue="arrId"
        optionLabel="name"
        ref="listBox"
        test-id="listDenunciantes"
        class="border-round-md shadow-2 border-none"
        v-if="denuncianteTodosSelect[0].type !== 'empty'"
      >
        <template #option="slotProps">
          <div class="flex justify-content-between align-items-center" v-if="slotProps.option.type !== 'empty'">
            <div class="flex flex-column text-base w-full">
              <div class="mb-2">
                <span class="uppercase font-semibold" v-if="slotProps.option.apellido">{{ slotProps.option.apellido }}, </span>
                <span>{{ slotProps.option.name }}.</span>
              </div>
              <span class="block mb-2" v-if="slotProps.option.type !== 'empty'" >DNI: {{ slotProps.option.documento }}</span>
              <Tag
                :severity="colorTag(slotProps.option.condicion.nombre)"
                :class="colorTag(slotProps.option.condicion.nombre)"
                class="w-max"
                :value="slotProps.option.condicion?.nombre"
                rounded
                v-if="slotProps.option.type !== 'empty'"
              />
            </div>
            <Button
              v-if="slotProps.option.type !== 'empty'"
              icon="pi pi-trash"
              class="p-button-danger p-button-lg p-button-rounded p-button-outlined px-2 py-2 m-1"
              style="z-index: 3"
              @click.stop="$_TemplateParentForm_delete(slotProps.option.arrId)"
            />  
          </div>
        </template>
      </Listbox>
      <div class="button_Section_process volver">
        <Button
          label="Volver"
          icon="pi pi-back"
          @click="handleBackToMainForm()"
          test-id="buttonVolver"
        />
      </div>
    </div>
    <!-- Formulario -->
    <div :class="{ 'col': !minimal }">
      <div
        class="field-checkbox ml-2"
        :class="{ notVisible: checkOrdenPublico() }"
        v-if="
          arrId &&
          viewData[viewData.actuacion].checkboxPublicOrder
        "
      >
        <Checkbox id="binary" v-model="ordenPublico" :binary="true" />
        <label for="binary">Orden público</label>
      </div>
      <div
        :class="{hideForm: formData.type === null || ordenPublico}"
        class="card shadow-2"
      >
        <FormPersonaV2
          ref="denunciante"
          :acusado="false"
          @update:persona="updatePersona"
          @validationStatus="handleValidationStatus"
          :arrId="arrId"
          :getPersonByArrId="getDenuncianteByArrId"
          :ordenPublico = "ordenPublico"
          :formType = "formType"
          typeOfPerson="denunciante"
          :hidden="viewData[viewData.actuacion].formPersona.hidden"
          :Involucrados="denuncianteTodosSelect"
        />
      </div>
    </div>
    <ModalTemplate 
      title="Información"
      type="informativo"
      :message="modalRefInformMessage"
      :showIcon="true"
      @confirm="closeModal()"
      severity="danger"
      ref="ModalRefInform"
      :closable="false"
    />
    <ModalSucces 
      title="¿Estás seguro de que quieres volver?"
      type="confirmativo"
      :message="modalRefSaveMessage"
      :showIcon="true"
      @confirm="backToTheMainForm"
      @cancel="cancelBack"
      :closable="false"
      severity="success"
      ref="ModalRefSave"
    />
    <ModalSucces 
      title="¡Los datos se guardaron con éxito!"
      type="success"
      :message="modalRefSaveSuccess"
      :showIcon="true"
      @confirm="backToTheMainForm"
      @cancel="cancelBack"
      :closable="false"
      severity="success"
      ref="ModalRefSaveSuccess"
    />
  </div>

  
</template>

<script>
import Listbox from "primevue/listbox";
import FormPersonaV2 from "../forms/FormPersonaV2.vue";
import ModalTemplate from '../../components/ModalTemplate.vue'
/*
Este es un modelo para componente que seran re utilizables muchas veces
*/
import { mapGetters, mapState } from "vuex";
import ModalSucces from "../ModalSucces.vue";
import Card from "primevue/card/Card";
export default {
  name: "FormDenunciantes",
  components: {
    FormPersonaV2,
    Listbox,
    ModalTemplate,
    ModalSucces,
    Card
  },
  props: {
    minimal: {
      type: String, // vista minima del formulario si esta definido
    },
    predefined: {
      type: Object, //string que se usara como base para llenar la declaracion de denuncia
    },
    aid: {
      type: String, //string que se usara para volver a mostrar en Editor de denuncia al recargar
    },
    formType: String,
    actuacionType: String //Defines the type of form
  },
  data() {
    return {
      arrId: this.aid || null,
      firstTime: true,
      saveOrBackButtonActive: false,
      formDataCopy: {},
      denuncianteData: null, // Aquí se almacenará toda la info del denunciante
      modalRefInformMessage: '',
      modalRefSaveMessage: '',
      modalRefSaveSuccess: '',
      validationErrors: {},
      isTouched: false,
      hasErrors: false,
    };
  },
  mounted() {
    if (
      typeof this.getDenuncianteByArrId(this.$router.currentRoute.query.id) !=
      "undefined"
    ) {
      this.arrId = this.$router.currentRoute.query.id;
    }   
  },
  created() { 
    
    if(this.$route.query.id == 'new')
    {
      this.arrId = this.denuncianteTodosSelect[(this.denuncianteTodosSelect.length)-1].arrId
    }    
  },
  computed: {
    ...mapGetters("sumario/denunciante", [
      "getDenuncianteByArrId",
      "getEmptyDocDenunciante",
      "getNewDenuncianteArrId",
      "denuncianteTodosSelect",
    ]),
    ...mapState({
      denunciante: (state) => state.sumario.denunciante,
      viewData: (state) => state.sumarioViews,
    }),
    //manejo del dato de state
    formData: {
      get() {
        if (this.predefined && !this.arrId) {
          if (this.predefined.new) {
            return {
              doc: {},
              type: "empty",
              ordenPublico: this.predefined.default.ordenPublico,
            };
          }
        }
        if (this.arrId) {
          this.denuncianteData = {...this.getDenuncianteByArrId(this.arrId).doc}
          return this.getDenuncianteByArrId(this.arrId);
        }
        return { doc: {}, type: null, ordenPublico: false };
      },
    },
    //datos que se editan en este componente y no en componentes hijos especificos de Denunciante
    ordenPublico: {
      get() {
        return this.formData.ordenPublico;
      },
      set(value) {
        this.isTouched = true
        this.$_TemplateParentForm_updateValue("ordenPublico", value);
      },
    },

    dataArrId: {
      get() {
        return this.arrId;
      },
      set(value) {
        // if(!this.validateEmail()) return;
        this.arrId = value;
      },
    },
  },
  //comunicacion con hijos
  watch: {
    arrId: function (newValue) {
      if (
        newValue != null &&
        this.$router.currentRoute.query.id != newValue 
      ){
        this.$router.push({ path: `/actuaciones/new/${this.actuacionType}/initial/denunciante?id=${newValue}` });
      }
    },
    formData: function (newValue) {
      if (typeof newValue != "object") return;
      if(this.firstTime){
        setTimeout(() => { 
          this.updateFormPersona(newValue)
          this.firstTime = false
        }, 1)
      } else {
        this.updateFormPersona(newValue)
      }
    },
    validationErrors: {
      handler(newErrors) {
        this.hasErrors = Object.values(newErrors).some(
          (error) => error !== null && error !== ""
        );
      }
    }
  },

  methods: {
    handleNewAfectado(){
      const id = this.denuncianteTodosSelect[(this.denuncianteTodosSelect.length)-1].arrId
      this.$router.push({
        path: `/actuaciones/new/${this.actuacionType}/initial/denunciante?id=${id}`,
      });
    },
    //este metodo sera el encargado de setear el denunciante y guardarlo
    async handleSaveDenunciante(){
      if (!this.formData.arrId) {
        this.formData.arrId = await this.getNewDenuncianteArrId;
      }
      this.validacion('/actuaciones/new/' + this.actuacionType + '/initial')
      await this.$store.dispatch("sumario/denunciante/setDenunciante", {
          ...this.formData,
          doc:{ ...this.denuncianteData},
        });
      await this.$store.dispatch("sumario/denunciante/setEmptyTitle", this.viewData[this.viewData.actuacion].formPersona.whistleblower.emptyTitle);
      this.$_TemplateParentForm_setArrId();
      this.modalRefSaveSuccess = "La información se ha guardado correctamente. Si desea realizar una nueva carga, haga click en 'Seguir cargando'. En caso de querer regresar a la página anterior, haga click en 'Volver'.";
      this.$refs.ModalRefSaveSuccess.open()
    },
    handleValidationStatus(errors) {
      this.validationErrors = { ...errors };

    },
    updatePersona(nuevaData) {
      if(nuevaData.apellido){
        this.isTouched = true
      }
      this.denuncianteData = nuevaData;
    },
    //Este metodo retornara true haciendo que el Checkbox de Orden publico se inabilite si ya fue marcado una vez
    checkOrdenPublico() {
      for (let item of this.denunciante.list) {
        if (item.ordenPublico == true) {
          return true;
        }
      }
      return false;
    },
    //se ejecuta al ser activado por editor para mostrar luego de estar oculto
    //revisar si es necesario que lo use en la primera carga
    activeComponent() {
      this.$refs.denunciante.setData(this.formData.doc);
    },

    $_TemplateParentFor_setValue(data) {
      if (!this.predefined) return;
      if (!this.predefined.valueFactory) return;
      let text = this.predefined.valueFactory;

      //caracteristicas + identikit
      let charters = false;
      
      if (charters) {
        const regexCharters = /{textDescription}/gi;
        text = text.replace(regexCharters, charters);
      }

      //persona fisica
      if (data.doc.sexo) {
        text = text.replace(/{sexo}/gi, data.doc.sexo.nombre);
      }

      if (data.doc.nombre) {
        text = text.replace(/{nombre}/gi, data.doc.nombre);
      }

      if (data.doc.apellido) {
        text = text.replace(/{apellido}/gi, data.doc.apellido);
      }
      if (data.doc.documento) {
        text = text.replace(/{dni}/gi, data.doc.documento);
      }
      this.$emit("setValue", text);
    },

    // guarda cambios en estado
    async $_TemplateParentForm_updateValue(field, value) {
      let formDataType = 'formData';
      if (!this.formData.arrId) {
        this.formData.arrId = await this.getNewDenuncianteArrId;
      }
      if(field === 'ordenPublico' && value === true){
        formDataType = 'formDataCopy';
        this.formDataCopy = {...this.formData};
        const propsToKeep  = ['condicion', 'arrId', 'instruccion', 'nacionalidad', 'tipoDocumento'];
        this.formDataCopy.doc = propsToKeep.reduce((obj, key) => (key in this.formData.doc && (obj[key] = this.formData.doc[key]), obj), {});
      }
      await this.$store.dispatch("sumario/denunciante/setDenunciante", {
        ...this[formDataType],
        [field]: value,
      });

      this.$_TemplateParentForm_setArrId();
    },

    // guarda cambios en doc (especifico para persona del componente acusado)
    async $_TemplateParentForm_handleUpdateDocValue(field, value) {
      if (!this.formData.arrId) {
        this.formData.arrId = await this.getNewDenuncianteArrId;
      }
      await this.$store.dispatch("sumario/denunciante/setEmptyTitle", this.viewData[this.viewData.actuacion].formPersona.whistleblower.emptyTitle);
      this.$_TemplateParentForm_setArrId();
    },

    //le pone array id, es para el caso de nuevos desde editor de denuncia
    $_TemplateParentForm_setArrId() {
      if (!this.arrId) {
        this.arrId = this.formData.arrId;
        if (this.predefined) {
          this.$emit("setArrId", this.arrId);
        }
      }
    },

    // elimina del array
    $_TemplateParentForm_delete(arrId) {
      this.arrId = null;
      this.$store.dispatch(
        "sumario/denunciante/deleteDenuncianteByArrId",
        arrId
      );
    },

    updateFormPersona(newValue){
      // console.log(this.$refs.denunciante)
      this.$refs.denunciante.setData(newValue.doc);
      //force the value of checkbox 'personaJuridica' to not be null
      if (newValue.doc.personaJuridica) {
        this.$refs.denunciante.personaJuridica = newValue.doc.personaJuridica;
      } else {
        this.$refs.denunciante.personaJuridica = false;
      }
      this.$_TemplateParentFor_setValue(newValue);  
    },

    validacion(route){
      // console.log("route: " + route)
      this.saveOrBackButtonActive = true;
      if(!this.validateEmail()) return;
      let errores = this.$refs.denunciante.emitErrores()
      if(errores == true)
      {
        alert("Verifica los campos")
      }else
      {
        // this.$router.push(route)
      }
    },

    validateEmail(){
      // if(this.validateEmailWarning()) return changeRoute;
      // return this.validateEmailError();
    },

    closeModal(){
      this.$refs.ModalRefInform.close();
      this.$refs.denunciante.$el.querySelector("#emailInput").select();
    },

    closeModalWarning(){
      this.$refs.ModalRefInformWarning.close();
      this.saveOrBackButtonActive = false;
    },

    handleBackToMainForm(){
      this.modalRefSaveMessage = 'Antes de volver, asegúrese de haber guardado los datos ingresados. Si ya los ha guardado, puede continuar. Si no ha guardado los datos, hágalo ahora para evitar la pérdida de información.'
      this.$refs.ModalRefSave.open()
    },

    backToTheMainForm(){
      this.saveOrBackButtonActive = true;
      // if(!this.validateEmail()) return;
      this.$router.push( '/actuaciones/new/' + this.actuacionType + '/initial/');
    },

    cancelBack(){
      this.$refs.ModalRefSave.close();
      this.$refs.ModalRefSaveSuccess.close()
    },

    validateEmailError(){
      let validation = this.$rest.validateEmail(this.$refs.denunciante?.email);
      if(this.$refs.denunciante.condicion.nombre === 'Denunciante'){
        this.modalRefInformMessage = 'Se requiere un email con formato válido.';
      } else {
        this.modalRefInformMessage = 'Se requiere un email válido o dejar el campo vacío si no tienes uno disponible.';
      }
      if(validation !== true){
        this.$refs.ModalRefInform.open();
      } 
      return validation;
    },
    colorTag(value) {
      switch (value) {
        case "Denunciante y Damnificado":
          return "success";
        case "Denunciante":
          return "primary";
        case "Damnificado":
          return "warning";
        case "Víctima":
          return "info";
        case "Fallecido":
          return "secondary";
        case "Protagonista":
          return "primary";
        case "Acompañante":
          return "warning";
        case "Peatón":
          return "help";
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.card {
  background: #ffffff;
  padding: 2rem;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 1rem;
}
.hideForm {
  display: none;
}
.button_Section_process {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button_Section_process:not(.volver) {
  margin-right: 25px;
}

.guardar {
  margin-top: -05px;
}

.volver {
  margin-top: 10px;
}

.p-listbox .p-listbox-item.p-highlight {
  background-color:red !important; /* Gris claro */
  color: #333 !important; /* Texto oscuro */
}

@media screnn and (max-width: 768px) {
}
.pad {
  padding: 1rem 0.7rem;
}
.lateral > button {
  margin-top: 20px;
}
.pad > div {
  margin-right: 30px;
}
.notVisible {
  display: none;
}

</style>