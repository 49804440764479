<template>
    <Card
      :class="{
        borderShadow: true,
        passed:
          alertas() == 'ok' &&
          warningAlerts() == 'ok' &&
          viewData.showPeoplesAlertas &&
          showValidation == true,
        error: alertas() != 'ok' && viewData.showPeoplesAlertas && showValidation == true,
        warningPeoples: warningAlerts() != 'ok' && showValidation == true,
        'disabled': disabled 
      }"
      class="borderShadow  shadow-3 border-round-lg border-1 border-400" 
      tabindex="0"
      test-id="cardPeople"
    >
      <template #title>
        <div class="title flex justify-content-between flex-wrap">
          <div test-id="cardPeopleTitle" class="text-gray-700 font-medium text-xl">
            {{ viewData.cardDenunciante.title }}
          </div>
          <!-- <Button icon="pi pi-plus"  class="p-button-rounded p-button-info p-button-outlined" @click="$router.push({path: '/actuaciones/' + actuacionType + '/initial-data/denunciante?id=', query: {id: 'new'}})" test-id="cardPeoplePlusIcon"/> -->
          <Button
            icon="pi pi-plus"
            class="p-button-rounded p-button-info p-button-outlined text-gray-500"
            :disabled="disabled"
            @click="ManageActions('create')"
            test-id="cardPeoplePlusIcon"
          />
        </div>
      </template>
      <template #content>
        <div class="search1">
          <div
            v-for="(item, i) in denunciantes"
            v-bind:key="i"
            class="grid justify-content-between align-items-center"
          >
            <!-- action pegar en relato -->
              <Button
                icon="pi pi-clone"
                style="color: rgba(104, 159, 56, 1);"
                class="p-button-rounded p-button-info p-button-text text-xl col-1"
                @click="pasteInDeposition(item)"
                test-id="cardPeoplePencil"
              />

            <!-- Nombre, dni, tag y domicilio -->
            <div class="col-10 flex-row"  test-id="ordenPublico">
                <div class="flex justify-content-start align-items-center gap-3">
                  <p class="title">
                    <span :test-id="'elemento-denunciante' + i" class="text-black-alpha-80">
                      {{ nombreYapellidos(item)}}
                    </span>
                  </p>
                  <p>
                    DNI: {{ item.doc.documento }}
                  </p>
                  <Tag
                    :severity="colorTag(item.doc?.condicion?.nombre)"
                    :class="colorTag(item.doc?.condicion?.nombre)"
                    :value="item.doc?.condicion?.nombre"
                    rounded
                    test-id="tagProtagonista"
                    v-if="colorTag(item.doc?.condicion?.nombre) !== ''"
                  />
                </div>
                <p class="address" v-if="!item.ordenPublico">
                  {{ item.doc.domicilio || item.doc.domicilioSIPE }}
                </p>
            </div>
            
            <!-- actions enviar y borrar -->
            <div class="col-1">
              <MenuOptions
                :item="item"
                :deleteItem="(event) => ManageActions('delete', item, event)"
                :editItem="() => ManageActions('edit',item)"
              />
            </div>

          </div>
          <div v-if="denunciantes.length < 1" class="flex justify-content-end">
            <span class="text-gray-500">
              Sin Registros
            </span>
          </div>

          <!-- ERRORES -->
          <div
            v-if="
              alertas() != 'ok' && viewData.showPeoplesAlertas && showValidation == true
            "
            style="color: #dc3545"
            class="text-right"
          >
            {{ alertas() }}
          </div>

          <div
            v-if="
              warningAlerts() != 'ok' &&
              viewData.showPeoplesAlertas &&
              showValidation == true
            "
            style="color: #ffc107"
            class="text-right"
          >
            {{ warningAlerts() }}
          </div>
        </div>
      </template>
    </Card>
  <!-- </div> -->
</template>

<script>
import { mapState } from "vuex";
import MenuOptions from '../components/MenuOptions/MenuOptions.vue'
export default {
  name: "Peoples",
  props: {
    datos: Array,
    formType: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Array,
    warning: Array,
    showValidation: Boolean,
    deleteConfirmationInProgress: Boolean,
    modalVisble: Boolean,
    viewData: Object,
    actuacionType: String,
  },
  components:{
    MenuOptions
  },  
  data: () => {
    return {
      isSearching: false,
      people: {},
      dni: null,
      error: false,
      sexo: null,
      filteredSexo: [],
      timer: true,
      showDeleteButton: false,
    };
  },
  created() {
    // this.$router.push({path: '/sumario/denuncia/formulario/denunciante?id=', query: {id: 'new'}})
    this.people = this.datos;
    // console.log("people: \n",JSON.stringify(this.people))
    if (this.datos.documento) this.dni = this.datos.documento;
    if (this.datos.sexo) this.sexo = this.datos.sexo;
  },
  computed: {
    ...mapState({
      sexoList: (state) => state.siis.sexo,
      sumario: (state) => state.sumario,
    }),
    dniPeople: {
      get() {
        // console.log("sipe", this.$store.state.sipe)
        return this.dni;
      },
      set(value) {
        this.error = false;
        this.isSearching = false;
        return (this.dni = value);
      },
    },

    // fullName: {
    //   get () { return `${this.people.apellido}, ${this.people.nombre}` }
    // },
    denunciantes: {
      get() {
        return this.$store.getters["sumario/denunciante/denuncianteTodos"];
      },
    },

    dataSexo: {
      get() {
        return this.sexo;
      },
      set(v) {
        return (this.sexo = v);
      },
    },
  },
  methods: {
    emitNavigateEvent() {
      // Emite un evento personalizado que el componente padre puede escuchar
      this.$emit("navigateToDenunciante", { id: "new" });
    },

    ManageActions(action="", item, event){
      if (action === "create"){
         this.emitNavigateEvent();
         this.$emit("disableAfectation");
      }else if (action === "edit"){
        this.$router.push({
          path: "/actuaciones/new/" + this.actuacionType + "/initial/denunciante",
          query: { id: item.arrId },
        });
        this.$emit("disableAfectation");
      }else if(action === "delete"){
        this.$emit("disableAfectation");
        this.deleteConfirmation(item.doc.arrId, event)
      }
    },
    //Esta funcion se hizo para que no aparescan undefined en la lista de Denunciantes
    nombreYapellidos(item) {
      let NombreCompleto = "Datos Incompletos";
      if (item.ordenPublico == true) {
        NombreCompleto = "Orden público";
        return NombreCompleto;
      } else {
        NombreCompleto =
          item.doc.apellido != undefined 
            ? item.doc.apellido.toUpperCase() 
            : NombreCompleto;
        if (NombreCompleto == "Datos Incompletos") {
          NombreCompleto =
            item.doc.nombre != undefined 
              ? item.doc.nombre 
              : NombreCompleto;
        } else {
          NombreCompleto =
            item.doc.nombre != undefined
              ? NombreCompleto + ", " + item.doc.nombre
              : NombreCompleto;
        }
        return NombreCompleto;
      }
    },
    alertas() {
      // console.log('this.errors', this.errors);
      //si hay un solo dato cargado y este es ordenPublico, entonces aprobamos la validacion
      let personCondition = this.viewData.cardDenunciante.title.toLowerCase();
      if (personCondition.includes("protagonistas"))
        personCondition = "protagonista/acompañante o damnificado";
      if (this.viewData.cardDenunciante.title.includes("Víctima")) {
        personCondition = "de la " + personCondition;
      } else {
        personCondition = "del " + personCondition;
      }
      if (this.viewData.showPeoplesAlertas) {
        if (this.errors.includes("Se registraron denunciante y acusado iguales")) {
          return "Se registraron denunciante y acusado iguales";
        } else if (this.errors.includes("Se registraron denunciantes repetidos")) {
          return "Se registraron denunciantes repetidos";
        } else if (this.errors.includes("Falta DNI del denunciante y/o damnificado")) {
          return "Falta DNI del afectado";
        } else if (
          this.errors.includes("Falta teléfono del denunciante y/o damnificado")
        ) {
          return "Falta teléfono del afectado";
        } else if (
          this.errors.includes("Falta apellido del denunciante y/o damnificado")
        ) {
          return "Falta apellido del afectado";
        } else if (this.errors.includes("Falta nombre del denunciante y/o damnificado")) {
          return "Falta nombre del afectado";
        } else if (
          this.errors.includes("Falta estado civil del denunciante y/o damnificado")
        ) {
          return "Falta estado civil del afectado";
        } else if (
          this.errors.includes(
            "Falta fecha de nacimiento del denunciante y/o damnificado"
          )
        ) {
          return "Falta fecha de nacimiento del afectado";
        } else if (
          this.errors.includes("Falta domicilio del denunciante y/o damnificado")
        ) {
          return "Falta domicilio del afectado";
        } else if (this.errors.includes("Falta DNI del damnificado")) {
          return "Falta número de documento del afectado";
        } else if (this.errors.includes("Falta apellido del damnificado")) {
          return "Falta apellido del afectado";
        } else if (this.errors.includes("Falta nombre del damnificado")) {
          return "Falta nombre del afectado";
        } else if (this.errors.includes("Falta estado civil del damnificado")) {
          return "Falta estado civil del afectado";
        } else if (this.errors.includes("Falta fecha de nacimiento del damnificado")) {
          return "Falta fecha de nacimiento del afectado";
        } else if (this.errors.includes("Falta teléfono del damnificado")) {
          return "Falta teléfono del afectado";
        } else if (this.errors.includes("Al menos 1 afectado tiene un email con formato incorrecto")) {
          return "Al menos 1 afectado tiene un email con formato incorrecto";
        } else if (
          this.errors.includes(
            "Debe especificar al menos un Damnificado de tipo Orden Público"
          )
        ) {
          return "Debe especificar al menos un Damnificado de tipo Orden Público";
        } else if (this.errors.includes("Email con formato incorrecto")) {
          return "Existe al menos un email con formato incorrecto";
        } else if (this.errors.includes("Falta domicilio del damnificado")) {
          return "Falta domicilio del afectado";
        } else if (this.errors.includes("Falta email del denunciante")) {
          return "Falta email del denunciante";
        } else {
          return "ok";
        }
      } else {
        return "ok";
      }
    },
    warningAlerts() {
      // if(this.warning.includes('No especificó la profesión del denunciante y/o damnificado')){return 'No especificó la profesión del afectado'}
      if (this.warning.includes("Falta Email del afectado") && !this.errors.includes("Falta email del denunciante")) {
        return "Falta Email del afectado";
      } else {
        return "ok";
      }
    },
    inputSexo(event) {
      // console.log("inputSexo",event);
      // console.log("valor",this.dataSexo);
      let value = this.sexoList.filter(
        (item) => item.nombre.toLowerCase().search(this.dataSexo.toLowerCase()) >= 0
      );

      this.sexo = value[0];
    },
    filterSexo(event) {
      this.filteredSexo = this.sexoList.filter(
        (item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0
      );
    },
    async getPeople() {
      //verify length of DNI
      if (this.dni < 1000000) {
        this.error = "El DNI debe tener al menos 7 dígitos";
        return;
      } else if (this.dni >= 1000000000) {
        this.error = "El DNI no puede tener más de 9 dígitos";
        return;
      }

      // console.log("getPeobles")
      // console.log("getPeobles", this.dni)

      try {
        this.isSearching = true;
        this.error = false;
        this.timer = false;
        await this.$_Peoples_sipeSearch();
        this.timer = true;
      } catch (e) {
        console.log("error", e);
      }
    },
    async $_Peoples_sipeSearch() {
      // this.isSearching = true;
      let data = {};
      try {
        // this.$_Peoples_sipeMode(true)
        let sexo = this.dataSexo.nombre == "Masculino" ? "M" : "F";
        // const url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sipe/?dni=${this.dni}&sexo=${sexo}`;
        const url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/data-persona?dni=${this.dni}&sexo=${sexo}`;
        const result = await fetch(url, {
          credentials: "include",
        });

        // console.log("result", result)
        data = await result.json();
        // console.log("data", data)
      } catch (e) {
        console.error("err", e);
        this.people = {};
        this.error = "Datos incompletos o problema de conexión con SIPE.";
        this.isSearching = false;
      }

      if (data.sipe.resultadoWS.exito === true) {
        let address = data.sipe.personaInfo.domicilios.domicilio[0];
        let datas = {
          nombre: data.sipe.personaInfo.nombre,
          apellido: data.sipe.personaInfo.apellido,
          documento: this.dni,
          domicilioSIPE: `${address.calle} ${address.numero} ${address.barrio} CP: ${address.cpostal} ${address.municipio}, ${address.provincia}, ${address.pais}`,
          fechaNacimiento: data.sipe.personaInfo.fechaNacimiento,
          sexo: this.dataSexo,
        };
        this.people = datas;
        // console.log("emit update", this.people)
        this.$emit("updateValue", this.people);

        // console.log("people", this.people)
        this.isSearching = false;
        // return data.sipe.personaInfo
        /* Nombre y Apellido */
        this.dataNombre = data.sipe.personaInfo.nombre;
        this.dataApellido = data.sipe.personaInfo.apellido;
        /*  */

        /* Fecha de nacimiento */
        let dataDeNacimiento = data.sipe.personaInfo.fechaNacimiento;
        let orderedDate =
          dataDeNacimiento.slice(8, 10) +
          dataDeNacimiento.slice(4, 7) +
          "-" +
          dataDeNacimiento.slice(0, 4);
        this.fechaNacimiento = orderedDate;
        this.dataFechaNacimiento = this.fechaNacimiento;
        /*  */
      } else {
        this.people = {};
        this.error = "Persona no encontrada";
        this.isSearching = false;
      }

      return;
    },
    deleteConfirmation(arrId, event) {
      this.$emit(
        "deleteConfirmationPeoples",
        event,
        this.viewData.formDenunciaDeleteButton.whistleblowerText,
        arrId
      );
    },
    onMouseleave() {
      if (this.deleteConfirmationInProgress !== true || this.modalVisble !== true)
        this.showDeleteButton = false;
    },
    pasteInDeposition(item) {
      let paste =
        item.doc.domicilioSIPE != undefined
          ? item?.doc?.condicion?.nombre +
            " " +
            this.nombreYapellidos(item) +
            " " +
            item.doc.domicilioSIPE
          : item?.doc?.condicion?.nombre + " " + this.nombreYapellidos(item);
      // console.log('moveToDeposition', paste, 'whistleblower')
      if (item.ordenPublico) {
        paste =
          (item?.doc?.condicion?.nombre !== undefined
            ? item?.doc?.condicion?.nombre + " " + "Persona jurídica:"
            : "Persona jurídica:") +
          (" Cuil:" + item.doc.cuit) +
          (", rubro:" + item.doc.rubro) +
          (", telefono:" + item.doc.telefonoJuridico) +
          (", tiene seguro:" + (item.doc.tieneSeguro ? "SI" : "NO")) +
          (", domicilio legal:" + item.doc.domicilioJuridico);
      }
      this.$emit("moveToDeposition", paste, "whistleblower");
    },
    changeOrAddShowButton(arrId) {
      if (this.deleteConfirmationInProgress !== true || this.modalVisble !== true)
        this.showDeleteButton = arrId;
    },
    colorTag(value) {
      switch (value) {
        case "Denunciante y Damnificado":
          return "success";
        case "Denunciante":
          return "warning";
        case "Damnificado":
          return "danger";
        case "Víctima":
          return "info";
        case "Fallecido":
          return "secondary";
        case "Protagonista":
          return "primary";
        case "Acompañante":
          return "warning";
        case "Peatón":
          return "help";
        default:
          return "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  font-size: 1rem;
}
.address {
  font-size: 0.8rem;
  margin-left: 1px;
  margin-top: -15px;
}
.delete_action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.title-icon {
  display: flex;
  align-items: center;
}
.borderShadow:focus {
  box-shadow: 0 0 0 0.2rem #89d4fe;
  outline: none;
}
.gender {
  margin-top: 0px;
  margin-bottom: 5px;
}
.search > input {
  margin-right: 10px;
}
p.title > span {
  font-weight: 600;
  color: var(--primary-color);
}
ul {
  list-style: none;
  padding: 0;
}
ul li {
  list-style: none;
  margin: 0 0 5px;
  padding: auto 0;
}
.mt-10 {
  margin-top: 10px;
}
.mr-8 {
  margin-right: 8px;
}
.spinner {
  display: none;
}
.passed {
  border-bottom: solid 2px #28a745;
  /* background-color: #28a745; */
}
.warningPeoples {
  border-bottom: solid 2px #ffc107;
}
.error {
  border-bottom: solid 2px #dc3545;
}
.paste-button {
  width: 25px;
}
.help {
  background: var(--help);
}
.secondary {
  background: var(--secondary);
}
.disabled {
  pointer-events: none; 
  filter: brightness(0.9); 
}
</style>