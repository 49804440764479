import Vue from 'vue'
import axios from 'axios'

import * as AltaDenuncia from '../../data/sumario_denuncia_alta';
import sumario from './sumario.js'

// console.log(AltaDenuncia.default)
const state = () => ({
  ubicacion: {
    departamentos: AltaDenuncia.default.departamentos
  },
  estadoCivil: AltaDenuncia.default.estadocivil,
  orejas: AltaDenuncia.default.oreja,
  parpados: AltaDenuncia.default.parpados,
  menton: AltaDenuncia.default.menton,
  frente: AltaDenuncia.default.frente,
  nariz: AltaDenuncia.default.nariz,
  bigote: AltaDenuncia.default.bigote,
  labios: AltaDenuncia.default.labios,
  barba: AltaDenuncia.default.barba,
  boca: AltaDenuncia.default.boca,
  cabello: AltaDenuncia.default.cabello,
  cejas: AltaDenuncia.default.cejas,
  ojos: AltaDenuncia.default.ojos,
  cutis: AltaDenuncia.default.cutis,
  nacionalidad: AltaDenuncia.default.nacionalidad,
  // modusoperandi:  AltaDenuncia.default.modusoperandi,
  // causas:  AltaDenuncia.default.causas,
  juzgados:  AltaDenuncia.default.juzgados,
  dependencias:  AltaDenuncia.default.dependencias,
  dependencia:  null,   //ahora lo pide a la api
  instruccion: AltaDenuncia.default.enum_instruccion,
  
  headGarmentList: AltaDenuncia.default.headGarmentList,
  headStyleList: AltaDenuncia.default.headStyleList,
  headColorList: AltaDenuncia.default.headColorList,
  sBodyGarmentList: AltaDenuncia.default.sBodyGarmentList,
  sBodySleevesList: AltaDenuncia.default.sBodySleevesList,
  sBodyStyleList:  AltaDenuncia.default.sBodyStyleList,
  sBodyColorList: AltaDenuncia.default.sBodyColorList,
  iBodyGarmentList: AltaDenuncia.default.iBodyGarmentList,
  iBodyStyleList: AltaDenuncia.default.iBodyStyleList,
  iBodyColorList: AltaDenuncia.default.iBodyColorList,
  feetFootwearList: AltaDenuncia.default.feetFootwearList,
  feetStyleList: AltaDenuncia.default.feetStyleList,
  feetColorList: AltaDenuncia.default.feetColorList,
  hairLengthList: AltaDenuncia.default.hairLengthList,
  hairTypeList:  AltaDenuncia.default.hairTypeList,
  hairColorList: AltaDenuncia.default.hairColorList,
  genderList: AltaDenuncia.default.genderList,
  heightList: AltaDenuncia.default.heightList,
  volumeList: AltaDenuncia.default.volumeList,
  stageOfLifeList: AltaDenuncia.default.stageOfLifeList,
  headPartList: AltaDenuncia.default.headPartList,
  headZoneList: AltaDenuncia.default.headZoneList,
  headTypeList: AltaDenuncia.default.headTypeList,
  bodyPartList: AltaDenuncia.default.bodyPartList,
  bodyZoneList: AltaDenuncia.default.bodyZoneList,
  bodyTypeList: AltaDenuncia.default.bodyTypeList,
  armPartList: AltaDenuncia.default.armPartList,
  armSideList: AltaDenuncia.default.armSideList,
  armZoneList: AltaDenuncia.default.armZoneList,
  armTypeList: AltaDenuncia.default.armTypeList,
  legPartList: AltaDenuncia.default.legPartList,
  legSideList: AltaDenuncia.default.legSideList,
  legZoneList: AltaDenuncia.default.legZoneList,
  legTypeList: AltaDenuncia.default.legTypeList,
  accessoryList: AltaDenuncia.default.accessoryList,
  partList: AltaDenuncia.default.partList,
  sexo: AltaDenuncia.default.sexo,
  exhibeDocumento: AltaDenuncia.default.exhibeDocumento,
  tieneEstudios: AltaDenuncia.default.tieneEstudios,
  yesOrNoList: AltaDenuncia.default.yesOrNoList,
  condicion: AltaDenuncia.default.condicion,
  condicionCombinada: AltaDenuncia.default.condicionCombinada,
  condicionPreliminar: AltaDenuncia.default.condicionPreliminar,
  condicionCaidaCasual: AltaDenuncia.default.condicionCaidaCasual,
  condicionUFIDenunciante: AltaDenuncia.default.condicionUFIDenunciante,
  condicionUFIAcusado: AltaDenuncia.default.condicionUFIAcusado,
  condicionUFIDenuncianteFallecido: AltaDenuncia.default.condicionUFIDenuncianteFallecido,
  condicionUFIDenuncianteParadero: AltaDenuncia.default.condicionUFIDenuncianteParadero,
  condicionUFIAcusadoParadero: AltaDenuncia.default.condicionUFIAcusadoParadero,
  condicionTentativaSuicidio: AltaDenuncia.default.condicionTentativaSuicidio,
  condicionSumarioAcusado: AltaDenuncia.default.condicionSumarioAcusado,
  condicionAcusadoDetenido: AltaDenuncia.default.condicionAcusadoDetenido,
  condicionContravencionAcusado: AltaDenuncia.default.condicionContravencionAcusado,
  objetosAllTypes: AltaDenuncia.default.objetosAllTypes,
  objetosOnlySecuestrado: AltaDenuncia.default.objetosOnlySecuestrado,

  tipoDocumento: AltaDenuncia.default.tipoDocumento,
  zoneList: AltaDenuncia.default.zoneList,
  jerarquia: AltaDenuncia.default.jerarquia,
  
  colorList: AltaDenuncia.default.colorList,
  mobilityList: AltaDenuncia.default.mobilityList,
  mobilityColorListEnabled: AltaDenuncia.default.mobilityColorListEnabled,
  typeBikeList: AltaDenuncia.default.typeBikeList,
  typeMotorcycleList: AltaDenuncia.default.typeMotorcycleList,
  typeCarList: AltaDenuncia.default.typeCarList,
  brandBikeList: AltaDenuncia.default.brandBikeList,
  brandMotorcycleList: AltaDenuncia.default.brandMotorcycleList,
  brandCarList: AltaDenuncia.default.brandCarList,
  whiteWeaponList: AltaDenuncia.default.whiteWeaponList,
  fireWeaponList: AltaDenuncia.default.fireWeaponList,
  objectWeaponList: AltaDenuncia.default.objectWeaponList,
  weaponColorList: AltaDenuncia.default.weaponColorList,
  typeOfWeaponList: AltaDenuncia.default.typeOfWeaponList,
  articulos: [],
  departamentales: [],
  categoriaTipoArchivoPDF:[],
  categoriaTipoArchivoPDFLoad:false,
  juzgados:[],
  tipoActuacion:[],
  modusoperandi:[],
  sitio:[],
  tipoMoneda:[],
  afectationCardDisabled:false,
  personsAndEffectsCardDisabled:false,
  causaCaratula:[],
  delitos:[],
  destino: [],
  destinoListLoad:false,
  modelo: [],
  modeloListLoad:false,
  marca: [],
  marcaListLoad:false,
  d1_jerarquia: [],
  d1_jerarquiaListLoad:false,
  sectorial: [],
  sectorialListLoad:false,
  tipoDeposito: [],
  tipoDepositoListLoad:false,
  categoriasDeposito: [],
  categoriasDepositoListLoad:false,
  subcategoriasDepositoListLoad:false,
  vinculatedActuationsLoad:false,
  subcategoriasDeposito: [],
  estadosEfectoDeposito: [],
  estadosSolicitudDeposito: [],
  vinculatedActuations: [],
  ufis: [],
  prosecutors: [],
  assistants: [],  
  affected: [],  
  linked: [],  
  articulosListLoad: false,
  departamentalesListLoad: false,
  tipoUsuario:null,
  sexo: [
    {id: "F", nombre: "Femenino"},
    {id: "M", nombre: "Masculino"},
  ],
  yesOrNoList:  [ 
    {id: 1, nombre: 'Sí'}, 
    {id: 0, nombre: 'No'} 
  ],
  categoriasArticulos: [],
  tiposModelosEscritos:[
    {id: "Sumario_prevencional_por_denuncia", nombre:"Sumario prevencional por denuncia"},
    {id: "Sumario_prevencional_por_oficio", nombre:"Sumario prevencional por oficio"},
    {id: "otros", nombre:"otros"},
  ],
})




// actions
const actions = {

  async setDependencias({ commit }, refresh) {
   
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state.dependenciasListLoad && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("Dependencias")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/dependencia`;
          const r = await Vue.prototype.$rest.get({url})
          // datos = r.data.data;
          datos=[];
          r.data.data.forEach(element => {
            datos.push(
              {
                "dependencia":element.Nombre,
                "tel":element.Telefonos,
                "localidad":element.Departamento,
                "id":element.DependenciaID,
                "coordenadas1":null,
                "coordenadas2":null
              })
          });
          Vue.prototype.$offline.setList("Dependencias", datos);
        }
        await commit('setData', {
          key: 'dependencia',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setDependencias>>", e)
      }
    })
  },
  async setTipoUsuario({ commit }, refresh) {
    
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state.tipoUsuarioListLoad && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("TipoUsuario")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-usuario`;

          const r = await Vue.prototype.$rest.get({url})
         
          //  datos = r.data.data;
          datos=[];
          r.data.data.forEach(element => {
            datos.push(
              {
                "tipo":element.Nombre,
                "fecha":element.FechaCreacion,
                "id":element.TipoUsuarioID,
                "padreId":element.PadreTipoUsuarioID,
              })
          });
          Vue.prototype.$offline.setList("TipoUsuario", datos);
        }
        await commit('setData', {
          key: 'tipoUsuario',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("TipoUsuario>>", e)
      }
    })
  },
  async setDepartamentales({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state.departamentalesListLoad && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("departamentales")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/departamental`;
          const r = await Vue.prototype.$rest.get({url})
          
          //datos = r.data.data;
          datos=[];
          r.data.data.forEach(element => {
            datos.push(
              {
                "departamental":element.Nombre,
                "id":element.DepartamentalID
              })
          });
          
          Vue.prototype.$offline.setList("departamentales", datos);
        }
        await commit('setData', {
          key: 'departamentales',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setDepartamentales>>", e)
      }
    })
  },
  async setArticulos({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state.articulosListLoad && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("articulos")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/articulos`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("articulos", datos);
        }
        await commit('setData', {
          key: 'articulos',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setArticulos>>", e)
      }
    })
  },

  async setJuzgados({ commit }, refresh ) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['juzgados' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("Juzgados")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/juzgado/`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("Juzgados", datos);
        }
        await commit('setData', {
          key: 'juzgados',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setJuzgados>>", e)
      }
    })
  },

  async setModusoperandi({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['modusoperandi' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("Modusoperandi")
          // console.log("setModusoperandi", r)
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        // console.log("setModusoperandi datos", refresh,  datos)
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-modus-operandi`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("Modusoperandi", datos);
        }
        await commit('setData', {
          key: 'modusoperandi',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setModusoperandi>>",  e)
      }
    })
  },

  async setSitio({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['sitio' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("Sitio")
          // console.log("setSitio", r)
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        // console.log("setSitio datos", refresh,  datos)
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/tipo-sitio`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("Sitio", datos);
        }
        await commit('setData', {
          key: 'sitio',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setSitio>>",  e)
      }
    })
  },
  


  async setCausaCaratula({ commit, rootState }, refresh) {

    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['causaCaratula' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("CausaCaratula")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-causa-caratula/`;
          let filtro = 'Causa Caratula';
          url = `${url}?search={"TipoCaratula": [{"operator": "=", "value": "${filtro}"}]}`//`${url}?TipoCaratula=${filtro}`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("CausaCaratula", datos);
        }
        // console.log('datos', datos)
        await commit('setData', {
          key: 'causaCaratula',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setCausaCaratula>>", e)
      }
    })
  },
  async setDelitos({ commit, rootState }, refresh) {

    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['delitos' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("CausaCaratula")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-causa-caratula/`;
          let filtro =  'Delito'
          url = `${url}?search={"TipoCaratula": [{"operator": "=", "value": "${filtro}"}]}`//`${url}?TipoCaratula=${filtro}`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("CausaCaratula", datos);
        }
        // console.log('datos', datos)
        await commit('setData', {
          key: 'delitos',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setCausaCaratula>>", e)
      }
    })
  },

  async setProsecutorOrAssistant({ commit }, type, refresh) {
    
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state[type.en + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList(type.en)
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/personalfiscal?search={"Jerarquia": [{"operator": "=", "value": "${type.es}"}]}`
          // let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/personalfiscal?Jerarquia=${type.es}`;
          // let filtro = rootState.sumario.formType.startsWith('ufi') ? 'Delito' : 'Causa Caratula' 
          // url = `${url}?TipoCaratula=${filtro}`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList(type.en, datos);
        }
        // console.log('type.en', type.en)
        await commit('setData', {
          key: type.en,
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setCausaCaratula>>", e)
      }
    })
  },
  async setMonedas({ commit }, type, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['tipoMoneda' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("tipoMoneda")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/tipo-moneda/`;
        
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("tipoMoneda", datos);
        }
        await commit('setData', {
          key: 'tipoMoneda',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setDestino>>", e)
      }
    })
  },
  async setAfectationCardDisabled({ commit, state }, value) {

    await commit('setData', {
      key: 'afectationCardDisabled',
      value: value
    })
  
  },
  async setPersonsAndEffectDisabled({ commit, state }, value) {
    
    await commit('setData', {
      key: 'personsAndEffectsCardDisabled',
      value: value
    })
  
  },

  async setUFIs({ commit }, refresh) {

    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['ufis' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("ufis")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/tipoufi/`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("ufis", datos);
        }
        // console.log('datos', datos)
        await commit('setData', {
          key: 'ufis',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setCausaCaratula>>", e)
      }
    })
  },

  async setJerarquia({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['d1_jerarquia' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("d1_jerarquia")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/jerarquia/`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("d1_jerarquia", datos);
        }
        await commit('setData', {
          key: 'd1_jerarquia',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setJerarquia>>", e)
      }
    })
  },


  async setDestino({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['destino' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("Destino")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/destino/`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("Destino", datos);
        }
        await commit('setData', {
          key: 'destino',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setDestino>>", e)
      }
    })
  },


  async setMarca({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['marca' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("Marcas")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/marcas/`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          // console.log('datos', datos)
          Vue.prototype.$offline.setList("Marcas", datos);
        }
        await commit('setData', {
          key: 'marca',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setMarca>>", e)
      }
    })
  },
  
  async setTipoDiligencia({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['tipoDiligencia' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("TipoDiligencia")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-diligencia/?order_by=Orden`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("TipoDiligencia", datos);
        }
        await commit('setData', {
          key: 'tipoDiligencia',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setTipoDiligencia>>", e)
      }
    })
  },

  async setTipoActuaciones({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['tipoActuacion' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("TipoActuacion")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-actuacion/?order_by=Orden`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("TipoActuacion", datos);
        }
        await commit('setData', {
          key: 'tipoActuacion',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setTipoActuaciones>>", e)
      }
    })
  },



  async setModelo({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['modelo' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("modelo")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/modelos`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("modelo", datos);
        }
        await commit('setData', {
          key: 'modelo',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setModelo>>", e)
      }
    })
  },
  async setUserList({ commit, state },listType = 'actuaciones', refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        // if (state['userList'+'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList('userList')
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos?.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/usuario?only=${listType.toLowerCase()}`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList('userList', datos);
        }
        await commit('setData', {
            key: 'userList',
            value: datos
        })
        resolve()
      } catch (e) {
        console.error("setUserList>>", e)

      }
    })
  },

  async setSectorial({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['sectorial' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("sectorial")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1){
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sectorial`;
          const r = await Vue.prototype.$rest.get({url})
          datos = r.data.data;
          Vue.prototype.$offline.setList("sectorial", datos);
        }
        await commit('setData', {
          key: 'sectorial',
          value: datos
        })
        resolve()
      } catch (e ){
        console.error("setSectorial>>", e)
      }
    })
  },

  async setCategoriasDeposito ({ commit }) {
    return new Promise(async (resolve) => {
      if (state.categoriasDepositoListLoad) return;
      let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/categorias`;
      
      
      const r = await axios.get(url, { withCredentials: true })
      // console.log("respuesta",r)
      
      commit('setData', {
        key: 'categoriasDeposito',
        value: r.data.data
      })
      resolve()
    })
  },

  async setEstadosEfectoDeposito ({ commit }) {
    return new Promise(async (resolve) => {
      // if (state.categoriasDepositoListLoad) return;
      let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/tipo-estado-efecto`;
      
      const r = await axios.get(url, { withCredentials: true })
      
      commit('setData', {
        key: 'estadosEfectoDeposito',
        value: r.data.data
      })
      resolve()
    })
  },

  async setEstadosSolicitudDeposito ({ commit }) {
    return new Promise(async (resolve) => {
      // if (state.categoriasDepositoListLoad) return;
      let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/tipo-estado-solicitud`;
      
      const r = await axios.get(url, { withCredentials: true })
      
      commit('setData', {
        key: 'estadosSolicitudDeposito',
        value: r.data.data
      })
      resolve()
    })
  },

  async setSubCategoriasDeposito({commit}){
    return new Promise(async(resolve)=>{
      if(state.subcategoriasDepositoListLoad)return
      let url =`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/sub-categorias`;
      const r= await axios.get(url, { withCredentials: true })
      // console.log("setSubCategoriasDeposito",r)
      commit('setData', {
        key: 'subcategoriasDeposito',
        value: r.data.data
      })
      resolve()
    })

  },

  async setCategoriaTipoArchivoPDF({ commit }, refresh) {
    return new Promise(async (resolve) => {
      try {
        let datos;
        if (state['categoriaTipoArchivoPDF' + 'ListLoad'] && !refresh) return;
        if (!refresh && Vue.prototype.$offline.offlineServer) {
          let r = await Vue.prototype.$offline.getList("categoriaTipoArchivoPDF")
          if (Array.isArray(r.data.data) && r.data.data.length > 0 && r.data.data[0].list) datos = r.data.data[0].list
        }
        if (!datos || refresh || datos.length < 1) {
          let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/categoriatipoarchivopdf`;
          const r = await Vue.prototype.$rest.get({ url })
          datos = r.data.data;
          Vue.prototype.$offline.setList("categoriaTipoArchivoPDF", datos);
        }
        await commit('setData', {
          key: 'categoriaTipoArchivoPDF',
          value: datos
        })
        resolve()
      } catch (e) {
        console.error("setCategoriaTipoArchivoPDF>>", e)
      }
    })
  },

  async setTipoDeposito({commit}){
    return new Promise(async(resolve)=>{
      if(state.tipoDepositoListLoad)return
      let url =`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-depositos`;
      const r= await axios.get(url, { withCredentials: true })
      // console.log(r)
      commit('setData', {
        key: 'tipoDeposito',
        value: r.data.data
      })
      resolve()
    })
  },
  
  async getImagesForGallery({ commit }, url) {
    return new Promise(async (resolve, reject) => {
      try {
        const r = await Vue.prototype.$rest.get({url: process.env.VUE_APP_BACKEND_ENVIRONMENT + url})
        let list = []
        for (let i in r.data.data){
          let awsurl = r.data.data[i].awsurl === '' ? 'deposito-publico/' : r.data.data[i].awsurl;
          list.push({
            itemImageSrc:
            `${process.env.VUE_APP_AWS_S3_URL}/${awsurl}${r.data.data[i].File_ArticuloDepositoID}-sd${r.data.data[i].Extension}`,
            thumbnailImageSrc:
              `${process.env.VUE_APP_AWS_S3_URL}/${awsurl}${r.data.data[i].File_ArticuloDepositoID}-md${r.data.data[i].Extension}`,
            itemImageSrc2:
            `${process.env.VUE_APP_AWS_S3_URL}/${awsurl}${r.data.data[i].File_ArticuloDepositoID}${r.data.data[i].Extension}`,
            thumbnailImageSrc2:
                `${process.env.VUE_APP_AWS_S3_URL}/${awsurl}${r.data.data[i].File_ArticuloDepositoID}${r.data.data[i].Extension}`,
            File_ArticuloDepositoID: r.data.data[i].File_ArticuloDepositoID,
            title: r.data.data[i].Filename,
          })
        }
        resolve(list)
      } catch (e){
        reject()

      }
    })
  },

  async setVinculatedActuations({ commit, state }, payload) {
    return new Promise(async (resolve) => {
      try {
        let newVinculatedActuations = [...state.vinculatedActuations];
        if(payload?.length === 0){
          newVinculatedActuations = [];
        } else{
          newVinculatedActuations.push(payload);
        }
        await commit('setData', {
          key: 'vinculatedActuations',
          value: newVinculatedActuations
        })
        resolve()
      } catch (e ){
        console.error("vinculatedActuations>>", e)
      }
    })
  },

  async replaceVinculatedActuations({ commit }, payload) {
    return new Promise(async (resolve) => {
      try {
        let newVinculatedActuations = [...payload];
        await commit('setData', {
          key: 'vinculatedActuations',
          value: newVinculatedActuations
        })
        resolve()
      } catch (e ){
        console.error("vinculatedActuations>>", e)
      }
    })
  },

  async deleteItemFromVinculatedActuations({ commit, state }, payload) {
    return new Promise(async (resolve) => {
      try {
        let newVinculatedActuations = [...state.vinculatedActuations];
        newVinculatedActuations = newVinculatedActuations.filter(item => item.id !== payload.id);
        await commit('setData', {
          key: 'vinculatedActuations',
          value: newVinculatedActuations
        })
        resolve()
      } catch (e ){
        console.error("vinculatedActuations>>", e)
      }
    })
  },

  async deleteImageFromGallery({ commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log('id', id);
        let options = {
          url: '/files/deposito/' + id,
          success: 'Se eliminó la imagen con éxito'
        };
        const r = await Vue.prototype.$rest.delete(options);
        resolve(r);
      } catch (e){
        reject();
      }
    })
  },
}

const getters = {
  getDependecias:(state, getters)=>{
    return state.dependencia;
  },
  getCategoriasArticulosParent: (state)=> state.categoriasArticulos.filter(item => item.parentId === false),
  getCategoriasArticulosByParent:(state)=> (catId) => {
    if (typeof catId == 'undefined') return []
    let parent = state.categoriasArticulos.filter(item => item.catID === catId)
    return state.categoriasArticulos.filter(item => item.master == parent[0].path && !item.childs)
  }
}
// mutations
const mutations = {  
  setData( state, payload){
    return new Promise(resolve => {
      state[payload.key] = payload.value;
      state[payload.key + "ListLoad"] = true;
      // console.log("set ", payload.key)
      resolve()
    })
  },


}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    sumario,
  }
}
