export const ufiIncendioConfig = [
    {
        path: "/actuaciones/new/ufi-incendio-vivienda/initial",
        pathName: "ufi-incendio-vivienda",
        titulo: "UFI - INCENDIO EN VIVIENDA",
        props: { formType: "ufi-incendio-vivienda", actuacionType: "ufi-incendio-vivienda"},
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-incendio-vivienda/initial/denunciante",
        pathName: "ufi-incendio-vivienda",
        titulo: "AFECTADOS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-incendio-vivienda/initial/momento",
        pathName: "ufi-incendio-vivienda",
        titulo: "FECHA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },    
      {
        path: "/actuaciones/new/ufi-incendio-vivienda/initial/efectos",
        pathName: "ufi-incendio-vivienda",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]