<template>
  <div>
    <Sidebar
      :visible.sync="localIsVisible"
      :showCloseIcon="true"
      class="sidebar-container"
      style="min-height: 95vh; overflow-y: auto; width: 255px"
      @hide="onSidebarHide"
      unstyled="false"
    >
      <div class="headerSidebar">
        <Button
          icon="pi pi-times"
          class="p-button-rounded p-button-text p-button-lg"
          @click="onSidebarHide"
          style="color: #4B5563; margin-right: .3em;"
        />
      </div>
      <div class="menuSidebar">
        <!-- acá se muestran los botones "top" arriba de todos los grupos -->
        <div v-for="link in nonGroupedLinks.topBtns" :key="link.pathName">
          <div v-if="link.type === 'button'">
            <Button
              @click="handleLinkClick(link)"
              class="buttons"
              style="width: 100%; margin-bottom: 15px"
            >
              <i :class="['pi', link.icon]"></i>{{ link.titulo }}
            </Button>
          </div>
          <div
            v-else-if="link.type === 'link'"
            @click="handleLinkClick(link)"
            class="routerLinksTitles"
            :style="{ 'margin-bottom': dynamicMargin }"
          >
            <div class="routerLinks">
              <i :class="['pi', link.icon]"></i>
              {{ link.titulo }}
            </div>
          </div>
        </div>
        <div v-for="group in groupedLinks" :key="group.name" class="linkGroups">
          <div class="group-title">
            <span class="group-title-text" style="color: #4B5563; display: flex; align-items: center;" @click="toggleGroup(group.name)">
              <i :class="['pi', group.icon]"></i>
              {{ group.name }}
            </span>
            <Button
              v-if="group.links.length > 0"
              :icon="group.isExpanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
              class="p-button-text p-button-sm"
              @click="toggleGroup(group.name)"
              style="margin-left: auto; color: #4B5563;"
            />
          </div>
          <div v-if="group.isExpanded && group.links.length > 0" v-for="link in group.links" :key="link.pathName">
            
                          <!-- Renderizado de submenús -->
            <template v-if="link.type === 'submenu'">
              <div class="submenu">
                <div class="submenu-header" @click="toggleSubmenu(link.pathName)" style="cursor: pointer;">
                  <span style="display: flex; align-items: center;">
                   <!-- <i :class="['pi', link.icon]"></i> -->
                    {{ link.titulo }}
                  </span>
                  <i :class="['pi', openSubmenus[link.pathName] ? 'pi-chevron-up' : 'pi-chevron-down']" style="margin-left: auto;"></i>
                </div>
                <div v-if="openSubmenus[link.pathName]" class="submenu-items">
                 <div v-for="item in link.items" :key="item.pathName" class="routerLinksTitles" @click="handleLinkClick(item)">
                  <div class="routerLinks">
                   <!-- <i :class="['pi', item.icon]"></i> -->
                    {{ item.titulo }}
                  </div>
                </div>
               </div>
              </div>
            </template>
              
            <div v-if="link.type === 'button'">
              <Button
                @click="handleLinkClick(link)"
                class="buttons"
                style="width: 100%; margin-bottom: 15px"
              >{{ link.titulo }}</Button>
            </div>
            <div
              v-else-if="link.type === 'link'"
              @click="handleLinkClick(link)"
              class="routerLinksTitles"
              :style="{ 'margin-bottom': dynamicMargin }"
            >
              <div class="routerLinks">
                {{ link.titulo }}
              </div>
            </div>
          </div>
        </div>
        
        <!-- acá se muestran los botones "bottom" debajo de todos los grupos -->
        <div v-for="link in nonGroupedLinks.bottomBtns" :key="link.pathName">
          <Button
            @click="handleLinkClick(link)"
            class="buttons"
            style="width: 100%; margin-bottom: 15px"
          >
            <i :class="['pi', link.icon]"></i>{{ link.titulo }}
          </Button>
        </div>
        
      </div>
    </Sidebar>
  </div>
</template>

<script>
import Sidebar from "primevue/sidebar";
import { sidebarLinks, groupIcons } from "./sidebarLinks";
import { mapState,mapActions } from 'vuex';

export default {
  components: {
    Sidebar,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    userType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localIsVisible: this.isVisible,
      expandedGroups: {},
      openSubmenus: []
    };
  },
  computed: {
  
    filteredLinks() {
      return sidebarLinks.filter((link) => {
        if (link.roles.length === 0) return true;
        return link.roles.includes(this.userType);
      });
    },
    uniqueGroups() {
      return [...new Set(this.filteredLinks.map((link) => link.grupo))];
    },    
    nonGroupedLinks() {
      const topBtns = this.filteredLinks.filter(link => link.grupo === 'TOP');
      const bottomBtns = this.filteredLinks.filter(link => link.grupo === 'BOTTOM');
      return { topBtns, bottomBtns };
    },
    groupedLinks() {
      return this.uniqueGroups
      .filter(group => group != 'TOP' && group != 'BOTTOM')
      .map((group) => ({
        name: group,
        icon: groupIcons[group],
        links: this.filteredLinks.filter((link) => link.grupo === group),
        isExpanded: this.expandedGroups[group] || false,
      }))
      .filter((group) => group.links.length > 0 && group.name !== '');
},
    dynamicMargin() {
      const minLength = 10;
      const baseMargin = '10px';
      for (const group of this.groupedLinks) {
        for (const link of group.links) {
          if (link.titulo.length > minLength) {
            return '20px';
          }
        }
      }
      return baseMargin;
    },
    ...mapState({
        // Mapea el estado que deseas observar del store
        formType: (state) => state.sumarioViews?.actuacion,
      }),
  },
  watch: {
    isVisible(newVal) {
      this.localIsVisible = newVal;
    },
    formType(newVal, oldVal) {
    // Acciones a realizar cuando someState cambia
      // console.log('El estado someState cambió de', oldVal, 'a', newVal);
      // Aquí puedes agregar cualquier lógica adicional que necesites
    },

  },
  methods: {
    ...mapActions("siis",["setAfectationCardDisabled","setPersonsAndEffectDisabled"]),
    onSidebarHide() {
      this.localIsVisible = false;
      this.$emit("update:isVisible", false);
    },
    // async handleLinkClick(link) {
    //   await this.$store.dispatch("sumario/resetState");
    //   if (link.action && link.action === "logout") {
    //     this.$emit("logout");
    //     return;
    //   }else{
    //     this.$router.push({
    //       path: link.path,
    //   //     // params: { pathName: link.pathName }
    //     });
    //   }
    //   this.localIsVisible = false;
    // },
    async handleLinkClick(link) {
      this.setPersonsAndEffectDisabled(false);
      this.setAfectationCardDisabled(false)
      await this.$store.dispatch("sumario/resetState");
      if (link.action && link.action === "logout") {
        this.$emit("logout");
        return;
      }
      const currentRoute = this.$router.currentRoute.path;
      if (link.path !== currentRoute) {
        this.$router.push({
          path: link.path,
          // params: { pathName: link.pathName }
        });
      }

      this.localIsVisible = false;
    },
    toggleGroup(groupName) {
      const updatedExpandedGroups = {}; // se cierran todos los grupos antes de abrir uno nuevo
      for (const group of Object.keys(this.expandedGroups)) {
        updatedExpandedGroups[group] = false;
      }
      updatedExpandedGroups[groupName] = !this.expandedGroups[groupName]; // abre solo el seleccionado
      this.expandedGroups = updatedExpandedGroups;
    },
    toggleSubmenu(subMenuName) {
      this.openSubmenus = {
      ...this.openSubmenus,
        [subMenuName]: !this.openSubmenus[subMenuName]
      }
    }
  }
}
</script>
<style>
.headerSidebar {
  width: 100%;
  height: 57px;
  margin: -2px -5px 0 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menuSidebar {
  max-height: calc(95vh - 57px);
  overflow-y: auto;
  cursor: pointer;
  padding: 7px 15px;
  margin-top: 10px;
  padding-bottom: 3.5em;
}
.sidebar-container {
  margin-top: 3.5em;
  background-color: #fff;
  width: 270px !important;
}
.routerLinks {
  color: #4B5563;
  text-decoration: none;
  display: flex; /* Uso de flexbox para alineación */
  align-items: center; /* Alineación vertical en el centro */
  justify-content: flex-start; /* Alineación horizontal a la izquierda */
  border-radius: 5px; /* Bordes redondeados */
  box-sizing: border-box; /* Incluye padding y border en el cálculo de la altura */
  padding: 12px 14px; /* Ajusta el padding para la altura total deseada */
  height: 35px; /* Altura total, incluyendo padding */
}

.routerLinks:hover {
  background-color: #4B5563; /* Color de fondo al pasar el ratón */
  color: rgba(255, 255, 255, 0.8);
  /* No es necesario cambiar la altura o el padding para el hover */

}

.routerLinksTitles {
  margin-top: 10px;
}
.routerLinksTitles:hover {
  margin: 10px 0; /* Esto agregará margen arriba y abajo al hacer hover */
}

.menuSidebar i.pi {
  font-size: 20px;
  margin-right: 10px;
}
.buttons {
  color: #4B5563;
  background: #00000000;
  list-style: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%; /* Se asume que este estilo no necesita !important porque no está siendo sobrescrito */
  margin-bottom: 15px;
  border: none;
  padding: 8px 16px 8px 9px;
}

.buttons:enabled:hover {
  background-color: #343a40 !important; /* Se asegura que el color de fondo al hacer hover tenga prioridad */
  border-color: rgba(
    255,
    255,
    255,
    0.2
  ) !important; /* Se cambia el color del borde al hacer hover */
}

.buttons:focus {
  outline: none;
  box-shadow: none;
  /* border: none; */
}

/* .p-button:enabled:hover{
  background-color: #343a40;
  border-color: rgba(255,255,255,.2);
} */

.p-button {
  outline: none;
  box-shadow: none;
}

.p-button:focus {
  border: none;
}

.group-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 10px;
}

.submenu {
  margin-bottom: 25px;  
}

.submenu-header {
  color: #4B5563;
  text-decoration: none;
  display: flex; /* Uso de flexbox para alineación */
  align-items: center; /* Alineación vertical en el centro */
  justify-content: flex-start; /* Alineación horizontal a la izquierda */  
  margin: 10px 0 20px 15px;
}

.submenu-header i.pi {
  font-size: 0.875rem;
}

.submenu-items {
  margin-left: 1em;
}

.short-line {
  border: 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  width: 10px;
  margin-right: 5px;
}

.long-line {
  flex-grow: 1;
  border: 0;
  height: 1px;
  background: #fff;
  margin-left: 5px;
}

.group-title-text {
  position: relative;
}

.linkGroups {
  margin-bottom: 20px;
}

.p-sidebar-content {
  background-color: #F8F9FA !important; /* #F1F1F1 */
}

@media (max-width: 768px) {
  .sidebar-container {
    width: 70% !important;
  }
}

</style>