<template>
    <Calendar 
        ref="calendarRef"
        v-model="innerValue" 
        dateFormat="dd/mm/yy" 
        :manual-input="false"
        :disabled="disabled"
        :minDate="computedMinDate" 
        :maxDate="computedMaxDate" 
        showTime
        showIcon
        @input="updateValue"
        :inline="false"
    >
        <template #footer>
            <div class="p-grid p-justify-between">
                <Button 
                    label="Ahora" 
                    icon="pi pi-clock" 
                    class="p-button-text" 
                    @click="setToNow" 
                    aria-label="Ahora"
                    :disabled="disabled"
                />
                <Button 
                    label="Cerrar" 
                    icon="pi pi-times" 
                    class="p-button-text" 
                    @click="closeCalendar"
                    aria-label="Cerrar" 
                />
            </div>
        </template>
    </Calendar>
</template>

<script>
export default {
    name: 'CustomCalendarV2',

    props: {
        value: {
            type: [Date, String],
            default: null
        },
        allowPastDates: {
            type: Boolean,
            default: false
        },
        maxPastDays: {
            type: Number,
            default: 0 // Si es 0, no hay límite para fechas pasadas (si `allowPastDates` es `true`).
        },
        minDate: {
            type: [Date, String, Number],
            default: null
        },
        maxDate: {
            type: [Date, String, Number],
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            innerValue: this.parseDate(this.value)
        };
    },

    computed: {
        computedMinDate() {
            if (!this.allowPastDates) return new Date(); // Solo permite la fecha actual
            if (this.maxPastDays > 0) {
                return new Date(Date.now() - this.maxPastDays * 24 * 60 * 60 * 1000);
            }
            return this.parseDate(this.minDate) || undefined;
        },

        computedMaxDate() {
            return new Date();
        }
    },

    watch: {
        value(newVal) {
            const parsedDate = this.parseDate(newVal);
            if (parsedDate?.getTime() !== this.innerValue?.getTime()) {
                this.innerValue = parsedDate;
            }
        }
    },

    methods: {
        updateValue(newValue) {
            if (this.disabled) return; // Evita cambios si está deshabilitado

            this.innerValue = newValue;
            this.$emit('input', this.formatDate(newValue));
        },

        setToNow() {
            if (this.disabled) return;
            
            const now = new Date();
            this.innerValue = now;
            this.$emit('input', this.formatDate(now));
        },
        closeCalendar() {
            this.$refs.calendarRef.overlayVisible = false;
        },

        parseDate(dateString) {
            if (!dateString) return null;
            if (dateString instanceof Date) return dateString;

            try {
                const [datePart, timePart] = dateString.split(', ');
                const [day, month, year] = datePart.split('/').map(Number);
                const [hours, minutes, seconds] = timePart
                    ? timePart.split(':').map(Number)
                    : [0, 0, 0];

                if (isNaN(day) || isNaN(month) || isNaN(year)) return null;

                return new Date(year, month - 1, day, hours, minutes, seconds);
            } catch (error) {
                console.error("Error parsing date:", dateString, error);
                return null;
            }
        },

        formatDate(date) {
            if (!date) return '';
            return date.toLocaleString("es-AR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false
            });
        }
    }
};
</script>

<style scoped>
.p-grow-1 {
    flex-grow: 1;
}
</style>
