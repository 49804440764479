<template>
  <div class="page">
    <div>
      <br>
      <div class="feature-intro">
        <h1>Editando Contraseña</h1>
        <p>Modifique los datos que desea EDITAR</p>
        <ProgressSpinner v-if="loader" class="{'spinner':true}"  
          style="width:50px;height:50px" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s"/>        
      </div>
      <div class="py-2">
        <label for="contrasenaAnterior.value" class="block">Contraseña Anterior</label>
        <Password
          :feedback="false"
          toggleMask
          id="contrasenaAnterior"
          v-model="contrasenaAnterior.value"
          test-id="contrasenaAnterior"
          @keyup.enter="save()"
          placeholder="Contraseña Anterior"
          class="p-fluid p-input w-full"
          :class="{ 'p-invalid': contrasenaAnterior.error, 'py-2': true }"
        />
        <div v-show="contrasenaAnterior.error" class="text-red-600 text-sm">
          {{contrasenaAnterior.mensaje}}
        </div>
      </div>
      <div class="py-2">
        <label for="nuevaContrasena.value" class="block">Nueva Contraseña</label>
        <Password
          :feedback="false"
          toggleMask
          id="nuevaContrasena"
          v-model="nuevaContrasena.value"
          test-id="nuevaContrasena"
          @keyup.enter="save()"
          placeholder="Nueva Contraseña"
          class="p-fluid p-input w-full"
          :class="{ 'p-invalid': nuevaContrasena.error, 'py-2': true }"
        />
        <div v-show="nuevaContrasena.error" class="text-red-600 text-sm">
          {{nuevaContrasena.mensaje}}
        </div>        
      </div>
      <div class="py-2">
        <label for="repitaNuevaContrasena.value" class="block">Repita Nueva Contraseña</label>
        <Password
          :feedback="false"
          toggleMask
          id="repitaNuevaContrasena"
          v-model="repitaNuevaContrasena.value"
          test-id="repitaNuevaContrasena"
          @keyup.enter="save()"
          placeholder="Repita Nueva Contraseña"
          class="p-fluid p-input w-full"
          :class="{ 'p-invalid': repitaNuevaContrasena.error, 'py-2': true }"
        />
        <div v-show="repitaNuevaContrasena.error" class="text-red-600 text-sm">
          {{repitaNuevaContrasena.mensaje}}
        </div>
      </div>

      <div class="mt-4 flex justify-content-between p-mt-6">
        <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text p-button-secondary"
              @click="back()"
              test-id="cancelButton"
            />
        <Button
              label="Guardar"
              icon="pi pi-check"
              @click="save()"
              test-id="edit-boton-guardar"
            />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Password from 'primevue/password';

export default {
  // name: "Contrasena",
  props: {
  },
  components: {
    Password,
  },
  data() {
    return {
      contrasenaAnterior: {
        value:'',
        error:false,
        mensaje:'',
      },
      nuevaContrasena:  {
        value:'',
        error:false,
        mensaje:'',
      },
      repitaNuevaContrasena: {
        value:'',
        error:false,
        mensaje:'',
      },
      loader: false,
    }
  },
  methods: {
    async save(){
      if(this.validacion()){
        this.loader = true;
        try {
          let data = {
            "Contraseña Anterior": this.contrasenaAnterior.value,
            "Nueva Contraseña": this.nuevaContrasena.value.trim(),
          };

          const response = await axios.put(
            `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/contrasena/`, data,
            { withCredentials: true }
          );

          // console.log("Dice:",response.data); // Respuesta exitosa en caso de status 200
          this.loader = false;
          this.$rest.toast_open({
            message: "La Contraseña se cambio con Exito",
            position: "top-right",
            type: "success",
            duration: 3000,
          });
          await this.$store.dispatch("user/logout");
          this.back();
        } catch (error) {
          this.loader = false;
          // Aquí intentamos acceder al mensaje de error de la respuesta
          let errorMessage = error.response?.data?.error;
          console.log(errorMessage); 
          if(errorMessage == "Contraseña incorrecta"){
            this.contrasenaAnterior.error = true;
            this.contrasenaAnterior.mensaje = "La contraseña ingresada es incorrecta.";
          }else{
            this.$rest.toast_open({
            message: "No se pudo realizar el cambio. Intente mas tarde ",//+errorMessage,
            position: "top-right",
            type: "error",
            duration: 3000,
          });
          this.back();

          }
        }
      }
    },
    back(){
      this.$router.push("/");
    },      
    validacion() {
      //validando campos vacios
      if (this.contrasenaAnterior.value.trim() === '') {
        this.contrasenaAnterior.error = true;
        this.contrasenaAnterior.mensaje = 'Este campo no puede quedar vacío';
      } else {
        this.contrasenaAnterior.error = false;
        this.contrasenaAnterior.mensaje = '';
      }
      if (this.repitaNuevaContrasena.value.trim() === '') {
        this.repitaNuevaContrasena.error = true;
        this.repitaNuevaContrasena.mensaje = 'Este campo no puede quedar vacío';
      } else {
        this.repitaNuevaContrasena.error = false;
        this.repitaNuevaContrasena.mensaje = '';
      }
      if (this.nuevaContrasena.value.trim() === '') {
        this.nuevaContrasena.error = true;
        this.nuevaContrasena.mensaje = 'Este campo no puede quedar vacío';
      } else {
        this.nuevaContrasena.error = false;
        this.nuevaContrasena.mensaje = '';
      }

      // Validación para evitar espacios en las contraseñas
      if (/\s/.test(this.nuevaContrasena.value)) {
        this.nuevaContrasena.error = true;
        this.nuevaContrasena.mensaje = 'La contraseña no debe contener espacios';
        return false;
      }

      //validando Nueva contraseña
      if (this.nuevaContrasena.value.length < 8) {
        this.nuevaContrasena.error = true;
        this.nuevaContrasena.mensaje = 'La Nueva contraseña debe tener al menos 8 caracteres';
        return false;
      }

      let aux = this.nuevaContrasena.value.split('');
      let Mayusculas = "ABCDEFGHIJKLMNOPQRSTUVWXYZÑ";
      let Minusculas = "abcdefghijklmnopqrstuvwxyzñ";

      if (!aux.some(element => Mayusculas.includes(element))) {
        this.nuevaContrasena.error = true;
        this.nuevaContrasena.mensaje = 'La Nueva contraseña debe contar con al menos un carácter en Mayúscula';
        return false;
      }
      if (!aux.some(element => Minusculas.includes(element))) {
        this.nuevaContrasena.error = true;
        this.nuevaContrasena.mensaje = 'La Nueva contraseña debe contar con al menos un carácter en Minúscula';
        return false;
      }
      let Numeros = "0123456789";
      if (!aux.some(element => Numeros.includes(element))) {
        this.nuevaContrasena.error = true;
        this.nuevaContrasena.mensaje = 'La Nueva contraseña debe contar con al menos un Número';
        return false;
      }

      //Validando Repita nueva contraseña
      if (this.repitaNuevaContrasena.value.trim() !== this.nuevaContrasena.value) {
        this.nuevaContrasena.error = true;
        this.nuevaContrasena.mensaje = 'Las Contraseñas no Coinciden';
        this.repitaNuevaContrasena.error = true;
        this.repitaNuevaContrasena.mensaje = 'Las Contraseñas no Coinciden';
      }

      if (this.repitaNuevaContrasena.error || this.nuevaContrasena.error || this.contrasenaAnterior.error) {
        return false;
      }
      return true;
},

  }
}
</script>

<style scoped>
</style>

 