import functionsDataResumen from './functionsDataResumen';
import Vue from 'vue'

const viculateds = (state, data) => {
  ///////////////////// Mensaje Advertencia carga Acusado///////////////
  if(state.acusados.list.length <= 1){
    let formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true },'denuncia': { contains: true },'oficio': { contains: true },'contravencional-denuncia': { contains: true },'ufi-siniestro-vial': { contains: true },'ufi-otros': { contains: true },};
    if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationWarning.push('No especificó un acusado')
      formTypesForValidateAndInclude = {'ufi-flagrancia': { contains: true },'ufi-cavig': { contains: true },'ufi-anivi': { contains: true },
      'ufi-generica': { contains: true },'ufi-generica-oficio': { contains: true },'ufi-delitos-contra-la-propiedad': { contains: true },  
      'ufi-delitos-contra-la-propiedad-oficio': { contains: true },'ufi-estafas-y-delitos-informaticos': { contains: true }, 
      'ufi-estafas-y-delitos-informaticos-oficio': { contains: true },
    };
    if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationErr.push('Falta especificar un acusado')
    if(functionsDataResumen.validateFormType(data, {'ufi-establecer-paradero': { contains: true }})) data.errorValidationErr.push('Falta especificar un Extraviado')
    if(functionsDataResumen.validateFormType(data, {'preliminar-fuga-menor': { contains: true }})) data.errorValidationErr.push('Falta especificar un causante')
    if(functionsDataResumen.validateFormType(data, {'preliminar-tentativa-suicidio': { contains: true }})) data.errorValidationErr.push('Debe especificar al menos un Autolesionado')
  }

  ///////////////////////////////////Acusado Contravencional Por Oficio CARATULA////////////////////////////////
  try{
    let acusados = '';
    let acusadosDatosParciales = ''
    let ListaAcusados = '';
    let ListaAcusadosDatosParciales = '';
    //si los acusados bienen de la BD usamos la lista completa
    let ArreAcusados  = [...state.acusados?.list]
    //en caso que vengan del store, borramos el primer componente que solo trae residuos
    if(state.acusados.list.length >0)
    {
      if(state.acusados.list[0].doc !== undefined)
      {
        ArreAcusados.pop()
      }
    }

    for(const item of ArreAcusados)
    {
      if(item.filiacionDesconocida!= true)
      {
        if( typeof(item.doc) != "undefined")
        {
          // console.log("hola",item.doc.email)
          acusadosDatosParciales = item.doc.RolesdenunciaID != undefined && item.doc.RolesdenunciaID != '' && item.doc.RolesdenunciaID != null ? '<b>'+item.doc.RolesdenunciaID+'</b>': acusadosDatosParciales;
          acusadosDatosParciales = acusadosDatosParciales != '' && (item.doc.apellido != undefined && item.doc.apellido != '' && item.doc.apellido != null) ?  acusadosDatosParciales+'<b>'+item.doc.apellido+'</b>': acusadosDatosParciales;
          acusadosDatosParciales = acusadosDatosParciales != '' && (item.doc.nombre != undefined && item.doc.nombre != '' && item.doc.nombre != null) ? acusadosDatosParciales+ ", " : acusadosDatosParciales //Esto agrega ", " solo en caso de ser nescesario
          acusadosDatosParciales = item.doc.nombre != undefined && item.doc.nombre != '' && item.doc.nombre != null?  acusadosDatosParciales+'<b>' +item.doc.nombre+'</b>' : acusadosDatosParciales; 
          acusadosDatosParciales = acusadosDatosParciales != '' && (item.doc.documento != undefined && item.doc.documento != '' && item.doc.documento != null) ? acusadosDatosParciales + ", ": acusadosDatosParciales  
          acusadosDatosParciales = item.doc.documento != undefined && item.doc.documento != '' && item.doc.documento != null ? acusadosDatosParciales+'<b>DNI N° '+ item.doc.documento+'</b>': acusadosDatosParciales; 
          acusadosDatosParciales = acusadosDatosParciales != '' && (item.doc.apodo != undefined && item.doc.apodo != '' && item.doc.apodo != null) ? acusadosDatosParciales + ", ": acusadosDatosParciales            

          acusados = item.doc.RolesdenunciaID != undefined && item.doc.RolesdenunciaID != '' && item.doc.RolesdenunciaID != null ? '<b>'+item.doc.RolesdenunciaID+'</b>': acusados;
          acusados = acusados != '' ? (item.doc.apellido != undefined && item.doc.apellido != '' && item.doc.apellido != null ? acusados+ ' <b>'+item.doc.apellido+'</b>': acusados) : ' <b>'+item.doc.apellido+'</b>';
          acusados = acusados != '' && (item.doc.nombre != undefined && item.doc.nombre != '' && item.doc.nombre != null) ? acusados+ ", " : acusados //Esto agrega ", " solo en caso de ser nescesario
          acusados = item.doc.nombre != undefined && item.doc.nombre != '' && item.doc.nombre != null?  acusados+'<b>' +item.doc.nombre+'</b>' : acusados; 
          acusados = acusados != '' && (item.doc.documento != undefined && item.doc.documento != '' && item.doc.documento != null) ? acusados + ", ": acusados  
          acusados = item.doc.documento != undefined && item.doc.documento != '' && item.doc.documento != null ? acusados+'<b>DNI N° '+ item.doc.documento+'</b>': acusados; 
          acusados = acusados != '' && (item.doc.apodo != undefined && item.doc.apodo != '' && item.doc.apodo != null) ? acusados + ", ": acusados  
          acusados = item.doc.apodo != undefined && item.doc.apodo != '' && item.doc.apodo != null ? acusados + 'Apodado como: '+ item.doc.apodo :acusados;
          acusados = item.doc.nacionalidad != undefined ? acusados + ', de nacionalidad '+item.doc.nacionalidad.nombre : acusados;
          acusados = item.doc.estadoCivil != undefined && item.doc.estadoCivil != null && item.doc.estadoCivil != '' ? acusados+ ', estado civil '+item.doc.estadoCivil.nombre: acusados;
          acusados = item.doc.fechaNacimiento != undefined && item.doc.fechaNacimiento != null && item.doc.fechaNacimiento != '' ? acusados+', de '+functionsDataResumen.calculateAge(item.doc.fechaNacimiento)+' años de edad' : acusados;
          acusados = item.doc.instruccion != undefined ? acusados+', '+item.doc.instruccion.nombre : acusados;
          acusados = item.doc.profesion != undefined && item.doc.profesion != '' && item.doc.profesion != null ? acusados+', de profesion '+item.doc.profesion : acusados;
    
          let dom =  item.doc.domicilio != undefined && item.doc.domicilio != '' && item.doc.domicilio != null ? item.doc.domicilio  : ""
          let domSIPE = item.doc.domicilioSIPE != undefined && item.doc.domicilioSIPE != null && item.doc.domicilioSIPE != '' ? item.doc.domicilioSIPE : ""
          
          let domicilio = dom != "" && dom != undefined ? dom: domSIPE;
          acusados= domicilio != "" ? acusados+', con domicilio en '+domicilio: acusados;

          data.domicilioContraventor = domicilio          
          data.ApellidoContraventor = item.doc.apellido != undefined ? item.doc.apellido : ''
          data.NombreContraventor = item.doc.nombre != undefined ? item.doc.nombre : ''

          data.Apodo = item.doc.apodo != undefined && item.doc.apodo != '' && item.doc.apodo != null? item.doc.apodo : '';
        }
        if(typeof(item.doc) == "undefined")
        {
          acusadosDatosParciales = item.RolesdenunciaID != undefined && item.RolesdenunciaID != '' && item.RolesdenunciaID != null ? '<b>'+item.RolesdenunciaID+'</b>': acusadosDatosParciales
          acusadosDatosParciales = acusadosDatosParciales != '' && (item.Apellido != undefined && item.Apellido != '' && item.Apellido != null) ? acusadosDatosParciales+ ' <b>'+item.Apellido+'</b>': acusadosDatosParciales
          acusadosDatosParciales = acusadosDatosParciales != '' && (item.Nombre != undefined && item.Nombre != '' && item.Nombre != null) ? acusadosDatosParciales+ ", " : acusadosDatosParciales //Esto agrega ", " solo en caso de ser nescesario
          acusadosDatosParciales = item.Nombre != undefined && item.Nombre != '' && item.Nombre != null ?  acusadosDatosParciales+'<b>' +item.Nombre+'</b>' : acusadosDatosParciales; 
          acusadosDatosParciales = acusadosDatosParciales != '' && (item.Dni != undefined && item.Dni != '' && item.Dni != null) ? acusadosDatosParciales + ", ": acusadosDatosParciales  
          acusadosDatosParciales = item.Dni != undefined && item.Dni != '' && item.Dni != null ? acusadosDatosParciales+'<b>DNI N° '+ item.Dni +'</b>': acusadosDatosParciales;
          acusadosDatosParciales = acusadosDatosParciales != '' && (item.Apodo != undefined && item.Apodo != '' && item.Apodo != null) ? acusadosDatosParciales + ", ": acusadosDatosParciales  

          acusados = item.RolesdenunciaID != undefined && item.RolesdenunciaID != '' && item.RolesdenunciaID != null ? '<b>'+item.RolesdenunciaID+'</b>': acusados
          acusados = acusados != '' ?(item.Apellido != undefined && item.Apellido != '' && item.Apellido != null ? acusados+ ' <b>'+item.Apellido+'</b>': acusados) : ' <b>'+item.Apellido+'</b>';
          acusados = acusados != '' && (item.Nombre != undefined && item.Nombre != '' && item.Nombre != null) ? acusados+ ", " : acusados //Esto agrega ", " solo en caso de ser nescesario
          acusados = item.Nombre != undefined && item.Nombre != '' && item.Nombre != null ?  acusados+'<b>' +item.Nombre+'</b>' : acusados; 
          acusados = acusados != '' && (item.Dni != undefined && item.Dni != '' && item.Dni != null) ? acusados + ", ": acusados  
          acusados = item.Dni != undefined && item.Dni != '' && item.Dni != null ? acusados+'<b>DNI N° '+ item.Dni +'</b>': acusados;
          acusados = acusados != '' && (item.Apodo != undefined && item.Apodo != '' && item.Apodo != null) ? acusados + ", ": acusados  
          acusados = item.Apodo != undefined && item.Apodo != '' && item.Apodo != null?  acusados + 'Apodado como: '+item.Apodo : acusados; 
          acusados = item.Nacionalidad != undefined ? acusados+ ', de nacionalidad '+item.Nacionalidad : acusados;
          acusados = item.EstadoCivil != undefined && item.EstadoCivil == '' && item.EstadoCivil == null ? acusados+ ', estado civil '+item.EstadoCivil: acusados;
          acusados = item.FechaDeNacimiento != undefined && item.FechaDeNacimiento != null && item.FechaDeNacimiento != '' ? acusados+', de '+functionsDataResumen.calculateAge(item.FechaDeNacimiento)+' años de edad' : acusados;
          acusados = item.Instruccion != undefined ? acusados+', '+item.Instruccion : acusados;
          acusados = item.Profesion != undefined ? acusados+', de profesion '+item.Profesion : acusados;
          acusados = item.DomicilioSipe != undefined && item.DomicilioSipe != "" && item.DomicilioSipe != null ? acusados+', con domicilio en '+item.DomicilioSipe: acusados;

          data.domicilioContraventor = item.DomicilioSipe != undefined ? item.DomicilioSipe : ''         
          data.ApellidoContraventor = item.Apellido  != undefined ? item.Apellido  : ''
          data.NombreContraventor = item.Nombre  != undefined ? item.Nombre  : ''

          data.Apodo = item.Apodo != undefined && item.Apodo != '' && item.Apodo != null? item.Apodo : '';
        }
        if(ListaAcusados=='')
        {
          ListaAcusados=acusados;
          ListaAcusadosDatosParciales = acusadosDatosParciales
        }else{
          ListaAcusados=acusados;
          ListaAcusadosDatosParciales=ListaAcusadosDatosParciales+';\n '+acusadosDatosParciales;
        }
      }else
      {
        if(ListaAcusados=='')
        {
          ListaAcusados="<b>Persona de Filación desconocida</b>";
          ListaAcusadosDatosParciales="<b>Persona de Filación desconocida</b>";
        }else{
          ListaAcusados=ListaAcusados+"<b>;\n Persona de Filación desconocida</b>";
          ListaAcusadosDatosParciales=ListaAcusadosDatosParciales+"<b>;\n Persona de Filación desconocida</b>";
        }
      }
    }
    data.acusadosDatosParciales = ListaAcusadosDatosParciales.replace(/<\/b>|<b>/g, '');
    data.acusadosCaratula = ListaAcusados;
    data.acusado = ListaAcusados
    //Le quitamos las </b> y <b> al acusadosCaratula con la siguiente linea de codigo
    // El caracter "\" en el replace se usa para indicar que el caracter siguiente a el, debe no debe ser tratado como un caracter especial( un caracter especial es el que cumple una funcion dentro de remplace, EJ "*" ,"/n", etc)
    data.acusados = ListaAcusados.replace(/<\/b>|<b>/g, '')
  }
  catch(e)
  {
    console.log("El error fue: ", e)
  }
}
export default {
  viculateds
}