import functionsDataResumen from './functionsDataResumen';

const legalData = (state, data) => {
  ///////////////////////Lista de artículos/////////////////////////////////
  try {
    let formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true }};
    if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude) && state.listaArticulos.length === 0)
    { 
      data.errorValidationErr.push('No se ingresaron Articulos relacionados con la Falta')
    }else
    {
      if(Array.isArray(state?.listaArticulos)){
        if(state?.listaArticulos[0]?.nombre != undefined)
        {
          for(let item of state.listaArticulos)
          {
            data.listaArticulos = data.listaArticulos == '' ? (item.nombre) : (data.listaArticulos + ", " + item.nombre)
          }
        }else
        {
          for(let item of state.listaArticulos)
          {
            data.listaArticulos = data.listaArticulos == '' ? (item.Numeroarticulo) : (data.listaArticulos + ", " + item.Numeroarticulo)
          }
        }
      }
    }
  } catch (error) {
    console.log('error', error);
  }

  ///////////////////////////////////Número de UFI////////////////////////////////////
  try{
    var UFI = state?.ActuacionUfi?.Numero;
    let formTypesForValidateAndInclude = {'Unidades Fiscales': { startsWith: true }, 'ufi': { startsWith: true }};
    if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude))
    {
      if( UFI != undefined && UFI != null)
      {
        data.ActuacionUfi = UFI
      }else
      {      
        data.errorValidationErr.push('Falta N° de UFI')
      }
    }
  }
  catch(e){
    console.log('error', e);
  }

  ///////////////////Caratula o Delito////////////
  try {
    if(state?.listaCaratulas?.Nombre != undefined )
    {
      data.caratula = state.causaCaratula.Nombre
    }else if(state?.listaCaratulas?.length > 0){
        data.listaCaratulas = ""
        state.listaCaratulas.map(e=>{
          if(data.listaCaratulas !== ""){
            data.listaCaratulas+=", "+ e.Nombre
          }
          else{
            data.listaCaratulas=e.Nombre
          }
        })
        data.caratula = data.listaCaratulas;
    }else  if(state?.causaCaratula )
    {
      data.caratula = state.causaCaratula
    }else
    {
      let formTypesForValidateAndInclude = {'oficio': { contains: true }, 'denuncia': { contains: true }, 'preliminar': { startsWith: true }};
      if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude))
      {
        data.err++;
        data.errorValidationErr.push('Falta carátula')
      } else if(functionsDataResumen.validateFormType(data, {'ufi': { startsWith: true }})){
        data.err++;
        data.errorValidationErr.push('Falta delito')
      }
    }
  }
  catch (e){
    console.log('error', e);
  }

  ///////////////////////////////////Fiscal////////////////////////////////////
  try{
    var prosecutor = state?.prosecutor?.Denominacion;
    let formTypesForValidateAndInclude = {'Unidades Fiscales': { startsWith: true }, 'ufi': { startsWith: true }};
    if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude))
    {
      if( prosecutor != undefined && prosecutor != null)
      {
        data.prosecutor = prosecutor
      }else
      {      
        data.errorValidationErr.push('Falta un Fiscal a cargo')
      }
    }
  }
  catch(e){
    console.log('error', e);
  }

  ///////////////////////////////////Ayudante fiscal////////////////////////////////////
  try{
    var assistant = state?.assistant?.Denominacion;
    let formTypesForValidateAndInclude = {'Unidades Fiscales': { startsWith: true }, 'ufi': { startsWith: true }};
    if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude))
    {
      if( assistant != undefined && assistant != null)
      {
        data.assistant = assistant
      }else
      {      
        data.errorValidationErr.push('Falta un Ayudante de Fiscal')
      }
    }
  }
  catch(e){
    console.log('error', e);
  }

  try {
    let formTypesForValidateAndInclude = {'oficio': { contains: true }, 'denuncia': { contains: true }, 'ufi': { startsWith: true }, 'preliminar': { startsWith: true }};
    if(state?.modusOperandi)
    {
      data.modusOperandi = typeof(modusOperandi) == 'object' ?  state.modusOperandi.Nombre : state.modusOperandi
    }else
    {
      if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude))
      {        
        data.errorValidationErr.push('Falta modus operandi')
      }
    }
  }
  catch (e){
    console.log('error', e);
  }

  try {
    if(state?.causaJuzgado?.Nombre)
    {
      data.juzgado = state.causaJuzgado.Nombre;
    }else
    {
      if(state?.causaJuzgado)
      {
        //la siguiente linea, es por que cuando se ingresa por teclado un string vacio '', se guarda un objeto con un campo Nombre que es igual a ese string
        data.juzgado = typeof(state.causaJuzgado) == 'object' ?  state.causaJuzgado.Nombre : state.causaJuzgado;
      }else
      {
        if(!functionsDataResumen.validateFormType(data, {'ufi': { startsWith: true }}))
        {            
          data.errorValidationErr.push("Falta juzgado interviniente")
        }
      }
    }
  }
  catch(e){
    console.log('error', e);
  }

  if (!state.causaNumero){
    let formTypesForValidateAndInclude = {'oficio': { contains: true }, 'denuncia': { contains: true },};

    if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude))
    {      
      data.errorValidationErr.push('Falta número de sumario / causa')
    }
    formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true },'ufi': { startsWith: true }};
    if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude))
    {      
      if(functionsDataResumen.validateFormType(data, {'ufi': { startsWith: true }})){
        data.errorValidationErr.push('Falta número de LEGAJO/Extracto')
      } else{
        data.errorValidationErr.push('Falta número de Expediente')      
      }
    }
    if(functionsDataResumen.validateFormType(data, {'preliminar': { startsWith: true }}))
    {
      data.errorValidationErr.push('Falta número de ACT. Preliminar / Nº de extracto')
    }    
  } else {
    let causaNumero = state.causaNumero;
    if (typeof causaNumero === "number") {
        // Si ya es un número, se guarda directo
        data.causaNumero = causaNumero;
    } else {
        let numeroProcesado = causaNumero.split('/')[0]; 
        data.causaNumero = Number(numeroProcesado); // Convertimos a número
    } 
  }
}
export default {
  legalData
}