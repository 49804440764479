<template>
  <div class="page" >
      <SuccessAndFail
        :msjEnable.sync="msjEnable"
        :mensajeDialog="mensaje"
        :buttomAceptar="buttomAceptar"
        :icon.sync="iconModal"/>
    <div :style="[cargando ? {'display' : 'none'} : {'display' : 'block'}]" >
      <div class="grid">
        <div class="flex justify-content-between w100">
          <div v-if="!previewMode && editorOn == false" class="col-3 modelosList">
            <div
              v-if="!sumario.formType.includes('Expediente Contravenciona')"
              class="menuWord"
            >
              <h4 class="mt-1">
                Plantillas de diligencias
                <!-- <Button
                  id="refresh"
                  icon="pi pi-refresh"
                  class="p-button-sm p-button-success p-button-text p-button-rounded"
                  @click="refreshDiligencias"
                /> -->
                  <i class="pi pi-refresh ml-3" id="refresh" @click="refreshDiligencias" style="font-size: 1rem; color: rgb(104, 159, 56)"/>
              </h4>
              <div>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filterModel"
                    type="text"
                    class="w100"
                    placeholder="filtrar"
                    id="search-diligencia"
                    test-id="input-search-diligencia"
                  />
                </span>
              </div>
              <div>
                <Button
                  label="CREAR NUEVA DILIGENCIA"
                  class="btn"
                  style="width: 15rem"
                  icon="pi pi-back"
                  @click="newDiligence" />
              </div>
            </div>
            <div v-else class="menuWord">
              <h4 class="mt-1">
                Plantillas de diligencias
                <Button
                  id="refresh"
                  icon="pi pi-refresh"
                  class="p-button-sm p-button-success p-button-text p-button-rounded"
                  @click="refreshDiligencias"
                />
              </h4>
              <div class="p-input-icon-left mb-3">
                <i class="pi pi-search" />
                <InputText
                  v-model="filterModel"
                  class="w100"
                  placeholder="Buscar diligencia"
                  id="search-diligencia"
                  test-id="input-search-diligencia"
                />
              </div>
              <div>
                  <Button
                    label="CREAR NUEVA DILIGENCIA"
                    class="btn"
                    style="width: 15rem"
                    icon="pi pi-back"
                    @click="newDiligence" />
              </div>
            </div>
            <div :class="{ spinner: timer, spinnerOn: true }">
              <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="5"
                fill="#EEEEEE"
                animationDuration="1.5s"
              />
            </div>

            <div v-for="item in filteredModelsApi" v-bind:key="item.key">
              <div
                v-if="
                  filterModel == '' && !(IsFormTypeIgualA(item.TiposumarioID) ||
                    item.Nombre == 'ACTA DE INICIO CONTRAVENCIONAL POR OFICIO' ||
                    item.Nombre == 'DENUNCIA EXPEDIENTE CONTRAVENCIONAL POR DENUNCIA' ||
                    item.Nombre == 'ACTA DE INICIO LEGAJO UFI' ||
                    item.Nombre == 'ACTA DE INICIO PRELIMINAR' ||
                    item.Visible == 'false')
                "
                class="modelos"
                @click="setDiligenciasApi(false, item.Nombre, item.Leyenda, item)"
                :class="{
                  sombreado: selectedDocument == item.Nombre,
                }"
                :test-id="item.Nombre.toUpperCase()"
              >
                {{ item.Nombre.toUpperCase() }}
              </div>
            </div>

            <div v-for="item in filteredModelsApi" v-bind:key="item.key">
              <div
                v-if=" !(filterModel == '' ||
                    IsFormTypeIgualA(item.TiposumarioID) ||
                    item.Nombre == 'ACTA DE INICIO CONTRAVENCIONAL POR OFICIO' ||
                    item.Nombre == 'DENUNCIA EXPEDIENTE CONTRAVENCIONAL POR DENUNCIA'||
                    item.Nombre == 'ACTA DE INICIO LEGAJO UFI FLAGRANCIA')"
                class="modelos"
                @click="setDiligenciasApi(false, item.Nombre, item.Leyenda, item)"
                :class="{
                  sombreado: selectedDocument == item.Nombre,
                }"
              >
                {{ item.Nombre.toUpperCase() }}
              </div>
            </div>

            <div v-for="item in filteredModelsBiblioteca" v-bind:key="item.key">
              <div
                class="modelos"
                v-if="filterModel != ''"
                @click="setDiligenciaBiblioteca(item[1])"
                :class="{
                  sombreado: selectedDocument == item[1]
                }"
              >
                {{ item[1].toUpperCase() }}
              </div>
            </div>
          </div>
          <div class="right__side">
            <div class="actions flex" >
              <InputText
                v-model="newNameDiligencia"
                class="mr-0"
                id="new-name-diligencia"
                test-id="input-new-name-diligencia"
                placeholder="Escribir el nombre de la diligencia específica"
              />

              <Button
                v-if="previewMode"
                label="Guardar"
                @click="$_TemplateForm_edit"
                class="p-button-warning ml-3"
                test-id="button-guardar"
                id="button-guardar"
              />
              <Button
                v-if="!previewMode && editorOn == false && !clickRegister"
                label="Registrar"
                @click="$_TemplateForm_save(false)"
                class="btn ml-3 p-button-warning"
                test-id="button-imprimir"
                id="button-imprimir"
              />
              <Button
                v-if="editorOn == false"
                label="Volver"
                class="btn ml-3 back-button"
                icon="pi pi-back"
                @click="$router.push(`/actuaciones/view/${sumario.id}`)"
              />

              <!--Estos 2 botones se agregaron para la edicion de diligencias-->
              <div v-if="editorOn == true || clickRegister == true"
              :class="{'spinner':hideLoadingMenu, 'ml-1':true, 'md:col-1':true, 'mt-auto':true}" >
                  <ProgressSpinner style="width:50px;height:50px" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s"/>
              </div>
              <Button
                v-if="editorOn == true"
                label="Guardar"
                @click="$_TemplateForm_edit"
                class="btn ml-3 p-button-warning"
                test-id="button-imprimir"
                id="button-imprimir"
              />
              <Button
                v-if="editorOn == true"
                label="Volver"
                class="btn ml-3 back-button"
                icon="pi pi-back"
                @click="$router.go(-1)"
              />
            </div>

            <div class="col-12">
              <editor-word ref="editor" :data="data" @showPreview="showPreviewModal" :preview="previewMode" class="letter" :insert="insert"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showPreview" :class="iframeFullscreen ? 'print-page' : 'print-page-half'">
        <div class="menuprint flex justify-content-between align-items-center" v-if="iframeFullscreen">
          <h1>Vista previa</h1>
          <Button
            icon="pi pi-times"
            @click="closePreview"
            class="p-button-rounded p-button-danger p-button-outlined"
            id="button-close"
            test-id="button-close"
          />
        </div>
        <iframe src="" frameborder="0" name="print_iframe"></iframe>
              </div>
    </div>
    <div class="PagLoading" v-if="cargando">
      <div class="preloader">
            <ProgressSpinner
              fill="#EEEEEE"
              animationDuration="1.5s"
            />
      </div>
    </div>
  </div>
</template>

<script>
import EditorWord from '@/components/EditorWord/EditorWord.vue'
import oldmodels from '@/documentos/oldmodels.js'
import docLimpio from '@/documentos/limpio.js'
import { mapGetters, mapState } from 'vuex'
import tanstackFunctions from "../../mixins/tanstackFunctions.js";

export default {
  name: 'DenunciaPrint',
  components: {
    EditorWord
  },
  mixins:[ tanstackFunctions ],
  props:{
    DiligenciaID: {
      type:String,
      default: null
    },
    actuacionType: {
      type:String,
      default: null
    }
  },
  data() {
    return {
      newNameDiligencia:'',
      clickRegister:false,
      activeDiligencia: {},
      timer:true,
      selectedModel: {},
      models: {},
      filteredModels: [],
      filteredModelsBiblioteca: [],
      filteredModelsApi:[],
      biblioteca: [],
      modelos: [],
      printDoc: {},
      docu: {},
      textFilter: '',
      diligencia: '',
      diligenciaDoc: {},
      showPreview: false,
      previewMode: false,
      previewModal: false,
      template: '',
      descriptionError: null,
      selectedDocument: '',
      limpio: '',
      id: '',
      diligenciasApi:'',
      endUrl:'',
      editorOn:false,
      idDiligencia:'',
      hideLoadingMenu:true,
      cargando:false,
      iframeFullscreen: true,
      //---------Datos para el Modal SuccessAndFail------
      buttomAceptar:{buttom:true,action:false},
      iconModal:{},
      mensaje:'',
      msjEnable:false,
      //-----------Fin datos para Modal SuccessAndFail-----
      insert: (data)=>{this.$refs.editor.insertSuggestion(data)}
    }
  },
  computed: {
    // ...mapGetters('sumario/diligencia', [
    //   'getDiligenciaByArrId',
    // ]),
    ...mapGetters({
      getDiligenciaByArrId: "sumario/diligencia/getDiligenciaByArrId",
      viewData: "sumarioViews/getViewData",
    }),
    ...mapState({
      sumario: state => state.sumario,
      user: state => state.user,
      diligenciasList: state => state.siis.tipoDiligencia,
      views: (state) => state.sumarioViews,
    }),

    filterModel: {
      get (){
        return this.textFilter
      },
      set (value) {
        this.textFilter = value
        this.filterModels(value)
        this.filterModelsVarios(value)
        this.filterModelsApi(value)
      }
    },
    data: {
      get (){
        return this.$store.getters['sumario/dataResumen']
      }
    },
    selectedModelNombre: {
      get (){
        return this.selectedModel.nombre
      },
      set (value) {
        this.selectedModel = value
        this.docu.html = this.models[value.id]
        this.$refs.editor.setCode(this.docu)
      }
    }

  },
  async mounted(){
    let { params: { documento }} = this.$route ;
    const { params: { sumarioID }} = this.$route;
    if(documento === undefined && this.DiligenciaID !== null) documento = this.DiligenciaID
    this.getActuacion(sumarioID)
    this.endUrl = documento

    let biblioteca = oldmodels;
    this.biblioteca = biblioteca;
    this.id = this.DiligenciaID === null ? this.$route.path.split("/").at(-1) : this.DiligenciaID;
    switch(documento)
    {
      case "modelos",undefined:
        this.timer = false
        if(this.diligenciasList == undefined)
        {
          await this.$store.dispatch('siis/setTipoDiligencia');
        }
          try{
            this.diligenciasApi = this.$store.state.siis.tipoDiligencia
          }
          catch(e){
            console.log("error Denuncia Print created get", e.message)
            if(e.message == "Request failed with status code 401")
            {
              this.$store.dispatch('user/setLogin', false)
              this.$router.push({path: `/login`})
            }
            this.diligenciasApi = []
          }
          this.timer = true

          let models =  this.getDiligencias()

          this.modelos = models
          this.filterModel = '';
      break;

      case "actaInicioPreview":
        // al poner cargando en true, evitamos que se muestre al usuario el editor word (que es la vista a la que se recurre para mostrar el pdf)
          this.cargando = true;
          //cargamos la diligencia Acta de inicio de la Bd
          this.timer = false

          var request
          try
          {
            let currentView = this.getCurrentView();
            if(currentView?.defaultDiligenceID !== undefined){
              request = await this.getDiligenciaById(currentView.defaultDiligenceID);
            } else {
              this.$rest.toast_open({message: "Falló la carga de la plantilla de previsualización de la actuación", position: "top-right",type: "error", duration: 30000,});
              console.error('Falló la carga de la plantilla de previsualización de la actuación: Revisar switch del DenunciaPrint')
              this.$router.push('/sumario/denuncia/detalle/toUpdate'+this.sumario.id)
            }
            this.diligenciasApi = [request]
          }
          catch(e)
          {
            console.log("error", JSON.stringify (e.message))
            if(e.message == "Request failed with status code 401")
            {
              this.$store.dispatch('user/setLogin', false)
              this.$router.push({path: `/login`})
            }
            this.diligenciasApi = []
          }
          this.timer = true
          this.printDoc = docLimpio;

          this.docu = this.printDoc()

          this.$refs.editor.setCode(this.docu)
          this.setDiligenciasApi(true, this.diligenciasApi[0].Nombre,this.diligenciasApi[0].Leyenda, this.diligenciasApi[0])

      break;
      //el case es actaInicio pero reutilizamos el mismo para Denuncia, solo cambia el tipo de contravencion
      case "actuacion-save":

        // al poner cargando en true, evitamos que se muestre al usuario el editor word (que es la vista a la que se recurre para mostrar el pdf)
          this.cargando = true;
          if(this.diligenciasList == undefined)
          {
            await this.$store.dispatch('siis/setTipoDiligencia');//actualizamos las plantillas de diligencias a mostrar
          }

          try
          {
            let currentView = this.getCurrentView();
            if(currentView?.defaultDiligenceID !== undefined){
              request = await this.getDiligenciaById(currentView.defaultDiligenceID);
            } else {
              this.$router.push('/sumario/denuncia/detalle/toUpdate'+this.sumario.id);
            }
            this.diligenciasApi = [request];
          }
          catch(e)
            {
             console.error("ERROR:", e)
            this.diligenciasApi = []
          }
          this.printDoc = docLimpio; //await import('../../documentos/limpio')

          this.docu = this.printDoc()

          this.$refs.editor.setCode(this.docu)
          //cargamos el documento en el editor word
          await this.setDiligenciasApi(false, this.diligenciasApi[0].Nombre,this.diligenciasApi[0].Leyenda, this.diligenciasApi[0])
          //guardamos la diligencia en la Bd
          this.$_TemplateForm_save(false)
      break;

      default:
          this.timer = true

          //Si el Id contiene la palabra edit, implica que queremos editar dicha diligencia
          if(documento?.includes("edit"))
          {
              //obtenemos el id limpio de esa diligencia
              this.id = documento.replace('edit', '')
              //editorOn se usa solo para ocultar el div que muestra las plantillas de diligencias
              this.editorOn = true;
              this.hideLoadingMenu= false;

              let respuesta = await this.getDiligencia()
              this.diligenciaDoc = respuesta.data.data[0];

              let diligenciaCodigo = respuesta.data.data[0].Contenido
              let diligenciaNombre = respuesta.data.data[0].Nombre
              this.hideLoadingMenu= true;

              let codigo
              //le quitamos el texto de cabecera que se le agrega al guardarse, para que este no aparezca 2 veces
              codigo = diligenciaCodigo.replace("&lt;p style=&quot;float:right;&quot; class=&quot;comisaria&quot;&gt;"+this.user.userdata.dependenciaHeader+"&lt;/p&gt;", '')
              this.setDiligenciasApi(false, diligenciaNombre, codigo, {TamanoSangria: respuesta.data.data[0].TamanoSangria})
          }else
          {
              this.showPreview = true;
              this.previewMode = true;
              this.cargando = true;

              //traemos la diligencia  cargada
              let respuesta = await this.getDiligencia()
              this.diligenciaDoc = respuesta.data.data[0];
              var txt = document.createElement("textarea");
              txt.innerHTML = respuesta.data.data[0].Contenido;
              let diligencia = txt.value;
              setTimeout(()=>{

                this.$refs.editor.setCode(diligencia)
                this.$refs.editor.setSangriaInt(parseInt(respuesta.data.data[0].TamanoSangria))
                this.cargando =  false;
                this.$emit('isDiligenciaLoaded', true); // Emite un evento cuando la diligencia está cargada
              }, 300)
              this.timer = false

          }
      break;

    }
  },
  methods: {

    async getDiligenciaById(TipodiligenciaID){
      return new Promise(async resolve => {
        // si la lista de diligencias ya fue cargada en el sistema la usamos
        if(this.diligenciasList != undefined)
        {
          return resolve(this.diligenciasList.find(item => item.TipodiligenciaID == TipodiligenciaID))
        }
        else// en caso de que aun no fueran cargadas (como es el caso de cuando se esta Creando una Contravencion) las cargamos
        {
          await this.$store.dispatch('siis/setTipoDiligencia');
          return resolve(this.$store.state.siis.tipoDiligencia.find(item => item.TipodiligenciaID == TipodiligenciaID))
        }
      })
    },
    async getDiligencia( refresh ){
      return new Promise(async resolve => {
        let r
        if ((this.$offline.onlineMode || refresh) && this.id?.search("offline-") < 0){
          r = await this.$rest.get({url: process.env.VUE_APP_BACKEND_ENVIRONMENT+"/diligencia/"+this.id})
        } else {
          r = await this.$offline.getItem("diligencias", {DiligenciaID: this.id})
        }
        return resolve(r);
      })

    },
    async getActuacion(sumarioid) {
      await this.$store.dispatch("sumario/setSumario", sumarioid);
    },

    IsFormTypeIgualA(actuacion){
      let actuacionActual = '';
      if(this.views?.[this.sumario.formType] !== undefined){
        actuacionActual = Object.keys(this.views[this.sumario.formType]?.actuationName)[0];
      } else{
        actuacionActual = this.sumario.formType;
      }
      const condiciones = {
        'Sumarios': 'Prevencional',
        'Ufis': 'Unidades Fiscales',
        'Preliminares': 'Preliminar',
        'Expedientes': 'Contravencional'
      };
      if (actuacion === actuacionActual || actuacion === "Todas") {
        return false;
      }
      if (condiciones[actuacion] && actuacionActual.includes(condiciones[actuacion])) {
        return false;
      }
      return true;
    },
    closePreview(){
      if (this.previewModal) {
        this.showPreview = false;
        return;
      }
      if(this.$route.query.caso!=="preview")
      {
        this.$router.push({path: `/actuaciones/view/${this.sumario.id}`})
      }
      else {
        this.showPreview = false;//cerramos la vista previa del pdf (por el caso de que el retorno sea a la misma pagina)
        let nav=navigator.userAgent.toLowerCase()
        switch(nav){
          case "firefox" :
          this.$router.go(-2)
          case "chrome":
          this.$router.go(-1)
          default :
          this.$router.go(-1)
        }
      }
    },
    filterModels (text) {
      this.filteredModels = this.modelos.filter((item) =>   item.data.documentoTipo.toLowerCase().search(text.toLowerCase()) >= 0 );
    },
    async filterModelsVarios (text) {
       this.filteredModelsBiblioteca = this.biblioteca.filter((item) =>   item[1].toLowerCase().search(text.toLowerCase()) >= 0 );
      },
    async filterModelsApi (text) {
      if ( !Array.isArray(this.diligenciasApi)) return
       this.filteredModelsApi = this.diligenciasApi.filter((item) =>   item.Nombre.toLowerCase().search(text.toLowerCase()) >= 0 );
      },
    async $_TemplateForm_save(vistaPrevia = true){
      this.hideLoadingMenu= false;
      this.clickRegister = true;
      var documento = await this.$refs.editor.docuHtml();//traemos el documento cargado)

      if( this.newNameDiligencia == ''){
        this.iconModal = {type :'pi pi-exclamation-circle', style : 'font-size: 3em; color: red;' }
        this.mensaje = 'El nombre ingresado no es valido'
        this.msjEnable = true

        this.clickRegister= false;//volvemos a habilitar el botno registrar

      }else if(this.limpio == documento || documento == '<p style="line-height: undefined; font-size: undefined; color:  undefined; text-indent:  undefined" class="" id=""></p>')
      {
        this.iconModal = {type :'pi pi-exclamation-circle', style : 'font-size: 3em; color: red;' }
        this.mensaje = "El documento que intenta guardar esta vacio"
        this.msjEnable = true

        this.clickRegister= false;//volvemos a habilitar el botno registrar

      }else{
          // e imprimimos en pantalla
          let document = this.$refs.editor.docuHtml()

          let TamanoSangria = this.$refs.editor.getSangria()
          let diligencia = {
            tipo: this.newNameDiligencia,
            creado: new Date(),
            documento:document,
            TamanoSangria,
            userId : this.user.uid
          }
          if (this.activeDiligencia.TipodiligenciaID){
            diligencia.TipodiligenciaID = this.activeDiligencia.TipodiligenciaID
          }
          await this.$store.dispatch('sumario/addDiligencia', diligencia);

          if(vistaPrevia)
          {
            await this.$refs.editor.print(false);
            //activamos la vista previa de EditorWord
            this.showPreview = true;
          } else
          {
              // redireccionamos a denunciaFormDatosGenerales
            this.$router.push('/actuaciones/view/' + this.sumario.id)
            if(this?.sumario?.id !== undefined) await this.deleteActuationFromCached(this.sumario.id);
          }
          this.sendEmailD8(diligencia.tipo);
      }
    },
    async $_TemplateForm_edit( ){
      if (window.confirm("¿Seguro que desea guardar las Modificaciones realizadas?")) {
        this.hideLoadingMenu = false;
        if( this.newNameDiligencia == ''){
          alert("El nombre ingresado no es valido")
        }else{
              let document = await this.$refs.editor.print(false);
              let TamanoSangria = this.$refs.editor.getSangria()

              let diligencia = {
                tipo: this.newNameDiligencia,
                creado: new Date(),
                documento:document,
                DiligenciaID: this.id,
                TamanoSangria,
              }
              if (this.id.search("offline-") == 0){
                diligencia = {...this.diligenciaDoc, ...diligencia}
              }

              if (this.activeDiligencia.TipodiligenciaID){
                diligencia.TipodiligenciaID = this.activeDiligencia.TipodiligenciaID
              }
              await this.$store.dispatch('sumario/editDiligencia', diligencia);
              alert("Documento Modificado con Exito")
              this.closePreview()
            }
            this.hideLoadingMenu= true;
      }
    },
    showPreviewModal(){
      this.previewModal = true;
      this.showPreview = true;

    },

    setDiligenciaBiblioteca( documentoName){

      document.getElementById("new-name-diligencia").value = documentoName//cargo el nombre del documento en el input NewName

      this.selectedDocument = documentoName;//es para el sombreado

      this.diligencia = documentoName;
      var indice=0;
      while(indice <= this.biblioteca.length && this.biblioteca[indice][1]!=documentoName)
      {
        indice++;
      }

      this.docu.html = this.biblioteca[indice][2]
      this.$refs.editor.setCode(this.docu)
    },

    async setDiligenciasApi(preview, nombre, leyenda, item)
    {
      var txt = document.createElement("textarea");
      txt.innerHTML = leyenda
      let codigo = txt.value;

      this.newNameDiligencia = nombre

      this.selectedDocument = nombre;//es para el sombreado
      this.activeDiligencia = item
      this.diligencia = nombre;

      this.printDoc = docLimpio;
      this.docu = this.printDoc()
      codigo = this.addVariables(codigo)

      this.docu.html = codigo //le quitamos las comillas al string
      this.$refs.editor.setSangriaInt(parseInt(item.TamanoSangria))
      this.$refs.editor.setCode(this.docu)// mostramos en el editor

      //El if siguiente es solo para los casos de nescesitar previsualizar la diligencia
      if(preview == true)
      {
          await this.$refs.editor.print();
          this.showPreview = true;
          this.cargando = false;
      }

    },

    addVariables(str) {
      //Ajustes de Doc
      str = str.replace('"<', '<')
      str = str.replace('>"', '>')
      str = str.replace(/1.4em;/g, 'style="line-height: 1.40em;')
      str = str.replace(/1.5;/g, 'style="line-height: 1.50em;')
      str = str.replace(/1.38;/g, 'style="line-height: 1.38em;')
      str = str.replace(';;', ';')
      str = str.replace(/style="line-height: style="line-height:/g, 'style="line-height:')

      str = str.replace(/\\/g, "");

      //Datos de la Denuncia
      str = str.replace(/@@Year@@/g, (this.data.año===undefined || this.data.año === null || this.data.año === NaN)? '' :this.data.año )
      //Reenplazamos por data.caratula, en caso de que no exista, vemos si el nuevo tipo de datao "listaCaratulas" existe y lo cargamos
      str = str.replace(/@@Caratula@@/g, (this.data.caratula === undefined || this.data.caratula === null || this.data.caratula === NaN)? '@@ListaCaratulas@@' :this.data.caratula )
      str = str.replace(/@@ListaCaratulas@@/g, (this.data.listaCaratulas === undefined || this.data.listaCaratulas === null || this.data.listaCaratulas === NaN)? '' :this.data.listaCaratulas )

      str = str.replace(/@@Numero_de_Sumario@@/g, (this.data.numeroSumario === undefined || this.data.numeroSumario === null || this.data.numeroSumario === NaN)? '' :this.data.numeroSumario )
      str = str.replace(/@@Relato@@/g, (this.data.relatoDenuncia===undefined || this.data.relatoDenuncia === null || this.data.relatoDenuncia === NaN)? '' :this.data.relatoDenuncia )
      str = str.replace(/@@Fec_de_la_Denun_2@@/g, (this.data.fechaDeExtencionCertificadoText[1]===undefined || this.data.fechaDeExtencionCertificadoText[1] === null || this.data.fechaDeExtencionCertificadoText[1] === NaN)? '' :this.data.fechaDeExtencionCertificadoText[1] )
      str = str.replace(/@@Fecha_de_la_Actuacion@@/g, (this.data.fechaDeExtencionCertificadoText[0]===undefined || this.data.fechaDeExtencionCertificadoText[0] === null || this.data.fechaDeExtencionCertificadoText[0] === NaN)? '' :this.data.fechaDeExtencionCertificadoText[0] )
      str = str.replace(/@@Hora_de_la_denuncia@@/g, (this.data.horaDeExtCertificado===undefined || this.data.horaDeExtCertificado === null || this.data.horaDeExtCertificado === NaN)? '' :this.data.horaDeExtCertificado )
      str = str.replace(/@@Juzgado_Interviniente@@/g, (this.data.juzgado===undefined || this.data.juzgado === null || this.data.juzgado === NaN)? '' :this.data.juzgado )

      str = str.replace(/@@Articulo@@/g, (this.data.listaArticulos===undefined || this.data.listaArticulos === null || this.data.listaArticulos === NaN)? '' :this.data.listaArticulos )
      str = str.replace(/@@Personal_Interviniente@@/g, (this.data.personalInterviniente===undefined || this.data.personalInterviniente === null || this.data.personalInterviniente === NaN)? '' :this.data.personalInterviniente )
      str = str.replace(/@@Hora_de_Creacion@@/g, (this.data.horaDeExtCertificado===undefined || this.data.horaDeExtCertificado === null || this.data.horaDeExtCertificado === NaN)? '' :this.data.horaDeExtCertificado )
      str = str.replace(/@@Efectos@@/g, (this.data.efectosDenunciados===undefined || this.data.efectosDenunciados === null || this.data.efectosDenunciados === NaN)? '' : this.data.efectosDenunciados)
                //Datos de Ufi
      str = str.replace(/@@Numero de Ufi@@/g, (this.data.ActuacionUfi===undefined || this.data.ActuacionUfi === null || this.data.ActuacionUfi === NaN)? '' : this.data.ActuacionUfi)
      str = str.replace(/@@Fiscal_a_cargo@@/g, (this.data.prosecutor===undefined || this.data.prosecutor === null || this.data.prosecutor === NaN)? '' : this.data.prosecutor)
      str = str.replace(/@@Ayudante_fiscal@@/g, (this.data.assistant===undefined || this.data.assistant === null || this.data.assistant === NaN)? '' : this.data.assistant)
      str = str.replace(/@@Delito@@/g, (this.data.caratula === undefined || this.data.caratula === null || this.data.caratula === NaN)? '' :this.data.caratula )
                //Datos propios de Preliminares
      str = str.replace(/@@Interviniente@@/g, (this.data.juzgado===undefined || this.data.juzgado === null || this.data.juzgado === NaN)? (
        (this.data.dependencia===undefined || this.data.dependencia === null || this.data.dependencia === NaN)? '' :this.data.dependencia ) :this.data.juzgado )

      //Datos de la Comisaria
      str = str.replace(/@@Dependencian_Nombre@@/g, (this.data.dependencia===undefined || this.data.dependencia === null || this.data.dependencia === NaN)? '' :this.data.dependencia )
      //En el caso que la actuacion se acabe de cargar, habra que traer el departamento de los datos del user
      let deptDepUser = this.determineDeptDepUser();
      let deptDependencia = deptDepUser === undefined ?  this.data.departamento  : deptDepUser
      str = str.replace(/@@Dependencia_Departamento@@/g, (deptDependencia === undefined || deptDependencia === null || deptDependencia === NaN)? '' :deptDependencia )
      str = str.replace(/@@Dependencia_Numero@@/g, (this.data.dependenciaNumero===undefined || this.data.dependenciaNumero === null || this.data.dependenciaNumero === NaN)? '' :this.data.dependenciaNumero )
      str = str.replace(/@@Domicilio_Dependencia@@/g, (this.data.dependenciaDomicilio===undefined || this.data.dependenciaDomicilio === null || this.data.dependenciaDomicilio === NaN)? '' :this.data.dependenciaDomicilio )


      //Datos del Damnificado
      str = str.replace(/@@NombreDamnificado@@/g, (this.data.nombreDenunciante===undefined || this.data.nombreDenunciante === null || this.data.nombreDenunciante === NaN)? '' :this.data.nombreDenunciante )
      str = str.replace(/@@ApellidoDamnificado@@/g, (this.data.nombreDenunciante===undefined || this.data.nombreDenunciante === null || this.data.nombreDenunciante === NaN)? '' :this.data.apellidoDenunciante )
      str = str.replace(/@@dniDamnificado@@/g, (this.data.denunciantesDni===undefined || this.data.denunciantesDni === null || this.data.denunciantesDni === NaN)? '' :this.data.denunciantesDni )
      str = str.replace(/@@EdadDamnificado@@/g, (this.data.edadDenunciante===undefined || this.data.edadDenunciante === null || this.data.edadDenunciante === NaN)? '' :this.data.edadDenunciante )
      str = str.replace(/@@NacionalidadDamnificado@@/g, (this.data.nacionalidadDenunciante===undefined || this.data.nacionalidadDenunciante === null || this.data.nacionalidadDenunciante === NaN)? '' :this.data.nacionalidadDenunciante )
      str = str.replace(/@@EstadoCivilDamnificado@@/g, (this.data.estadoCivilDenunciante===undefined || this.data.estadoCivilDenunciante === null || this.data.estadoCivilDenunciante === NaN)? '' :this.data.estadoCivilDenunciante )
      str = str.replace(/@@DomicilioDamnificado@@/g, (this.data.lugarDeRecidenciaDenunciante===undefined || this.data.lugarDeRecidenciaDenunciante === null || this.data.lugarDeRecidenciaDenunciante === NaN)? '' :this.data.lugarDeRecidenciaDenunciante )
      str = str.replace(/@@instruccionDamnificado@@/g, (this.data.instruccion===undefined || this.data.instruccion === null || this.data.instruccion === NaN) ? 'sin instruccion' :this.data.instruccion )
      str = str.replace(/@@TelefonoDamnificado@@/g, (this.data.telefonoDenunciante===undefined || this.data.telefonoDenunciante === null || this.data.telefonoDenunciante === NaN)? '' :this.data.telefonoDenunciante )
      str = str.replace(/@@ProfesionDamnificado@@/g, (this.data.profesion===undefined || this.data.profesion === null || this.data.profesion === NaN)? '' :this.data.profesion )
      str = str.replace(/@@DamnificadosText@@/g, (this.data.denunciantesCaratula===undefined || this.data.denunciantesCaratula === null || this.data.denunciantesCaratula === NaN)? '' :this.data.denunciantesCaratula )
      str = str.replace(/@@DamnificadosText2@@/g, (this.data.denunciantes===undefined || this.data.denunciantes === null || this.data.denunciantes === NaN)? '' :this.data.denunciantes )
      str = str.replace(/@@DamnificadosDatosParciales@@/g, (this.data.denuncianteDatosParciles===undefined || this.data.denuncianteDatosParciles === null || this.data.denuncianteDatosParciles === NaN)? '' :this.data.denuncianteDatosParciles )

      //Datos Del Hecho
      str = str.replace(/@@Lugar_del_Hecho@@/g, (this.data.lugarDelHecho===undefined || this.data.lugarDelHecho === null || this.data.lugarDelHecho === NaN)? '' :this.data.lugarDelHecho )
      str = str.replace(/@@Hora_del_Hecho@@/g, (this.data.horaDelHecho===undefined || this.data.horaDelHecho === null || this.data.horaDelHecho === NaN)? '' :this.data.horaDelHecho )
      str = str.replace(/@@Fecha_del_Hecho@@/g, (this.data.fechaDelHecho===undefined || this.data.fechaDelHecho === null || this.data.fechaDelHecho === NaN)? '' :this.data.fechaDelHecho )
      //Datos del/los Acusado/s
      str = str.replace(/@@Acusados@@/g, (this.data.acusados===undefined || this.data.acusados === null || this.data.acusados === NaN)? '' :this.data.acusados)
      str = str.replace(/@@AcusadosCaratula@@/g, (this.data.acusadosCaratula===undefined || this.data.acusadosCaratula === null || this.data.acusadosCaratula === NaN)? '' :this.data.acusadosCaratula)
      str = str.replace(/@@ApellidoContraventor@@/g, (this.data.ApellidoContraventor===undefined || this.data.ApellidoContraventor === null || this.data.ApellidoContraventor === NaN)? '' :this.data.ApellidoContraventor)
      str = str.replace(/@@NombreContraventor@@/g, (this.data.NombreContraventor===undefined || this.data.NombreContraventor === null || this.data.NombreContraventor === NaN)? '' :this.data.NombreContraventor)
      str = str.replace(/@@AcusadosDatosParciales@@/g, (this.data.acusadosDatosParciales===undefined || this.data.acusadosDatosParciales === null || this.data.acusadosDatosParciales === NaN)? ' ' :this.data.acusadosDatosParciales)
      str = str.replace(/@@Apodo@@/g, (this.data.Apodo===undefined || this.data.Apodo === null || this.data.Apodo === NaN)? '' :this.data.Apodo)

      str = str.replace(/@@DomicilioContraventor@@/g, (this.data.domicilioContraventor===undefined || this.data.domicilioContraventor === null || this.data.domicilioContraventor === NaN)? '' :this.data.domicilioContraventor)

      str = str.replace(/@@ObjetosSustraidos@@/g, (this.data.efectosDenunciados===undefined || this.data.efectosDenunciados === null || this.data.efectosDenunciados === NaN)? '' : this.data.efectosDenunciados)

      //este ultimo es para el texto plano del acta de inicio
      str = str.replace('|Text_flat|', this.sumario.actaInicio)

      return str;
    },
    async newDiligence(){
          this.printDoc = await import('../../documentos/limpio')
          this.docu = this.printDoc.default()

          this.$refs.editor.setCode(this.docu)

          this.limpio= await this.$refs.editor.docuHtml();//cargo limpio para chekear, en la creacion de nueva diligencia , si el documento esta vacio

          this.newNameDiligencia = '';
    },
    async refreshDiligencias(){
      this.getActuacion(sumarioID)
      await this.$store.dispatch('siis/setTipoDiligencia', true);
      this.diligenciasApi = this.$store.state.siis.tipoDiligencia
    },


    getDiligencias(){
      return [
        {
          "key": "0",
          "data": {
            "documentoTipo" : "CREAR NUEVO",
            "fecha":"",
            "estado":"Realizado",
            "acciones":"CREAR",
            "tipo": 'limpio'
          }
        },]
    },
    changeTheIframeFullscreen(newValue){
      this.iframeFullscreen = newValue;
    },
    getCurrentView(){
      let currentView = this.views?.[this.sumario.formType];
      if(currentView === undefined){
        const viewsToArray = Object.values(this.views);
        let index = viewsToArray.findIndex(item => item?.actuationName?.[this.sumario.formType] !== undefined);
        let formTypeValue = viewsToArray[index];
        currentView = this.views[formTypeValue];
      }
      return currentView;
    },
    determineDeptDepUser(){
      if(this?.user?.userdata?.dependenciaDepartamento === undefined){
        if(this?.user?.userdata?.userdata?.dependenciaDepartamento === undefined){
          return this.data.formulario;
        }  else{
          return this?.user?.userdata?.userdata?.dependenciaDepartamento;
        }
      } else{
        return this?.user?.userdata?.dependenciaDepartamento;
      }
    },
    currentDate(){
      let currentDate = new Date();
      let options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
      let formattedDate = currentDate.toLocaleDateString('es-ES', options);
      let parts = formattedDate.split(" de ");
      formattedDate = parts[0] + " de " + parts[1] + " del " + parts[2];
      return formattedDate;
    },
    getEmailBodyD8() {
      return `
        <p><u>SAN JUAN Capital</u>, ${this.currentDate()}</p>
        <p>${Object.keys(this.viewData.actuationName)[0]}</p>
        <p>S/D</p>
        <p>Objeto:  Solicitud de Evidencia Digital</p>
        <p>Me dirijo a Ud. a fin de informar que se ha generado una solicitud de evidencia digital por la ${this.user.dependencia}, ${this.user.userdata.apellido}, ${this.user.userdata.nombre} con el Nro ${this.sumario.causaNumero} de la actuación ${Object.keys(this.viewData.actuationName)[0]}, <a href="${window.location.href}">${window.location.href}</a>.</p>
      `
      ;
    },
    async sendEmailD8(diligenceType){
      if(diligenceType !== "SOLICITUD DE EVIDENCIA DIGITAL D-8") return;
      let addresseeD8 = "jeftikas@gmail.com";
      if(process.env.VUE_APP_BACKEND_ENVIRONMENT.includes('siis.policiadesanjuan.gob.ar'))
        addresseeD8 = "analiticaforensed8@policia.sanjuan.gov.ar"
      await this.$rest.sendEmail({
       sender: "siisadmin@policia.sanjuan.gob.ar", addressee: [addresseeD8],
       subject: "Solicitud de Evidencia Digital", emailBody: this.getEmailBodyD8(),
      });
    }
  },

}
</script>

<style scoped>
.grid {
  margin-top: 20px;
}
.btn {
  color: #fff;
  font-weight: 600;
  padding: 10px;
}
.inputAndBtn {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.right__side {
  height: 100%;
  min-width: 75%;
  width: 100%;/*esto se agrego para la parte del edit*/
  background-color: #fff;
}
ul {
  list-style: none;
  padding: 0;
}
ul li {
  list-style: none;
  margin: 0 0 5px;
  padding: auto 0;
}
.menuWord {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 5px;
  font-size: 15px;
  border-bottom: 3px solid #0d0d0d;
  background-color: #fff;
}
.modelosList {
  background-color: #fff;
  /* margin-top: 8px; */
  margin-right: 5px;
  padding-top: 0;
}
.modelos {
  padding: 8px 0.25rem;
  cursor: pointer;
  border-bottom: 1px solid var(--bluegray-100);
  line-height: 1.5em;
  font-size: 0.8em;
}
.oculto {
  display: none;
}
.print-page {
  z-index: 999;
  position: absolute;
  display: block;
  top: 3.5em;
  left: 0px;
  width: calc(100vw);
  height: calc(100vh);
  padding: 10px;
  background-color: #fff;
}
.print-page-half {
  margin-top: 300px;
  z-index: 999;
  position: absolute;
  display: block;
  top: 0px;
  right: 50px !important;
  left: auto !important;
  width: calc(50vw); /* Ahora ocupa el 50% del ancho de la ventana */
  height: calc(100vh);
  padding: 10px;
  background: transparent;
}
.menuprint {
  padding: 0 20px 10px 0px;
}
.print-page h1, .print-page-half h1 {
  font-size: 14px;
}
.print-page iframe, .print-page-half iframe {
  height: calc(100vh - 120px);
  width: 100%;
}
.sombreado {
  font-weight: 700;
  border-style: inset;
}
#new-name-diligencia {
  /* position: relative; */
  width: 30em;
  /* margin-right: 30em !important; */
}
.spinner {
  display: none;
}
.spinnerOn {
  text-align: center;
  margin: 1rem;
}
.actions {
  display: flex;
  /*justify-content: space-around;*/
  justify-content: flex-end;
  align-items: center;
  text-align: left;
  margin-top: 2px;
}
span {
  font-size: 18px;
}
.back-button {
  margin-left: 10px;
  margin-right: 10px;
}
.spinner{
    display: none;
}
.PagLoading{
  width: auto;
  height: auto;
  padding: auto;
  /* background-color: rgb(106, 106, 138); */
}
.preloader {
  display: flex;
  justify-content: center;
  /* padding-top: 15rem; */
}
</style>
