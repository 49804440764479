<template>
  <div>

    <div class="flex justify-content-between w100 colorFondo">
      
      <div class="w100 flex flex-column">
        <div v-if="editor" class="menuWord">

          <!-- <Button type="button" label="Insertar" class="p-button-outlined" @click="showSuggestions" />
          <TieredMenu ref="menuSuggestions" :model="suggestions" :popup="true" /> -->
          <DropdownForInserts :data="data" :notInclude="notInclude" @insert="(value) => insertSuggestion(value)"/>
            
          <Dropdown v-if="enableFontSize" v-model="fontSize" class="ml-2" :options="fontSizes" optionLabel="name"  optionValue="value" placeholder="Tamaño de tipografia" />
          <button
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ icon: true, 'is-active': editor.isActive('bold') }"
          >
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-bold"
              ></use>
            </svg>
          </button>
          <button
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ icon: true, 'is-active': editor.isActive('italic') }"
          >
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-italic"
              ></use>
            </svg>
          </button>
          <button
            @click="editor.chain().focus().toggleUnderline().run()"
            :class="{ icon: true, 'is-active': editor.isActive('underline') }"
          >
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-underline"
              ></use>
            </svg>
          </button>
          <button @click="editor.chain().focus().unsetAllMarks().run()" class="icon">
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-format-clear"
              ></use>
            </svg>
          </button>
          <button
            @click="editor.chain().focus().setTextAlign('left').run()"
            :class="{ icon: true, 'is-active': editor.isActive({ textAlign: 'left' }) }"
          >
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-align-left"
              ></use>
            </svg>
          </button>
          <button
            @click="editor.chain().focus().setTextAlign('center').run()"
            :class="{ icon: true, 'is-active': editor.isActive({ textAlign: 'center' }) }"
          >
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-align-center"
              ></use>
            </svg>
          </button>
          <button
            @click="editor.chain().focus().setTextAlign('right').run()"
            :class="{ icon: true, 'is-active': editor.isActive({ textAlign: 'right' }) }"
            test-id="buttonRight"
          >
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-align-right"
              ></use>
            </svg>
          </button>
          <button
            @click="editor.chain().focus().setTextAlign('justify').run()"
            :class="{
              icon: true,
              'is-active': editor.isActive({ textAlign: 'justify' }),
            }"
          >
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-align-justify"
              ></use>
            </svg>
          </button>
          <button @click="editor.chain().focus().undo().run()" class="icon">
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-arrow-go-back-line"
              ></use>
            </svg>
          </button>
          <button @click="editor.chain().focus().redo().run()" class="icon">
            <svg data-v-1782ed7d="" class="remix">
              <use
                data-v-1782ed7d=""
                xlink:href="/assets/remixicon.symbol.f09b1c74.svg#ri-arrow-go-forward-line"
              ></use>
            </svg>
          </button>

          <button
            @click="
              editor
                .chain()
                .focus()
                .setID('master-sangria', editor.getAttributes('paragraph'))
                .run(); setSangria()
            "
            :class="{ icon: true, 'is-active': isActivePropio('master-sangria') }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M4.929 13.207l2.121 2.121 1.414-1.414-2.12-2.121 2.12-2.121 2.829 2.828 1.414-1.414L9.88 8.257 12 6.136l2.121 2.121 1.415-1.414-2.122-2.121 2.829-2.829a1 1 0 0 1 1.414 0l4.95 4.95a1 1 0 0 1 0 1.414l-14.85 14.85a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 0-1.414l3.536-3.536z"
              />
            </svg>
          </button>
          <Button
            @click="
              editor
                .chain()
                .focus()
                .setClass('sangria', editor.getAttributes('paragraph'))
                .run()
            "
            :class="{ 'mr-3 p-button-outlined': true, 'is-active': isActivePropio('sangria') } "
            label="Sangria"
            test-id="buttonSangria" />


          <!-- <Button
            @click="zoom = 1.1"
            label="Z"
            test-id="buttonZoom" /> -->

            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M5 11h14v2H5z" />
            </svg> -->
          <!-- </button> -->
          <!-- <Button label="Previsualizar" class="ml-auto p-button-outlined buttonStyle"  @click="showPreview" ref="Previsualizar"  /> -->

          <!-- <Button   label="Guardar e imprimir" @click="$_TemplateForm_print" class="text-button" /> -->
        </div>
        <div class="gradient-div">

        </div>
        <!-- {{suggestions}} -->
        <div class="editor-page">
          <div class="zoom" :style="'transform: scale('+zoom+');'" >
            <div class="regla-parent"  test-id="reglaParent">

              <div class="regla margin-paper" ref="regla" :style="`width: ${widthEditor}mm;`">
                <Slider class="slider" v-model="sangria" :max="widthEditorPX" />
              </div>
            </div>
            <div ref="paper" class="paper">
              <!-- <Divider v-for="i in Array(20).keys()" :key="i" type="dashed" :style="styleDivider(i)"><span class="p-tag">Aproximado</span></Divider> -->
              <editor-content class="editor editorWord margin-paper" :style="`--text-indent: ${sangria}px; width: ${widthEditor}mm;`" :editor="editor" :readonly="true"/>
            </div>
          </div>
        </div>
      </div>
      <form
        method="POST"
        id="pdf"
        target="print_iframe"
        style="display: none"
        :enctype="enctype"
        :action="action_post"
      >
        <textarea
          name="codigo"
          id="codigo"
          cols="30"
          rows="10"
          v-model="allHtml"
        ></textarea>
        <textarea
          name="params"
          id="params"
          cols="30"
          rows="10"
          v-model="paramsPDF"
        ></textarea>
        <input v-if="token_local!=''" type="hidden" name="token" :value="token_local">
      </form>
    </div>
    <div class="parentMS">

      <div
        ref="masterSangria"
        class="masterSangria"
      ></div>
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";
//VueRenderer

import Divider from 'primevue/divider';
import { Editor, EditorContent, VueRenderer } from "@tiptap/vue-2";
// import {  } from '@tiptap/tiptap-commands'
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Heading from "@tiptap/extension-heading";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import EditorCommands from "./EditorCommands";
import EditorCommandsList from "./EditorCommandsList";
import Bold from "@tiptap/extension-bold";
import Underline from "@tiptap/extension-underline";

import TextStyle from "@tiptap/extension-text-style";

import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";

// import FontSize from "./ext/extension-font-size"
// import Gapcursor from '@tiptap/extension-gapcursor'
// import Highlight from '@tiptap/extension-highlight'

import Slider from 'primevue/slider';
// import { Extension } from '@tiptap/core'
import TieredMenu from 'primevue/tieredmenu';
import DropdownForInserts from '@/components/DropdownForInserts'
import Dropdown from 'primevue/dropdown';
import {
  lineHeight,
  masterClass,
  masterID,
  textIndent,
  // height,
  // border,
  // width,
  // display,
  // padding,
  // fontFamily,
  FontSize,
  color,
  // margin,
} from "./stylefunctionsEditorWord";
import Button from 'primevue/button';
import props from './mixin/props';

export default {
  mixins: [
    props,
  ],
  components: {
    EditorContent,
    Slider,
    TieredMenu,
    Divider,
    Button,
    DropdownForInserts,
    Dropdown,
  },
  props: {
    notInclude:{
      type: Array,
      default: () => []
    },
    data:{
      type: [Object, Array],
    },
  },
  data() {
    return {
      zoom: 1,
      enctype: 'text/plain',
      token_local: '',
      action_post: '',
      editor: null,
      code: "",
      allHtml: "",
      paramsPDF: "{}",
      pageSize: "Legal",
      sangria: 0,
      sangriaPrint: 0,
      toPrint:false,
      masterSangria: {},
      widthEditor: 170,
      widthEditorPX : 643,
      fontSizes: [
        {name: "Carátulas Tamaño 1", value: '1.84em' },
        {name: "Carátulas Tamaño 2", value: '1.58em' },
        {name: "Carátulas Tamaño 3", value: '1.32em' },
        {name: "Diligencias Tamaño 1", value: '1.21em' },
        {name: "Diligencias Tamaño 2", value: '1em' },
        {name: "Diligencias Tamaño 3", value: '0.87em' },
      ]
    };
  },
  
  mounted() {
    // console.log("print", this.value)
    this.widthEditorPX = this.$refs.regla.clientWidth;
    if (this.preview !== true) {
      this.editor = new Editor({
        editorProps: {
          attributes: {
            class: "master-sangria",
          },
        },
        extensions: [
          StarterKit,
          Document,
          Paragraph,
          Text,
          Bold,
          TextStyle,
          lineHeight,
          FontSize, //este es el nuevo
          Underline,
          // height,
          // width,
          TaskList,
          TaskItem,
          // border,
          // display,
          // padding,
          color,
          // margin,
          masterClass,
          masterID,
          textIndent,
          // fontFamily,

          // fontSize,
          Table.configure({
            resizable: true,
          }),
          TableRow,
          TableHeader,
          TableCell,
          Heading,
          // bordercollapse,
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
          EditorCommands.configure({
            suggestion: {
              items: (query) => {
                return [
                  {
                    title: "H1",
                    command: ({ editor, range }) => {
                      editor
                        .chain()
                        .focus()
                        .deleteRange(range)
                        .setNode("heading", { level: 1 })
                        .run();
                    },
                  },
                  {
                    title: "H2",
                    command: ({ editor, range }) => {
                      editor
                        .chain()
                        .focus()
                        .deleteRange(range)
                        .setNode("heading", { level: 2 })
                        .run();
                    },
                  },
                  {
                    title: "bold",
                    command: ({ editor, range }) => {
                      editor.chain().focus().deleteRange(range).setMark("bold").run();
                    },
                  },
                  {
                    title: "italic",
                    command: ({ editor, range }) => {
                      editor.chain().focus().deleteRange(range).setMark("italic").run();
                    },
                  },
                ]
                  .filter((item) =>
                    item.title.toLowerCase().startsWith(query.toLowerCase())
                  )
                  .slice(0, 10);
              },
              render: () => {
                let component;
                let popup;
                return {
                  onStart: (props) => {
                    component = new VueRenderer(EditorCommandsList, {
                      parent: this,
                      propsData: props,
                    });
                    popup = tippy("body", {
                      getReferenceClientRect: props.clientRect,
                      appendTo: () => document.body,
                      content: component.element,
                      showOnCreate: true,
                      interactive: true,
                      trigger: "manual",
                      placement: "bottom-start",
                    });
                  },
                  onUpdate(props) {

                    component.updateProps(props);
                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect,
                    });
                  },
                  onKeyDown(props) {
                    return component.ref?.onKeyDown(props);
                  },
                  onExit() {
                    popup[0].destroy();
                    component.destroy();
                  },
                };
              },
            },
          }),
        ],
        injectCSS: true,
        injectNonce: true,
        content: this.value,
      });
      this.editor.on('update', (props)=>{
        this.setSangria()
        // console.log("onUpdate>>>>>>", this.$el.querySelector("#master-sangria"), this.$el.querySelector("#master-sangria").offsetWidth, document.querySelector("#master-sangria"))


      })
    }
  },

  beforeDestroy() {
    this.editor?.destroy();
  },
  
  computed: {
    
    fontSize: {
      get(){
        // console.log("this.editor.getAttributes('fontSize')", this.editor.getAttributes('textStyle').fontSize)
        return this.editor.getAttributes('textStyle').fontSize;
      },
      set(v){
        // console.log("v")
        // this.editor.chain().focus().setFontSize({ fontSize: v}).run()
        this.editor.chain().focus().setFontSize(v).run()
      }
    },
    station: {
      get() {
        if(this.$store.state.user.userdata.claseDeUsuario === "UsuarioFiscal"){
          let newActuationName = this.deleteWords(this.$store.state.sumario.tiposumarioID);
          return newActuationName;
        } else {
          return this.$store.state.user.userdata.dependenciaHeader;
        }
      },
    },
    codePrint: {
      get() {
        return this.allHtml;
      },
      set(value) {
        // console.log('this.code', this.code);
        // console.log('value', value);
        // console.log('this.station', this.station);
        // console.log('this.sangria', this.sangria);
        this.allHtml =
          this.preview === true
            ? value
            : this.code.getCode(value, this.station, this.sangria);

        if (!this.toPrint){
          setTimeout(()=> {
            this.setSangria()
          }, 100)
        }
        this.toPrint = false
      },
    },
    mw: {
      get() {
        return this.mmConversionPx(220);
      },
    },
  },
  methods: {
    styleDivider(i){
      i++
      let top = 0
      let display = 'block'

      if (this.$refs.paper){

        // console.log("styleDivider", i, this.$refs.paper.clientHeight, i * 1165)
        top = i*1170
        if (i==1) top-=80
        if (this.$refs.paper.clientHeight < i * 1165) {
          display = 'none'
        }
      }
      return `position: absolute; top: ${top}px; display: ${display};`;
    },
    insertSuggestion(v){
      // console.log('insertSuggestion >>>>>',  typeof v)
      // if
      v = typeof v == 'number' ? v.toString() : v;
      this.editor.commands.insertContent(v);
      this.editor.commands.focus()
    },
    showSuggestions(event){
      this.$refs.menuSuggestions.toggle(event);
    },
    showPreview(){
      this.$emit('showPreview');
      this.toPrint = true;
      this.print()
    },
    loadVariables(caratula) {
      // console.log(caratula)
      // alert("this.editor.chain().focus() ",this.editor.chain().focus())
      const transaction = this.editor.state.tr.insertText(caratula);
      this.editor.view.dispatch(transaction);
    },

    isActivePropio(dato) {
      var atributos = this.editor.getAttributes("paragraph");
      // console.log("isActivePropio", dato, atributos)
      if (dato == "master-sangria") {
        if (atributos.masterID.masterID == dato) {
          return true;
        } else {
          return false;
        }
      }
      if (dato == "sangria") {
        if (atributos.masterClass.masterClass == dato) {
          return true;
        } else {
          return false;
        }
      }
    },
    setCode(docu) {
      var re = /\[object Object\]/g;

      if (this.preview === true) {
        this.codePrint = docu;
        setTimeout(() => {
          this.fSubmit();
        }, 100);
      } else {
        if (docu.html != undefined) {
          this.code = docu;
          
          docu.html = docu.html.replace(re, '');
          this.codePrint = docu.html;
          // console.log('docu.html>>>>',  docu.html)
          // this.codePrint = this.allHtml;

          // HTML
          const isSame = this.editor.getHTML() === docu.html;

          // JSON
          // const isSame = this.editor.getJSON().toString() === value.toString()

          if (isSame) {
            return;
          }
          // console.log('docu.html>>>> setContent',  docu.html)
          this.editor.commands.setContent(docu.html, false);
        } else {
          this.code = docu;
          const isSame = this.editor.getHTML() === docu.doc.documento;
          if (isSame) {
            return;
          }
          docu.doc.documento = docu.doc.documento.replace(re, '');
          // console.log('docu.doc.documento>>>>',  docu.doc.documento)
          this.editor.commands.setContent(docu.doc.documento, false);
        }
      }
    },
    setSangria() {
      return new Promise((resolve) => {
        if (document.querySelector("#master-sangria")) {
          this.$refs.masterSangria.innerHTML = document.querySelector("#master-sangria").innerHTML;
          this.$refs.masterSangria.setAttribute('style', document.querySelector("#master-sangria").getAttribute('style'))
          //console.log("setSangria>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", this.widthEditor, this.$refs.masterSangria.offsetWidth)
          this.sangria = this.widthEditorPX - this.$refs.masterSangria.offsetWidth;
          // this.sangriaPrint = this.widthEditor - this.$refs.masterSangria.offsetWidth;
        }
        resolve();
      });
    },
    setPageSize(pageSize) {
      return new Promise((resolve) => {
        if (pageSize) this.pageSize = pageSize;
        if (this.pageSize == 'legal'){
          this.widthEditor = 170;
        } else if (this.pageSize == 'A4'){
          this.widthEditor = 830;
        }
        resolve();
      });
    },
    async print(cod = false, pageSize) {

      // console.log("print")
      await this.setPageSize(pageSize);
      // await this.setSangria();
      return new Promise((resolve) => {
        // let seft = this;
        // console.log("PRINT");
        // ( async () => {
        if (cod != false) {
          this.codePrint = cod;
        } else {
          this.codePrint = this.editor.getHTML();
        }

        setTimeout(() => {
          this.fSubmit();
          return resolve(this.codePrint);
        }, 200);
        // })
      });
    },

    setSangriaInt(v){
      // console.log("setSanGRia", v)
      if (Number.isInteger(v)) this.sangria = v;
    },
    getSangria(){
      return parseInt(this.sangria);
    },
    docuHtml() {
      return this.editor.getHTML();
    },
    fSubmit() {
      // console.log("fSubmit>>> a imprimir", this.allHtml)

      /*
      ¡Importante!
      Si cambias left (24) y right (9)
      sera necesario recalcular los valores
      en la funcion setSangria
      */
      let config = {
        "margin-top": 12.5,
        "margin-bottom": 34.7,
        "margin-left": 35,
        "margin-right": 11,
        pageSize: "Legal",
        sangria: this.sangria,
        station: this.station
        // 'A4' = 850px; 'Legal' = 880px
      };

      this.paramsPDF = JSON.stringify(config);
      this.action_post = process.env.VUE_APP_BACKEND_ENVIRONMENT + '/print/'
      this.enctype = 'text/plain';

      if (this.$offline.offlineServer) {
        this.enctype = 'application/x-www-form-urlencoded';
        this.action_post = this.$offline.offlineServer + '/api/pdf/'
        this.token_local = this.$offline.activeJWT
      }

      setTimeout(() => {
        if(document.getElementById("pdf") != null)
        {
          // console.log("submit")
          document.getElementById("pdf").submit();
        }
        this.$emit("loading", false);
      }, 200);
    },

    mmConversionPx(value) {
      var inch = value / 25.4;
      var c_value = inch * this.conversion_getDPI()[0];
      return c_value;
    },

    conversion_getDPI() {
      var arrDPI = new Array();
      if (window.screen.deviceXDPI) {
        arrDPI[0] = window.screen.deviceXDPI;
        arrDPI[1] = window.screen.deviceYDPI;
      } else {
        var tmpNode = document.createElement("DIV");
        tmpNode.style.cssText =
          "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        arrDPI[0] = parseInt(tmpNode.offsetWidth);
        arrDPI[1] = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
      }
      return arrDPI;
    },

    pxConversionMm(value) {
      var inch = value / this.conversion_getDPI()[0];
      var c_value = inch * 25.4;
      return c_value;
    },
    deleteWords(actuationName){
      let phrasesToRemove  = ["por oficio", "oficio", "por denuncia", "denuncia"];
      let lowercaseText = actuationName.toLowerCase();
      phrasesToRemove.forEach(phrase => {
        let lowercasePhrase = phrase.toLowerCase();
        let position = lowercaseText.indexOf(lowercasePhrase);
        while (position !== -1) {
          actuationName = actuationName.substring(0, position) + actuationName.substring(position + phrase.length);
          lowercaseText = lowercaseText.substring(0, position) + lowercaseText.substring(position + phrase.length);
          position = lowercaseText.indexOf(lowercasePhrase);
        }
      });
      actuationName = actuationName.replace(/\s+/g, ' ').trim();
      return actuationName;
    }
  },
};
</script>
<style scoped vars>
.parentMS {
  font-size: 14pt;
  opacity: 0;
  position: absolute;
  top: -100px;
  position: absolute;
  font-family: "Times New Roman", Times, serif;
  color: red;

}

/deep/ .ProseMirror {
  font-size: 14pt;
  font-family: "Times New Roman", Times, serif;
}
/deep/ .ProseMirror * {
  font-family: "Times New Roman", Times, serif;
  text-rendering: geometricPrecision;
}
/deep/ .ProseMirror .sangria {
  /* color: red !important; */
  text-indent: var(--text-indent) !important;
}
/deep/ .p-tieredmenu {
  width: auto !important;
  /* margin-bottom: 40px; */
}
.colorFondo {
  background: #ffffff;
  /* text-align : center; */
  justify-content: center;
}
.regla-parent {
  width: 216mm;
  background:rgb(243, 243, 243);
  border-bottom: 1px solid #0d0d0d;
  padding: 10px 0;
  margin-bottom: 20px;

}
.regla-parent .regla {

  height: 20px;
  /* background: brown; */
  display: flex;
  align-items: center;
}
.regla-parent .regla .slider {
  width: 100%;
}
.editorWord * {
  font-family: "Times New Roman", Times, serif;
}
.menuWord {
  justify-content: center;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  flex-wrap: wrap;
  padding: 0.25rem;
  /* border-bottom: 1px solid rgb(107, 107, 107); */
}
.menuWord > button.icon {
  width: 40px;
  height: 40px;
  color: #0d0d0d;
  border: none;
  background-color: transparent;
  /* border-radius: 0.4rem; */
  padding: 0.25rem;
  cursor: pointer;
  margin-right: 0.25rem;
}
.menuWord > button.icon svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.is-active {
  color: red !important;
}
.zoom {


}
.gradient-div {
  display: block;
  height: 5px;
  width: 100%;

  background: linear-gradient(to bottom, rgb(184, 184, 184), rgb(243, 243, 243));

}
.editor-page {
  position: relative;
  overflow: auto;
  padding: 10px 0 20px;
  display: flex;
  justify-content: center;
  /* border: 1px solid; */
  z-index: 0;
  background-color: rgb(243, 243, 243);/* var(--clrMlrRefreshCanvasBgNew, #f3f3f3);*/

}
.paper {
  position: relative;
  background: #ffffff;
  padding: 47.21px 0 20px 0px; /* aqui lleva el margen superior de la pagina */
  outline: 1px solid #ababab;
  width: 216mm;
  min-height: 1344px;

}
/*
MILIMETROS * 3,777 PX
"margin-top": 12.5,
  "margin-bottom": 34.7,
  "margin-left": 30,
  "margin-right": 11,
    */
.editor {
  background: #ffffff;

  margin-top: 0;
  margin-bottom: 130.81;
  /* max-width: 80%; */
  font-size: 20px;
}
/deep/ .ProseMirror > * {
  font-family: 'Times New Roman', Times, serif !important;
  text-rendering: geometricPrecision;
}
.margin-paper {
  margin-right: 41.47px;
  margin-left: 132.19px;
}

</style>
