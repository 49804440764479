<template>
  <div style="width: 100%;">
    <div class="flex flex-column lg:flex-row" style="display: flex; justify-content: center;">
      <template>
        <DashboardCard v-for="(card, index) in cardsData"
          :cardName="card.cardName"
          :pendings="getStatistics(card.statisticsName).pendientes"
          :expireds="getStatistics(card.statisticsName).vencidos"
          :color="card.color"
          :key="index"
        />
      </template>
    </div>
  </div>
</template>

<script>
import DashboardCard from "@/components/Dashboard/DashboardCard";

import prosecutorMixin from "./mixins/prosecutorMixin.js";
import statisticsMixin from "./mixins/statisticsMixin.js";



export default {
  name: "DashboardTopInfo",
  mixins: [prosecutorMixin, statisticsMixin],
  components: {
    DashboardCard,
  },
  props: {
    dependencia: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dependenciaID: this.$store.state.user.userdata.dependenciaID,
      usuario: this.$store.state.user,
      dependencias: this.$store.state.siis.dependencias,
      CategoriaUfiID: this.$store.state.user.userdata.CategoriaUfiID,
    };
  },
  created() {
    this.$store.dispatch("siis/setDependencias");
    const user = this.$store.state.user;
    const userID = user.userdata.userId;
    let basicUrl = process.env.VUE_APP_BACKEND_ENVIRONMENT;
    let search = [
      {
        "exp_pre_EstadoActuacion.TipoEstadoActuacionID": [
          { operator: "=", value: "En Curso" },
        ],
      },
      {
        "exp_pre_EstadoActuacion.TipoEstadoActuacionID": [
          { operator: "=", value: "Vencida" },
        ],
      },
    ];
    if (this.$rest.esTipoUsuario("Oficial Sumariante", user)) {
      search[0]["exp_pre_ActuacionUsuarioDependencia.ActuacionUsuarioDependenciaID"] = [
        { operator: "=", value: userID },
      ];
      search[1]["exp_pre_ActuacionUsuarioDependencia.ActuacionUsuarioDependenciaID"] = [
        { operator: "=", value: userID },
      ];
    }
    let paramsPendientes = `search=${JSON.stringify(search[0])}`;
    let paramsVencidos = `search=${JSON.stringify(search[1])}`;

    this.getData([
      `${basicUrl}/Actuacion/estadisticas?${paramsPendientes}`,
      `${basicUrl}/Actuacion/estadisticas?${paramsVencidos}`,
    ]);
  },
  watch: {
    dependencia(actual= "") {
      this.showProgressSpinner = true;
      this.setDependencia(actual);
    },
  },
  methods: {
    setDependencia(value) {
      let paramsPendientes = `search={"exp_pre_EstadoActuacion.TipoEstadoActuacionID":[{"operator":"=","value":"En Curso"}],"DependenciaID":[{"operator":"=","value":"${value}"}]}`;
      let paramsVencidos = `search={"exp_pre_EstadoActuacion.TipoEstadoActuacionID":[{"operator":"=","value":"Vencida"}],"DependenciaID":[{"operator":"=","value":"${value}"}]}`;
      if(!value){
        paramsPendientes = paramsPendientes.replace(`,"DependenciaID":[{"operator":"=","value":"${value}"}]}`,"}")
        paramsVencidos = paramsVencidos.replace(`,"DependenciaID":[{"operator":"=","value":"${value}"}]}`,"}")
      }
      let basicUrl = process.env.VUE_APP_BACKEND_ENVIRONMENT
      this.getData([
        `${basicUrl}/Actuacion/estadisticas?${paramsPendientes}`,
        `${basicUrl}/Actuacion/estadisticas?${paramsVencidos}`,
      ]);
    },
  }
};
</script>

<style>
</style>
