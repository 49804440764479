<template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">
      <div class="feature-intro">
        <h1>Nuevo {{ UserTypeTitle }}</h1>
        <p>Ingrese los datos del formulario</p>
      </div>
    </div>
    <ConfirmPopup group="demo" ref="ConfirmPopup" />
    <TForm
      :data="data"
      :nombres="names"
      :errors="errors"
      :mensajes="mensajes"
      :mode="mode"
      :designEdit="designEdit"
      :designNew="design"
      :url="url"
      :viewer="viewer"
      :viewerID="viewerID"
      :valuesForDefault="valuesForDefault"
      :enableEditFields="[
        'Email',
        'NombreUsuario',
        'PersonaFisicaID',
        'TipoUsuarioID',
        'DependenciaID',
        'JuzgadoID',
        'NumeroCredencial',
        'CategoriaUfiID',
      ]"
      @errorRepeatedUser="errorRepeatedUser()"
      ref="TForm"
    >

    </TForm>
    <ModalTemplate
      title="Nombre de Usuario Inválido"
      type="informativo"
      message="El nombre de usuario ya está en uso. Por favor, elige otro nombre de usuario"
      :showIcon="true"
      @confirm="$refs.ModalRefInform.close()"
      severity="danger"
      ref="ModalRefInform"
    />
  </div>
</template>

<script>
import axios from "axios";
import ModalTemplate from "../../../components/ModalTemplate.vue";

export default {
  props: {
    viewer: {
      type: Boolean,
    },
    viewerID: {
      type: String,
    },
  },
  components: {
    ModalTemplate,
  },
  async updated() {
    await this.waitForRefExists(this?.$refs?.TForm?.$refs?.CategoriaUfiID?.[0]?.$el);
    if (this?.$refs?.TForm?.$refs?.TipoUsuarioID) {
      this.observer = new MutationObserver(this.removeOrShowUFICategoryButton);
      this.observer.observe(this.$refs.TForm.$refs.TipoUsuarioID[0], {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }
  },
  beforeDestroy() {
    if (this?.observer) {
      this.observer.disconnect();
    }
  },
  mounted() {
    this.$watch(
      // Observa los cambios en el campo "Nombre de Usuario" en el formulario TForm
      () => this.$refs.TForm.values.NombreUsuario,
      (newValue, oldValue) => {
        // Si el valor cambia y es diferente del anterior, añade el evento 'blur' al campo
        if (newValue && newValue !== oldValue) {
          this.$nextTick(() => {
            this.addBlurEventListener();
          });
        }
      },
      { immediate: true } // Ejecuta inmediatamente al definir el watch
    );

    this.$watch(
      () => this.$refs.TForm.values.UserType,
      (newValue) => {
        if (newValue !== null && newValue !== undefined) this.UserTypeTitle = newValue;
        if (this.mode == "new") {
          this.$refs.TForm.values.PersonaFisicaID = "";
          this.$refs.TForm.values.TipoUsuarioID = "";
          this.$refs.TForm.values.JuzgadoID = "";
          this.$refs.TForm.values.CategoriaUfiID = "";
          this.$refs.TForm.values.DependenciaID = "";
        } else {
          this.determineUserType();
        }
        this.removeAndShowButtons();
        this.$refs.TForm.$forceUpdate();
      }
    );
  },
  provide() {
    return {
      UserTypeTitleGrandson: () => this.UserTypeTitle,
    };
  },
  data() {
    return {
      mode: "new",
      data: {
        showValidation: false,
        UserType: {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: "Usuario",
          placeholder: "Ingrese el usuario",
          dropdown: {
            optionValue: "name",
            optionLabel: "name",
            options: [
              { name: "Usuario Policial" },
              { name: "Usuario Judicial" },
              { name: "Usuario Fiscal" },
              { name: "Usuario Departamental" },
            ],
          },
        },
        Email: {
          value: "gonzalonvaldez@gmail.com",
          value2: "gonzalonvaldez2@gmail.com",
          validation: "required|max_length[50]|Email",
          placeholder: "Ingrese el email",
          type: "input",
          testId: "Email",
        },
        NombreUsuario: {
          value: "nombreusuario",
          label: "Nombre de Usuario",
          placeholder: "Ingrese el nombre de usuario ",
          value2: "otronombreusuario",
          validation: "required|max_length[50]",
          type: "input",
          testId: "NombreUsuario",
        },
        PersonaFisicaID: {
          value: "123abc",
          label: " ",
          buttonLabel: "Buscar Persona Física",
          placeholder: "PersonaFisicaID",
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "1to1",
        },
        TipoUsuarioID: {
          value: "123abc",
          label: " ",
          buttonLabel: "Buscar tipo de Usuario",
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "1to1",
        },
        DependenciaID: {
          value: "123abc",
          label: " ",
          buttonLabel: "Buscar Dependencia",
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "1to1",
        },
        DepartamentalID: {
          value: "123abc",
          label: " ",
          buttonLabel: "Buscar Departamental",
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "",
        },
        JuzgadoID: {
          value: "123abc",
          label: " ",
          buttonLabel: "Buscar Juzgado",
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "1to1",
        },
        CategoriaUfiID: {
          value: "123abc",
          label: " ",
          buttonLabel: "Buscar UFI",
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "1to1",
        },
        NumeroCredencial: {
          value: "123abc",
          label: "Número de Credencial",
          placeholder: "Ingrese número de credencial",
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "input",
          testId: "NumeroCredencial",
        },
      },
      buttons: {
        "Usuario Policial": "DependenciaID",
        "Usuario Judicial": "JuzgadoID",
        "Usuario Fiscal": "CategoriaUfiID",
        "Usuario Departamental": "DepartamentalID", 

      },
      names: {
        Email: "",
        NombreUsuario: "",
        PersonaFisicaID: "",
        TipoUsuarioID: "",
        DependenciaID: "",
        JuzgadoID: "",
        CategoriaUfiID: "",
        NumeroCredencial: false,
      },
      errors: {
        Email: false,
        NombreUsuario: false,
        PersonaFisicaID: false,
        TipoUsuarioID: false,
        DependenciaID: false,
        NumeroCredencial: false,
        JuzgadoID: false,
        CategoriaUfiID: false,
      },
      mensajes: {
        Email: "",
        NombreUsuario: "",
        PersonaFisicaID: "",
        TipoUsuarioID: "",
        DependenciaID: "",
        NumeroCredencial: "",
        JuzgadoID: "",
        CategoriaUfiID: "",
      },
      design: [
        ["UserType", "Email"],
        ["NombreUsuario"],
        ["PersonaFisicaID", "TipoUsuarioID"],
        ["DepartamentalID"],
        ["DependenciaID", "JuzgadoID"],
        ["CategoriaUfiID"],
        ["NumeroCredencial"],
        
      ],
      designEdit: [
        ["UserType", "Email"],
        ["NombreUsuario"],
        ["PersonaFisicaID", "TipoUsuarioID"],
        ["DepartamentalID"],
        ["DependenciaID", "JuzgadoID"],
        ["CategoriaUfiID"],
        ["NumeroCredencial"],
        
      ],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/usuario",
      pageName: "Usuario",
      datosDrop: {
        Email: "",
        NombreUsuario: "",
        PersonaFisicaID: "",
        TipoUsuarioID: "",
        DependenciaID: "",
        JuzgadoID: "",
        CategoriaUfiID: "",
      },
      UserTypeTitle: null,
    };
  },
  computed: {
    valuesForDefault() {
      return { UserType: "Usuario Policial" };
    },
  },
  created() {
    let { id } = this.$route.params;
    this.id = id;
  },
  methods: {
    // Elimina los espacios iniciales y finales del campo "Nombre de Usuario"
    trimNombreUsuario() {
      if (this.$refs.TForm.values.NombreUsuario) {
        this.$refs.TForm.values.NombreUsuario = this.$refs.TForm.values.NombreUsuario.trim();
      }
    },
    // Añade un evento 'blur' al campo "Nombre de Usuario" para ejecutar trimNombreUsuario cuando pierde el foco
    addBlurEventListener() {
    if (this.$refs.TForm && this.$refs.TForm.$refs.NombreUsuario && this.$refs.TForm.$refs.NombreUsuario[0]) {
      this.$refs.TForm.$refs.NombreUsuario[0].$el.addEventListener('blur', this.trimNombreUsuario);
    }
  },    
    confirmationRestablishing(event) {
      this.$confirm.require({
        target: event.currentTarget,
        group: "demo",
        message: "¿Está seguro que desea restablecer la contraseña?",
        icon: "pi pi-question-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Sí",
        rejectLabel: "No",
        accept: async () => {
          let resp = await axios.get(
            process.env.VUE_APP_BACKEND_ENVIRONMENT + "/usuario/" + this.id + "?newPassword",
            { withCredentials: true }
          );
          if (resp.status === 200) {
            this.$toast.open({
              message: "El e-mail fue enviado con éxito",
              position: "top-right",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.open({
              message:
                "Ups! Algo falló, te pedimos disculpas y te ayudaremos a resolverlo. Por favor, haz click en este cartel rojo, te aparecera un nuevo mensaje, envía una captura de pantalla con esa información a la mesa de ayuda técnica.",
              position: "top-right",
              type: "error",
              duration: 0,
              onClick: () => this.errAlert(resp),
            });
          }
        },
        reject: () => {},
      });
    },
    errAlert(response) {
      let msg = typeof response.data == "object" ? JSON.stringify(response.data) : response.data;
      alert(msg);
    },
    determineUserType() {
      for (const buttonProp in this.buttons) {
        if (
          this.$refs.TForm.values[this.buttons[buttonProp]] !== null &&
          this.$refs.TForm.values[this.buttons[buttonProp]] !== undefined
        ) {
          this.UserTypeTitle = buttonProp;
          this.$refs.TForm.values.UserType = buttonProp;
        }
      }
    },
    removeAndShowButtons() {
      for (const buttonProp in this.buttons) {
        this.$refs.TForm.params[this.buttons[buttonProp]].type = "";
        this.$refs.TForm.params[this.buttons[buttonProp]].validation = "";
        if (this.buttons[this.UserTypeTitle] !== undefined) {
          this.$refs.TForm.params[this.buttons[this.UserTypeTitle]].type = "1to1";
          this.$refs.TForm.params[this.buttons[this.UserTypeTitle]].validation =
            "required|max_length[50]";
        }
        if (this.UserTypeTitle === "Usuario Departamental") {
          this.$refs.TForm.params["TipoUsuarioID"].type = "";
          this.$refs.TForm.params["TipoUsuarioID"].validation = "";
        }else{
          this.$refs.TForm.params["TipoUsuarioID"].type = "1to1";
          this.$refs.TForm.params["TipoUsuarioID"].validation = "required|max_length[50]";
        }
      }
    },
    removeOrShowUFICategoryButton() {
      if (this?.$refs?.TForm?.values?.UserType !== "Usuario Fiscal") return;
      if (
        this?.$refs?.TForm?.values?.TipoUsuarioID === "tipo-usuario-fiscal-general" ||
        this?.$refs?.TForm?.values?.TipoUsuarioID === "tipo-usuario-supervisor"
      ) {
        if (this?.$refs?.TForm?.$refs?.CategoriaUfiID[0].$el?.style !== undefined)
          this.$refs.TForm.$refs.CategoriaUfiID[0].$el.style = "display: none";
        this.$refs.TForm.$refs.CategoriaUfiID[0].style = "display: none";
        this.$refs.TForm.values.CategoriaUfiID = "ufis-todas";
      } else {
        if (this?.$refs?.TForm?.$refs?.CategoriaUfiID[0]?.style !== undefined)
          this.$refs.TForm.$refs.CategoriaUfiID[0].style = "display: inline";
      }
      this?.$refs?.TForm?.$forceUpdate();
    },
    errorRepeatedUser() {
      this.$refs.ModalRefInform.open();
    },
    async waitForRefExists(ref, attempts = 0, maxAttempts = 200, waitTime = 10) {
      if (!ref) {
        if (attempts < maxAttempts) {
          await new Promise((resolve) => setTimeout(resolve, waitTime));
          await this.waitForRefExists(ref, attempts + 1, maxAttempts, waitTime);
        }
      }
    },
  },
};
</script>
