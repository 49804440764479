<template>
  <div class="page">
    <div 
      class="dropdown-space"
      v-if="
        $rest.esTipoUsuario(
          [
            'Super User',
            'User Tester',
            'Super Usuario de E3',
            'Dpto. Asuntos Judiciales D-5',
            'Dpto.Criminalistica',
            'Lab. Informatico Forense',
            'Control de Gestion',
            'Dirección de Información para el Delito D-2',
            'Dpto. Planeamiento',
            'UsuarioDepartamental'
          ].concat(prosecutorUserTypes),
          usuario
        )
      "
    >
      <Dropdown
        v-model="dependenciaDrop"
        :options="dependenciaList"
        filter
        optionLabel="dependencia"
        optionValue="id"
        placeholder="Seleccione una dependencia..."
        showClear
      />
    </div>
    <div class="upContainer">

      <DashboardTopInfo
        v-if="!$rest.esTipoUsuario(['Deposito Judicial'], usuario) && $offline.onlineMode"
        :dependencia="dependenciaDrop"
      ></DashboardTopInfo>
    </div>
    <div
      class="downContainer"
      :class="{'centered': isSpecialUserType}"
    >
      <div class="left" v-if="!isSpecialUserType">
        <BibliotecaVirtual />
      </div>
      <div class="center" v-if="!isSpecialUserType">
        <DashboardChart :dependencia="dependenciaDrop" />
      </div>
      <div class="right" v-if="!isSpecialUserType && !$rest.esTipoUsuario(['UsuarioDepartamental'], usuario)">
        <!-- No estará disponible en producción hasta que se termine toda la funcionalidad del offline-->
        <OfflineTable />
      </div>
      <div class="centered-biblioteca" v-if="isSpecialUserType">
        <BibliotecaVirtual />
      </div>
    </div>

    <br />
    <div v-if="!$rest.esTipoUsuario(['Deposito Judicial'], usuario) && $offline.onlineMode">
      <DashboardBottomInfo :dependencia="dependenciaDrop" :user="usuario" />
    </div>
    <ConfirmPopup group="demo" ref="ConfirmPopup" style="z-index: 999" />
  </div>
</template>


<script>
import DashboardTopInfo from "@/components/Dashboard/DashboardTopInfo";
import DashboardBottomInfo from "./DashboardBottomInfo.vue";
import DashboardChart from "@/components/Dashboard/DashboardChart";
import OfflineTable from "@/components/Dashboard/OfflineTable";
import BibliotecaVirtual from "@/components/Dashboard/BibliotecaVirtual";
import { mapGetters } from "vuex";
import prosecutorMixin from "./mixins/prosecutorMixin.js";
import tanstackFunctions from "../../mixins/tanstackFunctions.js";
import actuationsRepository from "../../../dixieDB/repositories/actuationsRepository";
import actuacionesRepository from "../../../dixieDB/app-offline/repositories/actuacionesRepository";
import ConfirmPopupMixin from "../../components/TForm/mix/ConfirmPopupMixin.js";
// import { useQuery } from '@tanstack/vue-query';

export default {
  components: {
    DashboardTopInfo,
    DashboardBottomInfo,
    DashboardChart,
    OfflineTable,
    BibliotecaVirtual,
  },
  name: "DashboardBody",
  mixins: [prosecutorMixin, ConfirmPopupMixin, tanstackFunctions],
  data() {
    return {
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      searchText: "",
      usuario: this.$store.state.user,
      dependenciaDrop: null,
      actuationsOffline: [],
    };
  },
  computed: {
    ...mapGetters({
      dependenciaList: "siis/getDependecias",
      denunciations: "sumarioList/getList",
    }),
    image() {
      return "nature" + (this.first + 1);
    },
    isSpecialUserType() {
      return this.$rest.esTipoUsuario(
        [
          'Deposito Judicial',
          // 'Ayudante de Juez', 
          // 'Juez', 
          // 'Supervisor', 
          // 'Fiscal', 
          // 'Fiscal General', 
          // 'Administrativo', 
          // 'Ayudante Fiscal', 
          // 'Policia Investigador'
        ],
        this.usuario
      );
    }
  },
  async created() {
    // await this.getActuationsForTanstack();
    // let denunciations = await this.getFirstPageFromActuations();
    // console.log('denunciations', await denunciations);
    // this.actuationsOffline = await actuationsRepository.getAllActuations(actuacionesRepository.getAll());
    this.actuationsOffline = await actuationsRepository.getAllActuations(actuacionesRepository.getAll());
    // console.log('this.actuationsOffline', this.actuationsOffline);
    // console.log('actuacionesRepository.getAll()', await actuacionesRepository.getAll());
    // actuationsRepository.transformActuationsToJSON(actuacionesRepository.getAll());

    //solo llamamos a setDependencia si el usuario nescesita ver las Dependencias(y por ende tiende los permisos)
    if (
    this.$rest.esTipoUsuario(
      [
        "Super User",
        "User Tester",
        "Super Usuario de E3",
        "Dpto. Asuntos Judiciales D-5",
        "Dpto.Criminalistica",
        "Lab. Informatico Forense",
        "Dpto.Criminalistica",
        "Lab. Informatico Forense",
        "UsuarioDepartamental"
      ],
      this.usuario
    )
    ) {
      this.$store.dispatch("siis/setDependencias");
    }
    if (this.$rest.esTipoUsuario(["Jefe de Dependencia", "Jefe de Sumario"], this.usuario)) {
      this.dependenciaDrop = this.usuario.userdata.dependenciaID;
    }
    this.$store.dispatch("siis/setMarca"),
    this.$store.dispatch("siis/setModelo");
    this.$store.dispatch("siis/setCategoriasDeposito");
    this.$store.dispatch("siis/setSubCategoriasDeposito");
    this.$store.dispatch("siis/setTipoDeposito");
    this.$store.dispatch("siis/setEstadosEfectoDeposito");
    this.$store.dispatch("siis/setEstadosSolicitudDeposito");
  },
};
</script>

<style scoped>
.dropdown-space {
  margin-bottom: 20px; /* Ajusta el valor según el espacio que necesites */
}
.decoration__links {
  text-decoration: none;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px;
}
.menu {
  display: flex;
  justify-content: right;
  padding-top: 10px;
}

.inputText {
  font-size: 1.2rem;
}

.all-w {
  width: 100%;
}
.cards__section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}
.dashboard__cards__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.dashboard__card,
.dashboard__recent,
.dashboard__assigned {
  margin: 25px;
  min-width: 325px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 15px;
}
.p-button.btn__card__dashboard {
  margin-right: 10px;
  padding: 15px 10px 15px 15px;
  color: #fff;
  border: none;
}
@media screen and (max-width: 551px) {
  .p-card {
    margin: 10px;
  }
}

.card__header {
  font-size: 1.7rem;
  font-weight: 600;
}
>>> .p-card-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.recent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.title,
.subtitle {
  margin: 4px;
}
.title {
  font-size: 1.5rem;
}
.subtitle {
  font-size: 0.8rem;
}
.ongoing {
  color: #2196f3;
}
.expiring {
  color: #df1e10;
}
.ongoing__card {
  border-left: #2196f3 solid 7px;
}
.expiring__card {
  border-left: #df1e10 solid 7px;
}
.p-button.btn__card__dashboard.ongoing {
  background-color: #2196f3;
}
.p-button.btn__card__dashboard.expiring {
  background-color: #df1e10;
}
.item {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 10px;
}
>>> .p-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
}
.item__num {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.item__text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.item__text__num {
  padding: 0;
  margin: 0;
  font-size: 1.7rem;
  font-weight: bold;
}
.item__text__descr {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: lighter;
}
>>> .p-inputtext {
  padding-left: 2rem !important;
}
a {
  text-decoration: none;
  color: #4d535a;
}
.upContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.downContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.downContainer.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px); /* Ajusta según sea necesario */
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.center {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.centered-biblioteca {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-card .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-card .content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.custom-card .content .files-search {
  width: 100%;
}

.custom-card .content .files-search input {
  width: 100%;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.centered-biblioteca {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>