<template>
  <Button
    :icon="icon"
    :class="buttonClass"
    :style="{ color: buttonColor }"
    :disabled="disabled"
    v-tooltip.left="tooltipText"
    @click="handleClick"
  />
</template>

<script>
  export default {
    name: "CustomButton",
    props: {
      icon: {
        type: String,
        required: true, 
      },
      buttonClass: {
        type: String,
        default: "p-button-rounded p-button-outlined mr-2", 
      },
      buttonColor: {
        type: String,
        default: "#000", 
      },
      tooltipText: {
        type: String,
        default: "", 
      },
      disabled: {
        type: Boolean,
        default: false, 
      },
    },
    methods: {
      handleClick(event) {
        this.$emit("click", event); 
      },
    },
  };
</script>
  