//UFIs: 'ufi-establecer-paradero'      
export default {
    computed: {
        PreformUFIGenericOficio:{
            get (){
                let dateDenuncia = {
                    withStyles:   `<p style="color: #55b5ea ; display: inline"> ${this.dateDenuncia}</p>;`,
                    noStyles: ' ' + this.dateDenuncia + ';'
                }
               
                //changing the last character for a ';'
                dateDenuncia.withStyles =  dateDenuncia.withStyles.slice(0, -1)
                dateDenuncia.withStyles =  dateDenuncia.withStyles + ";"
                dateDenuncia.noStyles =  dateDenuncia.noStyles.slice(0, -1)
                dateDenuncia.noStyles =  dateDenuncia.noStyles + ";"
                
                let constance = {
                    withStyles:   ` el funcionario de Policía que suscribe y Secretario de actuaciones que refrenda, a los fines legales <p style="text-decoration: underline ; display: inline ; font-weight: bold"> HACE CONSTAR:</p> `,
                    noStyles: ` el funcionario de Policía que suscribe y Secretario de actuaciones que refrenda, a los fines legales HACE CONSTAR: `,
                }
                
                let constanceText = {
                    withStyles:   `Que en la fecha y hora indicada precedentemente se toma conocimiento por parte del `,
                    noStyles: `Que en la fecha y hora indicada precedentemente se toma conocimiento por parte del `,
                }

                constanceText.noStyles = constanceText.withStyles  
             
                if(this.textEditsCounter.Preform == 0)
                {
                    let Preform = {
                        withStyles: this.Preforma.noStyles +  this.dependencyUFI.noStyles + dateDenuncia.noStyles +  constance.withStyles + constanceText.withStyles + this.interveningPersonnel.withStyles,
                        noStyles: this.Preforma.noStyles +  this.dependencyUFI.noStyles + dateDenuncia.noStyles + constance.noStyles + constanceText.noStyles + this.interveningPersonnel.noStyles
                    };
                    return Preform
                }   else
                {   
                    let Preform = {
                        withStyles : "vacio por que no se muestra, es solo para que no de error por undefined",
                        noStyles : this.textEstaticos.Preform,
                    }
                    return Preform
                }
            }
        },
        instructionUFIGenericOficio:{
            get (){
                if(this.textEditsCounter.instructionDecree == 0){
                    let firstPart =   {
                        withStyles: "En virtud de ello la instrucción <b><u>DISPONE:</b></u> Iniciar el Legajo de investigación que corresponda. Designar como secretario de las mismas al funcionario de Policía al pie firmante. Dar inmediata intervención a la",
                    }
                    firstPart.noStyles =  firstPart.withStyles.replace('<b><u>DISPONE:</b></u>', 'DISPONE')
                    
                    let prosecutor ={ 
                        withStyles:   ` a cargo del Fiscal <p style="color: #55b5ea ; display: inline">${this.dataResumen.prosecutor ? this.dataResumen.prosecutor : ''} </p>`,
                        noStyles: ` a cargo del Fiscal ${this.dataResumen.prosecutor ? this.dataResumen.prosecutor : ''} `,
                    }
                    
                    let actuationName ={
                        'ufi-generica-oficio': 'UNIDAD FISCAL DE INVESTIGACIÓN DELITOS GENÉRICOS',
                        'ufi-delitos-contra-la-propiedad-oficio': 'UNIDAD FISCAL DE INVESTIGACIÓN DELITOS CONTRA LA PROPIEDAD',
                        'ufi-estafas-y-delitos-informaticos-oficio': 'UNIDAD FISCAL DE INVESTIGACIÓN DELITOS INFORMÁTICOS Y ESTAFAS'
                    }
                    
                    actuationName = actuationName[this.formType] !== undefined ? actuationName[this.formType] : '';
                    
                    let secondPart =  {
                        withStyles:  ` <p style='color: #55b5ea ; display: inline'> ${actuationName} </p> ` ,
                        noStyles:  ` ${actuationName}`,
                    }

                    let secondPointFivePart =  {
                        withStyles:  ` con intervención de la  U.F.I. N°  <p style='color: #55b5ea ; display: inline'> ${this.ActuacionUfi == null ? '' : this.ActuacionUfi.Numero } </p> ` ,
                        noStyles:  ` con intervención de la U.F.I. N° ${this.ActuacionUfi == null ? '' : this.ActuacionUfi.Numero } `,
                    }
                    
                    let thirdPart = {
                        withStyles: ` mediante la remisión del Parte Preventivo. Recibir a ${this.dataResumen.acusados !== ''  && this.dataResumen.acusados !== undefined ?  `<p style="color: #55b5ea ; display: inline">${this.dataResumen.acusados}</p>`: ''} `,
                        noStyles:` mediante la remisión del Parte Preventivo. Recibir a ${this.dataResumen.acusados !== ''  && this.dataResumen.acusados !== undefined ?  this.dataResumen.acusados : ''}`,
                    }
                    let hijackedItems = {
                        withStyles:  this.dataResumen.efectosDenunciados !== '' && this.dataResumen.efectosDenunciados !== undefined  ?  ` y el secuestro de <p style="color: #55b5ea ; display: inline">${this.dataResumen.efectosDenunciados}</p>.` : '',
                        noStyles:    this.dataResumen.efectosDenunciados !== '' && this.dataResumen.efectosDenunciados !== undefined ?  ` y el secuestro de ${this.dataResumen.efectosDenunciados}.` : ''
                    }
                    

                    let fourthPart = " Designar al Medico Legista de la Policía, a fin que examine al aprehendido. Requerir a través de cualquier medio idóneo la Plantilla Prontuarial actualizada del aprehendido. Llevar a cabo toda otra medida de carácter legal que se estime de interés.CERTIFICO.-"

                    let instructionDecree = {
                        withStyles: firstPart.withStyles + secondPart.withStyles + prosecutor.withStyles + secondPointFivePart.withStyles + thirdPart.withStyles + hijackedItems.withStyles + fourthPart,
                        noStyles: firstPart.noStyles + secondPart.noStyles + prosecutor.noStyles + secondPointFivePart.noStyles + thirdPart.noStyles + hijackedItems.noStyles + fourthPart,
                    }
                    return instructionDecree                  
                }   else{
                    let instructionDecree = {
                    noStyles:  this.textEstaticos.instructionDecree
                    }
                    return instructionDecree                
                }          
            },
        },   
        actOfStartUFIGenericOficio:{
            get (){
                let actOfStart = this.Preform.noStyles + ' ' + this.deposition + '. ' + this.instructionDecree.noStyles
                return actOfStart 
            }
        } 
    }    
};