import functionsDataResumen from './functionsDataResumen';
import Vue from 'vue'

const affecteds = (state, data) => {
  //////////////////////////////////////////Lista de Damnificados-Denunciantes-Para-caratula////////////////////////////////
  ///////////Esta lista tiene en consideracion que En sumario por oficio puede o no haber un damnificado/////////////
  try{
    let ListaDenunciantes='';
    let ListaDenunciantesDatosParciales='';
    let damnificado = '';
    let damnificadoDatosParciales = '';
    for(const item of state?.denunciante?.list) //aca solo va a ser un denunciante(pero tomamos en cuenta el hecho de que pueda ser mas de uno)
    {
      if(item.type != 'empty')
      {
        if( item.doc != undefined)
        {
          if(item.ordenPublico == true)
          {
            damnificado = "<b>Orden Publico</b>"
            damnificadoDatosParciales = "<b>Orden Publico</b>"

          }else
          {
            damnificadoDatosParciales = item.RolesdenunciaID  != undefined ? '<b>'+item.RolesdenunciaID +'</b>': damnificadoDatosParciales
            damnificadoDatosParciales = item.doc.apellido != undefined ?  damnificadoDatosParciales+' <b>'+item.doc.apellido+'</b>': damnificadoDatosParciales
            damnificadoDatosParciales = item.doc.nombre != undefined ?  damnificadoDatosParciales+'<b>, ' +item.doc.nombre+'</b>' : damnificadoDatosParciales; 
            damnificadoDatosParciales = item.doc.documento != undefined ? damnificadoDatosParciales+'<b>, DNI N° '+ item.doc.documento+'</b>': damnificadoDatosParciales;

            damnificado = item.RolesdenunciaID  != undefined ? '<b>'+item.RolesdenunciaID +'</b>': damnificado
            damnificado = item.doc.apellido != undefined ? damnificado+' <b>'+item.doc.apellido+'</b>': damnificado
            damnificado = item.doc.nombre != undefined ?  damnificado+'<b>, ' +item.doc.nombre+'</b>' : damnificado; 
            damnificado = item.doc.documento != undefined ? damnificado+'<b>, DNI N° '+ item.doc.documento+'</b>': damnificado;
            damnificado = item.doc.nacionalidad != undefined ? damnificado+ ', de nacionalidad '+item.doc.nacionalidad.nombre : damnificado;
            damnificado = item.doc.estadoCivil != undefined ? damnificado+ ', estado civil '+item.doc.estadoCivil.nombre: damnificado;
            damnificado = item.doc.fechaNacimiento != undefined ? damnificado+', de '+functionsDataResumen.calculateAge(item.doc.fechaNacimiento)+' años de edad' : damnificado;
            damnificado = item.doc.instruccion != undefined ? damnificado+', '+item.doc.instruccion.nombre : damnificado;
            damnificado = item.doc.profesion != undefined ? damnificado+', de profesion '+item.doc.profesion : damnificado;
  
            let dom =  item.doc.domicilio != undefined && item.doc.domicilio != null && item.doc.domicilio != ''? item.doc.domicilio  : ""
            let domSIPE = item.doc.domicilioSIPE != undefined && item.doc.domicilioSIPE != null && item.doc.domicilioSIPE != ''? item.doc.domicilioSIPE : ""
    
            let domicilio = dom != "" ? dom: domSIPE;
            damnificado= domicilio != "" ? damnificado+', con domicilio en '+domicilio+' \n ': damnificado;
          }

        }else
          damnificadoDatosParciales = item?.RolesdenunciaID  != undefined ? '<b>'+item?.RolesdenunciaID +'</b>': damnificadoDatosParciales
          damnificadoDatosParciales = item.Apellido != undefined ? damnificadoDatosParciales+' <b>'+item.Apellido+'</b>': damnificadoDatosParciales
          damnificadoDatosParciales = item.Nombre != undefined ?  damnificadoDatosParciales+'<b>, ' +item.Nombre+'</b>' : damnificadoDatosParciales; 
          damnificadoDatosParciales = item.Dni != undefined && item.Dni != '---'? damnificadoDatosParciales+'<b>, DNI N° '+ item.Dni +'</b>': damnificadoDatosParciales;{

          damnificado = item?.RolesdenunciaID  != undefined ? '<b>'+item?.RolesdenunciaID +'</b>': damnificado
          damnificado = item.Apellido != undefined ? damnificado+ ' <b>'+item.Apellido+'</b>': damnificado
          damnificado = item.Nombre != undefined ?  damnificado+'<b>, ' +item.Nombre+'</b>' : damnificado; 
          damnificado = item.Dni != undefined && item.Dni != '---'? damnificado+'<b>, DNI N° '+ item.Dni +'</b>': damnificado;
          damnificado = item.Nacionalidad != undefined ? damnificado+ ', de nacionalidad '+item.Nacionalidad : damnificado;
          damnificado = item.EstadoCivil != undefined ? damnificado+ ', estado civil '+item.EstadoCivil: damnificado;
          damnificado = item.FechaDeNacimiento != undefined ? damnificado+', de '+functionsDataResumen.calculateAge(item.FechaDeNacimiento)+' años de edad' : damnificado;
          damnificado = item.Instruccion != undefined ? damnificado+', '+item.Instruccion : damnificado;
          damnificado = item.Profesion != undefined ? damnificado+', de profesion '+item.Profesion : damnificado;
          damnificado = item.DomicilioSipe != undefined && item.DomicilioSipe != '' && item.DomicilioSipe != null ? damnificado+', con domicilio en '+item.DomicilioSipe+' \n ': damnificado;
        }

        if(ListaDenunciantes=='')
        {
          ListaDenunciantes=damnificado;
          ListaDenunciantesDatosParciales = damnificadoDatosParciales
        }else{
          ListaDenunciantes=damnificado;
          ListaDenunciantesDatosParciales = ListaDenunciantesDatosParciales+'; '+damnificadoDatosParciales
        }
      }
    }
    //con las siguientes  7 lineas de codigo nos ahorramos tener que repetir todo el proceso anterior para tener el mismo string sin "<b>" y "</b>"
    if(!ListaDenunciantes)
    {
      data.denunciantes = " ANONIMO " ;
      data.denuncianteDatosParciles = " ANONIMO ";
      data.denunciantesCaratula = " ANONIMO ";
    }else
    {
      data.denunciantesCaratula = ListaDenunciantes;
      data.denunciantes = ListaDenunciantes.replace(/<\/b>|<b>/g, '');
      data.denuncianteDatosParciles = ListaDenunciantesDatosParciales.replace(/<\/b>|<b>/g, '');
    }
  }catch(e)
  {
    console.log('tiro un problema al cargar el Denunciante: '+ e);
  }
  ///////////////////////////////////Lista de Dni////////////////////////////////////
  try{
    var ListaDenunciantesDni = '';
    for(const item of state?.denunciante?.list)
    {
      if(item.type != 'empty')
      {
        if( item.doc != undefined)
        {
          // ListaDenunciantesDni = item.doc.documento != undefined ? ListaDenunciantesDni+'; '+ item.doc.documento: ListaDenunciantesDni;
          ListaDenunciantesDni = ListaDenunciantesDni != '' ? ListaDenunciantesDni+'; '+ item.doc.documento: item.doc.documento;
        }else
        {
          // ListaDenunciantesDni = item.Dni != undefined ? ListaDenunciantesDni+'; '+ item.Dni: ListaDenunciantesDni;
          ListaDenunciantesDni = ListaDenunciantesDni != '' ? ListaDenunciantesDni+'; '+ item.Dni: item.Dni;
        }
      }
      data.denunciantesDni = ListaDenunciantesDni;
    }
    // console.log("data.denunciantesDni: ",data.denunciantesDni)
  }
  catch(e){
    console.log('tiro un problema al cargar el DenunciantesNyA: '+ e);
  }
  /* La siguiente linea de codigo se realizo con la implementacion de Preliminares, en estas no siempre hay un Protagonista, haciendo que denunciante.list[0] no siempre exista */
  let Protagonista = state?.denunciante?.list?.length > 0 ? state?.denunciante?.list?.[0] : state?.denunciante?.list
  //contamos cantidad de denunciantes
  let TotalDenunciantes = state.denunciante.list.length - 1;
  try {
    //contamos cantidad de denunciantes sin dni y que no sean ordenPublico
    let persSinDni = state.denunciante.list.filter(e => (e?.doc?.documento == undefined || e?.doc?.documento == '') && (e?.ordenPublico == undefined || e?.ordenPublico == false )).length - 1;
    if(persSinDni === 0 && Protagonista?.doc?.documento === undefined && Protagonista.Dni !== undefined){
      data.dniDenunciante = state?.denunciante.list?.length != 0 ? Protagonista.Dni : "";
      if(typeof(Protagonista) != 'undefined')
      {        
        data.errorValidationErr.push("Falta DNI del damnificado")
      }
    } else {
      if (TotalDenunciantes == 0 || persSinDni > 0){
        let formTypesForValidateAndInclude = {'oficio': { contains: true }};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)){
          if(TotalDenunciantes == 0){
            data.errorValidationErr.push("Debe especificar al menos un Damnificado de tipo Orden Público")
          }
          TotalDenunciantes != 0 ? data.errorValidationErr.push("Falta DNI del denunciante y/o damnificado")  : data.errorValidationWarning.push('No especificó DNI del denunciante y/o damnificado')        
        }
        formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true }, 'ufi-flagrancia': { contains: true },
        'ufi-generica': { contains: true },  'ufi-generica-oficio': { contains: true }, 'ufi-delitos-contra-la-propiedad': { contains: true },
        'ufi-delitos-contra-la-propiedad-oficio': { contains: true }, 'ufi-estafas-y-delitos-informaticos-oficio': { contains: true },
        'ufi-estafas-y-delitos-informaticos': { contains: true }, 'ufi-cavig': { contains: true }, 'ufi-establecer-paradero': { contains: true }, 
        'ufi-siniestro-vial': { contains: true }, 'ufi-otros': { contains: true }, 'ufi-intoxicacion': { contains: true }, 'ufi-emergencias-medicas': { contains: true }, 
        'ufi-incendio-vivienda': { contains: true }, 'ufi-ahorcamiento-suicidio': { contains: true }, 'preliminar-siniestro-vial': { contains: true }, 'denuncia': { contains: true }, };
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationErr.push("Falta DNI del damnificado")
      }
      data.dniDenunciante = Protagonista?.doc?.documento != undefined ? Protagonista?.doc?.documento : "";
    }
  }
  catch(e){
    console.log('error', e);
  }
 
  try {
    data.instruccion = Protagonista?.doc?.instruccion?.nombre; 
    if(Protagonista?.doc?.instruccion?.nombre === undefined){
      data.instruccion = Protagonista["Instruccion"] != undefined ? Protagonista["Instruccion"] :''
      data.errorValidationWarning.push('No especificó la instrucción del denunciante y/o damnificado')
    }
  }
  catch(e){    
    console.log('error', e);
  }
 
  try {
    if(Protagonista.Profesion){
      data.profesion = Protagonista.Profesion;
    }
    if (!Protagonista?.doc?.profesion){
      if(Protagonista.ordenPublico == false && data.formType==="oficio" || Protagonista.ordenPublico == undefined||data.formType!=="oficio"){      
        data.errorValidationWarning.push('No especificó la profesión del denunciante y/o damnificado')
      }
    }else{
      data.profesion =", de profesión " + Protagonista?.doc?.profesion;
    }
  }
  catch(e){    
    console.log('error', e);
  }  
 
  try {
    //contamos cantidad de denunciantes sin telefono y que no sean ordenPublico
    let persSinTelefono = state.denunciante.list.filter(e => (e?.doc?.telefono == undefined || e?.doc?.telefono == '') && (e?.ordenPublico == undefined || e?.ordenPublico == false )).length - 1;
    if(persSinTelefono === 0 && Protagonista?.doc?.telefono === undefined && Protagonista.Telefono !== undefined){
      data.telefonoDenunciante = state.denunciante.list?.length != 0 ? Protagonista.Telefono : "";
    } else {
      if (TotalDenunciantes == 0 || persSinTelefono > 0){
        let formTypesForValidateAndInclude = {'oficio': { contains: true }};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)){
          TotalDenunciantes != 0 ? data.errorValidationErr.push("Falta teléfono del denunciante y/o damnificado")   : data.errorValidationWarning.push('No especificó teléfono del denunciante y/o damnificado')
        }
        formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true }, 'ufi-flagrancia': { contains: true },
        'ufi-generica': { contains: true },'ufi-generica-oficio': { contains: true },'ufi-delitos-contra-la-propiedad': { contains: true },
        'ufi-delitos-contra-la-propiedad-oficio': { contains: true }, 'ufi-estafas-y-delitos-informaticos': { contains: true }, 
        'ufi-estafas-y-delitos-informaticos-oficio': { contains: true }, 'ufi-cavig': { contains: true }, 'ufi-establecer-paradero': { contains: true }, 'ufi-siniestro-vial': { contains: true }, 'ufi-otros': { contains: true }, 'ufi-intoxicacion': { contains: true }, 'ufi-emergencias-medicas': { contains: true }, 'ufi-incendio-vivienda': { contains: true }, 'ufi-ahorcamiento-suicidio': { contains: true }, 'preliminar-siniestro-vial': { contains: true }, 'denuncia': { contains: true }, };
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationErr.push("Falta teléfono del damnificado")
      }
      data.telefonoDenunciante = Protagonista?.doc?.telefono != undefined ? Protagonista?.doc?.telefono : "";
    }
  }
  catch(e){
    console.log('error', e);
  }
 
  try {
    // console.log('state.denunciante.list', state.denunciante.list);
    //contamos cantidad de denunciantes email que no sean ordenPublico
    let denunciantesWithoutEmail = [];
    state.denunciante.list.map( affected => {
      if( 
        (affected.doc?.email === undefined || affected.doc?.email === '') && affected.type !== "empty" &&
        (affected.doc.condicion.nombre === "Denunciante" || affected.doc.condicion.nombre === "Denunciante y Damnificado") &&
        (affected?.ordenPublico == undefined || affected?.ordenPublico == false )
      ){
        denunciantesWithoutEmail.push(affected);
      }
    });
    if(denunciantesWithoutEmail.length > 0)
      data.errorValidationErr.push("Falta email del denunciante");
  }
  catch(e){
    console.log('error', e);
  }

  try {
    let affectedsWithoutIncorrectEmails = [];
    state.denunciante.list.map( affected => {
      let validationEmailAffected = Vue.prototype.$rest.validateEmail(affected?.doc?.email);
      if(affected?.doc?.email !== undefined && !validationEmailAffected) 
        affectedsWithoutIncorrectEmails.push(affected);
    });
    if(affectedsWithoutIncorrectEmails.length > 0)
      data.errorValidationErr.push("Al menos 1 afectado tiene un email con formato incorrecto");
  }
  catch(e){
    console.log('error', e);
  }
 
  try {
    //contamos cantidad de denunciantes sin apellido y que no sean ordenPublico
    let persSinApellido = state.denunciante.list.filter(e => (e?.doc?.apellido == undefined || e?.doc?.apellido == '') && (e?.ordenPublico == undefined || e?.ordenPublico == false )).length - 1;
    if(persSinApellido === 0 && Protagonista?.doc?.apellido === undefined && Protagonista.Apellido !== undefined){
      data.apellidoDenunciante = state.denunciante.list?.length != 0 ? Protagonista.Apellido: "";
    } else{
      if (TotalDenunciantes == 0 || persSinApellido > 0){
        let formTypesForValidateAndInclude = {'oficio': { contains: true }};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)){
          TotalDenunciantes != 0 ? data.errorValidationErr.push("Falta apellido del denunciante y/o damnificado")   : data.errorValidationWarning.push('No especificó apellido del denunciante y/o damnificado')
        }
        formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true }, 'ufi-flagrancia': { contains: true },
        'ufi-generica': { contains: true }, 'ufi-generica-oficio': { contains: true },'ufi-delitos-contra-la-propiedad': { contains: true },
        'ufi-delitos-contra-la-propiedad-oficio': { contains: true }, 'ufi-estafas-y-delitos-informaticos-oficio': { contains: true },
        'ufi-estafas-y-delitos-informaticos': { contains: true }, 'ufi-cavig': { contains: true },
        'ufi-establecer-paradero': { contains: true }, 'ufi-siniestro-vial': { contains: true }, 'ufi-otros': { contains: true }, 'ufi-intoxicacion': { contains: true }, 'ufi-emergencias-medicas': { contains: true }, 'ufi-incendio-vivienda': { contains: true }, 'ufi-ahorcamiento-suicidio': { contains: true }, 'preliminar-siniestro-vial': { contains: true }, 'denuncia': { contains: true }, };
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationErr.push('Falta apellido del damnificado')
      }
      data.apellidoDenunciante = Protagonista?.doc?.apellido != undefined ? Protagonista?.doc?.apellido : "";
    }
  }
  catch(e){
    console.log('error', e);
  }

  try {
    //contamos cantidad de denunciantes sin nombre y que no sean ordenPublico
    let persSinNombre = state.denunciante.list.filter(e => (e?.doc?.nombre == undefined || e?.doc?.nombre == '') && (e?.ordenPublico == undefined || e?.ordenPublico == false )).length - 1;
    if(persSinNombre === 0 && Protagonista?.doc?.nombre === undefined && Protagonista.Nombre !== undefined){
      data.nombreDenunciante = state.denunciante.list?.length != 0 ? Protagonista.Nombre : "";  
    } else{
      if (TotalDenunciantes == 0 || persSinNombre > 0){
        let formTypesForValidateAndInclude = {'oficio': { contains: true }};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)){
          TotalDenunciantes != 0 ? data.errorValidationErr.push("Falta nombre del denunciante y/o damnificado")   : data.errorValidationWarning.push('No especificó nombre denunciante y/o damnificado') 
        }
        formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true }, 'ufi-flagrancia': { contains: true },
        'ufi-generica': { contains: true }, 'ufi-generica-oficio': { contains: true }, 'ufi-delitos-contra-la-propiedad': { contains: true },
        'ufi-delitos-contra-la-propiedad-oficio': { contains: true }, 'ufi-estafas-y-delitos-informaticos-oficio': { contains: true }, 
        'ufi-estafas-y-delitos-informaticos': { contains: true }, 'ufi-cavig': { contains: true }, 
        'ufi-establecer-paradero': { contains: true }, 'ufi-siniestro-vial': { contains: true }, 'ufi-otros': { contains: true }, 
        'ufi-intoxicacion': { contains: true }, 'ufi-emergencias-medicas': { contains: true }, 
        'ufi-incendio-vivienda': { contains: true }, 'ufi-ahorcamiento-suicidio': { contains: true }, 
        'preliminar-siniestro-vial': { contains: true }, 'denuncia': { contains: true }, 'ufi-anivi': { contains: true }, };
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationErr.push('Falta nombre del damnificado')
      }
      data.nombreDenunciante = Protagonista?.doc?.nombre != undefined ? Protagonista?.doc?.nombre : "";
    }
  }
  catch(e){
    console.log('error', e);
  }  
 
  try {
    data.nacionalidadDenunciante = Protagonista?.doc?.nacionalidad?.nombre;
  }
  catch(e){
    console.log("fallo al cargar la nacionalidad del Denunciante", e)
  }
 
  try {
    //contamos cantidad de denunciantes sin estadocivil y que no sean ordenPublico
    let persSinEstadoCivil = state.denunciante.list.filter(e => (e?.doc?.estadoCivil  == undefined || e?.doc?.estadoCivil.nombre  == '') && (e?.ordenPublico == undefined || e?.ordenPublico == false )).length - 1;
    if(persSinEstadoCivil === 0 && Protagonista?.doc?.estadoCivil === undefined && Protagonista.EstadoCivil !== undefined){
      data.estadoCivilDenunciante = state.denunciante.list?.length != 0 ?  Protagonista.EstadoCivil: "";
    } else {
      if (TotalDenunciantes == 0 || persSinEstadoCivil > 0){
        let formTypesForValidateAndInclude = {'oficio': { contains: true }};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)){
          TotalDenunciantes != 0 ? data.errorValidationErr.push("Falta estado civil del denunciante y/o damnificado")   : data.errorValidationWarning.push('No especificó estado civil del denunciante y/o damnificado')          
        }
        formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true }, 'ufi-flagrancia': { contains: true }, 
        'ufi-generica': { contains: true }, 'ufi-generica-oficio': { contains: true },'ufi-delitos-contra-la-propiedad': { contains: true },
        'ufi-delitos-contra-la-propiedad-oficio': { contains: true }, 'ufi-estafas-y-delitos-informaticos-oficio': { contains: true },
        'ufi-estafas-y-delitos-informaticos': { contains: true },'ufi-cavig': { contains: true }, 
        'ufi-establecer-paradero': { contains: true }, 'ufi-siniestro-vial': { contains: true }, 'ufi-otros': { contains: true }, 
        'ufi-intoxicacion': { contains: true }, 'ufi-emergencias-medicas': { contains: true }, 
        'ufi-incendio-vivienda': { contains: true }, 'ufi-ahorcamiento-suicidio': { contains: true }, 
        'preliminar-siniestro-vial': { contains: true }, 'denuncia': { contains: true },};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationErr.push('Falta estado civil del damnificado')
      }
      data.estadoCivilDenunciante = Protagonista?.doc?.estadoCivil?.nombre != undefined ? Protagonista?.doc?.estadoCivil.nombre : "";
    }
  }
  catch(e){
    console.log('error', e);
  }
   
  try {
    //contamos cantidad de denunciantes sin fecha de nacimiento y que no sean ordenPublico
    let persSinFechaNacimiento = state.denunciante.list.filter(e => (e?.doc?.fechaNacimiento == undefined || e?.doc?.fechaNacimiento == '') && (e?.ordenPublico == undefined || e?.ordenPublico == false )).length - 1;
    if(persSinFechaNacimiento === 0 && Protagonista?.doc?.fechaNacimiento === undefined && Protagonista.FechaDeNacimiento !== undefined){
      if(state.denunciante.list?.length !== 0)  {
        data.edadDenunciante = functionsDataResumen.calculateAge(Protagonista.FechaDeNacimiento);
      } 
    } else{
      if (TotalDenunciantes == 0 || persSinFechaNacimiento > 0){    
        let formTypesForValidateAndInclude = {'oficio': { contains: true }};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)){
          TotalDenunciantes != 0 ? data.errorValidationErr.push("Falta fecha de nacimiento del denunciante y/o damnificado")   : data.errorValidationWarning.push('No especificó fecha de nacimiento del denunciante y/o damnificado')       
        }
        formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true }, 'ufi-flagrancia': { contains: true }, 
        'ufi-generica': { contains: true }, 'ufi-generica-oficio': { contains: true },'ufi-delitos-contra-la-propiedad': { contains: true },
        'ufi-delitos-contra-la-propiedad-oficio': { contains: true },'ufi-estafas-y-delitos-informaticos-oficio': { contains: true },
        'ufi-estafas-y-delitos-informaticos': { contains: true },'ufi-cavig': { contains: true }, 
        'ufi-establecer-paradero': { contains: true }, 'ufi-siniestro-vial': { contains: true }, 'ufi-otros': { contains: true }, 
        'ufi-intoxicacion': { contains: true }, 'ufi-emergencias-medicas': { contains: true }, 
        'ufi-incendio-vivienda': { contains: true }, 'ufi-ahorcamiento-suicidio': { contains: true }, 
        'preliminar-siniestro-vial': { contains: true }, 'denuncia': { contains: true },};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationErr.push('Falta fecha de nacimiento del damnificado')
      }
      data.edadDenunciante = Protagonista?.doc?.fechaNacimiento != undefined ? functionsDataResumen.calculateAge(Protagonista?.doc?.fechaNacimiento) : "";
    }
  }
  catch(e){
    console.log('error', e);
  }

  try {
    //contamos cantidad de denunciantes sin fecha de nacimiento y que no sean ordenPublico
    let persSinDomicilio= state.denunciante.list.filter(e => ((e?.doc?.domicilio == undefined && e?.doc?.domicilioSIPE == undefined)|| (e?.doc?.domicilio == '' && e?.doc?.domicilioSIPE == undefined)) && (e?.ordenPublico == undefined || e?.ordenPublico == false )).length - 1;
    if(persSinDomicilio === 0 && Protagonista?.doc?.domicilio === undefined && Protagonista.DomicilioSipe !== undefined){
      if(state.denunciante.list?.length !== 0)  {
        data.lugarDeRecidenciaDenunciante = Protagonista.DomicilioSipe != undefined ?  Protagonista.DomicilioSipe: "";
      }
      let formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true }, 'ufi-flagrancia': { contains: true }, 
      'ufi-generica': { contains: true },'ufi-generica-oficio': { contains: true },'ufi-delitos-contra-la-propiedad-oficio': { contains: true },
      'ufi-delitos-contra-la-propiedad': { contains: true }, 'ufi-estafas-y-delitos-informaticos-oficio': { contains: true },
      'ufi-estafas-y-delitos-informaticos': { contains: true },'ufi-cavig': { contains: true }, 
      'ufi-establecer-paradero': { contains: true }, 'ufi-siniestro-vial': { contains: true }, 'ufi-otros': { contains: true },
      'ufi-intoxicacion': { contains: true }, 'ufi-emergencias-medicas': { contains: true }, 
      'ufi-incendio-vivienda': { contains: true }, 'ufi-ahorcamiento-suicidio': { contains: true }, 
      'preliminar-siniestro-vial': { contains: true }, 'denuncia': { contains: true }, 'oficio': { contains: true },};
      if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationErr.push("Falta domicilio del damnificado") 
    } else{
      let dom =  Protagonista?.doc?.domicilio != undefined ? Protagonista?.doc?.domicilio  : ""
      let domSIPE = Protagonista?.doc?.domicilioSIPE != undefined ? Protagonista?.doc?.domicilioSIPE : ""
  
      data.lugarDeRecidenciaDenunciante = dom != "" ? dom: domSIPE;
  
      if (TotalDenunciantes == 0 || persSinDomicilio > 0){
        let formTypesForValidateAndInclude = {'oficio': { contains: true }};
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)){
          TotalDenunciantes != 0 ? data.errorValidationErr.push("Falta domicilio del denunciante y/o damnificado")   : data.errorValidationWarning.push('No especificó domicilio del denunciante y/o damnificado') 
        }
        formTypesForValidateAndInclude = {'contravencional-oficio': { contains: true }, 'contravencional-denuncia': { contains: true }, 'ufi-flagrancia': { contains: true },
        'ufi-generica': { contains: true }, 'ufi-generica-oficio': { contains: true }, 'ufi-delitos-contra-la-propiedad': { contains: true },
        'ufi-delitos-contra-la-propiedad-oficio': { contains: true },'ufi-estafas-y-delitos-informaticos-oficio': { contains: true }, 
        'ufi-estafas-y-delitos-informaticos': { contains: true }, 'ufi-cavig': { contains: true }, 
        'ufi-establecer-paradero': { contains: true }, 'ufi-siniestro-vial': { contains: true }, 'ufi-otros': { contains: true }, 
        'ufi-intoxicacion': { contains: true }, 'ufi-emergencias-medicas': { contains: true }, 
        'ufi-incendio-vivienda': { contains: true }, 'ufi-ahorcamiento-suicidio': { contains: true }, 
        'preliminar-siniestro-vial': { contains: true }, 'denuncia': { contains: true }, };
        if(functionsDataResumen.validateFormType(data, formTypesForValidateAndInclude)) data.errorValidationErr.push("Falta domicilio del damnificado")
      }
    }
  }
  catch(e){
    console.log('error', e);
  } 
}
export default {
  affecteds
}