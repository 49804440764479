<template>
  <div class="content card" test-id="identikitForm">

    <div style="width: 100%">
      <div class="header-form" test-id="identikitHeaderForm">
        <div>
          <Button
            v-if="this.numberQuestion > 1"
            icon="pi pi-arrow-left"
            v-tooltip.bottom="'Siguiente pregunta'"
            class="p-button-secondary p-button-lg px-2 py-2 m-1"
            @click="backQuestion()"
            test-id="identikitBackButton"
          />  
        </div>
        <div class="flex">
          <p test-id="identikitQuestion">Pregunta {{this.numberQuestion}}/10:</p>
          <p class="header-question" test-id="identikitSpecificQuestion"><b>{{this.questions[this.numberQuestion - 1]}}</b></p>
        </div>
        <div test-id="identikitDivNextButton">
          <Button
            v-if="this.numberQuestion < 10"
            icon="pi pi-arrow-right"
            v-tooltip.bottom="'Siguiente pregunta'"
            class="p-button-secondary p-button-lg px-2 py-2 m-1"
            @click="nextQuestion()"
            test-id="identikitNextButton"
          />  
        </div>
      </div>
      <div class="description-content">
        <!-- QUESTION 1 -->
        <div v-if="numberQuestion === 1">
          <div class="flex flex-wrap" >
            <div class="field-checkbox checkbox-container" test-id="identikitCheckHead">
              <Checkbox id="binary" v-model="head" :binary="true" />
              <label>Cabeza</label>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteHeadGarment">
              <Dropdown dataKey='nombre' v-model="dataHeadGarment" class="input" v-if="head" :options="headGarmentList" optionLabel="nombre" placeholder="Prenda" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size"  test-id="identikitAutocompleteHeadStyle">
              <Dropdown dataKey='nombre' class="input" v-if="head" v-model="dataHeadStyle" :options="headStyleList" optionLabel="nombre" placeholder="Estilo" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect" test-id="identikitAutocompleteHeadColor">
              <Dropdown dataKey='nombre' class="input" v-if="head" v-model="dataHeadColor" :options="headColorList" optionLabel="nombre" :placeholder="dataHeadColor?dataHeadColor:'Color'" :filter="true" />
            </div>
          </div>

          <div class="flex flex-wrap mt-4">
            <div class="field-checkbox checkbox-container" test-id="identikitCheckSuperiorBody">
              <Checkbox id="binary" v-model="superiorBody" :binary="true" />
              <label>Cuerpo Sup.</label>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteBodyGarment">
              <Dropdown dataKey='nombre' class="input" v-if="superiorBody" v-model="dataSBodyGarment" :options="sBodyGarmentList" optionLabel="nombre" placeholder="Prenda" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteBodySleeves">
              <Dropdown dataKey='nombre' class="input" v-if="superiorBody" v-model="dataSBodySleeves" :options="sBodySleevesList" optionLabel="nombre" placeholder="Mangas" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteBodyStyle">
              <Dropdown dataKey='nombre' class="input" v-if="superiorBody" v-model="dataSBodyStyle" :options="sBodyStyleList" optionLabel="nombre" placeholder="Estilo" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect" test-id="identikitAutocompleteBodyColor">
               <Dropdown dataKey='nombre' class="input" v-if="superiorBody" v-model="dataSBodyColor" :options="sBodyColorList" optionLabel="nombre"  :filter="true" :placeholder="dataSBodyColor?dataSBodyColor:'Color'" />
            </div>
          </div>

          <div class="flex flex-wrap mt-4">
            <div class="field-checkbox checkbox-container" test-id="identikitCheckInferiorBody">
              <Checkbox id="binary" v-model="inferiorBody" :binary="true" />
              <label>Cuerpo Inf.</label>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteIBodyGarment">
              <Dropdown dataKey='nombre' class="input" v-if="inferiorBody" v-model="dataIBodyGarment" :options="iBodyGarmentList" optionLabel="nombre" placeholder="Prenda" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteIBodyStyle">
              <Dropdown dataKey='nombre' class="input" v-if="inferiorBody" v-model="dataIBodyStyle" :options="iBodyStyleList" optionLabel="nombre" placeholder="Estilo" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect" test-id="identikitAutocompleteIBodyColor">
              <Dropdown dataKey='nombre' class="input" v-if="inferiorBody" v-model="dataIBodyColor" :options="iBodyColorList" optionLabel="nombre"  :filter="true" :placeholder="dataIBodyColor?dataIBodyColor:'Color'" />
            </div>
          </div>

          <div class="flex flex-wrap mt-4">
            <div class="field-checkbox checkbox-container" test-id="identikitCheckFeet">
              <Checkbox id="binary" v-model="feet" :binary="true" />
              <label>Pies</label>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteFeetFootwear">
              <Dropdown dataKey='nombre' class="input" v-if="feet" v-model="dataFeetFootwear" :options="feetFootwearList" optionLabel="nombre" placeholder="Calzado"  :filter="true"/>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteFeetStyle">
              <Dropdown dataKey='nombre' class="input" v-if="feet" v-model="dataFeetStyle" :options="feetStyleList" optionLabel="nombre" placeholder="Estilo"  :filter="true"/>
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect" test-id="identikitAutocompleteFeetColor">
              <Dropdown dataKey='nombre' class="input" v-if="feet" v-model="dataFeetColor" :options="feetColorList" optionLabel="nombre" :placeholder="dataFeetColor?dataFeetColor:'Color'" :filter="true" />
            </div>
          </div>
        </div>
        <!-- END...   QUESTION 1 -->
        
        <!-- QUESTION 2 -->
        <div v-if="numberQuestion == 2">
          <div class="flex flex-wrap" >
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteHairLength">
              <Dropdown dataKey='nombre' class="input" v-model="dataHairLength" :options="hairLengthList" optionLabel="nombre" placeholder="Longitud" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteHairType">
              <Dropdown dataKey='nombre' class="input" v-model="dataHairType" :options="hairTypeList" optionLabel="nombre" placeholder="Tipo" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect"  test-id="identikitAutocompleteHairColor">
              <Dropdown dataKey='nombre' class="input" v-model="dataHairColor" :options="hairColorList" optionLabel="nombre" :placeholder="dataHairColor?dataHairColor:'Color'"  :filter="true"/>
            </div>
          </div>        
        </div>
        <!-- END...   QUESTION 2 -->

        <!-- QUESTION 3 -->
        <div v-if="numberQuestion == 3">
          <div class="flex flex-wrap" >
            <div class="field col md:col-3 autocomple-size-question3" test-id="identikitAutocompleteGender">
              <Dropdown dataKey='nombre' class="input" v-model="dataGender" :options="genderList" optionLabel="nombre" placeholder="Indique el sexo"  :filter="true"/>
            </div>
            <div class="field col md:col-3 autocomple-size-question3" test-id="identikitAutocompleteHeight">
              <Dropdown dataKey='nombre' class="input" v-model="dataHeight" :options="heightList" optionLabel="nombre" placeholder="Indique la estatura"  :filter="true"/>
            </div>
            <div class="field col md:col-3 autocomple-size-question3" test-id="identikitAutocompleteVolume">
              <Dropdown dataKey='male' class="input" v-model="dataVolume" :options="volumeList" optionLabel="male" placeholder="Indique el volúmen" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-question3" test-id="identikitSliderApproximateHeightDIV" >
              <div class="slider-mt">
                <h4>Estatura aprox.: {{this.approximateHeight}}cm</h4>
                <Slider v-model="dataApproximateHeight"  :step="10" :min="50" :max="230" test-id="identikitSliderApproximateHeight"/>
              </div>
            </div>
          </div>        
        </div>
        <!-- END...   QUESTION 3 -->

        <!-- QUESTION 4 -->
        <div v-if="numberQuestion == 4">
          <div class="flex flex-wrap">
            <div class="field col md:col-3 autocomple-size-question3" test-id="identikitAutocompleteStageOfLife">
              <Dropdown dataKey='nombre' class="input" v-model="dataStageOfLife" :options="stageOfLifeList" optionLabel="nombre" placeholder="Etapa de vida" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-question3" test-id="identikitSliderApproximateAge">
              <div class="slider-mt">
                <h4>Edad aprox.: {{this.approximateAge}} años</h4>
                <Slider v-model="dataApproximateAge"  :step="1" :min="2" :max="100"/>
              </div>
            </div>
          </div>        
        </div>
        <!-- END...   QUESTION 4 -->

        <!-- QUESTION 5 -->
        <div v-if="numberQuestion == 5">
          <div class="flex flex-wrap">
            <div class="field-checkbox checkbox-container" test-id="identikitCheckTattooHead">
              <Checkbox id="binary" v-model="tattooHead" :binary="true" />
              <label>Cabeza</label>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteHeadPart">
              <Dropdown dataKey='nombre' v-if="tattooHead" class="input" v-model="dataHeadPart" :options="headPartList" optionLabel="nombre" placeholder="Parte" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect" test-id="identikitAutocompleteHeadZone">
              <Dropdown dataKey='nombre' v-if="tattooHead" class="input" v-model="dataHeadZone" :options="headZoneList" optionLabel="nombre"  :placeholder="dataHeadZone?dataHeadZone:'Zona'" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteHeadType">
              <Dropdown dataKey='nombre' v-if="tattooHead" class="input" v-model="dataHeadType" :options="headTypeList" optionLabel="nombre" placeholder="Tipo" :filter="true" />
            </div>
          </div>

          <div class="flex flex-wrap mt-4">
            <div class="field-checkbox checkbox-container" test-id="identikitCheckTattooBody">
              <Checkbox id="binary" v-model="tattooBody" :binary="true" />
              <label>Cuerpo</label>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteBodyPart">
              <Dropdown dataKey='nombre' v-if="tattooBody" class="input" v-model="dataBodyPart" :options="bodyPartList" optionLabel="nombre" placeholder="Parte" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect" test-id="identikitAutocompleteBodyZone">
              <Dropdown dataKey='nombre' v-if="tattooBody" class="input" v-model="dataBodyZone" :options="bodyZoneList" optionLabel="nombre"  :placeholder="dataBodyZone?dataBodyZone:'Zona'" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteBodyType">
              <Dropdown dataKey='nombre' v-if="tattooBody" class="input" v-model="dataBodyType" :options="bodyTypeList" optionLabel="nombre" placeholder="Tipo" :filter="true" />
            </div>
          </div>

          <div class="flex flex-wrap mt-4">
            <div class="field-checkbox checkbox-container" test-id="identikitCheckTattooArm">
              <Checkbox id="binary" v-model="tattooArm" :binary="true" />
              <label>Brazo</label>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteArmPart">
              <Dropdown dataKey='nombre' v-if="tattooArm" class="input" v-model="dataArmPart" :options="armPartList" optionLabel="nombre" placeholder="Parte" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteArmSide">
              <Dropdown dataKey='nombre' v-if="tattooArm" class="input" v-model="dataArmSide" :options="armSideList" optionLabel="nombre" placeholder="Lado" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect" test-id="identikitAutocompleteArmZone">
              <Dropdown dataKey='nombre' v-if="tattooArm" class="input" v-model="dataArmZone" :options="armZoneList" optionLabel="nombre"  :placeholder="dataArmZone?dataArmZone:'Zona'" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteArmType">
              <Dropdown dataKey='nombre' v-if="tattooArm" class="input" v-model="dataArmType" :options="armTypeList" optionLabel="nombre" placeholder="Tipo" :filter="true" />
            </div>
          </div>

          <div class="flex flex-wrap mt-4">
            <div class="field-checkbox checkbox-container" test-id="identikitCheckTattooLeg">
              <Checkbox id="binary" v-model="tattooLeg" :binary="true" />
              <label>Pierna</label>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteLegPart">
              <Dropdown dataKey='nombre' v-if="tattooLeg" class="input" v-model="dataLegPart" :options="legPartList" optionLabel="nombre" placeholder="Parte" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteLegSide">
              <Dropdown dataKey='nombre' class="input" v-if="tattooLeg" :options="legSideList" optionLabel="nombre" v-model="dataLegSide" placeholder="Lado" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect" test-id="identikitAutocompleteLegZone">
              <Dropdown dataKey='nombre' class="input" v-if="tattooLeg" v-model="dataLegZone" :placeholder="dataLegZone?dataLegZone:'Zona'" :options="legZoneList" optionLabel="nombre" :filter="true"/>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteLegType">
              <Dropdown dataKey='nombre' v-if="tattooLeg" class="input" v-model="dataLegType" :options="legTypeList" optionLabel="nombre" placeholder="Tipo" :filter="true" />
            </div>
          </div>
        </div>
        <!-- END...   QUESTION 5 -->

        <!-- QUESTION 6 -->
        <div v-if="numberQuestion == 6">
          <div class="flex flex-wrap">
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteAccessory">
              <Dropdown dataKey='nombre' class="input" v-model="dataAccessory" :options="accessoryList" optionLabel="nombre" placeholder="Accesorio"  :filter="true"/>
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompletePart">
              <Dropdown dataKey='nombre' class="input" v-model="dataPart" :options="partList" optionLabel="nombre" placeholder="Parte" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteZone">
              <Dropdown dataKey='nombre' class="input" v-model="dataZone" :options="zoneList" optionLabel="nombre" placeholder="Zona" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size-multiSelect" test-id="identikitAutocompleteColor">
              <Dropdown dataKey='nombre' class="input" v-model="dataColor" :options="colorList" optionLabel="nombre" :placeholder="dataColor?dataColor:'Color'" :filter="true" />
            </div>
          </div>        
        </div>
        <!-- END...   QUESTION 6 -->

        <!-- QUESTION 7 -->
        <div v-if="numberQuestion == 7">
          <div class="flex flex-wrap">
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteMobility">
              <Dropdown dataKey='nombre' class="input" v-model="dataMobility" :options="mobilityList" optionLabel="nombre" placeholder="Movilidad"  :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteMobilityType">
              <Dropdown dataKey='nombre' class="input" v-model="dataMobilityType" :options="mobilityTypeList" optionLabel="nombre" placeholder="Tipo" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteBrand">
              <Dropdown dataKey='nombre' class="input" v-model="dataBrand" :options="brandList" optionLabel="nombre" placeholder="Marca" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteMobilityColor">
              <Dropdown dataKey='nombre' class="input" v-model="dataMobilityColor" :options="mobilityColorList" optionLabel="nombre" :placeholder="dataMobilityColor?dataMobilityColor:'Color'" :filter="true" />
            </div>
          </div>        
        </div>
        <!-- END...   QUESTION 7 -->

        <!-- QUESTION 8 -->
        <div v-if="numberQuestion == 8">
          <div class="flex flex-wrap">
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteTypeOfWeapon">
              <Dropdown dataKey='nombre' class="input" v-model="dataTypeOfWeapon" :options="typeOfWeaponList" optionLabel="nombre" placeholder="Tipo" :filter="true" />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteWeaponElement">
              <Dropdown dataKey='nombre' class="input" v-model="dataWeaponElement" :options="weaponElementList" optionLabel="nombre" placeholder="Elemento" :filter="true" />
            </div>
            <div class="field col md:col-3 inputText-size" v-if="dataTypeOfWeapon.nombre === 'Arma de fuego'" test-id="identikitAutocompleteWeaponCaliber">
              <InputText  v-model="dataWeaponCaliber" class="input" type="text" placeholder="Calibre"  />
            </div>
            <div class="field col md:col-3 autocomple-size" test-id="identikitAutocompleteWeaponColor">
              <Dropdown dataKey='nombre' class="input" v-model="dataWeaponColor" :options="weaponColorList" optionLabel="nombre" :placeholder="dataWeaponColor?dataWeaponColor:'Color'" :filter="true" />
            </div>
          </div>        
        </div>
        <!-- END...   QUESTION 8 -->
      
      
      </div>
      
      <div class="grid mt-4">
        <div class="flex flex-column col-10 ml-1" test-id="identikitSpecificDIVDescriptionTextArea">
          <label test-id="identikitSpecificDescription">Descripción específica</label>
          <Textarea v-model="textAreaContent" @input="addAnswer()" @keypress="addAnswer()" ref="textAreaContentHTML" rows="2" cols="10" class="mt-2 mr-4" test-id="identikitSpecificDescriptionTextArea"/>
          <!-- Old way to show a pseudoTextarea not editable ↓ -->
          <!-- <div class="pseudoTextarea" v-if="numberQuestion !== 9 && numberQuestion !== 10"> -->
            <!-- For look the styles in the text-area, uncomment this line ↓ -->
            <!-- <div class="pseudoTextarea-content" v-html="textAreaContentWithStyles" /> -->
            <!-- <div class="pseudoTextarea-content" v-html="textAreaContent" /> -->
          <!-- </div> -->
        </div>
        <div class="add-button" test-id="identikitSaveButton">
          <Button v-if="numberQuestion == 10" v-tooltip.bottom="'Terminar identikit'" label="Guardar" class="mr-3 col" @click="addAnswer(true)" />
        </div>
      </div>
      <div class="flex flex-column mt-4 ml-2">
        <label test-id="identikitDescriptionsList">Lista de descripciones</label>
        <Listbox v-model="selectAnswer" :options="listAnswers" dataKey="answer" optionValue="answer" optionLabel="answer" class="mt-2" style="min-height: 35px" test-id="identikitDescriptionsListbox">
          <template #option="slotProps">
            <div class="flex justify-content-between" >
              <span class="mt-2" :test-id="'identikitDescriptionsListboxRespuesta' + parseInt(slotProps.index + 1) ">{{slotProps.option.answer}}</span>
              <div class="flex justify-content-center align-items-center flex-nowrap" :test-id="'identikitDescriptionsListboxDIVButtons' + parseInt(slotProps.index + 1) ">
                <Button
                  icon="pi pi-undo"
                  class="p-button-info p-button-lg p-button-rounded p-button-outlined px-2 py-2 m-1"
                  style="z-index: 9999"
                  :style="{color:'#A956F7'}"
                  v-tooltip.left="'Deshacer cambio'"
                  :disabled="!canRevert(slotProps.option.questionNumber)" 
                  @click="revertAnswer($event ,slotProps.option.questionNumber)"
                />
                <Button
                  icon="pi pi-pencil"
                  class="p-button-info p-button-lg p-button-rounded p-button-outlined px-2 py-2 m-1"
                  style="z-index: 9999"
                  :style="{color: '#FFA800'}"
                  @click="editAnswer($event, slotProps.option.questionNumber)"
                />  
                <Button
                  icon="pi pi-trash"
                  class="p-button-danger p-button-lg p-button-rounded p-button-outlined px-2 py-2 m-1"
                  style="z-index: 9999"
                  @click="confirmDelete($event, slotProps.option)"
                />  
              </div>
            </div>
          </template>
        </Listbox>
        <ConfirmPopup></ConfirmPopup>
      </div>
    </div>
  </div>

</template>

<script>

import { mapState, mapGetters } from 'vuex'
import Listbox from 'primevue/listbox';
import ConfirmPopup from 'primevue/confirmpopup/ConfirmPopup';

export default {
  props: {
    minimal:  String,
    formType: String,
    arrId: String
  },
  name: 'FormIdentikit',
  components: {
    Listbox,
    ConfirmPopup
  },
  data() { 
    return {
      questionToDelete: null,
      description: null,
      orejas: null,
      parpados: null,
      menton: null,
      frente: null,
      nariz: null,
      bigote: null,
      edad: null,
      labios: null,
      barba: null,
      cabello: null,
      cejas: null,
      ojos: null,
      cutis: null,
      boca: null,
      color: null,
      fields: [
        "orejas",
        "parpados",
        "menton",
        "frente",
        "nariz",
        "bigote",
        "edad",
        "labios",
        "barba",
        "cabello",
        "cejas",
        "ojos",
        "cutis",
        'boca',
        'description',
      ],
      
      numberQuestion: 1,
      questions:  [
        "¿RECUERDA CÓMO VESTÍA EL INDIVIDUO?",
        "¿PUEDE DETALLAR EL CABELLO DEL INDIVIDUO?",
        "¿PUEDE DESCRIBIR LA CONTEXTURA FÍSICA DEL INDIVIDUO? ",
        "¿PUEDE INDICAR LA EDAD DEL INDIVIDUO? ",
        "¿ALCANZÓ A VER SI EL INDIVIDUO TENÍA ALGÚN TATUAJE? ",
        "¿VIO SI EL INDIVIDUO TENÍA ALGÚN ACCESORIO PERSONAL? (PIERCING, LENTES..) ",
        "¿CÓMO SE MOVILIZABA EL INDIVIDUO?",
        "¿QUÉ ELEMENTO/S UTILIZÓ EL/LA CONTRAVENTOR/A?",
        "¿ESCUCHÓ O CONOCE EL APODO DEL INDIVIDUO?",
        "¿HAY ALGUNA OTRA DESCRIPCIÓN QUE QUIERA AGREGAR?",
      ],
      listAnswers: [],
      approximateHeight: 170,
      approximateAge: 21,
      textAreaContent: "",
      textAreaContentWithStyles: "",
      selectAnswer: null,
      head: false,
      superiorBody: false, 
      inferiorBody: false,
      feet: false,
      tattooHead: false,
      tattooBody: false,
      tattooArm: false,
      tattooLeg: false,
      headGarment: null,
      headStyle: null,
      headColor: null,
      headColorMultiSelect: [],
      sBodyGarment: null,
      sBodySleeves: null,
      sBodyStyle: null,
      sBodyColor: null,
      sBodyColorMultiSelect: [],
      iBodyGarment: null,
      iBodyStyle: null,
      iBodyColor: null,
      iBodyColorMultiSelect: [],
      feetFootwear: null,
      feetStyle: null,
      feetColor: null,
      feetColorMultiSelect: [],
      hairLength: null,
      hairType: null,
      hairColor: null,
      hairColorMultiSelect: [],
      gender: null,
      height: null,
      volume: null,
      headPart: null,
      headZone: null,
      headZoneMultiSelect: [],
      headType: null,
      bodyPart: null,
      bodyZone: null,
      bodyZoneMultiSelect: [],
      bodyType: null,
      armPart: null,
      armSide: null,
      armZone: null,
      armZoneMultiSelect: [],
      armType: null,
      legPart: null,
      legSide: null,
      legZone: null,
      legZoneMultiSelect: [],
      legType: null,
      accessory: null,
      part: null,
      zone: null,
      zoneMultiSelect: [],
      color: null,
      colorMultiSelect: [],
      mobility: null,
      mobilityColor: null,
      mobilityColorMultiSelect: [],
      mobilityType: null,
      brand: null,
      weaponElement: null,
      weaponCaliber: null,
      weaponColor: null,
      typeOfWeapon: {"id": '', "nombre": '' },
      weaponColorMultiSelect: [],
      stageOfLife: {"id": "2", "nombre": "Juvenil"},
      fieldsMustBeNull: [
        'headGarment',
        'headStyle',
        'headColor',
        'sBodyGarment',
        'sBodySleeves',
        'sBodyStyle',
        'sBodyColor',
        'iBodyGarment',
        'iBodyStyle',
        'iBodyColor',
        'feetFootwear',
        'feetStyle',
        'feetColor',
        'hairLength',
        'hairType',
        'hairColor',
        'gender',
        'height',
        'volume',
        'headPart',
        'headZone',
        'bodyPart',
        'bodyZone',
        'bodyType',
        'armPart',
        'armSide',
        'armZone',
        'armType',
        'legPart',
        'legSide',
        'legZone',
        'legType',
        'accessory',
        'part',
        'zone',
        'color',
        'mobility',
        'mobilityColor',
        'mobilityType',
        'brand',
        'weaponElement',
        'weaponCaliber',
        'weaponColor'
      ],
      fieldsMustBeEmptyArray: [
        'headColorMultiSelect',
        'sBodyColorMultiSelect',
        'iBodyColorMultiSelect',
        'feetColorMultiSelect',
        'hairColorMultiSelect',
        'headZoneMultiSelect',
        'bodyZoneMultiSelect',
        'armZoneMultiSelect',
        'legZoneMultiSelect',
        'colorMultiSelect',
        'mobilityColorMultiSelect',
        'weaponColorMultiSelect',
        'mobilityColorList',
        'mobilityTypeList',
        'brandList',
        'weaponElementList'
      ],
      fieldsMustBeFalse: [
        'head',
        'superiorBody',
        'inferiorBody',
        'feet',
        'tattooHead',
        'tattooBody',
        'tattooArm',
        'tattooLeg'
      ],
      phrasesMustBeNull: [
        'head',
        'sBody',
        'iBody',
        'feet',
        'hair',
        'texture',
        'age',
        'tatooHead',
        'tatooBody',
        'tatooArm',
        'tatooLeg',
        'personalAccessory',
        'mobility',
        'weapon'
      ],
      headPhrase: {
        noStyles: '',
        withStyles: ''
      },
      sBodyPhrase: {
        noStyles: '',
        withStyles: ''
      },
      iBodyPhrase: {
        noStyles: '',
        withStyles: ''
      },
      feetPhrase: {
        noStyles: '',
        withStyles: ''
      },
      hairPhrase: {
        noStyles: '',
        withStyles: ''
      },
      texturePhrase: {
        noStyles: '',
        withStyles: ''
      },
      agePhrase: {
        noStyles: '',
        withStyles: ''
      },
      tatooHeadPhrase: {
        noArticle: '',
        withArticle: ''
      },
      tatooBodyPhrase: {
        noArticle: '',
        withArticle: ''
      },
      tatooArmPhrase: {
        noArticle: '',
        withArticle: ''
      },
      tatooLegPhrase: {
        noArticle: '',
        withArticle: ''
      },
      personalAccessoryPhrase: {
        noArticle: '',
        withArticle: ''
      },
      mobilityPhrase: {
        noStyles: '',
        withStyles: ''
      },
      weaponPhrase: {
        noStyles: '',
        withStyles: ''
      },
    }
  },
  watch: {
    arrId: function () {
      this.updateListAnwserWithStore()
      this.resetAllForm()
    },
  },
  created(){
    this.filterHeadGarment()
  },
  computed: {
     ...mapState({
      orejasList: state => state.siis.orejas,
      parpadosList: state => state.siis.parpados,
      mentonList: state => state.siis.menton,
      frenteList: state => state.siis.frente,
      narizList: state => state.siis.nariz,
      bigoteList: state => state.siis.bigote,
      labiosList: state => state.siis.labios,
      barbaList: state => state.siis.barba,
      bocaList: state => state.siis.boca,
      cabelloList: state => state.siis.cabello,
      cejasList: state => state.siis.cejas,
      ojosList: state => state.siis.ojos,
      cutisList: state => state.siis.cutis,
      
      headGarmentList: state => state.siis.headGarmentList,
      headStyleList: state => state.siis.headStyleList,
      headColorList: state => state.siis.headColorList,
      sBodyGarmentList: state => state.siis.sBodyGarmentList,
      sBodySleevesList: state => state.siis.sBodySleevesList,
      sBodyStyleList: state => state.siis.sBodyStyleList,
      sBodyColorList: state => state.siis.sBodyColorList,
      iBodyGarmentList: state => state.siis.iBodyGarmentList,
      iBodyStyleList: state => state.siis.iBodyStyleList,
      iBodyColorList: state => state.siis.iBodyColorList,
      feetFootwearList: state => state.siis.feetFootwearList,
      feetColorList: state => state.siis.feetColorList,
      feetStyleList: state => state.siis.feetStyleList,
      hairLengthList: state => state.siis.hairLengthList,
      hairTypeList: state => state.siis.hairTypeList,
      hairColorList: state => state.siis.hairColorList,
      genderList: state => state.siis.genderList,
      heightList: state => state.siis.heightList,
      volumeList: state => state.siis.volumeList,
      stageOfLifeList: state => state.siis.stageOfLifeList,
      headPartList: state => state.siis.headPartList,
      headZoneList: state => state.siis.headZoneList,
      headTypeList: state => state.siis.headTypeList,
      bodyPartList: state => state.siis.bodyPartList,
      bodyZoneList: state => state.siis.bodyZoneList,
      bodyTypeList: state => state.siis.bodyTypeList,
      armPartList: state => state.siis.armPartList,
      armSideList: state => state.siis.armSideList,
      armZoneList: state => state.siis.armZoneList,
      armTypeList: state => state.siis.armTypeList,
      legPartList: state => state.siis.legPartList,
      legSideList: state => state.siis.legSideList,
      legZoneList: state => state.siis.legZoneList,
      legTypeList: state => state.siis.legTypeList,
      accessoryList: state => state.siis.accessoryList,
      partList: state => state.siis.partList,
      zoneList: state => state.siis.zoneList,
      colorList: state => state.siis.colorList,
      mobilityList: state => state.siis.mobilityList,
      mobilityColorListEnabled: state => state.siis.mobilityColorListEnabled,
      typeBikeList: state => state.siis.typeBikeList,
      typeMotorcycleList: state => state.siis.typeMotorcycleList,
      typeCarList: state => state.siis.typeCarList,
      brandBikeList: state => state.siis.brandBikeList,
      brandMotorcycleList: state => state.siis.brandMotorcycleList,
      brandCarList: state => state.siis.brandCarList,
      whiteWeaponList: state => state.siis.whiteWeaponList,
      fireWeaponList: state => state.siis.fireWeaponList,
      objectWeaponList: state => state.siis.objectWeaponList,
      weaponColorList: state => state.siis.weaponColorList,
      typeOfWeaponList: state => state.siis.typeOfWeaponList,
    }),
    ...mapGetters('sumario/acusados', [
      'getAcusadoByArrId'
    ]),
    dataDescription: {
      get () { return this.description },
      set (value) { 
        this.$_TemplateForm_update('description', value )
        this.updateAnswer() 
      }
    },
    dataOrejas: {
      get () { return this.orejas },
      set (value) { 
        //this.orejaData = value.nombre
        this.$_TemplateForm_update('orejas', value )
        this.updateAnswer()  
      }
    },
    dataParpados: {
      get () { return this.parpados },
      set (value) { 
        //this.parpadoData = value.nombre
        this.$_TemplateForm_update('parpados', value ) 
        this.updateAnswer() 
      }      
    },
    dataMenton: {
      get () { return this.menton },
      set (value) { 
        //this.mentonData = value.nombre
        this.$_TemplateForm_update('menton', value )
        this.updateAnswer()  
      }
    },
     dataFrente: {
      get () { return this.frente },
      set (value) { 
        //this.frenteData = value.nombre
        this.$_TemplateForm_update('frente', value )
        this.updateAnswer()  
      }
    },
    dataNariz: {
      get () { return this.nariz },
      set (value) { 
        //this.narizData = value.nombre
        this.$_TemplateForm_update('nariz', value ) 
        this.updateAnswer() 
      }
    },
    dataBigote: {
      get () { return this.bigote },
      set (value) { 
        //this.bigoteData = value.nombre
        this.$_TemplateForm_update('bigote', value ) 
        this.updateAnswer() 
      }
    },
    dataEdad: {
      get () { return this.edad },
      set (value) { 
        //this.bigoteData = value.nombre
        this.$_TemplateForm_update('edad', value ) 
        this.updateAnswer() 
      }
    },
    dataLabios: {
      get () { return this.labios },
      set (value) { 
        //this.labiosData = value.nombre
        this.$_TemplateForm_update('labios', value ) 
        this.updateAnswer() 
      }
    },
    dataBarba: {
      get () { return this.barba },
      set (value) { 
        //this.barbaData = value.nombre
        this.$_TemplateForm_update('barba', value ) 
        this.updateAnswer() 
      }
    },
    dataBoca: {
      get () { return this.boca },
      set (value) { 
        //this.bocaData = value.nombre
        this.$_TemplateForm_update('boca', value ) 
        this.updateAnswer() 
      }
    },
    dataCabello: {
      get () { return this.cabello },
      set (value) { 
        //this.cabelloData = value.nombre
        this.$_TemplateForm_update('cabello', value ) 
        this.updateAnswer() 
      }
    },
    dataCejas: {
      get () { 
        return this.cejas
      },
      set (value) { 
        this.$_TemplateForm_update('cejas', value ) 
        this.updateAnswer() 
      }
    },
    dataOjos: {
      get () { return this.ojos },
      set (value) { 
        //this.ojosData = value.nombre
        this.$_TemplateForm_update('ojos', value ) 
        this.updateAnswer() 
      }
    },
    dataCutis: {
      get () { return this.cutis },
      set (value) { 
        //this.cutisData = value.nombre
        this.$_TemplateForm_update('cutis', value ) 
        this.updateAnswer() 
      }
    },
    dataHeadGarment: {  
      get () { return this.headGarment },
      set (value) { 
        if(value.nombre === undefined){
          this.headGarment = null  
        } else{
          this.headGarment = value
        }
        this.createPhrase("head") 
        this.updateAnswer() 
      }
    },
    dataHeadStyle: {  
      get () { return this.headStyle },
      set (value) { 
        if(value.nombre === undefined){
          this.headStyle = null
        } else{
          this.headStyle = value
        }
        this.createPhrase("head") 
        this.updateAnswer() 
      }
    },
    dataHeadColor: {
      get () { return this.headColor },
      set (value) { 
        this.selectOptionsMultiSelect(value, "headColorMultiSelect", "headColor")
        this.createPhrase("head") 
        this.updateAnswer() 
      }
    },
    dataSBodyGarment: {
      get () { return this.sBodyGarment },
      set (value) { 
        if(value.nombre === undefined){
          this.sBodyGarment = null
        } else{
          this.sBodyGarment = value
        }
        this.createPhrase("sBody") 
        this.updateAnswer() 
      }
    },
    dataSBodySleeves: {
      get () { return this.sBodySleeves },
      set (value) { 
        if(value.nombre === undefined){
          this.sBodySleeves = null
        } else{
          this.sBodySleeves = value
        }
        this.createPhrase("sBody") 
        this.updateAnswer() 
      }
    },
    dataSBodyStyle: {
      get () { return this.sBodyStyle },
      set (value) { 
        if(value.nombre === undefined){
          this.sBodyStyle = null
        } else{
          this.sBodyStyle = value
        }
        this.createPhrase("sBody") 
        this.updateAnswer() 
      }
    },
    dataSBodyColor: {
      get () { return this.sBodyColor },
      set (value) { 
        this.selectOptionsMultiSelect(value, "sBodyColorMultiSelect", "sBodyColor")
        this.createPhrase("sBody") 
        this.updateAnswer() 
      }
    },
    dataIBodyGarment: {
      get () { return this.iBodyGarment },
      set (value) { 
        if(value.nombre === undefined){
          this.iBodyGarment = null
        } else{
          this.iBodyGarment = value
        }
        this.createPhrase("iBody") 
        this.updateAnswer() 
      }
    },
    dataIBodyStyle: {
      get () { return this.iBodyStyle },
      set (value) { 
        if(value.nombre === undefined){
          this.iBodyStyle = null
        } else{
          this.iBodyStyle = value
        }
        this.createPhrase("iBody") 
        this.updateAnswer() 
      }
    },
    dataIBodyColor: {
      get () { return this.iBodyColor },
      set (value) { 
        this.selectOptionsMultiSelect(value, "iBodyColorMultiSelect", "iBodyColor")
        this.createPhrase("iBody") 
        this.updateAnswer() 
      }
    },
    dataFeetFootwear: {
      get () { return this.feetFootwear },
      set (value) { 
        if(value.nombre === undefined){
          this.feetFootwear = null
        } else{
          this.feetFootwear = value
        }
        this.createPhrase("feet") 
        this.updateAnswer() 
      }
    },
    dataFeetStyle: {
      get () { return this.feetStyle },
      set (value) { 
        if(value.nombre === undefined){
          this.feetStyle = null
        } else{
          this.feetStyle = value
        }
        this.createPhrase("feet") 
        this.updateAnswer() 
      }
    },
    dataFeetColor: {
      get () { return this.feetColor },
      set (value) { 
        this.selectOptionsMultiSelect(value, "feetColorMultiSelect", "feetColor")
        this.createPhrase("feet") 
        this.updateAnswer() 
      }
    },
    dataHairLength: {
      get () { return this.hairLength },
      set (value) { 
        if(value.nombre === undefined){
          this.hairLength = null
        } else{
          this.hairLength = value
        }
        this.createPhrase("hair") 
        this.updateAnswer() 
      }
    },
    dataHairType: {
      get () { return this.hairType },
      set (value) { 
        if(value.nombre === undefined){
          this.hairType = null
        } else{
          this.hairType = value
        }
        this.createPhrase("hair") 
        this.updateAnswer() 
      }
    },
    dataHairColor: {
      get () { return this.hairColor },
      set (value) { 
        this.selectOptionsMultiSelect(value, "hairColorMultiSelect", "hairColor")
        this.createPhrase("hair") 
        this.updateAnswer() 
      }
    },
    dataGender: {
      get () { return this.gender },
      set (value) { 
        if(value.nombre === undefined){
          this.gender = null
        } else{
          this.gender = value
        }
        this.createPhrase("texture") 
        this.updateAnswer() 
      }
    },
    dataHeight: {
      get () { return this.height },
      set (value) { 
        if(value.nombre === undefined){
          this.height = null
        } else{
          this.height = value
        }
        this.createPhrase("texture") 
        this.updateAnswer() 
      }
    },
    dataVolume: {
      get () { return this.volume },
      set (value) {
        if(value.male === undefined){
          this.volume = null
        } else{
          this.volume = value
        }
        this.createPhrase("texture") 
        this.updateAnswer() 
      }
    },
    dataApproximateHeight: {
      get () { return this.approximateHeight },
      set (value) {
        this.approximateHeight = value
        this.createPhrase("texture") 
        this.updateAnswer() 
      }
    },
    dataApproximateAge: {
      get () { return this.approximateAge },
      set (value) {
        this.approximateAge = value
        if(value <= 11)
          this.dataStageOfLife = {"id": "0", "nombre": "Infante"}
        if(value >= 12 && value <= 17)
          this.dataStageOfLife = {"id": "1", "nombre": "Adolescente"}
        if(value >= 18 && value <= 27)
          this.dataStageOfLife = {"id": "2", "nombre": "Juvenil"}
        if(value >= 28 && value <= 59)
          this.dataStageOfLife = {"id": "3", "nombre": "Adulto"}
        if(value >= 60)
          this.dataStageOfLife = {"id": "4", "nombre": "Adulto mayor"}
        this.createPhrase("age") 
        this.updateAnswer() 
      }
    },
    dataStageOfLife: {
      get () { return this.stageOfLife },
      set (value) {
        if(value.nombre === undefined){
          this.stageOfLife = null
        } else{
          this.stageOfLife = value
        }
        this.createPhrase("age") 
        this.updateAnswer() 
      }
    },
    dataHeadPart: {
      get () { return this.headPart },
      set (value) {
        if(value.nombre === undefined){
          this.headPart = null
        } else{
          this.headPart = value
        }
        this.createPhrase("head") 
        this.updateAnswer() 
      }
    },
    dataHeadZone: {
      get () { return this.headZone },
      set (value) {
        this.selectOptionsMultiSelect(value, "headZoneMultiSelect", "headZone")
        this.createPhrase("head") 
      }
    },
    dataHeadType: {
      get () { return this.headType },
      set (value) {
        if(value.nombre === undefined){
          this.headType = null
        } else{
          this.headType = value
        }
        this.createPhrase("head") 
        this.updateAnswer() 
      }
    },
    dataBodyPart: {
      get () { return this.bodyPart },
      set (value) {
        if(value.nombre === undefined){
          this.bodyPart = null
        } else{
          this.bodyPart = value
        }
        this.createPhrase("body") 
        this.updateAnswer() 
      }
    },
    dataBodyZone: {
      get () { return this.bodyZone },
      set (value) {
        this.selectOptionsMultiSelect(value, "bodyZoneMultiSelect", "bodyZone")
        this.createPhrase("body") 
        this.updateAnswer() 
      }
    },
    dataBodyType: {
      get () { return this.bodyType },
      set (value) {
        if(value.nombre === undefined){
          this.bodyType = null
        } else{
          this.bodyType = value
        }
        this.createPhrase("body") 
      }
    },
    dataArmPart: {
      get () { return this.armPart },
      set (value) {
        if(value.nombre === undefined){
          this.armPart = null
        } else{
          this.armPart = value
        }
        this.createPhrase("arm") 
      }
    },
    dataArmSide: {
      get () { return this.armSide },
      set (value) {
        if(value.nombre === undefined){
          this.armSide = null
        } else{
          this.armSide = value
        }
        this.createPhrase("arm") 
        this.updateAnswer() 
      }
    },
    dataArmZone: {
      get () { return this.armZone },
      set (value) {
        this.selectOptionsMultiSelect(value, "armZoneMultiSelect", "armZone")
        this.createPhrase("arm") 
        this.updateAnswer() 
      }
    },
    dataArmType: {
      get () { return this.armType },
      set (value) {
        if(value.nombre === undefined){
          this.armType = null
        } else{
          this.armType = value
        }
        this.createPhrase("arm") 
        this.updateAnswer() 
      }
    },
    dataLegPart: {
      get () { return this.legPart },
      set (value) {
        if(value.nombre === undefined){
          this.legPart = null
        } else{
          this.legPart = value
        }
        this.createPhrase("leg") 
        this.updateAnswer() 
      }
    },
    dataLegSide: {
      get () { return this.legSide },
      set (value) {
        if(value.nombre === undefined){
          this.legSide = null
        } else{
          this.legSide = value
        }
        this.createPhrase("leg") 
        this.updateAnswer() 
      }
    },
    dataLegZone: {
      get () { return this.legZone },
      set (value) {
        this.selectOptionsMultiSelect(value, "legZoneMultiSelect", "legZone")
        this.createPhrase("leg") 
        this.updateAnswer() 
      }
    },
    dataLegType: {
      get () { return this.legType },
      set (value) {
        if(value.nombre === undefined){
          this.legType = null
        } else{
          this.legType = value
        }
        this.createPhrase("leg") 
        this.updateAnswer() 
      }
    },
    dataAccessory: {
      get () { return this.accessory },
      set (value) {
        if(value.nombre === undefined){
          this.accessory = null
        } else{
          this.accessory = value
        }
        this.createPhrase("personalAccessory") 
        this.updateAnswer() 
      }
    },
    dataPart: {
      get () { return this.part },
      set (value) {
        if(value.nombre === undefined){
          this.part = null
        } else{
          this.part = value
        }
        this.createPhrase("personalAccessory") 
        this.updateAnswer() 
      }
    },
    dataZone: {
      get () { return this.zone },
      set (value) {
        this.selectOptionsMultiSelect(value, "zoneMultiSelect", "zone", ["nombre", "male"])
        this.createPhrase("personalAccessory") 
        this.updateAnswer() 
      }
    },
    dataColor: {
      get () { return this.color },
      set (value) {
        this.selectOptionsMultiSelect(value, "colorMultiSelect", "color")
        this.createPhrase("personalAccessory") 
        this.updateAnswer() 
      }
    },
    dataMobility: {
      get () { return this.mobility },
      set (value) {
        if(value.nombre === undefined){
          this.mobility = null
        } else{
          this.mobility = value
        }
        this.brand = null
        this.mobilityType = null
        this.mobilityColor = null
        this.mobilityColorMultiSelect = []
        this.dataMobilityTypeList = value.nombre
        this.dataBrandList = value.nombre
        this.dataMobilityColorList = value.nombre
        this.createPhrase("mobility")
        this.updateAnswer() 
      }
    },
    dataMobilityColor: {
      get () { return this.mobilityColor },
      set (value) {
        this.selectOptionsMultiSelect(value, "mobilityColorMultiSelect", "mobilityColor")
        this.createPhrase("mobility") 
        this.updateAnswer() 
      }
    },
    dataMobilityType: {
      get () { return this.mobilityType },
      set (value) {
        if(value.nombre === undefined){
          this.mobilityType = null
        } else{
          this.mobilityType = value
        }
        this.createPhrase("mobility")
        this.updateAnswer() 
      }
    },
    dataMobilityTypeList:  {
      get ()  { this.mobilityTypeList },
      set (value)  {
        switch (value) {
          case "A pie":
            this.mobilityTypeList = []
            break;
          case "Bicicleta":
            this.mobilityTypeList = this.typeBikeList
            break;
          case "Motocicleta":
            this.mobilityTypeList = this.typeMotorcycleList
            break;
          case "Automovil":
            this.mobilityTypeList = this.typeCarList
            break;
          default:
            this.mobilityTypeList = []
        }
      }
    },
    dataBrand: {
      get () { return this.brand },
      set (value) {
        if(value.nombre === undefined){
          this.brand = null
        } else{
          this.brand = value
        }
        this.createPhrase("mobility") 
      }
    },
    dataBrandList:  {
      get ()  { this.brand },
      set (value)  {
        switch (value) {
          case "A pie":
            this.brandList  = []
            break;
          case "Bicicleta":
            this.brandList  = this.brandBikeList
            break;
          case "Motocicleta":
            this.brandList  = this.brandMotorcycleList
            break;
          case "Automovil":
            this.brandList  = this.brandCarList
            break;
          default:
            this.brandList  = []
        }
        this.updateAnswer() 
      }
    },
    dataMobilityColorList:  {
      get ()  { this.mobilityColorList },
      set (value)  {
        if(value === "A pie" || value === undefined ){
          this.mobilityColorList = []
        } else  {
          this.mobilityColorList = this.mobilityColorListEnabled
        }
        this.updateAnswer() 
      }
    },
    dataWeaponType:  {
      get ()  { this.weaponType },
      set (value)  {
        this.weaponType = value
        this.updateAnswer() 
      }
    },
    dataWeaponElement: {
      get () { return this.weaponElement },
      set (value) {
        if(value.nombre === undefined){
          this.weaponElement = null
        } else{
          this.weaponElement = value
        }
        this.createPhrase("weapon") 
        this.updateAnswer() 
      }
    },
    dataWeaponElementList:  {
      get ()  { this.weaponElement },
      set (value)  {
        switch (value) {
          case "Arma blanca":
            this.weaponElementList  =  this.whiteWeaponList
            break;
          case "Arma de fuego":
            this.weaponElementList  = this.fireWeaponList
            break;
          case "Objeto":
            this.weaponElementList  = this.objectWeaponList
            break;
          default:
            this.brandList  = []
        }
        this.updateAnswer() 
      }
    },
    dataWeaponCaliber: {
      get () { return this.weaponCaliber },
      set (value) {
        if(value === undefined){
          this.weaponCaliber = null
        } else{
          this.weaponCaliber = value
        }
        this.createPhrase("weapon") 
        this.updateAnswer() 
      }
    },
    dataWeaponColor: {
      get () { return this.weaponColor },
      set (value) {
        this.selectOptionsMultiSelect(value, "weaponColorMultiSelect", "weaponColor")
        this.createPhrase("weapon") 
        this.updateAnswer() 
      }
    },
    dataTypeOfWeapon: {
      get () { return this.typeOfWeapon },
      set (value) {
        if(value.nombre === undefined){
          this.typeOfWeapon = {"id": '', "nombre": '' }
        } else{
          this.typeOfWeapon = value
        }
        this.weaponElement = null
        this.weaponColor = null
        this.weaponColorMultiSelect = []
        this.mobilityColorMultiSelect = []
        this.weaponCaliber = null
        this.dataWeaponElementList = value.nombre
        this.textAreaContent = ""
        this.textAreaContentWithStyles = ""
        this.updateAnswer() 
      }
    },
  },
   methods: {                     
    updateAnswer(){
      this.addAnswer()
      this.$store.dispatch("sumario/textResetEditsCounter","Contravener")
      this.$emit("updateValue", "numberQuestion", this.numberQuestion)
    },                   
    filterOrejas (event) {
      this.filteredOrejas = this.orejasList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterParpados (event) {
      this.filteredParpados = this.parpadosList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterMenton (event) {
      this.filteredMenton = this.mentonList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterFrente (event) {
      this.filteredFrente = this.frenteList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterNariz (event) {
      this.filteredNariz = this.narizList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterBigote (event) {
      this.filteredBigote = this.bigoteList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterLabios (event) {
      this.filteredLabios = this.labiosList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterBarba (event) {
      this.filteredBarba = this.barbaList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterBoca (event) {
      this.filteredBoca = this.bocaList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterCabello (event) {
      this.filteredCabello = this.cabelloList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterCejas (event) {
      this.filteredCejas = this.cejasList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterOjos (event) {
      this.filteredOjos = this.ojosList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterCutis (event) {
      this.filteredCutis = this.cutisList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },  
    filterHeadGarment (event) {
      this.filteredHeadGarment= this.headGarmentList.filter((item) => item.nombre.toLowerCase() >= 0 );
    },  
    filterHeadStyle(event){
      this.filteredHeadStyle = this.headStyleList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterHeadColor(event){
      this.filteredHeadColor = this.headColorList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterSBodyGarment(event){
      this.filteredSBodyGarment = this.sBodyGarmentList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterSBodySleeves(event){
      this.filteredSBodySleeves = this.sBodySleevesList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterSBodyStyle(event){
      this.filteredSBodyStyle = this.sBodyStyleList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterSBodyColor(event){
      this.filteredSBodyColor = this.sBodyColorList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterIBodyGarment(event){
      this.filteredIBodyGarment = this.iBodyGarmentList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterIBodyStyle(event){
      this.filteredIBodyStyle = this.iBodyStyleList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterIBodyColor(event){
      this.filteredIBodyColor = this.iBodyColorList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterFeetFootwear(event){
      this.filteredFeetFootwear = this.feetFootwearList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterFeetStyle(event){
      this.filteredFeetStyle = this.feetStyleList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterFeetColor(event){
      this.filteredFeetColor = this.feetColorList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterHairLength(event){
      this.filteredHairLength = this.hairLengthList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterHairType(event){
      this.filteredHairType = this.hairTypeList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterHairColor(event){
      this.filteredHairColor = this.hairColorList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterGender(event){
      this.filteredGender = this.genderList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterHeight(event){
      this.filteredHeight = this.heightList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterVolume(event){
      this.filteredVolume = this.volumeList.filter((item) => item.male.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterStageOfLife(event){
      this.filteredStageOfLife = this.stageOfLifeList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterHeadPart(event){
      this.filteredHeadPart = this.headPartList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterHeadZone(event){
      this.filteredHeadZone = this.headZoneList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterHeadType(event){
      this.filteredHeadType = this.headTypeList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterBodyPart(event){
      this.filteredBodyPart = this.bodyPartList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterBodyZone(event){
      this.filteredBodyZone = this.bodyZoneList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterBodyType(event){
      this.filteredBodyType = this.bodyTypeList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterArmPart(event){
      this.filteredArmPart = this.armPartList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterArmSide(event){
      this.filteredArmSide = this.armSideList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterArmZone(event){
      this.filteredArmZone = this.armZoneList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterArmType(event){
      this.filteredArmType = this.armTypeList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterLegPart(event){
      this.filteredLegPart = this.legPartList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterLegSide(event){
      this.filteredLegSide = this.legSideList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterLegZone(event){
      this.filteredLegZone = this.legZoneList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterLegType(event){
      this.filteredLegType = this.legTypeList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterAccessory(event){
      this.filteredAccessory = this.accessoryList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterPart(event){
      this.filteredPart = this.partList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterZone(event){
      this.filteredZone = this.zoneList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterColor(event){
      this.filteredColor = this.colorList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterMobility(event){
      this.filteredMobility = this.mobilityList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterMobilityColor(event){
      this.filteredMobilityColor = this.mobilityColorList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterMobilityType(event){
      this.filteredMobilityType = this.mobilityTypeList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterBrand(event){
      this.filteredBrand = this.brandList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterWeaponElement(event){
      this.filteredWeaponElement = this.weaponElementList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterWeaponColor(event){
      this.filteredWeaponColor = this.weaponColorList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    filterTypeOfWeapon(event){
      this.filteredTypeOfWeapon = this.typeOfWeaponList.filter((item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );
    },
    setData(data){
      for (let i of this.fields) {
        this[i] = data[i] || null
      }
    },
    $_TemplateForm_update( field, value ){
      this.$emit("updateValue", field, value)
    },
    handleUpdateDescriptionOffender(){
      this.$emit("updateValue", "listAnswers", this.listAnswers)
    },
    nextQuestion(){
      this.addAnswer()
      this.$store.dispatch("sumario/textResetEditsCounter","Contravener")
      this.numberQuestion = parseInt(this.numberQuestion) + 1
      this.$emit("updateValue", "numberQuestion", this.numberQuestion)
      this.textAreaContent = ""
      this.textAreaContentWithStyles = ""
    },
    addAnswer(lastQuestion){
      if(this.textAreaContent === "" && lastQuestion === true){
        this.$emit("updateValue", "numberQuestion", 1)
        this.$emit('finishIdentikit')
        return
      }
      if(this.textAreaContent === "")
        return
      const alreadyExists = this.listAnswers.find(answer => answer.questionNumber === this.numberQuestion)
      if(alreadyExists === undefined){
        this.listAnswers.push({question: this.questions[this.numberQuestion - 1], questionNumber: this.numberQuestion, answer: "Respuesta P." + this.numberQuestion  + ": " + this.textAreaContent})
        this.handleUpdateDescriptionOffender()
      } else{
        let indexQuestion = this.listAnswers.indexOf(alreadyExists)
        this.listAnswers[indexQuestion] = {question: this.questions[this.numberQuestion - 1], questionNumber: this.numberQuestion, answer: "Respuesta P." + this.numberQuestion  + ": " + this.textAreaContent}
        //forcing a re-render of Listbox
        this.listAnswers.push("")
        this.listAnswers.splice(this.listAnswers.length - 1, 1)
        this.handleUpdateDescriptionOffender()
      }
      if(lastQuestion === true)
        this.$emit('finishIdentikit')

    },
    canRevert(questionNumber) {
      const accused = this.$store.getters['sumario/acusados/getAcusadoByArrId'](this.arrId);
      const originalAnswer = accused?.identikit.find(item => +item.questionNumber === questionNumber);
      const currentAnswer = this.textAreaContent
      const canRevert = !!originalAnswer && originalAnswer.answer.split(":")[1].trim() !== currentAnswer;
      return canRevert // Retorna true si hay cambios
    },
    revertAnswer(event, questionNumber){
      this.$confirm.require({
        target: event.currentTarget,
        message: '¿Está seguro que quiere restablecer la descripción?',
        icon: 'pi pi-question-circle',
        acceptClass: "p-button-danger",
        acceptLabel: "Sí",
        rejectLabel: "No",
        accept: () => {
          this.$store.dispatch('sumario/acusados/revertToOriginalIdentikitQuestion', {
            arrId: this.arrId,
            questionNumber: questionNumber
          })
          const originalAnswer = this.$store.getters['sumario/acusados/getOriginalAnswerByQuestion'](this.arrId, questionNumber);
          this.textAreaContent = originalAnswer.split(":")[1].trim()
        },
        reject: () => { }
      });
    },
    editAnswer(event, question){
      this.$confirm.require({
        target: event.currentTarget,
        message: '¿Está seguro que quiere editar la descripción?',
        icon: 'pi pi-question-circle',
        acceptClass: "p-button-danger",
        acceptLabel: "Sí",
        rejectLabel: "No",
        accept: () => {  
          const arrId = this.arrId; 
          let answerToEdit = this.listAnswers.filter(answer => answer.questionNumber === question)
          this.$store.dispatch('sumario/acusados/saveOriginalIdentikitQuestion', {
            arrId,
            questionNumber: question,
            answer: answerToEdit[0].answer
          });
          this.numberQuestion = question
          // trim the phrase 'Repuesta P...'
          answerToEdit = answerToEdit[0].answer.replace(`Respuesta P.${question}: `, '')
          this.textAreaContent = answerToEdit
          this.$refs.textAreaContentHTML.$el.focus()
          window.scrollTo(0,0)
        },
        reject: () => { }
      });
    },
    confirmDelete(event, question){
      this.$confirm.require({
        target: event.currentTarget,
        message: '¿Está seguro que quiere eliminar la descripción?',
        icon: 'pi pi-question-circle',
        acceptClass: "p-button-danger",
        acceptLabel: "Sí",
        rejectLabel: "No",
        accept: () => {
          try {
              this.deleteAnswer(question)
          } catch (error) {
            console.error("Error al eliminar el archivo:", error);
          } 
        },
        reject: () => { }
      }); 
    },
    deleteAnswer(question){
      let indexQuestion = this.listAnswers.indexOf(question)
      this.listAnswers.splice(indexQuestion, 1)
      this.handleUpdateDescriptionOffender()
      this.textAreaContent = ''
    },
    fillTextAreaContent(){
      let arrayPhrase
      let stylesArrayPhrase 
      this.textAreaContent = ''
      
      if(this.numberQuestion === 1){
        this.textAreaContent = 'Vestía '
        this.textAreaContentWithStyles = 'Vestía '
      
        let headPhrase = this.headPhrase.noStyles !== '' ? this.headPhrase.noStyles + ',' : ''
        let sBodyPhrase = this.sBodyPhrase.noStyles !== '' ? this.sBodyPhrase.noStyles + ',' : ''
        let iBodyPhrase = this.iBodyPhrase.noStyles !== '' ? this.iBodyPhrase.noStyles + ',' : ''
        let feetPhrase = this.feetPhrase.noStyles !== '' ? this.feetPhrase.noStyles : ''

        let stylesHeadPhrase = this.headPhrase.withStyles !== '' ? this.headPhrase.withStyles + ',' : ''
        let stylesSBodyPhrase = this.sBodyPhrase.withStyles !== '' ? this.sBodyPhrase.withStyles + ',' : ''
        let stylesIBodyPhrase = this.iBodyPhrase.withStyles !== '' ? this.iBodyPhrase.withStyles + ',' : ''
        let stylesFeetPhrase = this.feetPhrase.withStyles !== '' ? this.feetPhrase.withStyles : ''
      
        arrayPhrase = [headPhrase, sBodyPhrase, iBodyPhrase, feetPhrase]
        stylesArrayPhrase = [stylesHeadPhrase, stylesSBodyPhrase, stylesIBodyPhrase, stylesFeetPhrase]
      }

      if(this.numberQuestion === 2){
        this.textAreaContent = 'Tenía cabello '
        this.textAreaContentWithStyles = 'Tenía cabello '

        arrayPhrase = [this.hairPhrase.noStyles]
        stylesArrayPhrase = [this.hairPhrase.withStyles]
      }

      if(this.numberQuestion === 3){
        this.textAreaContent = 'Una persona '
        this.textAreaContentWithStyles = 'Una persona '
        
        arrayPhrase = [this.texturePhrase.noStyles]
        stylesArrayPhrase = [this.texturePhrase.withStyles]
      }

      if(this.numberQuestion === 4){
        this.textAreaContent = 'Era '
        this.textAreaContentWithStyles = '<p style="color: #dc3545 ; display: inline">Era</p> '
        
        arrayPhrase = [this.agePhrase.noStyles]
        stylesArrayPhrase = [this.agePhrase.withStyles]
      }

      if(this.numberQuestion === 5){        
        let tatooHeadPhrase = this.tatooHeadPhrase.withArticle !== '' ? this.tatooHeadPhrase.withArticle + ',' : ''
        let tatooBodyPhrase = this.tatooBodyPhrase.withArticle !== '' ? this.tatooBodyPhrase.withArticle + ',' : ''
        let tatooArmPhrase = this.tatooArmPhrase.withArticle !== '' ? this.tatooArmPhrase.withArticle + ',' : ''
        let tatooLegPhrase = this.tatooLegPhrase.withArticle !== '' ? this.tatooLegPhrase.withArticle + ',' : ''

        let tatooHeadPhraseNoArticle = this.tatooHeadPhrase.noArticle !== '' ? this.tatooHeadPhrase.noArticle + ',' : ''
        let tatooBodyPhraseNoArticle = this.tatooBodyPhrase.noArticle !== '' ? this.tatooBodyPhrase.noArticle + ',' : ''
        let tatooArmPhraseNoArticle = this.tatooArmPhrase.noArticle !== '' ? this.tatooArmPhrase.noArticle + ',' : ''
        let tatooLegPhraseNoArticle = this.tatooLegPhrase.noArticle !== '' ? this.tatooLegPhrase.noArticle + ',' : ''
        
        arrayPhrase = [tatooHeadPhrase, tatooBodyPhrase, tatooArmPhrase, tatooLegPhrase]
        let arrayPhraseNoArticle = [tatooHeadPhraseNoArticle, tatooBodyPhraseNoArticle, tatooArmPhraseNoArticle, tatooLegPhraseNoArticle]

        arrayPhrase = arrayPhrase.filter(field => field !== '')
        arrayPhraseNoArticle = arrayPhraseNoArticle.filter(field => field !== '')
        
        let i = 0
     
        arrayPhrase.map( phrase => {
          if(phrase !== ''){
            if(i === arrayPhrase.length - 1){
              arrayPhraseNoArticle[i].replace(',', '.')
              phrase.replace(',', '.') 
            }
            if(this.textAreaContent === ''){
              this.textAreaContent = phrase.charAt(3) === 'a' ? 'Tatuada ' : 'Tatuado ' 
              this.textAreaContent =  this.textAreaContent + phrase + " "
            } else{
              this.textAreaContent =  this.textAreaContent + arrayPhraseNoArticle[i] + " "
            }
          }
          i++
        });
        return
      }

      if(this.numberQuestion === 6){
        this.textAreaContent = 'Portaba '
       
        arrayPhrase = [this.personalAccessoryPhrase.noStyles]
        stylesArrayPhrase = [this.personalAccessoryPhrase.noStyles]
      }

      if(this.numberQuestion === 7){
        this.textAreaContent = 'Se trasladaba '
       
        arrayPhrase = [this.mobilityPhrase.noStyles]
        stylesArrayPhrase = [this.mobilityPhrase.noStyles]
      }

      if(this.numberQuestion === 8){     
        arrayPhrase = [this.weaponPhrase.noStyles]
        stylesArrayPhrase = [this.weaponPhrase.noStyles]
      }

      let i = 0

      arrayPhrase.map( phrase => {
        if(phrase !== ''){
          this.textAreaContent =  this.textAreaContent + phrase + " "
          this.textAreaContentWithStyles =  this.textAreaContentWithStyles + stylesArrayPhrase[i] + " "
        }
        i++
      });

      if(
        this.textAreaContent === 'Vestía ' || 
        this.textAreaContent === 'Tenía cabello ' ||       
        this.textAreaContent === 'Portaba '
      )
        this.textAreaContent = ''
      if(
        this.textAreaContentWithStyles === 'Vestía ' ||
        this.textAreaContentWithStyles === 'Tenía cabello ' ||      
        this.textAreaContentWithStyles === 'Portaba '
      )
        this.textAreaContentWithStyles = ''
    },
    createPhrase(phraseType){
      this[phraseType + "Phrase"] = {
        noStyles: '',
        withStyles: ''
      }
      
      let stylesArrayFields
      let arrayFields

      if(this.numberQuestion === 1){
        let stylesGarment = this[phraseType + 'Garment'] !== null && this[phraseType + 'Garment'] !== undefined ? '<p style="color: #dc3545 ; display: inline">' + this[phraseType + 'Garment'].article + '</p>' + ' ' + '<b>' + this[phraseType + 'Garment'].nombre.toLowerCase() + '</b>'  : ''
        let stylesSleeves = this[phraseType + 'Sleeves'] !== null && this[phraseType + 'Sleeves'] !== undefined ? '<b>' + this[phraseType + 'Sleeves'].nombre.toLowerCase() + '</b>' : ''
        let stylesFootwear = this[phraseType + 'Footwear'] !== null && this[phraseType + 'Footwear'] !== undefined ? '<p style="color: #dc3545 ; display: inline">' + this[phraseType + 'Footwear'].article + '</p>' + ' ' + '<b>' + this[phraseType + 'Footwear'].nombre.toLowerCase() + '</b>': ''
        let stylesStyle = this[phraseType + 'Style'] !== null ?  '<b>' + this[phraseType + 'Style'].nombre.toLowerCase() + '</b>' : ''
        let stylesColor = this[phraseType + 'Color'] !== null ? 'de color ' + '<b>' + this[phraseType + 'Color'].toLowerCase().replaceAll('-', '')  + '</b>' : ''
  
        let garment = this[phraseType + 'Garment'] !== null && this[phraseType + 'Garment'] !== undefined ? this[phraseType + 'Garment'].article + ' ' + this[phraseType + 'Garment'].nombre.toLowerCase() : ''
        let sleeves = this[phraseType + 'Sleeves'] !== null && this[phraseType + 'Sleeves'] !== undefined ? this[phraseType + 'Sleeves'].nombre.toLowerCase() : ''
        let footwear = this[phraseType + 'Footwear'] !== null && this[phraseType + 'Footwear'] !== undefined ? this[phraseType + 'Footwear'].article + ' ' + this[phraseType + 'Footwear'].nombre.toLowerCase() : ''
        let style = this[phraseType + 'Style'] !== null ? this[phraseType + 'Style'].nombre.toLowerCase() : ''
        let color = this[phraseType + 'Color'] !== null ? 'de color ' + this[phraseType + 'Color'].toLowerCase().replaceAll('-', '')  : ''
  
        stylesArrayFields = [stylesGarment, stylesSleeves, stylesFootwear, stylesStyle, stylesColor]
        arrayFields = [garment, sleeves, footwear, style, color]
      }

      if(this.numberQuestion === 2){
        let stylesLength = this[phraseType + 'Length'] !== null && this[phraseType + 'Length'] !== undefined ? '<b>' + this[phraseType + 'Length'].nombre.toLowerCase() + '</b>' : ''
        let stylesType = this[phraseType + 'Type'] !== null && this[phraseType + 'Type'] !== undefined ? '<b>' + this[phraseType + 'Type'].nombre.toLowerCase() + '</b>' : ''
        let stylesColor = this[phraseType + 'Color'] !== null && this[phraseType + 'Color'] !== undefined ? '<p style="color: #dc3545 ; display: inline">' + 'de color ' + '</p>' + '<b>' + this[phraseType + 'Color'].toLowerCase().replaceAll('-', '') + '</b>' : ''
        
        let length = this[phraseType + 'Length'] !== null && this[phraseType + 'Length'] !== undefined ? this[phraseType + 'Length'].nombre.toLowerCase() : ''
        let color = this[phraseType + 'Color'] !== null && this[phraseType + 'Color'] !== undefined ? 'de color ' + this[phraseType + 'Color'].toLowerCase().replaceAll('-', '') : ''
        let type = this[phraseType + 'Type'] !== null && this[phraseType + 'Type'] !== undefined ? this[phraseType + 'Type'].nombre.toLowerCase() : ''

        stylesArrayFields = [stylesLength, stylesType, stylesColor]
        arrayFields = [length, type, color]
      }

      if(this.numberQuestion === 3){
        let stylesGender = this.gender !== null && this.gender !== undefined ? '<p style="color: #dc3545 ; display: inline">de sexo</p> ' + '<b>' + this.gender.nombre.toLowerCase() + '</b>' : ''
        let stylesHeight = this.height !== null && this.height !== undefined ? '<p style="color: #dc3545 ; display: inline">de estatura</p> ' + '<b>' + this.height.nombre.toLowerCase() + '</b>,' : ''
        let stylesVolume = this.volume !== null && this.volume !== undefined ? this.gender.nombre === 'Masculino' ? ' <b>' + this.volume.male.toLowerCase() + '</b>,' : ' <b>' + this.volume.femenine.toLowerCase() + '</b>,'  : ''
        let stylesApproximateHeight = this.approximateHeight !== null && this.approximateHeight !== undefined ? '<p style="color: #dc3545 ; display: inline">estatura aproximada de</p> ' + '<b>' + this.approximateHeight + ' cm' + '</b>' : ''

        let gender = this.gender !== null && this.gender !== undefined ? 'de sexo ' + this.gender.nombre.toLowerCase() : ''
        let height = this.height !== null && this.height !== undefined ? 'de estatura ' + this.height.nombre.toLowerCase() + ',' : ''
        let volume = this.volume !== null && this.volume !== undefined ? this.gender.nombre === 'Masculino' ? ' ' + this.volume.male.toLowerCase() + ',' : ' ' + this.volume.femenine.toLowerCase() + ',' : ''
        let approximateHeight = this.approximateHeight !== null && this.approximateHeight !== undefined ? 'estatura aproximada de ' + this.approximateHeight + ' cm' : ''

        stylesArrayFields = [stylesGender, stylesHeight, stylesVolume, stylesApproximateHeight]
        arrayFields = [gender, height, volume, approximateHeight]
      }

      if(this.numberQuestion === 4){
        let stylesStageOfLife = this.stageOfLife !== null && this.stageOfLife !== undefined ? '<p style="color: #dc3545 ; display: inline">un</p> ' + '<b>' + this.stageOfLife.nombre.toLowerCase() + '</b>' : ''
        let stylesApproximateAge = this.approximateAge !== null && this.approximateAge !== undefined ? '<p style="color: #dc3545 ; display: inline">de</p> ' + '<b>' + this.approximateAge + '</b>' + ' <p style="color: #dc3545 ; display: inline">años aproximadamente</p>': ''
       
        let stageOfLife = this.stageOfLife !== null && this.stageOfLife !== undefined ? 'un ' + this.stageOfLife.nombre.toLowerCase() : ''
        let approximateAge = this.approximateAge !== null && this.approximateAge !== undefined ? 'de ' + this.approximateAge + ' años aproximadamente': ''
       
        stylesArrayFields =  [stylesStageOfLife, stylesApproximateAge]
        arrayFields = [stageOfLife, approximateAge]
      }

      if(this.numberQuestion === 5){
        let phrase = "tatoo" + phraseType.charAt(0).toUpperCase() + phraseType.slice(1) + "Phrase"
        
        this[phrase] = {
          noArticle: '',
          withArticle: ''
        }
       
        let part = this[phraseType + "Part"] !== null && this[phraseType + "Part"] !== undefined ? ' en ' + this[phraseType + "Part"].article.toLowerCase() + ' ' + this[phraseType + "Part"].nombre.toLowerCase() : ''
        let side = this[phraseType + "Side"] !== null && this[phraseType + "Side"] !== undefined ?  this[phraseType + "Part"].article === 'la'  ? this[phraseType + "Side"].nombre.toLowerCase() + ' ' : this[phraseType + "Side"].male.toLowerCase() + ' ' : ''
        let zone = this[phraseType + "Zone"] !== null && this[phraseType + "Zone"] !== undefined ? 'en la zona ' + this[phraseType + "Zone"].toLowerCase().replaceAll('-', '') : ''
        let type = this[phraseType + "Type"] !== null && this[phraseType + "Type"] !== undefined ? ' ' + this[phraseType + "Type"].article.toLowerCase() + ' '  + this[phraseType + "Type"].nombre.toLowerCase() : ''
        let typeNoArticle = this[phraseType + "Type"] !== null && this[phraseType + "Type"] !== undefined ?  'otro de tipo '  + this[phraseType + "Type"].nombre.toLowerCase() : ''
       
        arrayFields = [type, part, side, zone]
        let arrayFieldsNoArticle = [typeNoArticle, part, side, zone]

        arrayFields = arrayFields.filter(field => field !== '')
        arrayFieldsNoArticle = arrayFieldsNoArticle.filter(field => field !== '')

        let i = 0 
         
        arrayFields.map( field => {
          if(field !== ''){
            this[phrase].noArticle = this[phrase].noArticle + arrayFieldsNoArticle[i]
            this[phrase].withArticle = this[phrase].withArticle +   field
          }
          if(i !== arrayFields.length - 1){
            this[phrase].noArticle = this[phrase].noArticle + " "
            this[phrase].withArticle = this[phrase].withArticle + " "
          }
          i++
        })
        this.fillTextAreaContent()
        return
      }

      if(this.numberQuestion === 6){
        let accessory = this.accessory !== null && this.accessory !== undefined ? this.accessory.article.toLowerCase() + ' ' + this.accessory.nombre.toLowerCase() : ''
        let part = this.part !== null && this.part !== undefined ? 'en su ' + this.part.nombre.toLowerCase() : ''
        let zone = this.zone !== null && this.zone !== undefined ? (this.part === null || this.part === undefined || this.part.gender === "femenine" ) ?  this.zone.nombre.toLowerCase().replaceAll('-', '') : this.zone.male.toLowerCase().replaceAll('-', '') : ''
        let color = this.color !== null && this.color !== undefined ? 'de color ' + this.color.toLowerCase().replaceAll('-', '')  : ''

        arrayFields = [accessory, part, zone, color]
        stylesArrayFields = [accessory, part, zone, color]
      }

      if(this.numberQuestion === 7){
        let mobility = this.mobility !== null && this.mobility !== undefined ? this.mobility.article.toLowerCase() + ' ' + this.mobility.nombre.toLowerCase() : ''
        let mobilityType = this.mobilityType !== null && this.mobilityType !== undefined ? this.mobilityType.nombre.toLowerCase() : ''
        let brand = this.brand !== null && this.brand !== undefined ? 'marca ' + this.brand.nombre : ''
        let mobilityColor = this.mobilityColor !== null && this.mobilityColor !== undefined ? 'de color ' + this.mobilityColor.toLowerCase().replaceAll('-', '') : ''
        
        arrayFields = [mobility, mobilityType, brand, mobilityColor]
        stylesArrayFields = [mobility, mobilityType, brand, mobilityColor]
      }

      if(this.numberQuestion === 8){
        let weaponElement = this.weaponElement !== null && this.weaponElement !== undefined ? this.weaponElement.article +  ' ' + this.weaponElement.nombre.toLowerCase() : ''
        let weaponCaliber = this.weaponCaliber !== null && this.weaponCaliber !== undefined ? 'de calibre ' + this.weaponCaliber : ''
        let weaponColor = this.weaponColor !== null && this.weaponColor !== undefined ? 'de color ' + this.weaponColor.toLowerCase().replaceAll('-', '') : ''

        arrayFields = [weaponElement, weaponCaliber, weaponColor]
        stylesArrayFields = [weaponElement, weaponCaliber, weaponColor]
      }

      arrayFields = arrayFields.filter(field => field !== '')
      stylesArrayFields = stylesArrayFields.filter(field => field !== '')
      
      let i = 0

      arrayFields.map( field => {
        if(field !== ''){
          this[phraseType + "Phrase"].noStyles = this[phraseType + "Phrase"].noStyles + field
          this[phraseType + "Phrase"].withStyles =  this[phraseType + "Phrase"].withStyles + stylesArrayFields[i] 
        }
        if(i !== arrayFields.length - 1){
          this[phraseType + "Phrase"].noStyles = this[phraseType + "Phrase"].noStyles + " "
          this[phraseType + "Phrase"].withStyles =  this[phraseType + "Phrase"].withStyles + " " 
        }
        i++
      })
      this.fillTextAreaContent()
    },
    selectOptionsMultiSelect(value, nameMultiSelect, nameString, genders){
      //the input is null
      if(value.nombre === undefined){
        this[nameString] = null
        this[nameMultiSelect] = []
        return
      }
      //the first option is added
      if(this[nameMultiSelect].length === 0){
        //verify if the value have gender
        this[nameMultiSelect].push(genders ? value : value.nombre)
        this[nameString] = this[nameMultiSelect][0]
        return
      }
      if(this[nameMultiSelect].length > 0){
        const alreadyExists = genders ? this[nameMultiSelect].find(option => option === value[genders[0]] || option === value[genders[1]] || option[genders[0]] === value[genders[0]] || option[genders[1]] === value[genders[1]])  : this[nameMultiSelect].find(option => option === value.nombre)
        let indexOption = this[nameMultiSelect].indexOf(alreadyExists)
        if(alreadyExists === undefined){
          //the added option did not exist previously or must be updated
          this[nameMultiSelect][indexOption] === undefined ? this[nameMultiSelect].push(value.nombre) :  this[nameMultiSelect][indexOption] = value.nombre
          this.createString(nameString, nameMultiSelect, genders)
        } else{
          //the selected option is unchecked
          this[nameMultiSelect].splice(indexOption, 1)
          this[nameMultiSelect][0] === undefined ? this[nameString] = null : this.createString(nameString, nameMultiSelect, genders)
        }
      }
    },
    createString(nameString, nameMultiSelect, genders){
      if(genders){ 
        //if the value have gender
        this[nameString] = {
          [genders[0]]: '',
          [genders[1]]: ''
        }
        let optionOfList 
        let indexOption 
        let trueOption 
        let i = 0
        this[nameMultiSelect].map( option => {
          trueOption = typeof option === 'object' ? option[genders[0]] : option
          optionOfList = this[nameString + "List"].find(element => element[genders[0]] === trueOption)
          indexOption = this[nameString + "List"].indexOf(optionOfList)
          if(i === 0){
            this[nameString][genders[0]] = this[nameString + "List"][indexOption][genders[0]] 
            this[nameString][genders[1]] = this[nameString + "List"][indexOption][genders[1]] 
          } else{
            this[nameString][genders[0]] = this[nameString][genders[0]] + ' - ' + this[nameString + "List"][indexOption][genders[0]] 
            this[nameString][genders[1]] = this[nameString][genders[1]] + ' - ' + this[nameString + "List"][indexOption][genders[1]] 
          }
          i++
        })
      } else{
        //if the value haven't gender
        this[nameString] = ''
        let i = 0
        this[nameMultiSelect].map( option => {
          if(i === 0){
            this[nameString] = option
          } else{
            this[nameString] = this[nameString] + ' - ' + option
          }
          i++
        })
      }
    },
    updateListAnwserWithStore(){
      let offender =  this.getAcusadoByArrId(this.$router.currentRoute.query.id)
      if(offender === undefined){
        this.listAnswers = []
      } else{
        this.listAnswers = [ ...offender.identikit ]
        this.listAnswers = this.listAnswers.map(obj => Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, k === 'questionNumber' && !isNaN(Number(v)) ? Number(v) : v])));
      }
    },
    resetAllForm(){
      //resetting all fields to default values
      this.fieldsMustBeNull.map( field => {
        this[field] = null
      })
      this.fieldsMustBeFalse.map( field => {
        this[field] = false
      })
      this.fieldsMustBeEmptyArray.map( field => {
        this[field] = []
      })
      this.phrasesMustBeNull.map( phrase => {
        if(this[phrase + "Phrase"].hasOwnProperty("noStyles")){
          this[phrase + "Phrase"] = {
            noStyles: '',
            withStyles: ''
          }
        }
        if(this[phrase + "Phrase"].hasOwnProperty("noArticle")){
          this[phrase + "Phrase"] = {
            noArticle: '',
            withArticle: ''
          }
        }
      })
      this.numberQuestion = this.rememberQuestion()
      this.textAreaContent =  ""
      this.textAreaContentWithStyles = ""
      this.typeOfWeapon = {"id": '', "nombre": '' }
      this.stageOfLife = {"id": "2", "nombre": "Juvenil"}
      this.approximateHeight = 170
      this.approximateAge = 21
    },
    backQuestion(){
      this.addAnswer()
      this.numberQuestion = parseInt(this.numberQuestion) - 1;
      this.$emit("updateValue", "numberQuestion", this.numberQuestion)
    },
    rememberQuestion(){
      let identikitQuestion
      if(typeof this.getAcusadoByArrId(this.arrId) === "undefined"){
        identikitQuestion = 1
      } else{
        identikitQuestion = this.getAcusadoByArrId(this.arrId).identikitQuestion
      }
      return identikitQuestion
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    
  }
  .map {
    display: block;
    margin-top: 10px;
    height: 60vh;
    width: 50vw;
  }
  .title {
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 1rem;
  }
  .title-toolbar {
    display: flex;
    justify-content: space-between;
  }
  .search > input {
    margin-right: 10px;
  }
  .forms > div, .labeled, .inputType {
    display: flex;
    
    /* align-items:flex-start;
    flex-wrap: wrap; */
    flex-wrap: wrap;
    
  }
  .inputType label {
    display: none;
  }
  .forms > div, .labeled {
    flex-direction: column;
  }
  .forms > div.actions {
    flex-direction: row-reverse;
    padding-right: 20px;
  }
  .forms > div .input {
    min-width: 20vw;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  div.flex-inline {
    display: flex;
    flex-wrap: wrap;
  }
  .minus10 {
    margin-top: -5px;
  }
  .header-form{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #969ca3;
    color: #969ca3;
    padding: 0 4px 0 4px
  }
  .header-question{
    margin-left: 10px;
    color: black;
  }
  .description-content{
    margin-top: 2rem;
  }
  .input{
    max-width: 13rem
  }
  .description-autocomplete{
    margin-left: 30px;
    max-width: 150px
  }
  .checkbox-container{
    width: 120px;
    margin-left: 8px
  }
  @media(min-width: 1362px)   {
    .add-button {
      height: 40px ;
      width: 150px ;
      margin-top: 36px ;
    }
  }
  @media(max-width: 1362px)   {
    .add-button {
      height: 40px ;
      width: 150px ;
      margin: 15px auto auto 15px;;
    }
  }
  .autocomple-size{
    width: 183px;
    min-width: 183px;
    margin-left: 5px;
    margin-top: -5px
  }
  .autocomple-size-question3{
    width: 220px;
    min-width: 183px;
    margin-left: 5px;
    margin-top: -5px
  }
  .autocomple-size-multiSelect{
    width: 220px;
    min-width: 183px;
    margin-left: 5px;
    margin-top: -5px
  }
  .pseudoTextarea{
    margin-top: 0.6rem;
    width: 97%;
    min-height: 80px;
    max-height: 180px;
    border: 1px solid #d5dadf;
  }
  .pseudoTextarea-content{
    margin: 10px 5px 10px 10px;
  }
  .slider-mt{
    margin-top: -25px
  }
  .inputText-size{
    width: 183px;
    min-width: 183px;
    margin-left: 5px;
    margin-right: 40px;
    margin-top: -5px
  }
</style>
