<template>
  <div class="mb-7">
    <div class="flex align-items-center justify-content-between" style="max-width: 1270px;">
      <div>
        <TTableFilters 
          class="ml-3 mt-3"
          apiversion="v2" 
          :filtersFields="filtersFields" 
          :dropdown="dropdown"  
          :alternativeStylesCleanButton="true"
          @search="() => getData()"
          :enableFilterMultiple="true"
          ref="Filters" 
        />
      </div>
      <Dropdown 
        v-model="captureFormat" 
        optionLabel="name" 
        :options="captureFormatList"
        optionValue="action"  
        placeholder="Exportar datos" 
        class="mt-6 capture-format-dropdown"
        @change="handleSelection"
      />
    </div>
    <div class="flex align-items-center w-10">
      <div  class="block-container">
        <BlockUI :blocked="loadingQuery" :fullScreen="true">
          <Card class="w-20rem mx-7">
            <template #content>
              <div class="header-actuaciones-list">
                <b><div class="text-xs mt-1">Actuaciones</div></b>
              </div>
              <div class="content-actuaciones-list overflow-auto">
                <div @click="highlightActuationOnMap">
                  <Listbox
                    v-model="actuationSelected"
                    :options="actuationsList"
                    dataKey="Id"
                    optionValue="Id"
                    optionLabel="name"
                  >
                    <template #option="slotProps">
                      <div class="flex align-items-center justify-content-between text-xs">
                        <p :style="{ color: slotProps.option.name === 'Centro Civico' ? 'red' : '' }">
                          {{ slotProps.option.name === 'Centro Civico' ? 'No existen actuaciones disponibles' : slotProps.option.name }}
                        </p>
                        <p>
                          {{ slotProps.option.number }}
                        </p>
                      </div>
                      <div class="flex justify-content-between" v-if="slotProps.option.name !== 'Centro Civico'">
                        <div class="flex text-xs -mt-2">
                          <i class="pi pi-tag mr-2" style="margin-top: 11px;"></i> <p>{{ slotProps.option.date }}</p>
                        </div>
                        <div :class="getClass(slotProps.option.state)" class="text-xs" style="height: 25px;">
                          {{ slotProps.option.state }}
                        </div>
                      </div>
                    </template>
                  </Listbox>
                </div>
              </div>
            </template>
          </Card>
        </BlockUI>
        <i v-if="loadingQuery" class="pi pi-spinner pi-spin custom-spinner"></i>
      </div>
      <div class="map">
        <!-- <transition name="fade">
          <div v-if="daysMessage" class="mb-1 dialog dialog-over-map">
            <span class="text-lg mr-2">&#9432;</span>
            <span class="font-medium text-xs mr-2">Visualizando actuaciones de los últimos {{ daysFilterNumber }} días.</span>
          </div>
        </transition> -->
        <transition name="fade">
          <div v-if="emptyActuations" class="mb-1 font-semibold dialog dialog-empty-actuations">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              style="width: 16px; height: 16px; 
              margin-right: 6px;" 
              viewBox="0 0 24 24" 
              fill="#f8e0e0"
            >
              <circle cx="12" cy="12" r="10" fill="transparent" stroke="#f8e0e0" stroke-width="2"></circle>
              <line x1="8" y1="8" x2="16" y2="16" stroke="#f8e0e0" stroke-width="2" stroke-linecap="round"></line>
              <line x1="16" y1="8" x2="8" y2="16" stroke="#f8e0e0" stroke-width="2" stroke-linecap="round"></line>
            </svg>
            <span class="text-xs">No existen actuaciones disponibles para el rango de fechas seleccionado.</span>
          </div>
        </transition>
        <MyMap id="map" ref="map" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MyMap from '@/components/MyMap.vue';
import generalFunctions from "../store/modules/generalFunctions.js";
import TTableFilters from "@/components/TTable/TTableFilters.vue";
import Listbox from "primevue/listbox";
import BlockUI from "primevue/blockui";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  components: {
    MyMap, 
    TTableFilters,
    Listbox,
    BlockUI
  },
  watch: {
    response(newValue) {
      let actuationsAmount = Array.isArray(newValue?.data?.data) ? newValue.data.data.length : 0;
      if(actuationsAmount > 0){
        // this.daysMessage = true;
        this.emptyActuations = false;
      } else {
        this.emptyActuations = true;
        // this.daysMessage = false;
      }
    },
  },
  name: "Location",
  data() {
    return {
      // daysMessage: false,
      emptyActuations: false,
      response: [],
      filtersFields: [
        {
          name: "Actuaciones",
          field: "createdAt",
          type: "multipleDays",
        },
        {
          name: "Fechas",
          field: " createdAt ",
          type: "dateOnlyRange"
        },
        {
          name: "Tipo de Actuación",
          field: "exp_pre_TipoActuacion.TipoActuacionID",
          hideOperators: true
        },
      ],
      dropdown: {
        "createdAt": {
          optionValue: "value",
          optionLabel: "name",
          options: [
            {
              name: "7 días",
              value: 7
            },
            {
              name: "15 días",
              value: 15
            },
            {
              name: "30 días",
              value: 30
            },
          ],
        },
        "exp_pre_TipoActuacion.TipoActuacionID": {
          optionValue: "value",
          optionLabel: "name",
          options: [],
        },
      },
      daysFilterNumber: 7,
      actuationsList: [],
      actuationSelected: {},
      loadingQuery: false,
      captureFormat: {},
      captureFormatList: [
        { name: 'JPG', action: () => this.processJPG() },
        { name: 'PDF', action: () => this.processPDF() },
        { name: 'CSV', action: () => this.exportToCSV() },
      ],
    };
  },
  mounted(){
    this.centerTheMapToDefaultPosition();
    setTimeout(()=> {
      this.$refs.Filters.value = { name: "7 días", value: 7};
      this.$refs.Filters.$refs.filterButton.$el.click();
    }, 100);
    this.dropdown["exp_pre_TipoActuacion.TipoActuacionID"].options = this.filterDropdownOptions;
  },
  computed: {
    ...mapState({
      views: (state) => state.sumarioViews,
      user: (state) => state.user,
    }),
    ...mapGetters({
      denunciations: "sumarioList/getList",
    }),
    filterDropdownOptions: {
      get() {
        let options = [
          { name: "Expedientes", value: "Expediente" },
          { name: "Sumarios", value: "Sumario" },
          { name: "UFI", value: "UNIDAD" },
          { name: "Actuaciones Preliminares - TODAS", value: "Actuacion Preliminar" },
          {
            name: "UFI Delitos Especiales - TODOS",
            value: "Unidades Fiscales de Investigacion D.E",
          },
        ];
        let filterName = "";
        for (const actuationName in this.views) {
          filterName = Object.keys(this.views[actuationName]?.filters || {})[0];
          options.push({
            name: filterName,
            value: this.views[actuationName]?.filters?.[filterName]?.actuacionesFilters
              ?.value,
          });
        }
        options = options.filter(
          (obj) => !Object.values(obj).some((value) => value === undefined)
        );
        if (this.user.userdata.claseDeUsuario === "UsuarioFiscal")
          return this.generateFilterOptionsForFiscalUser(options);
        return options;
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave', to.path);
    if(to.path === "/actuaciones/list") {
      window.location.href = "/actuaciones/list";
    } else  {
      next();
    }
  },
  methods: {
    formatLocations(allLocations, formatType = 'Tooltip'){
      let currentLocation = {};
      let newLocationsFormated = [];
      let locationFormated = {}; 
      for (let index = 0; index < allLocations.length; index++) {
        currentLocation = allLocations[index];
        currentLocation.index = index;
        locationFormated = this['buildALocation' + formatType](currentLocation);
        newLocationsFormated.push(locationFormated);
      }
      newLocationsFormated = this.verifyEmptyActuations(newLocationsFormated);
      return newLocationsFormated;
    },
    buildALocationTooltip(currentLocation){
      let locationFormated = {
        Id: currentLocation.index,
        position: { lat: currentLocation.HechoubicacionLatitud, lng: currentLocation.HechoubicacionLongitud },
        name:`${this.getTooltipMapActuationName(currentLocation.TiposumarioID)} <br> ${currentLocation.Numero}` ,
        icon: this.choosePinIcon(currentLocation.TiposumarioID)
      }
      return locationFormated;
    },
    buildALocationList(currentLocation){
      // console.log('currentLocation', currentLocation);
      let locationFormated = {
        Id: currentLocation.index,
        name:`${this.getTooltipMapActuationName(currentLocation.TiposumarioID)}`,
        number: `${currentLocation.Numero}`,
        state: currentLocation.Estado,
        date: this.formatDate(currentLocation.FechaCreacion),
        position: { lat: currentLocation.HechoubicacionLatitud, lng: currentLocation.HechoubicacionLongitud },
        caseTitle: currentLocation.CausaCaratula
      }
      return locationFormated;
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months in JS are 0-based
      const year = date.getFullYear(); // Full year
      return `${day}/${month}/${year}`;
    },
    verifyEmptyActuations(newLocationsFormated){
      if(newLocationsFormated.length === 0){
        this.emptyActuations = true;
        // this.daysMessage = false;
        newLocationsFormated = [{
          Id: 0,
          name: "Centro Civico",
          position: { lat: -31.5417287, lng: -68.53757 }
        }]
      } else {
        this.emptyActuations = false;
      }
      return newLocationsFormated;
    },
    getTooltipMapActuationName(nameToChange){
      let actuationName = generalFunctions.translateNameFromFormType(nameToChange);
      return this.views[actuationName]?.actuationName[nameToChange];
    },
    choosePinIcon(actuationType){
      if(actuationType?.includes('Unidades')) return require("../assets/map-pins/yellow-pin.png");
      if(actuationType?.includes('Sumario')) return require("../assets/map-pins/blue-pin.png");
      if(actuationType?.includes('Preliminar')) return require("../assets/map-pins/green-pin.png");
      if(actuationType?.includes('Expediente')) return require("../assets/map-pins/red-pin.png");
      return '';
    },
    centerTheMapToDefaultPosition(){
      this.$refs.map.zoom = 11;
      this.$refs.map.center = { lat: -31.518233645038876, lng: -68.48564548984253 };
    },
    async getData(){
      this.loadingQuery = true;
      let searchForDetermineFilterType = await this.$refs.Filters.getFiltersV2()?.search;
      let searchForQuery = await this.normalizeSearch(await this.$refs.Filters.getFiltersV2());
      this.$refs.map.$refs.LControlRef.$el.remove(); 
      if(searchForDetermineFilterType?.includes('exp_pre_TipoActuacion.TipoActuacionID')) {
        this.getByActuationType(searchForQuery);
        return;
      }
      if(searchForDetermineFilterType?.includes('createdAt') && this.$refs.Filters.value === null) {
        this.getByRange(searchForQuery);
      } else{
        await this.getByPredeterminedDays(searchForQuery);
      }
      await this.determineUseFromCloseFilterButton();
    },
    async getByPredeterminedDays(search){
      if(this.$refs.Filters.$refs.filterChip.length > 1 && this.searchForbiddenFilterAndDeleteIt()) {
        this.getData();
        return;
      }
      if(search === undefined){
        search = {"createdAt":[{"operator":"LAST_DAYS","value": 7}]};
        this.$refs.Filters.field = { name: "Actuaciones", field: "createdAt", type: "multipleDays" };
        this.$refs.Filters.operator = { value: "LAST_DAYS", type: 'INCLUIR' };
        this.$refs.Filters.value = { name: "7 días", value: 7};
        await this.$refs.Filters.$refs.filterButton.$el.click();
      } 
      await this.setDataInSumarioList(search);
      this.processLocations();
    },
    async getByRange(search){
      this.$refs.Filters.filters = this.$refs.Filters.filters.filter(filter => filter.name !== "Actuaciones");
      if(search === undefined) this.getByPredeterminedDays();
      await this.setDataInSumarioList(search);
      this.processLocations();
    },
    async getByActuationType(searchValue){
      let getOptions = {
        url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario?getTotal=false&search=${searchValue}`,
      };
      let response = await this.$rest.get(getOptions);
      this.$refs.map.markers = this.formatLocations(response.data.data);
      this.actuationsList = this.formatLocations(response.data.data, 'List');
      this.$refs.map.$refs.LControlRef.$el.remove(); 
      // this.daysMessage = false;
      this.loadingQuery = false;
    },
    getClass(state) {
      switch (state) {
        case "En Curso":
          return "bg-green-200 flex justify-content-center white-space-nowrap p-1 font-medium text-green-800 border-round";
        case "Vencida":
          return "bg-yellow-200 flex justify-content-center white-space-nowrap p-1 font-medium text-yellow-800 border-round";
        case "Finalizada":
          return "custom-bg-red-200 flex justify-content-center white-space-nowrap p-1 font-medium custom-text-red-800 border-round";
      }
    },
    highlightActuationOnMap() {
      if(!Number.isInteger(this.actuationSelected)) {
        this.centerTheMapToDefaultPosition();
        this.$refs.map.selectedItem = null;
        this.$refs.map.mapKey = this.$refs.map.mapKey + 1;
        setTimeout(() => {
          this.$refs.map.$refs.LControlRef.$el.remove();
        }, 100);
        return;
      }
      this.$refs.map.selectedItem = this.actuationSelected;
      this.$refs.map.center = this.$refs.map.markers[this.actuationSelected].position;
      this.$refs.map.zoom = 15;
      this.$refs.map.mapKey = this.$refs.map.mapKey + 1;
      setTimeout(() => {
        this.$refs.map.$refs.LControlRef.$el.remove();
      }, 100);
    },
    handleSelection() {
      this.captureFormat();
      this.captureFormat = null;
    }, 
    async processJPG(){
      const mapElement = this.$refs.map.$el;
      try {
        const canvas = await html2canvas(mapElement, {
          useCORS: true,
        });
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/jpeg");
        link.download = "map.jpg";
        link.click();
        this.showExportSuccessToast();
      } catch (error) {
        this.showExportFailToast();
        console.error("Error al exportar el mapa:", error);
      }
    },
    async processPDF(){
      const mapElement = this.$refs.map.$el;
      try {
        const canvas = await html2canvas(mapElement, {
          useCORS: true,
        });
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("landscape", "mm", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight);
        pdf.save("map.pdf");
        this.showExportSuccessToast();
      } catch (error) {
        console.error("Error al exportar el mapa a PDF:", error);
        this.showExportFailToast();
      }
    },
    showExportSuccessToast(){
      this.$toast.open({
        message: "Exportación exitosa. El archivo ha sido descargado correctamente.",
        position: "top-right",
        type: "success",
        duration: 3000,
      });
    },
    showExportFailToast(){
      this.$toast.open({
        message: "Error en la exportación. No se pudo generar el archivo.",
        position: "top-right",
        type: "error",
        duration: 3000,
      });
    },
    async normalizeSearch(search) {
      return search?.search?.replace(' createdAt ', 'createdAt').replace('"undefined":[{"value":""}],', '');
    },
    async setDataInSumarioList(search){
      await this.$store.dispatch("sumarioList/setSearch", search);
      await this.$store.dispatch("sumarioList/get", { accion: "inicio" });
      await this.$store.dispatch("sumarioList/setLimit", null);
    },
    processLocations(){
      this.$refs.map.markers = this.formatLocations(this.denunciations);
      this.actuationsList = this.formatLocations(this.denunciations, 'List');
      this.loadingQuery = false;
    },
    async determineUseFromCloseFilterButton(){
      let filterContentText = this.$refs.Filters.$refs.filterChip?.[0]?.children?.[0]?.innerHTML;
      let filtersAmount = this.$refs.Filters.$refs.filterChip.length;
      if(filterContentText === undefined) return;
      if(filtersAmount === 1 && filterContentText === 'Actuaciones incluir 7 días') {
        this.$refs.Filters.$refs.filterChip[0].children[1].disabled = true;
      } else  {
        this.$refs.Filters.$refs.filterChip[0].children[1].disabled = false;
      }
    },
    searchForbiddenFilterAndDeleteIt(){
      const allFilters = this.$refs?.Filters?.filters;
      const indexToDelete = allFilters?.findIndex(filter => filter?.operator?.value === "LAST_DAYS");
      const filterRecentlyAdded = this.$refs?.Filters?.filters[this.$refs?.Filters?.filters.length - 1];
      const datesAmount = allFilters?.filter(filter => filter?.field?.name === "Fechas").length;
      // let actuationsAmount = allFilters?.filter(filter => filter?.field?.name === "Actuaciones").length;
      const actuationsTypesAmount = allFilters?.filter(filter => filter?.field?.name === "Tipo de Actuación").length;
      if(indexToDelete === -1) return false;
      if(actuationsTypesAmount >= 1 && datesAmount === 0 && filterRecentlyAdded.operator?.value === "LAST_DAYS") return false;
      if(filterRecentlyAdded.operator?.value === "LAST_DAYS" || filterRecentlyAdded.field.name === "Fechas") {
        this.$refs.Filters.filters.splice(indexToDelete, 1);
        return true;
      }
    },
    async exportToCSV() {
      try {
        const csvContent = "data:text/csv;charset=utf-8," + "Nombre, Nro de actuacion, Caratula/Causa, Latitud,Longitud\n" + 
        this.actuationsList.map(
          (marker) => `${marker.name}, ${marker.number}, ${marker.caseTitle}, ${marker.position.lat},${marker.position.lng}` 
        ).join("\n");
        const link = document.createElement("a");
        link.setAttribute("href", encodeURI(csvContent));
        link.setAttribute("download", "map.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.showExportSuccessToast();
      } catch (error) {
        console.error("Error al exportar el mapa a PDF:", error);
        this.showExportFailToast();
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map {
  display: block;
  margin-top: 10px;
  height: 65vh;
  width: 55vw;
}
.dialog-over-map{
  background-color: #e0dfdf; 
  padding: 2px 10px;
  color: #34495e;
}
.dialog-empty-actuations{
  background-color: #d32f2f; 
  padding: 5px 10px; 
  color: #f8e0e0; 
  border: 1px solid #c0392b;
}
.dialog{
  border-radius: 2px;
  display: flex;
  align-items: center; 
  font-family: Arial, sans-serif; 
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.0s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.header-actuaciones-list {
  background-color: rgb(248 249 250);
  margin-top: -20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  height: 45px;
}
.content-actuaciones-list {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  min-height: 250px;
  max-height: 452px;
}
.custom-bg-red-200 {
  background-color: #ffada7;
}
.custom-text-red-800 {
  color: #8c231c;
}
.block-container {
  position: relative;
}
.custom-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  color: #3b4754;
  z-index: 1100;
}

>>> .capture-format-dropdown {
  background-color: #2196f3 !important;
  border-color: #1976d2 !important;
  color: white !important;
}

>>> .capture-format-dropdown .p-dropdown-label {
  color: white !important;
}

>>> .capture-format-dropdown.p-focus {
  background-color: #1976d2 !important;
  border-color: #1565c0 !important;
}

>>> .capture-format-dropdown .p-dropdown-panel {
  background-color: white !important;
  border-color: #2196f3 !important;
}

>>> .capture-format-dropdown .p-dropdown-item {
  color: #7d858c !important;
}

>>> .capture-format-dropdown .p-dropdown-item:hover {
  background-color: #2196f3 !important;
  color: white !important;
}

>>> .capture-format-dropdown .p-dropdown-trigger {
  color: white !important;
}

>>> .capture-format-dropdown .p-dropdown-trigger svg {
  fill: white !important;
}
</style>