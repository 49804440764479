<template>
  <div class="toolbar">
    <br />
    <div class="feature-intro title">
      <h1>Gestor de Archivos</h1>
    </div>
    <div>
      <TemplateTable
        tableTitle="Gestor de Archivos"
        reference="parametros/gestor-archivos/edit"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        :modal="modal"
        apiversion="2"
        :keyShow="keyShow"
        :enableFilterMultiple="true"
        :showLink="showLink"
        :filtersFields="filtersFields"
        :disableButtonEdit="false"
        :multiSelect="true"
        :enableSelectAllCheckbox="true"
        :enableEditButtons="false"
        :enableGlobalSearch="false"
        :enableAddButton="{ enabled: true, name: 'gestor-archivos-new' ,  params: { id: 'add' }}"
        :dropdown="dropdown"
      >
        <template #LinksBody="slotProps">
          <div class="flex flex-column md:flex-row">
          <a class="file__link" :href="`${url}/${slotProps.data['ArchivopdfID']}?download=true`" target="_blank">
            <CustomButton
              icon="pi pi-eye"
              buttonColor="#2196f3"
              :disabled="!slotProps.data['ExistingFile']"
              :tooltipText="!slotProps.data['ExistingFile'] ? 'Archivo no encontrado' : 'Visualizar archivo'"
            />
            </a>
            <CustomButton
              icon="pi pi-pencil"
              buttonColor="#ffa800"
              tooltipText="Editar archivo"
              @click="goToEditMode(slotProps.data)"
            />
            <CustomButton
              icon="pi pi-trash"
              buttonColor="#d32f2f"
              tooltipText="Eliminar archivo"
              @click="confirmDelete(slotProps.data)"
            />            
          </div>
        </template>
      </TemplateTable>
    </div>
    <ModalTemplate
      title="Confirmación"
      type="confirmativo"
      message="¿Está seguro que desea eliminar este archivo?"
      :showIcon="true"
      :dismissableMask="true"
      severity="danger"
      @confirm="onConfirm"
      @cancel="onCancel"
      ref="ModalRefConfirmDelete"
    />
  </div>
</template>

<script>
import axios from 'axios';
import ModalTemplate from "../../../components/ModalTemplate.vue";
import CustomButton from '@/components/custom-button/CustomButton.vue';

export default {
  name: "Gestordearchivos",
  components: {ModalTemplate, CustomButton},
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/files/archivopdf",
      customName: [],
      data: null,
      customColumn: [],
      idTable: "ArchivopdfID",
      loading: true,
      filtersFields: [{ name: "Nombre", field: "Nombre" }],
      keyShow: ["Nombre", "Tipo", "Detalle", "Links"],
      dropdown: {
        'Tipo':{
          optionValue: "Nombre",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {
            this.$store.dispatch("siis/setCategoriaTipoArchivoPDF", true)
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setCategoriaTipoArchivoPDF");
            this.dropdown['Tipo'].options = this.$store.state.siis.categoriaTipoArchivoPDF;
          }
        },
      },
      showLink: true,
      fileToDelete: null,
    };
  },
  methods: {
    goToEditMode(doc) {
      this.$router.push(`/parametros/gestor-archivos/edit/${doc.ArchivopdfID}`);
    },
    confirmDelete(doc) {
      this.fileToDelete = doc;
      this.$refs.ModalRefConfirmDelete.open();
    },
    async onConfirm() {
      try {
        if (this.fileToDelete) {
          await axios.delete(`${this.url}/${this.fileToDelete.ArchivopdfID}`, {
            withCredentials: true,
          });
          this.$refs.ModalRefConfirmDelete.close();
          this.$router.go(0);
        }
      } catch (error) {
        console.error("Error al eliminar el archivo:", error);
        this.$refs.ModalRefConfirmDelete.close();
      } finally {
        this.fileToDelete = null;
        this.$rest.toast_open({
          message: "Elemento/s eliminado correctamente",
          position: "top-right",
          type: "success",
          duration: 3000,
        });
      }
    },
    onCancel() {
      this.fileToDelete = null;
      this.$refs.ModalRefConfirmDelete.close();
    },    async loadDropdowns(dropdownKeys) {
      for (const key of dropdownKeys) {
        if (this.dropdown[key] && typeof this.dropdown[key].loadDropdown === 'function') {
          await this.dropdown[key].loadDropdown();
        } else {
          console.warn(`loadDropdown no está definido para ${key}`);
        }
      }
    }
  },
  async created() {
      const additionalFilterOptions = [
        { name: "Tipo de Documento", field: "Tipo", limit: 1, hideOperators: true }
      ];

      this.filtersFields = [
        ...this.filtersFields,
        ...additionalFilterOptions
      ]
      await this.loadDropdowns(["Tipo"]);
  },
};
</script>

<style scoped>
.title {
  margin-left: 30px;
}
.file__link {
  text-decoration: none;
}
</style>
