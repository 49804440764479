<template>
    <div class="grid card px-3 py-1 shadow-2" >
    <!-- Header -->
    <div  class="col-12 flex justify-content-between align-items-center border-bottom-1 border-black-alpha-30 p-0 mb-2">
      <h2 class="text-2xl font-light">Nuevo Vinculado</h2>
      <div class="button_Section_process" v-if="arrId">
        <Button  
          label="Guardar" 
          icon="pi pi-back" 
          @click='handleSaveAcusado(actuacionType)' 
          :test-id="viewData.formAcusados.testIdButtonSave"
          :disabled="hasErrors"
        />
      </div>
    </div>
    <!-- Lista de personas -->
    <div v-if="!minimal" class="col-12 md:col-4 lg:col-3 lateral">
      <div class="flex justify-content-between align-items-center w-full border-round-md shadow-2 border-none px-3 mb-2" style="border: 1px solid red;">
        <h3 class="font-light text-black-alpha-50">Vinculados</h3>
        <Button
          icon="pi pi-plus"
          class="p-button-success p-button-lg p-button-rounded p-button-outlined px-2 py-2 m-1"
          style="z-index: 3"
          @click="handleNewVinculado"
        />  
      </div>
      <Listbox 
        v-model="arrId"   
        :options="acusadosTodosSelect" 
        dataKey="arrId" 
        optionValue="arrId" 
        optionLabel="name" 
        id="listBoxAccuseds"
        class="border-round-md shadow-2 border-none"  
        v-if="acusadosTodosSelect[0].type !== 'empty'"
      >
        <template #option="slotProps">
          <div class="flex justify-content-between align-items-center" v-if="slotProps.option.type !== 'empty'">
            <div class="flex flex-column text-base w-full">
              <div class="mb-2">
                <span class="uppercase font-semibold" v-if="slotProps.option.apellido">{{slotProps.option.apellido}}, </span>
                <span class="mt-2" :test-id="'acusadosContravener' + parseInt(slotProps.index + 1)">{{slotProps.option.name}}.</span>
              </div>
              <span class="block mb-2" v-if="slotProps.option.type !== 'empty' && slotProps.option.documento" >DNI: {{ slotProps.option.documento }}</span>
              <Tag
                :severity="colorTag(slotProps.option)"
                class="w-max"
                :value="slotProps.option.condicion.nombre"
                rounded
                v-if="slotProps.option.type !== 'empty' && slotProps.option.condicion"
              />
            </div>
            <Button
              v-if="slotProps.option.type !== 'empty'"
              icon="pi pi-trash"
              class="p-button-danger p-button-lg p-button-rounded p-button-outlined px-2 py-2 m-1"
              style="z-index: 3"
              @click.stop="confirmDelete(slotProps.option.arrId)"
            />  

          </div>
        </template>
      </Listbox>
      <div class="button_Section_process volver">
        <Button 
          label="Volver" 
          icon="pi pi-back" 
          @click="handleBackToMainForm()"
          test-id="buttonVolverContraventor"
        />
      </div>
    </div>
    <!-- Formulario -->
    <div :class="{'col': !minimal}" >
      <div class="card">
        <div :class="{'hideForm' : formData.type === null}" class="flex justify-content-end">
          <div class="flex flex-column w-max" >
            <div class="field-checkbox" test-id="checkFilacionDesconocida">
              <Checkbox id="binary" v-model="filiacionDesconocida" :binary="true" />
              <label for="binary">Filiación Desconocida</label>
            </div>
          </div>
        </div>
        <div :class="{'hideForm' : formData.type === null || filiacionDesconocida}" >
          <FormPersonaV2
            :acusado="true"
            ref="acusado"
            :hidden="viewData.formPersona.condition.acusados === '' ? ['dataCondicion'] : []"
            @update:persona="updatePersona"
            @validationStatus="handleValidationStatus"
            :arrId="arrId"
            :getPersonByArrId="getAcusadoByArrId"
            typeOfPerson="acusados"
            :formType="formType"
            :Involucrados="acusadosTodosSelect"
          />
        </div>
      </div>

      <FormIdentikit
        :class="{'hideForm' : formData.type === null}" 
        ref="identikit"
        :formType="formType"
        :arrId="arrId"
        @updateValue="handleUpdateIdentikitValue"
        @finishIdentikit="finishIdentikit()"
      />
      
    </div>
    <ModalTemplate 
      title="Información"
      type="informativo"
      :message="modalRefInformMessage"
      :showIcon="true"
      @confirm="closeModal()"
      severity="danger"
      ref="ModalRefInform"
      :closable="false"
    />
    <ModalTemplate
      title="Confirmación de Eliminación"
      type="confirmativo"
      message="¿Está seguro de que desea eliminar este elemento?"
      :showIcon="true"
      severity="danger"
      @confirm="deleteConfirmed"
      @cancel="cancelDelete"
      ref="ModalRefDelete"
    />
    <ModalSucces
      title="¿Estás seguro de que quieres volver?"
      type="confirmativo"
      :message="modalRefSaveMessage"
      :showIcon="true"
      @confirm="backToTheMainForm"
      @cancel="cancelBack"
      :closable="false"
      severity="success"
      ref="ModalRefSave"
    />
    <ModalSucces 
      title="¡Los datos se guardaron con éxito!"
      type="success"
      :message="modalRefSaveSuccess"
      :showIcon="true"
      @confirm="backToTheMainForm"
      @cancel="cancelBack"
      :closable="false"
      severity="success"
      ref="ModalRefSaveSuccess"
    />
  </div>
</template>

<script>
import Listbox from 'primevue/listbox';
import FormIdentikit from '../../components/forms/FormIdentikit.vue'
import ModalTemplate from '../../components/ModalTemplate.vue'
import FormPersonaV2 from '../forms/FormPersonaV2.vue';
import ModalSucces from '../ModalSucces.vue';

/*
Este es un modelo para componente que seran re utilizables muchas veces
*/
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'FormAcusados',
  components: {
    FormPersonaV2, FormIdentikit, Listbox, ModalTemplate, ModalSucces
  },
  props: {
    minimal: {
      type: String // vista minima del formulario si esta definido
    },
    predefined: {
      type: Object //string que se usara como base para llenar la declaracion de denuncia
    },
    aid: {
      type: String //string que se usara para volver a mostrar en Editor de denuncia al recargar
    },
    formType: String, //Defines the type of form
    viewData: Object,//Defines the type of form,
    actuacionType: String, 
  },
  data() {
    return {
      arrId: this.aid || null ,  
      modalRefInformMessage: '',
      saveOrBackButtonActive: false,
      acusadoData: null, // Aquí se almacenará toda la info del denunciante
      modalRefSaveMessage: '',
      modalRefSaveSuccess: '',
      filiacionDesconocida: false,
      hasIdentikit: true,
      identikitAnswer: [],
      indentikitQuestion: [],
      validationErrors: {},
      hasErrors: false,
    }
  },
  mounted() {
    //go to top of the page
    window.scrollTo(0,0)
    if(typeof this.getAcusadoByArrId(this.$router.currentRoute.query.id) != 'undefined')  {
      this.arrId = this.$router.currentRoute.query.id
    }
    // if(this.getAcusadoByArrId(this.arrId).sipeSearch === true){
    //   this.$refs.acusado.disabledFields = [
    //       "dataNombre",
    //       "dataApellido",
    //       "dataFechaNacimiento",
    //       "dataDomicilioSIPE",
    //     ];
    //   this.$refs.acusado.sipeSearch = true
    // } else{
    //    this.$refs.acusado.disabledFields = [];
    //   this.$refs.acusado.sipeSearch = false
    // }

  },
  created(){
    if(this.$route.query.id == 'new')
    {
      this.arrId = this.acusadosTodosSelect[(this.acusadosTodosSelect.length)-1].arrId
    }
  },
  computed: {
    ...mapGetters('sumario/acusados', [
      'acusadosTodos',
      'acusadosCount',
      'getAcusadoByArrId',
      'getEmptyDocAcusado',
      'getNewAcusadoArrId',
      'acusadosTodosSelect'
    ]),
    //manejo del dato de state
    formData: {
      get () {
        if (this.predefined && !this.arrId){
          // return this.getAcusadoByArrId(this.getNewAcusadoArrId)
          if (this.predefined.new) {
            return {
              doc: this.getEmptyDocAcusado(),
              identikit: {},
              descriptionOffender: [],
              type: 'empty',
              filiacionDesconocida: this.predefined.default.filiacionDesconocida,
              hasIdentikit: this.predefined.default.hasIdentikit
            }
          }
        }
        if (this.arrId) {
          this.acusadoData = {...this.getAcusadoByArrId(this.arrId).doc}
          return this.getAcusadoByArrId(this.arrId)
        }
        return {
            doc: this.getEmptyDocAcusado(),
            type: null, 
            filiacionDesconocida: false, 
            hasIdentikit: false
          }
      },
    },
    //datos que se editan en este componente y no en componentes hijos especificos de Acusados
    // filiacionDesconocida: {
    //   get () { return this.formData.filiacionDesconocida },
    //   set (value) { this.handleFiliacionDesconocida(value) }
    // },
  },
  //comunicacion con hijos
  watch: {
    arrId: function (newValue) {
      if(newValue != null && this.$router.currentRoute.query.id != newValue)
        this.$router.push({path: `/actuaciones/new/${this.actuacionType}/initial/acusado?id=${newValue}`})
    },
    formData: function (newValue) {
      if (typeof newValue != 'object') return
      this.$refs.acusado.setData( newValue.doc )

      //force the value of checkbox 'personaJuridica' to not be null
      if(newValue.doc.personaJuridica) {
        this.$refs.acusado.personaJuridica = newValue.doc.personaJuridica
      } else  {
        this.$refs.acusado.personaJuridica = false
      }

      if(newValue.filiacionDesconocida) {
        this.filiacionDesconocida = newValue.filiacionDesconocida
      } else  {
        this.filiacionDesconocida = false
      }

      if(newValue.identikit != undefined){
        this.$refs.identikit.setData( newValue.identikit )
        this.$_TemplateParentFor_setValue(newValue)
      }

      if(newValue.hasIdentikit){
        this.hasIdentikit = newValue.hasIdentikit
      }else{
        this.hasIdentikit = false
      }
    },
    hasIdentikit: function(newValue){
      if(newValue){
        this.hasIdentikit = newValue
      }
    },
    validationErrors: {
      handler(newErrors) {
        this.hasErrors = Object.values(newErrors).some(
          (error) => error !== null && error !== ""
        );
      }
    }
  },

  methods: {
    handleNewVinculado(){
      const id = this.acusadosTodosSelect[(this.acusadosTodosSelect.length)-1].arrId
      this.$router.push({
        path: `/actuaciones/new/${this.actuacionType}/initial/acusado?id=${id}`,
      });
    },
    async handleSaveAcusado(actuacionType){
      if ( !this.formData.arrId ) {
        this.formData.arrId = await this.getNewAcusadoArrId
      }
      this.validacion("/actuaciones/new/" + actuacionType + "/initial")
      await  this.$store.dispatch('sumario/acusados/setAcusado', { 
        ...this.formData,
        doc: {...this.formData.doc, ...this.acusadoData},
        hasIdentikit: this.hasIdentikit,
        filiacionDesconocida: this.filiacionDesconocida,
        identikit: this.identikitAnswer,
        identikitQuestion: this.identikitQuestion
      })
      this.$store.dispatch("sumario/acusados/setEmptyTitle", this.viewData.formPersona.accused.emptyTitle);
      this.$store.dispatch('sumario/acusados/clearAllOriginalIdentikit');
      this.$_TemplateParentForm_setArrId()
      this.modalRefSaveSuccess = "La información se ha guardado correctamente. Si desea realizar una nueva carga, haga click en 'Seguir cargando'. En caso de querer regresar a la página anterior, haga click en 'Volver'.";
      this.$refs.ModalRefSaveSuccess.open()
    },
    handleValidationStatus(errors) {
      this.validationErrors = { ...errors };
    },
    updatePersona(nuevaData) {
      this.acusadoData = nuevaData;
    },

    handleBackToMainForm(){
      this.modalRefSaveMessage = '¿Estás seguro de que quieres volver?  Si decides continuar, se perderán todos los datos que no hayas guardado. Guarda los datos antes de volver para evitar la pérdida de información.'
      this.$refs.ModalRefSave.open()
    },

    backToTheMainForm(){
      this.saveOrBackButtonActive = true;
      this.$router.push( '/actuaciones/new/' + this.actuacionType + '/initial/');
    },

    cancelBack(){
      this.$refs.ModalRefSave.close();
      this.$refs.ModalRefSaveSuccess.close()
    },

    //se ejecuta al ser activado por editor para mostrar luego de estar oculto
    //revisar si es necesario que lo use en la primera carga
    activeComponent(){
      this.$refs.acusado.setData( this.formData.doc )
    },
    // Muestra el modal de confirmación
    confirmDelete(id) {
      this.idToDelete = id; // Guarda el ID del elemento seleccionado
      this.$refs.ModalRefDelete.open(); // Abre el modal
    },
    // Elimina el elemento confirmado
    deleteConfirmed() {
      if (this.idToDelete) {
        this.$_TemplateParentForm_delete(this.idToDelete); // Llama al método de eliminación
        this.idToDelete = null; // Limpia el estado
        this.$refs.ModalRefDelete.close(); // Cierra el modal
      }
    },
    // Cancela la eliminación
    cancelDelete() {
      this.idToDelete = null; // Limpia el estado
      this.$refs.ModalRefDelete.close(); // Cierra el modal
    },

    $_TemplateParentFor_setValue(data){
      if (!this.predefined) return
      if (!this.predefined.valueFactory) return
      let text = this.predefined.valueFactory;

      //caracteristicas + identikit
      let charters = false
      if (text.indexOf('{textDescription}')>=0){
        charters = ''
        if  (data.identikit.description) charters = data.identikit.description
        for (let i in data.identikit){
          if (i == 'description') continue
          charters = charters + " " + i + " " + data.identikit[i].nombre.toLowerCase()
        }
      }
      if (charters){
        const regexCharters = /{textDescription}/ig;
        text = text.replace(regexCharters, charters)
      }

      //persona fisica

      if (data.doc.sexo){
        text = text.replace(/{sexo}/ig, data.doc.sexo.nombre)
      }

      if (data.doc.nombre){
        text = text.replace(/{nombre}/ig, data.doc.nombre)
      }

      if (data.doc.apellido){
        text = text.replace(/{apellido}/ig, data.doc.apellido)
      }
      if (data.doc.documento){
        text = text.replace(/{dni}/ig, data.doc.documento)
      }
      this.$emit("setValue", text)
    },

    // guarda cambios en identikit (especifico para identikit del componente acusado)
    async handleUpdateIdentikitValue( field, value ){      
      if(field === "listAnswers"){
        this.identikitAnswer = [...value]
      }
      if(field === "numberQuestion"){
        this.indentikitQuestion = value
      }
    },

    //no se dispara este evento en identikit
    // async $_TemplateParentForm_handleDescriptionOffender( value ){
    //   if ( !this.formData.arrId ) {
    //     this.formData.arrId = await this.getNewAcusadoArrId
    //   }
    //   await  this.$store.dispatch('sumario/acusados/setAcusado', { ...this.formData,
    //     descriptionOffender: [ ...value ]
    //   })
    //   this.$_TemplateParentForm_setArrId()
    // },

    //le pone array id, es para el caso de nuevos desde editor de denuncia
    $_TemplateParentForm_setArrId(){
      if (!this.arrId) {
        this.arrId = this.formData.arrId
        if ( this.predefined ) {
          this.$emit("setArrId", this.arrId)
        }
      }
    },

    // elimina del array
    $_TemplateParentForm_delete(arrId) {
      this.arrId = null
      this.$store.dispatch('sumario/acusados/deleteAcusadoByArrId', arrId)
    },
    finishIdentikit(){
      this.arrId = null
    },
    closeModal(){
      this.$refs.ModalRefInform.close();
      this.$refs.acusado.$el.querySelector("#emailInput").select();
    },
    validateEmailError(){
      let validation = this.$rest.validateEmail(this.$refs.acusado?.email);
      if(this.$refs.acusado.condicion.nombre === 'Aprehendido' || this.$refs.acusado.condicion.nombre === 'Detenido'){
        this.modalRefInformMessage = 'Se requiere un email con formato válido.';
      } else {
        this.modalRefInformMessage = 'Se requiere un email válido o dejar el campo vacío si no tienes uno disponible.';
      }
      if(validation !== true){
        this.$refs.ModalRefInform.open();
      } 
      return validation;
    },
    colorTag(value) {
      if(value.condicion){
        switch (value.condicion.nombre) {
          case 'Detenido':
            return "warning"
            default:
              'info'
            }
      }
    },
    validacion(route){
      this.saveOrBackButtonActive = true;
      // if(!this.validateEmailError()) return;
      if(this.formType != 'ufi-flagrancia'){
        this.$store.dispatch("sumario/textResetEditsCounter","Contravener")
      }else{
        this.$store.dispatch("sumario/textResetEditsCounter","instructionDecree")
      }
      let errores = this.$refs.acusado.emitErrores()
      // if(errores == true)
      // {
      //   alert("Verifica los campos")
      // }else
      // {
      //   // this.$router.push(route)
      // }

    },
    
  }
}
</script>
<style scoped>
.card {
  background: #ffffff;
  padding: 2rem;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}
.hideForm {
  display: none;
}
.button_Section_process {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button_Section_process:not(.volver){
  margin-right: 25px;
}

.volver{
  margin-top: 10px;
}

.lateral > button {
  margin-top: 20px;
}
.pad {
  padding: 1rem .7rem;
}

.pad > div {
  margin-right: 30px;
}
</style>
