<template>
  <div class="toolbar">
    <br>
    <div class="feature-intro title">
      <h1>Departamental</h1>
    </div>
    <div>
      <TemplateTable
        tableTitle="Departamental"
        reference="departamental"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        apiversion="v1"
        :modal="modal"
        :keyShow="keyShow"
        :filtersFields="filtersFields"
        :multiSelect="false" 
        :enableSelectAllCheckbox="false" 
        :enableEditButtons="false"
        :enableGlobalSearch="true" 
        :enableAddButton="{ enabled: false }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DepartamentalList",
  props: {
    modal: {
      type: Boolean
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/departamental",
      idTable: "DepartamentalID",
      loading: true,
      data: null,
      filtersFields: [
        { name: "Nombre", field: "Nombre" },
      ],
      keyShow: [
        "DepartamentalID",
        "Nombre",
        "Ubicacion",
        "FechaCreacion",
      ],
    };
  },
  methods: {
    submit() {
      let selectedItem = this.$refs.tableData.getSelectedRow();
      this.$emit("selectItem", selectedItem.DepartamentalID);
     
    },
  },
};
</script>

<style scoped>
.title {
  margin-left: 30px;
}
</style>
