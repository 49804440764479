<template>
    <div class="page-expediente" >
        <SuccessAndFail
            :msjEnable.sync="msjEnable"
            :mensajeDialog="mensaje"
            :buttomAceptar="buttomAceptar"
            :icon.sync="iconModal"
        />
        <div>
            <div class="acta-lines-container">
                <div style="flex-grow: 1">
                    <div  class="header-dash align-items-center">
                        <p  class="textDiligenciaTitulos">
                            {{viewData.startActType.title}}
                        </p>           
                        <ProgressSpinner :class="{'spinnerStyle':true}" :style="[!timerStore ? {'display': 'none'}: '']" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s"/>        
                        <Button label="Ver PDF" class="ml-auto mr-3 buttonStyle bg-transparent border-500 text-500"  @click="redirect('preview')"  :disabled="enCarga" test-id="buttonPrevisualizar"/>
                        <Button label="Registrar" class="p-button-warning buttonStyle"  @click="validate()" :disabled="enCarga" test-id="buttonRegistrar"/>
                        <Button label="ir al expediente" class="p-button-warning buttonStyle"  @click="redirect('expediente')"  v-if="datosLegales && saveStatus == 'save'" test-id="buttonExpediente"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="description-label" v-if="datosLegales && viewData.preformTitle" >
            Preforma, depto. Dependencia Fecha Hora y Lugar del Hecho
        </div>
        <div class="editable__description" test-id="textDiligencia" >
            <div :style="[viewData.FormDatosLegalesStyles ? {'margin-top': '30px', 'margin-bottom': '60px'} : '']" class ="textDiligencia">
                <!-- Si el texto no ha sido editado lo mostramos el cómo queda de forma dinámica-->
                <div v-if="textEditsCounter.Preform == 0" :style="[TextBool.Preform ? {'width' : '100%','float': 'left'} : {'display' : 'none'}]"  v-html="Preform.withStyles" ></div> 
                <!-- En caso de que ya se haya editado almenos una vez este ya no se muestra dinámico sino que trae el texto plano, con las posibles modificaciones-->
                <div v-else-if="TextBool.Preform">{{textEstaticos.Preform}}</div>
                <div :style="[!TextBool.Preform ? {'width' : '100%','float': 'left'} : {'display' : 'none'}]">
                    <Textarea v-model="TextFlats.Preform" class="textarea textDiligencia" />
                </div>
            </div>
            <div class="ml-2" :style="[TextBool.Preform ? {'width' : '4%','float': 'left'} : {'display' : 'none'},  datosLegales ? {'margin-top': '-25px'} : '']"> 
               <Button   icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined mt-6"  @click="EditText('Preform')" test-id="FormDatosLegalesPencil"/>
            </div>
            <div class="ml-2" :style="[!TextBool.Preform ? {'width' : '4%','float': 'left'} : {'display' : 'none'}, datosLegales ? {'margin-top': '-25px'} : '']"> 
                <Button   icon="pi pi-save" class="p-button-rounded p-button-info p-button-outlined p-button-danger mt-6"  @click="SaveText('Preform')" test-id="guardarPreform"/>
            </div>
        </div>
        <div v-if="viewData.showDeposition">
            <div class="description-label mt-3" test-id="textRelatoLabel">
                Relato <span style="color: red;">*</span>
            </div>
            <div>
                <Textarea 
                    v-model="dataDeposition" 
                    :class="`textarea ${viewData.formDatosLegales.depositionStyles} textDiligencia`" 
                    ref="textareaContravencional"
                    test-id="textareaDiligencia"
                    @input="handleDepositionChange(deposition)"
                />
                <label class="errorMsg" v-if="isTouched || relatoError">
                    {{relatoError}}
                </label>
            </div>
        </div>
        <div  class="description-label" style="margin-top: 10px" v-if="datosLegales &&   formType == 'contravencional-oficio'"
        test-id="textDescriptionLabel">
            Contraventor
        </div>
        <div class="editable__description" v-if="  formType == 'contravencional-oficio'" test-id="textDescriptionLabel">
            <div :style="[viewData.FormDatosLegalesStyles ? {'margin-top': '30px', 'margin-bottom': '60px'} : '']" class = "textDiligencia">
                <!-- Al consultarle por los datos personales manifestando llamarse <div v-if="dataResumen.acusados !== ''"  style="display: inline"> <p class="fontColorLightBlue">{{ dataResumen.acusados }}</p>, procediendo al traslado a esta dependencia, </div> <div v-if="dataResumen.efectosDenunciados !== ''"  style="display: inline"> como así también del secuestro de <p class="fontColorLightBlue">{{ dataResumen.efectosDenunciados }}</p></div> -->
                <div v-if="textEditsCounter.Contravener == 0 && TextBool.Contravener"  :style="[viewData.FormDatosLegalesStyles ? {'margin-top': '30px', 'margin-bottom': '60px'} : '']" v-html="Contravener.withStyles" /> 
                <!-- En caso de que ya se haya editado almenos una vez este ya no se muestra dinamico sino que trae el texto plano, con las posibles modificaciones-->
                <div v-else-if="TextBool.Contravener">{{textEstaticos.Contravener}}</div>            
                <div :style="[!TextBool.Contravener ? {'width' : '100%','float': 'left'} : {'display' : 'none'}]">
                    <Textarea v-model="TextFlats.Contravener" class="textarea textDiligencia" />
                </div>                    
            </div>
            <div class="ml-2" :style="[TextBool.Contravener ? {'width' : '4%','float': 'left'} : {'display' : 'none'}, datosLegales ? {'margin-top': '-45px'} : '']"> 
                <Button   icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined mt-6"  @click="EditText('Contravener')" 
                test-id="textDescriptionPencil"
                />
            </div>
            <div class="ml-2" :style="[!TextBool.Contravener ? {'width' : '4%','float': 'left'} : {'display' : 'none'}, datosLegales ? {'margin-top': '-45px'} : '']"> 
                <Button   icon="pi pi-save" class="p-button-rounded p-button-info p-button-outlined p-button-danger mt-6"  @click="SaveText('Contravener')"  test-id="guardarPostform"/>
            </div>            
        </div>
        <div v-if="viewData.postform.show">
            <div class="description-label" style="margin-top: 20px" v-if="datosLegales && (viewData.postform.titleLegalData !== '')" >
               {{viewData.postform.titleLegalData}}
            </div>
            <div class="editable__description" v-if="viewData.postform.showOnlyAtLegalData ? true : true ">
                <div :style="[viewData.FormDatosLegalesStyles ? {'margin-top': '30px', 'margin-bottom': '60px'} : '']" class = "textDiligencia">
                    <div v-if="textEditsCounter[viewData.postform.type] == 0 && TextBool[viewData.postform.type]"  :style="[viewData.FormDatosLegalesStyles ? {'margin-top': '30px', 'margin-bottom': '60px'} : '']" v-html="this[viewData.postform.type].withStyles" /> 
                    <div v-else-if="TextBool[viewData.postform.type]" test-id="preform">{{textEstaticos[viewData.postform.type]}}</div>            
                    <div :style="[!TextBool[viewData.postform.type] ? {'width' : '100%','float': 'left'} : {'display' : 'none'}]">
                        <Textarea v-model="TextFlats[viewData.postform.type]" class="textarea textDiligencia" />
                    </div>                    
                </div>
                <div class="ml-2" :style="[TextBool[viewData.postform.type] ? {'width' : '4%','float': 'left'} : {'display' : 'none'}, datosLegales ? {'margin-top': '15px'} : '']"> 
                    <Button   icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-outlined mt-6"  @click="EditText(viewData.postform.type)" />
                </div>
                <div class="ml-2" :style="[!TextBool[viewData.postform.type] ? {'width' : '4%','float': 'left'} : {'display' : 'none'}, datosLegales ? {'margin-top': '15px'} : '']"> 
                    <Button   icon="pi pi-save" class="p-button-rounded p-button-info p-button-outlined p-button-danger mt-6"  @click="SaveText(viewData.postform.type)" />
                </div> 
            </div>
        </div>
        <ModalTemplate
            title="Información"
            type="informativo"
            message="Conexión inestable, falló el registro de la actuación"
            :showIcon="true"
            @confirm="redirectToHome()"
            :loadingSaveButton="loadingSaveButtonInModalTemplate"
            severity="danger"
            :closable="false"
            ref="ModalRefInform"
        />
        <ModalTemplate
            title=""
            message=""
            :showIcon="true"
            severity="danger"
            :closable="true"
            ref="ModalRefOnlineError"
        >
            <div class="flex align-items-center justify-content-center bg-white px-3 py-2">
                <div class="mr-4 mt-2">
                    <img src="../../../public/assets/exclamation.png" alt="Ícono de error" class="w-2rem h-2rem" />
                </div>
                <div>
                    <h1 class="text-xl m-0">Se ha producido un error</h1>
                </div>
            </div>
            <div class="mt-3 text-center" style="font-family: 'Roboto', sans-serif; color: #555555; line-height: 1.6;">
                <p>
                    Hubo un error al cargar la actuación. Por favor, copie el registro del error utilizando el botón 
                    'Copiar' a continuación y compártalo con el equipo de soporte técnico. El equipo se encargará de informar este incidente al correo  <span style="color: #007bff;">soportesii@electro3.com.ar</span>  
                    para resolver el problema a la brevedad
                </p>
            </div>
            <div class="flex justify-content-end gap-3 mt-3">
                <!-- Botón de Copiar Error -->
                <Button 
                    label="Copiar Error" 
                    class="p-button-primary" 
                    @click="copyError"
                    v-if="!loadingCopyButton"
                />
                <ProgressSpinner v-else :class="{'spinnerStyle':true}" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s" v-if="loadingCopyButton"/> 
            </div>

        </ModalTemplate>
    </div>
</template>

<script>

import {mapGetters, mapState} from 'vuex'
import FormDatosLegalesMixin from "../../mixins/FormDatosLegalesMixins/formDatosLegalesMixin.js";
import tanstackFunctions from "../../mixins/tanstackFunctions.js";
import ModalTemplate from "../../components/ModalTemplate.vue";

export default {
    name: 'FormDatosLegales',
    props:{
        formType: String,
        passValidation: Boolean,
        viewData: Object ,
        actuacionType: String
    },
    components: { ModalTemplate },
    mixins: [
        FormDatosLegalesMixin, tanstackFunctions
    ],
    mounted(){
        this.$store.dispatch("sumarioViews/setActuacionType", this.formType);
        // console.log('this.viewData.formDatosLegales.instructionDecree', this.viewData.formDatosLegales.instructionDecree);
        // console.log('this.viewData.formDatosLegales.actOfStart', this[this.viewData.formDatosLegales.actOfStart]);
        // console.log('this.viewData.formDatosLegales.Preform', this[this.viewData.formDatosLegales.Preform]);
        // console.log('this.viewData.postform', this.viewData.postform);
        // console.log('this.actOfStartSumarioOficio', this.actOfStartSumarioOficio);
        // console.log('this.PreformSumarioOficio', this.PreformSumarioOficio);
        // console.log('this.Preform', this.Preform);
        // console.log('this.formType', this.formType);
        // console.log('this.postformSumarioOficio', this.postformSumarioOficio);
        // console.log('this[viewData.postform.type].withStyles', this[this.viewData.postform.type].withStyles);
    },
    data () {
        return {
            isTouched: false,
            //---------Datos para el Modal SuccessAndFail------
            buttomAceptar:{buttom:true,action:false},
            iconModal:{},
            mensaje:'',
            msjEnable:false,
            //-----------Fin datos para Modal SuccessAndFail-----
            // --------------Esta comentado por que rompe la vista-----------
            // viewData.FormDatosLegalesStyles: this.$router.history.current.path === '/sumario/denuncia/formulario/contravencional/oficio',
            // viewData.FormDatosLegalesStyles2: this.$router.history.current.path === '/sumario/denuncia/formulario/contravencional/denuncia',
            // datosLegales: this.$router.history.current.path === '/sumario/denuncia/formulario/contravencional/oficio/datos',
            // // timer:false
            enCarga:false,
            TextBool: {
                Preform:true,
                Contravener: true,
                Postform: true,
                LegalData: true,
                instructionDecree: true,
                postformSumarioOficio: true
            },
            TextFlats: {
                Preform:'',
                Contravener:'',
                Postform: '',
                LegalData: '',
                instructionDecree: '',
                postformSumarioOficio: ''
            },
            AuxTextSecond:'',
            loadingSaveButtonInModalTemplate: false,
            loadingCopyButton: false,
            relatoError: '',
        }
    },
    computed: {
        ...mapGetters({
            dataResumen: 'sumario/dataResumen',
        }),
        ...mapState({
            userState: state => state.user,
            deposition: state => state.sumario.deposition,
            sumario: state => state.sumario,
            saveStatus: state => state.sumario.saveStatus,
            idsumario: state => state.sumario.id,
            timerStore: state => state.sumario.timer,     
            textEditsCounter: state => state.sumario.textEditsCounter,       
            textEstaticos: state => state.sumario.textEstaticos,      
            ActuacionUfi: state => state.sumario.ActuacionUfi,                  
            assistant: state => state.sumario.assistant,                  
        }),
        Preform:{
            get (){
                return this[this.viewData.formDatosLegales.Preform];
            }
        },
        actOfStart:{
            get (){
                return this[this.viewData.formDatosLegales.actOfStart];
            },
        },  
        instructionDecree:{
            get (){      
                return this[this.viewData.formDatosLegales.instructionDecree];
            },
        },          
    },
    methods: {
        handleDepositionChange(value){
            this.isTouched = true
            if(value.trim().length < 600){
                this.relatoError = 'El relato debe contener al menos 600 caracteres.'
            }else{
                this.relatoError = ''
            }
            this.$emit("update:relatoError", !this.isTouched, Boolean(this.relatoError))
        },
        SaveText(text)
        {
            if(this.textEditsCounter[text] === 0 || this.textEditsCounter[text] === ""){
                if (window.confirm("¿Seguro que desea modificar la diligencia?")) {
                    //TextBool es para saber si esta visible la edición o no
                    this.TextBool[text] = true
                    //Mantenemos el texto plano para cuando sea editado nuevamente
                    this.AuxTextSecond = this.TextFlats[text]
                    this.TextFlats[text] = this[text].noStylesFirtPart ? (this[text].noStylesFirtPart + this.TextFlats[text]) : this.TextFlats[text]
                    this.$store.dispatch('sumario/TextEstaticos', { field: text, value: this.TextFlats[text] })
                    //TexPreformEdit es para saber si ya fue editado y conocer el número de veces que se editó
                    this.$store.dispatch('sumario/textEditsCounter', {field: text})
                }else{
                    this.AuxTextSecond = this.TextFlats[text]
                    this.TextBool[text] = true
                }
            }else{
                this.TextBool[text] = true
                //Mantenemos el texto plano para cuando sea editado nuevamente
                this.AuxTextSecond = this.TextFlats[text]
                this.TextFlats[text] = this[text].noStylesFirtPart ? (this[text].noStylesFirtPart + this.TextFlats[text]) : this.TextFlats[text]
                this.$store.dispatch('sumario/TextEstaticos', { field: text, value: this.TextFlats[text] })
                //TexPreformEdit es para saber si ya fue editado y conocer el número de veces que se editó
                this.$store.dispatch('sumario/textEditsCounter', {field: text})
            }
        },
        EditText(text)
        {
            //si aun no se a editado el texto
            if(this.textEditsCounter[text] == 0)
            {
                this.TextBool[text] = false
                //si existe noStylesSecondPart es por que no hay que editar el texto entero, caso contrario se muestra completo
                this.TextFlats[text] = this[text].noStylesSecondPart ? this[text].noStylesSecondPart :this[text].noStyles  //cargamos el texto plano traido de la carga dinamica
            }else//En caso de que si se haya editado
            {
                this.TextBool[text] = false
                this.TextFlats[text] = this[text].noStylesSecondPart ? this[text].noStylesSecondPart : this.textEstaticos[text]  //cargamos el texto plano traido de la carga estatica
            }
        },
        redirect(op)
        {
            // console.log('op', op);
            switch(op)
            {
                case "expediente":
                    this.$router.push({path: `/sumario/denuncia/detalle/toUpdate${this.idsumario}`})
                break
                case "preview":
                //guardamos el texto plano del acta de inicio
                    this.$store.dispatch('sumario/TextActaInicio', this.actOfStart);
                    this.$router.push('/actuaciones/' + this.actuacionType +'/preview/actaInicioPreview?caso=preview')
                break     
            }
        },
        async validate()    {
            this.enCarga=true;
            if(this.dataResumen.errorValidationErr.length == 0)
            {
                //guardamos en el STORE el texto plano
                this.$store.dispatch('sumario/TextActaInicio', this.actOfStart);
                //generamos la Actuacion correspondiente
                // console.log('guardando denuncia');
                let val = await this.$store.dispatch('sumario/saveDenuncia');
                // console.log('val', val);
                if(val?.type === 'Error offline mode') {
                    this.$refs.ModalRefInform.open();
                    return;
                }
                if(val?.type === 'Error in online mode') {
                    this.$refs.ModalRefOnlineError.open();
                    setTimeout(() => {
                        this.addCloseButtonListener();
                    }, 100);
                    return;
                }
                //si retorna true, la carga de la actuacion, se realizo correctamente, y se continua con normalidad
                if(val != false)
                {
                    //Creamos la diligencia inicial del Acta
                    // if(!this.formType.startsWith('ufi'))//esto es por que en ufi aun no esta lista la diligencia que debe crearse automaticamente
                    // {
                        let actuacionType = this.$router.app.$route.params.pathName
                        localStorage.setItem("actuacionType", actuacionType)
                        this.$store.dispatch('sumarioList/get',{accion: "inicio"});
                        this.$router.push('/actuaciones/new/' + actuacionType + '/actuacion-save' )
                    // }
                }else//en el caso que retorne false, significa que hubo un fallo al intentar cargar la actuacion
                {
                    this.enCarga = false
                }
            }else
            {
                this.iconModal = {type :'pi pi-exclamation-circle', style : 'font-size: 3em; color: red;' }             
                this.mensaje = 'Falta algún dato para generar la actuación'
                this.msjEnable = true
                // alert("Falta algún dato para generar el Expediente")
                this.enCarga=false;
            }
        },
        redirectToHome() {
            this.loadingSaveButtonInModalTemplate = true;
            window.location.href = '/';
        },
        async copyError() {
            this.loadingCopyButton = true;
            let val = await this.$store.dispatch('sumario/saveDenuncia');
            const textToCopy = val.errorContent;
            if(navigator?.clipboard !== undefined){
                this.copyWithWriteText(textToCopy);
            }   else{
                this.copyWithOldMethod(textToCopy);
            }
            await this.$store.dispatch('sumario/saveDenuncia', true);
        },
        addCloseButtonListener() {
            const modalElement = this.$refs.ModalRefOnlineError.$refs.dialogRef.$el;
            const closeButton = modalElement.querySelector(".p-dialog-header-close");
            if (closeButton) {
                closeButton.addEventListener("click", this.onModalClose);
            } else {
                console.warn("No se encontró el botón de cierre.");
            }
        },
        async onModalClose() {
            await this.$store.dispatch('sumario/saveDenuncia', true);
        },
        copyWithWriteText(textToCopy){
            navigator.clipboard.writeText(textToCopy)
            .then(() => {
                this.$rest.toast_open({
                    message: "¡ Error Copiado !",
                    position: "top-right",
                    type: "success",
                    duration: 3000,
                });
            })
            .catch(err => {
                console.error("Error al copiar al portapapeles: ", err);
                alert("Hubo un problema al copiar el texto.");
            });
        },
        copyWithOldMethod(textToCopy){
            const textarea = document.createElement("textarea");
            textarea.value = textToCopy;
            document.body.appendChild(textarea);
            textarea.select();
            textarea.setSelectionRange(0, 99999)
            try {
                document.execCommand("copy");
                this.$rest.toast_open({
                    message: "¡ Error Copiado !",
                    position: "top-right",
                    type: "success",
                    duration: 3000,
                });
            } catch (err) {
                console.error("Error al copiar el texto:", err);
            }
        }
    }
}
</script>

<style scoped>
.acta-lines-container{
    display: flex;   
}
.acta-inicio{
    flex-grow: 1;
    flex-wrap: wrap;
}
.header-dash{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 20px;
    align-items: center;
}
.title-align{
    margin-top: 45px;
    margin-left: 5px;
}
@media(min-width: 1152px){
    .hr-margin{
        margin-top: -15px
    }
}
@media(max-width: 1152px){
    .hr-margin{
        margin-top: 5px
    }
}
.textarea{
    width: 100%;
    /* height: 250px; */
    border: 1px solid #ccc;
    padding: 5px;
    resize: vertical;
    font-family : "Times New Roman";
}
hr {
    border: .5px solid #ccc;
    height: 1px;
    padding: -5px;
    margin-left: 25px;
}
.verticalBR{
    border-left: 1px solid #969ca3;
    height: 80px;
    width: 1px;
    margin-top: -5px
}
.fontColorLightBlue{
    color: #55b5ea;
    display: inline;
}
.description-label{
    width: 100%;
    margin-top:3px;
    margin-bottom:3px;
    text-align: right;
}
@media(min-width: 625px){
    .page-expediente{
        padding: 10px  10px;
        min-height: calc(100vh - 92px);
    }
}
.spinnerDisable{
    display: none;
}
.spinnerStyle{
    position: relative;
    margin-left:40%;
    width:35px;
    height:35px
}
.textDiligencia{
    font-family : "Times New Roman";
    font-size : 1.10em;
    width: 100%;
}
.textDiligenciaTitulos{
    line-height: .8;
    font-family : "Times New Roman";
    font-size : 1.6em;
    font-weight: bold;
}
.buttonStyle{
    height: 80%;
    align-self: flex-start;
}
.editable__description{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.h-33rem{
    height: 33rem;
}
.errorMsg{
  color: #dc3545;
  text-align: center;
  margin-top: 3px;
}
.h-35rem{
    height: 35rem;
}
>>>.p-dialog .p-dialog-header-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>