<template>
  <div class="integration" >
    <!-- Form Momento -->
    <div :class="{ w100: card }">
      <div class="formulario grid" v-if="!card">
        <div :class="{ 'forms col': !minimal, inputType: minimal }">
          <div class="p-fluid formgrid grid">
            <div>
              <label test-id="textDesdeLabel">Desde <span style="color: red;">*</span></label>
              <div>
                <CustomCalendar
                  v-if="enableField('date')"
                  id="time24"
                  class="input"
                  :selectOtherMonths="true"
                  onkeydown="return false"
                  :showTime="true"
                  placeholder="Desde fecha y hora"
                  dateFormat="dd/mm/yy"
                  v-model="date"
                  :maxDate="today"
                  test-id="desdeCalendar"
                  :baseZIndex="999999"
                  :timeByKeyboard="true"
                  :disabled="sumario.actuationEditModeActive"
                  ref="CustomCalendarFrom"
                >
                  <template #footer>
                    <div class="flex justify-content-between">
                      <Button
                        label="Hoy"
                        icon="pi pi-check"
                        class="p-button-text w-6rem"
                        @click="setToNow('date')"
                      />
                      <div class="flex-grow-1"></div>
                      <Button
                        label="Cerrar"
                        icon="pi pi-times"
                        class="p-button-text w-6rem"
                        @click="closeCalendar('CustomCalendarFrom')"
                      />
                    </div>
                  </template>
                </CustomCalendar>
              </div>
                <label :class="{ visible: errores.date }"> {{ errores.date }}</label>
            </div>
            <div>
              <label test-id="textHastaLabel">Hasta <span style="color: red;">*</span></label>
              <div>
                <CustomCalendar
                  v-if="enableField('date2')"
                  id="basic"
                  class="input"
                  :selectOtherMonths="true"
                  onkeydown="return false"
                  :showTime="true"
                  placeholder="Hasta fecha y hora"
                  dateFormat="dd/mm/yy"
                  v-model="date2"
                  :maxDate="today"
                  test-id="hastaCalendar"
                  :baseZIndex="999999"
                  :timeByKeyboard="true"
                  :disabled="sumario.actuationEditModeActive"
                  ref="CustomCalendarTo"
                >
                  <template #footer>
                    <div class="flex justify-content-between">
                      <Button
                        label="Hoy"
                        icon="pi pi-check"
                        class="p-button-text w-6rem"
                        @click="setToNow('date2')"
                      />
                      <div class="flex-grow-1"></div>
                      <Button
                        label="Cerrar"
                        icon="pi pi-times"
                        class="p-button-text w-6rem"
                        @click="closeCalendar('CustomCalendarTo')"
                      />
                    </div>
                  </template>
                </CustomCalendar>
              </div>
              <label :class="{ visible: errores.date2 }"> {{ errores.date2 }}</label>
            </div>
          </div>
        </div>
      </div>
        <Card
          :class="{
            passed:
              alertas() == ' ' &&
              viewData.showFormLugarMomentoAlertas &&
              showValidation == true,
            error:
              alertas() != ' ' &&
              viewData.showFormLugarMomentoAlertas &&
              showValidation == true,
          }"
          class="borderShadow  shadow-3 border-round-lg border-1 border-400" 
          v-if="card"
          tabindex="0"
          test-id="cardFechaHoraUbicacion"
        >
          <template #title>
            <div class="title flex justify-content-between flex-wrap">
              <div test-id="cardFechaHoraTitle" class="text-gray-700 font-medium text-xl">
                Fecha, Hora y Ubicación
              </div>
                <Button
                  v-if="!hasMoment && !hasPlace"
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-info p-button-outlined text-gray-500"
                  @click="$router.push({ path: editPath, query: { id: 'new' } })"
                  test-id="cardFechaHoraUbicacionPlusIcon"
                />
              </div>
          </template>
          <template #content>
            <div class="grid justify-content-between align-items-center">
              <!-- Enviar al relato -->
              <Button
                  v-if="(hasMoment || hasPlace)"
                  icon="pi pi-clone"
                  style="color: rgba(104, 159, 56, 1);"
                  class="p-button-rounded p-button-info p-button-text"
                  @click="pasteInDeposition()"
                  test-id="cardFechaHoraUbicacionPlusIcon"
              />
              <!-- Momento y lugar-->
              <div v-if="hasMoment" class="col-10 flex-row justify-content-start align-items-center gap-3">
                
                  <div class="mb-2">
                    <div class="preMoment"></div>
                    <div class="moment"></div>
                    <div style="display: none" test-id="cardFechaHoraConDatos">
                      {{ strPreMoment }} {{ strMoment }}
                    </div>
                  </div>
             
                  <div class="flex">
                    <div class="preAddress"></div>
                    <div class="address"></div>
                    <div style="display: none" test-id="cardUbicacionConDatos">{{ preAddress }} {{ address }}</div>
                  </div>
              </div>
              <!-- Acciones -->
              <div class="col-1" v-if="hasMoment">
                <MenuOptions
                  :deleteItem="(event) => deleteConfirmation(event)"
                  :editItem="() => $router.push({ path: editPath, query: { id: 'new' } })"
                />
              </div>
              <div v-if="!hasPlace" test-id="cardUbicacionSinDatos" class="flex justify-content-end w-full">
                <span class="text-gray-500">
                  Sin Registros
                </span>
              </div>
              <div>
                <div
                  v-if="
                    ( formType === 'denuncia' ||
                      formType === 'oficio' ||
                      formType === 'contravencional-oficio' ||
                      formType === 'contravencional-denuncia'||  
                      formType.startsWith('ufi') ||
                      (formType.startsWith('preliminar') && formType !== 'preliminar-incendio-vivienda' && formType !== 'preliminar-establecer-procedencias')) &&
                    showValidation == true
                  "
                  :class="{
                    alert:
                      formType === 'denuncia' ||
                      formType === 'oficio' ||
                      formType === 'contravencional-oficio' ||
                      formType === 'contravencional-denuncia' ||  
                      formType.startsWith('ufi') ||
                      (formType.startsWith('preliminar') && formType !== 'preliminar-incendio-vivienda' && formType !== 'preliminar-establecer-procedencias'),
                      'text-right': true,
                  }"
                >
                  <div  :class="{'mt-3': alertas() == 'Falta geolocalización'} ">
                    {{ alertas() }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
    </div>
    <!-- Form Lugar  -->
    <div :class="{ w100: card }" class="grid col-12 md:col-12 sm:col-12" >
      <div class="formulario grid" v-if="!card">
        <div :class="{ 'forms col': !minimal, inputType: minimal }">
          <div class="p-fluid formgrid grid">
            <div>
              <div>
                <label for="calle">Calle <span style="color: red;">*</span></label>
                <InputText
                  class="input"
                  id="calle"
                  type="text"
                  placeholder="Calle"
                  v-model="calle"
                  test-id="inputCalle"
                />
                <label :class="{ visible: errores.calle }"> {{ errores.calle }}</label>
              </div>
              <div>
                <label for="numero">Número <span style="color: red;">*</span></label>
                <InputText
                  id="numero"
                  type="number"
                  placeholder="Número"
                  v-model="numero"
                  test-id="inputNumero"
                />
                <label :class="{ visible: errores.numero }"> {{ errores.numero }}</label>
              </div>
              <br />
              <label for="departamento">Departamento <span style="color: red;">*</span></label>
              <Dropdown
                dataKey="nombre"
                v-model="departamento"
                :options="departamentos"
                optionLabel="nombre"
                placeholder="Departamento"
                test-id="buttonDepartamento"
                :filter="true"
                style="z-index: 999"
              />
              <label :class="{ visible: errores.departamento }"> {{ errores.departamento }}</label>
            </div>
            <div class="field col-12 md:col-12 checkbox" :class="{'-mb-1': !checked}">
              <Checkbox :binary="true" v-model="checked" class="boxcheck" test-id="checkbox"/>
              <label for="addAdressDetailCheckbox" class="labelcheck"
                >Agregar detalles de la dirección</label
              >
            </div>
            <div :class="{'mt-4': !checked, '-mt-3 mb-4': checked}">
              <label for="numero">Ubicación <span style="color: red;">*</span></label>
              <CustomAutoComplete 
                v-model="ubication" 
                :suggestions="ubicationSuggestions" 
                v-on:focus="onFocusEvent()" 
                @complete="searchUbication($event)" 
                placeholder="Ubicación" 
                field="display_name" 
                ref="ubicationRef"
                id="ubicationID"
                test-id="inputUbicacion"
                @selectItem="suggestion => selectASuggestion(suggestion)"
                @blur="CustomAutoCompleteBlur($event)"
                style="z-index: 998"
              >
                <template #item="slotProps">
                  <div @click="selectASuggestion(slotProps.item)">{{slotProps.item.display_name}}</div>
                </template>
              </CustomAutoComplete>
              <label :class="{ visible: errores.ubicacion }"> {{ errores.ubicacion }}</label>
            </div>
            <div class="field col-12 grid" v-if="checked">
              <div class="field col-12 md:col-12">
                <div class="field col-12">
                  <label for="casa">Casa: </label>
                  <InputText id="casa" type="text" placeholder="Número/Letra" v-model="casa"  test-id="casa"/>
                  <label :class="{ visible: errores.casa }"> {{ errores.casa }}</label>
                </div>
                <div class="field col-12 md:col-12">
                  <label for="orientacion">Orientación: </label>
                  <Dropdown
                    dataKey="nombre"
                    v-model="orientacion"
                    :options="optionsOrientation"
                    optionLabel="nombre"
                    :filter="true"
                    test-id="orientacion"
                    placeholder="Elija orientación"
                  />
                </div>
                <div class="field col-12 md:col-12">
                  <label for="sector">Sector: </label>
                  <InputText
                    id="sector"
                    type="text"
                    placeholder="Número/Letra/Nombre"
                    v-model="sector"
                    test-id="sector"
                  />
                  <label :class="{ visible: errores.sector }"> {{ errores.sector }}</label>
                </div>
                <div class="field col-12 md:col-12">
                  <label for="piso">Piso: </label>
                  <InputText id="piso" type="text" placeholder="Número" v-model="piso" test-id="piso" />
                  <label :class="{ visible: errores.piso }"> {{ errores.piso }}</label>
                </div>
                <div class="field col-12 md:col-12">
                  <label for="barrio">Barrio: </label>
                  <InputText id="barrio" type="text" placeholder="Nombre" v-model="barrio" test-id="barrio" />
                  <label :class="{ visible: errores.barrio }"> {{ errores.barrio }}</label>
                </div>
                <div class="field col-12 md:col-12">
                  <label for="zona">Zona: </label>
                  <InputText id="zona" type="text" placeholder="Número/Nombre" v-model="zona" test-id="zona" />
                  <label :class="{ visible: errores.zona }"> {{ errores.zona }}</label>
                </div>
              </div>
            </div>
            <div class="mb-6" :class="{'mt-4': !checked}">
              <div>
                <label for="numero">Latitud <span style="color: red;">*</span></label>
                <InputText
                  id="Lat"
                  type="text"
                  v-model="Lat"
                  :disabled="true"
                />
                <label :class="{ visible: errores.Lat }"> {{ errores.Lat }}</label>
              </div>
              <div class="mt-3">
                <label for="numero">Longitud <span style="color: red;">*</span></label>
                <InputText
                  id="Long"
                  type="text"
                  v-model="Long"
                  :disabled="true"
                />
                <label :class="{ visible: errores.Long }"> {{ errores.Long }}</label>
              </div>
            </div>
          </div>
          <div
            class="actions"
            :class="{ oficioActions: this.formType === 'oficio' }"
            v-if="!minimal"
          >
            <!-- <div style="margin-bottom: 2rem">
              <Button label="Ubicar" icon="pi pi-map-marker" @click="buscarUbicacion" />
            </div> -->
            <div>
              <Button
                label="Guardar"
                icon="pi pi-check"
                @click="save"
                test-id="buttonSaveUbicacion"
                :disabled="!isTouched || !isFormValid"
              />
            </div>
          </div>
        </div>

        <div class="labeled" v-if="!minimal">
          <div class="map">
            <MyMap
              id="map"
              ref="map"
              @updateCoordinates="$_FormUbicacion_handleUpdateCoordinates"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MyMap from '../MyMap.vue';
import CustomCalendar from '../custom-calendar/CustomCalendar.vue';
import AutoComplete from 'primevue/autocomplete';
import CustomAutoComplete from '../../components/custom-autocomplete/CustomAutoComplete.vue';
import MenuOptions from "../MenuOptions/MenuOptions.vue";

export default {
  components: {
    MyMap,
    CustomCalendar,
    AutoComplete,
    CustomAutoComplete,
    MenuOptions
  },
  props: [
    "minimal",
    "modelo",
    "card",
    "editPath",
    "factory",
    "default",
    "formType",
    "errors",
    "showValidation",
    "textareaContravencional",
    "deleteConfirmationInProgress",
    "modalVisble",
    "viewData",
  ],
  name: "FormMomento",
  data() {
    return {
      isTouched: false,
      checked: false,
      filteredLocation: [],
      filteredOrientation: [],
      optionsOrientation: [
        { id: 0, nombre: "Norte" },
        { id: 1, nombre: "Sur" },
        { id: 2, nombre: "Este" },
        { id: 3, nombre: "Oeste" },
      ],
      showButtons: "",
      errores: {
        calle: null,
        numero: null,
        casa: null,
        sector: null,
        piso: null,
        barrio: null,
        zona: null,
        Lat: null,
        Long: null,
        ubicacion: null,
      },
      Lat: 0,
      Long: 0,
      ubication: "",
      ubicationSuggestions: [], 
    };
  },
  departamentos: null,
  beforeRouteEnter(to, from, next) {
    next();
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  mounted() {
    if (this.rememberedCoordinates) {
      this.rememberedSearch(this.rememberedCoordinates);
    }
    // checks wheter there is data into details, if so, renders the input room for details.
    if (
      this.ubicacion.casa ||
      this.ubicacion.orientacion ||
      this.ubicacion.sector ||
      this.ubicacion.piso ||
      this.ubicacion.barrio ||
      this.ubicacion.zona
    )
      this.checked = true;
    
  },
  watch: {
    date(value){
      this.isTouched = true
      // DESDE
      if(value != null){
        if(value > this.date2){
          this.errores.date = 'La fecha Desde no puede ser mayor que la fecha de Hasta' 
          this.errores.date2 = 'La fecha Hasta no puede ser menor que la fecha de Desde' 
        }else{
          this.errores.date = null 
          this.errores.date2 = null
        }
      }
    },
    date2(value){
      this.isTouched = true
      // HASTA
      if(value != null){
        if(value < this.date){
          this.errores.date = 'La fecha Desde no puede ser mayor que la fecha de Hasta' 
          this.errores.date2 = 'La fecha Hasta no puede ser menor que la fecha de Desde' 
        }else{
          this.errores.date = null 
          this.errores.date2 = null
        }
      }
    },
    calle(v) {
      this.isTouched = true
      if (v != null) {
        this.errores.calle =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
      v === '' ? this.errores.calle = 'Este campo es obligatorio': null
      this.ubication = v + ' ' + this.numero;
      if(this?.departamento?.nombre !== undefined && this?.departamento?.nombre !== "Sin Departamento"){
        this.ubication = this.ubication + ', ' + this.departamento.nombre
      }
    },
    numero(v) {
      this.isTouched = true
      if (v != null) {
        this.errores.numero =
          v.length > 45 ? "Este campo no puede contener más de 45 Caracteres" : null;
      }
      v === '' ? this.errores.numero = 'Este campo es obligatorio': null
      this.ubication =  this.calle + ' ' + v;
      if(this?.departamento?.nombre !== undefined && this?.departamento?.nombre !== "Sin Departamento"){
        this.ubication = this.ubication + ', ' + this.departamento.nombre
      }
    },
    departamento(newValue, oldValue) {
      this.isTouched = true
      if(newValue === oldValue) return;
      let newUbication = this.calle + ' ' + this.numero;
      if (newValue.nombre !== "Sin Departamento"){
        newUbication =  this.calle + ' ' + this.numero + ', ' + newValue.nombre;
      }
      this.ubication =  newUbication;
    },
    casa(v) {
      this.isTouched = true
      if (v != null) {
        this.errores.casa =
          v.length > 20 ? "Este campo no puede contener más de 20 Caracteres" : null;
      }
    },
    sector(v) {
      this.isTouched = true
      if (v != null) {
        this.errores.sector =
          v.length > 20 ? "Este campo no puede contener más de 20 Caracteres" : null;
      }
    },
    piso(v) {
      this.isTouched = true
      if (v != null) {
        this.errores.piso =
          v.length > 20 ? "Este campo no puede contener más de 20 Caracteres" : null;
      }
    },
    barrio(v) {
      this.isTouched = true
      if (v != null) {
        this.errores.barrio =
          v.length > 20 ? "Este campo no puede contener más de 20 Caracteres" : null;
      }
    },
    zona(v) {
      this.isTouched = true
      if (v != null) {
        this.errores.zona =
          v.length > 20 ? "Este campo no puede contener más de 20 Caracteres" : null;
      }
    },
    ubication(value){
      this.isTouched = true
      value === '' ? this.errores.ubicacion = 'Este campo es obligatorio' : null
    }
  },
  computed: {
    ...mapState({
      ubicacion(state) {
        return state[this.modelo].preUbicacion;
      },
      ubicacionOk(state) {
        return state[this.modelo].ubicacion;
      },
      departamentos: (state) => state.siis.ubicacion.departamentos,
      sumario: (state) => state.sumario,
    }),
    isFormValid() {
    // Verificamos si hay errores
      const hasErrors = Object.values(this.errores).some(value => value !== null && value !== '');
      // Verificamos si hay campos obligatorios vacíos
      const requiredFields = ['calle', 'numero', 'departamento', 'Lat', 'Long', 'date', 'date2', 'ubication'];
      const hasEmptyFields = requiredFields.some(field => !this[field]);
      return !hasErrors && !hasEmptyFields;
    },
    departamento: {
      get() {
        let departamento = this.ubicacion.departamento;
        if(departamento === undefined) departamento = '';
        return departamento;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, departamento: value });
      },
    },
    calle: {
      get() {
        let calle = this.ubicacion.calle;
        if(calle === undefined) calle = '';
        return calle;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, calle: value });
      },
    },
    numero: {
      get() {
        let numero = this.ubicacion.numero;
        if(numero === undefined) numero = '';
        return numero;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, numero: value });
      },
    },
    rememberedCoordinates: {
      get() {
        return this.ubicacion.coordinates;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, coordinates: value });
      },
    },
    hasPlace: {
      get() {
        return this.address != "" || this.preAddress != "";
      },
    },
    address: {
      get() {
        let inputs = 0;

        let calleString = "sin calle";
        if (this.ubicacionOk.calle) {
          calleString = this.ubicacionOk.calle;
          inputs++;
        }

        let numeroString = "sin numero";
        if (this.ubicacionOk.numero) {
          numeroString = this.ubicacionOk.numero;
          inputs++;
        }

        let dto =
          typeof this.ubicacionOk.departamento == "object"
            ? this.ubicacionOk.departamento.nombre
            : false;
        let localidadString = "sin localidad";
        if (dto) {
          localidadString = dto == "CAPITAL" ? "SAN JUAN" : dto;
          inputs++;
        }

        let result = inputs > 0 ? `${calleString} ${numeroString}, ${localidadString}` : "";
        return result;
      },
    },
    preAddress: {
      get() {
        let inputs = 0;

        let calleString = "sin calle";
        if (this.ubicacion.calle) {
          calleString = this.ubicacion.calle;
          inputs++;
        }

        let numeroString = "sin numero";
        if (this.ubicacion.numero) {
          numeroString = this.ubicacion.numero;
          inputs++;
        }
        let dto =
          this.ubicacion.departamento != undefined && typeof this.ubicacion.departamento == "object"
            ? this.ubicacion.departamento.nombre
            : false;
        let localidadString = "sin localidad";
        if (dto) {
          localidadString = dto == "CAPITAL" ? "SAN JUAN" : dto;
          inputs++;
        }

        let casaString = "";
        if (this.ubicacion.casa) {
          casaString = `, ${this.ubicacion.casa}`;
          inputs++;
        }

        let orientacionString = "";
        if (this.ubicacion.orientacion) {
          if(this.ubicacion.orientacion?.nombre?.nombre === undefined){ 
            if(this.ubicacion.orientacion.nombre !== null){
              orientacionString = ", " + this.ubicacion.orientacion.nombre;
            } 
          } else {
            orientacionString = `${this.ubicacion.orientacion.nombre.nombre === null ? '' : ' , ' + this.ubicacion.orientacion.nombre.nombre}`;
          }         
          inputs++;
        }
        let sectorString = "";
        if (this.ubicacion.sector) {
          sectorString = ` , ${this.ubicacion.sector}`;
          inputs++;
        }

        let pisoString = "";
        if (this.ubicacion.piso) {
          pisoString = ` , ${this.ubicacion.piso}`;
          inputs++;
        }

        let barrioString = "";
        if (this.ubicacion.barrio) {
          barrioString = ` , ${this.ubicacion.barrio}`;
          inputs++;
        }

        let zonaString = "";
        if (this.ubicacion.zona) {
          zonaString = ` , ${this.ubicacion.zona}`;
          inputs++;
        }

        let result =
          inputs > 0
            ? `${calleString} ${numeroString}, ${localidadString}${casaString}${orientacionString}${sectorString}${pisoString}${barrioString}${zonaString}`
            : "";

        this.setCardHTML(".preAddress", result);
        return result;
      },
    },
    searchAddress: {
      get() {
        let inputs = 0;

        let calleString = "sin calle";
        if (this.ubicacion.calle) {
          calleString = this.ubicacion.calle;
          inputs++;
        }

        let numeroString = "";
        if (this.ubicacion.numero) {
          numeroString = this.ubicacion.numero;
          inputs++;
        }
        let dto =
          typeof this.ubicacion.departamento == "object"
            ? this.ubicacion.departamento.nombre
            : false;
        let localidadString = "";
        if (dto) {
          localidadString = dto == "CAPITAL" ? "SAN JUAN" : dto;
          inputs++;
        }

        if (localidadString != "") localidadString = ", " + localidadString;

        let result = inputs > 0 ? `${calleString} ${numeroString}${localidadString}` : "";
        return result;
      },
    },
    casa: {
      get() {
        return this.ubicacion.casa;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, casa: value });
      },
    },
    sector: {
      get() {
        return this.ubicacion.sector;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, sector: value });
      },
    },
    piso: {
      get() {
        return this.ubicacion.piso;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, piso: value });
      },
    },
    barrio: {
      get() {
        return this.ubicacion.barrio;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, barrio: value });
      },
    },
    zona: {
      get() {
        return this.ubicacion.zona;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, zona: value });
      },
    },
    orientacion: {
      get() {
        return this.ubicacion.orientacion;
      },
      set(value) {
        this.setPreUbicacion({ ...this.ubicacion, orientacion: value });
      },
    },
    today() {
      return new Date();
    },
    moment: {
      get() {
        return this.$store.state[this.modelo].preMoment;
      },
    },
    momentoOk: {
      get() {
        return this.$store.state[this.modelo].moment;
      },
    },
    // }),
    date: {
      get() {
        if (!this.moment.date) {
          if (this.default && this.default.date) {
            this.setPreMoment({ ...this.moment, date: this.default.date() });
            this.setMoment({ ...this.moment, date: this.default.date() });
          }
        }
        return this.moment.date;
      },
      set(value) {
        this.setPreMoment({ ...this.moment, date: value });
        this.setMoment({ ...this.moment, date: value });
      },
    },
    date2: {
      get() {
        if (!this.moment.date2) {
          if (this.default && this.default.date2) {
            this.setPreMoment({ ...this.moment, date2: this.default.date2() });
            this.setMoment({ ...this.moment, date2: this.default.date2() });
          }
        }
        return this.moment.date2;
      },
      set(value) {
        this.setPreMoment({ ...this.moment, date2: value });
        this.setMoment({ ...this.moment, date2: value });
      },
    },
    //card
    hasMoment: {
      get() {
        return this.strPreMoment != "";
      },
    },
    strMoment: {
      get() {
        let inputs = 0;

        let dateString = "sin fecha";
        if (this.momentoOk.date) {
          let date = this.momentoOk.date;
          if (typeof date == "string") date = new Date(date);
          dateString = date.toLocaleDateString("es-AR");
          inputs++;
        }

        let hourString = "hora no establecida";
        if (this.momentoOk.date) {
          let date = this.momentoOk.date;
          if (typeof date == "string") date = new Date(date);
          hourString = date.toLocaleTimeString("es-AR", { hour: "2-digit", minute: "2-digit" });
          inputs++;
        }

        let date2String = dateString;
        if (this.momentoOk.date2) {
          let date = this.momentoOk.date2;
          if (typeof date == "string") date = new Date(date);
          let toTimeDate = date.toLocaleDateString("es-AR");
          // console.log(toTimeDate)

          date2String = toTimeDate;
          inputs++;
        }

        let hour2String = hourString;
        if (this.momentoOk.date2) {
          let date = this.momentoOk.date2;
          if (typeof date == "string") date = new Date(date);
          let toTime = date.toLocaleTimeString("es-AR", { hour: "2-digit", minute: "2-digit" });
          hour2String = toTime;
          inputs++;
        }

        let from = dateString + " " + hour2String;
        let until = date2String + " " + hourString;

        // console.log(date2String)
        // if (input > 0)
        let result = inputs > 0 ? `Entre ${from} y ${until}` : "";
        // this.setCardHTML(".moment", result)
        return result;
      },
    },
    strPreMoment: {
      get() {
        let inputs = 0;

        let dateString = "sin fecha";
        if (this.moment.date) {
          let date = this.moment.date;
          if (typeof date == "string") date = new Date(date);
          dateString = date.toLocaleDateString("es-AR");
          inputs++;
        }

        let date2String = "sin fecha";
        if (this.moment.date2) {
          let date = this.moment.date2;
          if (typeof date == "string") date = new Date(date);
          let toTimeDate = (date2String = date.toLocaleDateString("es-AR"));
          date2String = toTimeDate;
          inputs++;
        }

        let hourString = "hora no establecida";
        if (this.moment.date) {
          let date = this.moment.date;
          if (typeof date == "string") date = new Date(date);
          hourString = date.toLocaleTimeString("es-AR", { hour: "2-digit", minute: "2-digit" });
          inputs++;
        }

        let hour2String = "hora no establecida";
        if (this.moment.date2) {
          let date = this.moment.date2;
          if (typeof date == "string") date = new Date(date);
          let toTime = date.toLocaleTimeString("es-AR", { hour: "2-digit", minute: "2-digit" });
          hour2String = toTime;
          inputs++;
        }

        let from = dateString != "sin fecha" ? dateString : "";
        from = hourString != "hora no establecida" ? `${from} ${hourString}` : from;
        // dateString + " " + hourString
        let until = date2String != "sin fecha" ? date2String : "";
        until = hour2String != "hora no establecida" ? `${until} ${hour2String}` : until;
        // date2String + " " + hour2String

        // if (input > 0)
        // let result = inputs > 0 ? `Entre ${from} y ${until}` : "";
        let result = from != "" && until != "" ? `Entre ${from} y ${until}` : "";
        result = result == "" && from != "" && until == "" ? from : result;
        result = result == "" && from == "" && until != "" ? until : result;

        this.setCardHTML(".preMoment", result);
        return result;
      },
    },
    // dataUbication: {
    //   get() {
    //     let ubication =  this.calle + ' ' + this.numero +  this.ubication
    //     if(ubication === undefined) ubication = '';
    //     return ubication;
    //   },
    //   set(value) {
    //     this.ubication = value;
    //     // console.log('value', value);
    //     // return value;
    //   },
    // },
  },
  methods: {
    alertas() {
      // console.log('this.errors', this.errors);
      // console.log("datos:\n", JSON.stringify(this.errors))
      if (this.errors.includes("La fecha del hecho no puede ser posterior a la fecha actual")) {
        return "La fecha del hecho no puede ser posterior a la fecha actual";
      } else if (this.errors.includes("Falta fecha del hecho")) {
        return "Falta fecha del hecho";
      } else if (this.errors.includes("Verificar el orden de las fechas del hecho")) {
        return "Verificar el orden de las fechas del hecho";
      } else if (this.errors.includes("Falta ubicación del hecho")) {
        return "Falta ubicación del hecho";
      } else if (this.errors.includes("Falta geolocalización")) {
        return "Falta geolocalización";
      } else if (this.errors.includes("Falta lugar del hecho")) {
	      return "Falta lugar del hecho";
      } else {
        return " ";
      }
    },
    enableField(v) {
      if (!this.factory) return true;
      return this.factory.indexOf(`{${v}}`) >= 0;
    },
    setPreMoment(data) {
      this.$store.dispatch(this.modelo + "/setPreMoment", data);
      this.setValue();
    },
    setMoment(data) {
      // console.log(this.modelo, data);
      this.$store.dispatch(this.modelo + "/setMoment", data);
      // this.setValue();
    },
    setValue() {
      if (!this.factory) return;
      const regexDate = /{Date}/gi;
      const regexDate2 = /{Date2}/gi;
      const regexHour = /{Hour}/gi;
      const regexHour2 = /{Hour2}/gi;
      let value = this.factory;
      if (this.date) value = value.replace(regexDate, this.date.toLocaleDateString("es-AR"));
      if (this.date2) value = value.replace(regexDate2, this.date2.toLocaleDateString("es-AR"));
      if (this.date)
        value = value.replace(
          regexHour,
          this.date.toLocaleTimeString("es-AR", { hour: "2-digit", minute: "2-digit" })
        );
      if (this.date2)
        value = value.replace(
          regexHour2,
          this.date2.toLocaleTimeString("es-AR", { hour: "2-digit", minute: "2-digit" })
        );
      this.$emit("setValue", value);
    },
    setCardHTML(parent, html) {
      setTimeout(() => {
        if (document.querySelector(parent)) {
          document.querySelector(parent).innerHTML = html;
        }
      }, 100);
    },
    showHiddenButtons() {
      if (this.hasMoment || this.hasPlace) {
        if (this.deleteConfirmationInProgress !== true || this.modalVisble !== true)
          this.showButtons = false;
      }
    },
    setPreUbicacion(data) {
      this.$store.dispatch(this.modelo + "/setPreUbicacion", data);
      this.setValue();
      //reinicia preform
      let actualPath = this.$router.history.current.path.split("/");
      if (actualPath[actualPath.length - 2] !== "denuncia")
        this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
    },
    setUbicacion(data) {
      this.$store.dispatch(this.modelo + "/setUbicacion", data);
      this.setValue();

      //reinicia preform
      let actualPath = this.$router.history.current.path.split("/");
      if (actualPath[actualPath.length - 2] !== "denuncia")
        this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
    },
    filterLocation(event) {
      this.filteredLocation = this.departamentos.filter(
        (item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0
      );
    },
    filterOrientation(event) {
      this.filteredOrientation = this.optionsOrientation.filter(
        (item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0
      );
    },
    async buscarUbicacion() {
      const searchUrl = "https://nominatim.openstreetmap.org/search?format=json&q=";
      const addressToSearch = this.ubication + ", San Juan, Argentina";
      const optionsString = await fetch(`${searchUrl}${encodeURI(addressToSearch)}`);
      const options = await optionsString.json();
      this.ubicationSuggestions = options;
    },
    locateOnMap(searchOnMap){
      this.$refs.map.setPosition({
        lat: parseFloat(searchOnMap.lat),
        lng: parseFloat(searchOnMap.lon),
      });
      this.rememberedCoordinates = {
        lat: parseFloat(searchOnMap.lat),
        lng: parseFloat(searchOnMap.lon),
      };
      this.Lat = parseFloat(searchOnMap.lat);
      this.Long = parseFloat(searchOnMap.lon);
    },
    $_FormUbicacion_handleUpdateCoordinates(coordinates) {
      this.Lat = coordinates.lat
      this.Long = coordinates.lng
      this.rememberedCoordinates = coordinates;
    },
    rememberedSearch(coordinates) {
      this.Lat = this.ubicacion.coordinates.lat
      this.Long = this.ubicacion.coordinates.lng
      if (this.$refs.map) {
        this.$refs.map.setPosition({
          lat: coordinates.lat,
          lng: coordinates.lng,
        });
      }
    },
    setValue() {
      if (!this.factory) return;
      const regex1 = /{calle}/gi;
      const regex2 = /{numero}/gi;
      const regex3 = /{departamento}/gi;
      const regex4 = /{rememberedCoordinates}/gi;
      const regex5 = /{casa}/gi;
      const regex6 = /{sector}/gi;
      const regex7 = /{piso}/gi;
      const regex8 = /{barrio}/gi;
      const regex9 = /{zona}/gi;
      let value = this.factory;
      if (this.calle) value = value.replace(regex1, this.calle);
      if (this.numero) value = value.replace(regex2, this.numero);
      if (this.departamento) value = value.replace(regex3, this.departamento.nombre);
      if (this.rememberedCoordinates) value = value.replace(regex4, this.rememberedCoordinates);
      if (this.casa) value = value.replace(regex5, this.casa);
      if (this.sector) value = value.replace(regex6, this.sector);
      if (this.piso) value = value.replace(regex7, this.piso);
      if (this.barrio) value = value.replace(regex8, this.barrio);
      if (this.zona) value = value.replace(regex9, this.zona);
    },
    setCardHTML(parent, html) {
      setTimeout(() => {
        if (document.querySelector(parent)) {
          document.querySelector(parent).innerHTML = html;
        }
      }, 100);
    },
    save() {
      //validamos los posibles errores
      if (this.validate() == true) {
        alert("Verifica los campos");
      }
      this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
      
      this.$emit('save')
      // derivador de la dirección para el botón "Guardar"
      // let actualPath = this.$router.currentRoute.path.split('/');
      // if (actualPath.length <= 6) {
      //   if(actualPath.includes("denuncia") && !actualPath.includes("oficio")){
      //     this.$router.push("/sumario/denuncia/formulario");
      //   }
      //   else {
      //     this.$router.push("/sumario/denuncia/formulario/oficio");
      //   }
      // }
      // else {
      //   if(actualPath.includes("denuncia") && actualPath.includes("contravencional") && !actualPath.includes("oficio")){
      //     this.$router.push("/sumario/denuncia/formulario/contravencional/denuncia");
      //   }
      //   else {
      //     this.$router.push("/sumario/denuncia/formulario/contravencional/oficio");
      //   }
      // }
    },
    validate() {
      for (let i in this.errores) {
        if (this.errores[i] != null) {
          return true;
        }
      }
      return false;
    },
    pasteInDeposition() {
      this.$emit(
        "moveToDeposition",
        this.strPreMoment + ", " + this.preAddress + ", " + this.address
      );
    },
    restartLocation() {
      this.$store.dispatch(this.modelo + "/setPreUbicacion", {});
      this.$store.dispatch(this.modelo + "/setUbicacion", {});
    },
    restartDate() {
      this.date = undefined;
      this.date2 = undefined;
    },
    deleteConfirmation(event) {
      this.$emit("deleteConfirmationUbicacion", event, "ubicación");
      this.$emit("deleteConfirmationMomento", event, "momento");
    },
    addShowButton() {
      if (this.hasMoment || this.hasPlace) {
        if (this.deleteConfirmationInProgress !== true || this.modalVisble !== true)
          this.showButtons = true;
      }
    },
    searchUbication() {
      this.buscarUbicacion();
		},
    onFocusEvent(){
      document.getElementById('ubicationID').dispatchEvent(new Event('input'));
    },
    selectASuggestion(suggestion){
      this.locateOnMap(suggestion);
      this.ubication = suggestion.display_name;
      document.getElementById("ubicationID").value = suggestion.display_name;
    },
    CustomAutoCompleteBlur(e){
      this.ubication = e.target.value;
    },
    setToNow(vModel){
      this[vModel] = this.today;
    },
    closeCalendar(calendarType){
      this.$refs[calendarType].overlayVisible = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
div.card {
  width: 100%;
}
.borderShadow:focus {
  box-shadow: 0 0 0 0.2rem #89d4fe;
  outline: none;
}
>>> .map {
  position: absolute;
  top: 65px;
  width: 70% !important;
}
>>> .vue2leaflet-map.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
  position: static;
  height: 80vh;
  width: 1200px;
}
.title {
  font-size: 1rem;
}
.title-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.search > input {
  margin-right: 10px;
}

.search {
  display: grid;
  justify-content: space-between;
  align-items: center;
}

.forms > div,
.labeled,
.inputType {
  display: flex;
  flex-wrap: wrap;
}
.inputType label {
  display: none;
}
.forms > div,
.labeled {
  flex-direction: column;
}
.forms > div.actions {
  flex-direction: row-reverse;
  padding-top: 20px;
  padding-right: 20px;
}

.forms > div .input {
  min-width: 20vw;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
}

div.flex-inline {
  display: flex;
  flex-wrap: wrap;
}

.minus10 {
  margin-top: -5px;
}
.passed {
  border-bottom: solid 2px #28a745;
  /* background-color: #28a745; */
}
.error {
  border-bottom: solid 2px #dc3545;
}
.warning {
  border-bottom: solid 2px #ffc107;
}
.alert {
  color: #dc3545;
}
.paste-button {
  width: 25px;
}
.actions__card {
  padding: 2px;
  gap: 3px;
}
.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
div.card {
  width: 100%;
}
.borderShadow:focus {
  box-shadow: 0 0 0 0.2rem #89d4fe;
  outline: none;
}
.map {
  display: block;
  margin-top: 10px;
  height: 60vh;
  width: 50vw;
}
.title {
  padding-top: 5px;
  padding-bottom: 0px;
  font-size: 1rem;
}
.title-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.search > input {
  margin-right: 10px;
}

.forms > div,
.labeled,
.inputType {
  display: flex;
  flex-wrap: wrap;
}
.inputType label {
  display: none;
}
.forms > div,
.labeled {
  flex-direction: column;
}
.forms > div.actions {
  flex-direction: row-reverse;
  padding-top: 20px;
  padding-right: 20px;
}

.forms > div .input {
  min-width: 20vw;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
}

div.flex-inline {
  display: flex;
  flex-wrap: wrap;
}

.minus10 {
  margin-top: -5px;
}
.passed {
  border-bottom: solid 2px #28a745;
  /* background-color: #28a745; */
}
.error {
  border-bottom: solid 2px #dc3545;
}
.warning {
  border-bottom: solid 2px #ffc107;
}
.alert {
  color: #dc3545;
}
.paste-button {
  width: 25px;
}
.formulario {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  gap: 15px;
}

div.card {
  width: 100%;
}
.actions > div > button {
  margin-left: 10px;
}
.map {
  display: block;
  margin-top: 10px;
  height: 60vh;
  width: 50vw;
}
.borderShadow:focus {
  box-shadow: 0 0 0 0.2rem #89d4fe;
  outline: none;
}
.title {
  padding-top: 5px;
  padding-bottom: 0px;
  font-size: 1rem;
}
.title-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.search > input {
  margin-right: 10px;
}

.forms > div,
.labeled,
.inputType {
  display: flex;

  /* align-items:flex-start;
  flex-wrap: wrap; */
  flex-wrap: wrap;
}
.inputType label {
  display: none;
}

.forms > div.actions {
  flex-direction: row-reverse;
}

.oficioActions {
  margin-top: 20px;
}

.forms > div .input {
  min-width: 20vw;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
}

div.flex-inline {
  display: flex;
  flex-wrap: wrap;
}

.minus10 {
  margin-top: -5px;
}

.label-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.label-mt {
  margin-top: 20px;
}

.input-oficio {
  max-width: 220px;
  margin: 10px 20px 20px;
}

@media (min-width: 1080px) {
  .inputs-container {
    max-width: 350px;
    margin-top: -60px;
  }
}

@media (max-width: 1080px) {
  .inputs-container {
    max-width: 350px;
    margin-top: 20px;
  }
}

@media (max-width: 720px) {
  .map {
    width: 90vw;
  }
}

.passed {
  border-bottom: solid 2px #28a745;
}
.error {
  border-bottom: solid 2px #dc3545;
}
.warning {
  border-bottom: solid 2px #ffc107;
}
.alert {
  color: #dc3545;
}
.paste-button {
  width: 25px;
}
.checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 35px;
}
.labelcheck {
  margin-top: 9px;
}
.actions__card {
  padding: 2px;
  gap: 3px;
}
.disable {
  display: none;
}
.visible {
  color: #dc3545;
  text-align: center;
}
</style>
