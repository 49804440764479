<template>
  <div>
    <div v-if="!enableForm && !saving">
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="5"
        fill="#EEEEEE"
        animationDuration="1.5s"
      />
    </div>
    <div  v-show="enableForm && !saving">
      <!-- formulario -->
      <div class="pageFormComponent grid w-full">
        <!-- inicia columna -->
        <div v-for="(pageCol, index) in activeDesign" :key="index" class="col"  :class="pageCol.class">
          <!-- inicia card -->

          <div
            id="card"
            :title="card.title"
            class="boxes"
            :class="card.class"
            :cardMode="cards"
            v-for="(card, indexCards) in pageCol.cards"
            :key="indexCards"
          >
            <!-- inicia fila en card -->
            <div v-for="(rows, index) in card.design" :key="index" class="grid mb-2">
              <!-- inicia columna en card -->
              <div
                v-for="(nameField, indexField) in rows"
                :key="indexField"
                class="col"
              >
                <!-- espacio vacio, solo para maquetacion -->
                <div v-if="nameField == ''"></div>

                <!-- campos editables -->
                <div v-if="nameField != ''">

                  <!-- header de campo -->
                  <div class="inputHead flex align-items-center">
                    <!-- label -->
                    <label
                      v-if="
                        params[nameField].type != 'hidden' &&
                        isVisibleComponent(nameField)
                      "
                      :class="[{ 'font-semibold': viewer }, 'py-1']"
                      >{{ getLabel(nameField) }}
                      <span v-if="params[nameField].validation && // Valida si el tipo de dato es un string o array para utilizar el método .includes()
                                (typeof params[nameField].validation === 'string' ||
                                Array.isArray(params[nameField].validation)) &&
                                params[nameField].validation.includes('required') &&
                                !(params[nameField].type == '1to1')"
                                style="color: red;">*</span>
                    </label>
                    <!-- boton de refrezco para dropdowns -->
                    <Button
                      id="refresh"
                      v-if="
                        ( !viewer || onlyEdit.includes(nameField) ) &&
                        params[nameField].dropdown &&
                        params[nameField].dropdown.refreshDropdown
                      "
                      icon="pi pi-refresh"
                      class="p-button-sm p-button-success p-button-text p-button-rounded"
                      @click="params[nameField].dropdown.refreshDropdown"
                    />

                  </div>
                  <!-- fin header de campo -->

                  <!-- componente principal del campo -->


                  <!-- tipo: textInfo  -->
                  <label
                    v-if="( !viewer || onlyEdit.includes(nameField) ) && params[nameField].type == 'textInfo'"
                    :test-id="params[nameField].testId"
                    :class="[{ 'font-semibold': true }, 'py-1']"
                    >
                    {{ formatData(nameField, values) }}

                    </label>
                  <div class="inputComponent">
                    <!-- tipo: input  -->
                    <InputText
                      :ref="nameField"
                      v-if="( !viewer || onlyEdit.includes(nameField) ) && params[nameField].type == 'input'"
                      :class="{ input: true, 'p-invalid': !isValid(nameField) }"
                      :type="params[nameField].inputType || 'text'"
                      min="0"
                      :disabled="isDisableField(nameField)"
                      @blur="() => handleBlur(nameField)"
                      :placeholder="getPlaceholder(nameField)"
                      v-model="values[nameField]"
                      :test-id="params[nameField].testId"
                    />
                    <!-- tipo: password  -->
                    <InputText
                      :ref="nameField"
                      v-if="( !viewer || onlyEdit.includes(nameField) ) && params[nameField].type == 'password'"
                      :class="{ input: true, 'p-invalid': !isValid(nameField) }"
                      type="password"
                      min="0"
                      :disabled="isDisableField(nameField)"
                      :placeholder="getPlaceholder(nameField)"
                      v-model="values[nameField]"
                    />

                    <!-- tipo: SelectButton  -->
                    <!-- {{values[nameField]}} -->
                    <SelectButton
                      :ref="nameField"
                      v-if="( !viewer || onlyEdit.includes(nameField) ) && params[nameField].type == 'SelectButton'"
                      :class="{ input: true, 'p-invalid': !isValid(nameField) }"
                      :disabled="isDisableField(nameField)"
                      v-model="values[nameField]"
                      optionLabel="name"
                      optionValue="value"
                      dataKey="value"
                      :options="params[nameField].options"
                      />
                      <!-- :value="values[nameField]"
                      optionLabel="name"
                      optionValue="value" -->

                    <!-- tipo: textarea  -->
                    <Textarea
                      :ref="nameField"
                      v-if="( !viewer || onlyEdit.includes(nameField) ) && params[nameField].type == 'textarea'"
                      :class="{ input: true, 'p-invalid': !isValid(nameField) }"
                      :autoResize="true"
                      rows="5"
                      :disabled="isDisableField(nameField)"
                      @blur="() => handleBlur(nameField)"
                      :placeholder="getPlaceholder(nameField)"
                      v-model="values[nameField]"
                      :test-id="params[nameField].testId"
                    />

                    <!-- tipo: slider  -->
                    <div v-if="( !viewer || onlyEdit.includes(nameField) ) && params[nameField].type == 'slider'">
                      {{ changeType(nameField) }}
                      <Slider
                        :ref="nameField"
                        :min="params[nameField].min"
                        :max="params[nameField].max"
                        :step="params[nameField].step"
                        v-model="values[nameField]"
                        :value="values[nameField]"
                        :disabled="isDisableField(nameField)"
                        test-id="sliderEdit"
                        class="w-full"
                      />
                      <p test-id="sliderValue">{{ values[nameField] }}</p>
                    </div>

                    <!--
                      tipo: 1to1
                      * usado para union rapida a tablas externas
                    -->
                    <div
                      v-if="params[nameField].type == '1to1'"
                      :ref="nameField"
                    >
                      <dinamic-viewer
                        v-if="
                          values[nameField] &&
                          values[nameField] != '' &&
                          values[nameField] != 'sin datos' &&
                          isDisable
                        "
                        :viewerID="values[nameField]"
                        :field="nameField"
                        :viewerComponent="params[nameField].viewerComponent"
                        class="w-full"
                      ></dinamic-viewer>
                      <div>
                        <Button
                          v-if="( !viewer || onlyEdit.includes(nameField) )"
                          :disabled="isDisableField(nameField)"
                          :label="params[nameField].buttonLabel ? params[nameField].buttonLabel : 'Seleccionar'"
                          icon="pi pi-external-link"
                          @click="openBasic(nameField)"
                          class="p-button"
                          :test-id="nameField"
                        />
                      </div>
                    </div>

                    <!-- tipo: template -->
                    <!-- Advertencia, se abre tambien en modo viewer -->
                    <div v-if="params[nameField].type == 'template'">
                      <slot
                        :ref="nameField"
                        :name="nameField"
                        :viewer="( viewer && !onlyEdit.includes(nameField) )"
                        :disabled="isDisableField(nameField)"
                        :value="values[nameField]"
                        :change="(val, field) => setValue(val, field || nameField)"
                        :data="values"
                        class="w-full"
                        :test-id="params[nameField].testId || nameField"
                      ></slot>
                    </div>

                    <!-- tipo: dropdown -->
                    <Dropdown
                      :ref="nameField"
                      v-if="( !viewer || onlyEdit.includes(nameField) ) && params[nameField].type == 'dropdown'"
                      v-model="values[nameField]"
                      :options="params[nameField].dropdown.options"
                      @change="() => changeDropdown(nameField, values)"
                      :optionLabel="params[nameField].dropdown.optionLabel"
                      :optionValue="params[nameField].dropdown.optionValue"
                      :filter="true"
                      :placeholder="getPlaceholder(nameField)"
                      :showClear="true"
                      :disabled="isDisableField(nameField)"
                      :class="{ 'p-invalid': !isValid(nameField) }"
                      :test-id="'dropdown'  + nameField"
                    >
                    </Dropdown>

                    <!-- tipo: FileUpload -->
                    <div v-if="( !viewer || onlyEdit.includes(nameField) ) && isVisibleComponent(nameField, 'FileUpload')">
                      <!-- showUploadButton(nameField): {{showUploadButton(nameField)}} -->
                      <CustomFileUpload
                        :ref="'FileUpload-' + nameField"
                        @parentUpdateFiles="
                          (event, clear) => updateFiles(nameField, event, clear)
                        "
                        @parentMyUploader="() => uploadFiles(nameField)"
                        @parentRemoveFiles="() => removeFiles(nameField)"
                        d="userfile[]"
                        invalidFileSizeMessage="{0}: tamaño no válido, el archivo no debe pesar más de {1}."
                        :accept="params[nameField].upload.accept"
                        :auto="true"
                        :customUpload="true"
                        @uploader="(event) => updateFiles(nameField, event)"
                        :maxFileSize="params[nameField].upload.maxFileSize"
                        :multiple="params[nameField].upload.multiple"
                        :showUploadButton="showUploadButton(nameField)"
                        uploadLabel="Adjuntar"
                        chooseLabel="Examinar..."
                        class="p-button-sm w-full"
                        :fileExtension="params[nameField].upload.fileExtension"
                        :disabled="isDisableField(nameField)"
                        :buttonsDelete="true"
                      >
                        <template #empty>
                          <p>Examine o arrastre archivos aquí.</p>
                        </template>
                      </CustomFileUpload>
                    </div>

                    <!-- type: date -->
                    <Calendar
                      :ref="nameField"
                      v-if="( !viewer || onlyEdit.includes(nameField) ) && (nameField == 'Fecha' || params[nameField].type == 'date')"
                      :class="{ input: true, 'p-invalid': !isValid(nameField) }"
                      :placeholder="getPlaceholder(nameField)"
                      dateFormat="dd-mm-yy"
                      v-model="values[nameField]"
                      :disabled="isDisableField(nameField)"
                    />

                    <!-- type: image_gallery -->
                    <div v-if="params[nameField].type == 'image_gallery'">
                      <Galleria
                        :ref="nameField"
                        :value="params[nameField].gallery.list"
                        :activeIndex.sync="activeIndex"
                        :responsiveOptions="responsiveOptions"
                        :numVisible="7"
                        :circular="true"
                        :fullScreen="true"
                        :showItemNavigators="true"
                        :showThumbnails="false"
                        :visible.sync="displayCustom"
                      >
                        <template #item="slotProps">
                          <img
                            :src="slotProps.item.itemImageSrc"
                            :alt="slotProps.item.alt"
                            style="width: 100%; display: block;"
                            @error="
                              (e) => imgError(e, slotProps.item, nameField, 'lg')
                            "
                          />
                        </template>
                        <template #thumbnail="slotProps">
                          <img
                            :src="slotProps.item.thumbnailImageSrc"
                            :alt="slotProps.item.alt"
                            style="width: 100%; display: block;"
                            @error="
                              (e) => imgError(e, slotProps.item, nameField, 'sd')
                            "
                          />
                        </template>
                      </Galleria>

                      <Card class="-m-2" v-if="params[nameField].gallery.list.length > 0">
                        <template #content>
                          <DataTable :ref="nameField" :value="params[nameField].gallery.list" responsiveLayout="scroll">
                            <template #header>
                              <div class="flex">
                                <div>
                                  Imágenes del Objeto
                                </div>
                                <div>
                                  <Button
                                    id="refresh"
                                    v-if="mode == 'edit' && params[nameField].gallery && params[nameField].gallery.refresh"
                                    icon="pi pi-refresh"
                                    class="
                                      p-button-rounded
                                      p-button-outlined
                                      ml-4
                                    "
                                    style="margin-top: -7px"
                                    @click="params[nameField].gallery.refresh"
                                  />
                                </div>
                              </div>
                            </template>
                            <Column>
                              <template #body="slotProps">
                                <img
                                  :src="slotProps.data.thumbnailImageSrc"
                                  :alt="slotProps.data.alt"
                                  style="cursor: pointer"
                                  :ref="'img' + slotProps.data.index"
                                  @click="imageClick(slotProps.index)"
                                  @error="(e) => imgError(e, slotProps.data.index, slotProps.data.nameField, 'sd')"
                                  height="100px"
                                  width="150px"
                                />
                              </template>
                            </Column>
                            <Column field="title"></Column>
                            <Column>
                              <template #body="slotProps">
                                <div v-if="loadingDeleteButton !== slotProps.data.File_ArticuloDepositoID">
                                  <Button
                                    v-if="( !viewer || onlyEdit.includes(nameField) )"
                                    v-tooltip.bottom="'Eliminar'"
                                    icon="pi pi-trash"
                                    class="p-button-danger p-button-lg px-2 py-2 m-1"
                                    @click="deleteConfirmation($event, 'imagen', slotProps.data.File_ArticuloDepositoID)"
                                    @blur="closeConfirmpopupWithDelay()"
                                  />
                                </div>
                                <div v-else>
                                  <ProgressSpinner style="width:50px;height:50px;z-index: 3" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s" />
                                </div>
                                <div class="invisible-div" v-if="deleteConfirmationInProgress" />
                              </template>
                            </Column>
                          </DataTable>
                        </template>
                      </Card>
                    </div>
                    <!-- fin image_gallery -->

                    <!-- Inicio table Sumario -->
                    <div v-if="params[nameField].type == 'table'" :test-id="'divContenedorTableSumario'">
                      <Card :test-id="'cardTableSumario'">
                        <template #content :test-id="'templateTableSumario'">
                          <div class=" flex justify-content-between" style="" :test-id="'DivButtonTableSumario'">
                            <h2 :test-id="'TitleTableSumario'">{{params[nameField].title}}</h2>
                            <Button
                              v-if="( !viewer || onlyEdit.includes(nameField) ) && params[nameField]['button']"
                              :label="params[nameField]['button']"
                              class="p-button-sm mt-4 mb-2"
                              @click="openBasic(nameField)"
                              :test-id="'Button'+params[nameField]['button']"
                            />
                          </div>
                          <DataTable :value="params[nameField].dataTable.list" :test-id="'DataTableTableSumario'" :loading="params[nameField].dataTable.loading">
                              <Column
                                v-for="column in params[nameField].keyShow"
                                :field="column.title"
                                :key="column.title"
                                :sortable="false"
                                :sortField="column.fieldName"
                                :test-id="'ColumTableSumario'">
                                <template #header>
                                  <div class="flex align-items-center" :test-id="'divColumHeaderTableSumario'">
                                    {{ column.title }}
                                    <Button
                                      v-if="column.sortButton"
                                      :icon="'pi pi-sort-alt'"
                                      class="p-button-rounded p-button-secondary p-button-text"/>
                                  </div>
                                </template>
                                <template #body="slotProps">
                                  <div
                                    :test-id="'fila-nombre' + slotProps.index"
                                    :id=" 'sin-propietario'"
                                    class="flex align-items-center"
                                  >
                                    <div v-if="params[nameField].formatDoc(column.title, slotProps.data) != 'delete'" :class="params[nameField].getClass(column, slotProps.data)" :test-id="'divContenidoDataTable'+column.title">
                                      {{ params[nameField].formatDoc(column.title, slotProps.data) }}
                                    </div>
                                    <div v-else class="flex">
                                      <!-- eliminar Vinculacion -->
                                      <Button
                                        v-if="
                                          ( !viewer || onlyEdit.includes(nameField) )&&
                                          slotProps.data.deleteButtonEnabled === true
                                        "
                                        v-tooltip.left="'Eliminar este Vinculo'"
                                        icon="pi pi-trash"
                                        class="p-button-danger mr-2"
                                        :id="'boton-eliminar' + slotProps.index"
                                        :ref="'boton-eliminar' + slotProps.index"
                                        @click="params[nameField].dataTable.deleteConfirmation($event, slotProps.data)"
                                        :test-id="'ButtonDeleteColum'+column.title"
                                      />
                                      <Button
                                        v-if="
                                          ( !viewer || onlyEdit.includes(nameField) )  &&
                                          slotProps.data.ActuacionID == null &&
                                          slotProps.data.editButtonEnabled === true

                                        "
                                        v-tooltip.left="'Editar este Vinculo'"
                                        icon="pi pi-pencil"
                                        class="p-button-warning mr-2"
                                        :id="'boton-editar' + slotProps.index"
                                        :ref="'boton-editar' + slotProps.index"
                                        @click="goToEditDepositoVinculoActuacion(slotProps.data.vinculoID)"
                                        :test-id="'ButtonDeleteColum'+column.title"
                                      />
                                    </div>
                                  </div>
                                </template>
                            </Column>

                          </DataTable>
                          <div v-if="( !viewer || onlyEdit.includes(nameField) )" class="mr-2 flex flex-row-reverse" style="" :test-id="'divContenedorVinculacionManual'">
                                <VinculacionManual v-if="params[nameField].manualVinculation" @event="(val) => params[nameField].dataTable.vincularObjeto(val,id) "/>
                          </div>
                          <ConfirmPopup></ConfirmPopup>
                        </template>

                      </Card>
                    </div>
                    <!-- Fin table Sumario -->

                    <!-- modo vista del campo -->
                    <div v-if="( viewer && !onlyEdit.includes(nameField) )" class="InputViewer">
                      <!-- viewer -->
                      <!-- si existe un template con el nombre de del campo mas Viewer mostrara eso -->
                      <slot
                        :name="nameField + 'Viewer'"
                        v-bind:data="values"
                        v-if="$scopedSlots[nameField + 'Viewer']"
                      ></slot>
                      <!--
                      <slot
                        :name="nameField"
                        v-bind:data="values"
                        v-else-if="$scopedSlots[nameField]"
                      ></slot> -->
                      <!-- sino muestra el texto -->
                      <!-- <div v-else-if="!$scopedSlots[nameField] && nameField.search('ID') < 0"> -->
                      <div v-else-if="!$scopedSlots[nameField]">
                        {{ formatData(nameField, values) }}
                      </div>
                      <!-- <div v-else>
                        <span>nameField:</span>{{ nameField }}
                        <span> nameField.search('ID'):</span>{{ nameField.search('ID') }}
                      </div> -->
                    </div>
                    <!-- fin modo vista del campo -->

                  </div>
                  <!-- fin de componente principal del campo -->

                  <!--
                    footer del campo
                    *donde se vera informacion de validacion
                  -->
                  <div class="inputFooter">
                    <div v-show="!isValid(nameField)" class="text-red-600 mt-2 text-sm">
                      {{errors[nameField].errMsg}}
                    </div>
                    <div v-show="!hasMsg(nameField)" class="text-red-600 mt-2">
                      {{params[nameField].msg}}
                    </div>
                  </div>
                  <!-- fin de footer del campo -->
                </div>
                <!-- fin campos editables -->

              </div>
              <!-- fin de columna en card -->

            </div>
            <!-- fin de fila en card -->

          </div>
          <!-- fin de card -->

        </div>
        <!-- fin de columna -->

      </div>

      <!-- fin de formulario -->

      <!-- botones de accion -->
      <slot name="beforeActions" v-bind:data="values"></slot>
      <div class="mt-4 flex justify-content-between" v-if="( !viewer || onlyEdit.length > 0 )" id="footer-buttons">
        <!-- izquierda -->
        <div class="actions  flex">
          <slot name="actionLeftA" v-bind:data="values"></slot>
          <Button
            v-if="cancelButton"
            label="Cancelar"
            icon="pi pi-times"
            class="p-button-text p-button-secondary"
            @click="back()"
            test-id="cancelButton"
          />
          <!-- SS-4944 -->
          <!-- <Button
            v-if="backPath"
            label="Volver"
            class="p-button-text p-button-secondary"
            @click="$router.push(backPath)"
          /> -->
          <!-- SS-4944 -->
          <slot name="actionLeftB" v-bind:data="values"></slot>
        </div>

        <!-- centro -->
        <div >
          <ProgressSpinner
            v-if="!enableForm || saving"
            style="width: 50px; height: 50px"
            strokeWidth="5"
            fill="#EEEEEE"
            animationDuration="1.5s"
          />
        </div>

        <!-- derecha -->

        <div v-if="enableForm && !saving" class="actions">
          <Button
            v-if="showSaveButton"
            label="Guardar"
            icon="pi pi-check"
            @click="save()"
            test-id="edit-boton-guardar"
          />
        </div>
      </div>
      <div v-else>
          <!-- SS-4944 -->
          <!-- <Button
            v-if="backPath"
            label="Volver"
            class=" p-button-primary"
            @click="$router.push(backPath)"
          /> -->
          <!-- SS-4944 -->
      </div>
      <!-- fin de botones accion -->
    </div>

    <!-- dialogo para asociacion rapida a otros formularios -->
    <Dialog
      :visible.sync="displayBasic"
      :style="{ width: '75vw' }"
      maximizable
      contentStyle="padding:0px"
      v-if="( !viewer || onlyEdit.length > 0 )"
    >
      <div v-if="displayBasic">
        <dinamic-selector
          @selectItem="selectItem"
          :field="modalField"
          :listComponent="data[modalField]?.listComponent"
        ></dinamic-selector>
      </div>
    </Dialog>
    <!-- fin de dialogo para asociacion rapida a otros formularios -->

    <!-- ConfirmPopup -->
    <ConfirmPopup group="demo" ref="ConfirmPopup" style="z-index: 3" />
  </div>
</template>

<script>
import tformMixin from "./tformMixin.js";
import VinculacionManual from '@/components/VinculacionManual'

//MIXIN

export default {
  mixins: [
    tformMixin
  ],
  components: {
        VinculacionManual,
  },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    onlyEdit: {
      type: Array,
      default: () => [],
    },
  },
  async created() {
    const { id } = this.$route.params;
    if (id) this.id = id;
    this.enableForm = false;
    await this.prepareOldVersion();
    await this.emptyValuesAndErrors();
    await this.setDesign();
    if(typeof this.url === 'string')
      this.getDoc();
    if(typeof this.url === 'object')
      this.multipleGetDoc();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inputComponent > * {
  width: 100%;
}
.invisible-div{
  position: absolute;
  z-index: 2;
  height: 60px;
  width: 40px;
  margin-left: 3px;
  margin-top: -55px;
  cursor: pointer;
}
</style>

