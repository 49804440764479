export const expedienteOficioConfig = [
    {
        path: "/actuaciones/new/expediente-oficio/initial",
        pathName: "expediente-oficio",    
        titulo: "EXPEDIENTE OFICIO",
        icon: "pi-desktop",
        roles: [
          "User Tester",
          "Super User",
          "Jefe de Dependencia",
          "Oficial Sumariante",
          "Jefe de Sumario",
        ],
        type: "link",
        grupo: "EXPEDIENTES",
        props: {
          formType: "contravencional-oficio",
          actuacionType: "expediente-oficio",     
        },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/expediente-oficio/initial/denunciante",
        pathName: "expediente-oficio",
        titulo: "AFECTADOS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/expediente-oficio/initial/momento",
        pathName: "expediente-oficio",
        titulo: "FECHA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/expediente-oficio/initial/acusado",
        pathName: "expediente-oficio",
        titulo: "VINCULADOS",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/expediente-oficio/initial/efectos",
        pathName: "expediente-oficio",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]