<template>
  <div test-id="formulario_principal_actuacion" class="page formulario_principal_actuacion px-7">
    <div
      class="grid justify-content-between align-items-center vertical-container menu flex-row-reverse"
      test-id="pageTitle"
    >
      <div class="actions">
        <Button
          label="Continuar"
          test-id="ButtonContinue"
          v-if="!viewData.botonContinuar"
          icon="pi pi-next"
          @click="$router.push({ path: viewData.saveButton.path })"
        />
      </div>
    </div>

    <div class="grid col-12 md:col-12 sm:col-12 shadow-3 px-3" style="background-color: #ffffff;">
    <!-- Header -->
      <div class="col-12 flex flex-column" >
        <div class="w-full flex justify-content-between align-items-center  border-bottom-1 border-200">
          <h2 class="text-gray-700 font-medium">{{ viewData.hasFormDatosLegales && sumario.actuationEditModeActive ? 'Edición de Actuación' : 'Nueva Actuación'}}</h2>
          <div class="hour_date__section">
            <div class="flex">
              <p class="text__hour" test-id="textDenunciaFecha">
                Denuncia en fecha:
              </p>
                <CustomCalendarV2
                  v-model="calendarDateSelected"
                  :allowPastDates="viewData.calendarDenuncia"
                  :maxPastDays="viewData.calendarDenuncia ? 4 : 0"
                  :disabled="!viewData.calendarDenuncia"
                  @input="updateDenunciaFecha"
                />
            </div>
          </div>
        </div>
          <!-- FECHA DE DENUNCIA -->
        <div class="flex justify-content-end mt-2">
         
          
        </div>
      </div>

      <!-- Primera columna -->
      <div class="col">
        <TabView>
          <TabPanel header="Datos Requeridos" key="1">
            <div class="formsPage" >
            <!-- AFECTADOS -->
            <div class="secundary card double">
              <Peoples
                :datos="sumario.denunciante.list"
                @navigateToDenunciante="handleNavigation"
                @updateValue="updateDenunciante"
                :formType="this.formType"
                :actuacionType="this.actuacionType"
                :errors="resumen.errorValidationErr"
                :warning="resumen.errorValidationWarning"
                :showValidation="showValidation"
                @disableAfectation="disabledAfectationCard()"
                @deleteConfirmationPeoples="(val, val2, val3) => deleteConfirmation(val, val2, val3)"
                :deleteConfirmationInProgress="deleteConfirmationInProgress"
                @moveToDeposition="(val) => pasteInDeposition(val)"
                :modalVisble="modalVisible()"
                :viewData="viewData"
                :disabled="personsAndEffectsCardDisabled"
              />
            </div>
            <!-- VINCULADOS -->
            <div class="seundary card double">
              <ResumenCard
                :title="viewData.cardAcusado.title"
                :editPath="'/actuaciones/new/' + this.actuacionType + '/initial/acusado'"
                :warning="resumen.errorValidationWarning"
                :errors="resumen.errorValidationErr"
                :showValidation="showValidation"
                :formType="formType"
                test-id="cardAcusados"
                :viewData="viewData"
                :detectedError="vinculatedError"
                v-if="viewData.cardAcusado.show"
                @disableAfectation="disabledAfectationCard()"
                :disabled="personsAndEffectsCardDisabled"
              >
                <div
                  v-for="(item, i) in acusadosTodosResumen"
                  v-bind:key="i"
                  class="grid justify-content-between align-items-center"
                >
                  <Button
                    icon="pi pi-clone"
                    style="color: rgba(104, 159, 56, 1);"
                    ref="cardFocus"
                    class="p-button-rounded p-button-info p-button-text"
                    @click="pasteInDeposition(false, item.arrId, 'contravener')"
                  />
                  <!-- Nombre, dni, tag y domicilio -->
                  <div class="col-10 flex-row" >
                    <div class="flex justify-content-start align-items-center gap-3">
                      <p class="title">
                        <span class="text-black-alpha-80">
                          {{ nameViculated(item) }}
                        </span>
                      </p>
                      <p>
                        DNI: {{ item.doc.documento }}
                      </p>
                      <Tag
                        :severity="colorTag('acusado', item)"
                        :value="item?.filiacionDesconocida ? 'Acusado' : item?.doc?.condicion?.nombre"
                        rounded
                        test-id="tagAcusado"
                      />
                    </div>
                    <p class="address">
                      {{ item.doc.domicilioSIPE || item.doc.domicilioSIPE }}
                    </p>
                  </div>
                  <!-- actions enviar y borrar -->
                  <div class="col-1" >
                    <MenuOptions
                      :item="item"
                      :deleteItem="(event) => ManageActionsDenunciaForm('delete', item, viewData.formDenunciaDeleteButton.accusedText, event, false)"
                      :editItem="() => ManageActionsDenunciaForm('edit', item,'acusado', null, false)"
                    />
                  </div>
                </div>
                <div>
                  <div
                    v-if="viewData.cardAcusadosWarnings && showValidation == true"
                    class="text-right"
                    :class="{
                      warning:
                        (!formType.startsWith('ufi') ||
                          formType === 'ufi-siniestro-vial' ||
                          formType !== 'preliminar-tentativa-suicidio') &&
                        !vinculatedError,
                      alert:
                        (formType.startsWith('ufi') && formType !== 'ufi-siniestro-vial') ||
                        formType === 'preliminar-fuga-menor' ||
                        formType == 'preliminar-tentativa-suicidio' ||
                        vinculatedError,
                    }"
                  >
                    {{
                      getValidationMessage(
                        !formType.startsWith("ufi") ? "Contraventor/a" : "Aprehendido/a"
                      )
                    }}
                  </div>
                  <div v-else-if="acusadosTodosResumen.length < 1" test-id="cardContraventorSinDatos" class="flex justify-content-end">
                    <span test-id="cardAcusadosSinDatos" class="text-gray-500"> Sin Registros</span>
                  </div>
                </div>
              </ResumenCard>
            </div>

            <!-- FORM LUGAR MOMENTO -->
            <div class="secundary card double" >
              <div @click="setExample">
                <FormLugarMomento
                  :editPath="'/actuaciones/new/' + this.actuacionType + '/initial/momento'"
                  :errors="viewData.formLugarMomentoError ? resumen.errorValidationErr : 'vacio'"
                  @moveToDeposition="(val) => pasteInDeposition(val)"
                  @deleteConfirmationMomento="(val, val2) => deleteConfirmation(val, val2)"
                  :deleteConfirmationInProgress="deleteConfirmationInProgress"
                  :modalVisble="modalVisible()"
                  ref="Moment"
                  card="true"
                  modelo="sumario"
                  :showValidation="showValidation"
                  :formType="formType"
                  :viewData="viewData"
                />
              </div>
            </div>

            <!-- OBJETOS SECUESTRADOS O DENUNCIADOS -->
            <div class="secundary card double">
              <ResumenCard
                :title="viewData.cardEfectos.title"
                :editPath="'/actuaciones/new/' + actuacionType + '/initial/efectos'"
                :warning="resumen.errorValidationWarning"
                :errors="resumen.errorValidationErr"
                :formType="formType"
                :showValidation="showValidation"
                test-id="cardEfectosSecuestrados"
                :viewData="viewData"
                :showPlusButton="editEffects"
                @disableAfectation="disabledAfectationCard()"
                :disabled="personsAndEffectsCardDisabled"
              >
                <div
                  v-for="(item, i) in robadosTodosResumen"
                  v-bind:key="i"
                  class="grid justify-content-between align-items-center"
                >
                  <Button
                    icon="pi pi-clone"
                    style="color: rgba(104, 159, 56, 1);"
                    ref="cardFocus"
                    class="p-button-rounded p-button-info p-button-text"
                    @click="pasteInDeposition(false, item.arrId, 'object-reported-or-hijacked')"
                  />
                  <!-- Nombre y tag  -->
                  <div class="col-10 flex flex-row justify-content-start align-items-center gap-3">
                    <p class="title">
                      <span class="text-black-alpha-80">
                        {{ item.name }}
                      </span>
                    </p>
                    <Tag
                      :severity="colorTag('efectos', item)"
                      :value="item?.tipoEfecto?.nombre"
                      rounded
                      test-id="tagAcusado"
                    />
                  </div>
                  <!-- actions enviar y borrar -->
                  <div class="col-1" >
                    <MenuOptions
                      :item="item"
                      :deleteItem="(event) => ManageActionsDenunciaForm('delete',item,'efecto secuestrado',event,false)"
                      :editItem="() => ManageActionsDenunciaForm('edit', item,'efectos', null, false) "
                    />
                  </div>
                </div>
                <div>
                  <div
                    v-if="viewData.cardObjetosWarnings && showValidation == true"
                    :class="{ warning: showValidation, 'text-right': true }"
                  >
                    {{ getValidationMessage("Efectos Denunciados") }}
                  </div>
                  <div
                    v-else-if="robadosTodosResumen.length < 1"
                    test-id="cardEfectosSecuestradosSinDatos"
                    class="flex justify-content-end"
                  >
                  <span class="text-gray-500">
                    Sin Registros
                  </span>
                  </div>
                </div>
              </ResumenCard>
            </div>

            <!-- AFECTACIÓN PATRIMONIAL -->
            <div class="secundary card double">
              <ResumenCard v-if="this.viewData.cardPatrimonialAffectation.show && denunciante.list.length > 1 && acusados.list.length > 1"
                :title="viewData.cardPatrimonialAffectation.title"
                :editPath="'/actuaciones/new/' + actuacionType + '/initial/patrimonialAffectation'"
                :warning="resumen.errorValidationWarning"
                :errors="resumen.errorValidationErr"
                :formType="formType"
                :showValidation="showValidation"
                test-id="cardPatrimonialAffectation"
                :viewData="viewData"
                :showPlusButton="editEffects"
                @disablePersonsAndEffect="disbledPersonsAndEffectCard()"
                :disabled="afectationCardDisabled"
              >
                <div
                  v-for="(item, i) in afectacionTodosResumen"
                  v-bind:key="i"
                  class="grid justify-content-between align-items-center"
                >
                  <Button
                    icon="pi pi-clone"
                    style="color: rgba(104, 159, 56, 1);"
                    ref="cardFocus"
                    class="p-button-rounded p-button-info p-button-text"
                    @click="pasteInDeposition(false, item.arrId, 'patrimonial-afectation')"
                  />
                  <div class="col-10 flex-row justify-content-start align-items-center gap-3" >
                    <div class="flex justify-content-start align-items-center gap-3">
                      <p class="title">
                        <span class="text-black-alpha-80">
                          $ {{ item.monto }}.- ({{ item.tipoMoneda.nombre }})
                        </span>
                      </p>
                      <Tag
                        :severity="colorTagPatrimonialAffectation(item)"
                        :value="getFormattedTransactionType(item?.tipo)"
                        rounded
                        test-id="tagAcusado"
                      />
                    </div>
                    <p class="cbu-estate">
                      {{ returnCbuOrEstate(item) }}
                    </p>
                  </div>
                  <div class="col-1">
                    <MenuOptions
                        :item="item"
                        :deleteItem="(event) => ManageActionsDenunciaForm('delete', item, 'afectación patrimonial', event, true)"
                        :editItem="() => ManageActionsDenunciaForm('edit', item, 'patrimonialAffectation', null, true)"
                    />
                  </div>
                </div>
                <div>
                  <div
                    v-if="viewData.cardPatrimonialAffectation && showValidation == true"
                    :class="{ warning: showValidation, 'text-right': true }"
                  >
                    {{ getValidationMessage("Efectos Denunciados") }}
                  </div>
                  <div
                    v-else-if="this.afectacionTodosResumen.length < 1"
                    test-id="cardEfectosSecuestradosSinDatos"
                    class="flex justify-content-end"
                  >
                    <span class="text-gray-500">
                      Sin Registros
                    </span>
                  </div>
                    <i class="line"></i>
                  <div 
                  class="totalsContainer" 
                  >
                  <Button
                    icon="pi pi-clone"
                    style="color: rgba(104, 159, 56, 1);"
                    ref="cardFocus"
                    class="p-button-rounded p-button-info p-button-text"
                    @click="handleClick"
                  />
                    <ul>
                      <div v-for="(total, currency) in totalsByCurrency" :key="currency">
                        <li class="text-black-alpha-80" style="font-weight: 600;">Total en {{ currency }}: $ {{ total }}</li>
                      </div>
                    </ul>
                  </div>
                </div>
              </ResumenCard>
            </div>

            <!-- PERSONAL INTERVINIENTE -->
            <div class="secundary card double">
              <FormPersonalInterviniente
                  card="true"
                  modelo="sumario"
                  :formType="this.formType"
                  :errors="resumen.errorValidationErr"
                  :showValidation="showValidation"
                  @moveToDeposition="(val) => pasteInDeposition(val)"
                  :modalVisble="modalVisible()"
                  :deleteConfirmationInProgress="deleteConfirmationInProgress"
                  @deleteConfirmationPersonaInterviniente="
                    (val, val2, val3) => deleteConfirmation(val, val2, val3)
                  "
                  test-id="cardPersonalInterviniente"
                  v-if="viewData.formPersonalInterviniente"
                />
            </div>

            <!-- Tiene que haber un solo CONFIRMPOPUP para todos los componentes. Si no se rompe -->
            <ConfirmPopup group="demo" ref="ConfirmPopup" style="z-index: 999" />

          </div>
          </TabPanel>
          <TabPanel header="Datos Legales" key="2" :disabled="viewData.hasFormDatosLegales && sumario.actuationEditModeActive">
            <ContravencionalFormDatos/>
          </TabPanel>
        </TabView>
      </div>
      
      <!-- Segunda columna -->
      <div class="preview col">
        <div class="declaracion" test-id="declaracion">
          <div class="labeled">
            <div v-if="viewData.hasFormDatosLegales && !sumario.actuationEditModeActive">
              <FormDatosLegales
                @validate="validate()"
                ref="FormDatosLegales"
                :formType="formType"
                test-id="FormDatosLegales"
                :viewData="viewData"
                :key="$route.path"
              />
              <!-- v-if="formType === 'denuncia' || formType === 'oficio'" -->

              <Button
                v-if="viewData.hasButtonNext"
                label="Continuar"
                icon="pi pi-next"
                @click="
                  $router.push({
                    path: '/sumario/denuncia/formulario/contravencional/oficio/expediente',
                  })
                "
                style="padding: 2px 10px"
              />
            </div>
            <div v-else class="flex flex-row-reverse">
              <Button
                label="Terminar Edición"
                icon="pi pi-save"
                class="p-button-primary ml-3"
                @click="finishEdition()"
                v-if="!editMode.spinnerSaveButton"
                style="padding: 2px 10px"
                :disabled="editMode.disableFinishButton"
              />
              <ProgressSpinner
                v-else
                style="width: 50px; height: 50px; padding: 2px 10px"
                strokeWidth="5"
                fill="#EEEEEE"
                animationDuration="1.5s"
                class="ml-3"
              />
              <Button
                label="Cancelar"
                icon="pi pi-times"
                class="p-button-secondary"
                @click="cancelEdition()"
                v-if="!editMode.spinnerSaveButton"
                style="padding: 7px 10px"
              />
            </div>

            <div
              class="denuncia-print-placeholder"
              v-if="!isDiligenciaView && sumario.actuationEditModeActive"
            >
              <div class="loading-container" v-if="isLoadingDiligencia">
                <ProgressSpinner
                  style="width: 50px; height: 50px; padding: 2px 10px"
                  strokeWidth="5"
                  fill="#EEEEEE"
                  animationDuration="1.5s"
                  class="loading-spinner"
                />
                <div class="loading-text">Cargando diligencia...</div>
              </div>
              <div v-else>
                <div class="loading-text">No tiene ninguna diligencia creada</div>
              </div>
            </div>

            <div class="denuncia-print" v-if="sumario.actuationEditModeActive">
              <DenunciaPrint
                ref="DenunciaPrintRef"
                :DiligenciaID="sumario.diligencia[0]?.DiligenciaID"
                id="denunciaPrint"
                @isDiligenciaLoaded="handleDiligenciaLoaded"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
    <Button style="opacity: 0; cursor: auto" test-id="buttonGoStore" @click="goToStore" />
    <SuccessAndFail
      :msjEnable.sync="msjEnable"
      :mensajeDialog="mensaje"
      :buttomAceptar="buttomAceptar"
      :icon.sync="iconModal"
    />
    <ModalOfflineError ref="ModalOfflineErrorRef"/>

 </div>
</template>

<script>
// import { RETURN } from '@/components/datatable/bodycell.common';
import ResumenCard from "../../components/ResumenCard.vue";
import FormLugarMomento from "../../components/forms/FormLugarMomento.vue";
import FormPersonalInterviniente from "../../components/forms/FormPersonalInterviniente.vue";
import Peoples from "../../components/Peoples.vue";
import FormDatosLegales from "../../components/forms/FormDatosLegales.vue";
import { mapState, mapGetters, mapActions } from "vuex";
// import DenunciaFormDatos from './DenunciaFormDatos.vue'
import Icon from "../../components/Icon.vue";
import ConfirmPopupMixin from "../../components/TForm/mix/ConfirmPopupMixin.js";
import DenunciaPrint from "./DenunciaPrint.vue";
import tanstackFunctions from "../../mixins/tanstackFunctions.js";
import ModalOfflineError from "../../components/ModalOfflineError.vue";
import { verifyInternetConnection } from "../../utils/verifyInternetConnection";

import {
  colorTagPatrimonialAffectation,
  returnCbuOrEstate,
  getFormattedTransactionType,
} from "../../utils/showAfectation";
import ContravencionalFormDatos from "./ContravencionalFormDatos.vue";
import MenuOptions from "@/components/MenuOptions/MenuOptions.vue";
import CustomCalendarV2 from "@/components/Custom-calendarV2/CustomCalendarV2.vue";

export default {
  name: "SumarioDenunciaForm",
  props: {
    formType: String, //Defines the type of form
    errors: Array,
  },
  components: {
    // DenunciaFormDatos,
    DenunciaPrint,
    Icon,
    FormPersonalInterviniente,
    FormLugarMomento,
    ResumenCard,
    Peoples,
    FormDatosLegales,
    ModalOfflineError,
    ContravencionalFormDatos,
    MenuOptions,
    CustomCalendarV2
  },
  mixins: [ConfirmPopupMixin, tanstackFunctions],
  data() {
    return {
      // afectationCardDisabled:false,
      effectCardDisabled:false,
      personCardDisabled:false,
      setExampleT: 0,
      sortIcon: "notShowingCalendar",
      calendarDateSelected: null,
      showCalendar: false,
      dni: null,
      loading: false,
      description: null,
      cont: 0,
      //denuncia: "",
      assistant: "No",
      options: ["No", "Si"],
      forms: [
        {
          name: "Sumario Prevencional por denuncia",
          link: "/actuaciones/sumario-denuncia",
        },
      ],
      showValidation: false,
      showButton: "",
      isDiligenciaView: false,
      isLoadingDiligencia: true,
      actuacionType: "",
      vinculatedError: false,
      enCarga: false,
      //---------Datos para el Modal SuccessAndFail------
      buttomAceptar:{buttom:true,action:false},
      iconModal:{},
      mensaje:'',
      msjEnable:false,
      // deleteConfirmationInProgress: false,
      // currentTarget: null,
    };
  },
  mounted() {
    this.calendarDateSelected = this.$store.getters["sumario/dateDenuncia"];
    if(!this.sumario.actuationEditModeActive){
        this.setPersonsAndEffectDisabled(false);
        this.setAfectationCardDisabled(false)
      }
    this.initializingStoreData();
    if (this.sumario.diligencia.length === 0 && this.sumario.actuationEditModeActive) {
      this.$toast.open({
        message: "Esta actuación no tiene diligencias para mostrar.",
        position: "top-right",
        type: "warning",
        duration: 5000,
      });
      this.isLoadingDiligencia = false;
    }
    if (this.sumario.actuationEditModeActive) {
      this.isDiligenciaView = false;
      setTimeout(() => {
        this.$refs.DenunciaPrintRef.changeTheIframeFullscreen(false);
      }, 100);
    }
  },

  watch: {
    showCalendar(oldValue, newValue) {
      if (oldValue === false) {
        this.modifyDate(this.calendarDateSelected);
      }
    },
    currentTarget(newValue, oldValue) {
      //this prevents a error ocazioned when touch other delete button and ConfirmPopup is open
      if (oldValue !== null) {
        this.$refs.ConfirmPopup.visible = false;
        this.currentTarget = null;
      }
    },
    async formType(newValue, oldValue) {
      await this.$store.dispatch("sumarioViews/setActuacionType", newValue);
    },
  },
  beforeRouteLeave(to, from, next) {
    //hidden the ConfirmPopup if is open before change the route
    if (this.$refs.ConfirmPopup !== undefined) {
      this.$refs.ConfirmPopup.visible = false;
    }
    //updating the formType from views.js
    if (to?.matched[0]?.props?.default?.formType !== undefined) {
      this.$store.dispatch("sumarioViews/setActuacionType", to.matched[0].props.default.formType);
    }
    setTimeout(() => {
      next();
    }, 100);
  },
  computed: {
    ...mapState({
      sumario: (state) => state.sumario,
      denunciante: (state) => state.sumario.denunciante,
      acusados: (state) => state.sumario.acusados,
      robados: (state) => state.sumario.robados,
      afectacionPatrimonial: (state) => state.sumario.afectacionPatrimonial,
      denuncia: (state) => state.sumario.denunciaText,
      views: (state) => state.sumarioViews,
      editMode: (state) => state.editMode,
      // afectationCardDisabled: (state) => state.afectationCardDisabled,
    }),
    ...mapState('siis', {
    afectationCardDisabled: state => state.afectationCardDisabled,
    personsAndEffectsCardDisabled: state=> state.personsAndEffectsCardDisabled
  }),
    ...mapGetters({
      resumen: "sumario/dataResumen",
      viewData: "sumarioViews/getViewData",
    }),
    changeIcon() {
      return this.sortIcon === "showingCalendar" ? "pi pi-check" : "pi pi-calendar";
    },
    today() {
      return new Date();
    },
    yesterday() {
      return new Date(new Date().setDate(new Date().getDate() - 4));
    },
    textareaContravencional: {
      get() {
        return this.$refs.FormDatosLegales.$refs.textareaContravencional.$el;
      },
    },
    robadosTodosResumen: {
      get() {
        return this.$store.getters["sumario/robados/robadosTodosResumen"];
      },
    },
    afectacionTodosResumen: {
      get() {
        return this.$store.getters["sumario/afectacionPatrimonial/afectacionTodosResumen"];
      },
    },

    dateDenuncia: {
      get() {
        return this.$store.getters["sumario/dateDenuncia"];
      },
    },
    denunciantes: {
      get() {
        return this.$store.getters["sumario/denunciante/denuncianteTodosResumen"];
      },
    },
    acusadosTodosResumen: {
      get() {
        return this.$store.getters["sumario/acusados/acusadosTodosResumen"];
      },
    },
    denunciaText: {
      get() {
        return this.denuncia;
      },
      set(val) {
        this.setDenuncia(val);
      },
    },
    editEffects: {
      get() {
        return true;
      },
    },
    totalsByCurrency() {
      const totals = {};
      this.afectacionTodosResumen.forEach(item => {
        const amount = parseFloat(item.monto);
        const currency = item.tipoMoneda.nombre;
        if (!totals[currency]) {
          totals[currency] = 0;
        }
        // sumamos el monto de cada tipo de moneda
        totals[currency] += amount;
        
      });
      return totals;
    },
    actOfStart:{
      get (){
        return this[this.viewData.formDatosLegales.actOfStart];
      },
    },
    isEditMode() {
      return !!this.editMode.formType
    }
  },
  watch: {
    async $route(newRoute) {
      // Para el caso de que se cambie de Ruta usando el Menu, no se vuelve a invocar a la funcion created y por ende no se carga nuevamente el ...
      //...Orden publico, por ende, cuando identificamos ese cambio de ruta, volvemos a constatar si es nescesario volver a agregarlo
      if (newRoute.path.includes("oficio")) {
        if (this.formType === "contravencional-oficio" && this.denunciante.list.length == 1) {
          await this.$store.dispatch("sumario/setDenuncianteDefault");
        }
      }
    },
  },
  async created() {
    await this.$store.dispatch("sumarioViews/setActuacionType", this.formType);
    // en caso que el sumario sea contravencional por oficio cargamos por defecto el Damnificado Orden Publico
    if (this.formType === "contravencional-oficio" && this.denunciante.list.length == 1) {
      this.$store.dispatch("sumario/setDenuncianteDefault");
    }
    this.$store.dispatch("sumario/setformType", this.formType);
    if (this.sumario.saveStatus !== "new") {
      this.$store.dispatch("sumario/resetState");
    }
    this.actuacionType = this.$router.app.$route.params.pathName;

    this.$store.dispatch("siis/setSubCategoriasDeposito");
  },
  methods: {
    handleClick() {
      const concatenatedText = Object.entries(this.totalsByCurrency)
        .map(([currency, total]) => `El total es -$ ${total} - (${currency}) -`)
        .join('\n');
      this.pasteInDeposition(concatenatedText);
    },
    ...mapActions("siis",["setAfectationCardDisabled","setPersonsAndEffectDisabled"]),
    nameViculated(item) {
      if (item.filiacionDesconocida) {
        return "Persona de filiación desconocida";
      }
      const { apellido, nombre } = item?.doc || {}; // Desestructuración con seguridad
      if (apellido && nombre) {
        return `${apellido}, ${nombre}`;
      }
      if (apellido) {
        return apellido;
      }
      if (nombre) {
        return nombre;
      }
      return "Faltan datos";
    },

    colorTagPatrimonialAffectation,
    returnCbuOrEstate,
    getFormattedTransactionType,
    handleNavigation(data) {
      // Maneja la navegación aquí en el componente padre
      this.$router.push({
        path: `/actuaciones/new/${this.actuacionType}/initial/denunciante`,
        query: data,
      });
    },
    ManageActionsDenunciaForm(action="",item,text,event,isAfectation){
      if (action==="edit"){
        if(isAfectation){
          this.disbledPersonsAndEffectCard();
        }else{
          this.disabledAfectationCard();
        }
        this.$router.push({
          path: '/actuaciones/new/' + this.actuacionType + '/initial/'+text,
          query: { id: item.arrId },
        })

      }else if(action === "delete"){
        if(isAfectation){
          this.disbledPersonsAndEffectCard();
        }else{
          this.disabledAfectationCard();
        }
        this.deleteConfirmation(event,text,item.arrId)

      }
    },
    redirect(op){
      switch(op){
        case "expediente":
            this.$router.push({path: `/sumario/denuncia/detalle/toUpdate${this.idsumario}`})
        break
        case "preview":
          //guardamos el texto plano del acta de inicio
          this.$store.dispatch('sumario/TextActaInicio', this.actOfStart);
          this.$router.push('/actuaciones/' + this.actuacionType +'/preview/actaInicioPreview?caso=preview')
        break     
      }
    },
    disabledAfectationCard(){
      if(this.sumario.actuationEditModeActive){
        this.setAfectationCardDisabled(true);
      }

    },
    disbledPersonsAndEffectCard(){
      if(this.sumario.actuationEditModeActive){
        this.setPersonsAndEffectDisabled(true);
      }
    },
    handleDiligenciaLoaded(isLoaded) {
      if (isLoaded) {
        const iframe = document.getElementById("denunciaPrint").querySelector("iframe");
        iframe.addEventListener("load", () => {
          this.$nextTick(() => {
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
            const contentHeight = Math.max(
              iframeDoc.body.scrollHeight,
              iframeDoc.documentElement.scrollHeight,
              iframeDoc.body.offsetHeight,
              iframeDoc.documentElement.offsetHeight,
              iframeDoc.body.clientHeight,
              iframeDoc.documentElement.clientHeight
            );
            const heightThreshold = 10;
            if (contentHeight > heightThreshold) {
              this.isDiligenciaView = true; // Cambia el estado para ocultar el loader
            }
          });
        });
      }
    },
    //La siguiente funcion se encarga de controlar el color de las Tags para Acusados-Detenidos-Denunciantes segun sea el caso
    colorTag(type, value) {
      if (type === "acusado") {
        if (value?.filiacionDesconocida) return "warning";

        const condition = value?.doc?.condicion?.nombre;
        if (["Acusado"].includes(condition)) return "warning";
        if (["Detenido", "Aprehendido"].includes(condition)) return "danger";
      } else {
        const effectType = value?.tipoEfecto?.nombre;
        if (["Secuestrado"].includes(effectType)) return "warning";
        if (["Denunciado"].includes(effectType)) return "danger";
        if (["Recuperado"].includes(effectType)) return "success";
      }

      return "info";
    },

    firsAndLastNameSorted(dato) {
      let NombreYapellido = "";
      NombreYapellido =
        dato.doc.apellido != undefined && dato.doc.apellido != ""
          ? dato.doc.apellido
          : NombreYapellido;
      NombreYapellido =
        dato.doc.nombre != undefined && dato.doc.nombre != "" && NombreYapellido != ""
          ? NombreYapellido + ", " + dato.doc.nombre
          : NombreYapellido;
      NombreYapellido =
        dato.doc.nombre != undefined && dato.doc.nombre != "" && NombreYapellido == ""
          ? dato.doc.nombre
          : NombreYapellido;

      NombreYapellido =
        NombreYapellido == "" && dato.doc.apodo != undefined
          ? dato.doc.apodo + " (Apodo)"
          : NombreYapellido;
      NombreYapellido = NombreYapellido == "" ? "Filiación Desconocida" : NombreYapellido;

      return NombreYapellido;
    },
    setExample() {
      this.setExampleT++;
      if (this.setExampleT == 10) this.$store.dispatch("sumario/setExample");
    },
    validate() {
      const maxErrorsByType = {
        // 'ufi-estafas-y-delitos-informaticos': 9,
        ufi: 8,
        preliminar: 6,
        contravencional: 5,
        denuncia: 6,
        oficio: 6,
      };
      // Obtener el máximo de errores permitido según el tipo de formulario
      const formTypeKey = Object.keys(maxErrorsByType).find((key) =>
      this.formType.startsWith(key)
    );
    const maxErrors = maxErrorsByType[formTypeKey] || 5; // Por defecto 5 para otros casos
      // this.showValidation = true;

      if (this.resumen.errorValidationErr.length <= maxErrors) {
        this.$router.push({ name: "actuacion-final" });
      } else {
        this.showValidation = true;
      }
    },

    validateInEditMode() {
      if (
        ((this.formType !== "denuncia" || this.formType !== "oficio") &&
          this.resumen.errorValidationErr.length <= 0) ||
        ((this.formType === "denuncia" || this.formType === "oficio") &&
          this.resumen.errorValidationErr.length <= 1)
      ) {
        return true;
      } else {
        this.showValidation = true;
        return false;
      }
    },
    getValidationMessage(called) {
      switch (called) {
        case "Contraventor/a":
          return this.validateContraventor();
        case "Aprehendido/a":
          return this.validateAprehendido();
      }
      if (called === "Efectos Denunciados" || called === "Efectos") {
        if (
          this.resumen.errorValidationWarning.includes("No especificó ningún efecto denunciado")
        ) {
          return "No especificó ningún efecto denunciado";
        }
        return;
      }
      if (this.resumen.errorValidationErr.includes("Falta email del detenido y/o aprehendido")) {
        this.vinculatedError = true;
        return "Falta email del detenido y/o aprehendido";
      }
      if (this.resumen.errorValidationErr.includes("Al menos 1 vinculado tiene un email con formato incorrecto")) {
        this.vinculatedError = true;
        return "Al menos 1 vinculado tiene un email con formato incorrecto";
      }
      if (called == "Contraventor/a") {
        if (this.resumen.errorValidationWarning.includes("No especificó un acusado")) {
          if (this.formType === "denuncia" || this.formType === "oficio") {
            return "No especificó un acusado";
          } else {
            return "No especificó un Contraventor";
          }
        }
        if (this.resumen.errorValidationErr.includes("Falta especificar un causante")) {
          return "Falta especificar un causante";
        }
        if (
          this.resumen.errorValidationErr.includes(
            "Debe especificar al menos un Autolesionado"
          )
        ) {
          return "Debe especificar al menos un Autolesionado";
        }
      if (this.checkAndSetVinculatedError("Falta email del detenido y/o aprehendido")) {
        return "Falta email del detenido y/o aprehendido";
      }
      if (
        this.resumen.errorValidationErr.includes(
          "Al menos 1 vinculado tiene un email con formato incorrecto"
        )
      ) {
        this.vinculatedError = true;
        return "Al menos 1 vinculado tiene un email con formato incorrecto";
      }
      if (this.resumen.errorValidationErr.includes("Falta email del detenido y/o aprehendido")) {
        this.vinculatedError = true;
        return "Falta email del detenido y/o aprehendido";
      }
      if (
        this.resumen.errorValidationErr.includes(
          "Al menos 1 vinculado tiene un email con formato incorrecto"
        )
      ) {
        this.vinculatedError = true;
        return "Al menos 1 vinculado tiene un email con formato incorrecto";
      }
      if (called == "Contraventor/a") {
        if (this.resumen.errorValidationWarning.includes("No especificó un acusado")) {
          if (this.formType === "denuncia" || this.formType === "oficio") {
            return "No especificó un acusado";
          } else {
            return "No especificó un Contraventor";
          }
        }
        if (this.resumen.errorValidationErr.includes("Falta especificar un causante")) {
          return "Falta especificar un causante";
        }
        if (
          this.resumen.errorValidationErr.includes("Debe especificar al menos un Autolesionado")
        ) {
          return "Debe especificar al menos un Autolesionado";
        }
      }
      if (
        this.checkAndSetVinculatedError(
          "Al menos 1 vinculado tiene un email con formato incorrecto"
        )
      ) {
        return "Al menos 1 vinculado tiene un email con formato incorrecto";
      }

    }
  },
    checkAndSetVinculatedError(errorMessage) {
      if (this.resumen.errorValidationErr.includes(errorMessage)) {
        this.vinculatedError = true;
        return true;
      }
      return false;
    },
    validateContraventor() {
      if (this.resumen.errorValidationWarning.includes("No especificó un acusado")) {
        return this.formType === "denuncia" || this.formType === "oficio"
          ? "No especificó un acusado"
          : "No especificó un Contraventor";
      }
      if (this.resumen.errorValidationErr.includes("Falta especificar un causante")) {
        return "Falta especificar un causante";
      }
      if (this.resumen.errorValidationErr.includes("Debe especificar al menos un Autolesionado")) {
        return "Debe especificar al menos un Autolesionado";
      }
    },
    validateAprehendido() {
      if (this.formType !== "ufi-establecer-paradero") {
        if (
          this.resumen.errorValidationErr.includes("Falta especificar un acusado") ||
          (this.resumen.errorValidationWarning.includes("No especificó un acusado") &&
            this.formType === "ufi-siniestro-vial")
        ) {
          return "Falta especificar un Aprehendido y/o Acusado";
        }
      }
      if (this.formType === "ufi-establecer-paradero") {
        if (this.resumen.errorValidationErr.includes("Falta especificar un Extraviado")) {
          return "Falta especificar un Extraviado";
        }
      }
      return;
    },
    setDenuncia(val) {
      this.$store.dispatch("sumario/setValue", { field: "denunciaText", value: val });
    },
    async updateDenunciante(value) {
      let arrId = this.denunciante.list[this.denunciante.list.length - 1].arrId;
      await this.$store.dispatch("sumario/denunciante/setDenunciante", {
        arrId: arrId,
        doc: value,
        type: "empty",
      });
    },
    refresDate() {
      this.$store.dispatch("sumario/refreshDate", new Date());
      this.$store.dispatch("sumario/textResetEditsCounter", "Preform"); //este dato aparece en la Preform de FormExpedinte, cada que se modifica hay que actualizarla
    },
    modifyDate(calendarDateSelected) {
      this.$store.dispatch("sumario/modifyDate", calendarDateSelected);
    },
    addShowButton(arrId) {
      if (this.deleteConfirmationInProgress !== true || this.$refs.ConfirmPopup.visible !== true)
        this.showButton = arrId;
    },
    removeShowButton() {
      if (this.deleteConfirmationInProgress !== true || this.$refs.ConfirmPopup.visible !== true)
        this.showButton = "";
    },
    pasteInDeposition(directText, arrId, type) {
      let newText;
      let start;
      let end;
      let textArea;
      let updateStore;
      if (directText !== false) newText = directText;
      if (type === "contravener" || type === "whistleblower")
        newText = this.createPersonString(arrId, type);
      if (type === "object-reported-or-hijacked") newText = this.createObjectString(arrId);
      if (type === "patrimonial-afectation") newText = this.createAfectationString(arrId);

      // if (
      //   this.formType === "contravencional-oficio" ||
      //   this.formType === "contravencional-denuncia" ||
      //   this.formType.startsWith('ufi') ||
      //   this.formType.startsWith('preliminar') ||
      //   this.formType === "denuncia"
      // ) {
      textArea = this.textareaContravencional;
      updateStore = () => {
        this.$store.dispatch("sumario/setDeposition", textArea.value);
      };
      // }
      // if (this.formType === "oficio") {
      //   textArea = this.$refs.denunciaTextArea.$el;
      //   updateStore = () => {
      //     this.setDenuncia(textArea.value);
      //   };
      // }

      start = textArea.selectionStart;
      end = textArea.selectionEnd;
      textArea.setRangeText(newText, start, end, "select");
      textArea.focus();
      textArea.setSelectionRange(start + newText.length, end + newText.length);
      updateStore();
    },
    createPersonString(arrId, type) {
      let person;
      if (type === "contravener")
        person = this.acusados.list.filter((contravener) => contravener.arrId === arrId);
      if (type === "whistleblower")
        person = this.denunciante.list.filter((whistleblower) => whistleblower.arrId === arrId);

      person = person[0];
      let personString = "";
      if (person.ordenPublico) return "Orden público";
      if (!person.filiacionDesconocida) {
        personString = person?.doc?.condicion?.nombre + " ";
        personString =
          person.doc.apellido != undefined
            ? personString + person.doc.apellido + " "
            : personString;
        personString =
          person.doc.nombre != undefined ? personString + person.doc.nombre : personString;
        personString =
          person.doc.documento != undefined
            ? personString + ", DNI N° " + person.doc.documento
            : personString;
        personString =
          person.doc.nacionalidad != undefined
            ? personString + ", de nacionalidad " + person.doc.nacionalidad.nombre
            : personString;
        personString =
          person.doc.estadoCivil != undefined
            ? personString + ", estado civil " + person.doc.estadoCivil.nombre
            : personString;
        personString =
          person.doc.fechaNacimiento != undefined
            ? personString +
              ", de " +
              this.calculateAge(person.doc.fechaNacimiento) +
              " años de edad"
            : personString;
        personString =
          person.doc.instruccion != undefined
            ? personString + ", " + person.doc.instruccion.nombre
            : personString;
        personString =
          person.doc.Profesion != undefined
            ? personString + ", de profesion " + person.doc.Profesion
            : personString;

        let dom =
          person.doc.domicilio != undefined && person.doc.domicilio != ""
            ? person.doc.domicilio
            : "";
        let domSIPE =
          person.doc.domicilioSIPE != undefined && person.doc.domicilioSIPE != ""
            ? person.doc.domicilioSIPE
            : "";
        let domicilio = dom != "" ? dom : domSIPE;

        personString =
          domicilio != undefined && domicilio != ""
            ? personString + ", con domicilio en " + domicilio
            : personString;
        personString =
          person.doc.apodo != undefined
            ? personString + "\n Apodo: " + person.doc.apodo
            : personString;
      }
      let attribute = person.identikit.length > 0 ? "identikit" : "descriptionOffender";

      if (Array.isArray(person[attribute]))
        if (!person.doc.nombre) {
          personString +=
            "\n" + person?.doc?.condicion?.nombre + " " + "Persona de filiacion desconocida \n";
        }
      person[attribute].forEach((e, i) => {
        //suma al relato la descripcion del acusado
        personString =
          e.answer != undefined
            ? personString +
              // (i != 0 ? "\n" : "") +
              e.answer.replace(/^Respuesta P\.\d+:\s*/, ",").toLowerCase()
            : personString;
      });
      return personString;
    },
    calculateAge(birthday) {
      // birthday is a date
      birthday = birthday.replaceAll("-", "/");
      let dateWithReverseMounth = birthday.split("/");
      dateWithReverseMounth =
        dateWithReverseMounth[1] + "/" + dateWithReverseMounth[0] + "/" + dateWithReverseMounth[2];
      birthday = dateWithReverseMounth;
      birthday = new Date(birthday);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    createObjectString(arrId) {
      var value = "";
      var categoria = "";
      var subcategoria = "";
      var tipo = "";
      var marca = "";
      var modelo = "";
      var año = "";
      var chasis = "";
      var motor = "";
      var dominio = "";
      var serie = "";
      var imei = "";
      var abonado = "";
      var color = "";
      let objectsString = "";
      let object = this.robados.list.filter((item) => item.arrId === arrId);

      object = object[0];
      objectsString = object.doc.tipoEfecto.nombre + " ";
      if (object.doc.CategoriaID != undefined) {
        value = this.$store.state.siis.categoriasDeposito.find(
          (item) => item.CategoriaID === object.doc.CategoriaID
        );
        categoria = value.Nombre;
        objectsString = objectsString + ", Categoría: " + categoria;
      }
      if (object.doc.SubcategoriaID != undefined) {
        value = this.$store.state.siis.subcategoriasDeposito.find(
          (item) => item.SubcategoriaID === object.doc.SubcategoriaID
        );
        subcategoria = value.Nombre;
        objectsString = objectsString + ", Subcategoría: " + subcategoria;
      }
      if (object.doc.TipoID != undefined) {
        value = this.$store.state.siis.tipoDeposito.find(
          (item) => item.tipoID === object.doc.TipoID
        );
        tipo = value.nombre;
        objectsString = objectsString + `, Un/Una ${tipo}`;
      }
      if (object.doc.MarcaID != undefined) {
        value = this.$store.state.siis.marca.find((item) => item.MarcaID === object.doc.MarcaID);
        marca = value.nombre;
        objectsString = objectsString + ", Marca: " + marca;
      }
      if (object.doc.ModeloID != undefined) {
        value = this.$store.state.siis.modelo.find((item) => item.ModeloID === object.doc.ModeloID);
        modelo = value.Nombre;
        objectsString = objectsString + ", Modelo: " + modelo;
      }
      if (object.doc.Anio != undefined && object.doc.Anio != "") {
        año = object.doc.Anio;
        objectsString = objectsString + ", Año: " + año;
      }
      if (object.doc.Chasis != undefined && object.doc.Chasis != "") {
        chasis = object.doc.Chasis;
        objectsString = objectsString + ", Chasis: " + chasis;
      }
      if (object.doc.NumeroMotor != undefined && object.doc.NumeroMotor != "") {
        motor = object.doc.NumeroMotor;
        objectsString = objectsString + ", Numero de Motor: " + motor;
      }
      if (object.doc.Dominio != undefined && object.doc.Dominio != "") {
        dominio = object.doc.Dominio;
        objectsString = objectsString + ", Dominio: " + dominio;
      }
      if (object.doc.NumeroSerie != undefined && object.doc.NumeroSerie != "") {
        serie = object.doc.NumeroSerie;
        objectsString = objectsString + ", Numero de Serie: " + serie;
      }
      if (object.doc.Imei != undefined && object.doc.Imei != "") {
        imei = object.doc.Imei;
        objectsString = objectsString + ", Imei: " + imei;
      }
      if (object.doc.Abonado != undefined && object.doc.Abonado != "") {
        abonado = object.doc.Abonado;
        objectsString = objectsString + ", Abonado: " + abonado;
      }
      if (object.doc.Color != undefined && object.doc.Color != "") {
        color = object.doc.Color;
        objectsString = objectsString + ", Color: " + color;
      }

      return objectsString;
    },
    createAfectationString(arrId) {
      const tipoFormattedMap = {
        transferencia: "Transferencia Bancaria",
        cripto: "Criptomoneda",
        credito: "Tarjeta de Crédito",
        debito: "Tarjeta de Débito",
        efectivo: "Permuta",
        permuta: "Permuta",
      };
      let object = this.afectacionPatrimonial.list.filter((item) => item.arrId === arrId);

      object = object[0];
      var objectsString = "";
      if (object.doc.tipo != undefined) {
        objectsString = objectsString + tipoFormattedMap[object.doc.tipo];
      }
      if (object.doc.monto != undefined) {
        objectsString = objectsString + " - $ " + object.doc.monto;
      }
      if (object.doc.tipoMoneda.nombre != undefined) {
        objectsString = objectsString + ` - (${object.doc.tipoMoneda.nombre})`;
      }
      if (object.doc.bienEntregado != undefined) {
        objectsString = objectsString + ` - Bien entregado: ${object.doc.bienEntregado}`;
      }
      if (object.doc.origen != undefined) {
        objectsString = objectsString + ` - ${object.doc.origen}`;
      }
      if (object.doc.afectadoId != undefined) {
        objectsString = objectsString + ` - Denunciante: ${object.doc.afectadoId.nombre} `;
      }
      if (object.doc.vinculadoId != undefined) {
        objectsString = objectsString + ` - Acusado: ${object.doc.vinculadoId.nombre} `;
      }
      if (object.doc.observaciones != undefined) {
        objectsString = objectsString + `, ${object.doc.observaciones} `;
      }

      return objectsString;
    },
    goToStore() {
      this.cont++;
      if (this.cont >= 10) this.$router.push({ path: "/test/sumario" });
    },
    deleteContraventor(arrId) {
      this.$store.dispatch("sumario/acusados/deleteAcusadoByArrId", arrId);
    },
    deleteObject(arrId) {
      this.$store.dispatch("sumario/robados/deleteRobadoByArrId", arrId);
    },
    // modalVisible() {
    //   if (this.$refs.ConfirmPopup === undefined) {
    //     return false;
    //   } else {
    //     return this.$refs.ConfirmPopup.visible;
    //   }
    // },
    toggleIcon() {
      this.sortIcon =
        this.sortIcon === "showingCalendar" ? "notShowingCalendar" : "showingCalendar";
    },
    updateDenunciaFecha(newDate) {
      this.$store.dispatch("sumario/modifyDate", this.convertDateFormat(newDate));
    },
    convertDateFormat(dateString) {
      try {
          const [datePart, timePart] = dateString.split(', ');
          const [day, month, year] = datePart.split('/');

          // Reorganizamos al formato mm/dd/yyyy
          const newDateFormat = `${month}/${day}/${year}, ${timePart}`;
          return newDateFormat;
      } catch (error) {
          console.error("Error converting date format:", error);
          return null;
      }
    },
    nameAndSurname(item) {
      let completeName =
        item.doc.apellido && item.doc.nombre
          ? item.doc.apellido + " " + item.doc.nombre
          : !item.filiacionDesconocida
          ? "Faltan datos"
          : "Persona de filiacion desconocida";
      if (!this.viewData.stylesLightBluePersonas)
        completeName =
          completeName.toUpperCase() +
          (!item.filiacionDesconocida ? ", DNI N°: " + item.doc.documento : "");
      return completeName;
    },
    async finishEdition() {
        let internetConnection = await verifyInternetConnection();
        if(await !internetConnection) {
          this.$refs.ModalOfflineErrorRef.$refs.ModalRefInform.open();
          return;
        }
        await this.deleteActuationFromCached(this.sumario.id);
        let validation = this.validateInEditMode();
        if (validation) {
          await this.$store.dispatch("editMode/saveEdition", this.sumario);
          window.location.href =  '/actuaciones/view/' + this.sumario.id;
        }
        this.setAfectationCardDisabled(false);
        this.setPersonsAndEffectDisabled(false);

    },
    initializingStoreData(){
      this.$store.dispatch('siis/setArticulos');
      this.$store.dispatch('siis/setJuzgados');
      this.$store.dispatch('siis/setModusoperandi');
      this.$store.dispatch('siis/setDelitos');
      this.$store.dispatch('siis/setUFIs');
      this.$store.dispatch('siis/setProsecutorOrAssistant', {en: 'prosecutors', es: 'fiscal'});
      this.$store.dispatch('siis/setProsecutorOrAssistant', {en:'assistants', es: 'ayudante'});
      this.$store.dispatch('siis/setCausaCaratula');
      this.$store.dispatch('siis/setSitio');
      this.$store.dispatch('siis/setMonedas');
    },
    cancelEdition() {
      window.location.href =  '/actuaciones/view/' + this.sumario.id;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.withValidations {
  box-shadow: none;
}
.noValidations {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}
.actions__calendar__container {
  position: relative;
}
.vertical-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.floating-calendar {
  position: absolute;
  min-width: 420px;
  top: 245px;
  right: 40%;
  z-index: 3;
}
.hour_date__section {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  margin-right: 10px;
}
.text__hour {
  margin-top: 10px;
  margin-right: 10px;
}
.assistant {
  padding-right: 20px;
  padding-left: 10px;
  margin-bottom: 0px;
}
.assistant p {
  padding: 0;
  padding-right: 20px;
  font-weight: bolder;
}
.page {
  padding: 10px 30px;
  background-color: #f8f9fa;
}
ul {
  list-style: none;
  padding: 0;
}
ul li {
  list-style: none;
  margin: 0 0 5px;
  padding: auto 0;
}
.menu {
  padding: 0 30px 0 10px;
}
.feature-intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 20px;
}
.feature-intro > h1 {
  width: 150%;
  font-size: 1.5rem;
  padding-bottom: 0px;
  margin: 0px;
}

.feature-intro > p {
  font-size: 1.5rem;
  padding-top: 0px;
}
.feature-intro > i {
  align-items: flex-start;
  height: 100%;
}
.editor {
  width: 100%;
}

.title {
  font-size: 1rem;
  font-weight: 600;
}
.title-toolbar {
  display: flex;
  justify-content: space-between;
}

.search > input {
  margin-right: 10px;
}
.formsPage > div {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.formsPage > div.card > * {
  min-width: 20vw;
  margin-bottom: 20px;
  margin-right: 20px;
}
.declaracion {
  min-width: 20vw;
  margin-bottom: 20px;
  margin-right: 20px;
}

.label-denuncia {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1rem;
  font-weight: 700;
}

.label-oficio {
  padding-top: 5px;
  font-size: 1.6rem;
  font-weight: 150;
}

.minus10 {
  margin-top: -5px;
}

.card .double {
  display: flex;
}
.card.double > * {
  flex: 1;
}
.mt-10 {
  margin-top: 10px;
}
.mr-8 {
  margin-right: 8px;
}
.requeriments {
  border-bottom: 1px solid rgb(195, 195, 195);
  border-top: 1px solid rgb(195, 195, 195);
  padding: 5px;
  margin-top: 10px;
  font-size: 1.3rem;
  line-height: 0.1;
}
.requeriments button {
  width: 110px;
  height: 40px;
}
.requeriments-title {
  min-width: 350px;
}
.warning {
  color: #ffc107;
}
.alert {
  color: #dc3545;
}

.paste-button {
  width: 25px;
}
.address {
  font-size: 0.8rem;
  margin-left: 1px;
  margin-top: -15px;
}
.cbu-estate {
  font-size: 0.8rem;
  margin-left: 1px;
  margin-top: -15px;
}
.info_section {
  display: flex;
  flex-direction: column;
}
.denuncia-print {
  width: 0px;
  height: 0px;
  overflow: auto;
  /* border: 1px solid #ccc; */
}
.denuncia-print-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  transform: translateX(50px);
  width: 100vh;
  height: calc(100vh - 120px);
  position: relative;
}

.denuncia-print-placeholder::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/diligencia.png");
  background-size: cover;
  filter: brightness(0.4);
}

.loading-container {
  display: flex;
  align-items: center;
  z-index: 1; /* Asegura que esté por encima del fondo */
}
.loading-text {
  font-size: 26px;
  margin-left: 10px;
  color: white; /* Asegúrate de que el color sea visible sobre el fondo */
  z-index: 2; /* Asegúrate de que este texto esté por encima del fondo */
  position: relative; /* Importante para que z-index funcione */
}

.loading-spinner .p-progress-spinner-svg {
  /* Estilos opcionales para el spinner */
  stroke: white; /* Color del spinner */
}
.primary-color {
  font-weight: 600;
  color: var(--primary-color);
}
.error {
  border-bottom: solid 2px #dc3545;
}
.line {
  display: block;
  width: 100%;
  height: 1px;
  background: #a5afb9;
  margin-bottom: 1em;
}
.totalsContainer {
  display: flex;
  align-items: center;
}
</style>
