export const preliminarEstablecerConfig = [
    {
        path: "/actuaciones/new/preliminares-establecer-procedencia/initial",
        pathName: "preliminares-establecer-procedencia",
        titulo: "ESTABLECER PROCEDENCIA",
        props: {
          formType: "preliminar-establecer-procedencias" , actuacionType:"preliminares-establecer-procedencia"},
          nameOfRoute : "actuacion-initial"      
      },
      {
        path: "/actuaciones/new/preliminares-establecer-procedencia/initial/denunciante",
        pathName: "preliminares-establecer-procedencia",
        titulo: "AFECTADOS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/preliminares-establecer-procedencia/initial/momento",
        pathName: "preliminares-establecer-procedencia",
        titulo: "FECHA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/preliminares-establecer-procedencia/initial/acusado",
        pathName: "preliminares-establecer-procedencia",
        titulo: "VINCULADOS",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/preliminares-establecer-procedencia/initial/efectos",
        pathName: "preliminares-establecer-procedencia",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]