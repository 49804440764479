// version 1
export const GeneroConfig = {
  // Template
  templateTable:{
    data:null,
    disableButtonEdit:false,
    apiversion:'v2',
    enableAddButton:{ enabled: true, name: 'genero-new'},
    enableEditButtons:true,
    enableGlobalSearch:false,
    enableSelectAllCheckbox:true,
    filtersFields:[
      { name: "Nombre", field: "Nombre" },
    ],
    idTable:"GeneroID",
    keyShow: ['Nombre'],
    loading:true,
    modal:false,
    multiSelect:true,
    reference:"parametros/genero/edit",
    tableTitle:"Genero",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/genero",
    title: "Género",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Nombre: {
          value:"leonardo",
          value2:"exequiel",
          validation:"required|max_length[50]",
          type: "input"
        }
      },
      names: {
        Nombre: ""
      },
      errors: {
        Nombre: false
      },
      mensajes: {
        Nombre: ""
      },
      design: [
        [ "Nombre" ]
      ],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + '/parametros/genero',
      pageName:'Género'
    }
  },
};
export const PaisConfig = {
  templateTable:{
    data:null,
    disableButtonEdit:false,
    enableAddButton:{ enabled: true, name:'pais-new'},
    enableEditButtons:true,
    enableGlobalSearch:false,
    enableSelectAllCheckbox:true,
    filtersFields:[
      { name: "Nombre", field: "Nombre" },
    ],
    idTable:"PaisID",
    keyShow: ['Nombre'],
    loading:true,
    modal:false,
    multiSelect:true,
    reference:"parametros/pais/edit",
    tableTitle:"Pais",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/pais",
    title: "País",
  },
  //ADD - EDIT
  form:{
    data:{
      data: {
        Nombre:{
          value:"leonardo",
          value2:"exequiel",
          validation:"required|max_length[50]",
          type: "input"
        }
      },
      names: {
        Nombre:""
      },
      errors:  {
        Nombre:false
      },
      mensajes: {
        Nombre:""
      },
      design: [
        ["Nombre"]
      ],
      id:"",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + '/parametros/pais',
      pageName:'País'
    }
  }
}
export const DepositoDependenciaConfig = {
  // Template
  templateTable:{
    data:null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'deposito-dependencia-new' },
    enableEditButtons:true,
    enableGlobalSearch:false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "Nombre", field: "Nombre" },
      { name: "Orden", field: "Orden" },
    ],
    idTable:"DepositodependenciaID",
    keyShow: ['Nombre','Descripcion','Orden','Telefono'],
    loading:true,
    modal:false,
    multiSelect:true,
    reference: "parametros/deposito-dependencia/edit",
    tableTitle:"Pais",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/deposito-dependencia",
    title: "Depósito Dependencia",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Descripcion:{
          value:"descripcion",
          value2:"esto es una descripcion",
          validation:"max_length[50]",
          type: "input"
        },
        Nombre:{
          value:"leonardo",
          value2:"exequiel",
          validation:"required|max_length[50]",
          type: "input"
        },
        Orden:{
          value:1,
          value2:2,
          validation:"required|max_length[11]|integer",
          type: "input"
        },
        Telefono:{
          value:"264555555",
          value2:"2645851354",
          validation:"max_length[50]",
          type: "input"
        }
      },
      names: {
        Descripcion:"",
        Nombre:"",
        Orden:"",
        Telefono:""
      },
      errors:  {
        Descripcion:false,
        Nombre:false,
        Orden:false,
        Telefono:false
      },
      mensajes: {
        Descripcion:"",
        Nombre:"",
        Orden:"",
        Telefono:""
      },
      designEdit: [
        ["Descripcion","Nombre"],["Orden","Telefono"]
      ],
      design: [
        ["Descripcion","Nombre"],["Orden","Telefono"]
      ],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + '/deposito-dependencia',
      pageName:'Deposito Dependencia'
    }
  },
};
export const TipoMonedaConfig = {
  templateTable:{
    data:null,
    disableButtonEdit:false,
    apiversion:'v2',
    enableAddButton: { enabled: true, name:'tipo-moneda-new' },
    enableEditButtons:true,
    enableGlobalSearch:false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "Nombre", field: "NombreMoneda" },
      { name: "Prefijo", field: "Prefijo" },
      { name: "Tipo", field: "TipoDeMoneda" },
    ],
    idTable:"TipoMonedaID",
    keyShow: ['NombreMoneda','Prefijo','TipoDeMoneda'],
    loading:true,
    modal:false,
    multiSelect:true,
    reference: "parametros/tipo-moneda/edit",
    tableTitle:"tipo moneda",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/tipo-moneda",
    title: "Tipo Moneda",
  },
  // ADD - NEW
  form:{
    data:{
      data: {
        NombreMoneda:{
          validation:"required|max_length[50]",
          type: "input",
          label:"Nombre",
          placeholder:"Ingrese el nombre"
        },
        TipoDeMoneda :{
         validation:"required",
          type: "dropdown",
          label: "Tipo de moneda",
          placeholder: "Seleccione el tipo de moneda",
          dropdown: {
            optionValue: "id",
            optionLabel: "name",
            options: [
              { id: "Divisa", name: "Divisa" },
              { id: "Criptomoneda", name: "Criptomoneda" },
            ],
          },

        },
        Prefijo:{
          validation:"required|max_length[10]",
          type: "input"
        }
      },
      names: {
        NombreMoneda:"Nombre",
        Prefijo:"Prefijo",
        TipoDeMoneda :"Tipo Moneda"
      },
      errors:  {
        NombreMoneda:false,
        TipoDeMoneda :false,
        Prefijo:false
      },
      mensajes: {
        NombreMoneda:"",
        TipoDeMoneda :"",
        Prefijo:""
      },
      design: [
        ["NombreMoneda","Prefijo","TipoDeMoneda"]
      ],
      id:'',
      pageName: 'Tipo Moneda',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/tipo-moneda",
    }
  }
}
export const EstadoCivilConfig = {
  templateTable:{
    data:null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'estado-civil-new' },
    enableEditButtons:true,
    enableGlobalSearch:false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "Nombre", field: "Nombre" },
    ],
    idTable:"EstadocivilID",
    keyShow: ['Nombre'],
    loading:true,
    modal:false,
    multiSelect:true,
    reference: "parametros/estado-civil/edit",
    tableTitle:"Pais",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/estado-civil",
    title: "Estado Civil",
  },
  // ADD - NEW
  form:{
    data:{
      data: {
        Nombre:{
          value:"leonardo",
          value2:"exequiel",
          validation:"required|max_length[50]",
          type: "input"
        }
      },
      names: {
        Nombre:""
      },
      errors:  {
        Nombre:false
      },
      mensajes: {
        Nombre:""
      },
      design: [
        ["Nombre"]
      ],
      id:'',
      pageName: 'Estado Civil',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/estado-civil",
    }
  }
}
export const ProvinciaConfig = {
  templateTable:{
    data:null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'provincia-new' },
    enableEditButtons:true,
    enableGlobalSearch:false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "Nombre", field: "Nombre" },
    ],
    idTable:"ProvinciaID",
    keyShow: ['Nombre','PaisID'],
    loading:true,
    modal:false,
    multiSelect:true,
    reference: "parametros/provincia/edit",
    tableTitle:"Pais",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/provincia",
    title: "Provincia",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Nombre:{
          value:"nicolas",
          value2:"gonzalo",
          validation:"required|max_length[50]",
          type: "input"
        },
        PaisID:{
          value:"abc123",
          value2:"123abc",
          validation:"required|max_length[50]",
          type: "input"
        }
      },
      names: {
        Nombre:"",
        PaisID:""
      },
      errors:  {
        Nombre:false,
        PaisID:false
      },
      mensajes: {
        Nombre:"",
        PaisID:""
      },
      design: [
        ["Nombre","PaisID"]
      ],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/provincia",
      pageName: 'Provincia',
    }
  },
};
//version 2
export const SitioConfig = {
  // Template
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'sitio-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filteredLocation: [],
    filtersFields: [{ name: "Nombre", field: "Nombre" },],
    idTable: 'TipoSitioID',
    keyShow: ['Nombre','Descripcion'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/sitio/edit",
    tableTitle:'Sitio',
    title:'Sitio',
    url:process.env.VUE_APP_BACKEND_ENVIRONMENT + "/tipo-sitio",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Nombre:{
          value:"Cyber",
          value2:"Interior Cyber",
          validation:"required|max_length[200]",
          type: "input"
        },
        Descripcion:{
          value:"descripcion",
          value2:"otradescripcion",
          validation:"max_length[500]",
          type: "input"
        },
      },
      names: {"Nombre":"","Descripcion":""},
      errors:  {"Nombre":false,"Descripcion":false},
      mensajes: {"Nombre":"","Descripcion":""},
      design: [["Nombre"],["Descripcion"]],
      designEdit: [["Nombre"],["Descripcion"]],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/tipo-sitio",
      pageName:'Sitio',
    }
  },
};
export const DependenciaConfig = {
  templateTable:{
    apiversion:'v2',
    addDropdownFilter: {
      dispatch: "siis/setDepartamentales",
      name: "exp_pre_Dependencia.DepartamentalID",
      new:{
        name: "Departamental",
        field:"exp_pre_Dependencia.DepartamentalID",
        limit: 1
      },
      optionLabel: "departamental",
      options: [],
      optionValue: "id",
      stateName:'departamentales',
    },
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'dependencia-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filteredLocation: [],
    filtersFields: [{ name: "Nombre", field: "Nombre" }],
    idTable: 'DependenciaID',
    keyShow: [
      "Descripcion",
      "Nombre",
      "Telefonos",
      "CorreoElectronico",
      "DependenciaLetra",
      "ImpresionEncabezado",
      "Domicilio",
      "Departamento",
      "DepartamentalNombre"
    ],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/dependencia/edit",
    tableTitle:'Dependencia',
    title:'Dependencia',
    url:process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/dependencia",
  },
  form:{
      data:{
          data: {
              "Admitesumarios":{
                "value":1,"value2":0,
                "validation":"required|max_length[1]",
                type: "input",
                label: "Admite sumarios",
                placeholder: "Admite sumarios",
              },
              "Descripcion":{
                "value":"descripcion",
                "value2":"esto es una descripcion",
                "validation":"max_length[50]",
                type: "input",
                label: "Descripción",
                placeholder: "Descripción",

              },
              "Nombre":{
                "value":"leonardo",
                "value2":"exequiel",
                "validation":"required|max_length[50]",
                type: "input"
              },
              "Telefonos":{
                "value":"2645555555",
                "value2":"2645851354",
                "validation":"max_length[50]",
                type: "input",
                label: "Teléfonos",
                placeholder: "Teléfonos",
              },
              "DependenciaLetra":{
                "value":"comisaria25",
                "value2":"comisaria6ta",
                "validation":"max_length[50]",
                type: "input",
                label: "Dependencia letra",
                placeholder: "Dependencia letra",
              },
              "Interno":{
                "value":"4564",
                "value2":"6547",
                "validation":"max_length[50]",
                type: "input"
              },
              "ClavePolicial":{
                "value":"005",
                "value2":"019",
                "validation":"max_length[50]",
                type: "input",
                label: "Clave policial",
                placeholder: "Clave policial",
              },
              "ImpresionEncabezado":{
                "value":"123abc",
                "value2":"abc123",
                "validation":"max_length[255]",
                type: "input",
                label: "Impresión encabezado",
                placeholder: "Impresión encabezado",
              },
              "Domicilio":{
                "value":"123abc",
                "value2":"abc123",
                "validation":"max_length[255]|required",
                type: "input"
              },
              "Departamento":{
                "value":"123abc",
                "value2":"abc123",
                "validation":"max_length[50]|required",
                type: "input"
              },
              "CorreoElectronico":{
                "value":"123abc",
                "value2":"abc123",
                "validation":(value) => {
                  let validationError = false;
                  let validationMensaje;
                  if(value?.CorreoElectronico !== undefined){
                    if(!value.CorreoElectronico.includes('.')) validationError = true, validationMensaje = 'Falta el punto';
                    if(!value.CorreoElectronico.includes('@')) validationError = true,  validationMensaje = 'Falta el arroba';
                  }else{
                    value.CorreoElectronico = '';
                  }
                  return { error: validationError, mensaje: validationMensaje };
                },
                type: "input",
                label: "Email",
                placeholder: "Email",
              },
              "DepartamentalID": {
                "value": "Sin Departamental Asignada",
                "value2": "Sin Departamental Asignada 2",
                "validation": (value) => {
                  if(value?.DepartamentalID === undefined){
                    value.DepartamentalID = 'sin-departamental-asignada'
                  }
                  return false;
                },
                type: "dropdown",
                label: "Departamental",
                placeholder: "Sin Departamental Asignada",
               },
            },
            names: {
              "Admitesumarios":"",
              "Descripcion":"",
              "Nombre":"",
              "Telefonos":"",
              "CorreoElectronico":"",
              "DependenciaLetra":"",
              "Interno":"",
              "ClavePolicial":"",
              "ImpresionEncabezado":"",
              "Domicilio":"",
              "Departamento":"",
              "DepartamentalID":"",
            },
            errors:  {
              "ImpresionEncabezado":false,"Domicilio":false,"Departamento":false, "CorreoElectronico":false, "DepartamentalID":false
            },
            mensajes: {
              "Admitesumarios":"","Descripcion":"","Nombre":"","Telefonos":"","DependenciaLetra":"","Interno":"","ClavePolicial":"",
              "ImpresionEncabezado":"",
              "CorreoElectronico":"",
              "Domicilio":"",
              "Departamento":"",
              "Departamental":""
            },
            dropdown:
            {
              DepartamentalID: {
                optionValue: "id",
                optionLabel: "departamental",
                options: [],
                refreshDropdown: null,
              }
            },
            designEdit: [
              ["Admitesumarios","Descripcion"],
              ["Nombre","Telefonos"],
              ["DependenciaLetra","Interno"],
              ["ImpresionEncabezado","Domicilio"],
              ["ClavePolicial", "CorreoElectronico"],
              ["DepartamentalID"]
            ],
            design: [
              ["Admitesumarios","Descripcion"],
              ["Nombre","Telefonos"],
              ["DependenciaLetra","Interno"],
              ["ImpresionEncabezado", "Domicilio"],
              ["ClavePolicial", "CorreoElectronico"],
              ["DepartamentalID", "Departamento"]
            ],
            id:'',
            url: process.env.VUE_APP_BACKEND_ENVIRONMENT + '/parametros/dependencia',
            pageName:'Dependencia'
      }
  },
};
export const ArticulosConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'articulos-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filteredLocation: [],
    filtersFields: [
      { name: "Numero de Articulo", field: "Numeroarticulo" },
      { name: "Codigo", field: "Codigo" },
    ],
    idTable: 'ArticulosID',
    keyShow: ['Numeroarticulo','Codigo'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/articulos/edit",
    tableTitle:'Artículos',
    title: "Artículos",
    url:process.env.VUE_APP_BACKEND_ENVIRONMENT + "/articulos",

  },
  // New - Edit
  form:{
    data:{
      data: {
        Numeroarticulo: {
        value: "",
        value2: "",
        validation: "required|max_length[50]",
        type: "input"
        },
        Codigo: {
        value: "",
        value2: "",
        validation: "required|max_length[50]",
        type: "input"
        },
      },
      names: {
      Numeroarticulo: "",
      Codigo: "",
      },
      errors: {
        Numeroarticulo: false,
        Codigo: false,
      },
      mensajes: {
        Numeroarticulo: "",
        Codigo: "",
      },
      design: [
      ["Numeroarticulo"],
      ["Codigo"]
      ],
      designEdit: [
      ["Numeroarticulo"],
      ["Codigo"]
      ],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/articulos",
      pageName: "Articulos",
      //version 1.1
      labels: {
        Numeroarticulo: "Número del Artículo"
      },
      placeholder: {
        Numeroarticulo: "Escribir Número del Artículo"
      },
    }
  },
};
export const JuzgadoConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'juzgado-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filteredLocation: [],
    filtersFields: [
      { name: "Nombre", field: "Nombre" },
    ],
    idTable: 'JuzgadoID',
    keyShow: ['Nombre','Telefono','Domicilio'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/juzgado/edit",
    tableTitle:'Juzgado',
    title: "Juzgado",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/juzgado",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Nombre: {
          value: "nicolas",
          value2: "gonzalo",
          validation: "required|max_length[50]",
          type: "input"
        },
        Telefono: {
          value: "2645064965",
          value2: "2645064969",
          validation: "max_length[50]",
          type: "input"
        },
        Domicilio: {
          value: "abc123",
          value2: "123abc",
          validation: "max_length[255]",
          type: "input"
        },
      },
      names: {
        Nombre: "",
        Telefono: "",
        Domicilio: "",
      },
      errors: {
        Nombre: false,
        Telefono: false,
        Domicilio: false,
      },
      mensajes: {
        Nombre: "",
        Telefono: "",
        Domicilio: "",
      },
      design: [["Nombre", "Telefono"], ["Domicilio"]],
      designEdit: [
        ["Nombre"],
        ["Telefono", "Domicilio"],
      ],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/juzgado",
      pageName: 'Juzgado',
    }
  },
};
export const PersonaFisicaConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'persona-fisica-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [{ name: "Nombre", field: "Nombre" }, { name: "DNI", field: "Dni" }, { name: "Apellido", field: "Apellido" }, { name: "Cuil", field: "Cuil" }],
    idTable: 'PersonaFisicaID',
    keyShow: [
      "Nombre",
      "Apellido",
      // "TipodocumentoID",
      "Dni",
      "Telefono",
      "Cuil",
      "Celular",
      "Sexo",
      "Nacionalidad",
      "LugarDeNacimiento",
    ],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/persona-fisica/edit",
    tableTitle:"Persona física",
    title: "Persona Física",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/persona-fisica",
  },
  // New - Edit
  form:{
    data:{
      data:{
        Apellido:{
          value:"canada",
          value2:"valdez",
          validation:"required|max_length[50]",
          type: "input"
        },
        Celular:{
          value:"26455555",
          value2:"2645064969",
          validation:"max_length[50]",
          type: "input"
        },
        Cuil:{
          value:"264655656",
          value2:"23493478323",
          validation:"max_length[50]",
          type: "input"
        },
        Dni:{
          value:"375068659",
          value2:"49347832",
          validation:"required|max_length[50]",
          type: "input"
        },
        // "FechaDefuncion":{"value":"2021-07-29","value2":"2021-07-30","validation":"",type: "date"},
        LugarDeNacimiento:{
          value:"otrolugar",
          value2:"unlugar",
          validation:"alpha|max_length[50]",
          type: "input"
        },
        Nacionalidad:{
          value:"aleman",
          value2:"argentino",
          validation:"alpha|max_length[50]",
          type: "input"
        },
        Nombre:{
          value:"leo",
          value2:"gonzalo",
          validation:"required|max_length[50]",
          type: "input"
        },
        Sexo:{
          value:"probando",
          value2:"otrosexo",
          validation:"alpha|max_length[50]",
          type: "input"
        },
        Telefono:{
          value:"2648548",
          value2:"4256858",
          validation:"alpha_num|max_length[50]",
          type: "input"
        }
      },
      names: {"Apellido":"","Celular":"","Cuil":"","Dni":""/*,"FechaDefuncion":""*/,"LugarDeNacimiento":"","Nacionalidad":"","Nombre":"","Sexo":"","Telefono":""},
      errors:  {"Apellido":false,"Celular":false,"Cuil":false,"Dni":false,"FechaDefuncion":false,"LugarDeNacimiento":false,"Nacionalidad":false,"Nombre":false,"Sexo":false,"Telefono":false},
      mensajes: {"Apellido":"","Celular":"","Cuil":"","Dni":""/*,"FechaDefuncion":""*/,"LugarDeNacimiento":"","Nacionalidad":"","Nombre":"","Sexo":"","Telefono":""},
      design: [["Apellido","Nombre"],["Dni","Cuil"],["Sexo"],["Nacionalidad","LugarDeNacimiento"],["Telefono","Celular"]],
      designEdit: [["Apellido","Celular"],["Cuil","Dni"],[/*"FechaDefuncion",*/"LugarDeNacimiento"],["Nacionalidad","Nombre"],["Sexo","Telefono"]],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/persona-fisica",
      pageName: 'Persona Física',
    }
  },
};
export const ModusOperandiConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'modus-operandi-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [{ name: "Nombre", field: "Nombre" },],
    idTable: 'TipomodusoperandiID',
    keyShow: ['Nombre','Descripcion'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/modus-operandi/edit",
    tableTitle:"Modus Operandi",
    title: "Modus Operandi",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-modus-operandi",
  },
  // New - Edit
  form:{
    data:{
      data:{
        Nombre:{
          value:"nicolas",
          value2:"gonzalo",
          validation:"required|max_length[100]",
          type: "input"
        },
        Descripcion:{
          value:"descripcion",
          value2:"otradescripcion",
          validation:"max_length[500]",
          type: "input"
        },
      },
      names: {"Nombre":"","Descripcion":""},
      errors:  {"Nombre":false,"Descripcion":false},
      mensajes: {"Nombre":"","Descripcion":""},
      design: [["Nombre"],["Descripcion"]],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-modus-operandi",
      pageName: 'Modus Operandi',
    }
  },
};
export const MarcasConfig = {
  routeData:[
    {
      path: '/parametros/marcas',
      roles:['User Tester','Super User'],
      nameOfRoute: "marcas"
    },
    {
      path: '/parametros/marcas/new',
      roles:['User Tester','Super User'],
      nameOfRoute: "marcas"
    },
    {
      path: '/parametros/marcas/edit/:id',
      roles:['User Tester','Super User'],
      nameOfRoute: "marcas"
    },
  ],
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'marcas-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "Nombre", field: "nombre" },
    ],
    idTable: 'MarcaID',
    keyShow: ['nombre'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/marcas/edit",
    tableTitle:"Marcas",
    title: "Marcas",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/marcas",
  },
  // New - Edit
  form:{
    data:{
      data:{
        "nombre":{
          "value":"nicolas",
          "value2":"gonzalo",
          "validation":"required|max_length[100]",
          type: "input"
        }
      },
      names: {"nombre":""},
      errors:  {"nombre":false},
      mensajes: {"nombre":""},
      design: [["nombre"]],
      designEdit: [["nombre"]],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/marcas",
      pageName: 'Marcas',
    }
  },
};
export const CategoriasConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'categorias-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "nombre", field: "Nombre" },
    ],
    idTable: 'CategoriaID',
    keyShow: ['Nombre'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/categorias/edit",
    tableTitle:"Categorías",
    title: "Categorías",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/categorias",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Nombre:{
          value:"nicolas",
          value2:"gonzalo",
          validation:"required|max_length[100]",
          type: "input"
        }
      },
      names: {"Nombre":""},
      errors:  {"Nombre":false},
      mensajes: {"Nombre":""},
      design: [["Nombre"]],
      designEdit: [["Nombre"]],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/categorias",
      pageName: 'Categorias',
    }
  },
};
export const TipoEfectoDepositoConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'tipo-efecto-deposito-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "nombre", field: "nombre" },
    ],
    idTable: 'tipoID',
    keyShow: ['nombre','subcategoria'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference:"parametros/tipo-efecto-deposito/edit",
    tableTitle:"Tipo Efecto",
    title: "Tipo Efecto",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-depositos",
  },
  // New - Edit
  form:{
    data:{
      data:{
        nombre:{
          value:"nicolas",
          value2:"gonzalo",
          validation:"required|max_length[100]",
          type: "input"
        },
        id_subcategoria:{
          value:"nicolas",
          value2:"gonzalo",
          validation:"required|max_length[100]",
          type: "dropdown"
        }
      },
      names:{
        nombre:"",
        id_subcategoria:""
      },
      errors:{
        nombre:false,
        id_subcategoria:false
      },
      mensajes: {
        nombre:"",
        id_subcategoria:""
      },
      design: [["nombre"],["id_subcategoria"]],
      designEdit: [["nombre"],["id_subcategoria"]],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-depositos",
      pageName:'Tipo Efecto',
      labels:{
        id_subcategoria:"Sub-Categoria",
        nombre:"Nombre"
      },
      placeholder:{
        id_subcategoria:"Elija una Sub-Categoria",
        nombre:"Escribir Nombre",
      },
      dropdown:{
        id_subcategoria: {
          optionValue: "SubcategoriaID",
          optionLabel: "Nombre",
          options: [],
        }
      }
    },
  }
};
export const CaratulaConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'caratula-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "Nombre", field: "Nombre" },
      { name: "Tipo Caratula", field: "TipoCaratula"}
    ],
    idTable: 'TipocausacaratulaID',
    keyShow: ["Nombre", "TipoCaratula"],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/caratula/edit",
    tableTitle:"Caratula",
    title: "Carátula",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-causa-caratula",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Nombre:{
          value:"leonardo",
          value2:"exequiel",
          validation:"required|max_length[100]",
          type: "input"
        },
        Descripcion:{
          value:"123abc",
          value2:"abc123",
          validation:"max_length[500]",
          type: "input"
        },
        TipoCaratula:{
          value:"Delito",
          value2:"Causa caratula",
          validation:"required|max_length[50]",
          type: "dropdown"
        }
      },
      names: {
        Nombre:"",
        Descripcion:"",
        TipoCaratula:""
      },
      errors: {
        Nombre:false,
        Descripcion:false,
        TipoCaratula:false
      },
      mensajes: {
        Nombre:"",
        Descripcion:"",
        TipoCaratula:""
      },
      designEdit: [["Nombre"],["Descripcion"],["TipoCaratula"]],
      design: [["Nombre"],["Descripcion"],["TipoCaratula"]],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-causa-caratula",
      pageName: 'Caratula',
      placeholder:{
        TipoCaratula:"Elija una tipo de Caratula",
      },
      dropdown:
      {
        TipoCaratula: {
          options: ["Delito","Causa Caratula"],
        }
      }
    }
  },
};
export const PersonalFiscalConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'personal-fiscal-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "Jerarquia", field: "Jerarquia" },
      { name: "Denominacion", field: "Denominacion" },
    ],
    idTable: 'PersonalfiscalID',
    keyShow: ['Jerarquia','Denominacion'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/personal-fiscal/edit",
    tableTitle:"Personal Fiscal",
    title: "Personal Fiscal",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/personalfiscal",
  },
  form:{
    data:{
      data: {
        Denominacion:{
          value:"dr pepe honguito",
          value2:"dr mario bross",
          validation:"max_length[100]|required",
          type: "input"
        },
        Jerarquia:{
          value:"ayudante",
          value2:"fiscal",
          validation:"max_length[100]|required",
          type: "dropdown"
        }
      },
      names: {
        Denominacion:"",
        Jerarquia:""
      },
      errors:  {
        Denominacion:false,
        Jerarquia:false
      },
      mensajes: {
        Denominacion:"",
        Jerarquia:""
      },
      design: [
        ["Denominacion"],["Jerarquia"]
      ],
      id:'',
      pageName: 'Personalfiscal',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/personalfiscal",
      placeholder:{
        Denominacion:"Por ej: Dr Jorge Rivero",
        Jerarquia:"Seleccione el tipo de Jerarquia"
      },
      dropdown:
      {
        Jerarquia: {
          options: ["Fiscal","Ayudante"],
        }
      }
    }
  }
}
export const SubCategoriasConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'sub-categorias-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "nombre", field: "Nombre" },
    ],
    idTable: 'SubcategoriaID',
    keyShow: ['categoria','Nombre'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/sub-categorias/edit",
    tableTitle:"SubCategorías",
    title: "Sub-Categorías",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/sub-categorias",
  },
  // New - Edit
  form:{
    data:{
      data: {
        id_categoria: {
          value: "nicolas",
          value2: "gonzalo",
          validation: "required|max_length[100]",
          type: "dropdown",
        },
          Nombre: {
          value: "nicolas",
          value2: "gonzalo",
          validation: "required|max_length[100]",
          type: "input",
        },
      },
      names: {
        id_categoria:"",
        Nombre: "",
      },
      errors: {
        id_categoria:false,
        Nombre: false
      },
      mensajes: {
        id_categoria:"",
        Nombre: ""
      },
      design: [["id_categoria"],["Nombre"]],
      designEdit: [["id_categoria"],["Nombre"]],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/sub-categorias",
      pageName: "Sub-Categoría",
      labels:{
        id_categoria:"Categoría",
        Nombre:"Nombre"
      },
      placeholder:{
          id_categoria:"Elija una Categoría",
          Nombre:"Escribir Nombre",
      },
      dropdown:{
        id_categoria: {
          optionValue: "CategoriaID",
          optionLabel: "Nombre",
          options: [],
        },
      }
    }
  },
};

export const ModelosConfig = {
  templateTable:{
    apiversion:'v',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'modelos-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "Nombre", field: "Nombre" },
    ],
    idTable: 'ModeloID',
    keyShow: ['categorias','subcategorias','marcas','tipo','Nombre'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference:  "parametros/modelos/edit",
    tableTitle:"Modelos",
    title: "Modelos",
    titles: {
      "categorias": "Categoria",
      "subcategorias": "Sub-Categoria",
      "marcas": "Marca",
      "tipo": "Tipo",
      "Nombre": "Nombre",

    },
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/modelos",
  },
  // New - Edit
  form:{
    data:{
      data: {
        id_categoria: {
          value: "nicolas",
          value2: "gonzalo",
          validation: "required|max_length[100]",
          type: "dropdown",
        },
        id_subcategoria: {
          value: "nicolas",
          value2: "gonzalo",
          validation: "required|max_length[100]",
          type: "dropdown",
        },
        id_marca: {
          value: "nicolas",
          value2: "gonzalo",
          validation: "required|max_length[100]",
          type: "dropdown",
        },
        id_tipo: {
          value: "nicolas",
          value2: "gonzalo",
          validation: "required|max_length[100]",
          type: "dropdown",
        },
        Nombre: {
          value: "nicolas",
          value2: "gonzalo",
          validation: "required|max_length[100]",
          type: "input",
        },
      },
      names: { id_categoria:"",id_marca:"",Nombre: "",id_subcategoria:"",id_tipo:"" },
      errors: { id_categoria:false, id_marcar:false,Nombre: false,id_subcategoria:false,id_tipo:false },
      mensajes: { id_categoria:"",id_marca:"",Nombre: "",id_subcategoria:"",id_tipo:"" },
      design: [["id_categoria","id_subcategoria"],["id_marca","id_tipo"],["Nombre"]],
      designEdit: [["id_categoria","id_subcategoria"],["Nombre","id_marca"]],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/modelos",
      pageName: 'Modelos',
      labels:{
        id_categoria:"Categoria",
        id_subcategoria:"Sub-Categoria",
        id_marca:"Marcas",
        id_tipo:"Tipo",
        Nombre:"Nombre"
      },
      placeholder:{
        id_categoria:"Elija una Categoria",
        id_subcategoria:"Elija una Sub-Categoria",
        id_marca:"Elija una Marca",
        id_tipo:"Elija un Tipo",
        Nombre:"Escribir Nombre",
      },
      dropdown: {
        id_categoria: {
          optionValue: "CategoriaID",
          optionLabel: "Nombre",
          options: [],
          changeDropdown: null, // Esto se asignará después
        },
        id_subcategoria: {
          optionValue: "SubcategoriaID",
          optionLabel: "Nombre",
          options: [],
          changeDropdown: null, // Esto se asignará después
        },
        id_marca: {
          optionValue: "MarcaID",
          optionLabel: "nombre",
          options: [],
          refreshDropdown: null, // Esto se asignará después
        },
        id_tipo: {
          optionValue: "tipoID",
          optionLabel: "nombre",
          options: [],
        },
      }
    }
  },
}

export const EfectoDenunciaConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'efecto-denuncia-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "Fechadeposito", field: "Fechadeposito" }
    ],
    idTable: 'EfectodenunciaID',
    keyShow: [
      "Tipo",
      "Marca",
      "Modelo",
      "Color",
      "IdSeriePatente",
      "Detalles",
      "TieneSeguro",
      "Fechainivig",
      "Fechadeposito",
      "DenunciaID",
    ],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/efecto-denuncia/edit",
    tableTitle:"Efecto denuncia",
    title: "Efecto denuncia",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/efecto-denuncia",
  },
  form:{
    data:{
      data: {
        Descripcion:{
          value:"otraDescripcion",
          value2:"Consequi pariadatat pa Irurer ea proident officia.",
          validation:"",
          type: "input"
        },
        Fechadeposito:{
          value:"2021-07-29",
          value2:"2021-07-30",
          validation:"required",
          type: "date"
        },
        Fechafinvig:{
          value:"2021-07-29",
          value2:"2021-07-30",
          validation:"",
          type: "date"
        },
        Fechainivig:{
          value:"2021-07-29",
          value2:"2021-07-30",
          validation:"",
          type: "date"
        },
        Nombre:{
          value:"nicolas",
          value2:"gonzalo",
          validation:"alpha|max_length[50]",
          type: "input"
        },
        DenunciaID:{
          value:"abc123",
          value2:"123abc",
          validation:"required|max_length[50]",
          type: "input"
        },
        Tipo:{
          value:"abc123",
          value2:"123abc",
          validation:"",
          type: "input"
        },
        Marca:{
          value:"abc123",
          value2:"123abc",
          validation:"",
          type: "input"
        },
        Modelo:{
          value:"abc123",
          value2:"123abc",
          validation:"",
          type: "input"
        },
        Color:{
          value:"abc123",
          value2:"123abc",
          validation:"",
          type: "input"
        },
        IdSeriePatente:{
          value:"abc123",
          value2:"123abc",
          validation:"",
          type: "input"
        },
        Detalles:{
          value:"abc123",
          value2:"123abc",
          validation:"",
          type: "input"
        },
        TieneSeguro:{
          value:"abc123",
          value2:"123abc",
          validation:"",
          type: "input"
        },
        NumeroChasis:{
          value:"abc123",
          value2:"123abc",
          validation:"",
          type: "input"
        },
        NumeroMotor:{
          value:"abc123",
          value2:"123abc",
          validation:"",
          type: "input"
        },
      },
      names: {
        "Descripcion":"",
        "Fechadeposito":"",
        "Nombre":"",
        "DenunciaID":"",
        "TipoefectoID":"",
        "Fechainivig":"",
        "Fechafinvig":null,
        "Tipo":"",
        "Marca":"",
        "Modelo":"",
        "Color":"",
        "IdSeriePatente":"",
        "Detalles":"",
        "TieneSeguro":"",
        "NumeroChasis":"",
        "NumeroMotor":""
      },
      errors:{
        "Descripcion":false,
        "Fechadeposito":false,
        "Fechafinvig":false,
        "Nombre":false,
        "DenunciaID":false,
        "TipoefectoID":false,
        "Fechainivig":false,
        "Tipo":false,
        "Marca":false,
        "Modelo":false,
        "Color":false,
        "IdSeriePatente":false,
        "Detalles":false,
        "TieneSeguro":false,
        "NumeroChasis":false,
        "NumeroMotor":false
      },
      mensajes:{
        "Descripcion":"",
        "Fechadeposito":"",
        "Nombre":"",
        "DenunciaID":"",
        "TipoefectoID":"",
        "Fechainivig":"",
        "Fechafinvig":"",
        "Tipo":"",
        "Marca":"",
        "Modelo":"",
        "Color":"",
        "IdSeriePatente":"",
        "Detalles":"",
        "TieneSeguro":"",
        "NumeroChasis":"",
        "NumeroMotor":""
      },
      design: [
        ["Fechadeposito"],
        ["Descripcion","DenunciaID"],
        ["Tipo","Marca","Modelo"],
        ["Color","IdSeriePatente","Detalles"],
        ["TieneSeguro","NumeroChasis","NumeroMotor"]
      ],
      designEdit: [
        ["Fechadeposito","Descripcion"],
        ["Tipo","Marca","Modelo"],
        ["Color","IdSeriePatente","Detalles"],
        ["TieneSeguro","NumeroChasis","NumeroMotor"]
      ],
      id:'',
      pageName: 'Efecto Denuncia',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/efecto-denuncia",
    }
  }
}
export const UsuarioConfig = {
  templateTable:{
    apiversion:'v2',
    addDropdownFilter: {
      dispatch: "siis/setDependencias",
      name: "DependenciaID",
      new:{
        name: "Dependencia",
        field:"DependenciaID",
        limit: 1
      },
      optionLabel: "dependencia",
      options: [],
      optionValue: "id",
      stateName:'dependencia',
    },
    data: null,
    disableButtonEdit:false,
    dispatch:"siis/setDependencias",
    enableAddButton: { enabled: true, name: "usuario-new" },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields: [
      { name: "NombreUsuario", field: "NombreUsuario" },
      { name: "Email", field: "Email" },
      { name: "Tipo de Usuario", field: "TipoUsuarioID" },
    ],
    idTable: 'UsuarioID',
    keyShow: [
      "Email",
      "NombreUsuario",
    ],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/usuario/edit",
    tableTitle:"Usuario",
    title: "Usuario",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/usuario",

  },
  // New - Edit
  form:{
    data:{
      valuesForDefault: { UserType: 1 },
      data: {
        showValidation: false,
        UserType: {
          validation: "required|max_length[150]",
          type: "dropdown",
          label: "Usuario",
          placeholder: "Ingrese el usuario",
          dropdown: {
            optionValue: "id",
            optionLabel: "name",
            options: [
              { id: 1, name: "Usuario Policial" },
              { id: 2, name: "Usuario Judicial" },
            ],
          },
        },
        Email: {
          value: "gonzalonvaldez@gmail.com",
          value2: "gonzalonvaldez2@gmail.com",
          validation: "required|max_length[50]|Email",
          placeholder: " Ingrese el email",
          type: "input",
          testId: "Email"
        },
        NombreUsuario: {
          value: "nombreusuario",
          label: "Nombre de Usuario",
          placeholder: "Ingrese el nombre de usuario ",
          value2: "otronombreusuario",
          validation: "required|max_length[50]",
          type: "input",
          testId: "NombreUsuario"
        },
        PersonaFisicaID: {
          value: "123abc",
          label: " ",
          buttonLabel: 'Buscar Persona Física',
          placeholder: "PersonaFisicaID",
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "1to1",
        },
        TipoUsuarioID: {
          value: "123abc",
          label: " ",
          buttonLabel: 'Buscar tipo de Usuario',
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "1to1",
        },
        DependenciaID: {
          value: "123abc",
          label: " ",
          buttonLabel: 'Buscar Dependencia',
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "1to1",
        },
        JuzgadoID: {
          value: "123abc",
          label: " ",
          buttonLabel: 'Buscar Juzgado',
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "1to1",
        },
        NumeroCredencial: {
          value: "123abc",
          label: "Número de Credencial",
          placeholder: "Ingrese número de credencial",
          value2: "abc123",
          validation: "required|max_length[50]",
          type: "input",
          testId: "NumeroCredencial"
        },
      },
      names: {
        Email: "",
        NombreUsuario: "",
        PersonaFisicaID: "",
        TipoUsuarioID: "",
        DependenciaID: "",
        JuzgadoID: "",
        NumeroCredencial: false,
      },
      errors: {
        Email: false,
        NombreUsuario: false,
        PersonaFisicaID: false,
        TipoUsuarioID: false,
        DependenciaID: false,
        NumeroCredencial: false,
        JuzgadoID: false,
      },
      mensajes: {
        Email: "",
        NombreUsuario: "",
        PersonaFisicaID: "",
        TipoUsuarioID: "",
        DependenciaID: "",
        NumeroCredencial:"",
        JuzgadoID: "",
      },
      design: [
        ["UserType","Email"],
        ["NombreUsuario"],
        ["PersonaFisicaID", "TipoUsuarioID"],
        ["DependenciaID", "JuzgadoID"],
        ["NumeroCredencial"],
      ],
      designEdit: [
        ["UserType","Email"],
        ["NombreUsuario"],
        ["PersonaFisicaID", "TipoUsuarioID"],
        ["DependenciaID", "JuzgadoID"],
        ["NumeroCredencial"],
      ],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/usuario",
      pageName: 'Usuario',
      datosDrop: {
        Email: "",
        NombreUsuario: "",
        PersonaFisicaID: "",
        TipoUsuarioID: "",
        DependenciaID: "",
        JuzgadoID: "",
      },
      UserTypeTitle: null,
    }
  },
};
export const DiligenciaConfig = {
  templateTable:{
    apiversion:'v2',
    addDropdownFilter: { 
      dispatch: "siis/setTipoDiligencia",
      name: "TiposumarioID",
      new:{
        name: "Tipo Actuación",
        field:"TiposumarioID",
        limit: 1
      },
      optionLabel: "TiposumarioID",
      options: [],
      optionValue: "TiposumarioID",
      stateName:'tipoDiligencia',
    },
    data: null,
    disableButtonEdit:true,
    //dropdown:{field:"TiposumarioID",placeholder:"Seleccione un tipo de actuacion...",options: ["Sumario Prevencional Por Denuncia","Expediente Contravencional por denuncia","Expediente Contravencional por oficio","Sumario Prevencional Por Oficio"]},
    enableAddButton: { enabled: true, name: "tipo-diligencia-new" , params: { id: 'new' } },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:false,
    filtersFields: [{ name: "Nombre", field: "Nombre"}],
    idTable: 'TipodiligenciaID',
    keyShow: ['Nombre','TiposumarioID', 'NombreUsuario', 'Fechainivig', 'Orden','Review'],
    titles: {
      TiposumarioID: "Actuación",
      Fechainivig: "Fecha de Creación"
    },
    loading: true,
    modal: false,
    multiSelect:true,
    ordenable:{url:`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-diligencia`,id:"TipodiligenciaID",variableOrden:"Orden"},
    reference: "parametros/tipo-diligencia/edit",
    secondFilter:"dropdownData",
    tableTitle:"Modelos de Diligencias",
    title: "Gestor de Diligencias",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/tipo-diligencia",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Descripcion:{
          value:"descripcion",
          value2: "otraDescripcion",
          validation:"",
          type: "input"
        },
        Fechainivig:{
          value:"2021-07-29",
          value2:"2021-07-30",
          validation:"required",
          type: "date"
        },
        Leyenda:{
          value:"descripcion",
          value2:"otraDescripcion",
          validation:"",
          type: "input"
        },
        Nombre:{
          value:"nicolas",
          value2:"gonzalo",
          validation:"required|max_length[50]",
          type: "input"
        },
        Orden:{
          value:123452,
          value2:698453,
          validation:"required|integer|max_length[11]",
          type: "input",
          testId:"inputOrden"
        },
        CaratulaID:{
          value:"abc123",
          value2:"123abc",
          validation:"required|max_length[50]",
          type: "input"
        },
        FormatoID:{
          value:"abc123",
          value2:"123abc",
          validation:"required|max_length[50]",
          type: "input"
        },
        TiposumarioID:{
          value:"abc123",
          value2:"123abc",
          validation:"required|max_length[90]",
          type: "input"
        },
        UsuarioID:{
          value:"abc123",
          value2:"123abc",
          validation:"required|max_length[50]",
          type: "input"
        },
        TiempoLimite:{
          value:0,
          value2:15,
          validation:"required",
          type: "slider",
          min:0,
          max:48,
          step:1,
          label:`tiempo limite`
        }
      },
      names: {
        Descripcion:"",
        Fechainivig:"",
        Leyenda:"",
        Nombre:"",
        Orden:"",
        CaratulaID:"",
        FormatoID:"",
        TiposumarioID:"",
        UsuarioID:"",
        TiempoLimite:0
      },
      errors:{
        Descripcion:false,
        Fechainivig:false,
        Leyenda:false,
        Nombre:false,
        Orden:false,
        CaratulaID:false,
        FormatoID:false,
        TiposumarioID:false,
        UsuarioID:false,
        TiempoLimite:false
      },
      mensajes:{
        Descripcion:"",
        Fechainivig:"",
        Leyenda:"",
        Nombre:"",
        Orden:"",
        CaratulaID:"",
        FormatoID:"",
        TiposumarioID:"",
        UsuarioID:"",
        TiempoLimite:""
      },
      design:[
        ["Nombre","Fechainivig"],
        ["Descripcion","Leyenda"],
        ["Orden","CaratulaID"],
        ["FormatoID","TiposumarioID"],
        ["UsuarioID"],["TiempoLimite"]
      ],
      designEdit:[
        ["Descripcion","Fechainivig","Leyenda"],
        ["Nombre","Orden"],["CaratulaID","FormatoID"],
        ["TiposumarioID","UsuarioID"],["TiempoLimite",""]
      ],
      id:'',
      label:{
        FechaLimite:"Tiempo Limite"
      },
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + '/parametros/tipo-diligencia',
      title:'Tipo Diligencia',
      pageName: 'Tipo Diligencia',
    }
  },
};

export const parametrosConfig = {
  templateTable:{
    title:'PARAMETRIZACIÓN',
  }
}

export const GestorArchivosConfig ={
  templateTable:{
    apiversion:'2',
    data: null,
    disableButtonEdit:false,
    enableAddButton:{ enabled: true, name: 'gestor-archivos-new' , params: { id: 'add' } },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filtersFields:[{ name: "Nombre", field: "Nombre" }],
    idTable: 'ArchivopdfID',
    keyShow:  ["Nombre", "Tipo", "Detalle", "Links"],
    loading: true,
    modal: false,
    reference:  "parametros/gestor-archivos/edit",
    tableTitle:"Gestor de Archivos",
    title: "Gestor de Archivos",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/files/archivopdf"
  },
  // New - Edit
  form:{
    data:{
      data: {
        Nombre: {
          validation: "required|max_length[100]",
          type: "input",
          label: "Nombre del archivo",
          placeholder: "Nombre del archivo",
        },
        Detalle: {
          validation: "max_length[100]",
          type: "input",
          label: "Detalle del archivo",
          placeholder: "Detalle del archivo",
        },
        Tipo: {
          validation: "max_length[150]",
          type: "dropdown",
          label: "Tipo de archivo",
          placeholder: "Tipo de archivo",
          dropdown: {
            optionValue: "Nombre",
            optionLabel: "Nombre",
            options: [],
          },
        },
        file: {
          validation: "required|min_length[1]",
          type: "FileUpload",
          upload: {
            fileExtension: "PDF",
            accept: ".pdf",
            maxFileSize: 15000000,
            multiple: false,
            enableWhenNew: true,
            uploadAndSave: true,
            enabledToUpdate: false,
            types: [
              "application/pdf"
            ],
          },
          label: "Subir PDF",
        },
        pdfLink: {
          type: "template"
        }
      },
      names: { Nombre:"",Detalle:"",Tipo: "",Links:""},
      design: [["Nombre"],["Tipo"],["Detalle"],],
      designEdit: [["Nombre"],["Tipo"],["Detalle"],["pdfLink"]],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/files/archivopdf",
      pageName: 'Archivo para la Biblioteca',
    }
  },
};

export const CategoriasTipoArchivoConfig ={
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:true,
    enableAddButton: { enabled: true, name: 'categorias-tipo-archivo-new'},
    enableEditButtons:true,
    filtersFields: [ { name: "nombre", field: "Nombre"},],
    idTable: 'CategoriatipoarchivopdfID',
    keyShow: ['Nombre', 'Detalle'],
    loading: true,
    modal: false,
    reference: "parametros/categorias-tipo-archivo/edit",
    secondFilter:"dropdownData",
    tableTitle:"Categoría de Tipo de Archivo PDF",
    title: "Categoría de Tipo de Archivo PDF",
    url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/categoriatipoarchivopdf",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Nombre: {
          validation: "max_length[50]",
          type: "input",
          label: "Nombre",
          placeholder: "Nombre de la categoría del tipo de archivo PDF",
        },
        Detalle: {
          validation: "max_length[200]",
          type: "input",
          label: "Detalle",
          placeholder: "Detalle de la categoría del tipo de archivo PDF",
        },
      },
      names: {
        Nombre: "",
        Detalle: "",
      },
      errors:{
        Nombre:false,
        Detalle:false,
      },
      mensajes:{
        Nombre: "El nombre de la categoría del tipo de archivo PDF es requerido",
        Detalle: "El detalle no puede ser mayor a 200 caracteres",
      },
      design:[
        ["Nombre"],["Detalle"],
      ],
      designEdit:[
        ["Nombre"],["Detalle"],
      ],
      id:'',
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/categoriatipoarchivopdf",
      title:'Categoría de Tipo de Archivo PDF',
      pageName: 'Categoría de tipo de archivo PDF',
    }
  },
};

export const DepartamentalConfig = {
  templateTable:{
    apiversion:'v2',
    data: null,
    disableButtonEdit:false,
    enableAddButton: { enabled: true, name:'departamental-new' },
    enableEditButtons:true,
    enableGlobalSearch: false,
    enableSelectAllCheckbox:true,
    filteredLocation: [],
    filtersFields: [
      { name: "Nombre de la Departamental", field: "Nombre" },
      { name: "Ubicacion", field: "Ubicacion" },
    ],
    idTable: 'DepartamentalID',
    keyShow: ['Nombre','Ubicacion'],
    loading: true,
    modal: false,
    multiSelect:true,
    reference: "parametros/departamental/edit",
    tableTitle:'Departamental',
    title: "Departamental",
    url:process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/departamental",
  },
  // New - Edit
  form:{
    data:{
      data: {
        Nombre: {
          value: "",
          value2: "",
          validation: "required|max_length[50]",
          type: "input"
        },
        Ubicacion: {
        value: "",
        value2: "",
        validation: "max_length[500]",
        type: "input"
        },
      },
      names: {
      Nombre: "",
      Ubicacion: "",
      },
      errors: {
        Nombre: false,
        Ubicacion: false,
      },
      mensajes: {
        Nombre: "",
        Ubicacion: "",
      },
      design: [
      ["Nombre"],
      ["Ubicacion"]
      ],
      designEdit: [
      ["Nombre"],
      ["Ubicacion"]
      ],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/departamental",
      pageName: "Departamental",
      //version 1.1
      labels: {
        Nombre: "Nombre de la Departamental"
      },
      placeholder: {
        Nombre: "Escribir el nombre de la Departamental"
      },
    }
  },
};
