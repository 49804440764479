//UFIs: 'ufi-establecer-paradero'      
export default {
    computed: {
        PreformUFIEstablecerParadero:{
            get (){
                let dateDenuncia = {
                    withStyles:   `<p style="color: #55b5ea ; display: inline">${this.dateDenuncia}</p>,`,
                    noStyles: this.dateDenuncia + ','
                }
               
                //changing the last character for a ';'
                dateDenuncia.withStyles =  dateDenuncia.withStyles.slice(0, -1)
                dateDenuncia.withStyles =  dateDenuncia.withStyles + ";"
                dateDenuncia.noStyles =  dateDenuncia.noStyles.slice(0, -1)
                dateDenuncia.noStyles =  dateDenuncia.noStyles + ";"
                
                let constance = {
                    withStyles:   ` el funcionario de Policía que suscribe, Jefe Titular de esta Dependencia, con intervención del Secretario de Actuaciones que Refrenda, a los fines legales <p style="text-decoration: underline ; display: inline"> HACE CONSTAR:</p> `,
                    noStyles: ` el funcionario de Policía que suscribe, Jefe Titular de esta Dependencia, con intervención del Secretario de Actuaciones que Refrenda, a los fines legales HACE CONSTAR: `,
                }
                
                let constanceText = {
                    withStyles:   `Que en la fecha y hora indicada, se hace presente el ciudadano: `,
                    noStyles: `Que en la fecha y hora indicada, se hace presente el ciudadano: `,
                }
                
                let people = {
                    withStyles:   this.dataResumen.denunciantes !== undefined ? `<p style="color: #1356cc ; display: inline">${this.dataResumen.denunciantes}</p>,` : '',
                    noStyles: this.dataResumen.denunciantes !== undefined ? ` ${this.dataResumen.denunciantes}, ` : '',
                }

                constanceText.noStyles = constanceText.withStyles  
                
                let dateAndTime ={ 
                    withStyles:   ` a fin de dar cuenta que desde fecha <p style="color: #1356cc ; display: inline">${this.dataResumen.fechaDelHecho1 ? this.dataResumen.fechaDelHecho1 + ' hora' : ''} ${this.dataResumen.horaDelHecho1 ? this.dataResumen.horaDelHecho1 + ' hs' : ''}</p>, `,
                    noStyles: ` a fin de dar cuenta que desde fecha ${this.dataResumen.fechaDelHecho1 ? this.dataResumen.fechaDelHecho1 + ' hora' : ''}${this.dataResumen.horaDelHecho1 ? this.dataResumen.horaDelHecho1 + ' hs' : ''}, `,
                }
                
                let lostPerson ={ 
                    withStyles:   `desconoce el paradero de: <p style="color: #1356cc ; display: inline"> ${this.dataResumen.acusados ? this.dataResumen.acusados : ''}</p>. `,
                    noStyles: `desconoce el paradero de:  ${this.dataResumen.acusados ? this.dataResumen.acusados : ''}. `,
                }

                if(this.textEditsCounter.Preform == 0)
                {
                    let Preform = {
                        withStyles: this.Preforma.noStyles  + this.department.noStyles + this.dependencyUFI.noStyles + dateDenuncia.noStyles +  constance.withStyles + constanceText.withStyles + people.withStyles + dateAndTime.withStyles + lostPerson.withStyles ,
                        noStyles: this.Preforma.noStyles +  this.department.noStyles + this.dependencyUFI.noStyles + dateDenuncia.noStyles + constance.noStyles + constanceText.noStyles + people.noStyles + dateAndTime.noStyles + lostPerson.noStyles 
                    };
                    return Preform
                }   else
                {   
                    let Preform = {
                        withStyles : "vacio por que no se muestra, es solo para que no de error por undefined",
                        noStyles : this.textEstaticos.Preform,
                    }
                    return Preform
                }
            }
        },
        instructionDecreeUFIEstablecerParadero:{
            get (){
                if(this.textEditsCounter.instructionDecree == 0){
                    let prosecutor ={ 
                        withStyles:   `En virtud de ello se mantuvo comunicación con el fiscal en turno <p style="color: #1356cc ; display: inline"> ${this.dataResumen.prosecutor ? this.dataResumen.prosecutor : ''}</p>, `,
                        noStyles: `En virtud de ello se mantuvo comunicación con el fiscal en turno  ${this.dataResumen.prosecutor ? this.dataResumen.prosecutor : ''}, `,
                    }

                    let finalPartWithUFINum ={ 
                        withStyles:   `quien interiorizado de lo acontecido, el mismo al respecto dispuso que se inicie protocolo de búsqueda de personas, que se le dé aviso a la sección búsqueda y rescate de personas y a la red interna de la policía. En virtud de ello la Instrucción se <b style='text-decoration: underline'>DISPONE:</b> Iniciar el Legajo de investigación que corresponda. Designar como secretario de las mismas al funcionario de Policía al pie firmante. Dar intervención a la U.F.I. Nº <p style="color: #1356cc ; display: inline">${this.ActuacionUfi == null ? '' : this.ActuacionUfi.Numero }</p> mediante la comunicación con el Ayudante Fiscal. Recibir, agregar y vincular a la causa, Acta de Procedimiento, a los fines legales correspondientes. Llevar a cabo toda otra medida de carácter legal que se estime de interés. CERTIFICO.-`,
                        noStyles: `quien interiorizado de lo acontecido, el mismo al respecto dispuso que se inicie protocolo de búsqueda de personas, que se le dé aviso a la sección búsqueda y rescate de personas y a la red interna de la policía. En virtud de ello la Instrucción se DISPONE: Iniciar el Legajo de investigación que corresponda. Designar como secretario de las mismas al funcionario de Policía al pie firmante. Dar intervención a la U.F.I. Nº ${this.ActuacionUfi == null ? '' : this.ActuacionUfi.Numero } mediante la comunicación con el Ayudante Fiscal. Recibir, agregar y vincular a la causa, Acta de Procedimiento, a los fines legales correspondientes. Llevar a cabo toda otra medida de carácter legal que se estime de interés. CERTIFICO.-`,
                    }

                    let instructionDecree = {
                        withStyles:  prosecutor.withStyles + finalPartWithUFINum.withStyles,
                        noStyles:   prosecutor.noStyles + finalPartWithUFINum.noStyles
                    }
                    return instructionDecree   
                }   else{       
                    let instructionDecree = {
                        noStyles:  this.textEstaticos.instructionDecree
                    }
                    return instructionDecree                
                }             
            },
        },   
        actOfStartUFIEstablecerParadero:{
            get (){
                let actOfStart = this.Preform.noStyles + ' ' + this.deposition + '. ' + this.instructionDecree.noStyles
                return actOfStart 
            }
        } 
    }    
};