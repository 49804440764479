import {isEqualExcept} from "./editMode"
import store from '../../index.js'
import dateMixin from '@/mixins/dateMixin';

const prepareDoc = (state)=>{
  try {
    var tipoSumario
    tipoSumario = Object.keys(store.state.sumarioViews[state.formType]?.actuationName)[0];
    
    
    // console.log("tipoSumario: ",tipoSumario);
    //Creamos un Array para cargar los Denunciantes, ya que estos pueden ser 0 o muchos
    var DenunciantesList = []
    //Quitamos el ultimo elemento, por que no queremos se cargue el dato que viene vacio del denunciante. Encepto en contravencional-oficio que altera el orden
    var aux = parseInt(state.denunciante.list.length) -1
    var indDenunciante = 0
    for (let ind = indDenunciante; ind < aux; ind++) {
      let domicilioSipe = state.denunciante.list[ind].doc.domicilioSIPE != undefined && state.denunciante.list[ind].doc.domicilioSIPE != '' && state.denunciante.list[ind].doc.domicilioSIPE != null ? state.denunciante.list[ind].doc.domicilioSIPE  : ''
      let domicilio = state.denunciante.list[ind].doc.domicilio != undefined && state.denunciante.list[ind].doc.domicilio != '' && state.denunciante.list[ind].doc.domicilio != null ? state.denunciante.list[ind].doc.domicilio  : ''
      if (state.denunciante.list[ind].ordenPublico == true) {
        var arre = [
          //cargamos la tabla Rolesdenuncia
          { "Rol": tiposRoles(state.formType, "DenunciantesList") , "Descripcion": tiposRoles(state.formType, "DenunciantesList")+" de la actuacion N°" + state.causaNumero },//Al ser Orden publico siempre se asume que es Damnificado
          //en este caso Personafisica no se carga
          {
            "Apellido": typeof (state.denunciante.list[ind].doc.apellido) === 'undefined' || state.denunciante.list[ind].doc.apellido == "" ? "Orden Publico" : state.denunciante.list[ind].doc.apellido,
            "Nombre": typeof (state.denunciante.list[ind].doc.nombre) === 'undefined' ? "" : state.denunciante.list[ind].doc.nombre, "Telefono": state.denunciante.list[ind].doc.telefono,
            "Dni": typeof (state.denunciante.list[ind].doc.documento) === 'undefined' ? "" : state.denunciante.list[ind].doc.documento, "Nacionalidad": state.denunciante.list[ind].doc.nacionalidad.nombre,
            "Sexo": typeof (state.denunciante.list[ind].doc.sexo) === 'undefined' ? "" : state.denunciante.list[ind].doc.sexo.nombre,
            "EstadoCivil": (typeof (state.denunciante.list[ind].doc.estadoCivil) === 'undefined' || state.denunciante.list[ind].doc.estadoCivil === null) ? "" : state.denunciante.list[ind].doc.estadoCivil.nombre, "FechaDeNacimiento": state.denunciante.list[ind].doc.fechaNacimiento,
            "ExhibeDocumento": (typeof (state.denunciante.list[ind].doc.exhibeDocumento) === 'undefined' || state.denunciante.list[ind].doc.exhibeDocumento === null) ? 1 : state.denunciante.list[ind].doc.exhibeDocumento.id, 
            "Observaciones": (typeof (state.denunciante.list[ind].doc.observaciones) === 'undefined' || state.denunciante.list[ind].doc.observaciones === null) ? "" : state.denunciante.list[ind].doc.observaciones, 
            "TieneEstudios": (typeof (state.denunciante.list[ind].doc.tieneEstudios) === 'undefined' || state.denunciante.list[ind].doc.tieneEstudios === null) ? 1 : state.denunciante.list[ind].doc.tieneEstudios.id, 
            "Cuil": (typeof (state.denunciante.list[ind].doc.cuil) === 'undefined' || state.denunciante.list[ind].doc.cuil === null) ? "" : state.denunciante.list[ind].doc.cuil, 
            "Vinculo": (typeof (state.denunciante.list[ind].doc.vinculo) === 'undefined' || state.denunciante.list[ind].doc.vinculo === null) ? "" : state.denunciante.list[ind].doc.vinculo, 
            "FechaDeNacimiento": state.denunciante.list[ind].doc.fechaNacimiento,
            "FechaDeNacimiento": typeof (state.denunciante.list[ind].doc.fechaNacimiento) === 'undefined' ? "----" : state.denunciante.list[ind].doc.fechaNacimiento.slice(0, 10),//esto se lo agregamos por que SIPE suele traer algunas fechas con datos extra(Ej: hora_T),
            "Instruccion": state.denunciante.list[ind].doc.instruccion.nombre,
            "Profesion": state.denunciante.list[ind].doc.profesion, "DomicilioSipe": state.denunciante.list[ind].doc.domicilioSIPE,// "TipodocumentoID": 123,
            "PersonaJuridica": typeof (state.denunciante.list[ind].doc.personaJuridica) === 'undefined' ? "" : state.denunciante.list[ind].doc.personaJuridica,
            "Cuit": typeof (state.denunciante.list[ind].doc.cuit) === 'undefined' ? "" : state.denunciante.list[ind].doc.cuit,
            "Denominacion": typeof (state.denunciante.list[ind].doc.denominacion) === 'undefined' ? "" : state.denunciante.list[ind].doc.denominacion,
            "DomicilioJuridico": typeof (state.denunciante.list[ind].doc.domicilioJuridico) === 'undefined' ? "" : state.denunciante.list[ind].doc.domicilioJuridico,
            "Rubro": typeof (state.denunciante.list[ind].doc.rubro) === 'undefined' ? "" : state.denunciante.list[ind].doc.rubro,
            "TelefonoJuridico": typeof (state.denunciante.list[ind].doc.telefonoJuridico) === 'undefined' ? "" : state.denunciante.list[ind].doc.telefonoJuridico,
            "TieneSeguro": typeof (state.denunciante.list[ind].doc.tieneSeguro) === 'undefined' ? '0' : state.denunciante.list[ind].doc.tieneSeguro,
          },
            //cargamos la tabla ActuacionPersonaFisica
          { "Orden": ind, "PersonaFisicaID": "Orden público" }
        ]
      } else {
        // console.log("state.denunciante.list[ind].doc.condicion", state.denunciante.list[ind].doc.condicion)
        var arre = [
          //cargamos la tabla Rolesdenuncia
          // { "Rol": typeof (state.denunciante.list[ind].doc.condicion) === 'undefined' ? "Damnificado" : state.denunciante.list[ind].doc.condicion.nombre, },
          { "Rol": tiposRoles(state.formType, "DenunciantesList",state.denunciante.list[ind].doc.condicion)},
          //cargamos la tabla Personafisica
          {
            "KeyPersonaFisicaFrontend":state.denunciante.list[ind].doc.arrId,
            "Apellido": typeof (state.denunciante.list[ind].doc.apellido) === 'undefined' ? "" : state.denunciante.list[ind].doc.apellido,
            "Nombre": typeof (state.denunciante.list[ind].doc.nombre) === 'undefined' ? "" : state.denunciante.list[ind].doc.nombre, "Telefono": state.denunciante.list[ind].doc.telefono,
            "CorreoElectronico": typeof(state.denunciante.list[ind].doc.email) === 'undefined' ? "" : state.denunciante.list[ind].doc.email,
            "Dni": typeof (state.denunciante.list[ind].doc.documento) === 'undefined' ? "" : state.denunciante.list[ind].doc.documento, "Nacionalidad": state.denunciante.list[ind].doc.nacionalidad.nombre,
            "Sexo": typeof (state.denunciante.list[ind].doc.sexo) === 'undefined' ? "" : state.denunciante.list[ind].doc.sexo.nombre,
            "EstadoCivil": (typeof (state.denunciante.list[ind].doc.estadoCivil) === 'undefined' || state.denunciante.list[ind].doc.estadoCivil === null) ? "" : state.denunciante.list[ind].doc.estadoCivil.nombre, "FechaDeNacimiento": state.denunciante.list[ind].doc.fechaNacimiento,
            "ExhibeDocumento": (typeof (state.denunciante.list[ind].doc.exhibeDocumento) === 'undefined' || state.denunciante.list[ind].doc.exhibeDocumento === null) ? 1 : state.denunciante.list[ind].doc.exhibeDocumento.id, 
            "Observaciones": (typeof (state.denunciante.list[ind].doc.observaciones) === 'undefined' || state.denunciante.list[ind].doc.observaciones === null) ? "" : state.denunciante.list[ind].doc.observaciones, 
            "TieneEstudios": (typeof (state.denunciante.list[ind].doc.tieneEstudios) === 'undefined' || state.denunciante.list[ind].doc.tieneEstudios === null) ? 1 : state.denunciante.list[ind].doc.tieneEstudios.id, 
            "Cuil": (typeof (state.denunciante.list[ind].doc.cuil) === 'undefined' || state.denunciante.list[ind].doc.cuil === null) ? "" : state.denunciante.list[ind].doc.cuil, 
            "Vinculo": (typeof (state.denunciante.list[ind].doc.vinculo) === 'undefined' || state.denunciante.list[ind].doc.vinculo === null) ? "" : state.denunciante.list[ind].doc.vinculo, 
            "FechaDeNacimiento": typeof (state.denunciante.list[ind].doc.fechaNacimiento) === 'undefined' ? "----" : state.denunciante.list[ind].doc.fechaNacimiento.slice(0, 10),//esto se lo agregamos por que SIPE suele traer algunas fechas con datos extra(Ej: hora_T),
            "Instruccion": state.denunciante.list[ind].doc.instruccion.nombre,
            "Profesion": state.denunciante.list[ind].doc.profesion,
            "DomicilioSipe": domicilio != '' ?  domicilio : domicilioSipe,// "TipodocumentoID": 123,
            "PersonaJuridica": typeof (state.denunciante.list[ind].doc.personaJuridica) === 'undefined' ? "" : state.denunciante.list[ind].doc.personaJuridica,
            "Cuit": typeof (state.denunciante.list[ind].doc.cuit) === 'undefined' ? "" : state.denunciante.list[ind].doc.cuit,
            "Denominacion": typeof (state.denunciante.list[ind].doc.denominacion) === 'undefined' ? "" : state.denunciante.list[ind].doc.denominacion,
            "DomicilioJuridico": typeof (state.denunciante.list[ind].doc.domicilioJuridico) === 'undefined' ? "" : state.denunciante.list[ind].doc.domicilioJuridico,
            "Rubro": typeof (state.denunciante.list[ind].doc.rubro) === 'undefined' ? "" : state.denunciante.list[ind].doc.rubro,
            "TelefonoJuridico": typeof (state.denunciante.list[ind].doc.telefonoJuridico) === 'undefined' ? "" : state.denunciante.list[ind].doc.telefonoJuridico,
            "CodigosCoelsa": typeof (state.denunciante.list[ind].doc.codigosCoelsa) === 'undefined' ? [] : state.denunciante.list[ind].doc.codigosCoelsa,
            "Plataformas": typeof (state.denunciante.list[ind].doc.plataformas) === 'undefined' ? [] : state.denunciante.list[ind].doc.plataformas,
            "TieneSeguro": typeof (state.denunciante.list[ind].doc.tieneSeguro) === 'undefined' ? '0' : state.denunciante.list[ind].doc.tieneSeguro,
          },
          //cargamos la tabla ActuacionPersonaFisica
          { "Orden": ind }
        ]
      }
      DenunciantesList.push(arre);
    }

    //Creamos un Array para cargar los Acusados, ya que estos pueden ser 0 o muchos
    var AcusadosList = []
    //acusados.list viene con un dato de mas...por eso le restamos uno
    var aux = parseInt(state.acusados.list.length) - 1
    for (let ind = 0; ind < aux; ind++) {

      let domicilioSipe = state.acusados.list[ind].doc.domicilioSIPE != undefined && state.acusados.list[ind].doc.domicilioSIPE != '' && state.acusados.list[ind].doc.domicilioSIPE != null ? state.acusados.list[ind].doc.domicilioSIPE  : ''
      let domicilio = state.acusados.list[ind].doc.domicilio != undefined && state.acusados.list[ind].doc.domicilio != '' && state.acusados.list[ind].doc.domicilio != null ? state.acusados.list[ind].doc.domicilio  : ''
      const arreDesconocido=[
        //cargamos la tabla Rolesdenuncia
        { "Rol": tiposRoles(state.formType, "AcusadoList"), "Descripcion": tiposRoles(state.formType, "AcusadoList")+" del sumario N°" + state.causaNumero },
        //en este caso Personafisica no se carga
        { "Apellido": "Persona de Filiacion desconocida", 
          "Identikit": state.acusados.list[ind].identikit.length > 0 ? state.acusados.list[ind].identikit : state.acusados.list[ind].descriptionOffender},
        //cargamos la tabla ActuacionPersonaFisica
        { "Orden": ind, "PersonaFisicaID": "Persona de Filiacion desconocida" },       
      ]
        if (state.acusados.list[ind].filiacionDesconocida == true) {
          var arre = arreDesconocido
        } else {
          const personafisica={
            "KeyPersonaFisicaFrontend":state.acusados.list[ind].doc.arrId,
            "Apellido": state.acusados.list[ind].doc.apellido,
            "Nombre": state.acusados.list[ind].doc.nombre, 
            "Telefono": state.acusados.list[ind].doc.telefono,
            "Dni": state.acusados.list[ind].doc.documento, 
            "Nacionalidad": state.acusados.list[ind].doc.nacionalidad.nombre,
            "Sexo": typeof (state.acusados.list[ind].doc.sexo) === 'undefined' ? "" : state.acusados.list[ind].doc.sexo.nombre,
            "EstadoCivil": (typeof (state.acusados.list[ind].doc.estadoCivil) === 'undefined' || state.acusados.list[ind].doc.estadoCivil === null) ? "" : state.acusados.list[ind].doc.estadoCivil.nombre,
            "ExhibeDocumento": (typeof (state.acusados.list[ind].doc.exhibeDocumento) === 'undefined' || state.acusados.list[ind].doc.exhibeDocumento === null) ? 1 : state.acusados.list[ind].doc.exhibeDocumento.id, 
            "Observaciones": (typeof (state.acusados.list[ind].doc.observaciones) === 'undefined' || state.acusados.list[ind].doc.observaciones === null) ? "" : state.acusados.list[ind].doc.observaciones, 
            "TieneEstudios": (typeof (state.acusados.list[ind].doc.tieneEstudios) === 'undefined' || state.acusados.list[ind].doc.tieneEstudios === null) ? 1 : state.acusados.list[ind].doc.tieneEstudios.id, 
            "Cuil": (typeof (state.acusados.list[ind].doc.cuil) === 'undefined' || state.acusados.list[ind].doc.cuil === null) ? "" : state.acusados.list[ind].doc.cuil, 
            "FechaDeNacimiento": (typeof (state.acusados.list[ind].doc.fechaNacimiento) === 'undefined' || state.acusados.list[ind].doc.fechaNacimiento === null) ? "" : state.acusados.list[ind].doc.fechaNacimiento.slice(0, 10),
            "Instruccion": state.acusados.list[ind].doc.instruccion.nombre, 
            "Profesion": state.acusados.list[ind].doc.profesion,
            "DomicilioSipe": domicilio != '' ?  domicilio : domicilioSipe, //"TipodocumentoID": 123,
            "PersonaJuridica": typeof (state.acusados.list[ind].doc.personaJuridica) === 'undefined' ? "" : state.acusados.list[ind].doc.personaJuridica,
            "Cuit": typeof (state.acusados.list[ind].doc.cuit) === 'undefined' ? "" : state.acusados.list[ind].doc.cuit,
            "Denominacion": typeof (state.acusados.list[ind].doc.denominacion) === 'undefined' ? "" : state.acusados.list[ind].doc.denominacion,
            "DomicilioJuridico": typeof (state.acusados.list[ind].doc.domicilioJuridico) === 'undefined' ? "" : state.acusados.list[ind].doc.domicilioJuridico,
            "Rubro": typeof (state.acusados.list[ind].doc.rubro) === 'undefined' ? "" : state.acusados.list[ind].doc.rubro,
            "TelefonoJuridico": typeof (state.acusados.list[ind].doc.telefonoJuridico) === 'undefined' ? "" : state.acusados.list[ind].doc.telefonoJuridico,
            "Apodo": typeof (state.acusados.list[ind].doc.apodo) === 'undefined' ? "" : state.acusados.list[ind].doc.apodo,
            "Identikit": state.acusados.list[ind].identikit.length > 0 ? state.acusados.list[ind].identikit : state.acusados.list[ind].descriptionOffender,
            "Plataformas": typeof (state.acusados.list[ind].doc.plataformas) === 'undefined' ? [] : state.acusados.list[ind].doc.plataformas,
            "CorreoElectronico": typeof(state.acusados.list[ind].doc.email) === 'undefined' ? "" : state.acusados.list[ind].doc.email,
            "TieneSeguro": typeof (state.acusados.list[ind].doc.tieneSeguro) === 'undefined' ? '0' : state.acusados.list[ind].doc.tieneSeguro,
          }
          
          var arre = [
            //cargamos la tabla Rolesdenuncia
              { "Rol": tiposRoles(state.formType, "AcusadoList",state.acusados.list[ind].doc.condicion), "Descripcion": tiposRoles(state.formType, "AcusadoList",state.acusados.list[ind].doc.condicion)+" del sumario N°" + state.causaNumero },
            //cargamos la tabla Personafisica
          personafisica,
            //cargamos la tabla ActuacionPersonaFisica
            { "Orden": ind },
          ]
          if (PersonaVacia(personafisica))  {
          arre=arreDesconocido
          }
        }
        AcusadosList.push(arre);
    }
    //Creamos un Array para cargar los Efectos , ya que estos pueden ser 0 o muchos
    var EfectosList = []

    //robados.list viene con un dato de mas...por eso le restamos uno
    var aux = parseInt(state.robados.list.length) - 1
    for (let ind = 0; ind < aux; ind++)
    {
      // console.log("/////: ", state.robados.list[ind])

      var arre =
      // [
        //cargamos la tabla Efectos
        
          {
            "KeyEfectoFisicaFrontend":state.robados.list[ind].arrId,
            "CategoriaID": typeof (state.robados.list[ind].doc.CategoriaID) === 'undefined' ? "" : state.robados.list[ind].doc.CategoriaID,
            "SubcategoriaID": typeof (state.robados.list[ind].doc.SubcategoriaID) === 'undefined' ? "" : state.robados.list[ind].doc.SubcategoriaID,
            "TipoID": typeof (state.robados.list[ind].doc.TipoID) === 'undefined' ? "" : state.robados.list[ind].doc.TipoID,
            "MarcaID": typeof (state.robados.list[ind].doc.MarcaID) === 'undefined' ? "" : state.robados.list[ind].doc.MarcaID,
            "ModeloID": typeof (state.robados.list[ind].doc.ModeloID) === 'undefined' ? "" : state.robados.list[ind].doc.ModeloID,
            "Anio": typeof (state.robados.list[ind].doc.Anio) === 'undefined' ? "" : state.robados.list[ind].doc.Anio, 
            "Chasis": typeof (state.robados.list[ind].doc.Chasis) === 'undefined' ? "" : state.robados.list[ind].doc.Chasis,
            "NumeroMotor": typeof (state.robados.list[ind].doc.NumeroMotor) === 'undefined' ? "" : state.robados.list[ind].doc.NumeroMotor,
            "Dominio": typeof (state.robados.list[ind].doc.Dominio) === 'undefined' ? "" : state.robados.list[ind].doc.Dominio,
            "NumeroSerie": typeof (state.robados.list[ind].doc.NumeroSerie) === 'undefined' ? "" : state.robados.list[ind].doc.NumeroSerie,
            "Imei": typeof (state.robados.list[ind].doc.Imei) === 'undefined' ? "" : state.robados.list[ind].doc.Imei,
            "Abonado": typeof (state.robados.list[ind].doc.Abonado) === 'undefined' ? "" : state.robados.list[ind].doc.Abonado,
            "Color": typeof (state.robados.list[ind].doc.Color) === 'undefined' ? "" : state.robados.list[ind].doc.Color,
            "tipoEfecto": typeof (state.robados.list[ind].doc.tipoEfecto) === 'undefined' ? "" : state.robados.list[ind].doc.tipoEfecto.id,
            "EstadoEfectoActuacionID": state.robados.list[ind].doc.tipoEfecto.nombre,
          }
      //  ,
      //   //cargamos la tabla exp_pre_ActuacionEfecto
      //   {
      //     "EstadoEfectoActuacionID": state.robados.list[ind].doc.tipoEfecto.nombre,
      //   }
      // ]
      const emptyEffect={
        "CategoriaID": "",
        "SubcategoriaID": "",
        "TipoID": "",
        "MarcaID": "",
        "ModeloID": "",
        "Anio": "",
        "Chasis": "",
        "NumeroMotor": "",
        "Dominio": "",
        "NumeroSerie": "",
        "Imei": "",
        "Abonado": "",
        "Color": "",
        "tipoEfecto": "Secuestrado",
        "EstadoEfectoActuacionID": "Secuestrado"
      }
      if (!isEqualExcept(arre,emptyEffect,["tipoEfecto","EstadoEfectoActuacionID"]))  {
        EfectosList.push(arre);
      }else{
        console.log('se descarto el efecto :',arre);
      }
    }
    let stateCopy =  { ...state }
    delete stateCopy.views //al state que le mandamos le borramos el .views por que genera un error en la API
    //definimos el objeto que le mandamos a la API
    // console.log('state.causaCaratula', state.causaCaratula)
    var objSumarioPost = {
      "Denuncia": {
        "Fechacreacion": dateMixin.methods.generateDate(new Date), "Fechaenvio": "",
        "Nombre": (!state.formType.includes('contravencional') && (state.causaCaratula != null)) ? state.causaCaratula.Nombre : "",
        "Relato":  state.deposition,
        "TipodenunciaID": tipoSumario
      },
      // "Diligencia":{"Contenido":"","Descripcion":"","Fechacreacion":"","Fechafinvig":"","Fechainivig":"","Numero":"","Orden":"","TipodiligenciaID":""},
      "Sumario": {
        "Fechafin": "", "Numero": state.causaNumero, "TiposumarioID": tipoSumario,"FechaCreacion": dateMixin.methods.generateDate(state.fechaDenuncia),

        "CausaCaratula": state.causaCaratula != null ? state.causaCaratula.Nombre : "",
      },//para post se espesifica numero de sumario para edit no
      // "Identikit": {"Fechainivigidentikit":state.fechaCertificadoDenuncia,"Fechafinvigidentikit":"","Numeroidentikit":""}.
      "Denunciante": DenunciantesList,
      "Acusado": AcusadosList,
      "MomentoUbicacion": [{
        "MomentoFechaHoraDesde": typeof (state.moment.date) === 'undefined' ? "" : dateMixin.methods.generateDate(state.moment.date),
        "MomentoFechaHoraHasta": typeof (state.moment.date2) === 'undefined' ? "" : dateMixin.methods.generateDate(state.moment.date2),
        "Calle": typeof (state.preUbicacion.calle) === 'undefined' ? "" : state.preUbicacion.calle,
        "Numero": typeof (state.preUbicacion.numero) === 'undefined' ? "" : state.preUbicacion.numero,
        "Departamento": typeof (state.preUbicacion.departamento) === 'undefined' ? "" : state.preUbicacion.departamento.nombre,
        "Casa": typeof (state.preUbicacion.casa) === 'undefined' ? "" : state.preUbicacion.casa,
        "Orientacion": typeof (state.preUbicacion.orientacion) === 'undefined' ? "" : state.preUbicacion.orientacion.nombre,
        "Sector": typeof (state.preUbicacion.sector) === 'undefined' ? "" : state.preUbicacion.sector, "Piso": typeof (state.preUbicacion.piso) === 'undefined' ? "" : state.preUbicacion.piso,
        "Barrio": typeof (state.preUbicacion.barrio) === 'undefined' ? "" : state.preUbicacion.barrio, "Zona": typeof (state.preUbicacion.zona) === 'undefined' ? "" : state.preUbicacion.zona,
        "Longitud":typeof (state.preUbicacion.coordinates) === 'undefined' ? "" : state.preUbicacion.coordinates.lng, 
        "Latitud":typeof (state.preUbicacion.coordinates) === 'undefined' ? "" : state.preUbicacion.coordinates.lat, 
        }],                  
      "ModusOperandi": {
        "Descripcion": "Modus operandi del sumario N°" + state.causaNumero,
        "Nombre": state.modusOperandi == null ? "" : state.modusOperandi.Nombre
      },
      "Juzgadointerviniente": { "Descripcion": "", 
        "Nombre": state.causaJuzgado != null ? state.causaJuzgado.Nombre : '', 
        "JuzgadoID" : state.causaJuzgado != null ? state.causaJuzgado.JuzgadoID : ''},
      "Efectos": EfectosList,
      "AfectacionPatrimonial": state.afectacionPatrimonial.list?.map(item => ({
        "fecha": item?.doc.fecha || "",
        "monto": item?.doc.monto || "",
        "tipoMoneda": item?.doc.tipoMoneda || "",
        "identificadorOrigen": item?.doc.identificadorOrigen || "",
        "identificadorDestino": item?.doc.identificadorDestino || "",
        "afectadoId": item?.doc.afectadoId || null,
        "vinculadoId": item?.doc.vinculadoId || null,
        "esPropietario": item?.doc.esPropietario || null,
        "observaciones": item?.doc.observaciones || "",
        "NroTarjeta": item?.doc.NroTarjeta || null,
        "entidadBancaria": item?.doc.entidadBancaria || null,
        "bienEntregado": item?.doc.bienEntregado || null,
        "tipo": item?.doc.tipo || "",
        "Dni":item?.doc.dni|| "",
        "NombreCompleto":item?.doc.nombreCompleto|| ""
      })) || [],
      "ActuacionArticulo": { "Articulos": state.listaArticulos },
      "Sumariocausacartula": { "Caratulas": state.listaCaratulas.length > 0 ? state.listaCaratulas : [state.causaCaratula]},
      "ActuacionUfi": {"TipoufiID": typeof (state.ActuacionUfi?.TipoufiID) === 'undefined' ? "" : state.ActuacionUfi?.TipoufiID,
                      "Numero": typeof (state.ActuacionUfi?.Numero) === 'undefined' ? "" : state.ActuacionUfi?.Numero},   
      "PersonalInterviniente": { "DatosPersonalInterviniente": state.PersonalInterviniente },
      "PersonalFiscal": state.prosecutor?.PersonalfiscalID !== undefined  && state.assistant?.PersonalfiscalID !== undefined ? [ 
        {"PersonalfiscalID": state.prosecutor.PersonalfiscalID, "Jerarquia": "Fiscal"},
        {"PersonalfiscalID": state.assistant.PersonalfiscalID, "Jerarquia": "Ayudante"}
      ] : [],
      //Aca hay que cargar la informacion del sitio Cuando se haya desarrollado dicha parte
      "Sitio":state?.Sitio?.TipoSitioID !== undefined ?
      {"TipoSitioID" :  state.Sitio.TipoSitioID }: null,
      "State": stateCopy//state//Enviamos todo el state al post para que quede registro de los datos ingresados en los Logs
    }
    // console.log("state.ActuacionUfi?.TipoufiID: ", state.ActuacionUfi);
    return Promise.resolve(objSumarioPost)
  }
  catch(e){
    console.error("prepareDoc error", e)
    return Promise.reject()
  }
}
function PersonaVacia(personafisica) {
   const vacia = {
    Apodo: "",
    Cuit: "",
    Denominacion: "",
    Dni: undefined,
    DomicilioJuridico: "",
    DomicilioSipe: "",
    EstadoCivil: "",
    FechaDeNacimiento: "",
    Identikit: [],
    Instruccion: "Sabe leer y sabe firmar",
    Nacionalidad: "ARGENTINA",
    PersonaJuridica: "",
    Profesion: undefined,
    Rubro: "",
    Sexo: "",
    Telefono: undefined,
    TelefonoJuridico: "",
    Apellido: undefined,
    Nombre: undefined
  };
  
  return !Object.entries(vacia).some(([key, invalidValue]) => {
    const actualValue = personafisica[key];

    // Special handling for Identikit if it's an object with a 'value' array
    if (key === 'Identikit' && actualValue && typeof actualValue === 'object') {
      return Array.isArray(actualValue.value) && actualValue.value.length > 0;
    }

    // General handling for other types
    return actualValue !== invalidValue && actualValue !== undefined && actualValue !== "";
  });
}

function tiposRoles(tipoActuacion, tipoLista, seleccion = null){
  if(seleccion != null && typeof (seleccion) != 'undefined'){
    return seleccion.nombre
  }else{
    return store.state.sumarioViews[tipoActuacion].roleType[tipoLista];   
  }
}

  const includesWords = (array)=>{
    let i = 0;
    while( i < array.length && !state.formType.includes(array[i])){
    i++;
    }
    return i< array.length ? true : false;
  }  


export {
    prepareDoc,
    includesWords
}