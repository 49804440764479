export const preliminarTentativaConfig = [
     
    {
        path: "/actuaciones/new/preliminares-suicidio/initial",
        pathName: "preliminares-suicidio",
        titulo: "TENTATIVA DE SUICIDIO",
        props: { formType: "preliminar-tentativa-suicidio", actuacionType:"preliminares-suicidio"},
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/preliminares-suicidio/initial/denunciante",
        pathName: "preliminares-suicidio",
        titulo: "AFECTADOS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/preliminares-suicidio/initial/momento",
        pathName: "preliminares-suicidio",
        titulo: "FECHA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/preliminares-suicidio/initial/acusado",
        pathName: "preliminares-suicidio",
        titulo: "VINCULADOS",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/preliminares-suicidio/initial/efectos",
        pathName: "preliminares-suicidio",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]