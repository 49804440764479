import Denunciante from "@/store/modules/sumario/denunciante";
import generalFunctions from "../generalFunctions";

const basicViewData = (params) => ({
  label: {},
  actuacionType: "",
  mainForm: {
    path: "/actuaciones/" + params.routeMainForm,
  },
  Acusados: {
    editPath: "/actuaciones/initial-data/acusado",
  },
  LugarMomento: {
    editPath: "/sumario/denuncia/formulario/momento",
  },
  cardEfectos: {
    title: "Efectos",
  },
  cardPatrimonialAffectation: {
    title: "Afectación Patrimonial",
    show: false,
  },
  cardAcusado: {
    title: "Vinculados",
    show: true,
  },
  cardDenunciante: {
    title: "Afectados",
  },
  saveButton: {
    path: "/sumario/denuncia/formulario/datos",
  },
  formAcusados: {
    testIdButtonSave: "buttonSaveAcusado",
  },
  startActType: {
    title: "Denuncia contravencional",
    textDiligenciaTitulos: true,
  },
  //for add a new postform type, is necessary add the 'TextBook', the 'Text Flats' and the 'textEdit Counter'too
  postform: {
    titleLegalData: "Infracción y Juzgado Interviniente",
    showOnlyAtLegalData: true,
    type: "LegalData",
    show: true,
  },
  legalData: {
    causaNumero: {
      title: "N° de expediente / N° de extracto",
      placeholder: "N° expediente/extracto",
    },
    showModusOperandi: false,
    showRelatedArticles: true,
    showCrime: false,
    showJudgedIntervener: true,
    showCausaCaratula: false,
    showUFI: false,
    fiscalInCharge: false,
    fiscalAssistant: false,
  },
  formPersona: {
    // conditionDefault: '',
    condition: {
      denunciante: "condicionList",
      acusados: "",
    },
    // hidden: [],
    whistleblower: {
      emptyTitle: "Nuevo Afectado",
    },
    accused: {
      emptyTitle: "Nuevo acusado",
    },
  },
  personCondition: {
    denunciante: { id: "Denunciante y Damnificado", nombre: "Denunciante y Damnificado" },
    acusados: { id: "Acusado", nombre: "Acusado" },
  },
  contraventional: false,
  showError: false,
  showValidation: false,
  calendarDenuncia: false,
  formLugarMomentoError: false,
  cardAcusadosDelete: true,
  cardAcusadosWarnings: false,
  cardObjetosWarnings: false,
  formPersonalInterviniente: false,
  botonContinuar: false,
  cardObjetosDelete: false,
  cardAcusadosDeleteWarnings: false,
  hasFormDatosLegales: false,
  hasButtonNext: false,
  hasTextareaDenuncia: false,
  FormDatosLegalesStyles: false,
  stylesLightBluePersonas: true,
  checkboxPublicOrder: false,
  preformTitle: true,
  showDeposition: true,
  formDenunciaDeleteButton: {
    show: true,
    whistleblowerText: "denunciante o damnificado",
    accusedText: "contraventor",
  },
  objetos: {
    type: "objetosAllTypes",
  },
  mixedFormDatosLegalesDenunciaFormDatos: false,
  showPeoplesAlertas: true,
  showFormLugarMomentoAlertas: false,
  resumenCardPassed: false,
  resumenCardWarning: false,
  personConditionDefault: { vinculated: { id: "Acusado", nombre: "Acusado" }, affected: false },
});
const basicUfiViewData = (params) => ({
  ...basicViewData(params),
  cardDenunciante: {
    title: "Afectados",
  },
  formDenunciaDeleteButton: {
    show: true,
    whistleblowerText: "víctima o damnificado",
    accusedText: "aprehendido",
  },
  cardAcusado: {
    title: "Vinculados",
    show: true,
  },
  // cardEfectos: {
  //   title: 'Efectos'
  // },
  startActType: {
    title: "ACTA DE INICIO LEGAJO",
    textDiligenciaTitulos: false,
  },
  postform: {
    titleLegalData: "",
    showOnlyAtLegalData: true,
    type: "instructionDecree",
    show: true,
  },
  legalData: {
    causaNumero: {
      title: "LEGAJO N° / N° de extracto",
      placeholder: "LEGAJO N°",
    },
    showModusOperandi: true,
    relatedArticles: false,
    showCrime: true,
    showJudgedIntervener: false,
    showUFI: true,
    fiscalInCharge: true,
    fiscalAssistant: true,
  },
  formPersona: {
    condition: {
      denunciante: "condicionUFIDenunciante",
      acusados: "condicionUFIAcusado",
    },
    // conditionDefault: 'Víctima',
    // hidden: ['dataCondicion'],
    whistleblower: {
      emptyTitle: "Nueva víctima",
    },
    accused: {
      emptyTitle: "Nuevo aprehendido",
    },
  },
  personCondition: {
    denunciante: { id: "victima", nombre: "Víctima" },
    acusados: { id: "Aprehendido", nombre: "Aprehendido" },
  },
  calendarDenuncia: true,
  formLugarMomentoError: true,
  cardAcusadosWarnings: true,
  cardAcusadosDelete: true,
  cardObjetosWarnings: true,
  formPersonalInterviniente: true,
  botonContinuar: true,
  cardObjetosDelete: true,
  cardAcusadosDeleteWarnings: true,
  hasFormDatosLegales: true,
  FormDatosLegalesStyles: true,
  stylesLightBluePersonas: false,
  checkboxPublicOrder: true,
  preformTitle: false,
  showPeoplesAlertas: true,
  showFormLugarMomentoAlertas: true,
  resumenCardPassed: true,
  defaultDiligenceID: "ufiF5fe4-7957-11ec-816d-16c2a030bb97",
  personConditionDefault: { ...basicViewData(params).personConditionDefault, affected: { id: "Fallecido", nombre: "Fallecido" } },  
  formDatosLegales: {Preform: 'PreformUFI', actOfStart: 'actOfStartUFI', instructionDecree: 'instructionDecreeUFI', depositionStyles: 'h-21rem'}
})

const basicUfiDelitosEspecialesViewData = (params) => ({
  ...basicUfiViewData(params),
  formPersonalInterviniente: true,
  objetos: {
    type: "objetosOnlySecuestrado",
  },
  cardDenunciante: {
    title: "Afectados",
  },
  cardAcusado: {
    title: "",
    show: false,
  },
  formPersona: {
    whistleblower: {
      emptyTitle: "Nuevo afectado",
    },
    condition: {
      denunciante: "condicionUFIDenuncianteFallecido",
      acusados: "",
    },
    accused: {
      emptyTitle: "",
    },
  },
  personCondition: {
    denunciante: { id: "Denunciante y Damnificado", nombre: "Denunciante y Damnificado" },
    acusados: { id: "Acusado", nombre: "Acusado" },
  },
  formDatosLegales: {
    ...basicUfiViewData(params).formDatosLegales,
    Preform: "PreformUFIDelitosEspeciales",
    instructionDecree: "instructionDecreeUFIDelitosEspeciales",
  },
});

const basicActuacionesPreliminaresViewData = (params) => ({
  ...basicViewData(params),
  formPersona: {
    // conditionDefault: '',
    condition: {
      denunciante: "condicionPreliminar",
      acusados: "condicionSumarioAcusado",
    },
    // hidden: [],
    whistleblower: {
      emptyTitle: "Nuevo afectado",
    },
    accused: {
      emptyTitle: "Nuevo vinculado",
    },
  },
  cardAcusado: {
    title: "",
    show: false,
  },
  formPersonalInterviniente: true,
  startActType: {
    title: "ACTA DE INICIO",
    textDiligenciaTitulos: true,
  },
  postform: {
    titleLegalData: "",
    showOnlyAtLegalData: false,
    type: "instructionDecree",
    show: true,
  },
  hasFormDatosLegales: true,
  FormDatosLegalesStyles: true,
  botonContinuar: true,
  formLugarMomentoError: true,
  cardAcusadosWarnings: true,
  mixedFormDatosLegalesDenunciaFormDatos: true,
  preformTitle: false,
  showFormLugarMomentoAlertas: true,
  defaultDiligenceID: "preliminar-7957-11ec-816d-16c2a030bb98",
  formDatosLegales: {
    Preform: 'PreformActuacionesPreliminares', 
    actOfStart: 'actOfStartActuacionesPreliminares', 
    instructionDecree: 'instructionDecreeActuacionesPreliminares',
    depositionStyles: 'h-33rem -mt-8 -mb-6'
  }
})

const state = () => ({
  actuacion: localStorage.getItem("actuacion_formType") || "denuncia", //formType
  default: {
    ...basicViewData({ routeMainForm: "" }),
    showError: false,
  },
  denuncia: {
    ...basicViewData({ routeMainForm: "" }),
    cardDenunciante: {
      title: "Afectados",
      deleteButtonText: "denunciante",
    },
    formPersona: {
      condition: {
        denunciante: "condicionCombinada",
        acusados: "condicionSumarioAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo Afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    hasButtonNext: true,
    // hasTextareaDenuncia: true,
    formDenunciaDeleteButton: {
      show: true,
      whistleblowerText: "denunciante",
      text: "",
      accusedText: "acusado",
    },
    hasFormDatosLegales: true,
    botonContinuar: true,
    hasButtonNext: false,
    startActType: {
      title: "Denuncia penal",
      textDiligenciaTitulos: true,
    },
    postform: {
      titleLegalData: "Lo que solicita...",
      showOnlyAtLegalData: false,
      type: "Postform",
      show: true,
    },
    showPeoplesAlertas: true,
    cardAcusadosWarnings: true,
    cardObjetosWarnings: true,
    formLugarMomentoError: true,
    showFormLugarMomentoAlertas: true,
    resumenCardPassed: true,
    resumenCardWarning: true,
    legalData: {
      causaNumero: {
        title: "N° de sumario / N° de extracto",
        placeholder: "N° sumario/extracto",
      },
      showModusOperandi: true,
      showJudgedIntervener: true,
      relatedArticles: false,
      showCrime: false,
      showUFI: false,
      fiscalInCharge: false,
      fiscalAssistant: false,
      showCausaCaratula: true,
    },
    actuationName: {"Sumario Prevencional Por Denuncia": "PREVENCIONAL DENUNCIA"},
    defaultDiligenceID: 'SPpD-11ec-816d-16c2a030bb97',
    roleType: {AcusadoList: "Acusado", DenunciantesList: "Damnificado"},
    formDatosLegales: {Preform: 'PreformSumarioDenuncia', actOfStart: 'denunciaPenalSumarioDenuncia', depositionStyles: 'h-15rem'},
    filters: { 
      "Sumario Prevencional Por Denuncia": {
        deposito: { value: "Sumario Prevencional Por Denuncia" },
      },
    },
  },
  oficio: {
    ...basicViewData({ routeMainForm: "" }),
    // mainForm: {
    //   path: '/sumario/denuncia/formulario/oficio'
    // },
    cardDenunciante: {
      title: "Afectados",
    },
    formPersona: {
      condition: {
        denunciante: "condicionCombinada",
        acusados: "condicionSumarioAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo Afectado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    formPersonalInterviniente: true,
    hasButtonNext: false,
    checkboxPublicOrder: true,
    hasFormDatosLegales: true,
    botonContinuar: true,
    startActType: {
      title: "Acta de inicio",
      textDiligenciaTitulos: true,
    },
    postform: {
      titleLegalData: "Lo que solicita...",
      showOnlyAtLegalData: false,
      type: "postformSumarioOficio",
      show: true,
    },
    formDenunciaDeleteButton: {
      show: true,
      whistleblowerText: "denunciante",
      text: "",
      accusedText: "acusado",
    },
    showPeoplesAlertas: true,
    showFormLugarMomentoAlertas: true,
    formLugarMomentoError: true,
    resumenCardWarning: true,
    cardAcusadosWarnings: true,
    cardObjetosWarnings: true,
    resumenCardPassed: true,
    legalData: {
      causaNumero: {
        title: "Nº de sumario / Nº de extracto",
        placeholder: "Nº sumario/extracto",
      },
      showModusOperandi: true,
      showJudgedIntervener: true,
      relatedArticles: false,
      showCrime: false,
      showUFI: false,
      fiscalInCharge: false,
      fiscalAssistant: false,
      showCausaCaratula: true,
    },
    actuationName: {"Sumario Prevencional Por Oficio": "PREVENCIONAL OFICIO"},
    defaultDiligenceID: 'SPpO-22ec-926d-16c2a030bb97',
    roleType: {AcusadoList: "Acusado", DenunciantesList: "Damnificado"},
    personConditionDefault: {...basicViewData({routeMainForm: '/oficio'}).personConditionDefault, affected: { id: "Damnificado", nombre: "Damnificado" }},
    formDatosLegales: {Preform: 'PreformSumarioOficio', actOfStart: 'actOfStartSumarioOficio', depositionStyles: 'h-35rem'},
    filters: { 
      "Sumario Prevencional Por Oficio": {
        deposito: { value: "Sumario Prevencional Por Oficio" },
      },
    },
  },
  ufi: {
    ...basicUfiViewData({ routeMainForm: "/ufi" }),
  },
  "ufi-cavig": {
    ...basicUfiViewData({ routeMainForm: "/ufi-cavig" }),
    actuationName: { "Unidades Fiscales de Investigacion CAVIG": "UFI - CAVIG" },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Víctima" },
    personConditionDefault: {
      affected: { id: "Denunciante", nombre: "Denunciante" },
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    filters: {
      "UFI - CAVIG": {
        deposito: { value: "Unidades Fiscales de Investigacion CAVIG" },
        actuacionesFilters: { value: "Cavig" },
      },
    },
    emailUFI: "uficavig@jussanjuan.gov.ar",
    formPersona: {
      condition: {
        denunciante: "condicionList",
        acusados: "condicionUFIAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
  },
  "ufi-anivi": {
    ...basicUfiViewData({ routeMainForm: "/ufi-anivi" }),
    actuationName: { "Unidades Fiscales de Investigacion ANIVI": "UFI - ANIVI" },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Víctima" },
    personConditionDefault: {
      affected: { id: "Denunciante", nombre: "Denunciante" },
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    filters: {
      "UFI - ANIVI": {
        deposito: { value: "Unidades Fiscales de Investigacion ANIVI" },
        actuacionesFilters: { value: "ANIVI" },
      },
    },
    emailUFI: "ufianivi@jussanjuan.gov.ar",
    formPersona: {
      condition: {
        denunciante: "condicionList",
        acusados: "condicionUFIAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
  },
  "ufi-flagrancia": {
    ...basicUfiViewData({ routeMainForm: "/ufi-flagrancia" }),
    formPersona: {
      condition: {
        denunciante: "condicionList",
        acusados: "condicionUFIAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    actuationName: { "Unidades Fiscales de Investigacion Flagrancia": "UFI - FLAGRANCIA" },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Víctima" },
    personConditionDefault: {
      affected: { id: "Denunciante", nombre: "Denunciante" },
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    filters: {
      "UFI - FLAGRANCIA": {
        deposito: { value: "Unidades Fiscales de Investigacion Flagrancia" },
        actuacionesFilters: { value: "Flagrancia" },
      },
    },
    emailUFI: "ufiflagrancia@jussanjuan.gov.ar",
  },
  "ufi-generica": {
    ...basicUfiViewData({ routeMainForm: "/ufi-generica" }),
    formPersona: {
      condition: {
        denunciante: "condicionList",
        acusados: "condicionUFIAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    actuationName: {
      "Unidades Fiscales de Investigación Delitos Genéricos por Denuncia":
        "UFI - DELITOS GENÉRICOS POR DENUNCIA",
    },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Víctima" },
    personConditionDefault: {
      affected: { id: "Denunciante", nombre: "Denunciante" },
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    filters: {
      "UFI - DELITOS GENÉRICOS": {
        deposito: { value: "Unidades Fiscales de Investigación Delitos Genéricos" },
        actuacionesFilters: { value: "Unidades Fiscales de Investigación Delitos Genéricos" },
      },
    },
    formPersonalInterviniente: false,
    startActType: {
      title: "DENUNCIA LEGAJO",
      textDiligenciaTitulos: false,
    },
    emailUFI: "ufigenerica@jussanjuan.gov.ar",
    defaultDiligenceID: "ufi-denuncia-legajo-ufis-denuncia",
    formDatosLegales: {
      Preform: 'PreformUFIGenericDenuncia', 
      actOfStart: 'actOfStartUFIGenericDenuncia', 
      instructionDecree: 'instructionDecreeUFIGenericDenuncia',
      depositionStyles: 'h-13rem -mt-8 -mb-6'
    },
  },
  "ufi-generica-oficio": {
    ...basicUfiViewData({ routeMainForm: "/ufi-generica-oficio" }),
    formPersona: {
      condition: {
        denunciante: "condicionList",
        acusados: "condicionUFIAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    actuationName: {
      "Unidades Fiscales de Investigación Delitos Genéricos por Oficio":
        "UFI - DELITOS GENÉRICOS POR OFICIO",
    },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Víctima" },
    emailUFI: "ufigenerica@jussanjuan.gov.ar",
    personConditionDefault: {
      affected: { id: "Denunciante", nombre: "Denunciante" },
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
  },
  "ufi-estafas-y-delitos-informaticos": {
    ...basicUfiViewData({ routeMainForm: "/ufi-estafas-y-delitos-informaticos" }),
    formPersona: {
      condition: {
        denunciante: "condicionList",
        acusados: "condicionUFIAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    actuationName: {
      "Unidades Fiscales de Investigación Delitos Informáticos y Estafas por Denuncia":
        "UFI - ESTAFAS Y DELITOS INFORMATICOS POR DENUNCIA",
    },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Víctima" },
    personConditionDefault: {
      affected: { id: "Denunciante", nombre: "Denunciante" },
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    filters: {
      "UFI - DELITOS INFORMÁTICOS Y ESTAFAS": {
        deposito: { value: "Unidades Fiscales de Investigación Delitos Informáticos y Estafas" },
        actuacionesFilters: {
          value: "Unidades Fiscales de Investigación Delitos Informáticos y Estafas",
        },
      },
    },
    formPersonalInterviniente: false,
    startActType: {
      title: "DENUNCIA LEGAJO",
      textDiligenciaTitulos: false,
    },
    emailUFI: "ufidelitosinformaticos@jussanjuan.gov.ar",
    defaultDiligenceID: "ufi-denuncia-legajo-ufis-denuncia",
    formDatosLegales: {
      Preform: 'PreformUFIGenericDenuncia', 
      actOfStart: 'actOfStartUFIGenericDenuncia', 
      instructionDecree: 'instructionDecreeUFIGenericDenuncia',
      depositionStyles: 'h-13rem -mt-8 -mb-6'
    },
    cardPatrimonialAffectation: {
      ...basicUfiViewData({ routeMainForm: "/ufi-estafas-y-delitos-informaticos" })
        .cardPatrimonialAffectation,
      show: true,
    },
  },
  "ufi-estafas-y-delitos-informaticos-oficio": {
    ...basicUfiViewData({ routeMainForm: "/ufi-estafas-y-delitos-informaticos-oficio" }),
    formPersona: {
      condition: {
        denunciante: "condicionList",
        acusados: "condicionUFIAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    actuationName: {
      "Unidades Fiscales de Investigación Delitos Informáticos y Estafas por Oficio":
        "UFI - ESTAFAS Y DELITOS INFORMATICOS POR OFICIO",
    },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Víctima" },
    emailUFI: "ufidelitosinformaticos@jussanjuan.gov.ar",
    personConditionDefault: {
      affected: { id: "Denunciante", nombre: "Denunciante" },
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    cardPatrimonialAffectation: {
      ...basicUfiViewData({ routeMainForm: "/ufi-estafas-y-delitos-informaticos" })
        .cardPatrimonialAffectation,
      show: true,
    },
  },
  "ufi-delitos-contra-la-propiedad": {
    ...basicUfiViewData({ routeMainForm: "/ufi-delitos-contra-la-propiedad" }),
    formPersona: {
      condition: {
        denunciante: "condicionList",
        acusados: "condicionUFIAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    actuationName: {
      "Unidades Fiscales de Investigación Delitos Contra la Propiedad por Denuncia":
        "UFI - DELITOS CONTRA LA PROPIEDAD POR DENUNCIA",
    },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Víctima" },
    personConditionDefault: {
      affected: { id: "Denunciante", nombre: "Denunciante" },
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    filters: {
      "UFI - DELITOS CONTRA LA PROPIEDAD": {
        deposito: { value: "Unidades Fiscales de Investigación Delitos Contra la Propiedad" },
        actuacionesFilters: {
          value: "Unidades Fiscales de Investigación Delitos Contra la Propiedad",
        },
      },
    },
    formPersonalInterviniente: false,
    startActType: {
      title: "DENUNCIA LEGAJO",
      textDiligenciaTitulos: false,
    },
    emailUFI: "ufidelitospropiedad@jussanjuan.gov.ar",
    defaultDiligenceID: "ufi-denuncia-legajo-ufis-denuncia",
    formDatosLegales: {
      Preform: 'PreformUFIGenericDenuncia', 
      actOfStart: 'actOfStartUFIGenericDenuncia', 
      instructionDecree: 'instructionDecreeUFIGenericDenuncia',
      depositionStyles: 'h-13rem -mt-8 -mb-6'
    },
  },
  "ufi-delitos-contra-la-propiedad-oficio": {
    ...basicUfiViewData({ routeMainForm: "/ufi-delitos-contra-la-propiedad-oficio" }),
    formPersona: {
      condition: {
        denunciante: "condicionList",
        acusados: "condicionUFIAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    actuationName: {
      "Unidades Fiscales de Investigación Delitos Contra la Propiedad por Oficio":
        "UFI - DELITOS CONTRA LA PROPIEDAD OFICIO",
    },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Víctima" },
    emailUFI: "ufidelitospropiedad@jussanjuan.gov.ar",
    personConditionDefault: {
      affected: { id: "Denunciante", nombre: "Denunciante" },
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
  },
  "ufi-delitos-especiales": {
    ...basicUfiViewData({ routeMainForm: "/ufi" }),
    emailUFI: "ufidelitosesp@jussanjuan.gov.ar",
  },
  "contravencional-oficio": {
    ...basicViewData({ routeMainForm: "/contravencional/oficio" }),
    // mainForm: {
    //   path: '/sumario/denuncia/formulario/contravencional/oficio'
    // },
    // cardEfectos: {
    //   title: 'Efectos'
    // },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    formAcusados: {
      testId: "buttonSaveContraventor",
    },
    startActType: {
      title: "Acta de inicio",
      textDiligenciaTitulos: true,
    },
    formPersona: {
      condition: {
        denunciante: "condicionCombinada",
        acusados: "condicionContravencionAcusado",
      },
      // hidden: [],
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    contraventional: true,
    calendarDenuncia: true,
    formLugarMomentoError: true,
    cardAcusadosWarnings: true,
    cardAcusadosDelete: true,
    cardObjetosWarnings: true,
    formPersonalInterviniente: true,
    botonContinuar: true,
    cardObjetosDelete: true,
    cardAcusadosDeleteWarnings: true,
    hasFormDatosLegales: true,
    showPeoplesAlertas: true,
    showFormLugarMomentoAlertas: true,
    resumenCardPassed: true,
    resumenCardWarning: true,
    actuationName: {
      "Expediente Contravencional por oficio": "CONTRAVENCIONAL OFICIO",
      "Expediente Contravencional por oficio": "CONTRAVENCIONAL OFICIO",
    },
    defaultDiligenceID: 'feca5fe4-7957-11ec-816d-16c2a030bb97',
    roleType: {AcusadoList: "Acusado", DenunciantesList: "Damnificado"},
    personConditionDefault: {...basicViewData({routeMainForm: '/contravencional/oficio'}).personConditionDefault, affected: { id: "Denunciante y Damnificado", nombre: "Denunciante y Damnificado" }},
    formDatosLegales: {Preform: 'PreformContravencionalOficio', actOfStart: 'actOfStartContravencionalOficio', depositionStyles: 'h-33rem'},
    filters: { 
      "Expediente Contravencional por oficio": {
        deposito: { value: "Expediente Contravencional por oficio" },
      },
    },
  },
  "contravencional-denuncia": {
    ...basicViewData({ routeMainForm: "/contravencional/denuncia" }),
    // mainForm: {
    //   path: '/sumario/denuncia/formulario/contravencional/denuncia'
    // },
    // cardEfectos: {
    //   title: 'Efectos'
    // },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    formAcusados: {
      testId: "buttonSaveContraventor",
    },
    postform: {
      titleLegalData: "Lo que solicita...",
      showOnlyAtLegalData: false,
      type: "Postform",
      show: true,
    },
    formPersona: {
      // conditionDefault: '',
      condition: {
        denunciante: "condicionCombinada",
        acusados: "condicionContravencionAcusado",
      },
      // hidden: [],
      whistleblower: {
        emptyTitle: "Nuevo Afectado",
        emailWarning: true,
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    contraventional: true,
    calendarDenuncia: true,
    formLugarMomentoError: true,
    cardAcusadosWarnings: true,
    cardAcusadosDelete: true,
    cardObjetosWarnings: true,
    botonContinuar: true,
    cardObjetosDelete: true,
    cardAcusadosDeleteWarnings: true,
    hasFormDatosLegales: true,
    showPeoplesAlertas: true,
    showFormLugarMomentoAlertas: true,
    resumenCardPassed: true,
    resumenCardWarning: true,
    actuationName: {"Expediente Contravencional por denuncia": "CONTRAVENCIONAL DENUNCIA"},
    defaultDiligenceID: 'ecpd5fe4-7957-11ec-816d-16c2a030bb97',
    roleType: {AcusadoList: "Acusado", DenunciantesList: "Damnificado"},
    formDatosLegales: {Preform: 'PreformContravencionalDenuncia', actOfStart: 'actOfStartContravencionalDenuncia', depositionStyles: 'h-17rem'},
    filters: { 
      "Expediente Contravencional por denuncia": {
        deposito: { value: "Expediente Contravencional por denuncia" },
      },
    },
  },
  "ufi-establecer-paradero": {
    ...basicUfiDelitosEspecialesViewData({ routeMainForm: "/ufi-establecer-paradero" }),
    cardDenunciante: {
      title: "Afectados",
    },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    formPersona: {
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      condition: {
        denunciante: "condicionUFIDenuncianteParadero",
        acusados: "condicionUFIAcusadoParadero",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    personCondition: {
      denunciante: { id: "Denunciante", nombre: "Denunciante" },
      acusados: { id: "Extraviado", nombre: "Extraviado" },
    },
    formPersonalInterviniente: false,
    actuationName: {"Unidades Fiscales de Investigacion D.E Establecer Paradero": "UFI - Establecer Paradero"},
    roleType: {AcusadoList: "Extraviado", DenunciantesList: "Denunciante"},
    personConditionDefault: {affected: { id: "Denunciante", nombre: "Denunciante" }, vinculated:  { id: "Extraviado", nombre: "Extraviado" }},
    formDatosLegales: {
      Preform: 'PreformUFIEstablecerParadero', 
      actOfStart: 'actOfStartUFIEstablecerParadero', 
      instructionDecree: 'instructionDecreeUFIEstablecerParadero',
      depositionStyles: 'h-13rem -mt-8 -mb-6'
    },
    filters: { 
      "UFI- Delitos Especiales - Establecer paradero": {
        deposito: {value: "Unidades Fiscales de Investigacion D.E Establecer Paradero"}, 
        actuacionesFilters:{value: "D.E Establecer Paradero"} 
      },  
    }, 
    formDatosLegales: {
      ...basicUfiDelitosEspecialesViewData({routeMainForm: '/ufi-establecer-paradero'}).formDatosLegales, 
      Preform: 'PreformUFIDelitosEspeciales', 
      instructionDecree: 'instructionDecreeUFIDelitosEspeciales',
      depositionStyles: 'h-17rem -mt-8 -mb-6'
    }
  },
  "ufi-siniestro-vial": {
    ...basicUfiDelitosEspecialesViewData({ routeMainForm: "/ufi-siniestro-vial" }),
    cardDenunciante: {
      title: "Afectados",
    },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    formPersona: {
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      condition: {
        denunciante: "condicionUFIDenuncianteFallecido",
        acusados: "condicionUFIAcusado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    personCondition: {
      denunciante: { id: "Fallecido", nombre: "Fallecido" },
      acusados: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    resumenCardWarning: true,
    actuationName: {
      "Unidades Fiscales de Investigacion D.E Siniestro Vial": "UFI - Siniestro Vial",
    },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Fallecido" },
    personConditionDefault: {
      ...basicUfiDelitosEspecialesViewData({ routeMainForm: "/ufi-siniestro-vial" })
        .personConditionDefault,
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    filters: {
      "UFI- Delitos Especiales - Siniestro Vial": {
        deposito: { value: "Unidades Fiscales de Investigacion D.E Siniestro Vial" },
        actuacionesFilters: { value: "D.E Siniestro Vial" },
      },
    },
  },
  "ufi-otros": {
    ...basicUfiDelitosEspecialesViewData({ routeMainForm: "/ufi-otros" }),
    cardDenunciante: {
      title: "Afectados",
    },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    formPersona: {
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      condition: {
        denunciante: "condicionUFIDenuncianteFallecido",
        acusados: "condicionUFIAcusado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    personCondition: {
      denunciante: { id: "Fallecido", nombre: "Fallecido" },
      acusados: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    resumenCardWarning: true,
    actuationName: {
      "Unidades Fiscales de Investigacion D.E Otros": "UFI - Otros",
    },
    roleType: { AcusadoList: "Aprehendido", DenunciantesList: "Fallecido" },
    personConditionDefault: {
      ...basicUfiDelitosEspecialesViewData({ routeMainForm: "/ufi-otros" })
        .personConditionDefault,
      vinculated: { id: "Aprehendido", nombre: "Aprehendido" },
    },
    filters: {
      "UFI- Delitos Especiales - Otros": {
        deposito: { value: "Unidades Fiscales de Investigacion D.E Otros" },
        actuacionesFilters: { value: "D.E Otros" },
      },
    },
  },
  "ufi-intoxicacion": {
    ...basicUfiDelitosEspecialesViewData({ routeMainForm: "/ufi-intoxicacion" }),
    actuationName: { "Unidades Fiscales de Investigacion D.E Intoxicacion": "UFI - Intoxicación" },
    roleType: { DenunciantesList: "Fallecido" },
    filters: {
      "UFI- Delitos Especiales - Intoxicacion": {
        deposito: {value: "Unidades Fiscales de Investigacion D.E Intoxicacion"}, 
        actuacionesFilters:{value: "D.E Intoxicacion"} 
      },  
    }, 
    formDatosLegales: {
      ...basicUfiDelitosEspecialesViewData({routeMainForm: '/ufi-intoxicacion'}).formDatosLegales, 
      Preform: 'PreformUFIDelitosEspeciales', 
      instructionDecree: 'instructionDecreeUFIDelitosEspeciales',
      depositionStyles: 'h-20rem -mt-8 -mb-6'
    }
  },
  "ufi-emergencias-medicas": {
    ...basicUfiDelitosEspecialesViewData({ routeMainForm: "/ufi-emergencias-medicas" }),
    actuationName: {
      "Unidades Fiscales de Investigacion D.E Emergencias Medicas": "UFI - Emergencias Medicas",
    },
    roleType: { DenunciantesList: "Fallecido" },
    filters: {
      "UFI- Delitos Especiales - Emergencias Medicas": {
        deposito: {value: "Unidades Fiscales de Investigacion D.E Emergencias Medicas"}, 
        actuacionesFilters:{value: "D.E Emergencias Medicas"} 
      },  
    }, 
    formDatosLegales: {
      ...basicUfiDelitosEspecialesViewData({routeMainForm: '/ufi-emergencias-medicas'}).formDatosLegales, 
      Preform: 'PreformUFIDelitosEspeciales', 
      instructionDecree: 'instructionDecreeUFIDelitosEspeciales',
      depositionStyles: 'h-20rem -mt-8 -mb-6'
    }
  },
  "ufi-incendio-vivienda": {
    ...basicUfiDelitosEspecialesViewData({ routeMainForm: "/ufi-incendio-vivienda" }),
    actuationName: {
      "Unidades Fiscales de Investigacion D.E Incendio en Vivienda": "UFI - Incendio en Vivienda",
    },
    filters: {
      "UFI- Delitos Especiales - Incendio en vivienda": {
        deposito: {value: "Unidades Fiscales de Investigacion D.E Incendio en Vivienda"}, 
        actuacionesFilters:{value: "D.E Incendio en Vivienda"} 
      },  
    }, 
    formDatosLegales: {
      ...basicUfiDelitosEspecialesViewData({routeMainForm: '/ufi-incendio-vivienda'}).formDatosLegales, 
      Preform: 'PreformUFIDelitosEspeciales', 
      instructionDecree: 'instructionDecreeUFIDelitosEspeciales',
      depositionStyles: 'h-20rem -mt-8 -mb-6'
    }
  },
  "ufi-ahorcamiento-suicidio": {
    ...basicUfiDelitosEspecialesViewData({ routeMainForm: "/ufi-ahorcamiento-suicidio" }),
    actuationName: {
      "Unidades Fiscales de Investigacion D.E Ahorcamiento o Suicidio":
        "UFI - Ahorcamiento o Suicidio",
    },
    roleType: { DenunciantesList: "Fallecido" },
    filters: {
      "UFI- Delitos Especiales - Suicidio": {
        deposito: {value: "Unidades Fiscales de Investigacion D.E Ahorcamiento o Suicidio"}, 
        actuacionesFilters:{value: "D.E Ahorcamiento o Suicidio"} 
      },  
    }, 
    formDatosLegales: {
      ...basicUfiDelitosEspecialesViewData({routeMainForm: '/ufi-ahorcamiento-suicidio'}).formDatosLegales, 
      Preform: 'PreformUFIDelitosEspeciales', 
      instructionDecree: 'instructionDecreeUFIDelitosEspeciales',
      depositionStyles: 'h-20rem -mt-8 -mb-6'
    }
  },

  "preliminar-incendio-vivienda": {
    ...basicActuacionesPreliminaresViewData({ routeMainForm: "/preliminar-incendio-vivienda" }),
    formPersona: {
      // conditionDefault: '',
      condition: {
        denunciante: "condicionCombinada",
        acusados: "condicionSumarioAcusado",
      },
      // hidden: [],
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    showFormLugarMomentoAlertas: false,
    actuationName: {
      "Actuacion Preliminar por Incendio en Vivienda":
        "ACTUACIONES PRELIMINARES -  INCENDIO EN VIVIENDA",
    },
    roleType: { AcusadoList: "Acusado", DenunciantesList: "Denunciante" },
    filters: {
      "Actuaciones Preliminares - Incendio en Vivienda": {
        deposito: { value: "Actuacion Preliminar por Incendio en Vivienda" },
        actuacionesFilters: { value: "Preliminar por Incendio en Vivienda" },
      },
    },
  },
  "preliminar-establecer-procedencias": {
    ...basicActuacionesPreliminaresViewData({
      routeMainForm: "/preliminar-establecer-procedencias",
    }),
    formPersona: {
      // conditionDefault: '',
      condition: {
        denunciante: "condicionList",
        acusados: "condicionSumarioAcusado",
      },
      // hidden: [],
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    showFormLugarMomentoAlertas: false,
    actuationName: {
      "Actuacion Preliminar para Establecer Procedencias":
        "ACTUACIONES PRELIMINARES -  ESTABLECER PROCEDENCIA",
    },
    roleType: { AcusadoList: "Acusado", DenunciantesList: "Denunciante" },
    personConditionDefault: {
      ...basicActuacionesPreliminaresViewData({
        routeMainForm: "/preliminar-establecer-procedencias",
      }).personConditionDefault,
      affected: { id: "Denunciante", nombre: "Denunciante" },
    },
    filters: {
      "Actuaciones Preliminares - Establecer Procedencia": {
        deposito: { value: "Actuacion Preliminar para Establecer Procedencias" },
        actuacionesFilters: { value: "Preliminar para Establecer Procedencias" },
      },
    },
  },
  "preliminar-fuga-menor": {
    ...basicActuacionesPreliminaresViewData({ routeMainForm: "/preliminar-fuga-menor" }),
    formPersona: {
      // conditionDefault: '',
      condition: {
        denunciante: "condicionList",
        acusados: "condicionSumarioAcusado",
      },
      // hidden: [],
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    actuationName: {
      "Actuacion Preliminar por Fuga de Menor": "ACTUACIONES PRELIMINARES -  FUGA MENOR",
    },
    roleType: { AcusadoList: "Acusado", DenunciantesList: "Denunciante" },
    personConditionDefault: {
      ...basicActuacionesPreliminaresViewData({
        routeMainForm: "/preliminar-establecer-procedencias",
      }).personConditionDefault,
      affected: { id: "Denunciante", nombre: "Denunciante" },
    },
    filters: {
      "Actuaciones Preliminares - Fuga de Menor": {
        deposito: { value: "Actuacion Preliminar por Fuga de Menor" },
        actuacionesFilters: { value: "Preliminar por Fuga de Menor" },
      },
    },
  },
  "preliminar-incendio-automotor": {
    ...basicActuacionesPreliminaresViewData({ routeMainForm: "/preliminar-incendio-automotor" }),
    cardDenunciante: {
      title: "Afectados",
    },
    actuationName: {
      "Actuacion Preliminar por Incendio de Automotor":
        "ACTUACIONES PRELIMINARES -  INCENDIO DE AUTOMOTOR",
    },
    roleType: { DenunciantesList: "Damnificado" },
    personConditionDefault: {
      ...basicActuacionesPreliminaresViewData({
        routeMainForm: "/preliminar-establecer-procedencias",
      }).personConditionDefault,
      affected: { id: "Damnificado", nombre: "Damnificado" },
    },
    filters: {
      "Actuaciones Preliminares - Incendio Automotor": {
        deposito: { value: "Actuacion Preliminar por Incendio de Automotor" },
        actuacionesFilters: { value: "Preliminar por Incendio de Automotor" },
      },
    },
    formDatosLegales: {
      Preform: 'PreformActuacionesPreliminares', 
      actOfStart: 'actOfStartActuacionesPreliminares', 
      instructionDecree: 'instructionDecreeActuacionesPreliminares',
      depositionStyles: 'h-23rem -mt-8 -mb-6'
    }
  },
  "preliminar-siniestro-vial": {
    ...basicActuacionesPreliminaresViewData({ routeMainForm: "/preliminar-siniestro-vial" }),
    formPersona: {
      condition: {
        denunciante: "condicionCaidaCasual",
        acusados: "condicionSumarioAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    cardDenunciante: {
      title: "Afectados",
    },
    showPeoplesAlertas: true,
    actuationName: {
      "Actuacion Preliminar por Siniestro Vial": "ACTUACIONES PRELIMINARES -  SINIESTRO VIAL",
    },
    roleType: { DenunciantesList: "Damnificado" },
    personConditionDefault: {
      ...basicActuacionesPreliminaresViewData({
        routeMainForm: "/preliminar-establecer-procedencias",
      }).personConditionDefault,
      affected: { id: "Protagonista", nombre: "Protagonista" },
    },
    filters: {
      "Actuaciones Preliminares - Siniestro Vial": {
        deposito: {value: "Actuacion Preliminar por Siniestro Vial"}, 
        actuacionesFilters:{value: "Preliminar por Siniestro Vial"} 
      },  
    }, 
    formDatosLegales: {
      Preform: 'PreformActuacionesPreliminares', 
      actOfStart: 'actOfStartActuacionesPreliminares', 
      instructionDecree: 'instructionDecreeActuacionesPreliminares',
      depositionStyles: 'h-23rem -mt-8 -mb-6'
    }  
  },
  "preliminar-caida-casual": {
    ...basicActuacionesPreliminaresViewData({ routeMainForm: "/preliminar-caida-casual" }),
    formPersona: {
      condition: {
        denunciante: "condicionCaidaCasual",
        acusados: "condicionSumarioAcusado",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      accused:  {
        emptyTitle: "Nuevo acusado",
      },
    },
    cardDenunciante: {
      title: "Afectados",
    },
    actuationName: {
      "Actuacion Preliminar por Caida Casual": "ACTUACIONES PRELIMINARES -  CAIDA CASUAL",
    },
    roleType: { DenunciantesList: "Damnificado" },
    personConditionDefault: {
      ...basicActuacionesPreliminaresViewData({
        routeMainForm: "/preliminar-establecer-procedencias",
      }).personConditionDefault,
      affected: { id: "Protagonista", nombre: "Protagonista" },
    },
    filters: {
      "Actuaciones Preliminares - Caida Casual": {
        deposito: {value: "Actuacion Preliminar por Caida Casual"}, 
        actuacionesFilters:{value: "Preliminar por Caida Casual"} 
      },  
    }, 
    formDatosLegales: {
      Preform: 'PreformActuacionesPreliminares', 
      actOfStart: 'actOfStartActuacionesPreliminares', 
      instructionDecree: 'instructionDecreeActuacionesPreliminares',
      depositionStyles: 'h-23rem -mt-8 -mb-6'
    }  
  },  
  'preliminar-tentativa-suicidio':{
    ...basicActuacionesPreliminaresViewData({routeMainForm: '/preliminar-tentativa-suicidio'}),
    formPersona: {
      // conditionDefault: '',
      condition: {
        denunciante: "condicionCombinada",
        acusados: "condicionTentativaSuicidio",
      },
      // hidden: [],
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    personCondition: {
      denunciante: { id: "Denunciante y Damnificado", nombre: "Denunciante y Damnificado" },
      acusados: { id: "Autolesionado", nombre: "Autolesionado" },
    },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    actuationName: {
      "Actuacion Preliminar por Tentativa de Suicidio":
        "ACTUACIONES PRELIMINARES -  TENTATIVA DE SUICIDIO",
    },
    roleType: { AcusadoList: "Autolesionado", DenunciantesList: "Damnificado" },
    personConditionDefault: {
      ...basicActuacionesPreliminaresViewData({
        routeMainForm: "/preliminar-establecer-procedencias",
      }).personConditionDefault,
      vinculated: { id: "Autolesionado", nombre: "Autolesionado" },
    },
    filters: {
      "Actuaciones Preliminares - Tentativa de Suicidio": {
        deposito: { value: "Actuacion Preliminar por Tentativa de Suicidio" },
        actuacionesFilters: { value: "Preliminar por Tentativa de Suicidio" },
      },
    },
  },
  "preliminar-otros": {
    ...basicActuacionesPreliminaresViewData({ routeMainForm: "/preliminar-otros" }),
    formPersona: {
      condition: {
        denunciante: "condicionCombinada",
        acusados: "condicionAcusadoDetenido",
      },
      whistleblower: {
        emptyTitle: "Nuevo afectado",
      },
      accused: {
        emptyTitle: "Nuevo vinculado",
      },
    },
    cardAcusado: {
      title: "Vinculados",
      show: true,
    },
    actuationName: { "Actuacion Preliminar - Otros": "ACTUACIONES PRELIMINARES - OTROS" },
    roleType: { AcusadoList: "Acusado" },
    filters: {
      "Actuaciones Preliminares - Otros": {
        deposito: { value: "Actuacion Preliminar - Otros" },
        actuacionesFilters: { value: "Preliminar - Otros" },
      },
    },
  },
});

// actions
const actions = {
  setActuacionType({ commit, dispatch }, formType) {
    if (!formType) return;
    dispatch("setEmptyDoc", formType);
    // console.log("setActuacionType", formType)
    localStorage.setItem("actuacion_formType", formType);
    commit("setActuacionType", formType);
  },
  setEmptyDoc({ commit, dispatch }, formType) {
    let sendDispatch = true;
    let condition = generalFunctions.determineDefaultConditionFromAffected(formType);
    if (condition === false) sendDispatch = false;
    if (sendDispatch) {
      let emptyDoc = Denunciante.emptyDoc();
      Denunciante.emptyDoc = () => ({
        ...emptyDoc,
        condicion: condition,
      });
      dispatch("sumario/denunciante/setEmptyDoc", Denunciante.emptyDoc(), { root: true });
    }
  },
};

// mutations
const mutations = {
  setActuacionType(state, formType) {
    state.actuacion = formType;
  },
};

const getters = {
  getViewData: (state) => {
    return state[state.actuacion] || state["default"];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
