// import isSuperUserGuard from '@/guards/isSuperUserGuard'
// import isUserTesterGuard from '@/guards/isUserTesterGuard'
import LayoutDeposito from "@/pages/deposito/LayoutDeposito";
import Deposito from "@/pages/deposito/Deposito";
import EfectoForm from "@/pages/deposito/Efecto-form";
import isUserRolGuard from "@/guards/isUserRolGuard";
// import Solicitudes from "@/pages/deposito/Solicitudes";
import solicitudesTable from "@/pages/deposito/Deposito-bandeja-entrada";
import solicitudes from "@/pages/deposito/Solicitudes";
import { depositoConfig } from "./RoutesConfig/depositoConfig";
// import { Efecto } from "@/pages";

function findPropsForRoute(pathName) {
  const link = depositoConfig.find((link) => link.pathName === pathName);
  return link ? link.props : {};
}


export default {
  name: "deposito",
  redirect: "deposito/efectos",
  beforeEnter: isUserRolGuard,
  component: LayoutDeposito,
  children: [
    {
      path: "solicitudes",
      name: "solicitudes",
      component: () => import("@/pages/deposito/Deposito-bandeja-entrada"),
    },
    {
      path: "solicitudes/:id",
      name: "solicitudID",
      component: () => import("@/pages/deposito/Solicitudes"),
      props: (route) => findPropsForRoute(route.params.pathName),
      meta: { showBackButton: true } 
    },   
    {
      path: "solicitudes/edit/:id",
      beforeEnter: isUserRolGuard,
      name: "editSolicitudID",
      // component: () => import("@/pages/deposito/Solicitudes"),
      component: () => import("@/pages/deposito/Efecto-form"),
      props: (route) => findPropsForRoute(route.params.pathName)
    },
    {
      path: "efectos",
      name: "efectos",
      component: Deposito,
    },
    {
      path: "efectos/new",
      name: "newEfecto",
      component: EfectoForm,
      meta: { showBackButton: true }  
    },
    {
      path: "efectos/:id",
      name: "efectoID",
      component: EfectoForm,  
      meta: { showBackButton: true } 
    },   
    {
      path: "efectos/edit/:id",
      name: "editEfecto",
      component: EfectoForm,  
    }
    
  ],
};

