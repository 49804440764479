export const depositoConfig= [  
      {
        path: "deposito",
        pathName: "deposito-efectos",
        roles:['User Tester','Super User','Deposito Judicial','Jefe de Dependencia','UsuarioDepartamental'],
        titulo: "DEPÓSITO JUDICIAL"
      },  
      {
        path: "solicitudes",
        pathName: "solicitudes",        
        titulo: "DEPÓSITO JUDICIAL",
        props: { pageTitle: "DEPÓSITO JUDICIAL" },
        nameOfRoute : "solicitudes"
      },
      {
        path: "solicitudes/:id",
        pathName: "solicitudID",        
        titulo: "SOLICITUDES",       
        props: { showSolicitud: true },
        nameOfRoute : "solicitudID"
      },   
      {
        path: "solicitudes/edit/:id",
        pathName: "editSolicitudID",         
        props: { showSolicitud: true },
        nameOfRoute : "editSolicitudID"
      },
      {
        path: "efectos",
        pathName: "DEPOSITO JUDICIAL",        
        titulo: "DEPÓSITO JUDICIAL",
        nameOfRoute : "efectos"
      },
      {
        path: "efectos/new",
        pathName: "newEfecto",       
        titulo: "EFECTOS",
        nameOfRoute : "newEfecto" 
      },
      {
        path: "efectos/:id",
        pathName: "efectoID",        
        titulo: "EFECTOS",
        nameOfRoute : "efectoID"
      },   
      {
        path: "efectos/edit/:id",
        pathName: "editEfecto",       
        titulo: "EFECTOS", 
        nameOfRoute : "editEfecto"
      }
]