export const sumarioOficioConfig = [
  {
    path: "/actuaciones/new/sumario-oficio/initial",
    pathName: "sumario-oficio",
    titulo: "SUMARIO OFICIO",
    icon: "pi-bookmark",
    roles: [
      "User Tester",
      "Super User",
      "Jefe de Dependencia",
      "Oficial Sumariante",
      "Jefe de Sumario",
    ],
    type: "link",
    grupo: "SUMARIOS",
    props: {
      formType: "oficio",
      actuacionType: "sumario-oficio",
    },
    nameOfRoute : "actuacion-initial"
  },
  {
    path: "/actuaciones/new/sumario-oficio/initial/denunciante",
    pathName: "sumario-oficio",
    roles: [
      "User Tester",
      "Super User",
      "Jefe de Dependencia",
      "Oficial Sumariante",
      "Jefe de Sumario",
    ],
    titulo: "AFECTADOS",
    nameOfRoute : "actuacion-form-denunciante"
  },
  {
    path: "/actuaciones/new/sumario-oficio/initial/momento",
    pathName: "sumario-oficio",
    roles: [
      "User Tester",
      "Super User",
      "Jefe de Dependencia",
      "Oficial Sumariante",
      "Jefe de Sumario",
    ],
    titulo: "FECHA, HORA y UBICACIÓN",
    nameOfRoute : "actuacion-form-momento"
  },
  {
    path: "/actuaciones/new/sumario-oficio/initial/acusado",
    pathName: "sumario-oficio",
    roles: [
      "User Tester",
      "Super User",
      "Jefe de Dependencia",
      "Oficial Sumariante",
      "Jefe de Sumario",
    ],
    titulo: "VINCULADOS",
    nameOfRoute : "actuacion-form-acusado"
  },
  {
    path: "/actuaciones/new/sumario-oficio/initial/efectos",
    pathName: "sumario-oficio",
    roles: [
      "User Tester",
      "Super User",
      "Jefe de Dependencia",
      "Oficial Sumariante",
      "Jefe de Sumario",
    ],
    titulo: "EFECTOS",
    nameOfRoute : "actuacion-form-efectos"
  },
];
