<template>
  <div>
    <component v-for="item in items" ref="itemRefs" :is="item" :key="item.name"></component>
  </div>  
</template>

<script>

// import json from './tablesDataByIdName.json'
import SumarioDataTable from '../../pages/sumario/SumarioDataTable.vue';
import PersonaFisicaList from '../../pages/parametros/persona-fisica.vue';
import TipoUsuarioList from '../../pages/parametros/tipo-usuario.vue';
import DependenciaList from '../../pages/parametros/dependencia.vue';
import DepartamentalList from '../../pages/parametros/departamental.vue';
import sumario from '../../pages/sumario/SumarioHomePage.vue';
import JuzgadoList from '../../pages/parametros/juzgado.vue';
import CategoriaUfiList from '../../pages/parametros/ufis.vue';

export default {
  components: { PersonaFisicaList, TipoUsuarioList, DependenciaList, SumarioDataTable, sumario, JuzgadoList, CategoriaUfiList,DepartamentalList  },
  name: 'DinamicSelector',
  props: {
    field: {
      type: String,
      required: true
    },
    listComponent: {
      type: String
    },
  },
  data() {
    return {
      items: [],
      // data: json
    };
  },
  created(){
    let nameComponent = this.listComponent || this.field.replace("ID", "List")
    // console.log("open", nameComponent, this.listComponent)
    let component = this.$options.components[nameComponent];
    // if (!this.data[this.field]) return;
    
    let self = this;
    const renderComponent = {
      render (h) {         
        return h(component, {
          class: ['foo'],
          on: { selectItem: self.selectItem },
          props: { 
            modal: true

          }
        })
      }
    }
    this.items.push(renderComponent)
  },
  methods: {
    selectItem(itemId){
        this.$emit("selectItem", itemId);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  
</style>
