<template>
  <div class="ttable">
    <div class="table-header flex flex-column md:flex-row md:justify-content-between">
      <div class="flex md:justify-content-between toolbar" id="table-title">
        <div class="left flex">
          <div v-if="selectedCards.length > 0" class="align-self-end pb-2">
            <Button icon="pi pi-pencil"
              class="p-button-rounded p-button-outlined p-button-warning roundeds-buttons mr-2"
              @click="goToEditMode(selectedCards[0], idTable)"
              v-if="enableEditButtons && selectedCards.length === 1 && !modal" id="boton-editar" test-id="buttonEdit" />
            <Button icon="pi pi-check" class="p-button-rounded p-button-outlined p-button-warning roundeds-buttons mr-2"
              @click="modalSelectItem(selectedCards[0], idTable)" v-if="selectedCards.length == 1 && modal"
              id="boton-editar" test-id="buttonEdit" />

            <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger roundeds-buttons mr-2"
              @click="confirmMassDelete()" v-if="enableDeleteButton && !modal" id="boton-papelera"
              test-id="buttonDelete" />
          </div>
          <div>
            <!-- PARA CACHE DE EFECTOS -->
            <!-- <TTableFilters ref="filters" :apiversion="apiversion" :dropdown="dropdown" :filtersFields="filtersFields"
              :enableFilterMultiple="enableFilterMultiple" @search="handleSearch"
              :getLimit="getLimit" /> -->
              <TTableFilters ref="filters" :apiversion="apiversion" :dropdown="dropdown" :filtersFields="filtersFields"
              :enableFilterMultiple="enableFilterMultiple" @search="(cleanFilters) => getData(cleanFilters)"
              :getLimit="getLimit" />
          </div>
        </div>
        <div class="right flex pb-2">
          <!-- <Button icon="pi pi-refresh" label="Actualizar" class="mr-2 align-self-end  p-button-secondary"
            @click="getData('refresh')" /> -->

          <Button v-if="enableAddButton.enabled && !modal" icon="pi pi-plus" label="Agregar" class="align-self-end"
            @click="$router.push({ name: enableAddButton.name, params: enableAddButton.params })" id="boton-agregar"
            test-id="buttonAdd" />
        </div>
      </div>
    </div>
    <div class="table-container">
      <DataTable :value="data" test-id="table" class="template-table" :rows="rows" :resizableColumns="resizableColumns"
        :columnResizeMode="columnResizeMode" showGridlines responsiveLayout="scroll" dataKey="id" :filters="filters"
        :scrollable="true" style="width: 100%" editMode="cell" :loading="loading" @row-reorder="onRowReorder"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 30]" :paginator="true" :totalRecords="totalRows" :lazy="getLimit != undefined"
        @page="handlePageChange($event)" id="DTid">
        <div id="double-ellipsis-icon-id" v-if="ordenableTable">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false"
            rowReorderIcon="double-ellipsis-icon" test-id=""><template #body="slotProps">
              <Button :draggable="true" rowReorderIcon="double-ellipsis-icon" icon="double-ellipsis-icon"
                :test-id="`drag${slotProps.data.index}`" :style="{
                  backgroundColor: 'transparent',
                  border: 'none',
                }">
              </Button> </template></Column>
        </div>
        <template #header> </template>
        <template #empty>
          <div v-if="err === false">
            {{ emptyMsg === null ? 'No hay items para mostrar.' : emptyMsg }}
          </div>
          <div v-if="err === true">
            No se ha podido cargar la tabla, por favor intente más tarde.
          </div>
        </template>
        <template #loading> Descargando, espere por favor. </template>
        <Column test-id="column-data">
          <template #body="slotProps">
            <div :test-id="'fila-checkbox-nombre' + slotProps.data.index">
              <CustomCheckbox :idc="'fila-checkbox-nombre' + slotProps.data.index"
                :ref="'filaCheckboxNombre' + slotProps.data.index" :value="slotProps.data"
                v-model="selectedRow[slotProps.data.index]"
                :disabled="selection && selectedCards[0].index !== slotProps.data.index"
                @click="clickCustomCheckbox(slotProps.data, multiSelect)" />
            </div>
          </template>
          <template #header>

          </template>
        </Column>

        <Column v-for="(column, index) in customColumn" :field="column.title" :header="getHeader(column.title)"
          :key="column.title" :headerStyle="getStyle('header', column.title)"
          :bodyStyle="getStyle('body', column.title)" :bodyClass="getProp('class', column.title, 'body')" sortable>
          <template #body="slotProps">
            <slot :name="column.title + 'Body'" v-bind:data="slotProps.data" v-bind:index="slotProps.index"
              v-if="$scopedSlots[column.title + 'Body']"></slot>
            <div :test-id="column.title + slotProps.index" :id="'fila-nombre' + slotProps.index"
              :class="getClassesDivContainer(column.title, slotProps.data)" v-if="!$scopedSlots[column.title + 'Body']">
              <!-- {{ slotProps.data[customName[index]] }} -->
              {{ formatData(customName[index], slotProps.data) }}
            </div>
          </template>
        </Column>
        <Column v-if="enableViewButtons">
          <template #body="slotProps">
            <div class="flex justify-content-end">
              <router-link :to="`/${reference}/${slotProps.data[idTable]}?view=true`" class="decoration__links">
                <Button v-tooltip.left="'Visualizar este efecto'" icon="pi pi-eye"
                  class="p-button-rounded p-button-outlined mr-2" />
              </router-link>
              <Button 
                icon="pi pi-trash" 
                class="p-button-rounded p-button-outlined p-button-danger roundeds-buttons mr-2"
                @click="handleDeleteItem(slotProps.data)"
                :disabled="slotProps.data.Estado === 'Precarga'" 
                v-tooltip.left="'Eliminar elemento'"
                v-if="reference === 'deposito/efectos'"
                test-id="buttonDelete" 
              />
            </div>
          </template>
        </Column>
        <template #paginatorstart>
          <div class="flex align-items-center" v-if="getLimit != undefined">
            <b>
              <span class="pagination">{{
                `Mostrando registros del ${offset} al ${offset + (getLimitByDropDown === null ? getLimit : getLimitByDropDown) > totalRows
                  ? totalRows
                  : offset + (getLimitByDropDown === null ? getLimit : getLimitByDropDown)
                } de un total de ${totalRows}`
              }}</span>
            </b>
          </div>
        </template>
        <template #paginatorend />
      </DataTable>
    </div>

    <div>
      <!-- <TTablePagination
        :page="page"
        :totalRows="totalRows"
        :offset="offset"
        :limit="getLimit"
        @setPage="setPage" /> -->

      <!-- {{ page }} -->
      <!-- <Paginator :first.sync="offset" :rows="rows" :totalRecords="totalRows" :rowsPerPageOptions="[10,20,30]" @page="handlePageChange($event)"></Paginator> -->
    </div>
    <!-- ""{{!getLimit}}"" -->
    <div v-if="!loading" style="display: flex; align-self: flex-start">
      <FileUpload v-if="enableUploadFile" uploadLabel="Importa XLS" chooseLabel="Importar XLS" mode="basic"
        name="userfile" withCredentials :url="url + '?importCSV=1'" accept=".xlsx" :maxFileSize="2000000" />
      <Button v-if="enableDownloadFile" id="bottonXLS" label="Descargar Excel" @click="downloadXls()" />
    </div>
    <ModalTemplate
      title="Confirmación de Eliminación"
      type="confirmativo"
      message="¿Está seguro que desea eliminar los elementos seleccionados?"
      :showIcon="true"
      :dismissableMask="true"
      severity="danger"
      @confirm="onConfirmMassDelete"
      @cancel="onCancelMassDelete"
      ref="ModalMassDelete"
    />
    <ModalTemplate
      title="Confirmación de Eliminación"
      type="confirmativo"
      message="¿Está seguro que desea eliminar el elemento seleccionado?"
      :showIcon="true"
      :dismissableMask="true"
      severity="danger"
      @confirm="onConfirmDelete"
      @cancel="onCancelDelete"
      ref="ModalDelete"
    />
  </div>
</template>

<script>

import axios from "axios";
import tableRest from './mixins/tableRest.js'
import ModalTemplate from "../ModalTemplate.vue";
import components from './mixins/components.js'
//↓importacion de cache de efectos
// import tanstackEfectos from "./mixins/tanstackEfectos";
export default {
  mixins: [
    tableRest, components
  ],
  // mixins: [
  //   tableRest, components, tanstackEfectos
  // ],
  name: "TemplateTable",
  components: { ModalTemplate },

  props: {
    disableButtonEdit: {
      type: Boolean, //string que se usará para habilitar la barra de busqueda global
    },
    filtersFields: {
      type: Array,
    },
    titles: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    formatDoc: {
      type: Function,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    apiversion: {
      type: String,
      default: "1",
    },
    keyShow: {
      type: Array,
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    multipleFilter: {
      type: Boolean,
      default: false,
    },
    resizableColumns: {
      type: Boolean,
      default: false,
    },
    removeLimit: {
      type: Boolean,
      default: false,
    },
    columnResizeMode: {
      type: String,
      default: "fit",
    },
    idTable: {
      type: String,
    },
    tableTitle: {
      type: String, //string que se usará como título de la tabla
    },
    reference: {
      type: String, //string que se usará como título de la tabla
    },
    viewReference: {
      type: String, //string que se usará como título de la tabla
    },
    enableEditButtons: {
      type: Boolean, //bool que se usará para habilitar los controles de edicion
    },
    enableViewButtons: {
      type: Boolean, //bool que se usará para habilitar los controles de edicion
      default: false
    },
    enableDeleteButton: {
      type: Boolean, //bool que se usará para habilitar los controles de edicion
      default: true,
    },
    modal: {
      type: Boolean, //bool que se usará para habilitar los controles de edicion
    },
    enableGlobalSearch: {
      type: Boolean, //string que se usará para habilitar la barra de busqueda global
    },
    enableSelectAllCheckbox: {
      type: Boolean, //string que se usará para habilitar la barra de busqueda global
    },
    multiSelect: {
      type: Boolean, //string que se usará para habilitar la barra de busqueda global
      default: true,
    },
    enableAddButton: {
      type: Object, //object que se usará para habilitar el boton de agregar y la ruta a la que navegar
      default: () => ({}),
    },
    enableUploadFile: {
      type: Boolean,
      default: false,
    },
    enableDownloadFile: {
      type: Boolean,
      default: false,
    },
    enableFilterMultiple: {
      type: Boolean,
      default: false,
    },
    enableRefresh: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Object,
      default: () => ({}),
    },
    ordenable: {
      type: Object,
    },
    emptyMsg: {
      type: String,
      default: null
    },
    classesDivContainer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rows: 10,
      ordenableTable: false,
      dataCopy: [],
      secFiliterValue: null,
      values: {},
      filters: {},
      itr: 0,
      search: "",
      selectedRow: {},
      selectedCards: [],
      showButtons: true,
      selection: false,
      cbHeader: false,
      customName: [],
      customColumn: [],
      filterMultipleSelect: [],
      filterType: [],
      dropSelect: false,
      dropValue: null,
      filtro: "",
      err: true,
      getLimitByDropDown: null,
      itemToDelete: null,
      forbiddenEditRoutes: [
        {
          'parametros/tipo-diligencia/edit': [
            'ufi-denuncia-legajo-ufis-oficio',
            'ecpd5fe4-7957-11ec-816d-16c2a030bb97',
            'ufiF5fe4-7957-11ec-816d-16c2a030bb97',
            'preliminar-7957-11ec-816d-16c2a030bb98',
            'SPpD-11ec-816d-16c2a030bb97',
            'SPpO-22ec-926d-16c2a030bb97',
            'ufi-denuncia-legajo-ufis-denuncia',
            'feca5fe4-7957-11ec-816d-16c2a030bb97'
          ]
        }
      ]
    };
  },
  async mounted() {
    // console.log("filterField: ",JSON.stringify(this.filterField))
    // setTimeout(() => {
    //   // console.log('this.dropdown', this.dropdown);
    // }, 14000);
    if (window.Cypress) {
      window.TemplateTable = this;
    }
    //↓ --------------Cache de efectos--------------------
    // if (this.reference === "deposito/efectos") {
    //   this.totalRows = await this.getTotalRowsCached() || await this.fetchTotalRows(),
    //   this.loading = true
    //   const cachedFirstPage = await this.getEfectosCachedByPage(1);
    //   if (cachedFirstPage) {
    //   // Usar datos cacheados
    //   this.data = cachedFirstPage;
    // } else {
    //   // Si no hay datos cacheados, realizar las peticiones
    //   this.data = await this.getEfectosTanStackByPage(1, this.getLimit, true);
    // }

    // // Prefetch de la segunda página
    // const cachedSecondPage = await this.getEfectosCachedByPage(2);
    // if (!cachedSecondPage) {
    //   this.prefetchNextPage(2, this.getLimit, true);
    // }
    //   this.loading = false
    // }else{
    //   this.getData();
    // }
    //↑ --------------fin Cache de efectos--------------------

    this.getData();




      // setTimeout(() => {
    //   if(this.err === true){
    //     this.loading = false
    //   }
    // }, 15000);
    // setTimeout(() => {
    //   console.log('this.data', this.data);
    // }, 15000);
  },
  async created() {
    //compatibilidad con anterior configuracion
    // console.log("this.keyShow", this.keyShow)

    for (let i in this.keyShow) {
      // console.log("read keyshow", i, this.keyShow[i])
      this.params[this.keyShow[i]] ??= {};
    }
    // console.log('this.params', this.params)
    // console.log('this.dropdown', this.dropdown)
    // for (let i in this.dropdown) {
    //   this.params[i].dropdown = this.dropdown[i];
    // }

    // console.log('this.titles', this.titles)
    for (let i in this.titles) {
      // console.log("i", i)
      if (this.params[i]) this.params[i].title = this.titles[i];
    }
    // console.log('this.styles', this.styles)
    for (let i in this.styles) {
      // console.log("i", i)
      if (i == "default") continue;
      if (this.params[i]) this.params[i].style = this.styles[i];
    }
    for (let i in this.classesDivContainer) {
      if (this.params[i]) this.params[i].classesDivContainer = this.classesDivContainer[i];
    }
    // console.log('this.params', this.params);

    // console.log('this.params', this.params)
    for (let i in this.params) {
      // console.log('set params', i)
      this.params[i].class ??= {};
      this.params[i].style ??= {};
      // this.params[i].dropdown ??= ''
      this.params[i].title ??= i;
    }

    // console.log('this.params fin', JSON.parse(JSON.stringify(this.params)))

    var keyShow = Object.keys(this.params);
    for (let i in keyShow) {
      this.customName.push(keyShow[i]);
      this.customColumn.push({ title: keyShow[i] });
    }
  },
  methods: {
    formatData(field, doc) {
      // console.log('field', field);
      // console.log('doc', doc);
      //  console.log("formatData>>>>>>>>>>>>>>>>", field, doc);
      if (this.params[field].format) return this.params[field].format(doc);
      let v;
      try {
        v = this.formatDoc(field, doc);
      } catch (e) {
        // console.error("fallo formatdoc"),
        v = doc[field];
      }
      return v;
    },
    /**
     * Para utilizarlo tenes que enviarle un objeto a TemplateTable llamado "ordenable" con :{
          url:"la url que va a hacer el put con el orden",
          id:"nombre del id de la tabla",
          variableOrden:"el nombre del atributo que contiene el orden"
      }
    */
    async onRowReorder(e) {

      let url = this.ordenable.url;
      let val = e.value;
      this.data = await val;
      let dataCopy = JSON.parse(JSON.stringify(this.data));
      this.loading = true;
      for (let i = 0; i < dataCopy.length; i++) {
        this.data[i][this.ordenable.variableOrden] = `${i}`;
        await axios.put(url + `/${this.data[i][this.ordenable.id]}`, this.data[i], {
          withCredentials: true,
        });
      }
      this.loading = false;
    },


    getHeader(field) {
      return this.params[field].title;
    },
    getStyle(type, field) {
      // if (!this.styles[field]) {
      // if (!this.styles["default"]) return "";
      //   return this.styles["default"][type] || "";
      // }
      return this.params[field].style[type] || "";
    },

    getProp(prop, field, type) {
      // console.log('getProp', prop, field, type)
      // console.log("this.params[field]", field, this.params,  this.params[field])
      if (type) return this.params[field][prop][type] || "";
      return this.params[field][prop];
    },
    getClassesDivContainer(field, data) {
      switch (typeof this.params[field].classesDivContainer) {
        case 'function':
          return this.params[field].classesDivContainer(data[field])
        case 'undefined':
          return ''
        case 'string':
          return this.params[field].classesDivContainer
        default:
          return ''
      }
    },
    getTableData() {
      return this.selectedCards;
    },
    getLoading() {
      return this.loading;
    },
    setLoading(value) {
      this.loading = value;
    },
    getCausaCaratula(data) {
      return data.causaCaratula ? data.causaCaratula.nombre : "";
    },
    getDate(date) {
      const d = new Date(date);
      return d.toLocaleDateString();
    },
    getItem(data) {
      // console.log("getItem", data)
      //prop del item que se mostrará en la lista
      if (data.list.length > 1) {
        return data.list[0].doc.apellido + ", " + data.list[0].doc.nombre;
      }
    },
    modalSelectItem(doc, id) {
      // console.log("modalSelectItem", doc, id)
      this.$emit("selectItem", doc[id]);
    },
    goToEditMode(doc, id) {
      // console.log(`/${this.reference}/${doc[id]}`)
      if(!this.detectRestrictedEditIDs(this.reference, doc[id])){
        this.$rest.toast_open({message: "El item seleccionado no se puede editar",position: "top-right",type: "error", duration: 3000,});
        return;
      }
      this.$router.push(`/${this.reference}/${doc[id]}`);
    },
    detectRestrictedEditIDs(doc, id) {
      let allowEdit = true;
      this.forbiddenEditRoutes.map( route => {
        if(Object.keys(route)[0] === doc){
          route[Object.keys(route)[0]].map( IdRoute => {
            if(IdRoute === id) allowEdit = false;
          });
        }
      });
      return allowEdit;
    },
    // goToViewMode(doc, id) {
    //   // console.log(`/${this.reference}/${doc[id]}`)
    //   this.$router.push(`/${this.reference}/${doc.data[id]}?view=true`);
    // },
    handleDeleteItem(doc){
      this.itemToDelete = doc;
      this.$refs.ModalDelete.open();
    },  
    async onConfirmDelete() {
      try {
        await this.deleteItem( [this.itemToDelete], this.idTable);
        this.$refs.ModalDelete.close();
        this.getData();
        this.$toast.open({
          message: "Se borro correctamente el elemento de la tabla",
          position: "top-right",
          type: "success",
          duration: 3000,
        });
        this.itemToDelete = null; 
      } catch (error) {
        console.error("Error al eliminar el elemento:", error);
      }
    },
    onCancelDelete() {
      this.$refs.ModalDelete.close(); // Cierra el modal
      this.itemToDelete = null; // Limpia el ID guardado
    },
    async deleteItem(doc, id) {
      if (this.$route.name) {
        let res = await axios.get(
          process.env.VUE_APP_BACKEND_ENVIRONMENT + "/permiso-tipo-usuario",
          { withCredentials: true }
        );
        let arrayPermissionTypeUser = res.data.data;

        // console.log("id: "+doc[0].PermisousuarioID)

        // let i=0, aux
        // while(i < doc.length && !aux)
        // {
        let aux = doc.find((element) =>
          arrayPermissionTypeUser.find(
            (elem) => elem.PermisousuarioID == element.PermisousuarioID
          )
        );
        //   i++;
        // }
        if (aux) {
          alert(
            "No es posible Eliminar el/los Permisos seleccionados, por que se encuentran asignados a un Tipo de Usuario"
          );
        } else {
          this.elimina(doc, id);
        }

        // console.log("arrayPermissionTypeUser: "+ JSON.stringify(arrayPermissionTypeUser))
        // console.log("aux: "+aux)
      } else {
        // console.log("doc"+JSON.stringify(doc))
        // console.log("id"+JSON.stringify(id))

        this.elimina(doc, id);
      }
    },
    async elimina(doc, id) {
      const error = false;
      this.loading = true;
      for (let i in doc) {
        // console.log("esto dice que: "+doc[i][id]);
        try {
          await this.$rest.delete({ url: `${this.url}/${doc[i][id]}` });
        } catch (e) {
          error = true;
        }
      }

      if (!error) {
        this.getData()
        // console.log('no hay error')
        // this.$router.go(0); //lo comento por que actualiza todo y si hay filtros los borra.
      } else {
        console.log("Ocurrio un error al eliminar alguno de los elementos");
      }
    },

    onCellEdit(event, doc, field) {
      //Actualización de datos de la tabla
      // console.log(this.data)
      // console.log(this.data[doc.index][field])
      // console.log(doc.index)
      // console.log(field)
      // console.log(event.target.value)
      this.data[doc.index][field] = event.target.value;
      // console.log(this.data)
      // console.log(this.data[doc.index][field])
    },
    headerCheckbox() {
      if (!this.cbHeader) {
        //Al hacerle click al primer checkbox de la tabla, se marcan los demás
        this.$refs.filaCheckboxNombre0.onClick();
        this.$refs.filaCheckboxNombre0.onBlur();
        let newSelectedRow = {};
        //Marcamos todos los checkbox
        for (let i in this.data) {
          this.selectedCards[i] = this.data[i];
          newSelectedRow[i] = true;
        }
        this.selectedRow = newSelectedRow;
      } else {
        //Caso contrario, los desmarcamos
        this.selectedCards = [];
        this.selectedRow = {};
      }
    },
    clickCustomCheckbox(item, multiSelect) {
      //Desmarcamos el checkbox que marca los demás
      // console.log("clickCustomCheckbox", item, multiSelect)
      if (this.cbHeader) this.cbHeader = false;
      let searchId = this.selectedCards.filter((idItem) => idItem.index === item.index);
      // multiSelect decide si la tabla devuelve un obj, una lista de obj
      //Buscamos si el item marcado ya estaba seleccionado
      //De estarlo lo sacamos de 'selectedCards', caso contrario lo ponemos
      if (!multiSelect) {
        this.selectedCards.push(item);
        this.selection = !this.selection;
       
      } else {
        if (searchId.length == 0) {
          this.selectedCards.push(item);
        } else {
          let newSelectedCards = [];
          for (let i in this.selectedCards) {
            if (item.index != this.selectedCards[i].index) {
              newSelectedCards.push(this.selectedCards[i]);
            }
          }
          this.selectedCards = newSelectedCards;
        }
      }
    },
    handlePageChange(event) {
      if (this.getLimit == undefined) return
      this.setPage(event.page, event.rows);
    },
    // ↓ para cache de efectos
    // async handlePageChange(event) {
    //   if (this.getLimit == undefined) return
    //   const currentPage = event.page + 1
    //   if(this.tableTitle === 'Deposito Judicial'){
    //     const cachedData = await this.getEfectosCachedByPage(currentPage);
    //   if (cachedData) {
    //     this.data = cachedData;
    //   } else {
    //     this.data = await this.getEfectosTanStackByPage(currentPage, this.getLimit);
    //   }
    //   // Prefetch de la siguiente página
    //   this.prefetchNextPage(currentPage + 1, this.getLimit);
    //     }else{
    //       this.setPage(event.page, event.rows);
    //     }
    // },
    // async handleSearch(filters) {
    //   if (this.tableTitle === "Deposito Judicial") {
    //     this.loading = true;
    //     this.invalidateEfectosQuery()
    //     this.totalRows = await this.fetchTotalRows(false)
    //     this.data = await this.getEfectosTanStackByPage(1, this.getLimit, false);
    //     await document.getElementById('DTid').childNodes[16].childNodes[1].click()
    //     this.prefetchNextPage(2, this.getLimit, false);
    //     this.loading = false;
    //   } else {
    //     this.getData(filters);
    //   }
    // },
    setPage(page, getLimit) {
      if (page + 1 == this.page && (getLimit == this.getLimit && this.rows == getLimit)) return
      if (getLimit !== this.getLimit || this.rows !== getLimit) {
        this.getLimitByDropDown = getLimit;
        this.rows = getLimit;
      }
      this.loading = true;
      this.offset = page * getLimit;
      this.page = page + 1;
      this.getData();
    },
    confirmMassDelete() {
      this.$refs.ModalMassDelete.open();
    },

    async onConfirmMassDelete() {
      try {
        this.deleteItem(this.selectedCards, this.idTable)
        this.selectedCards = [];
        this.$refs.ModalMassDelete.close();
        this.getData(); // Refresh data
      } catch (error) {
        console.error("Error al eliminar elementos: ", error);
      }
    },

    onCancelMassDelete() {
      this.$refs.ModalMassDelete.close();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .siis-datatable table {
  display: block;
  padding: 10px;
}
.siis-datatable thead, .siis-datatable tbody {
  display: block;
  padding: 10px;
} */

/* .table-container  */
.siis-datatable table {
  border-spacing: 0;
  table-layout: fixed;
}

.siis-datatable th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;

  cursor: default;
  color: #495057;
  background: #f8f9fa;
  background-clip: border-box;
  transition: box-shadow 0.2s;
  border-width: 1px 1px 1px 1px;
}

.siis-datatable td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 1px;
  padding: 1rem 1rem;

  cursor: default;
}

.siis-datatable td>div {
  /* display: block; */
  word-wrap: normal;
  white-space: normal;
  max-width: 100%;
  display: block;
  /* padding: 10px; */
}

.siis-datatable th+th,
.siis-datatable td+td {
  border-left-width: 0;
}

/* .siis-datatable table thead tr th, .siis-datatable  table tbody tr td, .siis-datatable tr, .siis-datatable thead, .siis-datatable tbody, .siis-datatable table {
  display: block;
  padding: 10px;
}
.siis-datatable table thead tr th, .siis-datatable  table tbody tr td, .siis-datatable tr, .siis-datatable thead, .siis-datatable tbody, .siis-datatable table {
  display: block;
  padding: 10px;
} */
</style>
<style scoped>
.ttable {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 10px;
}

.ttable>div>* {
  margin-left: 10px;
}

.ttable>.right {
  display: flex;
  flex-direction: row-reverse;
}

.toolbar {
  width: 100%;
}

.declaracion {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.action-buttons {
  text-align: right;
}

.headerItem {
  width: 250px;
}

.mb-input {
  margin-bottom: 20px;
}

#contSpan {
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#filterSelec {
  display: flex;
  flex-direction: column;
}

#boton-agregar {
  margin: 0;
  display: flex;
}

#itemFilter {
  display: inline-block;
  margin: 10px;
  padding: 0;
}

#filtro-multiple {
  display: inline;
  margin-bottom: 10px;
  /* margin-left: 15px; */
}

@media (max-width: 800px) {
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
}

span {
  margin: 0 15px 15px 0;
}

.pagination {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  margin: 0.143rem;
  padding: 0 0.5rem;
}

/* >>> .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 0.2rem
    #fbfbfb;
    border-color: #ced4da
  }
  >>> .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus::hover {
    border-color: #2196F3
  }  */
</style>
