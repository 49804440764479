//UFIs: 'ufi-emergencias-medicas', 'ufi-siniestro-vial', 'ufi-ahorcamiento-suicidio', 'ufi-incendio-vivienda' and 'ufi-intoxicacion'      
export default {
    computed: {
        PreformUFIDelitosEspeciales:{
            get (){
                let dateDenuncia = {
                    withStyles:   `<p style="color: #55b5ea ; display: inline">${this.dateDenuncia}</p>,`,
                    noStyles: this.dateDenuncia + ','
                }
                
                //changing the last character for a ';'
                dateDenuncia.withStyles =  dateDenuncia.withStyles.slice(0, -1)
                dateDenuncia.withStyles =  dateDenuncia.withStyles + ";"
                dateDenuncia.noStyles =  dateDenuncia.noStyles.slice(0, -1)
                dateDenuncia.noStyles =  dateDenuncia.noStyles + ";"
                
                let constance = {
                    withStyles:   ` el funcionario de Policía que suscribe, Jefe Titular de esta Dependencia, con intervención del Secretario de Actuaciones que Refrenda, a los fines legales <p style="text-decoration: underline ; display: inline"> HACE CONSTAR:</p> `,
                    noStyles: ` el funcionario de Policía que suscribe, Jefe Titular de esta Dependencia, con intervención del Secretario de Actuaciones que Refrenda, a los fines legales HACE CONSTAR: `,
                }
                
                let constanceText = {
                    withStyles:   `Que en la fecha y hora indicada, se hizo presente personal de `,
                    noStyles: `Que en la fecha y hora indicada, se hizo presente personal de `,
                }
                
                constanceText.noStyles = constanceText.withStyles  
                
                let interveningPersonnel =  { 
                    withStyles:   `<p style="color: #1356cc ; display: inline">${this.dataResumen.personalIntervinienteDelitosEspeciales}</p>`,
                    noStyles: this.dataResumen.personalIntervinienteDelitosEspeciales !== undefined ? this.dataResumen.personalIntervinienteDelitosEspeciales : '',
                }
                
                let reporting ={ 
                    withStyles:   `,  informando que `,
                    noStyles: `,  informando que `,
                }
                
                if(this.textEditsCounter.Preform == 0)
                {
                    let Preform = {
                        withStyles: this.Preforma.noStyles + this.department.noStyles + this.dependencyUFI.noStyles + dateDenuncia.noStyles + constance.withStyles + constanceText.withStyles +  interveningPersonnel.withStyles + reporting.withStyles ,
                        noStyles: this.Preforma.noStyles + this.department.noStyles + this.dependencyUFI.noStyles + dateDenuncia.noStyles + constance.noStyles + constanceText.noStyles + interveningPersonnel.noStyles + reporting.noStyles
                    };
                    return Preform
                }   else
                {   
                    let Preform = {
                        withStyles : "vacio por que no se muestra, es solo para que no de error por undefined",
                        noStyles : this.textEstaticos.Preform,
                    }
                    return Preform
                }
            }
        },
        instructionDecreeUFIDelitosEspeciales:{
            get (){
                if(this.textEditsCounter.instructionDecree == 0){ 
                    let firstPart =   {
                        withStyles: `. Motivo por el cual se mantuvo comunicación telefónica con el <p style='color: #1356cc ; display: inline'>${this.dataResumen.assistant ? this.dataResumen.assistant : ''}</p>, Ayudante Fiscal en turno, quien dio la directiva de iniciar las actuaciones correspondientes. `,
                        noStyles: `. Motivo por el cual se mantuvo comunicación telefónica con el ${this.dataResumen.assistant ? this.dataResumen.assistant : ''}, Ayudante Fiscal en turno, quien dio la directiva de iniciar las actuaciones correspondientes. `,
                    }
                    
                    let secondPart =   {
                        withStyles: "En virtud de ello la Instrucción se <b><u>DISPONE:</b></u> Iniciar el Legajo de investigación que corresponda. Designar como secretario de las mismas al funcionario de Policía al pie firmante. Dar intervención a la",
                    }
                    
                    secondPart.noStyles =  secondPart.withStyles.replace('<b><u>DISPONE:</b></u>', 'DISPONE')
                    
                    let thirdPart =  {
                        withStyles:  ` <b style='text-decoration: underline'>U.F.I.</b> <b style='color: #1356cc ; text-decoration: underline'>${this.ActuacionUfi == null ? '' : this.ActuacionUfi.Numero }</b> ` ,
                        noStyles:  ` U.F.I. ${this.ActuacionUfi == null ? '' : this.ActuacionUfi.Numero } `,
                    }
                    
                    let fourthPart =  {
                        withStyles:  ` mediante la comunicación con el Ayudante Fiscal. Recibir, agregar y vincular a la causa, Acta de Procedimiento, a los fines legales correspondientes. Llevar a cabo toda otra medida de carácter legal que se estime de interés. <b style='text-decoration: underline'>CERTIFICO</b>.-`,
                        noStyles:  ` mediante la comunicación con el Ayudante Fiscal. Recibir, agregar y vincular a la causa, Acta de Procedimiento, a los fines legales correspondientes. Llevar a cabo toda otra medida de carácter legal que se estime de interés. CERTIFICO.-`,
                    }
                    
                    let instructionDecree = {
                        withStyles: firstPart.withStyles + secondPart.withStyles + thirdPart.withStyles + fourthPart.withStyles,
                        noStyles: firstPart.noStyles + secondPart.noStyles + thirdPart.noStyles  + fourthPart.noStyles,
                    }
                   
                    return instructionDecree 
                }   else{
                    let instructionDecree = {
                        noStyles:  this.textEstaticos.instructionDecree
                    }
                    
                    return instructionDecree                
                }
                                
            },
        },   
        // actOfStartUFI:{
        //     get (){
        //         let actOfStart = this.Preform.noStyles + ' ' + this.deposition + '. ' + this.instructionDecree.noStyles
        //         return actOfStart 
        //     }
        // } 
    }    
};