const emptyDoc =  {
  instruccion: {id:'sabe_leer_sabe_firmar', nombre:'Sabe leer y sabe firmar'},
  nacionalidad: {id:1 , nombre: "ARGENTINA"},
  tipoDocumento:{'id':'DNI','nombre':'DNI'},
  exhibeDocumento:{'id': 1,'nombre':'Sí'},
  tieneEstudios:{'id': 1,'nombre':'Sí'},
  condicion: { id: "Acusado", nombre: "Acusado"} ,

}
const emptyObject = {
  list: [
    {
      arrId: uuidv4(),
      doc: { ...emptyDoc },
      identikit: [],
      originalIdentikit: {},
      descriptionOffender: [],
      identikitQuestion: 1,
      type: 'empty',
      conocido: true,
      hasIdentikit: false,
      sipeSearch: false,
      emptyTitle: ''
    },
  ],
  emptyDoc: { ...emptyDoc }
}
const state = () => ({
  ...emptyObject
})

// actions
const actions = {

  async setAcusado ({ commit, state }, params) {
    // console.log(params, state)
    let empty = params.type === 'empty'
    await commit('setAcusado', params)
    if (empty) commit('addAcusado')
  },
  async deleteAcusadoByArrId ({ commit, getters }, arrId) {
    let index = await getters.getIndexAcusadoByArrId(arrId) 
    commit('deleteAcusado', index)
  },
  async setSipeSearch ({ commit, state }, value) {
    await commit('setSipeSearch', value) 
  },
  async setEmptyTitle ({ commit, state }, value) {
    await commit('setEmptyTitle', value) 
  },
  async saveOriginalIdentikitQuestion({ commit }, { arrId, questionNumber, answer }) {
    commit('setOriginalIdentikit', { arrId, questionNumber, answer });
  },
  async revertToOriginalIdentikitQuestion({ commit }, { arrId, questionNumber }) {
    commit('revertIdentikitQuestion', { arrId, questionNumber });
  },
  async clearAllOriginalIdentikit({ commit }) {
    commit('clearAllOriginalIdentikit');
  }
}

const getters = {
  acusadosTodos: state => {
    return state.list
  },
  getEmptyDocAcusado: state => () => {
    return state.emptyDoc;
  },
  acusadosTodosSelect: state => {
    return state.list.map(item => {
      if (item.type === 'empty') {
        return { 
          arrId: item.arrId, 
          name: item.emptyTitle, 
          type: item.type, 
          condicion: item.doc?.condicion 
        };
      }
      if (item.filiacionDesconocida === true) {
        return { 
          arrId: item.arrId, 
          type: item.type, 
          name: 'Filiación Desconocida' 
        };
      }
      if (!item.doc) {
        return { 
          arrId: item.arrId, 
          name: 'Datos incompletos', 
          type: item.type, 
          apodo: null, 
          condicion: null 
        };
      }
      if (!item.doc.nombre && !item.doc.apellido) {
        return { 
          arrId: item.arrId,  
          name: 'Datos incompletos', 
          type: item.type, 
          apodo: item.doc.apodo, 
          condicion: item.doc.condicion 
        };
      }
      if (!item.doc.nombre) {
        return { 
          arrId: item.arrId,  
          name: item.doc.apellido, 
          type: item.type, 
          apodo: item.doc.apodo, 
          condicion: item.doc.condicion 
        };
      }
      if (!item.doc.apellido) {
        return { 
          arrId: item.arrId,  
          name: item.doc.nombre, 
          type: item.type, 
          apodo: item.doc.apodo, 
          condicion: item.doc.condicion 
        };
      }
      return { 
        arrId: item.arrId,  
        name: item.doc.nombre, 
        apellido: item.doc.apellido, 
        documento: item.doc.documento,
        type: item.type, 
        apodo: item.doc.apodo, 
        condicion: item.doc.condicion 
      };
    });
  },
  acusadosTodosResumen: (state, getters) => {
    return getters.acusadosTodos.filter(todo => todo.type === 'doc')
  },
  acusadosCount: (state, getters ) => {
    return getters.acusadosTodos.length - 1
  },
  getNewAcusadoArrId: (state ) => {
    return state.list[ state.list.length - 1].arrId
  },
  getAcusadoByArrId: (state) => (arrId) => {
    return state.list.find(todo => todo.arrId === arrId)
  },
  getIndexAcusadoByArrId: (state) => (arrId) => {
    return state.list.findIndex(todo => todo.arrId === arrId)
  },
  getOriginalAnswerByQuestion: (state) => (arrId, questionNumber) => {
    const acusado = state.list.find(item => item.arrId === arrId);
    return acusado?.originalIdentikit[questionNumber] || null;
  },
}

// mutations
const mutations = {

  setAcusado (state, params) {
    state.list = state.list.map(n => {
      if ( n.arrId === params.arrId ) {
        params.doc.arrId = params.arrId
        params.descriptionOffender = params.descriptionOffender
        params.type = 'doc'
        return params
      }
      return n;
    })
  },
  addAcusado (state) {
    state.list = [...state.list, {
      arrId: uuidv4(),
      doc: {...emptyDoc },
      identikit: [],
      descriptionOffender: [],
      identikitQuestion: 1,
      type: 'empty',
      conocido: true,
      hasIdentikit: false,
      emptyTitle: '',
      originalIdentikit: {}
    }]
  },
  deleteAcusado (state, payload) {
    state.list = [
      ...state.list.slice(0, payload),
      ...state.list.slice(payload + 1)
    ]
  },
  setSipeSearch(state, payload){
    let acusado = state.list.find(todo => todo.arrId === payload.arrId) 
    acusado.sipeSearch = payload.sipeSearch
  },
  setEmptyTitle(state, payload){
    // console.log('state', state);
    // console.log('payload', payload);
    let acusado = state.list.find(todo => todo.type === 'empty') 
    let index = state.list.indexOf(acusado) 
    // console.log('index', index);
    if(state.list[index]?.emptyTitle != undefined ){//Este if es solo para que no surja un Warning la primera vez que se llama (ya que emptytitle aun no existe)
      state.list[index].emptyTitle  = payload
    }
  },

  setOriginalIdentikit(state, { arrId, questionNumber, answer }) {
    const acusado = state.list.find(todo => todo.arrId === arrId);
    if (acusado) {
      acusado.originalIdentikit[questionNumber] = answer;
    }
  },
  revertIdentikitQuestion(state, { arrId, questionNumber }) {
    const acusado = state.list.find(todo => todo.arrId === arrId);
    if (acusado && acusado.originalIdentikit[questionNumber]) {
      const originalAnswer = acusado.originalIdentikit[questionNumber];
      const questionIndex = acusado.identikit.findIndex(item => item.questionNumber === questionNumber);
      if (questionIndex !== -1) {
        acusado.identikit[questionIndex].answer = originalAnswer;
      }
    }
  },
  clearAllOriginalIdentikit(state) {
    state.list.forEach(acusado => {
      acusado.originalIdentikit = {};
    });
  }
}


export default {
  emptyObject,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

function uuidv4(){
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}