<template>
  <div class="dinamic-viewer">
    <!-- viewer -->
    <component v-for="item in items" ref="itemRefs" :is="item" :key="item.name"></component>
  </div>  
</template>

<script>

// import json from './tablesDataByIdName.json'
import SumarioViewer from '../../pages/sumario/SumarioViewer.vue';
import PersonaFisicaViewer from '../../pages/parametros/add-edit/persona-fisica.vue';
import TipoUsuarioViewer from '../../pages/parametros/add-edit/tipo-usuario.vue';
import DependenciaViewer from '../../pages/parametros/add-edit/dependencia.vue';
import DepartamentalViewer from '../../pages/parametros/add-edit/departamental.vue';
import JuzgadoViewer from '../../pages/parametros/add-edit/juzgado.vue';
import CategoriaUfiViewer from '../../pages/parametros/add-edit/ufis.vue';
export default {
  components: { PersonaFisicaViewer, TipoUsuarioViewer, DependenciaViewer, SumarioViewer, JuzgadoViewer, CategoriaUfiViewer,DepartamentalViewer },
  name: 'DinamicViewer',
  props: {
    field: {
      type: String,
      required: true
    },
    viewerID: {
      type: String,
      required: true
    },
    viewerComponent: {
      type: String,
    }
  },
  data() {
    return {
      items: [],
      // data: json
    };
  },
  created(){
    // PersonafisicaList
    let nameComponent = this.viewerComponent || this.field.replace("ID", "Viewer")
    let component = this.$options.components[nameComponent];
    let self = this;
    const renderComponent = {
      render (h) {         
        return h(component, {
          // class: ['foo'],
          // on: { selectItem: self.selectItem },
          props: { 
            viewer: true,
            viewerID: self.viewerID
          }
        })
      }
    }
    this.items.push(renderComponent)
    // console.log("items", this.items)
  },

}
</script>

<style scoped>
  
</style>
