// import Sumario from '@/store/modules/sumario'

var emptyDoc =  () => ({

  instruccion: {id:'sabe_leer_sabe_firmar', nombre:'Sabe leer y sabe firmar'},
  nacionalidad: { id: 1, nombre: "ARGENTINA" },
  condicion: { id: "Denunciante y Damnificado", nombre: "Denunciante y Damnificado"} ,
  tipoDocumento:{'id':'DNI','nombre':'DNI'},
  exhibeDocumento:{'id': 1,'nombre':'Sí'},
  tieneEstudios:{'id': 1,'nombre':'Sí'}
})

const emptyObject = () => ({
  list: [
    {
      arrId: uuidv4(),
      doc: { ...emptyDoc() },
      type: 'empty',
      sipeSearch: false,
      emptyTitle: ''
    },
  ],
  emptyDoc: { ...emptyDoc() }
})
const state = Object.assign({}, emptyObject());
// actions
const actions = {

  async setDenunciante ({ commit, state }, params) {
    // console.log("params: ",params," \n state: ", state)
    // console.log('params', params)
    let empty = params.type === 'empty'
    await commit('setDenunciante', params)
    //if (state.list.length==1)
    if (empty) commit('addDenunciante')
  },
  async deleteDenuncianteByArrId ({ commit, getters }, arrId) {
    let index = await getters.getIndexDenuncianteByArrId(arrId) 
    commit('deleteDenunciante', index)
  },
  async setSipeSearch ({ commit, state }, value) {
    await commit('setSipeSearch', value) 
  },
  async setEmptyTitle ({ commit, state }, value) {
    await commit('setEmptyTitle', value) 
  },
  setEmptyDoc({commit}, doc){
    // console.log("setEmptyDoc", doc)
    commit('setEmptyDoc', doc) 
  }
}

const getters = {
  denuncianteTodos: state => {
    return state.list.filter(todo => todo.type === 'doc')
  },
  getEmptyDocDenunciante: state => () => {
    return state.emptyDoc;
  },

  denuncianteTodosSelect: state => {
    return state.list.map(item => {
        // Si el tipo es 'empty', devolver un objeto con título vacío
        if (item.type === 'empty') {
          return { 
            arrId: item.arrId, 
            name: item.emptyTitle, 
            type: item.type, 
            condicion: item.doc?.condicion 
          };
        }
        // Si es "Orden público", devolver un objeto específico
        if (item.ordenPublico === true) {
          return { 
            arrId: item.arrId, 
            type: item.type, 
            name: 'Orden público', 
            condicion: item.doc?.condicion 
          };
        }
        // Validar que `item.doc` exista antes de acceder a sus propiedades
        if (!item.doc) {
          return { 
            arrId: item.arrId, 
            name: 'Datos incompletos', 
            type: item.type, 
            condicion: null 
          };
        }

        // Si no tiene nombre ni apellido, devolver "Datos incompletos"
        if (!item.doc.nombre && !item.doc.apellido) {
          return { 
            arrId: item.arrId,  
            name: 'Datos incompletos', 
            type: item.type, 
            condicion: item.doc.condicion 
          };
        }

        // Si solo tiene apellido
        if (!item.doc.nombre) {
          return { 
            arrId: item.arrId,  
            name: item.doc.apellido,
            documento: item.doc.documento, 
            type: item.type,  
            condicion: item.doc.condicion 
          };
        }
        // Si solo tiene nombre
        if (!item.doc.apellido) {
          return { 
            arrId: item.arrId,  
            name: item.doc.nombre,
            documento: item.doc.documento, 
            type: item.type,  
            condicion: item.doc.condicion 
          };
        }
        // Si tiene nombre y apellido, concatenarlos
        return { 
          arrId: item.arrId,  
          name: item.doc.nombre,
          apellido: item.doc.apellido,
          documento: item.doc.documento, 
          type: item.type, 
          condicion: item.doc.condicion 
        };
    });
  },
  
  denuncianteTodosResumen: (state, getters) => {
    //console.log("getters.denuncianteTodosSelect++++++++", getters.denuncianteTodosSelect)
    return getters.denuncianteTodosSelect.filter(todo => todo.type === 'doc')
  },

  denuncianteCount: (state, getters ) => {
    return getters.denuncianteTodos.length - 1
  },
  getNewDenuncianteArrId: (state ) => {
    return state.list[ state.list.length - 1].arrId
  },
  getDenuncianteByArrId: (state) => (arrId) => {
    return state.list.find(todo => todo.arrId === arrId)
  },
  getIndexDenuncianteByArrId: (state) => (arrId) => {
    let index = state.list.findIndex(todo => todo.arrId === arrId);
    if(index === -1) index = state.list.findIndex(todo => todo?.doc?.arrId === arrId);
    return index;
  },
  
}

// mutations
const mutations = {
  // resetTodo(state){
  //   this.state =  {...emptyObject};
  // },
  setDenunciante (state, params) { 
    state.list = state.list.map(n => {
      if ( n.arrId === params.arrId) {
        params.doc.arrId = params.arrId
        params.type = 'doc'
        return params
      }
      // console.log('n, params', n, params)
      // if ( n.arrId === params.arrId &&  params.type === 'empty') {
      //   console.log('inside empty', params, navigator)
      //   n.name = params.name
      // }
      return n;
    })
  },
  addDenunciante (state) {
    state.list = [...state.list, {
      arrId: uuidv4(),
      doc: Object.assign({}, state.emptyDoc ),
      type: 'empty',
      ordenPublico:false,
      emptyTitle: ''
    }]
    // if(state.list[state.list.length - 2].doc?.ufi === true )
    //   state.list[state.list.length - 2].doc.condicion = { id: "Víctima", nombre: "Víctima" }
  },
  deleteDenunciante (state, payload) {
    state.list = [
      ...state.list.slice(0, payload),
      ...state.list.slice(payload + 1)
    ]
  },
  setSipeSearch(state, payload){
    let denunciante = state.list.find(todo => todo.arrId === payload.arrId) 
    denunciante.sipeSearch = payload.sipeSearch
  },
  setEmptyTitle(state, payload){
    let denunciante = state.list.find(todo => todo.type === 'empty') 
    let index = state.list.indexOf(denunciante) 
    if(state.list[index]?.emptyTitle != undefined){//Este if es solo para que no surja un Warning la primera vez que se llama (ya que emptytitle aun no existe)
      state.list[index].emptyTitle  = payload
    }
  },
  setEmptyDoc(state, payload){
    state.emptyDoc = payload
    if (state.list.length > 0 && state.list[0].type == 'empty'){
      state.list[0].doc = payload
      state.list = [{
        ...state.list[0],
        doc: payload
      }]
    }
  },
}


export default {
  emptyObject,
  emptyDoc,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

function uuidv4(){
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}