export const ufiPropiedadOficioConfig = [
  {
      path: "/actuaciones/new/ufi-propiedad-oficio/initial",
      pathName: "ufi-propiedad-oficio",
      titulo: "UFI - DELITOS CONTRA LA PROPIEDAD POR OFICIO",
      icon: "pi-building",
      roles: [
        "User Tester",
        "Super User",
        "Jefe de Dependencia",
        "Oficial Sumariante",
        "Jefe de Sumario",
      ],
      type: "link",
      grupo: "UFI",
      props: { formType: "ufi-delitos-contra-la-propiedad-oficio", actuacionType: "ufi-propiedad-oficio" },
      nameOfRoute : "actuacion-initial"
    },
    {
      path: "/actuaciones/new/ufi-propiedad-oficio/initial/denunciante",
      pathName: "ufi-propiedad-oficio",
      titulo: "AFECTADOS",
      nameOfRoute : "actuacion-form-denunciante"
    },
    {
      path: "/actuaciones/new/ufi-propiedad-oficio/initial/momento",
      pathName: "ufi-propiedad-oficio",
      titulo: "FECHA, HORA y UBICACIÓN",
      nameOfRoute : "actuacion-form-momento"
    },
    {
      path: "/actuaciones/new/ufi-propiedad-oficio/initial/acusado",
      pathName: "ufi-propiedad-oficio",
      titulo: "VINCULADOS",
      nameOfRoute : "actuacion-form-acusado"
    },
    {
      path: "/actuaciones/new/ufi-propiedad-oficio/initial/efectos",
      pathName: "ufi-propiedad-oficio",
      titulo: "EFECTOS",
      nameOfRoute : "actuacion-form-efectos"
    },
]