<template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">
      <div v-if="getMode() === 'new'" class="feature-intro">
        <h1>Nuevo {{ pageName }}</h1>
        <p>Ingrese los datos del formulario</p>
      </div>
      <div v-else class="feature-intro">
        <h1>Editando {{ pageName }}</h1>
        <p>Modifique los datos que desea EDITAR</p>
      </div>
    </div>

    <div class="mb-4 text-xl">Departamental Seleccionado</div>

    <TForm
      :data="data"
      :nombres="names"
      :errors="errors"
      :mensajes="mensajes"
      :mode="getMode()"
      :designEdit="designEdit"
      :designNew="design"
      :url="url"
      :viewer="viewer"
      :viewerID="viewerID"
    />
  </div>
</template>

<script>
export default {
  props: {
    viewer: {
      type: Boolean,
    },
    viewerID: {
      type: String,
    },
  },
  data() {
    return {
      data: {
        "Nombre": { value: "departamental1", value2: "departamental2", validation: "required|max_length[50]", type: "input" },
        "Ubicacion": { value: "ubicacion1", value2: "ubicacion2", validation: "max_length[100]", type: "input" },
        "FechaCreacion": { value: "2023-01-01", value2: "2024-01-01", validation: "required|date", type: "date" },
      },
      names: {
        "Nombre": "Nombre",
        "Ubicacion": "Ubicación",
        "FechaCreacion": "Fecha de Creación",
      },
      errors: {
        "Nombre": false,
        "Ubicacion": false,
        "FechaCreacion": false,
      },
      mensajes: {
        "Nombre": "",
        "Ubicacion": "",
        "FechaCreacion": "",
      },
      designEdit: [["Nombre", "Ubicacion"], ["FechaCreacion"]],
      design: [["Nombre", "Ubicacion"], ["FechaCreacion"]],
      id: "",
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/parametros/departamental",
      pageName: "Departamental",
    };
  },
  created() {
    const { id } = this.$route.params;
    this.id = id;
  },
  methods: {
    getMode() {
      return this.id === "add" ? "new" : "edit";
    },
  },
};
</script>

<style scoped>
.page {
  margin: 20px;
}

.feature-intro {
  margin-bottom: 20px;
}

.mb-4 {
  margin-bottom: 16px;
}
</style>
