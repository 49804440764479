export const ufiIntoxicacionConfig = [
  {
    path: "/actuaciones/new/ufi-intoxicacion/initial",
    pathName: "ufi-intoxicacion",
    titulo: "UFI - INTOXICACIÓN",
    props: { formType: 'ufi-intoxicacion', actuacionType: "ufi-intoxicacion" },
    nameOfRoute: "actuacion-initial",
  },
  {
    path: "/actuaciones/new/ufi-intoxicacion/initial/denunciante",
    pathName: "ufi-intoxicacion",
    titulo: "AFECTADOS",
    nameOfRoute: "actuacion-form-denunciante",
  },
  {
    path: "/actuaciones/new/ufi-intoxicacion/initial/momento",
    pathName: "ufi-intoxicacion",
    titulo: "FECHA, HORA y UBICACIÓN",
    nameOfRoute: "actuacion-form-momento",
  },
  {
    path: "/actuaciones/new/ufi-intoxicacion/initial/efectos",
    pathName: "ufi-intoxicacion",
    titulo: "EFECTOS",
    nameOfRoute: "actuacion-form-efectos",
  },
];
