<template>
  <div :class="props.modalClass">
    <Dialog 
      :header="title"
      :visible.sync="opened"
      :containerStyle="styles"
      :modal="modal"
      :dismissableMask="dismissableMask"
      :closable="closable"
      ref="dialogRef"
    >
      <div class="flex" v-if="!hasSlot()">
        <i 
          v-if="hasIcon()"
          :class="getIcon() + ' mr-3 ' + getTextColorClassBySeverity()" 
          style="font-size: 4rem;"
        />
        <span class="flex align-items-center justify-content-center">
          {{ message }}
        </span>
      </div>
      <div v-if="hasSlot()" class="mt-3">
        <slot></slot>
      </div>
      <template #footer test-id="modal-buttons">
        <div v-if="type === 'confirmativo'">
          <Button
            label="No" 
            icon="pi pi-times" 
            class="p-button-text" 
            @click="emit('cancel')"
            test-id="btn-no"
          />
          <Button 
            label="Sí" 
            icon="pi pi-check" 
            :class="getButtonClass()"
            @click="emit('confirm')"
            test-id="btn-si"
          />
        </div>
        <div v-if="type === 'informativo'">
          <div v-if="!props.loadingSaveButton">
            <Button 
              label="Aceptar" 
              icon="pi pi-check" 
              :class="getButtonClass()"
              @click="emit('confirm')"
              test-id="btn-aceptar"
            />
          </div>
          <div v-else>
            <ProgressSpinner style="width:50px;height:50px" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s"/> 
          </div>
        </div>
        <div v-if="type === 'proceso'">
          <Button
            label="Cancelar" 
            icon="pi pi-times" 
            class="p-button-text" 
            @click="emit('cancel')"
            test-id="btn-cancelar"
            ref="acceptButtonRef"
          />
          <Button 
            label="Guardar" 
            icon="pi pi-check" 
            @click="emit('confirm')"
            :class="getButtonClass()"
            test-id="btn-guardar"
            ref="saveButtonRef"
          />
        </div>
        <div v-if="type === null" class="flex flex-wrap">
          <div v-for="(button, index) in buttons">
            <Button 
              :label="button.label" 
              :icon="button.icon" 
              :test-id="'btn-custom' + index"
              @click="emit('custom-event', button?.msgEvent)"
              :class="getButtonClass(button)"
              class="mt-3"
            />
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
  
  import { ref, onMounted, useSlots } from 'vue';
  
  let opened = ref(false);

  const emit = defineEmits(['confirm', 'cancel', 'custom-event']);
  
  const slots = useSlots();

  const props = defineProps({
    modalClass: {
      type: String,
      default: '',
    },
    styles: {
      type: Object,
      default: () => ({ width: '500px' }),
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    severity: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    modal: {
      type: Boolean,
      default: true,
    },
    dismissableMask: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    closable: {
      type: Boolean,
      default: true,
    },
    loadingSaveButton: {
      type: Boolean,
      default: false,
    },
  })
  
  const open = ref(() => {
    opened.value = true;
  })

  const close = ref(() => {
    opened.value = false;
  })

  const hasIcon = () => {
    if(props.showIcon && props.icon !== false){
      return true;
    } else{
      return false;
    }
  }

  const hasSlot = () => {
    if(Object.keys(slots).length >= 1){
      return true;
    } else{
      return false;
    }
  }

  const getIcon = () => {
    if(props.icon === null){
      if(props.type === 'informativo'){
        return 'pi pi-info-circle';
      }
      if(props.type === 'confirmativo'){
        return 'pi pi-question-circle';
      }
    } else{
      return 'pi ' + props.icon;
    }
  }

  const getTextColorClassBySeverity = () => {
    if(props?.severity === null){
      return '';
    } else{
      return 'text-' + props.severity;
    }
  }

  const getButtonClass = (button = {severity: null}) => {
    if(button?.severity !== null){
      return 'p-button-' + button.severity;
    } else{
      if(props?.severity === null){
        return '';
      } else{
        return 'p-button-' +  props.severity;
      }
    }
  }
  
  defineExpose({
    open,
    close
  });

  // onMounted(() => {

  // });

</script>

<style scoped>

</style>
