<template>
  <div class="content">
    <div :class="{ forms: !minimal, inputType: minimal }">
      <!-- PERSONA FÍSICA -->
      <div class="p-fluid">
        <div class="grid flex" v-if="seeData()">
          <div class="col-12 md:col-6">
            <div
              class="field col-12 md:col-12 sm:col-12 condition"
              v-if="true"
              test-id="buttonTipoDenunciante"
            >
              <Dropdown
                dataKey="nombre"
                v-model="persona.condicion"
                :options="this[viewData.formPersona.condition[this.typeOfPerson]]"
                optionLabel="nombre"
                placeholder="Elija condición"
                class="lg:col-12 md:col-12 sm:col-12"
                test-id="InputTipoDenunciante"
                :filter="true"
                @change="updateField('condicion', persona.condicion)"
              />
            </div>

            <div
              v-else
              :class="{
                'field col-12 md:col-12 sm:col-12':
                  viewData.formPersona.conditionDefault !== '' && !$route.path.includes('acusado'),
              }"
            >
              <p v-if="!$route.path.includes('acusado')">
                <b>{{ viewData.formPersona.conditionDefault }}</b>
              </p>
            </div>
          </div>
          <div
            class="field-checkbox col-12 md:col-6 justify-content-end"
            test-id="divPersonaJuridica"
          >
            <Checkbox
              id="persona-juridica"
              v-model="persona.personaJuridica"
              :disabled="isDisabled('personaJuridica')"
              :binary="true"
              test-id="checkPersonaJuridica"
            />
            <label for="persona-juridica">¿Es Persona Jurídica?</label>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="grid w-full wrap">
            <div class="field col-2 md:col-2 sm:col-2" test-id="buttonTipoDocDenunciante">
              <label for="tipoDocumento">Tipo de doc. <span v-if="!acusado" style="color: red;">*</span></label>
              <Dropdown
                dataKey="nombre"
                v-model="persona.tipoDocumento"
                placeholder="Tipo de Doc"
                :options="tipoDocumentoList"
                optionLabel="nombre"
                :filter="true"
                @change="updateField('tipoDocumento', persona.tipoDocumento)"
              />
            </div>
            <div class="field col-2 md:col-2 sm:col-2">
              <label for="documento">N° de doc. <span v-if="!acusado" style="color: red;">*</span></label>
              <InputText
                class="input"
                min="0"
                type="number"
                v-model="persona.documento"
                :disabled="isDisabled('dataDocumento')"
                placeholder="Número de documento"
                test-id="inputDniDenunciante"
                @input="updateField('documento', persona.documento)"
              />
              <label 
                v-if="modifiedData.documento" 
                :class="{ visible: errores.documento }"> {{ errores.documento }}</label>
            </div>
            <div
              class="field col-1 md:col-1 sm:col-1"
              test-id="inputSexDenunciante"
              style="width: 190px"
            >
              <label for="sexo">Sexo <span v-if="!acusado" style="color: red;">*</span></label>
              <Dropdown
                dataKey="nombre"
                v-model="persona.sexo"
                placeholder="Sexo"
                :options="sexoList"
                optionLabel="nombre"
                :filter="true"
                @change="updateField('sexo', persona.sexo)"
              />
            </div>
            <div class="field col-3" style="width: 200px">
              <label for="exhibeDocumento">¿Exhibe el documento? <span v-if="!acusado" style="color: red;">*</span></label>
              <Dropdown
                dataKey="id"
                v-model="persona.exhibeDocumento"
                placeholder="¿Exhibe el documento?"
                :options="yesOrNoList"
                optionLabel="nombre"
                test-id="buttonExhibeDocumento"
                :filter="true"
                @change="updateField('exhibeDocumento', persona.exhibeDocumento)"
              />
            </div>
            <div class="field col-2 md:col-2 sm:col-2">
              <label for="cuil">N° de Cuil.</label>
              <InputText
                class="input"
                min="0"
                v-model="persona.cuil"
                placeholder="N° de Cuil."
                @input="updateField('cuil', persona.cuil)"
              />
            </div>
            <div class="buttonAndSpinner">
              <!-- {{$offline.onlineMode}}   -->
              <div class="field align-self-end col-12" >
                <Button
                  type="button"
                  label="Buscar"
                  icon="pi pi-search"
                  class="p-button-primary ml-2"
                  @click="$_FormPersona_sipeSearch"
                  test-id="buttonBuscarPersona"
                />
              </div>
              <div
                :class="{
                  spinner: timer,
                  'ml-6': true,
                  'md:col-5': true,
                  'mt-auto': true,
                  'mb-5': true,
                }"
              >
                <ProgressSpinner
                  style="width: 70px; height: 70px"
                  strokeWidth="5"
                  fill="#EEEEEE"
                  animationDuration="1.8s"
                />
              </div>
            </div>
            <p class="field p-error col-12 mt-0 pt-0" test-id="sipeLabelError" v-if="persona.error">
              {{ persona.error }}
            </p>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="p-fluid formgrid grid" v-if="seeData()">
            <div class="field col-12 md:col-5 sm:col-5" test-id="inputApellido">
              <label for="apellido">Apellido <span v-if="!acusado" style="color: red;">*</span></label>
              <InputText
                class="input"
                type="text"
                placeholder="Apellido/s"
                v-model="persona.apellido"
                :disabled="isDisabled('dataApellido')"
                @input="updateField('apellido', persona.apellido)"
              />
              <label v-if="modifiedData.apellido" :class="{ visible: errores.apellido }"> {{ errores.apellido }}</label>
            </div>
            <div class="field col-12 md:col-5 sm:col-5" test-id="inputNombre">
              <label for="nombre">Nombre <span v-if="!acusado" style="color: red;">*</span></label>
              <InputText
                class="input"
                type="text"
                placeholder="Nombre"
                v-model="persona.nombre"
                :disabled="isDisabled('dataNombre')"
                @input="updateField('nombre', persona.nombre)"
              />
              <label v-if="modifiedData.nombre" :class="{ visible: errores.nombre }"> {{ errores.nombre }}</label>
            </div>
            <div class="field col-12 md:col-2 sm:col-2" test-id="inputNombre">
              <label for="edad">Edad <span v-if="!acusado" style="color: red;">*</span></label>
              <InputText
                class="input"
                type="number"
                placeholder="Edad"
                v-model="persona.edad"
                @input="updateField('edad', persona.edad)"
              />
              <label v-if="modifiedData.edad" :class="{ visible: errores.edad }"> {{ errores.edad }}</label>
            </div>

            <div class="field col-12 md:col-4 lg:col-4 sm:col-6" test-id="inputFechaNac">
              <label for="fechaNacimiento">Fecha de nac. <span v-if="!acusado" style="color: red;">*</span></label>
              <InputMask 
                class="input"
                mask="99/99/9999" 
                v-model="persona.fechaNacimiento"
                placeholder="dd/mm/yyyy" 
                :disabled="isDisabled('dataFechaNacimiento')"
                @input="updateField('fechaNacimiento', persona.fechaNacimiento)"
                slotChar="dd/mm/yyyy" 
              />
              <label v-if="modifiedData.fechaNacimiento" :class="{ visible: errores.fechaNacimiento }">
                {{ errores.fechaNacimiento }}</label
              >
            </div>
            <div class="field col-12 md:col-4 lg:col-4 sm:col-6" test-id="buttonNacionalidad">
              <label for="nacionalidad">Nacionalidad <span v-if="!acusado" style="color: red;">*</span></label>
              <Dropdown
                dataKey="nombre"
                v-model="persona.nacionalidad"
                placeholder="Nacionalidad"
                :options="nacionalidadList"
                optionLabel="nombre"
                :filter="true"
                @change="updateField('nacionalidad', persona.nacionalidad)"
              />
            </div>
            <div class="field col-12 md:col-4 lg:col-4" test-id="buttonEstadoCivil">
              <label for="estadoCivil">Estado civil <span v-if="!acusado" style="color: red;">*</span></label>
              <Dropdown
                dataKey="nombre"
                class="input"
                v-model="persona.estadoCivil"
                placeholder="Estado civil"
                :options="estadoCivilList"
                optionLabel="nombre"
                :filter="true"
                @change="updateField('estadoCivil', persona.estadoCivil)"
              />
            </div>
            <div class="field col-12" test-id="textareaDomDeclarado">
              <label for="residenciaSIPE">Domicilio <span v-if="!acusado" style="color: red;">*</span></label>
              <Textarea
                class="input"
                type="text"
                placeholder="Domicilio"
                v-model="persona.domicilio"
                @input="updateField('domicilio', persona.domicilio)"
              />
              <label v-if="modifiedData.domicilio" :class="{ visible: errores.domicilio }">
                {{ errores.domicilio }}</label
              >
            </div>
            <div
              class="field col-12 lg:col-6 md:col-6 sm:col-6"
              v-if="persona.condicion.nombre === 'Denunciante'"
            >
              <label for="vinculo">Vínculo con la víctima</label>
              <InputText
                class="input"
                type="text"
                placeholder="Vínculo con la víctima"
                v-model="persona.vinculo"
                test-id="inputVinculo"
                @input="updateField('vinculo', persona.vinculo)"
              />
              <label v-if="modifiedData.vinculo" :class="{ visible: errores.vinculo }">
                {{ errores.vinculo }}</label
              >
            </div>

            <div class="p-fluid formgrid grid">
              <div :class="generateClasses()">
                <label for="telefono">Teléfono <span v-if="!acusado" style="color: red;">*</span></label>
                <InputText
                  class="input"
                  type="number"
                  min="10000000"
                  max="999999999"
                  placeholder="Número de teléfono"
                  v-model="persona.telefono"
                  :disabled="isDisabled('dataTelefono')"
                  test-id="inputTel"
                  @input="updateField('telefono', persona.telefono)"
                />
                <label v-if="modifiedData.telefono" :class="{ visible: errores.telefono }"> {{ errores.telefono }}</label>
              </div>
              <div :class="generateClasses()">
                <label for="profesion">Email <span v-if="!acusado" style="color: red;">*</span></label>
                <InputText
                  class="input"
                  placeholder="Email"
                  v-model="persona.email"
                  :disabled="isDisabled('dataEmail')"
                  test-id="inputEmail"
                  id="emailInput"
                  @input="updateField('email', persona.email)"
                />
                <label v-if="modifiedData.email" :class="{ visible: errores.email }"> {{ errores.email }}</label>
              </div>
              <div :class="generateClasses()">
                <label for="profesion">Profesión <span v-if="!acusado" style="color: red;">*</span></label>
                <InputText
                  class="input"
                  placeholder="Profesión"
                  v-model="persona.profesion"
                  :disabled="isDisabled('dataProfesion')"
                  test-id="inputProfesion"
                  @input="updateField('profesion', persona.profesion)"
                />
                <label v-if="modifiedData.profesion"  :class="{ visible: errores.profesion }"> {{ errores.profesion }}</label>
              </div>
              <div :class="generateClasses()">
                <label for="tieneEstudios">¿Tiene estudios? <span v-if="!acusado" style="color: red;">*</span></label>
                <Dropdown
                  dataKey="id"
                  v-model="persona.tieneEstudios"
                  placeholder="¿Tiene estudios?"
                  :options="yesOrNoList"
                  optionLabel="nombre"
                  :filter="true"
                  test-id="buttonInstruccion"
                  @change="updateField('tieneEstudios', persona.tieneEstudios)"
                />
              </div>
              <div :class="generateClasses()">
                <label for="instruccion">Instrucción <span v-if="!acusado" style="color: red;">*</span></label>
                <Dropdown
                  dataKey="nombre"
                  v-model="persona.instruccion"
                  placeholder="Instrucción"
                  :options="instruccionList"
                  optionLabel="nombre"
                  :filter="true"
                  test-id="buttonInstruccion"
                  @change="updateField('instruccion', persona.instruccion)"
                />
              </div>
              <div class="field col-12">
                <label for="residencia">Observaciones</label>
                <Textarea
                  class="input"
                  type="text"
                  placeholder="Observaciones..."
                  v-model="persona.observaciones"
                  test-id="textareaDomResidencia"
                  @input="updateField('observaciones', persona.observaciones)"
                />
                <!-- <label :class="{visible: (errores.domicilio)}"> {{errores.domicilio}}</label> -->
              </div>
            </div>
            <div class="field col-12 md:col-3 sm:col-6" v-if="acusado">
              <label for="nombre">Apodo</label>
              <InputText
                class="input"
                type="text"
                placeholder="apodo"
                v-model="persona.apodo"
                test-id="inputApodo"
                @input="updateField('apodo', persona.apodo)"
              />
              <label v-if="modifiedData.apodo" :class="{ visible: errores.apodo }"> {{ errores.apodo }}</label>
            </div>
          </div>
        </div>

        <div
          class="p-fluid formgrid grid"
          v-if="
            formType === 'ufi-estafas-y-delitos-informaticos' ||
            formType === 'ufi-estafas-y-delitos-informaticos-oficio'
          "
        >
          <div class="field col-12">
            <h3>Datos complementarios</h3>
          </div>
          <ListBoxOptions
            v-if="typeOfPerson === 'denunciante'"
            @FormPersona_update="(data, value) => $_FormPersona_update(data, value)"
            :defaultValue="
              getPersonByArrId(arrId)?.doc?.codigosCoelsa === undefined
                ? []
                : getPersonByArrId(arrId)?.doc?.codigosCoelsa
            "
            formDataName="codigosCoelsa"
            valueName="IDcoelsa"
            tooltip="Agregar IDCOELSA"
            :preventRepeatedItems="true"
            :minLength="5"
            :maxLength="22"
            subPropName="CodigoCoelsa"
          />
          <ListBoxOptions
            @FormPersona_update="(data, value) => $_FormPersona_update(data, value)"
            :defaultValue="
              getPersonByArrId($route.query.id)?.doc?.plataformas === undefined
                ? []
                : getPersonByArrId($route.query.id)?.doc?.plataformas
            "
            formDataName="plataformas"
            valueName="Perfiles de redes sociales"
            tooltip="Agregar Perfil Social"
            :preventRepeatedItems="true"
            :minLength="5"
            :maxLength="255"
            subPropName="Url"
          />
        </div>

        <!-- FIN PERSONA FÍSICA -->
        <!-- PERSONA JURÍDICA -->
        <div class="p-fluid formgrid grid" v-if="persona.personaJuridica">
          <div class="field col-12">
            <h3>En representación de</h3>
          </div>
          <div class="field col-12 md:col-6">
            <label for="CUIT">CUIT <span v-if="!acusado" style="color: red;">*</span></label>
            <InputText
              class="input"
              type="number"
              min="0"
              placeholder="CUIT"
              v-model="persona.cuit"
              :disabled="isDisabled('dataCUIT')"
              test-id="inputCuit"
              @input="updateField('CUIT', persona.cuit)"

            />
            <label v-if="modifiedData.CUIT" :class="{ visible: errores.CUIT }"> {{ errores.CUIT }}</label>
          </div>
          <div class="field col-12 md:col-6">
            <label for="denominacion">Razón social <span v-if="!acusado" style="color: red;">*</span></label>
            <InputText
              class="input"
              type="text"
              placeholder="Razón social"
              v-model="persona.denominacion"
              :disabled="isDisabled('dataDenominacion')"
              test-id="inputRsocial"
              @input="updateField('denominacion', persona.denominacion)"

            />
            <label v-if="modifiedData.denominacion" :class="{ visible: errores.denominacion }"> {{ errores.denominacion }}</label>
          </div>
          <div class="field col-12">
            <label for="domicilioJuridico">Domicilio legal <span v-if="!acusado" style="color: red;">*</span></label>
            <InputText
              class="input"
              type="text"
              v-model="persona.domicilioJuridico"
              :disabled="isDisabled('dataDomicilioJuridico')"
              test-id="inputDomlegal"
              @input="updateField('domicilioJuridico', persona.domicilioJuridico)"
            />
            <label v-if="modifiedData.domicilioJuridico" :class="{ visible: errores.domicilioJuridico }">
              {{ errores.domicilioJuridico }}</label
            >
          </div>
          <div class="field col-12 lg:col-4 md:col-6 sm:col-6">
            <label for="telefono">Teléfono <span v-if="!acusado" style="color: red;">*</span></label>
            <InputText
              class="input"
              type="text"
              placeholder="Teléfono"
              v-model="persona.telefonoJuridico"
              :disabled="isDisabled('dataTelefonoJuridico')"
              test-id="inputTeljuridico"
              @input="updateField('telefonoJuridico', persona.telefonoJuridico)"
            />
            <label v-if="modifiedData.telefonoJuridico" :class="{ visible: errores.telefonoJuridico }">
              {{ errores.telefonoJuridico }}</label
            >
          </div>
          <div class="field col-12 lg:col-5 md:col-6 sm:col-6">
            <label for="rubro">Actividad <span v-if="!acusado" style="color: red;">*</span></label>
            <InputText
              class="input"
              type="text"
              placeholder="Actividad"
              v-model="persona.rubro"
              :disabled="isDisabled('dataRubro')"
              test-id="inputActividad"
              @input="updateField('rubro', persona.rubro)"
            />
            <label v-if="modifiedData.rubro" :class="{ visible: errores.rubro }"> {{ errores.rubro }}</label>
          </div>
          <div class="field col-12 lg:col-3 md:col-6 sm:col-6 chkbx" test-id="divTieneSeguro">
            <Checkbox 
              class="mr-2" 
              id="tiene-seguro" 
              v-model="persona.tieneSeguro" 
              :binary="true" 
              />
            <label for="tiene-seguro" class="txtchkbx">Tiene seguro</label>
          </div>
        </div>
      </div>
      <!-- FIN...   PERSONA JURÍDICA -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CustomAutoComplete from "../custom-autocomplete/CustomAutoComplete.vue";
import Listbox from "primevue/listbox";
import ListBoxOptions from "../ListBoxOptions/ListBoxOptions";
import InputMask from 'primevue/inputmask';


export default {
  props: {
    acusado: Boolean,
    minimal: String,
    hidden: { type: Array, default: () => [] },
    arrId: String,
    getPersonByArrId: Function,
    typeOfPerson: String,
    ordenPublico: Boolean,
    formType: String,
    Involucrados: { type: Array, default: () => [] },
  },
  components: {
    CustomAutoComplete,
    Listbox,
    ListBoxOptions,
    InputMask
  },
  name: "FormPersona",
  data() {
    return {
      editPerson: false,
      persona: {
        personaJuridica: false,
        persona: false,
        condicion: "",
        documento: "",
        tipoDocumento: "DNI",
        cuit: "",
        apellido: "",
        nombre: "",
        nacionalidad: "",
        sexo: "",
        fechaNacimiento: "",
        edad: "",
        estadoCivil: null,
        telefono: "",
        telefonoJuridico: "",
        redesSociales: "",
        profesion: "",
        instruccion: "",
        denominacion: "",
        apodo: "",
        rubro: "",
        domicilio: "",
        domicilioJuridico: "",
        disabledFields: [],
        domicilioSIPE: "",
        error: "",
        email: "",
        tieneSeguro: false,
        exhibeDocumento: "",
        tieneEstudios: "",
        observaciones: "",
        cuil: "",
        vinculo: "",
        edad: "",
      },
      fields: [
        "personaJuridica",
        "condicion",
        "documento",
        "tipoDocumento",
        "cuit",
        "apellido",
        "nombre",
        "nacionalidad",
        "sexo",
        "tipoDocumento",
        "fechaNacimiento",
        "edad",
        "estadoCivil",
        "telefono",
        "telefonoJuridico",
        "redesSociales",
        "profesion",
        "instruccion",
        "apodo",
        "denominacion",
        "rubro",
        "domicilio",
        "domicilioJuridico",
        "domicilioSIPE",
        "tieneSeguro",
        "email",
        "exhibeDocumento",
        "tieneEstudios",
        "observaciones",
        "cuil",
        "vinculo",
        "edad",
      ],
      timer: true,
      errores: {
        documento: 'Este campo es obligatorio',
        apellido: 'Este campo es obligatorio',
        nombre: 'Este campo es obligatorio',
        fechaNacimiento: 'Este campo es obligatorio',
        domicilio: 'Este campo es obligatorio',
        telefono: 'Este campo es obligatorio',
        profesion: 'Este campo es obligatorio',
        exhibeDocumento: 'Este campo es obligatorio',
        tieneEstudios: 'Este campo es obligatorio',
        edad: 'Este campo es obligatorio',
        apodo: null,
        CUIT: null,
        denominacion: null,
        domicilioJuridico: null,
        telefonoJuridico: null,
        rubro: null,
        email: 'Este campo es obligatorio',
        estadoCivil: 'Este campo es obligatorio'
      },
      modifiedData: {}
    };
  },
  mounted() { 
    if (this.dataSipeSearch === true) {
      this.disabledFields = [
        "dataNombre",
        "dataApellido",
        "dataFechaNacimiento",
        "dataDomicilioSIPE",
        "dataCUIL",
      ];
    } else {
      this.disabledFields = [];
    }

    this.$store.dispatch(
      "sumario/denunciante/setEmptyTitle",
      this.viewData.formPersona.whistleblower.emptyTitle
    );

    this.$store.dispatch(
      "sumario/acusados/setEmptyTitle",
      this.viewData.formPersona.accused.emptyTitle
    );

  },
  watch: {
    "persona.personaJuridica"(newVal) {
      if (!newVal) {
        // Elimina los errores cuando se desmarca "persona jurídica"
        ["CUIT", "denominacion", "telefonoJuridico", "rubro", "domicilioJuridico"].forEach(field => {
          this.errores[field] = null;
        });
        this.$emit("validationStatus", this.errores);
      } else {
        // Valida si ya había datos ingresados antes
        ["cuit", "denominacion", "telefonoJuridico", "rubro", "domicilioJuridico"].forEach(field => {
          if (this.persona[field]) {
            this.validarCampo(field, this.persona[field]);
          }
        });
      }
    },
    arrId:  {
      deep: true, 
      immediate: true, 
      handler () {
        const personaData = this.getPersonByArrId(this.arrId);
        if (personaData?.type !== "empty") {
          this.editPerson = true;
        }
        if(this.acusado) this.errores = {}
        if(personaData?.doc){
          this.setInitialData(personaData.doc);
        }else{
          this.setEmptyPerson()
        }
        if (this.dataSipeSearch === true) {
          this.disabledFields = [
            "dataNombre",
            "dataApellido",
            "dataFechaNacimiento",
            "dataDomicilioSIPE",
            "dataCUIL",
          ];
        } else {
          this.disabledFields = [];
        }
      }
    },
  },
  computed: {
    ...mapState({
      estadoCivilList: (state) => state.siis.estadoCivil,
      condicionList: (state) => state.siis.condicion,
      condicionCombinada: (state) => state.siis.condicionCombinada,
      condicionPreliminar: (state) => state.siis.condicionPreliminar,
      condicionCaidaCasual: (state) => state.siis.condicionCaidaCasual,
      condicionUFIDenunciante: (state) => state.siis.condicionUFIDenunciante,
      condicionUFIAcusado: (state) => state.siis.condicionUFIAcusado,
      condicionUFIDenuncianteFallecido: (state) => state.siis.condicionUFIDenuncianteFallecido,
      condicionUFIDenuncianteParadero: (state) => state.siis.condicionUFIDenuncianteParadero,
      condicionUFIAcusadoParadero: (state) => state.siis.condicionUFIAcusadoParadero,
      condicionTentativaSuicidio: (state) => state.siis.condicionTentativaSuicidio,
      condicionSumarioAcusado: (state) => state.siis.condicionSumarioAcusado,
      condicionContravencionAcusado: (state) => state.siis.condicionContravencionAcusado,
      condicionAcusadoDetenido: (state) => state.siis.condicionAcusadoDetenido,
      sexoList: (state) => state.siis.sexo,
      yesOrNoList: (state) => state.siis.yesOrNoList,
      tipoDocumentoList: (state) => state.siis.tipoDocumento,
      nacionalidadList: (state) => state.siis.nacionalidad,
      instruccionList: (state) => state.siis.instruccion,
      sumario: (state) => state.sumario,
    }),
    ...mapGetters({
      viewData: "sumarioViews/getViewData",
    }),
    dataSipeSearch: {
      get() {
        let person = this.getPersonByArrId(this.arrId);
        let sipeSearch = false;
        if (person !== undefined) {
          sipeSearch = person.sipeSearch;
        }
        return sipeSearch;
      },
    },
  },
  methods: {
    validarCampo(campo, valor, maxLength, requerido = true) {

      if (requerido && !valor && !this.acusado) {
        this.errores[campo] = `El campo ${campo} es obligatorio.`;
        return false;
      }
      if (valor !== null && valor.length > maxLength) {
        this.errores[campo] = `Este campo no puede contener más de ${maxLength} caracteres.`;
        return false
      } else {
        this.errores[campo] = null;
        return true
      }
    },
    setInitialData(data) {
      if (!data) return;
      const exhibeDocumentoAux = 
        typeof data.exhibeDocumento === "object"
          ? data.exhibeDocumento
          : { id: +data.exhibeDocumento, nombre: +data.exhibeDocumento === 1 ? 'Sí' : 'No' };

      const tieneEstudiosAux = 
        typeof data.tieneEstudios === "object"
          ? data.tieneEstudios
          : { id: +data.tieneEstudios, nombre: +data.tieneEstudios === 1 ? 'Sí' : 'No' };

      this.validarCampo('exhibeDocumento', exhibeDocumentoAux)
      this.validarCampo('tieneEstudios', tieneEstudiosAux)
      this.persona = {
        personaJuridica: data.personaJuridica ?? false,
        condicion: data.condicion ?? "",
        documento: data.documento ?? "",
        tipoDocumento: data.tipoDocumento ?? "DNI",
        cuit: data.cuit ?? "",
        sexo: data.sexo ?? "",
        exhibeDocumento: exhibeDocumentoAux,
        instruccion: data.instruccion ?? "",
        apellido: data.apellido ?? "",
        nombre: data.nombre ?? "",
        nacionalidad: data.nacionalidad ?? "",
        fechaNacimiento: data.fechaNacimiento ?? "",
        edad: data.edad ?? "",
        estadoCivil: data.estadoCivil ?? null,
        telefono: data.telefono ?? "",
        telefonoJuridico: data.telefonoJuridico ?? "",
        redesSociales: data.redesSociales ?? "",
        profesion: data.profesion ?? "",
        instruccion: data.instruccion ?? "",
        denominacion: data.denominacion ?? "",
        apodo: data.apodo ?? "",
        rubro: data.rubro ?? "",
        domicilio: data.domicilio || data.domicilioSIPE || "",
        domicilioJuridico: data.domicilioJuridico ?? "",
        disabledFields: [],
        domicilioSIPE: data.domicilioSIPE ?? "",
        email: data.email ?? "",
        tieneSeguro: data.tieneSeguro ?? false,
        tieneEstudios: tieneEstudiosAux,
        observaciones: data.observaciones ?? "",
        cuil: data.cuil ?? "",
        vinculo: data.vinculo ?? "",
        edad: data.edad ?? "",
      };
      if(data.apellido){
        this.errores = {}
      }
      this.$emit("update:persona", this.persona)
    },
    setEmptyPerson() {
      this.persona = {
        personaJuridica: false,
        condicion: "",
        documento: "",
        tipoDocumento: "DNI",
        cuit: "",
        sexo: "",
        exhibeDocumento: "",
        instruccion: "",
        apellido:  "",
        nombre: "",
        nacionalidad: "",
        fechaNacimiento: "",
        edad: "",
        estadoCivil: null,
        telefono:  "",
        telefonoJuridico: "",
        redesSociales: "",
        profesion: "",
        instruccion: "",
        denominacion: "",
        apodo: "",
        rubro: "",
        domicilio: "",
        domicilioJuridico:  "",
        disabledFields: [],
        domicilioSIPE: "",
        email: "",
        tieneSeguro: false,
        exhibeDocumento: "",
        tieneEstudios: "",
        observaciones: "",
        cuil: "",
        vinculo: "",
        edad: "",
      };
      this.$emit("update:persona", this.persona)
    },
    seeData() {
      if (this.formType == "oficio") {
        if (this.ordenPublico == undefined || this.ordenPublico == false) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    setData(data) {
      for (let i of this.fields) {
        this[i] = data[i] || null;
      }
    },
    getMaxLength(field) {
      const maxLengths = {
        documento: 15,
        apellido: 20,
        nombre: 20,
        fechaNacimiento: 11,
        domicilio: 100,
        telefono: 20,
        profesion: 20,
        apodo: 20,
        cuit: 11,
        denominacion: 30,
        domicilioJuridico: 100,
        rubro: 20,
        edad: 3
      };
      return maxLengths[field] || 255; // Valor por defecto
    },
    updateField(field, value) {
      this.persona[field] = value;
      this.modifiedData[field] = true //marcamos el campo como que se modifico
      if(field === 'email'){
        this.validateEmail(value)
      }else{
        this.validarCampo(field, value, this.getMaxLength(field));
      }
      this.$emit("update:persona", this.persona);
      this.$emit("validationStatus", this.errores);
    },
    validateEmail(email) {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!email && !this.acusado) {
        this.errores.email = "El email es obligatorio.";
      } else if (email && !emailRegex.test(email)) {
        this.errores.email = "El formato del email es inválido.";
      } else {
        this.errores.email = null;
      }
    },
    $_FormPersona_update(field, value) {
      this.$emit("updateValue", field, value);

      //reinicia la preforma

      let actualPath = this.$router.history.current.path.split("/");
      if (
        actualPath[actualPath.length - 2] == "denuncia" &&
        actualPath[actualPath.length - 1] !== "contraventor"
      )
        this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
      else if (actualPath[actualPath.length - 1] == "contraventor")
        this.$store.dispatch("sumario/textResetEditsCounter", "Contravener");
    },
    async $_FormPersona_sipeSearch() {
      if (!this.$offline.onlineMode) {
        this.$toast.open({
          message: "OFFLINE SIIS: No se pueden consultar datos a SIPE o RENAPER en modo offline.",
          position: "top-right",
          type: "error",
          duration: 3000,
        });
        return;
      }
      let data = {};
      try {
        this.$_FormPersona_sipeMode(true);
        let sexo = this.persona.sexo.nombre == "Masculino" ? "M" : "F";
        const url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/data-persona?dni=${this.persona.documento}&sexo=${sexo}`;
        const result = await fetch(url, {
          credentials: "include",
        });
        this.timer = true;

        data = await result.json();
      } catch (e) {
        if (
          process.env.VUE_APP_BACKEND_ENVIRONMENT === "http://dev.electro3.com.ar/siis/api/v1" ||
          process.env.VUE_APP_BACKEND_ENVIRONMENT === "http://localhost/siis/api/v1"
        ) {
          data = {
            sipe: {
              personaInfo: {
                apellido: "DI MARÍA",
                cuil: "245742775353",
                domicilios: {
                  domicilio: [
                    {
                      barrio: "",
                      calle: "LOS FRESNOS",
                      ciudad: "Funes",
                      cpostal: "2132",
                      departamento: "",
                      monoblock: "",
                      municipio: "Funes",
                      numero: "718",
                      pais: "Argentina",
                      piso: "",
                      provincia: "Santa Fe",
                      tipoDomicilio: "Falso",
                    },
                  ],
                },
                fechaActualiza: "2024-07-25",
                fechaFallecimiento: "",
                fechaNacimiento: "1988-02-14",
                idPersona: "2799684",
                nombre: "Ángel Fabián",
                sexo: "M",
              },
              resultadoWS: {
                codigoError: "",
                exito: true,
                msgError: "",
              },
            },
          };
        } else {
          console.error("err", e);
          // this.error = "Datos incompletos o problema de conexión con SIPE."
          this.$_FormPersona_sipeError("Datos incompletos o problema de conexión con SIPE.");
          this.$_FormPersona_sipeMode(false);
        }
      }
      if (data.sipe?.resultadoWS.exito !== true) {
        // console.log(data)
        if (data.sipe && data.sipe.resultadoWS) {
          this.$_FormPersona_sipeError("Persona no encontrada");
        } else {
          this.$_FormPersona_sipeError("SIPE sin conexión.");
        }
        this.$_FormPersona_sipeMode(false);
      } else {
        /* Nombre y Apellido */
        this.persona.nombre = data.sipe.personaInfo.nombre;
        this.persona.apellido = data.sipe.personaInfo.apellido;
        this.persona.cuil = data.sipe.personaInfo.cuil;
        /*  */
        /* Fecha de nacimiento */
        let dataDeNacimiento = data.sipe.personaInfo.fechaNacimiento;
        let orderedDate =
          dataDeNacimiento.slice(8, 10) +
          dataDeNacimiento.slice(4, 7) +
          "-" +
          dataDeNacimiento.slice(0, 4);
        this.fechaNacimiento = orderedDate;
        this.persona.fechaNacimiento = this.fechaNacimiento;
        /*  */

        /* Domicilio */
        let address = data.sipe.personaInfo.domicilios.domicilio[0];
        this.persona.domicilio = `${address.calle} ${address.numero}, ${address.barrio} CP:${address.cpostal} - ${address.municipio}, ${address.provincia}, ${address.pais}`;
        /*  */
        let edad = this.calcularEdad(this.persona.fechaNacimiento)
        this.updateField('nombre', this.persona.nombre)
        this.updateField('apellido', this.persona.apellido)
        this.updateField('cuil', this.persona.cuil)
        this.updateField('fechaNacimiento', this.persona.fechaNacimiento)
        this.updateField('edad', edad)
        this.updateField('domicilio', this.persona.domicilio)
      }

      // if (data.sipe.resultadoWS.exito === true) {
      //   /* Nombre y Apellido */
      //   this.dataNombre = data.sipe.personaInfo.nombre;
      //   this.dataApellido = data.sipe.personaInfo.apellido;
      //   /*  */

      //   /* Fecha de nacimiento */
      //   let dataDeNacimiento = data.sipe.personaInfo.fechaNacimiento;
      //   let orderedDate =
      //     dataDeNacimiento.slice(8, 10) +
      //     dataDeNacimiento.slice(4, 7) +
      //     "-" +
      //     dataDeNacimiento.slice(0, 4);
      //   this.fechaNacimiento = orderedDate;
      //   this.dataFechaNacimiento = this.fechaNacimiento;
      //   /*  */

      //   /* Domicilio */
      //   let address = data.sipe.personaInfo.domicilios.domicilio[0];
      //   this.dataDomicilioSIPE = `${address.calle} ${address.numero}, ${address.barrio} CP:${address.cpostal} - ${address.municipio}, ${address.provincia}, ${address.pais}`;
      //   /*  */
      // } else {
      //   this.$_FormPersona_sipeError("Persona no encontrada");
      //   this.$_FormPersona_sipeMode(false);
      // }
    },
    $_FormPersona_sipeError(error) {
      this.error = error;
      this.persona.nombre = "";
      this.persona.apellido = "";
      this.persona.fechaNacimiento = "";
      this.persona.domicilioSIPE = "";
      this.persona.cuil = "";
    },
    $_FormPersona_sipeMode(active) {
      if (active) {
        this.timer = false;
        this.error = false;
        this.disabledFields = [
          "dataNombre",
          "dataApellido",
          "dataFechaNacimiento",
          "dataDomicilioSIPE",
          "dataCUIL",
        ];
        this.$store.dispatch(`sumario/${this.typeOfPerson}/setSipeSearch`, {
          arrId: this.arrId,
          sipeSearch: true,
        });
      } else {
        this.timer = true;
        this.disabledFields = [];
        this.$store.dispatch(`sumario/${this.typeOfPerson}/setSipeSearch`, {
          arrId: this.arrId,
          sipeSearch: false,
        });
      }
    },
    checkDni() {
      if (this.persona.tipoDocumento != undefined && this.persona.tipoDocumento.nombre == "DNI") {
        return true;
      }
      return false;
    },

    isDisabled(field) {
      return false;
    },
    emitErrores() {
      for (let i in this.errores) {
        if (this.errores[i] != null) {
          // console.log("i= ", i, "\n");
          return true;
        }
      }
      return false;
    },
    generateClasses() {
      let classes;
      if (this.typeOfPerson === "denunciante") {
        classes = "field col-12 md:col-2 lg:col-2 sm:col-2 w-13rem";
      } else {
        classes = "field col-12 md:col-4 lg:col-4 sm:col-4";
      }
      return classes;
    },
    verifyJuridicPerson() {
        if(this.persona.tieneSeguro === '1' && this.persona.tieneSeguro !== 0) return true;
        const juridicPersonList = [this.rubro, this.persona.domicilioJuridico, this.persona.denominacion, this.persona.cuit, this.persona.telefonoJuridico];
        let thereIsJuridicPerson = false;
        juridicPersonList.map(item => {
          if(item !== '' && item !== null) thereIsJuridicPerson = true;
        })
        return thereIsJuridicPerson;
    },
    calcularEdad(fechaNacimiento) {
      const [dia, mes, anio] = fechaNacimiento.split('-').map(Number);
      const fechaNacimientoDate = new Date(anio, mes - 1, dia);
      const fechaActual = new Date();

      let edad = fechaActual.getFullYear() - fechaNacimientoDate.getFullYear();
      const mesActual = fechaActual.getMonth();
      const diaActual = fechaActual.getDate();

      // Ajuste si aún no ha pasado el cumpleaños de este año
      if (mesActual < (mes - 1) || (mesActual === (mes - 1) && diaActual < dia)) {
        edad--;
      }
      return edad;
    }
  },
};
</script>

<style scoped>
.condition {
  margin-left: -8px;
}
.spinner {
  display: none;
}
.buttonAndSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input {
  resize: vertical;
  margin: 0;
}
.chkbx {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.txtchkbx {
  margin-top: 8px;
}
.visible {
  color: #dc3545;
  text-align: center;
  margin-top: 3px;
}
</style>
