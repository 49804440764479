import axios from "axios";
import * as XLSX from 'xlsx';


export const sumariosToExcel2 = {
  data() {
    return {
      dataExcel: { name: "", data: [], fields: {}, showModal: false, loading: false, error: "" },
    };
  },
  methods: {
    /**
     * @param {String} tipo 'Planilla del Delito'
     */
    
    async downloadXls2(tipo) {
      this.$store.commit("sumarioList/loadingUpdate", true);
      let limit = 200;//esta variable se usa para regular la cantidad de registros que se traen por llamada a la api, si se usa un numero muy grande puede traer problemas de timeout y si se usa uno muy pequeño realiza muchas llamadas a la api
      let dataActuaciones = [];
      let url = this.lastUrl.replace('limit=8&offset=0', `limit=${limit}&offset=0`);

      //Traemos la info de todas las actuaciones filtradas y no solo la de las ultimas 8

      try{
        if (this.total > limit) {
          for (let i = 0; i < Math.ceil(this.total / limit); i++) {
              url = this.lastUrl.replace('limit=8&offset=0', `limit=${limit}&offset=${limit * i}`).replace('&getTotal=true', '')
              console.log('url: ', i, url)
            let res = (await axios.get(`${url + '&getXLSpersonalizado=' + tipo}`, { withCredentials: true, params: { method: "own" } })).data.data
            dataActuaciones = [...dataActuaciones, ...res]
          }
        } else {
          dataActuaciones = (await axios.get(`${url + '&getXLSpersonalizado=' + tipo}`, { withCredentials: true, params: { method: "own" } })).data.data
        }
      }catch(e){
        console.error('error: ', e)
        this.dataExcel.error="Ocurrio un error en la carga de los registros"
        this.dataExcel.loading = false;
      }
      
      //lotes de prueba
      // let dataActuaciones = (await axios.get(`${this.lastUrl.replace('limit=8&offset=0', '') + '&getXLSpersonalizado=' + "Planeamiento"}`, { withCredentials: true})).data.data
      // let dataActuaciones = (await axios.get(`${this.lastUrl.replace('limit=8&offset=0', 'limit=40&offset=0') + '&getXLSpersonalizado=' + "Planeamiento"}`, { withCredentials: true})).data.data

      console.log(dataActuaciones);

      // Crea el archivo de Excel
      const workbook  = XLSX.utils.book_new();

      //creamos la hoja de trabajo junto con los titulos y subtitulos
      const worksheet  = XLSX.utils.aoa_to_sheet([
        this.buildArray({"SUMARIO":10,"LUGAR":5,"Sumario":15,"ESTADO":1,"SP (Solicitud de  paradero) / SPC (Solicitud de pedido de Captura )":1,"Lugar de Detención (Donde fue aprehendido?)":2,"EFECTO":11,"ESCLARECIDO":1,"OBSERVACIONES":1,"Relato":1}),
        // ["SUMARIO", "","","","","","", "","","", "LUGAR","","","","", "SUMARIO","","","","","","", "","","","","","", "ESTADO","SP (Solicitud de  paradero) / SPC (Solicitud de pedido de Captura )","Lugar de Detención (Donde fue aprehendido?)","","EFECTO","","","","","","", "","","","","","",""],
        this.buildArray({"Departamental":1,"Dependencia":1,"Fecha de inicio (PREVENTIVO)":1,"Fecha Del Hecho":1,"Fecha de Cierre (RESUMEN)":1,"Nº Srio.":1,"Causa/Caratula - Art":1, "Hora":1,"Modus Operandi":1,"Sitio":1,"Domicilio exacto donde Ocurrio el Hecho o Referencia":1,"Departamento":1,"Coordenada":1,"Latitud":1,"Longitud":1, "AFECTADO":8,"VINCULADO":9,
        "":2,"Efecto":1,"Medida ($ - Kg - Litro - Kilates )":1,"Cantidad":1,"Marca":1,"Nº Serie/Chasis/ Imei":1, "Dominio":1,"ESTADO EFECTO":1,"Nº Motor":1,"Color":1,"Modelo":1,"Cilindrada":1}),
        //["Departamental","Dependencia","Fecha de inicio (PREVENTIVO)","Fecha Del Hecho","Fecha de Cierre (RESUMEN)","Nº Srio.","Causa (Caratula)", "Hora","Modus Operandi","Sitio","Domicilio exacto donde Ocurrio el Hecho o Referencia","Departamento","Coordenada","Latitud","Longitud", "DAMNIFICADO","","","","","VINCULADO","", "","","","","","", "", "","","","","","","","","", "","","","","","",""],
        this.buildArray({"":15,"Apellido":1,"Nombre":1,"Documento":1,"Edad":1,"Sexo":1,"Correo Electronico":1,"N° de Telefono":1,"Domicilio":1,"Apellido ":1,"Nombre ":1,"Documento ":1,"Edad ":1,"Sexo ":1,/*"Correo Electronico ":1,"N° de Telefono ":1,*/"Domicilio ":1, "Apodo":3,"Domicilio   ":1,"Departamento":1,}),
        // this.buildArray({"":15,"Apellido":1,"Nombre":1,"Documento":1,"Edad":1,"Sexo":1,"Domicilio":1,"Apellido ":1,"Nombre ":1,"Documento ":1,"Edad ":1,"Sexo ":1,"Domicilio ":1, "Apodo":3,"Domicilio   ":1,"Departamento":1,}),//Este es el formato viejo

      ]);
      
      //recorre las columnas dese A a AV
      for (let i = 0; i < 48; i++) { // 48 es el número de columnas entre A y AV 
        const col = XLSX.utils.encode_col(i); // Convierte el número de columna en su letra correspondiente
        this.modifyColumWidth(worksheet,col, 20);// Modifica el ancho de la celda A
      }

      // Define la combinación de celdas en la hoja de trabajo
      worksheet ['!merges'] = [
                              //Titulos
                              { s: { r: 0, c: 0 }, e: { r: 0, c: 9 } },   // A1-J1
                              { s: { r: 0, c: 10 }, e: { r: 0, c: 14 } },  // K1-O1
                              { s: { r: 0, c: 15 }, e: { r: 0, c: 26 } },  // P1-AB1
                              // { s: { r: 0, c: 30 }, e: { r: 0, c: 31 } },  // AE1-AF1
                              { s: { r: 0, c: 30 }, e: { r: 2, c: 30 } },  // AE1-AE3 //Estado
                              { s: { r: 0, c: 31 }, e: { r: 2, c: 31 } },  // AF1-AF3 //SP/SPC
                              { s: { r: 0, c: 32 }, e: { r: 1, c: 33 } },  // AG1-AH2 //Lugar de Detención
                              { s: { r: 0, c: 34 }, e: { r: 0, c: 44 } },  // AH1-AN1  //Efecto
                              { s: { r: 0, c: 48 }, e: { r: 2, c: 48 } },  // AR1-AR3 //Esclarecido
                              { s: { r: 0, c: 49 }, e: { r: 2, c: 49 } },  // AS1-AS3 //Observaciones
                              { s: { r: 0, c: 50 }, e: { r: 2, c: 50 } },  // AX1-AX3 //Relato

                              //Apartados Sumario
                              { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },  // A2-A3
                              { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },  // B2-B3
                              { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },  // C2-C3
                              { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },  // D2-D3
                              { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },  // E2-E3
                              { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },  // F2-F3
                              { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },  // G2-G3
                              { s: { r: 1, c: 7 }, e: { r: 2, c: 7 } },  // H2-H3
                              { s: { r: 1, c: 8 }, e: { r: 2, c: 8 } },  // I2-I3
                              { s: { r: 1, c: 9 }, e: { r: 2, c: 9 } },  // J2-J3

                              //Apartados Lugar
                              { s: { r: 1, c: 10 }, e: { r: 2, c: 10 } },  // K2-K3
                              { s: { r: 1, c: 11 }, e: { r: 2, c: 11 } },  // L2-L3
                              { s: { r: 1, c: 12 }, e: { r: 2, c: 12 } },  // M2-M3
                              { s: { r: 1, c: 13 }, e: { r: 2, c: 13 } },  // N2-N3
                              { s: { r: 1, c: 14 }, e: { r: 2, c: 14 } },  // O2-O3
                              
                              //Apartados Efectos
                              // { s: { r: 1, c: 31 }, e: { r: 2, c: 31 } },  
                              { s: { r: 1, c: 34 }, e: { r: 2, c: 34 } },  // AI2-AI3 //Efecto
                              { s: { r: 1, c: 35 }, e: { r: 2, c: 35 } },  // AJ2-AJ3 //Medida ($ - Kg - Litro - Kilates )
                              { s: { r: 1, c: 36 }, e: { r: 2, c: 36 } },  // AK2-AK3 //Cantidad
                              { s: { r: 1, c: 37 }, e: { r: 2, c: 37 } },  // AL2-AL3 //Marca
                              { s: { r: 1, c: 38 }, e: { r: 2, c: 38 } },  // AM2-AM3 //Nº Serie/Chasis/ Imei
                              { s: { r: 1, c: 39 }, e: { r: 2, c: 39 } },  // AN2-AN3 //Dominio
                              { s: { r: 1, c: 40 }, e: { r: 2, c: 40 } },  // AO2-AO3 //ESTADO EFECTO
                              { s: { r: 1, c: 41 }, e: { r: 2, c: 41 } },  // AP2-AP3 //Nº Motor
                              { s: { r: 1, c: 42 }, e: { r: 2, c: 42 } },  // AQ2-AQ3 //Color
                              { s: { r: 1, c: 43 }, e: { r: 2, c: 43 } },  // AR2-AR3 //Modelo
                              { s: { r: 1, c: 44 }, e: { r: 2, c: 44 } },  // AS2-AS3 //Cilindrada

                              //Subtitulos Sumario
                              { s: { r: 1, c: 15 }, e: { r: 1, c: 22 } },  //P2-W2 // AFECTADO
                              { s: { r: 1, c: 23 }, e: { r: 1, c: 29 } },  //U2-AB2 // VINCULADO
                      
                            ]; 


      this.dataVariable(worksheet, dataActuaciones);

      // Ajustar el ancho de la columna "Relato" al contenido
      const maxRelatoLength = dataActuaciones.reduce((max, actuacion) => {
        //Buscamos la celda con mayor numero de caracteres
        const relatoLength = actuacion['DenunciaRelato'] ? actuacion['DenunciaRelato'].length : 0;
        return Math.max(max, relatoLength);
      }, 0);
      // console.log("el ancho del texto mas grande segun esto es de: ", maxRelatoLength);
      //Le aplicamos el ancho mencionado a la columna del Relato
      this.modifyColumWidth(worksheet, 'AV',maxRelatoLength);




      // Centrar el contenido de la celda B5
      const cellAddressB5 = { c: 1, r: 4 }; // Columna B es la número 1, Fila 5 (índice 4)
      const cellRefB5 = XLSX.utils.encode_cell(cellAddressB5);
      const cellB5 = worksheet[cellRefB5];
      if (cellB5) {
        cellB5.s = { alignment: { wrapText: true, horizontal: 'center', vertical: 'center' } };
      }


      // Agrega la hoja de trabajo al libro
      XLSX.utils.book_append_sheet(workbook , worksheet , "Planilla del Delito");
      
      // Descarga el archivo XLSX
      XLSX.writeFile(workbook , "Planilla del Delito.xlsx");
      

      // Obtiene el número de columna a partir de su letra
      // console.log(this.columnToNumber('C'));

      this.$store.commit("sumarioList/loadingUpdate", false);
    },


    dataVariable(ws, actuaciones){
      var index = {value : 3};
      // Agrega valores a la hoja de trabajo
      for(let i = 0; i < actuaciones.length; i++){//Recorremos las Actuaciones
        // console.log("indice: ",i, " id actuacion: ", actuaciones[i].ActuacionID);
        let esclarecido = actuaciones[i]['Esclarecido'] == 0? "No" : "Si";
        let relato = actuaciones[i]['DenunciaRelato'];
        let valores = [];
        let personas = actuaciones[i]['ActuacionPersonaFisica'];
        for (const key in personas) {//Recorremos los roles de las personas de la denuncia
          if(key == 'VINCULADO'){
            this.loadPersona(ws,personas[key],actuaciones[i],esclarecido,index,"vinculados",relato);

          }else{
            this.loadPersona(ws,personas[key],actuaciones[i],esclarecido,index,"afectado",relato);

          }
        }
        if(actuaciones[i]['Efectos']){//si la actuacion tiene efectos
          let efectos = actuaciones[i]['Efectos'];
          for(let j = 0; j < efectos.length; j++){
            
            valores = this.datosGenerales(actuaciones[i]);//Esta funcion agrega los datos generales de la actuacion por ejemplo N° de actuacion, fecha de creacion, etc
            let efecto = efectos[j];
            // console.log("efecto: ", efecto);
            valores.push(
              "","","","","","","","","","","","","","","","","","","",
              efecto['Tipo'],//Efecto''
              "","",//Medida '',//Cantidad
              efecto['Marca'],//Marca
              efecto['IdSeriePatente'],//N° Serie/Chasis/Imei
              efecto['IdSeriePatente'],//Dominio
              efecto['Tipoefecto'],//Estado Efecto
              efecto['NumeroMotor'],//N° Motor
              efecto['Color'],//Color
              efecto['Modelo'],//Modelo
              '',//Cilindrada
              esclarecido,//Esclarecido
              '',//Observaciones
              relato,//Relato
            );
            XLSX.utils.sheet_add_aoa(ws, [[],valores], {origin:`A${index.value}`});
            index.value ++;
          }
        }

        // XLSX.utils.sheet_add_aoa(ws, [[]], {origin:`A${index.value}`});//es solo para separar las actuaciones
        // index.value ++;
      }
    },
    loadPersona(ws, personas,actuacion,esclarecido, index , tipo = null, relato){
      for(let j = 0; j < personas.length; j++){
        let valores = this.datosGenerales(actuacion);//Esta funcion agrega los datos generales de la actuacion por ejemplo N° de actuacion, fecha de creacion, etc

        let persona = personas[j];
        // console.log(personas[key][j]);
        // console.log("valores antes: ", valores);
        if(tipo == "vinculados"){
          valores.push(
            "","","","","","","","",
            persona['Apellido'],//Apellido,
            persona['Nombre'],//Nombre,
            persona['Dni'],//Documento,
            this.calcularEdad(persona['FechaDeNacimiento']),//Edad,
            persona['Sexo'],//Sexo,
            // persona['Email'],//Correo Electronico
            // persona['Telefono'],//N° de Telefono
            persona['DomicilioSipe'],//Domicilio
            persona['Apodo'],//Apodo
            persona['RolesdenunciaID'],//Estado
            "","","","","","","","","","","","","","",
            esclarecido,//Esclarecido
            '',//Observaciones
            relato,//Relato
          );
        }else{
          valores.push(
            persona['Apellido'],//Apellido,
            persona['Nombre'],//Nombre,
            persona['Dni'],//Documento,
            this.calcularEdad(persona['FechaDeNacimiento']),//Edad,
            persona['Sexo'],//Sexo,
            persona['Email'],//Correo Electronico
            persona['Telefono'],//N° de Telefono
            persona['DomicilioSipe'],//Domicilio
            "","","","","","","",
            persona['RolesdenunciaID'],//Estado
            "","","","","","","","","","","","","","",
            esclarecido,//Esclarecido
            '',//Observaciones
            relato,//Relato
          );
        }
        XLSX.utils.sheet_add_aoa(ws, [[],valores], {origin:`A${index.value}`});
        index.value ++;
      }
    },
    calcularEdad(birthday) {
      // console.log("fechaNacimiento: ", birthday );
      if(birthday != null && birthday != '' && birthday != undefined && birthday != '00-00-0000'){
        birthday = birthday.replaceAll('-', '/')
        let dateWithReverseMounth  = birthday.split('/')
        dateWithReverseMounth  = dateWithReverseMounth[1] + '/' + dateWithReverseMounth[0] + '/' + dateWithReverseMounth[2]
        birthday = dateWithReverseMounth
        birthday = new Date(birthday)
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        // console.log("edad: ", Math.abs(ageDate.getUTCFullYear() - 1970));
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      }else{return '---'}
    },
    /*
    La siguiente funcion recibe como argumento los datos de una actuacion, la hoja de trabajo y el indice de la fila donde se agregaran los datos
    Carga los datos generales de la actuacion en la hoja de trabajo en la fila indicada
     */
    datosGenerales(actuacion){
      return  [
                  actuacion['Departamental'],actuacion['DependenciaLetra'],this.formatFecha(actuacion['FechaCreacion']),
                  this.formatFecha(actuacion['HechoubicacionFechahoradesde']), "",
                  actuacion['Numero'], 
                  this.causaOrArticulo(actuacion['Actuacioncausacaratula'], actuacion['ActuacionArticulo']), 
                  this.formatHora(actuacion['FechaCreacion']), 
                  actuacion['Modusoperandi'] ? actuacion['Modusoperandi']['Nombre'] : "No posee", 
                  actuacion['Sitio'],
                  //Datos sobre el Lugar del Hecho
                  this.concatenarStrings(" ",[actuacion['HechoubicacionCalle'], actuacion['HechoubicacionNumero'], actuacion['HechoubicacionOrientacion']]),//Domicilio exacto donde Ocurrio el Hecho o Referencia
                  actuacion['HechoubicacionDepartamento'],//Departamento
                  this.concatenarStrings(" ; ", [actuacion['HechoubicacionLatitud'] , actuacion['HechoubicacionLongitud']]),//Coordenada
                  actuacion['HechoubicacionLatitud'],//Latitud
                  actuacion['HechoubicacionLongitud'],//Longitud
                ];
    },
    causaOrArticulo(causas, articulos){
      if(causas){
        return this.formatCausaCaratula(causas);
      }
      if(articulos){
        return this.formatArticulos(articulos);
      }
      return "No posee";
    },
    concatenarStrings(separador, array){
      const arrayFiltrado = array.filter((valor) => valor !== null && valor !== undefined && valor !== "");
      return arrayFiltrado.join(separador);
    },
    formatArticulos(data){
      return data.map(objeto => objeto['Numeroarticulo']).join(" - ");
    },    
    formatCausaCaratula(data){
      return data.map(objeto => objeto['Nombre']).join(" - ");
    },
    formatHora(date){
      let fecha = new Date(date)
      return fecha.getHours() + ':' + fecha.getMinutes() + ':' + fecha.getSeconds()
    },
    formatFecha(date){
      let fecha = new Date(date)
      return fecha.toLocaleDateString('es-AR');//esto ya la trae en formato slash
    },
    buildArray(data){
      let newArray = [];
      for (const key in data) {
        newArray.push(key);
        // console.log("key: ",key)
        // console.log("data[key]: ",data[key])
        for(let i = 1; i < data[key]; i++){
          newArray.push("");
        }
      }
      // console.log("newArray: ",newArray);
      return newArray;
    },

    modifyColumWidth(ws, col, ancho) {
      // Obtiene las propiedades de la columna
      const columna = XLSX.utils.decode_col(col);
      const columnProperties = ws['!cols'] ? ws['!cols'][columna] || {} : {};
      // console.log("columnProperties: ",columnProperties);
      // Establece el ancho de $columna en ancho
      columnProperties.width = ancho;

      // Establece la alineación del contenido en "center"
      columnProperties.align = "center";// esto no estaria funcionando
      
      // Guarda las propiedades de la columna en la hoja de trabajo
      if (!ws['!cols']) ws['!cols'] = [];
      ws['!cols'][columna] = columnProperties;
    },

    columnToNumber(col) {
      let num = 0;
      for (let i = 0; i < col.length; i++) {
        num *= 26;
        num += col.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
      }
      return num;
    },
    
  }
 
};
