export default   [
    
    {key: 0, title: 'DEPARTAMENTAL', content: 'Gestionar las departamentales', url: '/parametros/departamental',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    // {key: 1, title: 'TIPO DE EFECTO ATRIBUTO', content: 'Especificar los atributos de cada efecto denunciado que se usará en el sistema', url: '/parametros/tipo-efecto-atributo'},
    // {key: 2, title: 'TIPO DE DENUNCIA', content: 'Establecer el tipo de denuncias que existirán en los formularios del sistema', url: '/parametros/tipo-denuncia'},
    {key: 3, title: 'GÉNERO', content: 'Parametrizar géneros', url: '/parametros/genero', tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    {key: 4, title: 'GESTOR DE DILIGENCIAS', content: 'Creacion y Configuracion de nuevos modelos de Diligencias, y Gestion de los modelos ya existentes', url: '/parametros/tipo-diligencia'},
    // {key: 4, title: 'UBICACIÓN', content: 'Especificar los diferentes campos de ubicación que establecerán el lugar del hecho', url: '/ubicacion'},
    // {key: 5, title: 'ESTADO SUMARIO', content: 'Establecer los diferentes estados de sumario que existirán en el sistema', url: '/parametros/tipo-estado-actuacion'},
    // {key: 6, title: 'FORMACIÓN', content: 'Definir los diferentes tipos de formación para el uso en el sistema', url: '/parametros/formacion'},
    // {key: 7, title: 'TIPO EFECTO ATRIBUTO VALOR (TEAVALOR)', content: 'Establecer los diferentes "Tipo Efecto Atributo Valor (Teavalor)" para su uso en el sistema', url: '/tea-valor'},
    // {key: 8, title: 'ESTADO JUZGADO', content: 'Parametrizar los diferentes "Estados de juzgado" para su uso en el sistema', url: '/parametros/estado-juzgado'},
    // {key: 9, title: 'ESTADO USUARIO', content: 'Establecer los diferentes "Estados de usuario" para su uso en el sistema', url: '/parametros/estado-usuario'},
    // {key: 10, title: 'ESTADO EFECTO DENUNCIA', content: 'Establecer los diferentes estados de cada efecto de denuncia', url: '/estado-efecto-denuncia'},
    // {key: 11, title: 'TIPO USUARIO', content: 'Especificar los diferentes tipos de cada usuario', url: '/parametros/tipo-usuario'},
    {key: 11, title: 'ARTÍCULOS (ACTUACIONES)', content: 'Gestiona articulos para actuaciones', url: '/parametros/articulos'},
    // {key: 12, title: 'ESTADO EFECTO', content: 'Definir los diferentes estados que van a tener cada uno de los efectos denunciados', url: '/estado-efecto'},
    // {key: 13, title: 'ROLES DENUNCIA', content: 'Parametrizar los diferentes roles que cada denuncia va a tener', url: '/parametros/roles-denuncia'},
    // {key: 14, title: 'ESTADO DILIGENCIA', content: 'Especificar los distintos estados por los que llegará a estar una diligencia', url: '/parametros/estado-diligencia'},
    // {key: 15, title: 'ESTADO DEPENDENCIA', content: 'Establecer los distintos estados por los que llegará a estar una dependencia', url: '/estado-dependencia'},
    // {key: 16, title: 'DEPENDENCIA TIPO', content: 'Parametrizar los tipos de dependencia que existirán ', url: '/dependencia-tipo'},
    {key: 17, title: 'PAÍS', content: 'Definir los países que se listarán en el sistema', url: '/parametros/pais', tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    {key: 18, title: 'DEPÓSITO DEPENDENCIA', content: 'Establecer los diferentes "depósitos de dependencia" que se usarán en el sistema', url: '/parametros/deposito-dependencia', tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    // {key: 19, title: 'ESTADO DENUNCIA', content: 'Especificar los diferentes estados de denuncia que existirán en el sistema', url: '/parametros/estado-denuncia'},
    // {key: 20, title: 'TAMAÑO SECCIÓN', content: 'Crear el tamaño de una sección', url: '/parametros/tamano-seccion'},
    {key: 21, title: 'DEPENDENCIA', content: 'Gestionar las dependencias', url: '/parametros/dependencia',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    // {key: 22, title: 'FORMACIÓN PERSONA', content: 'Establecer la formación de una persona', url: '/formacion-persona'},
    // {key: 23, title: 'EFECTO DEPÓSITO DEPENDENCIA', content: 'Establecer "Efecto depósito dependencia" para su uso en el sistema', url: '/efecto-deposito-dependencia'},
    // {key: 24, title: 'DEPENDENCIA ESTADO', content: 'Definir los diferentes estados que van a tener cada uno de las dependencias', url: '/dependencia-estado'},
    // {key: 25, title: 'AUTORIDAD DEPENDENCIA', content: 'Gestionar la autoridad de cada una de las dependencias', url: '/autoridad-dependencia'},
    {key: 26, title: 'CARÁTULA', content: 'Gestionar las Carátulas, Cuasas y Delitos', url: '/parametros/caratula'},
    {key: 29, title: 'PERSONAL FISCAL', content: 'Gestionar el Personal Fiscal, Fiscales y Ayudantes', url: '/parametros/personal-fiscal'},
    // {key: 27, title: 'CONTRASEÑA', content: 'Gestionar las diferentes contraseñas del sistema', url: '/parametros/contrasena'},
    // {key: 28, title: 'COINCIDENCIA EFECTO', content: 'Establecer las coincidencias de efecto', url: '/coincidencia-efecto'},
    // {key: 29, title: 'DENUNCIA', content: 'Gestionar denuncias', url: '/denuncia'},
    // {key: 30, title: 'DILIGENCIA', content: 'Gestionar diligencias', url: '/diligencia'},
    // {key: 31, title: 'TIPO DILIGENCIA', content: 'Gestionar el tipo de las diligencias', url: '/parametros/tipo-diligencia'},
    // {key: 32, title: 'TIPO DILIGENCIA ATRIBUTO', content: 'Gestionar los atributos de las diligencias', url: '/parametros/tipo-diligencia-atributo'},
    // {key: 33, title: 'TIPO DILIGENCIA ATRIBUTO VALOR', content: 'Gestionar los valores de cada atributo de las diligencias', url: '/parametros/tipo-diligencia-atributo-valor'},
    // {key: 34, title: 'TIPO EFECTO', content: 'Establecer el tipo de los efectos', url: '/tipo-efecto'},
    // {key: 35, title: 'TIPO JUZGADO', content: 'Definir el tipo de cada juzgado', url: '/parametros/tipo-juzgado'},
    // {key: 36, title: 'TIPO LUGAR', content: 'Especificar el tipo de los lugares', url: '/parametros/tipo-lugar'},
    // {key: 37, title: 'TIPO SUMARIO', content: 'Establecer el tipo de sumario', url: '/parametros/tipo-actuacion'},
    // {key: 38, title: 'ATRIBUTO MODUS OPERANDIS', content: 'Gestionar atributos de modus operandis', url: '/atributo-mo'},
    // {key: 39, title: 'CALLE', content: 'Gestionar calles', url: '/parametros/calle'},
    // {key: 40, title: 'JERARQUÍA DEPENDENCIA', content: 'Administrar jerarquía de dependencias', url: '/jerarquia-dependencia'},
    // {key: 41, title: 'JERARQUÍA JUZGADO', content: 'Gestionar jerarquía de juzgados', url: '/parametros/jerarquia-juzgado'},
    // {key: 42, title: 'LOCALIDAD', content: 'Administrar localidades', url: '/parametros/localidad'},
    // {key: 43, title: 'CUADRANTE', content: 'Gestionar cuadrantes', url: '/parametros/cuadrante'},
    // {key: 44, title: 'PERMISO USUARIO', content: 'Administrar los permisos de cada usuario que utiliza el sistema', url: '/parametros/permiso-usuario'},
    // {key: 45, title: 'PERSONA JURÍDICA', content: 'Gestionar las distintas características de una persona jurídica', url: '/persona-juridica'},
    // {key: 46, title: 'GÉNERO PERSONA', content: 'Parametrizar géneros de personas', url: '/genero-persona'},
    // {key: 47, title: 'MODUS OPERANDIS ATRIBUTO (MOATRIBUTOMO)', content: 'Definir el "MOATRIBUTOMO" de cada modus operandi', url: '/parametros/mo-atributo-mo'},
    // {key: 48, title: 'SUMARIO ESTADO', content: 'Administrar estado del sumario', url: '/estado-actuacion'},
    // {key: 49, title: 'ROLL TIPO DENUNCIA', content: 'Establecer el roll tipo denuncia', url: '/rol-tipo-denuncia'},
    // {key: 50, title: 'PERMISO TIPO USUARIO', content: 'Definir el permiso tipo usuario', url: '/permiso-tipo-usuario'},
    // {key: 51, title: 'PERSONA DOMICILIO', content: 'Establecer domicilios de personas', url: '/persona-domicilio'},
    // {key: 52, title: 'OCUPACIÓN PERSONA', content: 'Definir ocupaciones de personas', url: '/parametros/ocupacion-persona'},
    // {key: 53, title: 'USUARIO SUMARIO', content: 'Administrar "usuario sumario"', url: '/actuacion-usuario-dependencia'},
    // {key: 54, title: 'JUZGADO ESTADO', content: 'Gestionar los distintos estados de un juzgado', url: '/juzgado-estado'},
    // {key: 55, title: 'JERARQUÍA DEPENDENCIA AUTORIDAD', content: 'Establecer la jerarquía de la autoridad de las dependencias', url: '/jerarquia-dependencia-autoridad'},
    // {key: 56, title: 'HECHO UBICACIÓN', content: 'Administrar el "hecho ubicación"', url: '/hecho-ubicacion'},
    {key: 56, title: 'TIPO DE MONEDA', content: 'Gestionar las monedas en las que se pueden realizar las transacciones', url: '/parametros/tipo-moneda'},

    {key: 57, title: 'ESTADO CIVIL', content: 'Gestionar estados civiles', url: '/parametros/estado-civil',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    // {key: 58, title: 'ESTADO CIVIL PERSONA', content: 'Definir estados civiles de personas', url: '/estado-civil-persona'},
    // {key: 59, title: 'ENCARGADO DEPÓSITO', content: 'Establecer "encargado deposito"', url: '/encargado-deposito'},
    // {key: 60, title: 'EFECTO ESTADO', content: 'Gestionar los distintos estados de efectos', url: '/efecto-estado'},
    // {key: 61, title: 'EFECTO DENUNCIA ESTADO', content: 'Administrar los diferentes estados de denuncia de efectos', url: '/efecto-denuncia-estado'},
    // {key: 62, title: 'DILIGENCIA ESTADO', content: 'Administrar estados de diligencias', url: '/diligencia-estado'},
    // {key: 63, title: 'AUTORIDAD JUZGADO', content: 'Gestionar autoridad de juzgados', url: '/autoridad-juzgado'},
    // {key: 64, title: 'BARRIO', content: 'Parametrizar barrios', url: '/parametros/barrio'},
    // {key: 65, title: 'DENUNCIA ESTADO USUARIO', content: 'Definir "denuncia estado usuario', url: '/denuncia-estado-usuario'},
    // {key: 66, title: 'EFECTO', content: 'Parametrizar efectos', url: '/efecto'},
    // {key: 67, title: 'EFECTO DENUNCIA', content: 'Gestionar efectos de denuncias', url: '/parametros/efecto-denuncia',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    // {key: 68, title: 'FORMATO', content: 'Parametrizar formato', url: '/parametros/formato'},
    {key: 69, title: 'PROVINCIA', content: 'Parametrizar provincias', url: '/parametros/provincia',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    // {key: 70, title: 'ROLL DEPENDENCIA SUMARIO', content: 'Establecer el roll dependencia sumario', url: '/parametros/rol-dependencia-sumario'},
    // {key: 71, title: 'ROLL JUZGADO SUMARIO', content: 'Establecer el roll juzgado sumario', url: '/parametros/rol-juzgado-actuacion'},
    // {key: 72, title: 'ROLL PERSONA DENUNCIA', content: 'Establecer el roll persona denuncia', url: '/parametros/rol-persona-denuncia'},
    // {key: 74, title: 'TIPO EFECTO ATRIBUTO VALOR - TIPO EFECTO ATRIBUTO (TEAVTEA)', content: 'Parametrizar Tipo Efecto Atributo Valor o Tipo Efecto Atributo(Teavtea)', url: '/tea-v-tea'},
    // {key: 75, title: 'TIPO EFECTO ATRIBUTO - TIPO EFECTO ATRIBUTO (TEATE)', content: 'Parametrizar Tipo Efecto Atributo o Tipo Efecto Atributo(Teate)', url: '/tea-te'},
    {key: 77, title: 'USUARIO', content: 'Gestionar a los diferentes usuarios del sistema', url: '/parametros/usuario',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    // {key: 78, title: 'TIPO DILIGENCIA', content: 'Administrar tipos de diligencias', url: '/parametros/tipo-diligencia'},
    // {key: 79, title: 'DOMICILIO', content: 'Gestionar domicilios', url: '/domicilio'},
    // {key: 80, title: 'JUZGADO TIPO JUZGADO', content: 'Administrar los diferentes tipos de juzgado', url: '/juzgado-tipo-juzgado'},
    {key: 81, title: 'JUZGADO', content: 'Gestionar juzgados', url: '/parametros/juzgado'},
    
    // {key: 82, title: 'IDENTIKIT', content: 'Gestionar identikits', url: '/identikit'},
    // {key: 83, title: 'HECHO', content: 'Gestionar hechos', url: '/parametros/hecho'},
    // {key: 84, title: 'PRÓRROGA SUMARIO', content: 'Administrar prórrogas de sumarios', url: '/parametros/prorroga-sumario'},
    {key: 85, title: 'PERSONA FÍSICA', content: 'Administrar personas físicas', url: '/parametros/persona-fisica',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    // {key: 86, title: 'OCUPACIÓN', content: 'Administrar ocupaciones', url: '/ocupacion'},
    {key: 87, title: 'MODUS OPERANDI', content: 'Administrar modus operandis', url: '/parametros/modus-operandi'},
    // {key: 89, title: 'JERARQUÍA', content: 'Gestionar jerarquías', url: '/parametros/jerarquia'},
    // {key: 90, title: 'SUMARIO', content: 'Gestionar sumarios', url: '/sumarioNuevo'},
    // {key: 91, title: 'USUARIO ESTADO', content: 'Gestionar estados de usuario', url: '/usuario-estado'},
    // {key: 92, title: 'VALOR ATRIBUTO MODUS OPERANDI', content: 'Definir los valores y atributos de los modus operandis', url: '/valor-atributo-mo'},
    { key: 93, title: 'MARCAS', content: 'Gestionar Marcas de modulo Deposito', url: '/parametros/marcas',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    { key: 94, title: 'MODELOS', content: 'Gestionar Modelos de modulo Deposito', url: '/parametros/modelos',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    { key: 95, title: 'CATEGORIAS', content: 'Gestionar Categorias de modulo Deposito', url: '/parametros/categorias',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    { key: 96, title: 'SUB-CATEGORIAS', content: 'Gestionar Sub-Categorias de modulo Deposito', url: '/parametros/sub-categorias',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    { key: 97, title: 'TIPO DE EFECTO', content: 'Gestionar Tipo de modulo Deposito', url: '/parametros/tipo-efecto-deposito',tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]} },
    { key: 98, title: 'GESTOR DE ARCHIVOS', content: 'Gestionar Archivos para la Biblioteca', url: '/parametros/gestor-archivos', tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    { key: 99, title: 'CATEGORIAS DE TIPO DE ARCHIVO PDF', content: 'Gestionar las categorías para los tipos de archivos PDF para el Gestor de Archivos', url: '/parametros/categorias-tipo-archivo', tipoUsuario:{excludes:[ "Dpto. Asuntos Judiciales D-5", "Dpto.Criminalistica","Lab. Informatico Forense","Dpto. Planeamiento"]}},
    { key: 100, title: 'SITIOS', content: 'Administrar los sitios en el sistema', url: '/parametros/sitio'},
]
