import Vue from 'vue'
import VueRouter from 'vue-router'


// Pages
import Dashboard from '@/pages/Dashboard.vue';
import AccesoDenegado from '@/pages/AccesoDenegado.vue';
import PaginaNoEncontrada from '@/pages/PaginaNoEncontrada.vue';
import CerrandoSesion from '@/pages/CerrarSesion.vue';
// Modulos de rutas
import depositoRoutes from '@/router/depositoRoutes';
import actuacionRoutes from '@/router/actuacionesRoutes';
import parametrizacionRoutes from '@/router/parametrizacionRoutes'
import isUserRolGuard from "@/guards/isUserRolGuard";
import Location from "@/pages/Location.vue";
import Contrasenia from "@/pages/Contrasena.vue";

Vue.use(VueRouter)


// Pages
const routes = [
    {
      path: '/',
      name: 'escritorio',
      component: Dashboard,
      props: { pageTitle: "ESCRITORIO" }
    },
    {
      path: '/deposito',
      name: 'deposito-efectos',
      ...depositoRoutes
    },
    {
      path:'/actuaciones',
      beforeEnter: isUserRolGuard,
      ...actuacionRoutes
    }, 
    {
      path:'/parametrizacion',
      // beforeEnter: isUserRolGuard,
      redirect:'/parametros',
    },
    {
      path:'/parametros',
      name: 'parametros',
      // beforeEnter: isUserRolGuard,
      ...parametrizacionRoutes
    }, 
    { 
      path: '/location',        
      name: 'localizacion',
      component: Location    
    },
    { 
      path: '/editar-contrasena',        
      name: 'cambiar-contraseña',
      component: Contrasenia     
    },
    { path: '/acceso-denegado', AccesoDenegado  , component: AccesoDenegado},
    { path: '/logout', name: 'cerrando-sesion'    , component: CerrandoSesion},
    { path: '/:pathMatch(.*)*', name: 'not-found', component: PaginaNoEncontrada },

   


  ]


const router = new VueRouter({
  routes,
  mode: 'history'
})
router.afterEach((to, from) => {
  const fromRoute=from.path;
  const toRoute=to.path;
  if (Vue.prototype.$isOffline) {
     if (fromRoute.startsWith("/actuaciones/new/") && toRoute.startsWith("/actuaciones/new/")) {
      return; // No hacemos nada, evitamos ejecutar el modal
    }
    document.dispatchEvent(new CustomEvent('sw-offline-detected'));
    // Reiniciamos la variable para evitar que se vuelva a disparar
    Vue.prototype.$isOffline = false;
  }
});

export default router

