<template>
  <div id="app">
    <!-- Modal "Sin conexión" -->
    <ModalTemplate
      title="Sin conexión a internet"
      type="informativo"
      :showIcon="true"
      severity="info"
      ref="infoModal"
      message="No se ha detectado conexión a Internet. Será redirigido automáticamente al sistema offline para que pueda continuar trabajando sin interrupciones."
      :closable="false"
      @confirm="handleOfflineRedirection()"
    />
    <div v-if="!loading">
      <Toolbar v-if="shouldShowToolbar" />
      <div id="pageHome">
        <div class="allPage">
          <div v-if="shouldShowRouterView" class="contentPages">
            <div style="align-items: center; padding: .5em 0 0 1em;" class="flex pl-7">              
              <CustomButton v-if="shouldShowBackButton"
                icon="pi pi-arrow-left"
                buttonColor="#2196f3"
                tooltipText="Regresar"
                @click="goBack()"
              />
              <h2 style="margin: 0;">{{ currentTitle }}</h2>
            </div>
            <router-view></router-view>
          </div>
          <div v-if="shouldShowLoginPage" class="contentPages">
            <login-page :online="online"></login-page>
          </div>
        </div>
        <div class="footer" v-if="shouldShowLogo">
          <img alt="Logo de la Policia de San Juan" src="./assets/logo-policia-de-san-juan.png" class="logo-policia">
          <img alt="Logo Gobierno" src="./assets/seguridad.png" class="logo-gobierno">
        </div>
      </div>
    </div>
    <div class="contentPages" v-if="loading">
      <div class="preloader">
        <ProgressSpinner />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import LoginPage from './pages/LoginPage.vue'
  import tanstackFunctions from "@/mixins/tanstackFunctions.js";
  import { getPageName } from "./utils/getPageName";
  import ModalTemplate from './components/ModalTemplate.vue';
  // import OnlineModalMixin from './mixins/onlineModalMixin';
  import CustomButton from '@/components/custom-button/CustomButton.vue';
  import backButton from '@/mixins/backButton';

  export default {
    components: { LoginPage, ModalTemplate, CustomButton },
    name: 'App',
    // mixins: [tanstackFunctions,OnlineModalMixin],
    //↓ mixin de cacheo de efectos
    // mixins: [tanstackFunctions, tanstackEfectos],
    mixins: [tanstackFunctions, backButton],
    provide() {
      return {
        getActuationPageFromApp: this.getActuationPageFromApp,
      };
    },
    data() {
      return {
        online: true,        
        showModal: false,       
        showReloadToast: false,
        updateAccepted: false
      }
    },

    computed: {
      ...mapGetters({
        denunciations: "sumarioList/getList",
      }),
      ...mapState({
        siteRoute: state => state.routes.siteRoute,
        logged: state => state.user.logged,
        userState: state => state.user,
        loading: state => state.user.loading,
        tipoUsuario: (state) => state.user.userdata.tipoUsuario,
        actuacionType: (state) => {
        const type = state.sumario.formType;
        return type ? type.toUpperCase() : null;
      },
      }),
      promptForUpdate(){
      store.commit('setShowReloadToast', true);

      },
      shouldShowToolbar() {
        return this.logged && this.userState.userId != 'User Public' && !this.isPublicRoute;
      },
      shouldShowRouterView() {
        return this.logged && this.userState.userId != 'User Public';
      },
      shouldShowLoginPage() {
        return !this.logged  || this.userState.userId == 'User Public';
      },
      isPublicRoute() {
        const publicRoutes = ['/deposito-public', '/efectos-public/bicicletas', '/efectos-public/electronica', '/efectos-public/busquedacel'];
        return publicRoutes.includes(this.$route.path);
      },
      shouldShowLogo() {
        return !this.isPublicRoute;
      },
      currentTitle () {
      return getPageName(this.$router, this.actuacionType)
      }
    },

    watch: {
      showModal(newValue) {
        if (newValue) {
          this.$refs.infoModal.open();
        } else {
          this.$refs.infoModal.close();
        }
      },
    },

    created() {
      // ↓ Descomentar esta línea para habilitar el cacheo de efectos
      // this.getTotalRowsTanStack()
      // this.getEfectosTanStackByPage(1, 10, true); // Página 1
      // this.prefetchNextPage(2, 10, true);
      // ↓ Descomentar esta línea para habilitar Tanstack
      // this.getActuationsForTanstack();
      document.addEventListener('sw-offline-detected', this.onOfflineDetected);
      this.$store.dispatch("user/updateloading", true);
      if (this.$route.path !== "/login") {
        this.$store.dispatch("user/checkLogin");
      }
    },

    beforeDestroy() {
      document.removeEventListener('sw-offline-detected', this.onOfflineDetected);
    },

    methods: {
      onOfflineDetected() {
        this.showModal = true;
      },

      handleOfflineRedirection(){
        location.reload()
      },
      onReloadClick() {
        this.userAccepted=true
        window.location.reload();
      },
      async getActuationPageFromApp(page) {
        await this.getActuationsForTanstackByPage(page);
      }
    },
  }
</script>


<style>
@import './assets/css/forms.css';
@import './assets/css/globalStyles.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.toast {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1em;
  z-index: 1000;
}
:root {
  /* --success: #689F38;
  --danger: #b32d23; */
  --primary: #3B82F6;
  --secondary: #495057;
  --success: #689F38;
  --info: #3B82F6;
  --warning: #FBC02D;
  --help: #A855F7;
  --danger: #D32F2F;
  --blue-50:#f4fafe;
  --blue-100:#cae6fc;
  --blue-200:#a0d2fa;
  --blue-300:#75bef8;
  --blue-400:#4baaf5;
  --blue-500:#2196f3;
  --blue-600:#1c80cf;
  --blue-700:#1769aa;
  --blue-800:#125386;
  --blue-900:#0d3c61;
  --green-50:#f6fbf6;
  --green-100:#d4ecd5;
  --green-200:#b2ddb4;
  --green-300:#90cd93;
  --green-400:#6ebe71;
  --green-500:#4caf50;
  --green-600:#419544;
  --green-700:#357b38;
  --green-800:#2a602c;
  --green-900:#1e4620;
  --yellow-50:#fffcf5;
  --yellow-100:#fef0cd;
  --yellow-200:#fde4a5;
  --yellow-300:#fdd87d;
  --yellow-400:#fccc55;
  --yellow-500:#fbc02d;
  --yellow-600:#d5a326;
  --yellow-700:#b08620;
  --yellow-800:#8a6a19;
  --yellow-900:#644d12;
  --cyan-50:#f2fcfd;
  --cyan-100:#c2eff5;
  --cyan-200:#91e2ed;
  --cyan-300:#61d5e4;
  --cyan-400:#30c9dc;
  --cyan-500:#00bcd4;
  --cyan-600:#00a0b4;
  --cyan-700:#008494;
  --cyan-800:#006775;
  --cyan-900:#004b55;
  --pink-50:#fef4f7;
  --pink-100:#fac9da;
  --pink-200:#f69ebc;
  --pink-300:#f1749e;
  --pink-400:#ed4981;
  --pink-500:#e91e63;
  --pink-600:#c61a54;
  --pink-700:#a31545;
  --pink-800:#801136;
  --pink-900:#5d0c28;
  --indigo-50:#f5f6fb;
  --indigo-100:#d1d5ed;
  --indigo-200:#acb4df;
  --indigo-300:#8893d1;
  --indigo-400:#6372c3;
  --indigo-500:#3f51b5;
  --indigo-600:#36459a;
  --indigo-700:#2c397f;
  --indigo-800:#232d64;
  --indigo-900:#192048;
  --teal-50:#f2faf9;
  --teal-100:#c2e6e2;
  --teal-200:#91d2cc;
  --teal-300:#61beb5;
  --teal-400:#30aa9f;
  --teal-500:#009688;
  --teal-600:#008074;
  --teal-700:#00695f;
  --teal-800:#00534b;
  --teal-900:#003c36;
  --orange-50:#fff8f2;
  --orange-100:#fde0c2;
  --orange-200:#fbc791;
  --orange-300:#f9ae61;
  --orange-400:#f79530;
  --orange-500:#f57c00;
  --orange-600:#d06900;
  --orange-700:#ac5700;
  --orange-800:#874400;
  --orange-900:#623200;
  --bluegray-50:#f7f9f9;
  --bluegray-100:#d9e0e3;
  --bluegray-200:#bbc7cd;
  --bluegray-300:#9caeb7;
  --bluegray-400:#7e96a1;
  --bluegray-500:#607d8b;
  --bluegray-600:#526a76;
  --bluegray-700:#435861;
  --bluegray-800:#35454c;
  --bluegray-900:#263238;
  --purple-50:#faf4fb;
  --purple-100:#e7cbec;
  --purple-200:#d4a2dd;
  --purple-300:#c279ce;
  --purple-400:#af50bf;
  --purple-500:#9c27b0;
  --purple-600:#852196;
  --purple-700:#6d1b7b;
  --purple-800:#561561;
  --purple-900:#3e1046;
  --red-50:#fff5f5;
  --red-100:#ffd1ce;
  --red-200:#ffada7;
  --red-300:#ff8980;
  --red-400:#ff6459;
  --red-500:#ff4032;
  --red-600:#d9362b;
  --red-700:#b32d23;
  --red-800:#8c231c;
  --red-900:#661a14;
}
.p-button-rounded {
  min-width:38px;
}
.ProseMirror:focus-visible {
  outline: none;
}
.ProseMirror {
  word-wrap: inherit !important;
  white-space: inherit !important;
}
#pageHome {
  background-color: #F8F9FA;
  padding-top: 3.5em;
}
.allPage{
  min-height: calc(100vh - 160px);
}
.w100 {
  width: 100%;
}
.page {
  padding: 10px  30px;
  min-height: calc(100vh - 92px);
}

.feature-intro > h1 {
  font-size: 1.5rem;
  padding-bottom: 0px;
  margin: 0px;
}

.feature-intro > p {
  font-size: 1.2rem;
  padding-top: 0px;
  margin-top: 0px;
}
.formOk {
  padding: 0;
  margin-left: -15px;
}
.formOk > .actions {
  margin-left: 10px;
}

.labeled {
  display: flex;
  flex-direction: column;
  /* align-items:flex-start;
  flex-wrap: wrap; */
  flex-wrap: wrap;
}
.preText {
  color: chocolate;
}

.contentPages  {
  /* display: flex;
  justify-content: center;
  align-items:center; */
  /* background-color: red; */
  min-height: calc(100vh - 150px);
  /* flex-wrap: nowrap; */
  /* height: 100%; */

}

.footer {
  /* position: absolute; */
  background: #FFF;
  align-self: flex-end;
  display: flex;
  padding-top: 10px;
  justify-content: center;
  bottom: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: .5em;
}
.footer > img {
  height: 60px;
  margin-bottom: 5px;
}
.footer .logo-policia {
  margin-right: 3.5em;
}
.footer .logo-gobierno {
  margin-left: 3.5em;
}
.preloader {
  display: flex;
  justify-content: center;
  padding-top: 15rem;
}
.double-ellipsis-icon:before{
  content: url("./assets/drag_indicator.svg") ;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  cursor: -webkit-grab;
  cursor: grab
}
.sangria {
  text-indent: 100px;
}

.success-text {
  color: var(--success);
}

.danger-text {
  color: var(--danger);
}
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #014a95;
  }
  40% {
    stroke: #c01d1e;
  }
  66% {
    stroke: #014a95;
  }
  80%,
  90% {
    stroke: #201e1e;
  }

}
.decoration__links {
  text-decoration: none;
}
.text-primary{
  color: #3B82F6;
}
.text-secondary{
  color: #495057;
}
.text-success{
  color: var(--success);
}
.text-info{
  color: var(--info);
}
.text-warning{
  color: var(--warning);
}
.text-help{
  color: var(--help);
}
.text-danger{
  color: var(--danger);
}
</style>
