export const ufiInformaticosOficioConfig = [
    {
        path: "/actuaciones/new/ufi-informatica-oficio/initial",
        pathName: "ufi-informatica-oficio",
        titulo: "UFI - DELITOS INFORMÁTICOS Y ESTAFAS POR OFICIO",
        icon: "pi-building",
        roles: [
          "User Tester",
          "Super User",
          "Jefe de Dependencia",
          "Oficial Sumariante",
          "Jefe de Sumario",
        ],
        type: "link",
        grupo: "UFI",
        props: { formType: "ufi-estafas-y-delitos-informaticos-oficio", actuacionType: "ufi-informatica-oficio" },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-informatica-oficio/initial/denunciante",
        pathName: "ufi-informatica-oficio",
        titulo: "AFECTADOS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-informatica-oficio/initial/momento",
        pathName: "ufi-informatica-oficio",
        titulo: "FECHA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/ufi-informatica-oficio/initial/acusado",
        pathName: "ufi-informatica-oficio",
        titulo: "VINCULADOS",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/ufi-informatica-oficio/initial/efectos",
        pathName: "ufi-informatica-oficio",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]