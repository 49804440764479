<template>
  <div>
    <div>
      <div class="title mb-2">
        Filtrar resultados
      </div>
      <div v-if="filtersFields" class="principal">
        <div>
          <!-- CAMPO -->
          <Dropdown
            dataKey="name"
            id="dropdown"
            v-model="field"
            :options="filtersFields"
            optionLabel="name"
            placeholder="en"
            class="mr-2 mb-2 field p-inputtext-sm"
            :filter="true"
            ref="TTableFiltersFiledRef"
          />
        </div>
        <div v-if="!field.hideOperators">
          <!-- OPERADOR -->
          <Dropdown
            dataKey="value"
            id="dropdown"
            v-model="operator"
            :options="activeOperators"
            class="mr-2 mb-2 p-inputtext-sm"
            optionLabel="type"
            placeholder="IGUAL A"
            :filter="true"
          />
        </div>
        <div v-if="operator && !operator.last">
          <MultiSelect
            v-if="dropdown[field.field] && field.multiple"
            :filter="true"
            v-model="value"
            :options="dropdown[field.field].options"
            :optionLabel="dropdown[field.field].optionLabel"
            class="mr-2 p-inputtext-sm w-11rem"
            @hide="onDropdownHide('MultiSelectRef')"
            ref="MultiSelectRef"
          />
             <!-- VALOR -->
          <Dropdown
            test-id="DropFiltros"
            v-else-if="dropdown[field.field] && !field.multiple"
            v-model="value"
            :options="getUniqueOptions(deleteNullProps(dropdown[field.field].options, dropdown[field.field].optionLabel), dropdown[field.field].optionValue)"
            @change="() => changeDropdown(field.field)"
            :optionLabel="dropdown[field.field].optionLabel"
            :dataKey="dropdown[field.field].optionValue"
            class="mr-2 p-inputtext-sm"
            filter
            @hide="onDropdownHide('DropFiltros')"
            ref="DropFiltros"
          >
          </Dropdown>
          <Calendar
            v-else-if="field.type == 'date' || field.type == 'dateOnlyRange'"
            class="mr-2 mb-2 p-inputtext-sm"
            inputClass="p-inputtext-sm"
            className="p-inputtext-sm"
            v-model="value"
            dateFormat="dd/mm/yy"
            placeholder="Fecha..."
            onkeydown="return false"
            test-id="calendar"
            showIcon
          />

          <InputText v-else-if="!field?.hideValue" :placeholder="field.name + '...'" v-model="value"
            class="mr-2 p-inputtext-sm" :test-id="value"/>

          <!-- Datos Espesificos para filtros que refieren a datos de otras Tablas -->
          <InputText v-if="value?.type == 'inputEspecific'" :placeholder="value.name + '...'" v-model="value2"
          class="mr-2 p-inputtext-sm" :test-id="value.name"/>

          <!-- El siguiente Dropdon buscara sus options en la Prop efectos con la key que se arma del nombre de la tabla y el nombre del campo con el que se va a realizar el filtro -->
          <Dropdown
            test-id="DropFiltros"
            v-if="value?.type == 'dropdownEspecific'"
            v-model="value2"
            :options="deleteNullProps(dropdown[value?.value]?.options, dropdown[value?.value]?.optionLabel)"
            @change="() => changeDropdown(value?.value)"
            :optionLabel="dropdown[value?.value]?.optionLabel"
            :dataKey="dropdown[value?.value]?.optionValue"
            class="mr-2 p-inputtext-sm"
            filter>
          </Dropdown>


        </div>
        <div>
          <!-- botonos izquierda -->
            <Button
              v-if="enableFilterMultiple"
              icon="pi pi-filter"
              label="Filtrar"
              class="mr-3 p-button-secondary p-button-sm"
              @click="handleFilterAction()"
              id="boton-filtro"
              test-id="boton-agregar-filtro"
              ref="filterButton"
            />
            <Button
              v-else
              icon="pi pi-filter"
              label="Filtrar"
              @click="runFilters()"
              class="p-button-secondary p-button-sm"
              :class="alternativeStylesCleanButton ? 'mr-1' : 'mr-3'"
              id="boton-buscar"
              test-id="buttonBuscar"
            />
            <Button
              v-if="filters.length > 0"
              :label="alternativeStylesCleanButton ? 'Limpiar' : 'Limpiar Filtro'"
              icon=" pi pi-refresh"
              class="mr-2 p-button-sm p-button"
              :class="alternativeStylesCleanButton ? 'p-button-outlined p-button-secondary' : ''"
              @click="cleanFilter"
              test-id="cleanFilter"
              style="background-color: #00000000; border: 1px solid #607D8B; color: #607D8B"
            />
        </div>

      </div>
      <div class="itemSelect mt-2 flex" v-if="enableFilterMultiple">
        <div
          v-for="(item, i) in filters"
          :key="i"
          id="filterSelec"
          class="mr-2 mb-2"
        >
          <div class="filter-item flex" v-if="item?.operator?.type.toLowerCase() !== undefined" ref="filterChip">
            <div class="px-2">{{ item.field.name }} {{item.operator.type.toLowerCase()}} {{getValueName(i)}}</div>
            <Button @click="removeFilter(i)" icon="pi pi-times" class="p-button-sm p-button-danger p-button-text" />
          </div>
        </div>
        <!-- <div v-if="filters.length > 0">
          <Button
            icon="pi pi-search"
            label="Filtrar"
            class="mr-2 mb-2 p-button-sm"
            id="boton-buscar"
            @click="$emit('search')"
            test-id="buttonBuscar"
          />
          <!-- <Button
            label="Limpiar filtros"
            class="mr-2 mb-2 p-button-secondary p-button-text p-button-sm"
            @click="cleanFilter"
            test-id="cleanFilter"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import dateMixin from "@/mixins/dateMixin.js"
import MultiSelect from 'primevue/multiselect';
export default {
  mixins: [
    dateMixin
  ],
  components: {
    MultiSelect
  },
  data: () => ({
    field: null,
    operator: null,
    value: null,
    value2:null,
    activeOperators: [],
    operators: {
      default: [
        { value: "=", type: 'IGUAL A',  },
        { value: 'CONTAINS', type: 'CONTIENE' },
        { value: '>', type: 'MAYOR QUE' },
        { value: '>=', type: 'MAYOR O IGUAL QUE' },
        { value: '<', type: 'MENOR QUE' },
        { value: '<=', type: 'MENOR O IGUAL QUE' },
      ],
      multiple: [
        { value: "IN", type: 'INCLUIR' },
      ],
      multipleDays: [
        { value: "LAST_DAYS", type: 'INCLUIR' },
      ],
      total:[
        { value: "IS NOT NULL", type: 'Todas', last: true},
      ],
      date: [
        { value: 'TODAY', type: "Hoy", last: true },
        { value: 'YESTERDAY', type: 'Ayer', last: true },
        { value: '>=', type: 'Desde' },
        { value: '<=', type: 'Hasta' },
        { value: 'THIS_WEEK', type:  'Esta Semana', last: true },
        { value: 'THIS_MONTH', type: 'Este Mes', last: true },
        { value: 'THIS_YEAR', type: 'Este Año', last: true },
      ],
      dateOnlyRange: [
        { value: '>=', type: 'Desde' },
        { value: '<=', type: 'Hasta' },
      ]
    },
    filters: [],
  }),
  props: {
    filtersFields: {
      type: Array,
    },
    dropdown: {
      type: Object,
      default: () => ({}),
    },
    apiversion: {
      type: String,
    },
    enableFilterMultiple: {
      type: Boolean,
      default: false,
    },
    alternativeStylesCleanButton: {
      type: Boolean,
      default: false,
    },
  },
  created(){
    this.field = this.filtersFields[0]
  },
  watch: {
    field(v){
      this.activeOperators = this.$utils.cloneObj(this.getOperators(v));
      this.value = null
      this.value2 = null;
    },
    filters(newValue){
      const hasDateOperators = newValue.some(item => item.operator?.type === "Desde" || item.operator?.type === "Hasta");
      if(hasDateOperators) {
        if(!this.validateDates(newValue)) {
          this.filters = [];
          this.$toast.open({ 
            message: "La fecha de inicio no puede ser mayor que la fecha de fin.",
            position: "top-right",
            type: "error",
            duration: 3000,
          });
        }
      }
    },
  },
  methods: {
    validateDates(dateArray) {
      const dates = {};
      dateArray.forEach(item => {
        const date = this.parseDate(item.valueView);
        if (!date) return; // Ignore invalid dates
        if (!dates[item.operator.type]) {
          dates[item.operator.type] = [];
        }
        dates[item.operator.type].push(date);
      });
      // Get the max "From" date and the min "To" date
      const minToDate = dates["Hasta"] ? Math.min(...dates["Hasta"]) : null;
      const maxFromDate = dates["Desde"] ? Math.max(...dates["Desde"]) : null;
      // Check for contradiction
      if (maxFromDate !== null && minToDate !== null && maxFromDate > minToDate) {
        return false; // Contradiction detected
      }
      return true; // No contradictions
    },
    parseDate(dateStr) {
      if(dateStr === undefined) return;
      const [day, month, year] = dateStr.split('/').map(Number);
      return new Date(year, month - 1, day).getTime();
    },
    getOperators(field){
      let list = this.operators["default"]
      if (field.type && this.operators[field.type]) {
        list = this.operators[field.type]
      }

      this.operator = list[0]
      return list;
    },
    getUniqueOptions(options, propertyName) {
      const uniqueOptions = [];
      const seenValues = new Set();

      if (!options || !Array.isArray(options)) {
        console.warn("getUniqueOptions: Invalid options input. Returning an empty array.");
        return [];
      }
      for (const option of options) {
        if (option && typeof option === 'object' && option.hasOwnProperty(propertyName)) {
          const value = option[propertyName];
          if (!seenValues.has(value)) {
            uniqueOptions.push(option);
            seenValues.add(value);
          }
        }
      }
      return uniqueOptions;
    },
    changeDropdown(field) {
      if (!this.dropdown[field] || !this.dropdown[field].changeDropdown) return;
      //si no existe un valor
      if (typeof this.value == "undefined") return;
      this.dropdown[field].changeDropdown(this.$utils.cloneObj(this.value), this.$utils.cloneObj(this.filters), this);
    },
    limpiaForm(){
      this.value = null;
      this.value2 = null;
    },
    async handleFilterAction() {
      if (this.enableFilterMultiple) {
        await this.addFilter();
      }
      this.$emit("search");
    },
    async addFilter() {
      //si esta vacio termina
      if ((!this.value || this.value == "") && (!this.operator || !this.operator.last)) return
      // si ya esa al limite termina
      if(this.hasLimit()) return
      if(!this.checkFiltersCompatibility()) return;
      // para fechas agregas ejecuta addDateFilter
      if (this.field.type == 'date' || this.field.type == 'dateOnlyRange')
      {
        await this.addDateFilter();

        // limpia filtro
        if (this.enableFilterMultiple) this.limpiaForm()
        return;
      }
      // Crear el filtro
      const newFilter = this.value2 == null
          ? { field: this.$utils.cloneObj(this.field), operator: this.$utils.cloneObj(this.operator), value: this.$utils.cloneObj(this.value) }
          : { field: { field: this.value.value, name: this.value.name }, operator: this.$utils.cloneObj(this.operator), value: this.$utils.cloneObj(this.value2) };

      // Verificar duplicados
      const exists = this.filters.some(filter =>
          filter.field.name === newFilter.field.name &&
          filter.operator.type === newFilter.operator.type &&
          filter.value === newFilter.value
      );

      if (!exists) {
          this.filters.push(newFilter);
      } else {
          console.error("El filtro no se agregó porque ya existe");
      }
      if (this.enableFilterMultiple) this.limpiaForm();
      // await this.runFilters()
    },
    addDateFilter(){
      let filter = {field: this.$utils.cloneObj(this.field), operator: this.$utils.cloneObj(this.operator)}
      switch (filter.operator.value){
        case ">=":
        filter.value = this.esCalendar2Sql(this.value)
        filter.valueView = this.value.toLocaleDateString('es-AR')
        break;
        case "<=":          //En el caso que se trate de una Fecha "Hasta", usamos un formato de fecha que retorne con el minuto 23:59 en lugar de 00:00
        filter.value = this.esCalendar2SqlFechaHasta(this.value)
        filter.valueView = this.value.toLocaleDateString('es-AR')
        break;
      }
      this.filters.push(filter)

      return Promise.resolve()
    },
    hasLimit(){
      if(this.field.limit){
        let count = 0;
        this.filters.map(e=> {
          if(e.field.name == this.field.name){
            count++
          }
        })
        if(count>=this.field.limit) {
          this.$toast.open({message: "No se puede agregar mas de una opcion de ese filtro", position: 'top-right', type: 'warning', duration: 5000});
          return true
        }
      }
    },
    removeFilter(item) {
      let filtersCopy= {...this.filters}
      this.filters.splice(item, 1);
      this.$emit('search')
    },
    async runFilters() {
      // Asegurarnos de que `this.filters` tenga como máximo un elemento
      if (this.filters.length > 1) {
        this.filters = [this.filters[this.filters.length - 1]];
      }
      // Emitimos el evento, asegurándonos que siempre llega con un solo filtro
      await this.$emit('search',false,true);
      //y luego borramos los valores cargados para que puedan se puedan ingresar nuevos valores
      // this.resetFilters()
    },
    // La siguiente funcion ejecuta los filtros luego de limpiarlos
    async cleanFilter(){
      this.resetFilters();
      await this.$emit('search');
    },
    //La siguiente funcion borra todos los valores de los filtros cargados
    resetFilters() {
      this.filters = [];
      this.value = null;
      this.value2 = null;
    },
    getValueName(i){
      let filter = this.$utils.cloneObj(this.filters[i]);
      let v = filter.value;
      if (filter.valueView) return filter.valueView

      if (!v) return ""
      if (typeof v == "string") return v
      let field = filter.field
      let fieldName = field.field
      if (field.multiple) {
        let optionLabel =  this.dropdown[filter.field.field].optionLabel;
        let arrValue = [];
        for (let valIndex in v){
          arrValue.push(v[valIndex][optionLabel])
        }
        return arrValue.join(", ")
      }


      return v[this.dropdown[fieldName]?.optionLabel]
    },
    getInjectString(filters){
      let filtersWithStringsToInject = [];
      for (let filterIndex in filters){
        for (let nestededFilterIndex in filters[filterIndex].value){
          if(filters[filterIndex].value[nestededFilterIndex]?.injectString !== undefined){
            filtersWithStringsToInject.push(filters[filterIndex].value[nestededFilterIndex]);
          }
        }
      }
      if(filtersWithStringsToInject.length > 0){
        return filtersWithStringsToInject;
      } else{
        return false;
      }
    },
    stringifySearch(searchToStringfy, arrInjectStrings){
      //si es objeto vacío, no retorna nada
      if(!Object.keys(searchToStringfy).length > 0) return;
      //si no tiene inject strings, retorna el search por defecto stringfiado
      if(arrInjectStrings === false) return JSON.stringify(searchToStringfy);

      let searchStringfided = JSON.stringify(searchToStringfy);
      //le quitamos  la llave de cierre, para agregarle más datos a la búsqueda
      searchStringfided = searchStringfided.slice(0, -1);

      for (let index = 0; index < arrInjectStrings.length; index++) {
        searchStringfided =  this.buildSearchStringfided(arrInjectStrings[index], searchStringfided);
        if(index + 1 === arrInjectStrings.length){
          searchStringfided = searchStringfided + "}";
        }
      }
      return searchStringfided;
    },
    buildSearchStringfided(filter, defaultSearch){
      let onlyInjectString = "{" + filter.injectString;
      //el "logicOperator":"OR" se agrega en caso de que el filtro que contiene el 'injectString' se usa conbinado con otro filtro dentro de sus mismo DropDown
      if(this.determineIfNeedsLogicalOperator(filter, defaultSearch) !== false) return this.determineIfNeedsLogicalOperator(filter, defaultSearch);
      //si el filtro que contiene el 'injectString' es el único elegido de su DropDown, pero está siendo usado junto con otro filtro sacado de otro lugar, entra por aquí sin el "logicOperator":"OR"
      if(this.filters.length  > 1)  {
        let finalSearch = filter.injectString.slice(0, -2) + "}], " + defaultSearch.substring(1);
        finalSearch =  this.removeEmptyFilters(finalSearch);
        return finalSearch;
      }
      //por descarte, si el filtro que contiene el 'injectString' está completamente solo, se usará solamente el "onlyInjectString" como search
      return onlyInjectString;
    },
    determineIfNeedsLogicalOperator(filter, defaultSearch){
      for (let index in this.filters) {
        if(!Array.isArray(this.filters[index].value)) continue;
        if (
          this.filters[index].value.some(subElement => 'injectString' in subElement) &&
          (this.filters[index].value.length > 1 && filter?.injectString !== undefined)
        ) {
          let filterWithLogicOperator = defaultSearch + "," + filter.injectString.slice(0, -2) + ',"logicOperator":"OR"}]';
          return filterWithLogicOperator;
        }
      }
      return false;
    },
    getValue(i){
      let filter = this.$utils.cloneObj(this.filters[i]);
      let v = filter.value

      if (!v) return ""
      if (typeof v == "string") return v
      let field = this.$utils.cloneObj(filter.field)
      let fieldName = field.field
      if (field.multiple) {
        let optionValue =  this.dropdown[filter.field.field].optionValue;
        let arrValue = [];
        for (let valIndex in v){
          arrValue.push(v[valIndex][optionValue])
        }
        return arrValue
      }

      return v[this.dropdown[fieldName].optionValue]
    },
    getActiveFilter(){
      return new Promise((resolve, reject) => {
        if (!this.enableFilterMultiple){
          this.addFilter()
        }
        resolve()
      })
    },
    getFiltersV1() {
      return new Promise(async (resolve, reject) => {
        this.filters = []
        await this.getActiveFilter()
        let filters = {}
        let operators = {
          "=": "=",
          "<": "min",
          ">": "max",
          "CONTAINS": "like"
        }

        for( let i in this.filters){
          let field = this.filters[i].field.field
          let operator = this.filters[i].operator.value
          field = operator == "=" ? field : `${field}@_@${operators[operator]}`
          filters[field] = this.getValue(i);
        }
        resolve(filters);
      })
    },
    getFiltersV2() {
      return new Promise(async (resolve, reject) => {
        await this.getActiveFilter()
        let filters = {}
        for( let i in this.filters){
          let field = this.filters[i].field.field
          let operator = this.filters[i]?.operator?.value
          let value = this.getValue(i);

          if (!filters[field]) filters[field] = []

          let options = {operator, value}

          if(this.filters[i].field.addFilter) options.addFilter = this.filters[i].field.addFilter

          filters[field].push(options)
        }
        resolve({
          search: this.stringifySearch(filters, this.getInjectString(this.filters))
        });
      })
    } ,
    deleteNullProps(array, optionLabel){
      let arrayWhitoutPropsNull = [];
      for (let i = 0; i < array.length; i++) {
        let obj = {...array[i]};
        if(obj[optionLabel] !== null){
          arrayWhitoutPropsNull.push(obj);
        }
      }
      return arrayWhitoutPropsNull;
    },
    checkFiltersCompatibility() {
      let singleFilterInPreviusFilters = false;
      for (const filterIndex in this.filters) {
        for (const subFilterIndex in this.filters[filterIndex].value) {
          if(this.filters[filterIndex].value[subFilterIndex]?.singleFilter) singleFilterInPreviusFilters = true;
        }
        if(singleFilterInPreviusFilters == true && this.filters[filterIndex].value.length <= 1) singleFilterInPreviusFilters = false;
      }
      let singleFilterInCurrentFilter = false;
      for (const valueIndex in this.value) {
        if(this.value[valueIndex]?.singleFilter) singleFilterInCurrentFilter = true;
      }
      if(singleFilterInCurrentFilter == true && this.value.length <= 1) singleFilterInCurrentFilter = false;
      if(this.filters.length > 0){
        if(singleFilterInPreviusFilters || singleFilterInCurrentFilter){
          this.$rest.toast_open({ message: "Filtros incompatibles", position: 'top-right', type: 'warning', duration: 10000});
          return false;
        }
      }
      return true;
    },
    removeEmptyFilters(filtersToClean){
      let filtersCleaned = [];
      filtersToClean.split("],").map( filter => {
        if(!filter.includes('"operator":"IN","value":["NULL"]')) filtersCleaned.push(filter);
      });
      filtersCleaned = filtersCleaned.join("], ");
      //si no tiene el corchete de cierre por habérselo quitado con el split, se lo agregamos nuevamente
      if (filtersCleaned.charAt(filtersCleaned.length - 1) !== "]") {
        filtersCleaned += "]";
      }
      return  "{" + filtersCleaned;
    },
    onDropdownHide(ref){
      this.$refs[ref].filterValue = '';
    }
  },
};
</script>
<style lang="scss" scoped>

.principal {
  display: flex;
  flex-wrap: wrap;
  max-width: 940px;
  .field {
    min-width: 200px;
  }

}
.title {
  font-weight: 600;
  font-size: .8em;
}
.itemSelect {
  color:  var(--indigo-200);
  max-width: 70%;
  .filter-item {
    align-items: center;
    border: 1px solid var(--indigo-200);
    line-height: 1.5em;
    max-width: 100%;
    div {
      font-weight: 600;
      font-size: .8em;
    }
  }
}
.transparent-button {
  background-color: transparent;
  color: #607D8B;
  border: 1px solid #607D8B;
}

.transparent-button:hover {
  background-color: rgba(96, 125, 139, 0.1); 
  border: 1px solid #607D8B;
}
</style>
