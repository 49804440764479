export default [
  {
    name: "GENERICOS POR DENUNCIA",
    title: "Genéricos por Denuncia",
    url: "/actuaciones/new/ufi-generica-denuncia/initial"
  },
  {
    name: "GENERICOS POR OFICIO",
    title: "Genéricos por Oficio",
    url: "/actuaciones/new/ufi-generica-oficio/initial"
  },
  {
    name: "ESTABLECER PARADERO",
    title: "UFI - Actuaciones por personas extraviadas",
    url: "/actuaciones/new/ufi-establecer-paradero/initial",
  }
];
