import { actuacionesConfig } from "@/router/RoutesConfig/actuacionesConfig/actuacionesConfig";
import { depositoConfig } from "@/router/RoutesConfig/depositoConfig"
import { parametrosConfig } from "@/router/RoutesConfig/parametrosConfig"

export const getPageName = (router , actuacionType) =>{ 

        const currentRouteName = router.app.$route.name;
        const currentRoutePath = router.app.$route.path;

        // Verificar las condiciones existentes y devolver valores específicos
        if (currentRouteName === null || currentRouteName === "listadoActuaciones") {
          return "ACTUACIONES";
        }
        const currentPathName = router.app.$route.params.pathName || "";

        if (currentRouteName === "view-id") {
          return actuacionType;
        }
        if (currentRouteName === "actuacion-final") {
          return "DATOS LEGALES";
        }
        if (currentRoutePath === "/parametros/list") {
          return "PARAMETRIZACIÓN";
        }
        if (currentRoutePath === "/location") {
          return "LOCALIZACIÓN";
        }
        if (currentRoutePath === "/") {
          return "ESCRITORIO";
        }
        if (currentRouteName === "actuacion-save" || currentRouteName === "actuacion-addDiligencia") {
          return "EDITOR DE DILIGENCIAS";
        }
        if (currentRoutePath === "/actuaciones/newForm") {
          return "ACTUACIONES";
        }
        if (currentRouteName === "actuacion-notificaciones") {
          return "NOTIFICACIONES";
        }

        if (currentRoutePath.startsWith("/parametros") && currentRoutePath.includes("/edit/")) {
          // Si es una ruta de parámetros con "/edit/", determinar el tipo de parámetro
          const segments = currentRoutePath.split("/");
          const editIndex = segments.indexOf("edit");
          if (editIndex !== -1 && editIndex < segments.length - 1) {
            const parametroType = segments[editIndex - 1];
            // Devolver el tipo de parámetro en mayúsculas y sin " - EDITAR"
            return parametroType.toUpperCase();
          }
        }
        
        // Buscar en actuacionesConfig
        const routeDataActuaciones = actuacionesConfig.find((route) => {
          return route.nameOfRoute === currentRouteName && route.pathName === currentPathName;
        });
        // Buscar en depositoConfig
        const routeDataDeposito = depositoConfig && depositoConfig.find((route) => {
          return route && route.nameOfRoute === currentRouteName;
        });
        // Buscar en parametrosConfig
        const parametrosConfigs = parametrosConfig;
const routeDataParametrizacion = parametrosConfigs && parametrosConfigs.find((config) => {
  const configPath = config && config.path && config.path.toLowerCase();
  return currentRoutePath && configPath && currentRoutePath.includes(configPath);
});
 
        // Determinar el título a partir de las búsquedas
        if (routeDataActuaciones) {
          return routeDataActuaciones.titulo;
        } else if (routeDataDeposito) {
          return routeDataDeposito.titulo;
        } else if (routeDataParametrizacion) {
          return routeDataParametrizacion.title.toUpperCase();
        } else {
          return "ESCRITORIO";
        }
}