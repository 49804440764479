export const ufiOtrosConfig = [
    {
        path: "/actuaciones/new/ufi-otros/initial",
        pathName: "ufi-otros",
        titulo: "UFI - OTROS",
        props: { formType: "ufi-otros", actuacionType: "ufi-otros"},
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-otros/initial/denunciante",
        pathName: "ufi-otros",
        titulo: "AFECTADOS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-otros/initial/momento",
        pathName: "ufi-otros",
        titulo: "FECHA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/ufi-otros/initial/acusado",
        pathName: "ufi-otros",
        titulo: "VINCULADOS",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/ufi-otros/initial/efectos",
        pathName: "ufi-otros",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]