<template>
    <div>
        <Button 
            type="button" 
            icon="pi pi-ellipsis-v" 
            @click="toggle($event)" 
            aria-haspopup="true"
            aria-controls="overlay_menu" 
            class="p-button-text p-button-rounded p-button-secondary" 
        />
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
        deleteItem: Function,
        editItem: Function
    },
    data() {
        return {
            menu: null,
            items: [
                {
                    label: "Opciones",
                    items: [
                        {
                            label: "Editar",
                            icon: "pi pi-pencil",
                            command: () => {
                                if (this.editItem) {
                                    this.editItem(this.item);
                                }
                                this.menu.toggle(event);
                            },
                        },
                        {
                            label: "Eliminar",
                            icon: "pi pi-trash",
                            command: (event) => {
                                if (this.item && this.item.arrId) {
                                    this.deleteItem(event.originalEvent, this.item.arrId); // Asegura que se envía el ID correcto
                                } else {
                                    this.deleteItem(event.originalEvent); 
                                }
                                this.menu.toggle(event);
                            },
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        this.menu = this.$refs.menu;
    },
    methods: {
        toggle(event) {
            if (this.menu) {
                this.menu.toggle(event);
            }
        },
    },
};
</script>