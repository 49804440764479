<template>
  <div class="main grid col-12">
      <div class="col" v-if="!viewData.mixedFormDatosLegalesDenunciaFormDatos">
        <div class="p-fluid">
          <!-- Número de Expediente -->
          <div class="field flex-iline" >
            <label for="causaNumero">{{viewData.legalData.causaNumero.title}}</label>
            <div class="flex">
              <InputNumber
                :useGrouping="false"
                :min="0"
                class="titleSelect"
                :placeholder="viewData.legalData.causaNumero.placeholder"
                v-model="causaNumero"
                test-id="inputNumeroExp"
              />
              <div class="flex ml-1 titleSelect" style="">
                <p class="mr-1">/</p>
                <Dropdown v-model="NumActPart2" :options="anios" optionLabel="anio" optionValue="code" :placeholder="anios[0].anio.toString()" />
              </div>
            </div>
            <label class="mt-2" :class="{visible: error}"> {{error}}</label>
          </div>
          <div class="field">
            <div class="titleSelect">
              <label for="TipoSitioID">SITIO</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('site')" :style="[UpdateSite ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateMo ? {'display': 'none'}: '']"></i>
            </div>
            <Dropdown dataKey='TipoSitioID'
              v-model="dataSitio"
              placeholder="SIN SITIO"
              :options="sitio"
              optionLabel="Nombre"
              test-id="dropdownModusOperandi" :filter="true"
            />
          </div>
          <div class="field" v-if="viewData.legalData.showModusOperandi">
            <div class="titleSelect">
              <label for="modusoperandi">Modus operandi</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('modus operandi')" :style="[UpdateMo ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateMo ? {'display': 'none'}: '']"></i>
            </div>
            <Dropdown dataKey='Nombre'
                v-model="dataModusOperandi"
                placeholder="Modus operandi"
                :options="modusoperandiList"
                optionLabel="Nombre"
                test-id="dropdownModusOperandi" :filter="true"
            />
          </div>
          <!-- Artículos -->
          <div v-if="viewData.legalData.showRelatedArticles">
              <div class="titleSelect">
                <label for="ListaDeArticulos">Artículos relacionados</label>
                <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('articulo')" :style="[UpdateArticulos ? {'display': 'none'}: '']"/>
                <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateArticulos ? {'display': 'none'}: '']"></i>
              </div>
            <div class="field" test-id="buttonArt">
              <Dropdown dataKey='Numeroarticulo'
                v-model="dataArticulos"
                placeholder="Artículos del código de faltas"
                :options="articulosList"
                optionLabel="Numeroarticulo"
                test-id="dropdownArticulos" :filter="true"
              />
            </div>
          </div>
          <div>
            <div class="field containerArticulos" v-if="listaArticulos.length > 0">
              <div class="listaArticulos" v-for="item in listaArticulos" :key="item.ArticulosID">
                <div class="articulo__nombre">
                  <p>Art {{ item.Numeroarticulo }} - {{ item.Codigo }}</p>
                </div>
                <div class="btn">
                  <Button icon="pi pi-trash" class="md:col-4 p-button-text"  @click="deleteArticulo(item.ArticulosID)" style="color:white; width:30px; height:30px;background-color:#dc3545; margin:3px"/>
                </div>
              </div>
            </div>
          </div>

          <div class="field"  v-if="viewData.legalData.showCrime">
            <div class="titleSelect">
              <label for="causaCaratula">DELITO</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('delitos')" :style="[UpdateCausa ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateCausa ? {'display': 'none'}: '']"></i>
            </div>
            <Dropdown dataKey='Nombre'
                v-model="dataDelito"
                placeholder="DELITO"
                :options="delitosList"
                optionLabel="Nombre"
                :filter="true"
            />
          </div>

          <div class="field" v-if="viewData.legalData.showCausaCaratula">
            <div class="titleSelect">
              <label for="causaCaratula">Causa / Carátula</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('causa-caratula')" :style="[UpdateCausa ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateCausa ? {'display': 'none'}: '']"></i>
            </div>
            <div @keydown.enter="pressedEnterForAddCausaCaratula($event)">
            <Dropdown
              dataKey='TipocausacaratulaID'
              v-model="dataCausaCaratula"
              placeholder="Causa / Carátula"
              :options="causasList"
              optionLabel="Nombre"
              test-id="dropdownCausaCaratula" :filter="true"
              @change="addCausaCaratula($event)"
              class="mb-3"
            />
              <div>
                <div class="field flex flex-column border-round-md border-solid border-400 border-1" v-if="listaCausaCaratula.length > 0">
                  <div class="pb-1 pt-1">
                    <div class="flex flex-row flex-nowrap justify-content-between align-items-center pl-2" v-for="item in listaCausaCaratula" :key="item.TipocausacaratulaID">
                      <div class="text-xs">
                        <p>{{ item.Nombre }}</p>
                      </div>
                      <div class="w-5rem">
                        <Button icon="pi pi-trash" class="md:col-4 p-button-text"  @click="deleteCaratula(item.TipocausacaratulaID)" style="color:white; width:30px; height:30px;background-color:#dc3545; margin:3px"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Juzgado interviniente -->
          <div  v-if="viewData.legalData.showJudgedIntervener">
              <div class="titleSelect">
                <label for="juzgado">Juzgado interviniente</label>
                <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('juzgado')" :style="[UpdateJuzgado ? {'display': 'none'}: '']"/>
                <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateJuzgado ? {'display': 'none'}: '']"></i>
              </div>
            <div class="field" test-id="ButtonInterviniente">
              <Dropdown dataKey='Nombre'
                v-model="dataJuzgado"
                placeholder="Juzgado interviniente"
                :options="juzgadosList"
                optionLabel="Nombre"
                test-id="dropdownJuzgado" :filter="true"
              />
            </div>
          </div>
          <div class="field"  v-if="viewData.legalData.showUFI">
            <div class="titleSelect">
              <label for="causaCaratula">UFI N°</label>
              <!-- <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('delitos')" :style="[UpdateCausa ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateCausa ? {'display': 'none'}: '']"></i> -->
            </div>
            <Dropdown dataKey='Numero'
                v-model="dataActuacionUfi"
                placeholder="UFI N°"
                :options="ufis"
                optionLabel="Numero"
                :filter="true"
            />
          </div>
          <div class="field"  v-if="viewData.legalData.fiscalInCharge">
            <div class="titleSelect">
              <label for="causaCaratula">FISCAL A CARGO</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('fiscal')" :style="[UpdateFiscal ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateFiscal ? {'display': 'none'}: '']"></i>
            </div>
            <Dropdown dataKey='Denominacion'
                v-model="dataProsecutor"
                placeholder="FISCAL A CARGO"
                :options="prosecutors"
                optionLabel="Denominacion"
                :filter="true"
            />
          </div>
          <div class="field"  v-if="viewData.legalData.fiscalAssistant">
            <div class="titleSelect">
              <label for="causaCaratula">AYUDANTE FISCAL</label>
              <Button icon="pi pi-refresh" class="p-button-sm p-button-success p-button-text p-button-rounded ml-2" @click="toUpdateSelect('ayudante')" :style="[UpdateAyudante ? {'display': 'none'}: '']"/>
              <i class="pi pi-spin pi-spinner mt-1 ml-4" style="font-size: 1rem" :style="[!UpdateAyudante ? {'display': 'none'}: '']"></i>
            </div>
            <Dropdown dataKey='Denominacion'
                v-model="dataAssistant"
                placeholder="AYUDANTE FISCAL"
                :options="assistants"
                optionLabel="Denominacion"
                :filter="true"
            />
          </div>
          <div v-if="resumen.errorValidationErr.length > 0 || resumen.errorValidationWarning.length > 0">
            <h4>
              Advertencias:
            </h4>
            <div v-for="(advertencia, i) in resumen.errorValidationErr" :key="i">
            <p class="errores">{{advertencia}}</p>
            </div>
            <div v-for="(advertencia, i) in resumen.errorValidationWarning">
            <p class="warning">{{advertencia}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mr-2" v-if="viewData.mixedFormDatosLegalesDenunciaFormDatos">
        <DenunciaFormDatos />
        <div v-if="resumen.errorValidationErr.length > 0 || resumen.errorValidationWarning.length > 0">
          <h4>
            Advertencias:
          </h4>
          <div  v-for="(advertencia, i) in resumen.errorValidationErr" :key="i">
          <p class="errores">{{advertencia}}</p>
          </div>
          <div v-for="(advertencia, i) in resumen.errorValidationWarning" :key="i">
            <p class="warning">{{advertencia}}</p>
          </div>
        </div>
      </div>
      <!-- <div class="col">
        <FormDatosLegales  :formType="formType" @validacion="validacion()" :passValidation="passValidation"  :viewData="viewData" :actuacionType="actuacionType"/>
      </div> -->
  </div>
</template>

<script>

import FormDatosLegales from '../../components/forms/FormDatosLegales.vue';
import DenunciaFormDatos from './DenunciaFormDatos.vue';
import { mapState, mapGetters } from "vuex";

export default {
  name: "ContravencionalFormDatos",
  components: {FormDatosLegales, DenunciaFormDatos},

  props: {
    actuacionType: String
  },
  data() {
    return {
		anios: [
			{anio: new Date().getFullYear(), code: JSON.stringify(new Date().getFullYear()).slice(-2)},
			{anio: new Date().getFullYear()-1, code: JSON.stringify(new Date().getFullYear()-1).slice(-2)},
		],
      NumActPart2:JSON.stringify(new Date().getFullYear()).slice(-2),
      causaNumero: null,
      articulos: '',
      timer: true,
      fields: {
       'name': 'b',
      },
      UpdateArticulos:false,
      UpdateJuzgado:false ,
      error: null,
      passValidation: false,
      UpdateMo: false,
      UpdateCausa: false,
      UpdateFiscal: false,
      UpdateAyudante: false,
      UpdateSite: false,
      testArray: [
        {Denominacion: 1},
        {Denominacion: 2},
        {Denominacion: 3},
        {Denominacion: 4},
      ],
      dataCausaCaratula: null
    };
  },
  mounted(){
    // console.log('this.resumen.errorValidationErr', this.resumen.errorValidationErr);
    // console.log('this.resumen.errorValidationWarning', this.resumen.errorValidationWarning);
    // console.log('viewData.mixedFormDatosLegalesDenunciaFormDatos', this.viewData.mixedFormDatosLegalesDenunciaFormDatos);
    if(this.resumen.dniDenunciante===undefined){
      this.$router.push('/sumario/denuncia/formulario/contravencional/oficio')
    }
    let errores = this.emitErrores()
    if(errores == true)
    {
      this.passValidation = false
    } else{
      this.passValidation = true
    }
    // console.log('this.ufis', this.ufis)
  }
  ,
   created() {
    if(this.offlineActuation === true){
      // console.log('this.causaNumeroStore', this.causaNumeroStore);
      // console.log('typeof this.causaNumeroStore', typeof this.causaNumeroStore);
      this.causaNumero = Number(this.causaNumeroStore);
    } else{
      this.causaNumero = this.causaNumeroStore ? Number(this.causaNumeroStore.substring(0, this.causaNumeroStore.length - 3)): null
    }
    this.NumActPart2 = this.causaNumeroStore ? this.causaNumeroStore.slice(-2) : JSON.stringify(new Date().getFullYear()).slice(-2)
    if(this.sumario?.anio !== undefined){
      let currentYear = this.anios.find(elemento => elemento.anio === parseInt(this.sumario?.anio));
       this.NumActPart2 = currentYear.code;
    }
  },
  computed: {
    ...mapState({
      modusoperandiList: state => state.siis.modusoperandi,
      causasList: state => state.siis.causaCaratula,
      delitosList: state => state.siis.delitos,
      delito: state => state.sumario.causaCaratula,
      juzgadosList: state => state.siis.juzgados,
      ufis: state => state.siis.ufis,
      prosecutors: state => state.siis.prosecutors,
      assistants: state => state.siis.assistants,
      sitio: state => state.siis.sitio,
      sumario: state => state.sumario,
      articulosList: state => state.siis.articulos,
      listaArticulos: state => state.sumario.listaArticulos,
      isSaving: state => state.sumario.isSaving,
      saveStatus: state => state.sumario.saveStatus,
      causaNumeroStore: state => state.sumario.causaNumero,
      idsumario: state => state.sumario.id,
      dependencia: state => state.user.dependencia,
      formType: (state) => state.sumarioViews.actuacion,
      listaCausaCaratula: state => state.sumario.listaCaratulas || [],
      offlineActuation: state => state.sumario?.offlineActuation,
    }),
    ...mapGetters({
      resumen: "sumario/dataResumen",
      viewData: "sumarioViews/getViewData"
    }),
    dataModusOperandi: {
      get () { return this.sumario.modusOperandi },
      set (value) {
        if (typeof value == 'string') value = {id: 'new', nombre: value}
        this.$store.dispatch('sumario/setValue', { field: 'modusOperandi', value: value } );
      }
    },
    dataSitio: {
      get () { return this.sumario.Sitio },
      set (value) {
        typeof value !== 'object' ? value = null : value = {TipoSitioID: value.TipoSitioID};
        this.$store.dispatch('sumario/setValue', { field: 'Sitio', value: value } );
      }
    },
    dataActuacionUfi: {
      get () {
        let ufiEnTurno = this.getUfiEnTurno();
        if(this.sumario.ActuacionUfi === null && ufiEnTurno !== undefined){
          //ufiEnTurno default value
          this.$store.dispatch('sumario/setValue', { field: 'ActuacionUfi', value: ufiEnTurno } );
          return ufiEnTurno;
        } else  {
          return this.sumario.ActuacionUfi;
        }
      },
      set (value) {
        if (typeof value == 'string') value = {id: 'new', nombre: value}
        this.$store.dispatch('sumario/setValue', { field: 'ActuacionUfi', value: value } );
        this.$store.dispatch("sumario/textResetEditsCounter","instructionDecree")//si se modifica el numero de Ufi, hay que borrar los cambios del text instrucionDecre de FormDatosLegales.vue
      }
    },
     dataProsecutor: {
      get () { return this.sumario.prosecutor },
      set (value) {
        if (typeof value == 'string') value = {id: 'new', nombre: value}
        this.$store.dispatch('sumario/setValue', { field: 'prosecutor', value: value } );
      }
    },
     dataAssistant: {
      get () { return this.sumario.assistant },
      set (value) {
        if (typeof value == 'string') value = {id: 'new', nombre: value}
        this.$store.dispatch('sumario/setValue', { field: 'assistant', value: value } );
      }
    },
    dataDelito: {
      get () { return this.delito },
      set (value) {
        if (typeof value == 'string') value = {id: 'new', Nombre: value}
        this.$store.dispatch('sumario/setValue', { field: 'causaCaratula', value: value } );
      }
    },
    dataArticulos: {
      get () { return this.articulos },
      set (value) {
        if (typeof value == 'string') {
          this.articulos = value
          return
        }
        if (value !== null && value !== undefined && !this.listaArticulos.includes(value)) {
          var lista = [...this.listaArticulos, value]
          this.$store.dispatch('sumario/setValue', { field: 'listaArticulos', value: lista } );
          this.articulos = '';
        }
      }
    },
    dataJuzgado: {
      get () { return this.sumario.causaJuzgado },
      set (value) {

        if (typeof value == 'string') value = {JuzgadoID: 'new', Nombre: value}
        this.$store.dispatch('sumario/setValue', { field: 'causaJuzgado', value: value } );
      }
    },
  },
  watch:{
    causaNumero(v)
    {
      if(v != null)
      {
        this.$store.dispatch('sumario/setValue', { field: 'causaNumero', value:  v+'/'+this.NumActPart2 } );
        if(JSON.stringify(v).length > 15){
          this.error = 'Este campo no puede contener mas de 15 Caracteres'
          this.passValidation = false
        }else{
          this.error = null
          this.passValidation = true
        }
      }else
      {

        this.$store.dispatch('sumario/setValue', { field: 'causaNumero', value:  null } );
      }
    },
    NumActPart2(v)
    {
      if(this.causaNumero)
      {
        this.$store.dispatch('sumario/setValue', { field: 'causaNumero', value:  this.causaNumero+'/'+v } );
      }
    }
  },
  methods: {
    async toUpdateSelect(tipo){
      switch(tipo)
      {
        case "modus operandi":
            this.UpdateMo = true;
            await this.$store.dispatch('siis/setModusoperandi', true);
            this.UpdateMo = false;
        break;
        case "articulo":
            this.UpdateArticulos = true;
            await this.$store.dispatch('siis/setArticulos');
            this.UpdateArticulos = false;
        break;
        case "juzgado":
            this.UpdateJuzgado = true;
            await this.$store.dispatch('siis/setJuzgados');
            this.UpdateJuzgado = false;
        break;
        case "delitos":
            this.UpdateCausa = true;
            await this.$store.dispatch('siis/setDelitos', true);
            this.UpdateCausa = false;
        break;
        case "fiscal":
            this.UpdateFiscal = true;
            await this.$store.dispatch('siis/setProsecutorOrAssistant',{en: 'prosecutors', es: 'fiscal'}, true);
            this.UpdateFiscal = false;
        break;
        case "ayudante":
            this.UpdateAyudante = true;
            await this.$store.dispatch('siis/setProsecutorOrAssistant',{en: 'prosecutors', es: 'ayudante'}, true);
            this.UpdateAyudante = false;
        case "site":
            this.UpdateSite = true;
            await this.$store.dispatch('siis/setSitio');
            this.UpdateSite = false;
        break;
      }
    },
    deleteArticulo(ArticulosID) {
      let arrayDeArticulos = [...this.listaArticulos].filter(item => item.ArticulosID !== ArticulosID)
      this.$store.dispatch('sumario/setValue', { field: 'listaArticulos', value: arrayDeArticulos } );
    },
    filterArticulos (event) {
      this.filteredArticulos = this.articulosList.filter((item) => (item.Numeroarticulo.toLowerCase().search(event.query.toLowerCase()) >= 0)||(item.Codigo.toLowerCase().search(event.query.toLowerCase()) >= 0) );
    },
    filterJuzgado (event) {
      this.filteredJuzgado = (this.juzgadosList.filter((item) => item.Nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 ));
    },
    selecArticulo(){
      let value = this.articulosList.filter((item) => item.Codigo.toLowerCase().search(this.dataArticulos.toLowerCase()) >= 0 );
      this.dataArticulos=value[0];
      this.articulos = '';
    },
    setData(data){
      for (let i of this.fields) {
        this[i] = data[i] || null
      }
    },
    async $_SumarioDenunciaForm_save(){
      this.$store.dispatch('sumario/setDependencia', this.dependencia);
      // if(this.resumen.warning >0)
      if(this.errorValidationWarning.length >0)
      {
        alert('Falta algún dato para generar el Sumario');
      }else{
        this.$store.dispatch('sumario/saveDenuncia');
      }
    },
    $_TemplateForm_update( field, value ){
      this.$emit("updateValue", field, value)
    },
    redirect()
    {
      // console.log("en redirect el id es: ", JSON.stringify(this.idsumario))
      this.$router.push({path: `/sumario/denuncia/detalle/${this.idsumario}`})
    },
    emitErrores(){
      if(this.causaNumero != null)
      {
        this.error = this.causaNumero.length > 50 ? 'Este campo no puede contener mas de 50 Caracteres' : null
      }
      if(this.error != null)
      {
        return true;
      }
      return false;
    },
    validacion(){
      let errores = this.emitErrores()
      if(errores == true)
      {
        alert("Verifica los campos")
        this.passValidation = false
      } else{
        this.passValidation = true
      }
    },
    getUfiEnTurno(){
      return this.ufis.find(nroUfi => nroUfi.Numero === "EN TURNO");
    },
    addCausaCaratula(event) {
      if(event.originalEvent.key === 'ArrowDown' || event.originalEvent.key === 'ArrowUp') return;
      let repetedTipocausacaratulaID = this.listaCausaCaratula.some(causaCaratula => causaCaratula.TipocausacaratulaID === event.value.TipocausacaratulaID);
      if(repetedTipocausacaratulaID) return;
      if (typeof event.value == 'string') event.value = {id: 'new', Nombre: event.value};
      var lista = [...this.listaCausaCaratula, event.value];
      this.$store.dispatch('sumario/setValue', { field: 'listaCaratulas', value: lista } );
    },
    deleteCaratula(TipocausacaratulaID) {
      let arrayDeCaratulas = [...this.listaCausaCaratula].filter(item => item.TipocausacaratulaID !== TipocausacaratulaID);
      this.$store.dispatch('sumario/setValue', { field: 'listaCaratulas', value: arrayDeCaratulas } );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.anio{
  background-color: darkgrey;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.errores {
  color: #dc3545;
  width: 100%;
  margin: 3px 0;
}

.warning {
  color: #FBC02D;
  width: 100%;
  margin: 3px 0;
}

.articulo__nombre {
  width:82%;
}

.btn{
  width: 18%;
}

.containerArticulos {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.listaArticulos {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  line-height: 0.9;
  font-size: smaller;
}

.MWDropdown {
  width: 100%;
  resize: vertical;
}

@media screen and (max-width: 625px) {
  .whole__body {
    flex-direction: column;
  }
  .errores{
    width: 100%;
  }
  .form{
    width: 100%;
  }
}

.main {
  width: 100%;
  height: 100%;
  padding: 0;
}

.feature-intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.feature-intro > h1 {
  width: 100%;
  font-size: 1.5rem;
}

.feature-intro > p {
  font-size: 1.5rem;
  padding-top: 0px;
}

.feature-intro > i {
  align-items: flex-start;
  height: 100%;
}
.titleSelect{
  height:2em;
  display:flex;
  align-items: center;
}
.visible {
  color: #dc3545;
  text-align: center;
  margin-top: 3px;
}
</style>
