export const verifyInternetConnection = async  () => { 
  let connectionToInternet;
  const response = await fetch("https://jsonplaceholder.typicode.com/posts/1", {
    method: "GET",
    cache: "no-store",
  });
  if(response.url === "https://jsonplaceholder.typicode.com/posts/1")  { 
    // Si la URL de la respuesta al fetch es la misma que en la request al que apuntamos, entonces sí tenemos internet
    connectionToInternet = true;
  } else {
    //Pero si la URL de la respuesta al fetch es la página del "offline", entonces no tenemos conexión a internet
    connectionToInternet = false;
  }
  return connectionToInternet;
};
 