const mix = {
  data: () => ({
    id: "",
    values: {},
    docParams: {
      uploadAndSave: false,
      enabledToUpdate: true,
      doc: {},
    },
    saving: false,
    enableForm: true,
    responsesCombined: {}
  }),
  props: {
    url: {
      type: [String, Array], //string que se usará para habilitar la barra de busqueda global
    },
    idFieldName: {
      type: String,
    },
    put: {
      type: Boolean,
      default: true
    },
    post: {
      type: Boolean,
      default: true
    },
    specificUrlForPut: {
      type: String,
    },
    valuesForDefault: {
      type: Object,
      default: () => {}
    },
    showSaveButton: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    async getDoc(){
      if (this.mode == "edit" || this.viewer) {
        this.enableForm = false;
        if (!this.viewerID) {
          const { id } = this.$route.params;
          this.id = id;
        } else {
          this.id = this.viewerID;
        }

        let getOptions = {
          url: this.url + "/" + this.id,
        };

        let resp = await this.$rest.get(getOptions);
        // console.log("resp get", resp)
        
        for (let prop in this.params) {

          if (this.params[prop].type == "date") {
            resp.data.data[0][prop] = new Date(resp.data.data[0][prop]);
          }

          if (this.params[prop].type == "dropdown" && this.params[prop].dropdown.changeDropdown){
            // console.log("this.params[prop]", prop, this.params[prop])
            this.params[prop].dropdown.changeDropdown(resp.data.data[0][prop], resp.data.data[0]);
          }

          if (this.params[prop].formatGetDoc){
            resp.data.data[0][prop] = this.params[prop].formatGetDoc(resp.data.data[0])
          } 
        }

        //preformatData


        // console.log("REST editComponent: ", JSON.stringify(resp.data));
        this.values = resp.data.data[0];


        // this.enableUploadButton = true;
      } else{
        if(this.valuesForDefault !== {}){
          this.values = {...this.valuesForDefault};
        }
      }
      this.enableForm = true;
    },
    async multipleGetDoc(){
      if (this.mode == "edit" || this.viewer) {
        this.enableForm = false;

        for (let index = 0; index < this.url.length; index++) {
          
          let getOptions = {
            url: this.url[index],
          };

          let resp = await this.$rest.get(getOptions);
          
          for (let prop in this.params) {

            if (this.params[prop].type == "date") {
              resp.data.data[0][prop] = new Date(resp.data.data[0][prop]);
            }

            if (this.params[prop].type == "dropdown" && this.params[prop].dropdown.changeDropdown){
              // console.log("this.params[prop]", prop, this.params[prop])
              this.params[prop].dropdown.changeDropdown(resp.data.data[0][prop], resp.data.data[0]);
            }

            if (this.params[prop].formatGetDoc){
              resp.data.data[0][prop] = this.params[prop].formatGetDoc(resp.data.data[0])
            } 
          }
          this.responsesCombined = this.combineResponsesAndRenameRepeatedProps(resp.data.data[0]);
        }
        this.values = this.responsesCombined;
      }
      this.enableForm = true;
    },
    async putDoc(){
      try {
        if (!this.put) return Promise.resolve()
        let { doc } = this.docParams
        let urlForPut = '';
        if(this.specificUrlForPut != '' && this.specificUrlForPut != undefined){
          urlForPut = this.specificUrlForPut;
        } else{
          urlForPut = this.url + "/" + this.id;
        }
        let putOptions = {
          url: urlForPut,
          formData: doc,
          success: this.successMessage,
        };
        let result = await this.$rest.put(putOptions);
        this.$emit('putResult', result)
        return Promise.resolve(this.id)
      }
      catch(e){
        console.error("e", e);
        return Promise.reject(e);
      }
    },

    async postDoc(){
      try {
        if (!this.post) return Promise.resolve()
        let { doc } = this.docParams
        
        let post = {
          url: this.url,
          formData: doc,
          success: this.successMessage,
        };
        let result = await this.$rest.post(post);
        this.$emit('postResult', {...result, doc})
        return Promise.resolve(result.data.data[this.idFieldName])
      }
      catch(e){
        return Promise.reject(e)
      }
    },
    async prepareDoc(){
      try {
        if(this.onlyEdit.length > 0){
          let doc = {};
          this.onlyEdit.map(field => {
            doc[field] = this.values[field];
          });
          this.docParams.doc = doc;
          return Promise.resolve();
        }
        
        var doc = JSON.parse(JSON.stringify(this.values));
        if (doc.UserType=="Usuario Departamental"){
          doc.TipoUsuarioID="tipo-UsuarioDepartamental"

        }
        for (let prop in this.params) {
          var value = doc[prop];
          if (this.params[prop].type == "date" && value != null) {
            //si el dato es una fecha y no esta null
            if (typeof doc[prop] != "string") {
              //si no es un string (osea se cargo como.date) lo convertimos para que la vd pueda entenderlo
              doc[prop] = value
                .toISOString()
                .slice(0, 10)
                .replace("T", " ");
            } //caso contrario
            else {
              if (value) {
                //si el dato ya viene cargado de la BD vendra como un string...solo le sacamos la hora en este caso, ya que eso genera un error
                doc[prop] = value.slice(0, 10);
              } //en el ultimo de los casos que venga vacio, simplemente lo mandamos como null
              else {
                doc[prop] = null;
              }
            }
          }
        }
  
        // para subidas de archivos
        for (let field in this.params){
          if (this.params[field].upload){
            
            if (this.params[field].upload.uploadAndSave === true) this.docParams.uploadAndSave = true
            if (this.params[field].upload.enabledToUpdate === false) this.docParams.enabledToUpdate = false
            delete doc[field]
          }
          if (!this.params[field].validation){
            delete doc[field]
          }
        }
  
        this.docParams.doc = doc
        
        return Promise.resolve();
      }
      catch(e){
        return Promise.reject()
      }
    },
    async save() {
      try {
        this.$emit("saveAttempt");
        this.saving = true;
        this.enableForm = false;
        let valid = await this.validate()
        if (!valid) {
          this.enableForm = true;
          this.saving = false;
          this.$emit("finishPetition");
          return;
        }
        await this.prepareDoc()
        // console.log("1")
        // para formularios que son de archivos 1 archivo por formulario
        let id = await this.saveFileForm();
        if (id) return this.finishSave(id)
        // console.log("2")
        //guardar formulario
        if (this.mode == "edit"){
          await this.putDoc()
          id = this.id
        }
        // console.log("3")
        //guardar formulario
        
        if (!id && this.mode != "edit") {
          id = await this.postDoc()
          this.id = id
        }
        // console.log("4")
        await this.forceUpload()

        // console.log("5")
        this.finishSave(id)

      } catch (e) {
        e.catch((reason) => {
          if (reason.data?.code === "DUPLICATE_ENTRY") {
            this.$emit("errorDuplicatedEntry", reason.data.message);
            this.saving = false;
          } 
          if(reason.data?.error === "Nombre de Usuario Invalido"){
            this.$emit("errorRepeatedUser");
            this.saving = false;
          } 
        });
        this.$emit("finishPetition");
        this.enableForm = true;
      }
    },

    finishSave(id){
      this.$emit("save", this.docParams)
      // console.log(this.toListAfterSave)
      if (this.toListAfterSave)  this.back()
      this.id = id
      this.setDesign("edit")
      this.enableForm = true;
      this.saving = false;
    },
    combineResponsesAndRenameRepeatedProps(responseToCombine){
      let propCounters = {};
      //this prevents a loss of reactivity
      let newResponsesCombined = {...this.responsesCombined};
      for (const prop in responseToCombine) {
        if (!newResponsesCombined.hasOwnProperty(prop)) {
          newResponsesCombined[prop] = responseToCombine[prop];
        } else {
          if (!propCounters.hasOwnProperty(prop)) {
            propCounters[prop] = 2;
          } 
          let newPropName = prop + propCounters[prop]; 
          newResponsesCombined[newPropName] = responseToCombine[prop];
          propCounters[prop]++;
        }
      }
      return newResponsesCombined;
    },
    goToEditDepositoVinculoActuacion(vinculoID) {
      return new Promise((resolve) => {
        this.$router.push('/deposito-vinculo-actuacion/'+ vinculoID).then(resolve).catch((error) => {
          console.error('Error navigating to deposito-vinculo-actuacion/vinculoID: ', error, ' trying window.location.href');
          const fullPath = `${window.location.protocol}//${window.location.host}` + '/deposito-vinculo-actuacion/'+ vinculoID;
          window.location.href = fullPath;
          resolve();
        });
      });
    },
  },
}

module.exports = mix