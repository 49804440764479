export const ufiFlagranciaConfig = [
    {
        path: "/actuaciones/new/ufi-flagrancia/initial",
        pathName: "ufi-flagrancia",
        titulo: "UFI - FLAGRANCIA",
        icon: "pi-bookmark-fill",
        roles: [
          "User Tester",
          "Super User",
          "Jefe de Dependencia",
          "Oficial Sumariante",
          "Jefe de Sumario",
        ],
        type: "link",
        grupo: "UFI",
        props: {
          formType: "ufi-flagrancia",
          actuacionType: "ufi-flagrancia",    
        },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/ufi-flagrancia/initial/denunciante",
        pathName: "ufi-flagrancia",
        titulo: "AFECTADOS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/ufi-flagrancia/initial/momento",
        pathName: "ufi-flagrancia",
        titulo: "FECHA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },
      {
        path: "/actuaciones/new/ufi-flagrancia/initial/acusado",
        pathName: "ufi-flagrancia",
        titulo: "VINCULADOS",
        nameOfRoute : "actuacion-form-acusado"
      },
      {
        path: "/actuaciones/new/ufi-flagrancia/initial/efectos",
        pathName: "ufi-flagrancia",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]