export const preliminarCaidaConfig = [
    {
        path: "/actuaciones/new/preliminares-caida-casual/initial",
        pathName: "preliminares-caida-casual",
        titulo: "CAÍDA CASUAL",
        props: { formType: "preliminar-caida-casual", actuacionType: "preliminares-caida-casual" },
        nameOfRoute : "actuacion-initial"
      },
      {
        path: "/actuaciones/new/preliminares-caida-casual/initial/denunciante",
        pathName: "preliminares-caida-casual",
        titulo: "AFECTADOS",
        nameOfRoute : "actuacion-form-denunciante"
      },
      {
        path: "/actuaciones/new/preliminares-caida-casual/initial/momento",
        pathName: "preliminares-caida-casual",
        titulo: "FECHA, HORA y UBICACIÓN",
        nameOfRoute : "actuacion-form-momento"
      },      
      {
        path: "/actuaciones/new/preliminares-caida-casual/initial/efectos",
        pathName: "preliminares-caida-casual",
        titulo: "EFECTOS",
        nameOfRoute : "actuacion-form-efectos"
      },
]