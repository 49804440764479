<template>
  <div class="content">
    <div :class="{ forms: !minimal, inputType: minimal }">
      <!-- PERSONA FÍSICA -->
      <div class="p-fluid">
        <div class="grid flex" v-if="seeData()">
          <div class="col-12 md:col-6">
            <div
              class="field col-12 md:col-12 sm:col-12 condition"
              v-if="true"
              test-id="buttonTipoDenunciante"
            >
              <Dropdown
                dataKey="nombre"
                v-model="dataCondicion"
                :options="this[viewData.formPersona.condition[this.typeOfPerson]]"
                optionLabel="nombre"
                placeholder="Elija condición"
                class="lg:col-12 md:col-12 sm:col-12"
                test-id="InputTipoDenunciante"
                :filter="true"
              />
            </div>

            <div
              v-else
              :class="{
                'field col-12 md:col-12 sm:col-12':
                  viewData.formPersona.conditionDefault !== '' && !$route.path.includes('acusado'),
              }"
            >
              <p v-if="!$route.path.includes('acusado')">
                <b>{{ viewData.formPersona.conditionDefault }}</b>
              </p>
            </div>
          </div>
          <div
            class="field-checkbox col-12 md:col-6 justify-content-end"
            v-if="!persona"
            test-id="divPersonaJuridica"
          >
            <Checkbox
              id="persona-juridica"
              v-model="dataPersonaJuridica"
              :disabled="isDisabled('personaJuridica')"
              :binary="true"
              test-id="checkPersonaJuridica"
            />
            <label for="persona-juridica">¿Es Persona Jurídica?</label>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="grid w-full wrap">
            <div class="field col-2 md:col-2 sm:col-2" test-id="buttonTipoDocDenunciante">
              <label for="tipoDocumento">Tipo de doc.</label>
              <Dropdown
                dataKey="nombre"
                v-model="dataDocumentoTipo"
                placeholder="Tipo de Doc"
                :options="tipoDocumentoList"
                optionLabel="nombre"
                :filter="true"
              />
            </div>
            <div class="field col-2 md:col-2 sm:col-2">
              <label for="documento">N° de doc.</label>
              <InputText
                class="input"
                min="0"
                type="number"
                v-model="dataDocumento"
                :disabled="isDisabled('dataDocumento')"
                placeholder="Número de documento"
                test-id="inputDniDenunciante"
              />
              <label :class="{ visible: errores.documento }"> {{ errores.documento }}</label>
            </div>
            <div
              class="field col-1 md:col-1 sm:col-1"
              test-id="inputSexDenunciante"
              style="width: 190px"
            >
              <label for="sexo">Sexo</label>
              <Dropdown
                dataKey="nombre"
                v-model="dataSexo"
                placeholder="Sexo"
                :options="sexoList"
                optionLabel="nombre"
                :filter="true"
              />
            </div>
            <div class="field col-3" style="width: 200px">
              <label for="exhibeDocumento">¿Exhibe el documento?</label>
              <Dropdown
                dataKey="id"
                v-model="dataExhibeDocumento"
                placeholder="¿Exhibe el documento?"
                :options="yesOrNoList"
                optionLabel="nombre"
                test-id="buttonExhibeDocumento"
                :filter="true"
              />
            </div>
            <div class="field col-2 md:col-2 sm:col-2">
              <label for="documento">N° de Cuil.</label>
              <InputText
                class="input"
                min="0"
                type="number"
                v-model="dataCUIL"
                disabled
                placeholder="N° de Cuil."
              />
              <label :class="{ visible: errores.documento }"> {{ errores.documento }}</label>
            </div>
            <div class="buttonAndSpinner">
              <!-- {{$offline.onlineMode}}   -->
              <div class="field align-self-end col-12" v-if="!dataSipeSearch">
                <Button
                  type="button"
                  label="Buscar"
                  icon="pi pi-search"
                  v-if="checkDni()"
                  class="p-button-primary ml-2"
                  @click="$_FormPersona_sipeSearch"
                  test-id="buttonBuscarPersona"
                />
              </div>
              <div
                :class="{
                  spinner: timer,
                  'ml-6': true,
                  'md:col-5': true,
                  'mt-auto': true,
                  'mb-5': true,
                }"
              >
                <ProgressSpinner
                  style="width: 70px; height: 70px"
                  strokeWidth="5"
                  fill="#EEEEEE"
                  animationDuration="1.8s"
                />
              </div>
            </div>
            <p class="field p-error col-12 mt-0 pt-0" test-id="sipeLabelError" v-if="error">
              {{ error }}
            </p>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="p-fluid formgrid grid" v-if="seeData()">
            <div class="field col-12 md:col-5 sm:col-5" test-id="inputApellido">
              <label for="apellido">Apellido</label>
              <InputText
                class="input"
                type="text"
                placeholder="Apellido/s"
                v-model="dataApellido"
                :disabled="isDisabled('dataApellido')"
              />
              <label :class="{ visible: errores.apellido }"> {{ errores.apellido }}</label>
            </div>
            <div class="field col-12 md:col-5 sm:col-5" test-id="inputNombre">
              <label for="nombre">Nombre</label>
              <InputText
                class="input"
                type="text"
                placeholder="Nombre"
                v-model="dataNombre"
                :disabled="isDisabled('dataNombre')"
              />
              <label :class="{ visible: errores.nombre }"> {{ errores.nombre }}</label>
            </div>
            <div class="field col-12 md:col-2 sm:col-2" test-id="inputNombre">
              <label for="edad">Edad</label>
              <InputText
                class="input"
                type="text"
                placeholder="Edad"
                v-model="edad"
                :disabled="true"
              />
              <label :class="{ visible: errores.nombre }"> {{ errores.nombre }}</label>
            </div>

            <div class="field col-12 md:col-4 lg:col-4 sm:col-6" test-id="inputFechaNac">
              <label for="fechaNacimiento">Fecha de nac.</label>
              <InputText
                class="input"
                type="text"
                placeholder="Fecha de nacimiento"
                v-model="dataFechaNacimiento"
                :disabled="isDisabled('dataFechaNacimiento')"
              />
              <label :class="{ visible: errores.fechaNacimiento }">
                {{ errores.fechaNacimiento }}</label
              >
            </div>
            <div class="field col-12 md:col-4 lg:col-4 sm:col-6" test-id="buttonNacionalidad">
              <label for="nacionalidad">Nacionalidad</label>
              <Dropdown
                dataKey="nombre"
                v-model="dataNacionalidad"
                placeholder="Nacionalidad"
                :options="nacionalidadList"
                optionLabel="nombre"
                :filter="true"
              />
            </div>
            <div class="field col-12 md:col-4 lg:col-4" test-id="buttonEstadoCivil">
              <label for="estadoCivil">Estado civil</label>
              <Dropdown
                dataKey="nombre"
                class="input"
                v-model="dataEstadoCivil"
                placeholder="Estado civil"
                :options="estadoCivilList"
                optionLabel="nombre"
                :filter="true"
              />
            </div>
            <div class="field col-12" test-id="textareaDomDeclarado">
              <label for="residenciaSIPE">Domicilio</label>
              <Textarea
                class="input"
                type="text"
                placeholder="Domicilio"
                v-model="dataDomicilioSIPE"
              />
            </div>
            <div
              class="field col-12 lg:col-6 md:col-6 sm:col-6"
              v-if="condicion.nombre === 'Denunciante'"
            >
              <label for="vinculo">Vínculo con la víctima</label>
              <InputText
                class="input"
                type="text"
                placeholder="Vínculo con la víctima"
                v-model="dataVinculo"
                test-id="inputVinculo"
              />
              <label :class="{ visible: errores.telefonoJuridico }">
                {{ errores.telefonoJuridico }}</label
              >
            </div>

            <div class="p-fluid formgrid grid">
              <div :class="generateClasses()">
                <label for="telefono">Teléfono</label>
                <InputText
                  class="input"
                  type="number"
                  min="10000000"
                  max="999999999"
                  placeholder="Número de teléfono"
                  v-model="dataTelefono"
                  :disabled="isDisabled('dataTelefono')"
                  test-id="inputTel"
                />
                <label :class="{ visible: errores.telefono }"> {{ errores.telefono }}</label>
              </div>
              <div :class="generateClasses()">
                <label for="profesion">Email</label>
                <InputText
                  class="input"
                  placeholder="Email"
                  v-model="dataEmail"
                  :disabled="isDisabled('dataEmail')"
                  test-id="inputEmail"
                  id="emailInput"
                />
                <label :class="{ visible: errores.email }"> {{ errores.email }}</label>
              </div>
              <div :class="generateClasses()">
                <label for="profesion">Profesión</label>
                <InputText
                  class="input"
                  placeholder="Profesión"
                  v-model="dataProfesion"
                  :disabled="isDisabled('dataProfesion')"
                  test-id="inputProfesion"
                />
                <label :class="{ visible: errores.profesion }"> {{ errores.profesion }}</label>
              </div>
              <div :class="generateClasses()">
                <label for="tieneEstudios">¿Tiene estudios?</label>
                <Dropdown
                  dataKey="id"
                  v-model="dataTieneEstudios"
                  placeholder="¿Tiene estudios?"
                  :options="yesOrNoList"
                  optionLabel="nombre"
                  :filter="true"
                  test-id="buttonInstruccion"
                />
              </div>
              <div :class="generateClasses()">
                <label for="instruccion">Instrucción</label>
                <Dropdown
                  dataKey="nombre"
                  v-model="dataInstruccion"
                  placeholder="Instrucción"
                  :options="instruccionList"
                  optionLabel="nombre"
                  :filter="true"
                  test-id="buttonInstruccion"
                />
              </div>
              <div class="field col-12">
                <label for="residencia">Observaciones</label>
                <Textarea
                  class="input"
                  type="text"
                  placeholder="Observaciones..."
                  v-model="dataObservaciones"
                  test-id="textareaDomResidencia"
                />
                <!-- <label :class="{visible: (errores.domicilio)}"> {{errores.domicilio}}</label> -->
              </div>
            </div>
            <div class="field col-12 md:col-3 sm:col-6" v-if="acusado">
              <label for="nombre">Apodo</label>
              <InputText
                class="input"
                type="text"
                placeholder="apodo"
                v-model="dataApodo"
                test-id="inputApodo"
              />
              <label :class="{ visible: errores.apodo }"> {{ errores.apodo }}</label>
            </div>
          </div>
        </div>

        <div
          class="p-fluid formgrid grid"
          v-if="
            formType === 'ufi-estafas-y-delitos-informaticos' ||
            formType === 'ufi-estafas-y-delitos-informaticos-oficio'
          "
        >
          <div class="field col-12">
            <h3>Datos complementarios</h3>
          </div>
          <ListBoxOptions
            v-if="typeOfPerson === 'denunciante'"
            @FormPersona_update="(data, value) => $_FormPersona_update(data, value)"
            :defaultValue="
              getPersonByArrId(arrId)?.doc?.codigosCoelsa === undefined
                ? []
                : getPersonByArrId(arrId)?.doc?.codigosCoelsa
            "
            formDataName="codigosCoelsa"
            valueName="IDcoelsa"
            tooltip="Agregar IDCOELSA"
            :preventRepeatedItems="true"
            :minLength="5"
            :maxLength="22"
            subPropName="CodigoCoelsa"
          />
          <ListBoxOptions
            @FormPersona_update="(data, value) => $_FormPersona_update(data, value)"
            :defaultValue="
              getPersonByArrId($route.query.id)?.doc?.plataformas === undefined
                ? []
                : getPersonByArrId($route.query.id)?.doc?.plataformas
            "
            formDataName="plataformas"
            valueName="Perfiles de redes sociales"
            tooltip="Agregar Perfil Social"
            :preventRepeatedItems="true"
            :minLength="5"
            :maxLength="255"
            subPropName="Url"
          />
        </div>

        <!-- FIN PERSONA FÍSICA -->
        <!-- PERSONA JURÍDICA -->
        <div class="p-fluid formgrid grid" v-if="personaJuridica">
          <div class="field col-12">
            <h3>En representación de</h3>
          </div>
          <div class="field col-12 md:col-6">
            <label for="CUIT">CUIT</label>
            <InputText
              class="input"
              type="number"
              min="0"
              placeholder="CUIT"
              v-model="dataCUIT"
              :disabled="isDisabled('dataCUIT')"
              test-id="inputCuit"
            />
            <label :class="{ visible: errores.CUIT }"> {{ errores.CUIT }}</label>
          </div>
          <div class="field col-12 md:col-6">
            <label for="denominacion">Razón social</label>
            <InputText
              class="input"
              type="text"
              placeholder="Razón social"
              v-model="dataDenominacion"
              :disabled="isDisabled('dataDenominacion')"
              test-id="inputRsocial"
            />
            <label :class="{ visible: errores.denominacion }"> {{ errores.denominacion }}</label>
          </div>
          <div class="field col-12">
            <label for="domicilioJuridico">Domicilio legal</label>
            <InputText
              class="input"
              type="text"
              v-model="dataDomicilioJuridico"
              :disabled="isDisabled('dataDomicilioJuridico')"
              test-id="inputDomlegal"
            />
            <label :class="{ visible: errores.domicilioJuridico }">
              {{ errores.domicilioJuridico }}</label
            >
          </div>
          <div class="field col-12 lg:col-4 md:col-6 sm:col-6">
            <label for="telefono">Teléfono</label>
            <InputText
              class="input"
              type="text"
              placeholder="Teléfono"
              v-model="dataTelefonoJuridico"
              :disabled="isDisabled('dataTelefonoJuridico')"
              test-id="inputTeljuridico"
            />
            <label :class="{ visible: errores.telefonoJuridico }">
              {{ errores.telefonoJuridico }}</label
            >
          </div>
          <div class="field col-12 lg:col-5 md:col-6 sm:col-6">
            <label for="rubro">Actividad</label>
            <InputText
              class="input"
              type="text"
              placeholder="Actividad"
              v-model="dataRubro"
              :disabled="isDisabled('dataRubro')"
              test-id="inputActividad"
            />
            <label :class="{ visible: errores.rubro }"> {{ errores.rubro }}</label>
          </div>
          <div class="field col-12 lg:col-3 md:col-6 sm:col-6 chkbx" test-id="divTieneSeguro">
            <Checkbox class="mr-2" id="tiene-seguro" v-model="dataTieneSeguro" :binary="true" />
            <label for="tiene-seguro" class="txtchkbx">Tiene seguro</label>
          </div>
        </div>
      </div>
      <!-- FIN...   PERSONA JURÍDICA -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CustomAutoComplete from "../../components/custom-autocomplete/CustomAutoComplete.vue";
import functionsDataResumen from "@/store/modules/sumario/dataResumenModules/functionsDataResumen";
import Listbox from "primevue/listbox";
import ListBoxOptions from "../ListBoxOptions/ListBoxOptions";

export default {
  props: {
    acusado: Boolean,
    minimal: String,
    hidden: { type: Array, default: () => [] },
    arrId: String,
    getPersonByArrId: Function,
    typeOfPerson: String,
    ordenPublico: Boolean,
    formType: String,
    Involucrados: { type: Array, default: () => [] },
  },
  components: {
    CustomAutoComplete,
    Listbox,
    ListBoxOptions,
  },
  name: "FormPersona",
  data() {
    return {
      personaJuridica: false,
      persona: false,
      condicion: "",
      documento: "",
      tipoDocumento: "DNI",
      cuit: "",
      apellido: "",
      nombre: "",
      nacionalidad: "",
      sexo: "",
      fechaNacimiento: "",
      edad: "",
      estadoCivil: null,
      telefono: "",
      telefonoJuridico: "",
      redesSociales: "",
      profesion: "",
      instruccion: "",
      denominacion: "",
      apodo: "",
      rubro: "",
      domicilio: "",
      domicilioJuridico: "",
      disabledFields: [],
      domicilioSIPE: "",
      error: "",
      email: "",
      tieneSeguro: false,
      exhibeDocumento: "",
      tieneEstudios: "",
      observaciones: "",
      cuil: "",
      vinculo: "",
      edad: "",
      fields: [
        "personaJuridica",
        "condicion",
        "documento",
        "tipoDocumento",
        "cuit",
        "apellido",
        "nombre",
        "nacionalidad",
        "sexo",
        "tipoDocumento",
        "fechaNacimiento",
        "edad",
        "estadoCivil",
        "telefono",
        "telefonoJuridico",
        "redesSociales",
        "profesion",
        "instruccion",
        "apodo",
        "denominacion",
        "rubro",
        "domicilio",
        "domicilioJuridico",
        "domicilioSIPE",
        "tieneSeguro",
        "email",
        "exhibeDocumento",
        "tieneEstudios",
        "observaciones",
        "cuil",
        "vinculo",
        "edad",
      ],
      timer: true,
      errores: {
        documento: null,
        apellido: null,
        nombre: null,
        fechaNacimiento: null,
        domicilio: null,
        telefono: null,
        profesion: null,
        apodo: null,
        CUIT: null,
        denominacion: null,
        domicilioJuridico: null,
        telefonoJuridico: null,
        rubro: null,
      },
    };
  },
  mounted() {
    if (this.dataSipeSearch === true) {
      this.disabledFields = [
        "dataNombre",
        "dataApellido",
        "dataFechaNacimiento",
        "dataDomicilioSIPE",
        "dataCUIL",
      ];
    } else {
      this.disabledFields = [];
    }
    this.$store.dispatch(
      "sumario/denunciante/setEmptyTitle",
      this.viewData.formPersona.whistleblower.emptyTitle
    );
    this.$store.dispatch(
      "sumario/acusados/setEmptyTitle",
      this.viewData.formPersona.accused.emptyTitle
    );
  },
  watch: {
    arrId: function () {
      if (this.dataSipeSearch === true) {
        this.disabledFields = [
          "dataNombre",
          "dataApellido",
          "dataFechaNacimiento",
          "dataDomicilioSIPE",
          "dataCUIL",
        ];
      } else {
        this.disabledFields = [];
      }
    },
    dataDocumento(v) {
      if (v != null) {
        this.errores.documento =
          v.length > 50 ? "Este campo no puede contener más de 50 Caracteres" : null;
      }
    },
    dataApellido(v) {
      if (v != null) {
        this.errores.apellido =
          v.length > 50 ? "Este campo no puede contener más de 50 Caracteres" : null;
      }
    },
    dataNombre(v) {
      if (v != null) {
        this.errores.nombre =
          v.length > 50 ? "Este campo no puede contener más de 50 Caracteres" : null;
      }
    },
    dataFechaNacimiento(v) {
      if (v != null) {
        this.errores.fechaNacimiento =
          v.length > 50 ? "Este campo no puede contener más de 50 Caracteres" : null;
      }
    },
    dataDomicilio(v) {
      if (v != null) {
        this.errores.domicilio =
          v.length > 500 ? "Este campo no puede contener más de 500 Caracteres" : null;
      }
    },
    dataTelefono(v) {
      if (v != null) {
        this.errores.telefono =
          v.length > 50 ? "Este campo no puede contener más de 50 Caracteres" : null;
      }
    },
    dataProfesion(v) {
      if (v != null) {
        this.errores.profesion =
          v.length > 45 ? "Este campo no puede contener más de 45 Caracteres" : null;
      }
    },
    dataApodo(v) {
      if (v != null) {
        this.errores.apodo =
          v.length > 50 ? "Este campo no puede contener más de 50 Caracteres" : null;
      }
    },
    dataCUIT(v) {
      if (v != null) {
        this.errores.CUIT =
          v.length > 50 ? "Este campo no puede contener más de 50 Caracteres" : null;
      }
    },
    dataDenominacion(v) {
      if (v != null) {
        this.errores.denominacion =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
    },
    dataDomicilioJuridico(v) {
      if (v != null) {
        this.errores.domicilioJuridico =
          v.length > 500 ? "Este campo no puede contener más de 500 Caracteres" : null;
      }
    },
    dataTelefonoJuridico(v) {
      if (v != null) {
        this.errores.telefonoJuridico =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
    },
    dataRubro(v) {
      if (v != null) {
        this.errores.rubro =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
    },
  },
  computed: {
    ...mapState({
      estadoCivilList: (state) => state.siis.estadoCivil,
      condicionList: (state) => state.siis.condicion,
      condicionCombinada: (state) => state.siis.condicionCombinada,
      condicionPreliminar: (state) => state.siis.condicionPreliminar,
      condicionCaidaCasual: (state) => state.siis.condicionCaidaCasual,
      condicionUFIDenunciante: (state) => state.siis.condicionUFIDenunciante,
      condicionUFIAcusado: (state) => state.siis.condicionUFIAcusado,
      condicionUFIDenuncianteFallecido: (state) => state.siis.condicionUFIDenuncianteFallecido,
      condicionUFIDenuncianteParadero: (state) => state.siis.condicionUFIDenuncianteParadero,
      condicionUFIAcusadoParadero: (state) => state.siis.condicionUFIAcusadoParadero,
      condicionTentativaSuicidio: (state) => state.siis.condicionTentativaSuicidio,
      condicionSumarioAcusado: (state) => state.siis.condicionSumarioAcusado,
      condicionContravencionAcusado: (state) => state.siis.condicionContravencionAcusado,
      condicionAcusadoDetenido: (state) => state.siis.condicionAcusadoDetenido,
      sexoList: (state) => state.siis.sexo,
      yesOrNoList: (state) => state.siis.yesOrNoList,
      tipoDocumentoList: (state) => state.siis.tipoDocumento,
      nacionalidadList: (state) => state.siis.nacionalidad,
      instruccionList: (state) => state.siis.instruccion,
      sumario: (state) => state.sumario,
    }),
    ...mapGetters({
      viewData: "sumarioViews/getViewData",
    }),
    dataPersonaJuridica: {
      get() {
        if(this.verifyJuridicPerson()) this.personaJuridica = true;
        return this.personaJuridica;
      },
      set(value) {
        this.personaJuridica = value;
        this.$_FormPersona_update("personaJuridica", value);
      },
    },
    dataPersona: {
      get() {
        return this.persona;
      },
      set(value) {
        this.persona = value;
        this.$_FormPersona_update("persona", value);
        this.$_FormPersona_update("apellido", "Orden");
        this.$_FormPersona_update("nombre", "Público");
      },
    },
    dataCondicion: {
      get() {
        let rolPersonaSeleccionada = this.Involucrados.find((e) => e.arrId == this.arrId);
        if (!this.condicion) {
          //si la persona seleccionada ya solia tener un rol asignado, recuperamos ese rol
          if (rolPersonaSeleccionada?.condicion?.nombre != undefined) {
            this.condicion = rolPersonaSeleccionada.condicion;
          } else {
            //caso contrario asignamos uno por defecto
            this.condicion = this.viewData?.personCondition?.[this.typeOfPerson];
            //si this.arraId es igual a null, se trata del dato type= empty y por ende no le asignamos condicion
            if(this.arrId != null) this.$_FormPersona_update("condicion", this.condicion) ;
          }
        }
        return this.condicion;
      },

      set(value) {
        // console.log("value: ",value)
        this.dataVinculo = "";
        this.$_FormPersona_update("condicion", value);
      },
    },

    dataDocumento: {
      get() {
        return this.documento;
      },
      set(value) {
        this.$_FormPersona_update("estadoCivil", this.estadoCivil);
        this.$_FormPersona_sipeMode(false);
        this.$_FormPersona_update("documento", value);
      },
    },
    dataDocumentoTipo: {
      get() {
        return this.tipoDocumento;
      },
      set(value) {
        this.$_FormPersona_sipeMode(false);
        this.$_FormPersona_update("tipoDocumento", value);
      },
    },
    dataCUIT: {
      get() {
        return this.cuit;
      },
      set(value) {
        this.$_FormPersona_update("cuit", value);
      },
    },
    dataCUIL: {
      get() {
        return this.cuil;
      },
      set(value) {
        this.$_FormPersona_update("cuil", value);
      },
    },
    dataApellido: {
      get() {
        return this.apellido;
      },
      set(value) {
        this.$_FormPersona_update("apellido", value);
      },
    },
    dataApodo: {
      get() {
        return this.apodo;
      },
      set(value) {
        this.$_FormPersona_update("apodo", value);
      },
    },
    dataNombre: {
      get() {
        return this.nombre;
      },
      set(value) {
        this.$_FormPersona_update("nombre", value);
      },
    },
    dataNacionalidad: {
      get() {
        return this.nacionalidad;
      },
      set(value) {
        this.$_FormPersona_update("nacionalidad", value);
      },
    },
    dataSexo: {
      get() {
        return this.sexo;
      },
      set(value) {
        this.$_FormPersona_sipeMode(false);
        this.$_FormPersona_update("sexo", value);
      },
    },
    dataFechaNacimiento: {
      get() {
        return this.fechaNacimiento;
      },
      set(value) {
        let formattedDate = functionsDataResumen.calculateAgeWithSeveralFormats(value);
        this.dataEdad = formattedDate;
        this.$_FormPersona_update("fechaNacimiento", value);
      },
    },
    dataEdad: {
      get() {
        return this.edad;
      },
      set(value) {
        this.$_FormPersona_update("edad", value);
      },
    },
    dataDomicilio: {
      get() {
        return this.domicilio;
      },
      set(value) {
        this.$_FormPersona_update("domicilio", value);
      },
    },
    dataDomicilioJuridico: {
      get() {
        return this.domicilioJuridico;
      },
      set(value) {
        // this.domicilioJuridico  = value
        this.$_FormPersona_update("domicilioJuridico", value);
      },
    },
    dataDomicilioSIPE: {
      get() {
        return this.domicilioSIPE;
      },
      set(value) {
        this.$_FormPersona_update("domicilioSIPE", value);
      },
    },
    dataEstadoCivil: {
      get() {
        return this.estadoCivil;
      },
      set(value) {
        // this.estadoCivil = value
        this.$_FormPersona_update("estadoCivil", value);
      },
    },
    dataTelefono: {
      get() {
        return this.telefono;
      },
      set(value) {
        this.$_FormPersona_update("telefono", value);
      },
    },
    dataTelefonoJuridico: {
      get() {
        return this.telefonoJuridico;
      },
      set(value) {
        this.$_FormPersona_update("telefonoJuridico", value);
      },
    },
    dataRedesSociales: {
      get() {
        return this.redesSociales;
      },
      set(value) {
        this.$_FormPersona_update("redesSociales", value);
      },
    },
    dataProfesion: {
      get() {
        return this.profesion;
      },
      set(value) {
        this.$_FormPersona_update("profesion", value);
      },
    },
    dataEmail: {
      get() {
        return this.email;
      },
      set(value) {
        this.$_FormPersona_update("email", value);
      },
    },
    dataVinculo: {
      get() {
        return this.vinculo;
      },
      set(value) {
        this.$_FormPersona_update("vinculo", value);
      },
    },
    dataObservaciones: {
      get() {
        return this.observaciones;
      },
      set(value) {
        this.$_FormPersona_update("observaciones", value);
      },
    },
    dataInstruccion: {
      get() {
        return this.instruccion;
      },
      set(value) {
        this.$_FormPersona_update("instruccion", value);
      },
    },
    dataDenominacion: {
      get() {
        return this.denominacion;
      },
      set(value) {
        this.$_FormPersona_update("denominacion", value);
      },
    },
    dataRubro: {
      get() {
        return this.rubro;
      },
      set(value) {
        this.$_FormPersona_update("rubro", value);
      },
    },
    dataTieneSeguro: {
      get() {
        if(this.tieneSeguro === "1")  this.tieneSeguro = true;
        return this.tieneSeguro;
      },
      set(value) {
        this.$_TemplateForm_update("tieneSeguro", value);
      },
    },
    dataExhibeDocumento: {
      get() {
        return this.exhibeDocumento;
      },
      set(value) {
        this.$_TemplateForm_update("exhibeDocumento", value);
      },
    },
    dataTieneEstudios: {
      get() {
        return this.tieneEstudios;
      },
      set(value) {
        this.$_TemplateForm_update("tieneEstudios", value);
      },
    },

    dataSipeSearch: {
      get() {
        let person = this.getPersonByArrId(this.arrId);
        let sipeSearch = false;
        if (person !== undefined) {
          sipeSearch = person.sipeSearch;
        }
        return sipeSearch;
      },
    },
  },
  create() {
    this.dataDocumentoTipo.nombre = "DNI";
    // console.log('this[viewData.formPersona.condition[this.typeOfPerson]]', viewData.formPersona)
  },
  methods: {
    seeData() {
      if (this.formType == "oficio") {
        if (this.ordenPublico == undefined || this.ordenPublico == false) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    selecCondicion() {
      let value = this.condicionList.filter(
        (item) => item.nombre.toLowerCase().search(this.dataCondicion.toLowerCase()) >= 0
      );
      this.condicion = value[0];
    },
    selecSexo() {
      let value = this.sexoList.filter(
        (item) => item.nombre.toLowerCase().search(this.dataSexo.toLowerCase()) >= 0
      );
      this.sexo = value[0];
    },
    selecDocumentoTipo() {
      let value = this.tipoDocumentoList.filter(
        (item) => item.nombre.toLowerCase().search(this.dataDocumentoTipo.toLowerCase()) >= 0
      );
      this.tipoDocumento = value[0];
    },
    selecNacionalidad() {
      let value = this.nacionalidadList.filter(
        (item) => item.nombre.toLowerCase().search(this.dataNacionalidad.toLowerCase()) >= 0
      );
      this.dataNacionalidad = value[0];
    },
    selecInstruccion() {
      let value = this.instruccionList.filter(
        (item) => item.nombre.toLowerCase().search(this.dataInstruccion.toLowerCase()) >= 0
      );
      this.dataInstruccion = value[0];
    },
    selecEstadoCivil() {
      let value = this.estadoCivilList.filter(
        (item) => item.nombre.toLowerCase().search(this.dataEstadoCivil.toLowerCase()) >= 0
      );
      this.dataEstadoCivil = value[0];
    },
    setData(data) {
      for (let i of this.fields) {
        this[i] = data[i] || null;
      }
    },
    $_TemplateForm_update(field, value) {
      this.$emit("updateValue", field, value);
    },
    $_FormPersona_update(field, value) {
      this.$emit("updateValue", field, value);

      //reinicia la preforma

      let actualPath = this.$router.history.current.path.split("/");
      if (
        actualPath[actualPath.length - 2] == "denuncia" &&
        actualPath[actualPath.length - 1] !== "contraventor"
      )
        this.$store.dispatch("sumario/textResetEditsCounter", "Preform");
      else if (actualPath[actualPath.length - 1] == "contraventor")
        this.$store.dispatch("sumario/textResetEditsCounter", "Contravener");
    },
    async $_FormPersona_sipeSearch() {
      if (!this.$offline.onlineMode) {
        this.$toast.open({
          message: "OFFLINE SIIS: No se pueden consultar datos a SIPE o RENAPER en modo offline.",
          position: "top-right",
          type: "error",
          duration: 3000,
        });
        return;
      }
      let data = {};
      try {
        this.$_FormPersona_sipeMode(true);
        let sexo = this.dataSexo.nombre == "Masculino" ? "M" : "F";
        const url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/data-persona?dni=${this.dataDocumento}&sexo=${sexo}`;
        const result = await fetch(url, {
          credentials: "include",
        });
        this.timer = true;

        data = await result.json();
      } catch (e) {
        if (
          process.env.VUE_APP_BACKEND_ENVIRONMENT === "http://dev.electro3.com.ar/siis/api/v1" ||
          process.env.VUE_APP_BACKEND_ENVIRONMENT === "http://localhost/siis/api/v1"
        ) {
          data = {
            sipe: {
              personaInfo: {
                apellido: "DI MARÍA",
                cuil: "245742775353",
                domicilios: {
                  domicilio: [
                    {
                      barrio: "",
                      calle: "LOS FRESNOS",
                      ciudad: "Funes",
                      cpostal: "2132",
                      departamento: "",
                      monoblock: "",
                      municipio: "Funes",
                      numero: "718",
                      pais: "Argentina",
                      piso: "",
                      provincia: "Santa Fe",
                      tipoDomicilio: "Falso",
                    },
                  ],
                },
                fechaActualiza: "2024-07-25",
                fechaFallecimiento: "",
                fechaNacimiento: "1988-02-14",
                idPersona: "2799684",
                nombre: "Ángel Fabián",
                sexo: "M",
              },
              resultadoWS: {
                codigoError: "",
                exito: true,
                msgError: "",
              },
            },
          };
        } else {
          console.error("err", e);
          // this.error = "Datos incompletos o problema de conexión con SIPE."
          this.$_FormPersona_sipeError("Datos incompletos o problema de conexión con SIPE.");
          this.$_FormPersona_sipeMode(false);
        }
      }
      if (data.sipe?.resultadoWS.exito !== true) {
        // console.log(data)
        if (data.sipe && data.sipe.resultadoWS) {
          this.$_FormPersona_sipeError("Persona no encontrada");
        } else {
          this.$_FormPersona_sipeError("SIPE sin conexión.");
        }
        this.$_FormPersona_sipeMode(false);
      } else {
        /* Nombre y Apellido */
        this.dataNombre = data.sipe.personaInfo.nombre;
        this.dataApellido = data.sipe.personaInfo.apellido;
        this.dataCUIL = data.sipe.personaInfo.cuil;
        /*  */
        /* Fecha de nacimiento */
        let dataDeNacimiento = data.sipe.personaInfo.fechaNacimiento;
        let orderedDate =
          dataDeNacimiento.slice(8, 10) +
          dataDeNacimiento.slice(4, 7) +
          "-" +
          dataDeNacimiento.slice(0, 4);
        this.fechaNacimiento = orderedDate;
        this.dataFechaNacimiento = this.fechaNacimiento;
        /*  */

        /* Domicilio */
        let address = data.sipe.personaInfo.domicilios.domicilio[0];
        this.dataDomicilioSIPE = `${address.calle} ${address.numero}, ${address.barrio} CP:${address.cpostal} - ${address.municipio}, ${address.provincia}, ${address.pais}`;
        /*  */
      }

      // if (data.sipe.resultadoWS.exito === true) {
      //   /* Nombre y Apellido */
      //   this.dataNombre = data.sipe.personaInfo.nombre;
      //   this.dataApellido = data.sipe.personaInfo.apellido;
      //   /*  */

      //   /* Fecha de nacimiento */
      //   let dataDeNacimiento = data.sipe.personaInfo.fechaNacimiento;
      //   let orderedDate =
      //     dataDeNacimiento.slice(8, 10) +
      //     dataDeNacimiento.slice(4, 7) +
      //     "-" +
      //     dataDeNacimiento.slice(0, 4);
      //   this.fechaNacimiento = orderedDate;
      //   this.dataFechaNacimiento = this.fechaNacimiento;
      //   /*  */

      //   /* Domicilio */
      //   let address = data.sipe.personaInfo.domicilios.domicilio[0];
      //   this.dataDomicilioSIPE = `${address.calle} ${address.numero}, ${address.barrio} CP:${address.cpostal} - ${address.municipio}, ${address.provincia}, ${address.pais}`;
      //   /*  */
      // } else {
      //   this.$_FormPersona_sipeError("Persona no encontrada");
      //   this.$_FormPersona_sipeMode(false);
      // }
    },
    $_FormPersona_sipeError(error) {
      this.error = error;
      this.dataNombre = "";
      this.dataApellido = "";
      this.fechaNacimiento = "";
      this.dataFechaNacimiento = "";
      this.dataDomicilioSIPE = "";
      this.dataCuil = "";
    },
    $_FormPersona_sipeMode(active) {
      if (active) {
        this.timer = false;
        this.error = false;
        this.disabledFields = [
          "dataNombre",
          "dataApellido",
          "dataFechaNacimiento",
          "dataDomicilioSIPE",
          "dataCUIL",
        ];
        this.$store.dispatch(`sumario/${this.typeOfPerson}/setSipeSearch`, {
          arrId: this.arrId,
          sipeSearch: true,
        });
      } else {
        this.timer = true;
        this.disabledFields = [];
        this.$store.dispatch(`sumario/${this.typeOfPerson}/setSipeSearch`, {
          arrId: this.arrId,
          sipeSearch: false,
        });
      }
    },
    checkDni() {
      if (this.dataDocumentoTipo != undefined && this.dataDocumentoTipo.nombre == "DNI") {
        return true;
      }
      return false;
    },

    isDisabled(field) {
      return this.disabledFields.indexOf(field) >= 0;
    },
    emitErrores() {
      for (let i in this.errores) {
        if (this.errores[i] != null) {
          // console.log("i= ", i, "\n");
          return true;
        }
      }
      return false;
    },
    generateClasses() {
      let classes;
      if (this.typeOfPerson === "denunciante") {
        classes = "field col-12 md:col-2 lg:col-2 sm:col-2 w-13rem";
      } else {
        classes = "field col-12 md:col-4 lg:col-4 sm:col-4";
      }
      return classes;
    },
    verifyJuridicPerson() {
        if(this.tieneSeguro === '1' && this.tieneSeguro !== 0) return true;
        const juridicPersonList = [this.rubro, this.domicilioJuridico, this.denominacion, this.cuit, this.telefonoJuridico];
        let thereIsJuridicPerson = false;
        juridicPersonList.map(item => {
          if(item !== '' && item !== null) thereIsJuridicPerson = true;
        })
        return thereIsJuridicPerson;
    },
  },
};
</script>

<style scoped>
.condition {
  margin-left: -8px;
}
.spinner {
  display: none;
}
.buttonAndSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input {
  resize: vertical;
  margin: 0;
}
.chkbx {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.txtchkbx {
  margin-top: 8px;
}
.visible {
  color: #dc3545;
  text-align: center;
  margin-top: 3px;
}
</style>
