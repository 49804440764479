<template>
  <div :class="props.modalClass" class="border-round-2xl">
    <Dialog 
      :visible.sync="opened" 
      :containerStyle="styles" 
      :modal="modal" 
      :dismissableMask="dismissableMask"
      :closable="closable" 
      ref="dialogRef"
    >
      <div class="flex flex-column justify-content-center align-items-center">
        <i :class="getIcon() + ' mr-3'" :style="{ fontSize: '4rem', color: getIconColor() }" />
        <p class="font-bold text-xl">{{ title }}</p>
        <span class="flex align-items-center justify-content-center text-center">
          {{ message }}
        </span>
      </div>
      <template #footer test-id="modal-buttons">
        <div class="w-full flex justify-content-end">
          <Button 
            :label="props.type === 'success' ? 'Volver' : 'Volver'" 
            @click="emit('confirm')" 
            test-id="btn-si"
            class="bg-transparent text-gray-900 font-medium border-600 border-round-lg w-5" 
          />
          <Button 
            :label="props.type === 'success' ? 'Seguir Cargando' : 'Cancelar'" 
            @click="emit('cancel')" 
            test-id="btn-no" 
            class="border-round-lg w-5" 
          />

        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>

import { ref, onMounted, useSlots } from 'vue';

let opened = ref(false);

const emit = defineEmits(['confirm', 'cancel', 'custom-event']);

const slots = useSlots();

const props = defineProps({
  modalClass: {
    type: String,
    default: '',
  },
  styles: {
    type: Object,
    default: () => ({ width: '500px' }),
  },
  title: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: null,
  },
  severity: {
    type: String,
    default: null,
  },
  message: {
    type: String,
    default: null,
    default: '',
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: null,
  },
  buttons: {
    type: Array,
    default: () => [],
  },
  modal: {
    type: Boolean,
    default: true,
  },
  dismissableMask: {
    type: Boolean,
    default: false,
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: null,
  },
  buttons: {
    type: Array,
    default: () => [],
  },
  closable: {
    type: Boolean,
    default: true,
  },
  loadingSaveButton: {
    type: Boolean,
    default: false,
  },
})

const open = ref(() => {
  opened.value = true;
})

const close = ref(() => {
  opened.value = false;
})

const getIcon = () => {
  if (props.icon === null) {
    if (props.type === 'success') {
      return 'pi pi-check-circle';
    }
    if (props.type === 'confirmativo') {
      return 'pi pi-exclamation-triangle';
    }
  } else {
    return 'pi ' + props.icon;
  }
}

const getIconColor = () => {
  if (props.icon === null) {
    if (props.type === 'success') {
      return 'rgba(4, 142, 65, 1)';
    }
    if (props.type === 'confirmativo') {
      return 'rgba(251, 192, 45, 1)';
    }
  } else {
    return 
  }
}

defineExpose({
  open,
  close
});

</script>