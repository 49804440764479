export default {
    computed: {
      excludedBackButtonPaths() {
        // Rutas en las que no debe aparecer el botón de volver atrás [↓]
        return [
          '/actuaciones/new/sumario-denuncia/initial',
          '/actuaciones/new/sumario-oficio/initial',
          '/actuaciones/new/expediente-denuncia/initial',
          '/actuaciones/new/expediente-oficio/initial',
          '/actuaciones/new/ufi-flagrancia/initial',
          '/actuaciones/new/ufi-propiedad-denuncia/initial',
          '/actuaciones/new/ufi-propiedad-oficio/initial',
          '/actuaciones/new/ufi-informatica-denuncia/initial',
          '/actuaciones/new/ufi-informatica-oficio/initial',
          '/actuaciones/new/ufi-cavig/initial',
          '/actuaciones/new/ufi-anivi/initial',
          '/actuaciones/new/ufi-generica-denuncia/initial',
          '/actuaciones/new/ufi-generica-oficio/initial',
          '/actuaciones/new/ufi-establecer-paradero/initial',
          '/actuaciones/new/ufi-emergencias-medicas/initial',
          '/actuaciones/new/ufi-siniestro-vial/initial',
          '/actuaciones/new/ufi-suicidio/initial',
          '/actuaciones/new/ufi-incendio-vivienda/initial',
          '/actuaciones/new/ufi-intoxicacion/initial'
        ];
      },
      // Función que determina debe o no mostrar el botón de volver atrás [↓]
      shouldShowBackButton() {
        const showBackButton = this.$route.meta?.showBackButton && 
                       !this.excludedBackButtonPaths.some(path => 
                         this.$route.path.startsWith(path)
                       );
        // console.log(this.$route.path, "mostrar: ", showBackButton) => Para depuración
        return showBackButton;
      }
    },
    methods: {
    // Función de botón volver atrás [↓]
      goBack() {
        if (this.$route.name == 'view-id') { // En caso de previsualizar una actuación, el botón de volver atrás llevará al usuario a la lista de actuaciones
          this.$router.push('/actuaciones/list');
        } else {
          this.$router.go(-1);
        }
      }
    }
  };
  