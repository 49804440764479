

<template>
    <Card
      :class="{
        passed: calculatePassedCard(),
        warning: calculateWarningCard(),
        error: calculateErrorCard(),
        'disabled': disabled
      }"
      test-id="Resumen"
      class="borderShadow shadow-3 border-round-lg border-1 border-400"
      tabindex="0"
    >
      <template #title>
        <div class="title flex justify-content-between flex-wrap">
          <div test-id="ResumenTitle" class="text-gray-700 font-medium text-xl">
            {{ title }}
          </div>
          <!-- Botón deshabilitado si disabled es true -->
          <Button
            icon="pi pi-plus"
            v-if="showPlusButton"
            ref="cardFocus"
            class="p-button-rounded p-button-info p-button-outlined text-gray-500"
            :disabled="disabled"
            @click="triggerAddMethod(editPath)"
       
            test-id="ResumenPlusIcon"
          />
          <!-- @click="!disabled && $router.push({ path: editPath, query: { id: 'new' } })" -->
        </div>
      </template>
      <template #content>
        <slot></slot>
      </template>
    </Card>
</template>

<script>

export default {
  props: {
    showPlusButton: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null
    },
    editPath: {
      type: String,
      default: null
    },
    warning: {
      type: [String, Array]
    },
    errors: {
      type: [String, Array]
    },
    formType: {
      type: String,
      default: null
    },
    showValidation: {
      type: Boolean,
      default: false
    },
    viewData: {
      type: Object,
    },
    detectedError: {
      type: Boolean,
      default: false
    },
  },
  name: 'ResumenCard',
  data() { 
    return {
    }
  },
  // mounted(){
    // console.log('alertas()', this.alertas(), this.title)
  // },
  methods: {
    triggerAddMethod(editPath){
      if(this.title==="Aprehendido y/o acusado" || this.title==="Efectos"|| this.title==="Víctima y/o damnificado"){
        this.$emit("disableAfectation");
        this.$router.push({ path: editPath, query: { id: 'new' } });
        
        
      }else if(this.title==="Afectación Patrimonial" ){
        this.$router.push({ path: editPath, query: { id: 'new' } });
        this.$emit("disablePersonsAndEffect");
      }else{
        this.$router.push({ path: editPath, query: { id: 'new' } });
      }
    },
    alertas()
    {
      // console.log('this.title', this.title);
      // console.log('this.errors', this.errors);
      if (this.disabled) return null;
      if(this.title == "Acusado" && !this.warning.includes("No especificó un acusado")){
        return "ok" 
      }
      if(this.title == "Contraventor/a" && !this.warning.includes("No especificó un acusado")){
        return "ok" 
      }else{
        if(this.title == "Vinculados" && (!this.errors.includes("Falta especificar un acusado") && !this.warning.includes("No especificó un acusado"))){
          // console.log('error vinculados');
          return "ok" 
        }else{
          if(this.title == "Autolesionado" && !this.errors.includes("Debe especificar al menos un Autolesionado"))
          {
            return "ok" 
          }
        }
      }
      if((this.title == "Efectos secuestrados" || this.title == "Efectos") && !this.warning.includes("No especificó ningún efecto denunciado"))
      {
        return "ok" 
      }
      if(this.title == "Causante" && !this.errors.includes("Falta especificar un causante"))
      {
        return "ok" 
      }
      return "false"
    }, 
    calculatePassedCard(){
      if (this.disabled) return null;
      return this.viewData.resumenCardPassed && this.alertas() == 'ok' && this.showValidation == true;
    },
    calculateWarningCard(){
      if (this.disabled) return null;
      return this.alertas() != 'ok' && this.showValidation == true &&
      (
        (
          this.viewData.resumenCardWarning === true
        ) ||
        (
          this.formType.startsWith('ufi') && 
          (this.title == 'Efectos secuestrados' || this.title == 'Efectos')
        ) 
      );
    },
    calculateErrorCard(){
      if (this.disabled) return null;
      if(this.detectedError) return true;
      return (
        this.alertas() != 'ok' && 
        this.showValidation == true &&
        (
          this.formType.startsWith('ufi')||
          ( 
            (this.formType === 'preliminar-tentativa-suicidio' && this.title == 'Autolesionado')) || 
            (this.formType === 'preliminar-fuga-menor' && this.title == 'Causante')
          ) 
      ) && 
      (this.title != 'Efectos secuestrados' || this.title != 'Efectos');
    }
  }
}
</script>
<style scoped>
  .content {
    width: 100%;
  }
  div.card {
    width: 100%;
  }
  .borderShadow:focus {
    box-shadow: 0 0 0 0.2rem #89d4fe;
    outline: none;
  }
  .title {
    font-size: 1rem;
  }
  .title-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .passed{
    border-bottom: solid 2px #28a745 ;
    /* background-color: #28a745; */ 
  }
  .error{
     border-bottom: solid 2px #dc3545;
  }
  .warning{
    border-bottom: solid 2px #ffc107
  }  
  .alert
  {
    color: #dc3545;
  }
  .disabled {
    pointer-events: none; 
    filter: brightness(0.9); 
  }
  

</style>
