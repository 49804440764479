<template>
  <div>
    <ModalTemplate
      title="Información"
      type="informativo"
      message="Conexión inestable, falló el registro de la actuación"
      :showIcon="true"
      @confirm="redirectToHome()"
      :loadingSaveButton="loadingSaveButtonInModalTemplate"
      severity="danger"
      :closable="false"
      ref="ModalRefInform"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModalTemplate from "./ModalTemplate.vue";

export default {
  name: "ModalOfflineError",
  components: {
    ModalTemplate
  },
  // props: {
  //   datos: Array,
  // },
  data: () => {
    return {
      loadingSaveButtonInModalTemplate: false
    };
  },
  // created() {
  
  // },
  // computed: {
  //   ...mapState({
  //   }),
    
  // },
  methods: {
    redirectToHome() {
      this.loadingSaveButtonInModalTemplate = true;
      window.location.href = '/';
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>